import React from 'react';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';

const ListTrucks = () => {
  const headers = [
    {
      label: 'Code',
      key: 'code'
    },
    {
      label: 'Nom',
      key: 'label' || ''
    },
    {
      label: 'Unité de projection',
      key: 'projection_unit'
    },
    {
      label: 'Abbreviation',
      key: 'abbreviation' || ''
    },
    {
      label: 'Coefficient',
      key: 'coefficient' || ''
    },
    {
      label: 'Dépôt',
      key: 'deposit' || ''
    },
    { label: 'Techniciens', key: 'users' }
  ];
  return (
    <SimpleResourceLandingLayout
      resourceName="trucks"
      columns={[...columns()]}
      populate="deposit,users"
      withSubRoutes
      headers={headers}
      resourceModelName="Truck"
    />
  );
};

export default ListTrucks;
