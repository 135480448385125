import React, { useCallback, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import NewInvoiceForm from './Components/NewInvoiceForm';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useInvoiceContext from './InvoiceContext';
import useAuthContext from '../../../contexts/AuthContext';

const CreateUpdateInvoice = ({ purpose }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { user } = useAuthContext();
  const form = useRef();
  const {
    getInvoice,
    setIsLoading,
    setListPrestations,
    vats,
    invoice,
    setIsVat,
    updateInvoice
  } = useInvoiceContext();
  const getInvoiceInit = useCallback(async () => {
    setIsLoading(true);
    await getInvoice(purpose, id);
    if (invoice && invoice.vat) {
      form.current.setFieldsValue({ vat: invoice?.vat._id });
      setListPrestations(invoice.prestations);
      setIsVat(true);
    }
    setIsLoading(false);
  }, [purpose, id]);

  useEffect(() => {
    if (purpose === 'edit' && id) getInvoiceInit();
    if (purpose === 'create') {
      form.current.resetFields();
      form.current.setFieldsValue({ organization: user.organization });
    }
  }, [getInvoiceInit]);

  const handleSubmit = (values) => {
    const { deposit_invoice, ...rest } = values;
    updateInvoice(id, rest);
  };
  const onValueChange = (prev, values) => {
    if (prev.vat) {
      setIsVat(true);
    }
    if (values.prestations && values.prestations.length > 0) {
      const subTotalResult = values.prestations.map((presta) => {
        if (presta.unit_price) {
          if (presta.discount && presta.discount.value) {
            if (presta.discount.type === 'CURRENCY')
              return {
                ...presta,
                subtotal: presta.unit_price - presta.discount.value
              };
            if (presta.discount.type === 'PERCENT')
              return {
                ...presta,
                subtotal:
                  presta.unit_price -
                  presta.unit_price * (presta.discount.value / 100)
              };
          }
          return {
            ...presta,
            subtotal: presta.unit_price
          };
        }
        return presta;
      });
      setListPrestations([...subTotalResult]);
      const resultSubTotal =
        subTotalResult.map((sub) => sub.subtotal).reduce((a, b) => a + b, 0) -
        (invoice?.deposit_invoice ? invoice?.deposit_invoice.subTotal : 0);
      const resultTotalVat =
        resultSubTotal *
        (vats.filter((ele) => ele._id === values.vat)[0].rate / 100);
      const prorata =
        (values.prorata_percentage / 100) *
        (resultSubTotal +
          resultTotalVat -
          (values.holdback / 100) * (resultSubTotal + resultTotalVat));
      form.current.setFieldsValue({
        prestations: [...subTotalResult],
        subTotal: resultSubTotal,
        total_VAT: resultTotalVat,
        prorata_account: prorata,
        total:
          resultSubTotal +
          resultTotalVat -
          (values.holdback / 100) * (resultSubTotal + resultTotalVat) -
          prorata
      });
    }
  };

  return (
    <>
      <PageHeaderCustom title={t(`invoices.form.title.${purpose}`)} />
      <ContentCustom>
        <NewInvoiceForm
          purpose={purpose}
          id={id}
          onValueChange={onValueChange}
          handleSubmit={handleSubmit}
          ref={form}
        />
      </ContentCustom>
    </>
  );
};

CreateUpdateInvoice.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateInvoice;
