import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Tabs } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import useErrorMessage from '../../../utils/ErrorMessage';
import TableStock from './TableStock';
import CardsGraph from './CardsGraph';

const { TabPane } = Tabs;

const ShowDeposit = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [depositValue, setDepositValue] = useState({});
  const [tanks, setTanks] = useState([]);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [primaryMatters, setPrimaryMatters] = useState([]);
  const [epis, setEpis] = useState([]);
  const [articles, setArticles] = useState([]);
  const [activeKey, setActiveKey] = useState('1');

  const getTanks = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/tanks'
      });
      const tanksFilter = data.filter(({ deposit }) => deposit === id);
      setTanks(tanksFilter);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPrimaryMatters = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/primary-matters/bydeposit/${id}`
      });
      setPrimaryMatters(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEpis = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/epis/bydeposit/${id}?populate=unit`
      });
      setEpis(data.map(({ _id, ...d }) => ({ ...d, key: _id })));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getArticles = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/articles/deposit/${id}?populate=unit`
      });
      setArticles(data.map(({ _id, ...d }) => ({ ...d, key: _id })));
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getDeposit = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deposits/${id}`
      });
      setDepositValue(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  const onChange = () => {
    setForceRefresh(!forceRefresh);
  };

  useEffect(() => {
    (async () => {
      await getDeposit();
      await getTanks();
      await getPrimaryMatters();
      await getEpis();
      await getArticles();
    })();
  }, [getDeposit, forceRefresh]);

  return (
    <>
      <PageHeaderCustom title={depositValue.label || ''} withSubRoutes />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <Tabs
            type="card"
            activeKey={activeKey}
            onChange={(key) => setActiveKey(key)}
          >
            <TabPane onChange={onChange} tab="Cuves" key="1">
              <CardsGraph
                batches={primaryMatters && primaryMatters}
                tanks={tanks}
              />
            </TabPane>
            <TabPane tab="Stock consommables" key="2">
              <TableStock stockType="consumables" />
            </TabPane>
            <TabPane tab="Stock EPI" key="3">
              <TableStock stockType="epis" />
            </TabPane>
            <TabPane tab="Stock machines" key="4">
              <TableStock stockType="machines" />
            </TabPane>
            <TabPane tab="Valorisation Financière" key="5">
              {`La valorisation financière de ce dépôt est de ${Math.round(
                articles
                  ?.map((article) =>
                    article ? article.buy_price * article.quantity : 0
                  )
                  .reduce((curr, prev) => curr + prev, 0) +
                  epis
                    ?.map((epi) => (epi ? epi.buy_price * epi.quantity : 0))
                    .reduce((curr, prev) => curr + prev, 0) +
                  tanks
                    ?.map((tank) => {
                      const tankValue = [];
                      primaryMatters.forEach(({ batch, buy_price }) =>
                        batch
                          .filter(
                            (el) =>
                              el.tank._id === tank._id && el.archived === false
                          )
                          .forEach(({ quantity }) =>
                            tankValue.push(quantity * buy_price)
                          )
                      );
                      return tankValue.reduce((curr, prev) => curr + prev, 0);
                    })
                    .reduce((curr, prev) => curr + prev, 0)
              )} €`}
            </TabPane>
          </Tabs>
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowDeposit;
