import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Modal,
  Select,
  Row,
  InputNumber,
  Input,
  Form,
  Descriptions,
  Radio
} from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;
const CreateDeposit = ({
  setShowModal,
  showModal,
  getInvoices,
  quotationData
}) => {
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [vats, setVats] = useState([]);
  const [banks, setBanks] = useState([]);
  const [disabled, setDisabled] = useState();
  const [typeValue, setTypeValue] = useState('percentage');

  const setValueTypeForm = {
    euro: (
      <Descriptions.Item label="Montant d'acompte">
        <Form.Item noStyle noLabel name={['deposit', 'euro']}>
          <InputNumber
            disabled={disabled}
            style={{ width: 150 }}
            formatter={(value) => value && `${value}€`}
            parser={(value) => value.replace('€', '')}
            placeholder="Montant (en €)"
          />
        </Form.Item>
      </Descriptions.Item>
    ),
    percentage: (
      <Descriptions.Item label="Pourcentage d'acompte">
        <Form.Item noStyle noLabel name={['deposit', 'percentage']}>
          <InputNumber
            disabled={disabled}
            formatter={(value) => value && `${value}%`}
            parser={(value) => value.replace('%', '')}
            placeholder="(en %)"
          />
        </Form.Item>
      </Descriptions.Item>
    )
  };

  const createInvoice = async (body) => {
    try {
      const {
        contact,
        customer,
        number,
        organization,
        site,
        _id,
        bank_account,
        ...rest
      } = quotationData;
      const result = {
        ...rest,
        email_sent: false,
        emission_date: null,
        deadline_date: null,
        contact: contact?._id,
        customer: customer?._id,
        quotation: _id,
        organization: organization?._id,
        payment_bank: bank_account ? bank_account._id : body.bank_account,
        site: site?._id,
        type: 'DEPOSIT',
        type_invoice: 'DEPOSIT',
        prestations: [
          {
            label: body?.description,
            unit_price: body.subtotal,
            subtotal: body.subtotal,
            discount: null
          }
        ],
        subTotal: body.subtotal,
        vat: quotationData.vat,
        total_VAT: Math.round(
          (vats.filter((vat) => vat._id === body.tva)[0].rate / 100) *
            body.subtotal
        ),

        total_discount: 0,
        total: body.total
      };
      await dispatchAPI('POST', {
        url: `/invoices/`,
        body: {
          ...result
        }
      });
      getInvoices();
      setShowModal(false);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getVats = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/vats'
      });
      setVats(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getBanks = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/bank-settings'
      });
      setBanks(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    getVats();
    getBanks();
    form.setFieldsValue({
      deposit: {
        tva: quotationData.vat ? quotationData.vat._id : null,
        bank_account:
          (quotationData.bank_account && quotationData.bank_account._id) || null
      }
    });
  }, []);

  const handleSubmit = (values) => {
    if (values.deposit) {
      createInvoice(values.deposit);
    }
  };
  const invoiceConfirm = () => {
    setDisabled(true);
  };
  const onChangeValue = (_, values) => {
    const { tva, euro, percentage, type } = values.deposit;
    if (type) setTypeValue(type);
    if (percentage)
      form.setFieldsValue({
        deposit: {
          subtotal: (percentage / 100) * quotationData.total
        }
      });
    if (euro)
      form.setFieldsValue({
        deposit: {
          subtotal: euro
        }
      });
    if (tva) {
      if (euro) {
        const rate = vats.filter((vat) => vat._id === tva)[0].rate;
        form.setFieldsValue({
          deposit: {
            total: ((rate / 100) * euro + euro).toFixed(2)
          }
        });
      }

      if (percentage) {
        const rate = vats.filter((vat) => vat._id === tva)[0].rate;
        form.setFieldsValue({
          deposit: {
            total: (
              ((rate / 100) * quotationData.total + quotationData.total) *
              (percentage / 100)
            ).toFixed(2)
          }
        });
      }
    }
    if (_.deposit.type) {
      form.setFieldsValue({
        deposit: {
          [type]: null,
          subtotal: 0,
          total: 0
        }
      });
    }
  };

  return (
    <Modal
      title={`Facturation d'acompte devis n ° ${quotationData?.number}`}
      visible={showModal}
      width={1000}
      destroyOnClose
      onCancel={() => setShowModal(false)}
      footer={false}
    >
      <Descriptions title="Informations devis" column={3}>
        <Descriptions.Item label="Montant global">
          {`${quotationData?.total} € TTC`}
        </Descriptions.Item>
        <Descriptions.Item label="Client">
          {`${quotationData?.customer?.name}`}
        </Descriptions.Item>
        <Descriptions.Item label="Banque">
          {`${quotationData?.bank_account?.name || 'Aucune banque saisie'}`}
        </Descriptions.Item>
        <Descriptions.Item label="Référence chantier">
          {`${quotationData?.site?.reference || 'Aucune référence'}`}
        </Descriptions.Item>
      </Descriptions>
      <Divider>Saisie acompte</Divider>
      <Form
        form={form}
        onValuesChange={onChangeValue}
        onFinish={(values) => handleSubmit(values)}
      >
        <Descriptions>
          <Descriptions.Item label="Libellé">
            <Form.Item noStyle noLabel name={['deposit', 'description']}>
              <Input
                style={{ maxWidth: 300 }}
                disabled={disabled}
                placeholder="Description..."
              />
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item label="Type de saisie">
            <Form.Item noStyle noLabel name={['deposit', 'type']}>
              <Radio.Group disabled={disabled} defaultValue={typeValue}>
                <Radio value="euro">Montant en €</Radio>
                <Radio value="percentage">Montant en %</Radio>
              </Radio.Group>
            </Form.Item>
          </Descriptions.Item>
          {setValueTypeForm[typeValue]}
          <Descriptions.Item label="Taux TVA">
            <Form.Item noStyle noLabel name={['deposit', 'tva']}>
              <Select disabled placeholder="TVA" style={{ width: 200 }}>
                {vats?.map((e) => (
                  <Option value={e._id} key={e._id}>
                    {`Taux: ${e.rate} % - ${e.code_number}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Banque">
            <Form.Item noStyle noLabel name={['deposit', 'bank_account']}>
              <Select
                disabled={disabled}
                placeholder="Saisir la banque"
                defaultValue={
                  quotationData.bank_account
                    ? quotationData.bank_account._id
                    : null
                }
                style={{ width: 250 }}
              >
                {banks?.map((e) => (
                  <Option value={e._id} key={e._id}>
                    {`${e.name} - ${e.city}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
        <Divider>Montant de la facture</Divider>
        <Descriptions>
          <Descriptions.Item span={2} label="Total HT">
            <Form.Item noStyle name={['deposit', 'subtotal']}>
              <InputNumber
                formatter={(value) => value && `${value}€`}
                parser={(value) => value.replace('€', '')}
                bordered={false}
                readOnly
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Total TTC">
            <Form.Item noStyle name={['deposit', 'total']}>
              <InputNumber
                style={{ width: 200 }}
                formatter={(value) => value && `${value}€`}
                parser={(value) => value.replace('€', '')}
                bordered={false}
                readOnly
              />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>

        <Row style={{ marginTop: 10 }} justify="center">
          <Button
            onClick={() => invoiceConfirm()}
            disabled={disabled}
            type="primary"
            ghost
          >
            Valider une facture d&apos;acompte
          </Button>
          {disabled ? (
            <Button
              style={{ left: 10 }}
              type="secondary"
              danger
              onClick={() => setDisabled(false)}
            >
              Modifier
            </Button>
          ) : null}
        </Row>
        <Divider />
        <Row justify="center">
          <Button
            disabled={!disabled}
            type="primary"
            htmlType="submit"
            icon={<ProfileOutlined />}
          >
            Facturer
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

CreateDeposit.propTypes = {
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  getInvoices: PropTypes.func.isRequired,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  quotationData: PropTypes.oneOfType([PropTypes.object])
};

CreateDeposit.defaultProps = {
  setShowModal: null,
  showModal: null,
  data: null,
  quotationData: null
};

export default CreateDeposit;
