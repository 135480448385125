import React, {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState
} from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const TruckContext = createContext({});

export const TruckContextProvider = ({ children }) => {
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [deposits, setDeposits] = useState([]);
  const [users, setUsers] = useState([]);
  const [technicians, setTechnicians] = useState([]);

  const getDeposits = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/deposits' });
      setDeposits(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?populate=truck&type=TECH,SELLER'
      });
      setUsers(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getDeposits();
    await getUsers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return (
    <TruckContext.Provider
      value={{
        dispatchAPI,
        errorMessage,
        isFieldsLoading,
        setIsFieldsLoading,
        deposits,
        users,
        technicians,
        setTechnicians,
        ExclamationCircleOutlined
      }}
    >
      {children}
    </TruckContext.Provider>
  );
};

export default () => useContext(TruckContext);
