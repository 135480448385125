import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListPrimaryMatters from './ListPrimaryMatters';
import CreateUpdatePrimaryMatters from './CreateUpdatePrimaryMatters';
import ShowPrimaryMatters from './ShowPrimaryMatters';
import TableArchivedBatches from './TableArchivedBatches';

const PrimaryMattersRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdatePrimaryMatters purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdatePrimaryMatters purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowPrimaryMatters} />
      <Route path={`${path}/archives/:id`} component={TableArchivedBatches} />
      <Route path={`${path}`} component={ListPrimaryMatters} />
    </Switch>
  );
};

export default PrimaryMattersRouter;
