import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import SimpleResourceLandingLayout from '../../components/layouts/SimpleResourceLandingLayout';
import { userRoles, userTypes } from '../../utils/constants/tagColors';
import useAuthContext from '../../contexts/AuthContext';

const ListUsers = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [organizations, setOrganizations] = useState([]);

  useEffect(() => {
    (async () => {
      const response = await dispatchAPI('GET', {
        url: `/organizations`
      });
      setOrganizations(response.data);
    })();
  }, []);

  const headers = [
    {
      label: 'last_name',
      key: 'last_name'
    },
    {
      label: 'first_name',
      key: 'first_name'
    },
    {
      label: 'type',
      key: 'type'
    },
    {
      label: 'email',
      key: 'email'
    },
    {
      label: 'Phone number country code',
      key: 'phone_number.country_code'
    },
    {
      label: 'Phone number',
      key: 'phone_number.number'
    },
    {
      label: 'role',
      key: 'role'
    },
    {
      label: 'Address street number',
      key: 'address.number'
    },
    {
      label: 'Address street name',
      key: 'address.street'
    },
    {
      label: 'Address additional information',
      key: 'address.additional'
    },
    {
      label: 'Address postal code',
      key: 'address.postal_code'
    },
    {
      label: 'Address city',
      key: 'address.city'
    },
    {
      label: 'Address country',
      key: 'address.country'
    }
  ];

  const columns = [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      render: null,
      sorter: true
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('users.form.type'),
      key: 'type',
      dataIndex: 'type',
      sorter: true,
      render: (type) => (
        <Tag color={userTypes[type]}>{t(`users.type.${type}`)}</Tag>
      ),
      filters: Object.keys(userTypes).map((r) => {
        return {
          text: t(`users.type.${r}`),
          value: r
        };
      })
    },
    {
      title: t('users.form.role'),
      key: 'role',
      dataIndex: 'role',
      render: (role) => (
        <Tag color={userRoles[role.split(':')[1]]}>
          {t(`users.tags.${role.split(':')[1]}`)}
        </Tag>
      ),
      sorter: true,
      filters: Object.keys(userRoles).map((r) => ({
        text: t(`users.tags.${r}`),
        value:
          r.split('-').length > 1
            ? `${r.split('-')[1].toLowerCase()}s:${r}`
            : `${r.toLowerCase()}s:${r}`
      }))
    },
    {
      title: t('users.form.organization'),
      key: 'organization',
      dataIndex: 'organization',
      render: (organization) =>
        organization ? <Tag>{organization?.name}</Tag> : '-',
      sorter: true,
      filters: organizations.map((organization) => {
        const organization_name = organization.name.replaceAll('.', '_');
        return {
          text: t(`users.organization.${organization_name}`),
          value: organization._id
        };
      })
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      render: null,
      sorter: true
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="users"
      columns={columns}
      headers={headers}
      resourceModelName="User"
      populate="organization"
      withUploadButton={false}
    />
  );
};

export default ListUsers;
