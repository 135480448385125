import React from 'react';
import { Link } from 'react-router-dom';
import { Tag, Divider, Descriptions } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';

const useListContent = ({
  name,
  first_name,
  origin,
  designated_commercial,
  activity,
  address_details_1,
  address_details_2,
  postal_code,
  city,
  phone_number_mobile,
  phone_number_office,
  email,
  website,
  siret_number,
  payment_type,
  outstanding_amount,
  number_customer_account,
  observations,
  billing_address_1,
  billing_address_2,
  billing_postal_code,
  billing_city,
  professional,
  type,
  last_name,
  other_time_granted,
  holdbacks
}) => {
  const fontSizeLabel = { fontSize: 12 };
  return [
    {
      label: 'companies.form.professional',
      content: professional ? (
        <Tag color="var(--addColor)">Professionnel</Tag>
      ) : (
        <Tag>Particulier</Tag>
      ),
      span: 1
    },
    {
      label: 'companies.form.name',
      content: name,
      span: 1
    },
    {
      label: 'companies.form.first_name',
      content: `${first_name || ''} ${last_name || ''}`,
      span: 1
    },
    {
      label: 'companies.form.designated_commercial',
      labelStyle: { fontSize: 10 },
      fontWeight: 600,
      content: designated_commercial && (
        <Link to={`/users/show/${designated_commercial._id}`}>
          {`${designated_commercial.first_name} ${designated_commercial.last_name}`}
        </Link>
      ),
      span: 1
    },
    {
      label: '',
      content: (
        <Descriptions
          title={<Divider orientation="left">Entreprise</Divider>}
          column={4}
        >
          <DescriptionsItem style={fontSizeLabel} label="Origine">
            {origin}
          </DescriptionsItem>
          <DescriptionsItem style={fontSizeLabel} label="Activité">
            {activity}
          </DescriptionsItem>
          <DescriptionsItem style={fontSizeLabel} label="Site internet">
            {website}
          </DescriptionsItem>
          <DescriptionsItem style={fontSizeLabel} label="Numéro SIRET">
            {siret_number}
          </DescriptionsItem>
          <DescriptionsItem style={fontSizeLabel} label="Adresse">
            {address_details_1}
          </DescriptionsItem>
          <DescriptionsItem style={fontSizeLabel} label="Complément d'adresse">
            {address_details_2}
          </DescriptionsItem>
          <DescriptionsItem style={fontSizeLabel} label="Code postal">
            {postal_code}
          </DescriptionsItem>
          <DescriptionsItem style={fontSizeLabel} label="Ville">
            {city}
          </DescriptionsItem>
        </Descriptions>
      ),
      span: 4,
      fontWeight: 600
    },
    {
      label: '',
      content: (
        <Descriptions
          style={{ padding: -10 }}
          title={<Divider orientation="left">Coordonnées</Divider>}
          column={4}
        >
          <DescriptionsItem style={fontSizeLabel} label="Téléphone mobile">
            {phone_number_mobile}
          </DescriptionsItem>
          <DescriptionsItem
            style={fontSizeLabel}
            label="Téléphone professionnel"
          >
            {phone_number_office}
          </DescriptionsItem>
          <DescriptionsItem style={fontSizeLabel} label="Adresse mail">
            {email}
          </DescriptionsItem>
        </Descriptions>
      ),
      span: 4,
      fontWeight: 600
    },
    ...(type !== 'SUPPLIERS'
      ? [
          {
            label: '',
            content: (
              <Descriptions
                title={<Divider orientation="left">Facturation</Divider>}
                column={4}
              >
                <DescriptionsItem
                  style={fontSizeLabel}
                  label="Adresse de facturation"
                >
                  {billing_address_1}
                </DescriptionsItem>
                <DescriptionsItem
                  style={fontSizeLabel}
                  label="Complément d'adresse"
                >
                  {billing_address_2}
                </DescriptionsItem>
                <DescriptionsItem style={fontSizeLabel} label="Code postal">
                  {billing_postal_code}
                </DescriptionsItem>
                <DescriptionsItem style={fontSizeLabel} label="Ville">
                  {billing_city}
                </DescriptionsItem>
                <DescriptionsItem
                  style={fontSizeLabel}
                  label="En cours accordé par la comptabilité"
                >
                  {outstanding_amount}
                </DescriptionsItem>
                <DescriptionsItem
                  style={fontSizeLabel}
                  label="Numéro de compte client"
                >
                  {number_customer_account}
                </DescriptionsItem>
                <DescriptionsItem
                  span={1}
                  style={fontSizeLabel}
                  label="Mode de règlement"
                >
                  {payment_type}
                </DescriptionsItem>
                <DescriptionsItem
                  span={1}
                  style={fontSizeLabel}
                  label="Autre délai accordé"
                >
                  {other_time_granted}
                </DescriptionsItem>
                <DescriptionsItem
                  span={3}
                  style={fontSizeLabel}
                  label="Observations"
                >
                  {observations}
                </DescriptionsItem>

                <DescriptionsItem
                  span={1}
                  style={fontSizeLabel}
                  label="Retenue de garantie"
                >
                  {`${holdbacks} %`}
                </DescriptionsItem>
              </Descriptions>
            ),
            span: 4,
            fontWeight: 600
          }
        ]
      : [])
  ];
};

export default useListContent;
