import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import moment from 'moment';
import { companiesTypes } from '../../../utils/constants/tagColors';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('companies.form.name'),
      key: 'name',
      dataIndex: 'name',
      render: (text) => `${text}`,
      sorter: true
    },

    {
      title: t('companies.form.postal_code'),
      key: 'postal_code',
      dataIndex: 'postal_code',
      sorter: true
    },
    {
      title: t('companies.form.city'),
      key: 'city',
      dataIndex: 'city',
      sorter: true
    },
    {
      title: t('companies.form.phone_number_mobile'),
      key: 'phone_number_mobile',
      dataIndex: 'phone_number_mobile',
      sorter: true
    },

    {
      title: t('contacts.form.type'),
      key: 'professional',
      dataIndex: 'professional',
      render: (pro) =>
        pro === true ? (
          <Tag>{`${t('contacts.form.professional')}`}</Tag>
        ) : (
          <Tag>{`${t('contacts.form.individual')}`}</Tag>
        ),
      sorter: true,
      filters: [
        { text: t('contacts.form.individual'), value: false },
        { text: t('contacts.form.professional'), value: true }
      ]
    },
    {
      title: t('companies.form.created_at'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    }
  ];
};

export default useColumns;
