import { useTranslation } from 'react-i18next';

const useColumnsForDetailSite = () => {
  const { t } = useTranslation();

  const numAverage = (a) => {
    let b = 0;
    let i;
    for (i = 0; i < a.length; i += 1) {
      if (!Number.isNaN(parseFloat(a[i]))) {
        b += parseFloat(a[i].toString().replace(',', '.'));
      }
    }
    return b / a.length;
  };

  return [
    {
      title: t('sites.column.floor'),
      dataIndex: 'floor',
      key: 'floor'
    },
    {
      title: t('sites.column.area'),
      dataIndex: 'area',
      key: 'area',
      render: (area) => (area === undefined ? '-' : `${area} m²`)
    },
    {
      title: t('sites.column.thickness'),
      dataIndex: 'thickness',
      key: 'thickness',
      render: (thickness) =>
        thickness === undefined ? '-' : `${thickness.join(' / ')} cm`
    },
    {
      title: t('sites.column.average_thickness'),
      dataIndex: 'thickness',
      key: 'thickness',
      render: (thickness) =>
        thickness === undefined ? '-' : `${numAverage(thickness).toFixed(1)} cm`
    },
    {
      title: t('sites.column.type'),
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: t('sites.column.specifications'),
      children: [
        {
          title: t('sites.form.shower_location'),
          width: 180,
          dataIndex: 'specifications',
          render: (spe) => spe?.shower_location
        },
        {
          title: t('sites.form.upper_one_meter_location'),
          width: 180,
          dataIndex: 'specifications',
          render: (spe) => spe?.upper_one_meter_location
        },
        {
          title: t('sites.form.heating_floor'),
          width: 180,
          dataIndex: 'specifications',
          render: (spe) => spe?.heating_floor
        },
      ]
    }
  ];
};

export default useColumnsForDetailSite;
