import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Divider, message, Popconfirm, Skeleton, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import listContent from './ListContent';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import PreIntervention from './PreIntervention/PreIntervention';
import Steps from './PostIntervention/Steps';
import useMovementsContext from './PostIntervention/MovementsContext';
import ShowInvoicing from './Invoicing/ShowInvoicing';
import ShowHourControl from './HourControl/ShowHourControl';
import ShowQuotation from './Quotation/ShowQuotation';

const { TabPane } = Tabs;

const ShowMovement = () => {
  const { t } = useTranslation();
  const { id, key } = useParams();
  const [movement, setMovement] = useState({});
  const [movements, setMovements] = useState([]);
  const { dispatchAPI, user } = useAuthContext();
  const {
    activeKeyTab,
    setActiveKeyTab,
    getCstbs,
    CSTB,
    getMovement,
    getFilesCstb
  } = useMovementsContext();
  const [isLoading, setIsloading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [enums, setEnums] = useState([]);

  const history = useHistory();

  const fetchData = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/movements/${id}?populate=quotation,customer,works_supervisor,site,truck.users,CSTB`
      });
      setMovement(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, [id, refresh]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/schemas/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const fetchMovements = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/movements?populate=truck,quotation,site`
      });
      setMovements(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getEnums();
      await fetchData();
      await fetchMovements();
      await getMovement(id);
    })();
  }, [fetchData, fetchMovements]);

  useEffect(() => {
    (async () => {
      if (CSTB) await getCstbs();
    })();
  }, [CSTB]);

  useEffect(() => {
    if (key) setActiveKeyTab(key);
  }, [key]);

  const deleteMovement = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/movements/${id}` });
      history.push('/intervention/manage_interventions');
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };

  const onChange = (active) => {
    setActiveKeyTab(active);
    if (active === 3) getFilesCstb(`/files?site_id=${CSTB?.site?._id}`);
    if (active === 4) getFilesCstb(`/files?cstb_id=${CSTB?.CSTB?._id}`);
  };

  return (
    <>
      {user?.type !== 'TECH' && user?.type !== 'SELLER' ? (
        <PageHeaderCustom title={t(`movements.show.title`)} withSubRoutes />
      ) : null}

      <ContentCustom>
        <Tabs
          destroyInactiveTabPane
          onChange={(activeKey) => onChange(activeKey)}
          activeKey={activeKeyTab}
          type="card"
        >
          <TabPane tab="Intervention" key="1">
            {user?.type !== 'TECH' && user?.type !== 'SELLER' ? (
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Popconfirm
                  title="Êtes-vous sûr de vouloir supprimer cette intervention ？"
                  okText="Oui"
                  cancelText="Non"
                  onConfirm={() => deleteMovement()}
                  placement="left"
                >
                  <Button icon={<DeleteOutlined />} danger ghost>
                    {t(`Supprimer l'intervention`)}
                  </Button>
                </Popconfirm>
              </div>
            ) : null}

            <Divider />
            <Skeleton loading={isLoading} active>
              <DescriptionList
                data={listContent(movement, setRefresh, refresh, movements)}
                translate
              />
            </Skeleton>
          </TabPane>
          {user?.type !== 'TECH' ? (
            <TabPane tab="Devis" key="2">
              <ShowQuotation />
            </TabPane>
          ) : null}

          <TabPane tab="Visite technique" key="3">
            <PreIntervention enums={enums} resource="sites" />
          </TabPane>
          {user?.type !== 'ACCOUNTING' && (
            <TabPane disabled={!CSTB} tab="Fiche CSTB" key="4">
              <Steps
                enums={enums}
                cstbId={movement?.CSTB}
                fetchMovement={fetchData}
              />
            </TabPane>
          )}
          {user.type !== 'SELLER' && (
            <TabPane disabled={!CSTB} tab="Fiche des heures" key="5">
              <ShowHourControl key="5" dataId={movement} />
            </TabPane>
          )}
          {user?.type !== 'TECH' ? (
            <TabPane disabled={!CSTB} tab="Fiche récapitulative" key="6">
              <ShowInvoicing />
            </TabPane>
          ) : null}
        </Tabs>
      </ContentCustom>
    </>
  );
};

export default ShowMovement;
