import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import moment from 'moment';

const useListContent = ({
  label,
  code,
  buy_price,
  description,
  supplier,
  condition,
  ref_supplier,
  quantity_min,
  buying_date,
  commissioning_date,
  maintenance_date,
  maintenance
}) => {
  const { t } = useTranslation();

  return [
    {
      label: t('tools.form.code'),
      content: code,
      span: 3
    },
    {
      label: t('tools.form.label'),
      content: label,
      span: 3
    },
    {
      label: t('tools.form.condition'),
      content: condition && <Tag>{condition}</Tag>,
      span: 3
    },
    {
      label: t('tools.form.quantity_min'),
      content: quantity_min,
      span: 1
    },
    {
      label: t('tools.form.buy_price'),
      content: buy_price,
      span: 1
    },
    {
      label: t('tools.form.buying_date'),
      content: buying_date && moment(buying_date).format('DD/MM/YYYY'),
      span: 1
    },
    {
      label: t('tools.form.commissioning_date'),
      content: commissioning_date && moment(commissioning_date).format('DD/MM/YYYY'),
      span: 1
    },
    {
      label: t('tools.form.maintenance'),
      content: maintenance && maintenance === true ? 'OUI' : 'NON',
      span: 1
    },
    {
      label: t('tools.form.maintenance_date'),
      content: maintenance_date && moment(maintenance_date).format('DD/MM/YYYY'),
      span: 2
    },
    {
      label: t('tools.form.supplier'),
      content: supplier && (
        <Link to={`/accounting/suppliers/show/${supplier._id}`}>
          {supplier.name}
        </Link>
      ),
      span: 1
    },
    {
      label: t('tools.form.ref_supplier'),
      content: ref_supplier,
      span: 2
    },
    {
      label: t('tools.form.description'),
      content: description,
      span: 3
    }
  ];
};

export default useListContent;
