import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useListContent = ({
  ref_internal,
  label,
  type,
  buy_price,
  sold_price,
  tva,
  description,
  supplier,
  ref_supplier,
  unit,
  density
}) => {
  const { t } = useTranslation();

  return [
    {
      label: t('primary-matters.form.ref_internal'),
      content: ref_internal,
      span: 1
    },
    {
      label: t('primary-matters.form.label'),
      content: label,
      span: 1
    },
    {
      label: t('primary-matters.form.type'),
      content: type,
      span: 1
    },
    {
      label: t('primary-matters.form.supplier'),
      content: supplier && (
        <Link to={`/purchases/suppliers/show/${supplier._id}`}>
          {supplier.name}
        </Link>
      ),
      span: 1
    },
    {
      label: t('primary-matters.form.ref_supplier'),
      content: ref_supplier,
      span: 2
    },
    {
      label: t('primary-matters.form.buy_price'),
      content: `${buy_price} €`,
      span: 1
    },
    {
      label: t('primary-matters.form.sold_price'),
      content: `${sold_price} €`,
      span: 1
    },
    {
      label: t('articles.form.mark_up'),
      content:
        sold_price &&
        buy_price &&
        `${Math.round((sold_price / buy_price) * 100 - 100)} %`,
      span: 1
    },
    {
      label: t('primary-matters.form.tva'),
      content: tva && `${tva} %`,
      span: 1
    },
    {
      label: t('primary-matters.form.description'),
      content: description,
      span: 2
    },
    {
      label: t('primary-matters.form.unit'),
      content: unit ? unit?.label : null,
      span: 1
    },
    {
      label: t('primary-matters.form.density'),
      content: <span>{`${density || '-'}`}</span>,
      span: 1
    }
  ];
};

export default useListContent;
