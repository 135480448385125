import React, { useState, useEffect } from 'react';
import { Modal, Input, Row } from 'antd';
import PropTypes from 'prop-types';

import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

const { TextArea } = Input;

const UpdateComment = ({ data, visible, setVisible, getResource }) => {
  const [updateComment, setUpdateComment] = useState();
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const updatCommentInvoice = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `invoices/${data.key}`,
        body: { comment: updateComment }
      });
      getResource();
      setVisible(false);
      setUpdateComment();
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  useEffect(() => {
    if (data && data.comment) setUpdateComment(data.comment);
  }, [data]);

  const onChangeCommentInput = (event) => {
    setUpdateComment(event.target.value);
  };

  return (
    <Modal
      visible={visible}
      title={`Mettre à jour le commentaire pour la facture n° ${data?.number}`}
      destroyOnClose
      closable
      onOk={() => updatCommentInvoice()}
      onCancel={() => setVisible(!visible)}
    >
      <Row justify="center">
        <TextArea
          defaultValue={updateComment}
          onChange={(e) => onChangeCommentInput(e)}
        />
      </Row>
    </Modal>
  );
};

UpdateComment.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  getResource: PropTypes.func.isRequired
};
UpdateComment.defaultProps = {
  data: null
};

export default UpdateComment;
