import { Form, Divider, Switch, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import FormList from '../../../Commercial/Sites/formList';

const { TextArea } = Input;

const useFields = (enums) => {
  const { t } = useTranslation();
  const [hasElectricity, setHasElectricity] = useState(false);
  const [hasWater, setHasWater] = useState(false);
  const [isNewBuilding, setIsNewBuilding] = useState(false);
  const [isRefurbishedBuilding, setIsRefurbishedBuilding] = useState(false);

  const fields = [
    {
      colon: false,
      name: ['separate_2'],
      input: (
        <Divider orientation="left">{t(`sites.form.intervention`)}</Divider>
      )
    },
    {
      name: ['details'],
      rules: [{ required: true }],
      input: <Form.List name="details">{FormList(enums, 'sites')}</Form.List>
    },
    {
      name: ['key']
    },
    {
      name: ['electricity'],
      valuePropName: 'checked',
      input: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={hasElectricity}
          onClick={() => setHasElectricity(!hasElectricity)}
        />
      )
    },
    {
      name: ['water'],
      valuePropName: 'checked',
      input: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={hasWater}
          onClick={() => setHasWater(!hasWater)}
        />
      )
    },
    {
      name: ['new_building'],
      valuePropName: 'checked',
      input: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={isNewBuilding}
          onClick={() => setIsNewBuilding(!isNewBuilding)}
        />
      )
    },
    {
      name: ['refurbished_building'],
      valuePropName: 'checked',
      input: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={isRefurbishedBuilding}
          onClick={() => setIsRefurbishedBuilding(!isRefurbishedBuilding)}
        />
      )
    },
    {
      name: ['annotations'],
      input: <TextArea style={{ height: '100px' }} />
    }
  ];

  return { fields };
};

export default useFields;
