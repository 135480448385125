import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Modal, Select, Button, Spin, Col } from 'antd';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

const { Option } = Select;

const BankManagementModal = ({
  dataQuote,
  visible,
  setVisible,
  getResource
}) => {
  const { dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [banks, setBanks] = useState([]);
  const [bankToSetForQuote, setBankToSetForQuote] = useState();
  const [loadingSend, setLoadingSend] = useState(false);

  const fetchBanksList = useCallback(async () => {
    setLoadingSend(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bank-settings`
      });
      setBanks(data);
      setLoadingSend(false);
    } catch (e) {
      setLoadingSend(false);
      if (e.response) errorMessage(e.response.status);
    }
  }, [dataQuote]);
  const updateQuotationBankAccount = async () => {
    setLoadingSend(true);
    try {
      await dispatchAPI('PATCH', {
        url: `/quotations/${dataQuote?._id}`,
        body: { bank_account: bankToSetForQuote }
      });
      setVisible(!visible);
      getResource();
      setLoadingSend(false);
    } catch (e) {
      setLoadingSend(false);
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    if (dataQuote && dataQuote._id) fetchBanksList();
  }, [dataQuote]);
  return (
    <Modal
      title={`Rattacher une banque au devis n° ${dataQuote?.number}`}
      centered
      visible={visible}
      destroyOnClose
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Annuler
        </Button>,
        bankToSetForQuote && (
          <Button
            key="submit"
            type="primary"
            onClick={updateQuotationBankAccount}
          >
            Joindre
          </Button>
        )
      ]}
    >
      <Spin tip="Chargement..." spinning={loadingSend}>
        {/* <Row justify="center"> */}
        <Col style={{ textAlign: 'center', marginBottom: 20 }} span={24}>
          <Select
            showSearch
            optionFilterProp="children"
            defaultValue={dataQuote?.bank_account?._id}
            placeholder="Choisir une banque..."
            style={{ width: 200 }}
            onChange={(value) => setBankToSetForQuote(value)}
          >
            {banks.length > 0 &&
              banks?.map((bank) => (
                <Option key={bank._id} value={bank._id}>
                  {bank.name || 'Inconnu'}
                </Option>
              ))}
          </Select>
        </Col>

        {bankToSetForQuote && (
          <Col span={24} style={{ textAlign: 'center' }}>
            <p>
              {banks.filter((el) => el._id === bankToSetForQuote)[0].address}
            </p>
            <p>{banks.filter((el) => el._id === bankToSetForQuote)[0].zip}</p>
            <p>{banks.filter((el) => el._id === bankToSetForQuote)[0].city}</p>
            <p>
              {banks
                .filter((el) => el._id === bankToSetForQuote)[0]
                .bank_rib.replace(/.(?=.{4,}$)/g, '#')}
            </p>
          </Col>
        )}
        {/* </Row> */}
      </Spin>
    </Modal>
  );
};

BankManagementModal.propTypes = {
  dataQuote: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  getResource: PropTypes.func.isRequired
};
BankManagementModal.defaultProps = {
  dataQuote: null
};

export default BankManagementModal;
