import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Banks from './Banks';
import CreateUpdateBank from './CreateUpdateBank';
import ShowBank from './ShowBank';

const BankRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateBank purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateBank purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowBank} />
      <Route path={path} component={Banks} />
    </Switch>
  );
};

export default BankRouter;
