import React from 'react';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';

const ListEPIs = () => {
  return (
    <SimpleResourceLandingLayout
      resourceName="epis"
      columns={[...columns()]}
      populate="family,company,unit"
      withSubRoutes
      withUploadButton={false}
      resourceModelName="EPI"
      searchPlaceHolder="Entrer un Code ou Nom"
    />
  );
};

export default ListEPIs;
