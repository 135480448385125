import React, { createContext, useContext, useState } from 'react';
import { Form } from 'antd';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useAuthContext from '../../../../contexts/AuthContext';

const MovementContext = createContext({});

export const MovementContextProvider = ({ children }) => {
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [CSTB, setCSTB] = useState();
  const [form] = Form.useForm();
  const [pictures, setPictures] = useState([]);
  const [coringHeavy, setCoringHeavy] = useState(null);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(0);
  const [range, setRange] = useState([]);
  const [valuesState, setValuesState] = useState();
  const [activeKeyTab, setActiveKeyTab] = useState('1');
  const [startHeavy, setStartHeavy] = useState(0);
  const [mediumHeavy, setMediumHeavy] = useState(0);
  const [endHeavy, setEndHeavy] = useState(0);
  const [cstblist, setCstbList] = useState([]);
  const [manager, setManager] = useState();
  const [batch, setBatch] = useState();
  const [managers, setManagers] = useState([]);
  const [visiblePic, setVisiblePic] = useState(false);
  const [picToDisplay, setPicToDisplay] = useState();

  const config = {
    onGetResource: {
      setFields: (data) => {
        return {
          ...data,
          details:
            data.details.length === 0
              ? CSTB?.site?.details.map((e) => {
                  return { floor: e.floor, type: e.type };
                })
              : data.details
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        return {
          ...data
        };
      }
    }
  };

  const sendMail = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `/cstb/mail`,
        body
      });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getMovement = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/movements/${id}?populate=truck.deposit,customer,quotation,site,CSTB,technicians,works_supervisor`
      });
      setCSTB(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getDeposit = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deposits/${id}?populate=manager`
      });
      setManager(data);
    } catch (e) {
      if (e.response && e.response.data.name === 'CastError') {
        errorMessage("Le Dépôt d'espece est manquant.");
      }
      errorMessage(e.response.status);
    }
  };

  const getCstbs = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/cstb?quotation_id=${CSTB?.quotation?._id}`
      });
      setCstbList(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const updateResource = async (body, cstbId) => {
    let bodyControl = null;
    if (body && body.fileList && body.fileList.fileList !== undefined) {
      const files = [];
      body.fileList.fileList.map((e) => files.push(e.response));
      bodyControl = {
        control: { quality: body.control.quality, files }
      };
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/cstb/${cstbId}`,
        body: bodyControl !== null ? { ...bodyControl } : { ...body }
      });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  const getFilesCstb = async (url) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url
      });
      setPictures(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  const deleteFiles = async (id, url) => {
    try {
      await dispatchAPI('DELETE', {
        url
      });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  const getManagerForToMail = async (truckId) => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users?type=MANAGER' });
      const result = data
        .filter(
          (contactMail) =>
            contactMail.managed_trucks &&
            contactMail.managed_trucks.includes(truckId)
        )
        .map((elementMail) => ({
          name: `${elementMail?.first_name} ${elementMail?.last_name}`,
          email: elementMail?.email
        }));
      setManagers(result);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getFilesToDisplay = async (url, typeFile) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url,
        responseType: 'arraybuffer'
      });
      setVisiblePic(false);
      const bufferArray = new Uint8Array(data);
      const blob = new Blob([bufferArray], {
        type: typeFile
      });
      const urlModal = window.URL.createObjectURL(blob);
      window.open(urlModal);
      return true;
    } catch (e) {
      if (e.response) return errorMessage(e.response.status);
    }
    return false;
  };
  const funcPicToDisplay = async (idPic, type) => {
    setVisiblePic(true);
    const dataPic = await getFilesToDisplay(`/files/${idPic}`, type);
    setPicToDisplay(dataPic);
  };
  return (
    <MovementContext.Provider
      value={{
        updateResource,
        CSTB,
        form,
        setCSTB,
        config,
        deleteFiles,
        pictures,
        setPictures,
        sendMail,
        coringHeavy,
        setCoringHeavy,
        visible,
        setVisible,
        current,
        setCurrent,
        valuesState,
        setValuesState,
        activeKeyTab,
        setActiveKeyTab,
        setStartHeavy,
        startHeavy,
        setMediumHeavy,
        mediumHeavy,
        setEndHeavy,
        endHeavy,
        range,
        setRange,
        getCstbs,
        cstblist,
        setCstbList,
        getMovement,
        getDeposit,
        manager,
        setBatch,
        batch,
        managers,
        getManagerForToMail,
        getFilesToDisplay,
        getFilesCstb,
        visiblePic,
        setVisiblePic,
        funcPicToDisplay,
        picToDisplay
      }}
    >
      {children}
    </MovementContext.Provider>
  );
};

export default () => useContext(MovementContext);
