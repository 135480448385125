import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Contacts from './ListContacts';
import ShowContact from './ShowContact';
import CreateUpdateContact from './CreateUpdateContact';

const ContactRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateContact purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateContact purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowContact} />
      <Route path={`${path}`} component={Contacts} />
    </Switch>
  );
};

export default ContactRouter;
