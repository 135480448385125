/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
  Typography,
  Radio,
  Modal,
  message,
  Tooltip
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { red } from '@ant-design/colors';
import moment from 'moment';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useColumns from '../../Configuration/Prestations/columns';
import useQuotationContext from './QuotationContext';
import useAuthContext from '../../../contexts/AuthContext';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const QuotationForm = ({
  handleSubmit,
  resource,
  initialValues,
  fields1,
  fields2,
  headerFields,
  contact,
  options,
  prestations,
  settings,
  prestationsAvailable,
  addPrestation,
  deletePrestation,
  isLoading,
  isModalVisible,
  setIsModalVisible
}) => {
  const history = useHistory();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const generateFields = useGenerateFormItem();
  const { idSite } = useParams();
  const [form] = Form.useForm();
  const [displayDiscount, setDiscountDisplay] = useState(
    initialValues.global_discount_value
      ? initialValues.global_discount_value > 0
      : false
  );
  const [
    typeOfSupportForPrestations,
    setTypeOfSupportForPrestations
  ] = useState();
  const { columnQuotationPrestation } = useColumns();
  const [displayOptions, setOptionsDisplay] = useState(false);
  const [displayLines, setLinesDisplay] = useState(true);
  const [prestationSearch, setPrestationSearch] = useState('');
  const [displayedDiscounts, setDiscountsDisplay] = useState([]);
  const [lineSubtotals, setSubtotals] = useState([]);
  const [totalMode, setMode] = useState('HT');
  const [total, setTotal] = useState(0);
  const { vat, setVat, vats } = useQuotationContext();

  const onInputChange = () => {
    const tmpPrestations = Object.values(form.getFieldValue('prestations'));
    const globalDiscount = form.getFieldValue('global_discount_value');
    const discountType = form.getFieldValue('global_discount_type');
    const vatSelected = form.getFieldValue('vat');
    const tmpSubtotals = [...lineSubtotals];
    let calculatedGlobalDiscount = 0;
    let subTotal = 0;
    let total_VAT = 0;
    let total_discount = 0;
    let total_area = 0;

    tmpPrestations.forEach(
      (
        { area, unit_price, discount, discount_type, subtotal, prestation },
        index
      ) => {
        const { forfait_price } =
          prestations?.find((el) => el.prestationId === prestation) || {};
        let tmpDiscount = 0;
        if (!tmpSubtotals[index]) {
          tmpSubtotals.push(subtotal);
        }
        const price = area <= 60 ? forfait_price : unit_price * area;
        total_area += area;

        if (discount) {
          tmpDiscount =
            discount_type === 'CURRENCY' ? discount : (price * discount) / 100;
          total_discount += tmpDiscount;
        }
        subTotal += price - tmpDiscount;
      }
    );

    if (globalDiscount && discountType) {
      calculatedGlobalDiscount =
        discountType === 'CURRENCY'
          ? globalDiscount
          : (subTotal * globalDiscount) / 100;
      total_discount += calculatedGlobalDiscount;
    }
    total_VAT =
      (subTotal - calculatedGlobalDiscount) *
      (vats.filter((ele) => ele._id === vatSelected)[0]?.rate / 100);
    const finalTotal = subTotal - calculatedGlobalDiscount + total_VAT;

    form.setFieldsValue({
      total_area,
      subTotal: parseFloat(
        totalMode === 'HT'
          ? subTotal - calculatedGlobalDiscount
          : subTotal - calculatedGlobalDiscount + total_VAT
      ).toFixed(2),
      total_discount: parseFloat(total_discount).toFixed(2),
      total_VAT: parseFloat(total_VAT).toFixed(2),
      total: parseFloat(finalTotal).toFixed(2)
    });
    setSubtotals(tmpSubtotals);
    setTotal(
      totalMode === 'HT'
        ? subTotal - calculatedGlobalDiscount
        : subTotal - calculatedGlobalDiscount + total_VAT
    );
  };

  const onShowDiscount = (prestationId) => {
    const tempDiscounts = [...displayedDiscounts];

    if (tempDiscounts.includes(prestationId)) {
      const idx = tempDiscounts.indexOf(prestationId);
      tempDiscounts.splice(idx, 1);
      form.setFieldsValue({
        prestations: {
          [prestationId]: {
            ...prestations[prestationId],
            discount: 0
          }
        }
      });
    } else {
      tempDiscounts.push(prestationId);
    }

    onInputChange();
    setDiscountsDisplay(tempDiscounts);
  };

  const onValuesChange = (changedValues, values) => {
    if (values.emission_date) {
      const settingLabel =
        resource === 'quotations'
          ? 'default_quotation_deadline'
          : 'default_invoice_deadline';
      form.setFieldsValue({
        deadline_date: moment(values.emission_date).add(
          parseInt(
            settings.find((setting) => setting.label === settingLabel).value,
            10
          ),
          'days'
        )
      });
    }
    if (changedValues.prestations) {
      const [formatedValue] = Object.entries(changedValues.prestations);
      if (formatedValue[1].area) {
        const value = prestations.find(
          (e) => e.prestationId === formatedValue[0]
        );
        form.setFieldsValue({
          prestations: {
            [formatedValue[0]]: {
              unit_price: value.unit_price
            }
          }
        });
      }
    }
    if (values.vat) {
      setVat(values.vat);
      form.setFieldsValue({
        vat: values.vat
      });
    }
  };

  const handleDeletePrestation = (key) => {
    deletePrestation(key);
    form.setFieldsValue({
      prestations: {
        [key]: { label: '', area: 0, unit_price: 0, forfait_price: 0 }
      }
    });
  };

  const updatePrestationSubtotal = (prestationId, index) => {
    const price = form.getFieldValue([
      'prestations',
      prestationId,
      'unit_price'
    ]);
    const area = form.getFieldValue(['prestations', prestationId, 'area']);
    const { forfait_price } = prestations.find(
      (el) => el.prestationId === prestationId
    );

    const tmpSubtotal = area > 60 ? price * area : forfait_price;

    let newDiscount = form.getFieldValue([
      'prestations',
      prestationId,
      'discount'
    ]);
    const discountType = form.getFieldValue([
      'prestations',
      prestationId,
      'discount_type'
    ]);

    if (!newDiscount) {
      newDiscount = 0;
    }

    const newSubtotal =
      tmpSubtotal -
      (discountType === 'CURRENCY'
        ? newDiscount
        : (newDiscount * tmpSubtotal) / 100);

    form.setFieldsValue({
      prestations: { [prestationId]: { subtotal: newSubtotal } }
    });

    const tmpSubtotals = [...lineSubtotals];
    tmpSubtotals[index] = newSubtotal;
    setSubtotals(tmpSubtotals);
  };
  useEffect(() => {
    if (initialValues) {
      form.setFieldsValue({
        organization: user.organization.name,
        site: initialValues?.site
      });
    }
  }, [initialValues]);
  useEffect(() => {
    if (idSite) {
      form.setFieldsValue({ site: idSite });
    }
  }, [idSite]);

  useEffect(() => {
    if (prestations.length > 0) {
      prestations.forEach(({ prestationId }, index) => {
        updatePrestationSubtotal(prestationId, index);
      });
      onInputChange();
    }
  }, [prestations]);

  useEffect(() => {
    if (displayedDiscounts.length > 0) {
      onInputChange();
    }
  }, [displayedDiscounts]);

  const displayErrorsFields = (errors) => {
    if (errors && errors.length > 0) {
      errors.forEach((error) => {
        if (error.name[0] !== 'prestations')
          message.error(
            `${t(`quotations.form.${error.name[0]}`)} : ${error.errors[0]}`
          );
        else
          message.error(
            `${t(`quotations.form.${error.name[0]}.${error.name[2]}`)} : ${
              error.errors[0]
            }`
          );
      });
    }
  };

  const removeDuplicates = (arr) => {
    return [...new Set(arr)];
  };
  return (
    <Spin spinning={isLoading}>
      <Form
        {...formItemLayout}
        name="invoicesForm"
        onFinish={(values) =>
          handleSubmit({ ...values, order: initialValues.order })
        }
        initialValues={initialValues}
        form={form}
        onValuesChange={onValuesChange}
        scrollToFirstError
        onFinishFailed={({ errorFields }) => displayErrorsFields(errorFields)}
      >
        <Row>
          <Col span={8} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.heading`)}</Title>
          </Col>
          <Col span={24}>
            {resource === 'quotations'
              ? headerFields.map((field, name) =>
                  generateFields('quotations', field, name)
                )
              : null}
          </Col>
        </Row>

        <Row>
          <Col span={8} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.body`)}</Title>
          </Col>

          <Col span={24}>
            {fields1.map((field) => generateFields(resource, field))}
          </Col>
        </Row>

        <Row style={{ marginTop: '20px' }}>
          <Col span={6} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.lines`)}</Title>
          </Col>

          <Col span={2} style={{ textAlign: 'right' }}>
            <Button type="link" onClick={() => setLinesDisplay(!displayLines)}>
              {displayLines
                ? t(`${resource}.form.title.hide`)
                : t(`${resource}.form.title.display`)}
              {displayLines ? (
                <UpOutlined style={{ marginLeft: 10 }} />
              ) : (
                <DownOutlined style={{ marginLeft: 10 }} />
              )}
            </Button>
          </Col>
          <Col span={24}>
            {displayLines &&
              prestations.map(
                (
                  {
                    label,
                    area,
                    thickness,
                    unit_price,
                    forfait_price,
                    subtotal,
                    discount,
                    discount_type,
                    prestationId,
                    comment
                  },
                  index
                ) => (
                  <Row
                    key={prestationId}
                    style={{
                      border: '1px solid var(--borderColor)',
                      padding: '10px',
                      marginTop: 8
                    }}
                  >
                    <Form.Item
                      name={['prestations', prestationId, 'prestation']}
                      initialValue={prestationId}
                      hidden
                    >
                      <Input disabled />
                    </Form.Item>

                    <Col span={24} style={{ marginTop: '16px' }}>
                      <Button
                        style={{
                          position: 'absolute',
                          top: 6,
                          right: 14,
                          zIndex: 10
                        }}
                        type="link"
                        size="small"
                        icon={
                          <DeleteOutlined
                            style={{ color: red.primary, fontSize: 16 }}
                          />
                        }
                        onClick={() => handleDeletePrestation(prestationId)}
                      />
                    </Col>

                    <Col span={8} offset={2} style={{ paddingRight: '10px' }}>
                      {t('invoices.form.prestations.name')}
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      <div style={{ marginTop: 4 }}>
                        <span style={{ color: 'var(--secondaryColor)' }}>
                          *
                        </span>
                        &nbsp;
                        {t('invoices.form.prestations.quantity')}
                      </div>
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      {t('invoices.form.prestations.unit_price')}
                      <Tooltip title={`Prix du forfait: ${forfait_price}`}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Col>
                    <Col span={3} style={{ paddingRight: '10px' }}>
                      <Button
                        type="link"
                        onClick={() => onShowDiscount(prestationId, index)}
                      >
                        {t('invoices.form.prestations.discount')}
                      </Button>
                    </Col>
                    <Col
                      span={6}
                      style={{ paddingRight: '10px', textAlign: 'center' }}
                    >
                      <strong>{t('invoices.form.prestations.subtotal')}</strong>
                    </Col>
                    <Col span={8} offset={2} style={{ paddingRight: '10px' }}>
                      <Form.Item
                        name={['prestations', prestationId, 'label']}
                        initialValue={label}
                      >
                        <Input disabled style={{ width: '300%' }} />
                      </Form.Item>
                      <Form.Item
                        hidden
                        name={['prestations', prestationId, 'thickness']}
                        initialValue={thickness}
                      >
                        <Input disabled style={{ width: '300%' }} />
                      </Form.Item>
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      <Form.Item
                        name={['prestations', prestationId, 'area']}
                        initialValue={area || 0}
                        rules={[
                          {
                            required: true,
                            message: 'La surface doit être définie'
                          }
                        ]}
                      >
                        <InputNumber
                          onChange={() => {
                            onInputChange();
                            updatePrestationSubtotal(prestationId, index);
                          }}
                          style={{ width: '300%' }}
                          min={0}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={2} style={{ paddingRight: '10px' }}>
                      <Form.Item
                        name={['prestations', prestationId, 'unit_price']}
                        initialValue={unit_price || 0}
                        rules={[
                          {
                            required: true,
                            message: 'Le prix doit être défini'
                          }
                        ]}
                      >
                        <InputNumber
                          formatter={(value) => value && `${value}€`}
                          parser={(value) => value.replace('€', '')}
                          onChange={() => {
                            onInputChange();
                            updatePrestationSubtotal(prestationId, index);
                          }}
                          style={{ width: '300%' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={3} style={{ paddingRight: '10px' }}>
                      {displayedDiscounts.includes(prestationId) && (
                        <Input.Group compact>
                          <Form.Item
                            name={['prestations', prestationId, 'discount']}
                            initialValue={discount || 0}
                          >
                            <InputNumber
                              onChange={() => {
                                onInputChange();
                                updatePrestationSubtotal(prestationId, index);
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            name={[
                              'prestations',
                              prestationId,
                              'discount_type'
                            ]}
                            initialValue={discount_type || 'CURRENCY'}
                          >
                            <Select
                              options={[
                                {
                                  label: '€ HT',
                                  value: 'CURRENCY'
                                },
                                {
                                  label: '%',
                                  value: 'PERCENT'
                                }
                              ]}
                              onChange={onInputChange}
                              style={{ width: '80px' }}
                            />
                          </Form.Item>
                        </Input.Group>
                      )}
                    </Col>
                    <Col
                      span={6}
                      style={{ paddingRight: '10px', justifyContent: 'center' }}
                    >
                      <Title
                        level={3}
                        style={{ width: '100%', textAlign: 'center' }}
                      >
                        {`${
                          lineSubtotals[index]
                            ? lineSubtotals[index].toFixed(2)
                            : 0.0
                        }€`}
                      </Title>
                      <Form.Item
                        name={['prestations', prestationId, 'subtotal']}
                        initialValue={subtotal || 0}
                        style={{ paddingRight: '10px', textAlign: 'center' }}
                        // span={4}
                      >
                        <InputNumber
                          formatter={(value) => value && `${value}€`}
                          disabled
                          style={{ visibility: 'hidden' }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        name={['prestations', prestationId, 'comment']}
                        initialValue={comment || ''}
                        label={t(`${resource}.form.prestations.comment`)}
                      >
                        <TextArea />
                      </Form.Item>
                    </Col>
                  </Row>
                )
              )}

            {displayLines && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: 20
                  }}
                >
                  <Radio.Group
                    onChange={(e) =>
                      setTypeOfSupportForPrestations(e.target.value)
                    }
                  >
                    {removeDuplicates(
                      prestationsAvailable.map((presta) => presta.support)
                    ).map((ele) => (
                      <Radio value={ele}>{ele}</Radio>
                    ))}
                  </Radio.Group>
                </div>

                <Form.Item
                  style={{
                    background: 'var(--bodyBackground)',
                    border: '1px solid var(--borderColor)',
                    marginTop: 8,
                    padding: '1%'
                  }}
                  {...formItemLayout}
                  wrapperCol={16}
                  label={t(`${resource}.form.prestations.select`)}
                >
                  <Input.Group>
                    <Form.Item rules={[{ required: true }]} noStyle>
                      <Select
                        value={prestationSearch}
                        loading={isLoading}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        onSelect={async (id) => {
                          await addPrestation(id);
                          setPrestationSearch('');
                        }}
                        onChange={setPrestationSearch}
                        style={{ width: '70%', marginRight: '1%' }}
                        disabled={
                          (resource === 'quotations' && contact === null) ||
                          vat === undefined
                        }
                      >
                        {prestationsAvailable.map(
                          ({ reference, label, _id, support }) =>
                            typeOfSupportForPrestations &&
                            support === typeOfSupportForPrestations && (
                              <Option key={_id} value={_id}>
                                {`${reference} - ${label}`}
                              </Option>
                            )
                        )}
                      </Select>
                    </Form.Item>
                    <Form.Item noStyle>
                      <Button
                        onClick={() => setIsModalVisible(true)}
                        disabled={
                          (resource === 'quotations' && contact === null) ||
                          vat === undefined
                        }
                      >
                        {`${t('buttons.search')}`}
                      </Button>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>
                <Modal
                  title={t(`${resource}.form.prestations.add_prestation`)}
                  visible={isModalVisible}
                  onOk={() => setIsModalVisible(false)}
                  onCancel={() => setIsModalVisible(false)}
                  width={1000}
                  cancelText={`${t('buttons.cancel')}`}
                  okText={`${t('buttons.add')}`}
                >
                  <SimpleResourceLandingLayout
                    resourceName="prestations"
                    columns={columnQuotationPrestation}
                    resourceModelName="Prestation"
                    withSubRoutes
                    withHead={false}
                    withUploadButton={false}
                    withCreateButton={false}
                    customActionColumn
                    selectRow={(key) => addPrestation(key)}
                  />
                </Modal>
              </>
            )}
          </Col>
        </Row>

        <Row style={{ marginTop: '20px' }}>
          <Col span={6} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.discount`)}</Title>
          </Col>
          <Col span={2} style={{ textAlign: 'right' }}>
            <Button
              type="link"
              onClick={() => setDiscountDisplay(!displayDiscount)}
            >
              {displayDiscount
                ? t('invoices.form.title.hide')
                : t('invoices.form.title.display')}
              {displayDiscount ? (
                <UpOutlined style={{ marginLeft: 10 }} />
              ) : (
                <DownOutlined style={{ marginLeft: 10 }} />
              )}
            </Button>
          </Col>
          <Col span={24}>
            <Row style={{ marginBottom: '16px' }}>
              {displayDiscount && (
                <Col span={24}>
                  <Form.Item
                    label={t(`${resource}.form.global_discount.value`)}
                  >
                    <Input.Group compact>
                      <Form.Item
                        name={['global_discount_value']}
                        initialValue={0}
                      >
                        <InputNumber
                          onChange={() => {
                            onInputChange();
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name={['global_discount_type']}
                        initialValue="CURRENCY"
                      >
                        <Select
                          options={[
                            {
                              label: '€ HT',
                              value: 'CURRENCY'
                            },
                            {
                              label: '%',
                              value: 'PERCENT'
                            }
                          ]}
                          style={{ width: '80px' }}
                          onChange={() => {
                            onInputChange();
                          }}
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                  <Form.Item
                    label={t(`${resource}.form.global_discount.comment`)}
                    name={['global_discount_comment']}
                  >
                    <TextArea />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Col>
        </Row>

        {resource === 'invoices' && (
          <Row style={{ marginTop: '20px' }}>
            <Col span={6} offset={8}>
              <Title level={3}>{t(`${resource}.form.title.options`)}</Title>
            </Col>
            <Col span={2} style={{ textAlign: 'right' }}>
              <Button
                type="link"
                onClick={() => setOptionsDisplay(!displayOptions)}
              >
                {displayOptions
                  ? t('invoices.form.title.hide')
                  : t('invoices.form.title.display')}
                {displayOptions ? (
                  <UpOutlined style={{ marginLeft: 10 }} />
                ) : (
                  <DownOutlined style={{ marginLeft: 10 }} />
                )}
              </Button>
            </Col>
            <Col span={24}>
              {displayOptions &&
                options.map((field, name) =>
                  generateFields(resource, field, name)
                )}
            </Col>
          </Row>
        )}

        <Row
          style={{
            background: 'var(--bodyBackground)',
            border: '1px solid var(--borderColor)',
            marginTop: '20px',
            marginBottom: '35px',
            padding: '10px'
          }}
        >
          <Col span={6} offset={8}>
            <Title level={3}>{t(`${resource}.form.title.recap`)}</Title>
          </Col>
          <Col span={24}>
            {fields2.map((field) => generateFields(resource, field))}
          </Col>
        </Row>

        <Row>
          <Col
            span={2}
            offset={8}
            onClick={() => {
              setTotal(
                totalMode === 'HT'
                  ? form.getFieldValue('total')
                  : form.getFieldValue('subTotal')
              );
              setMode(totalMode === 'HT' ? 'TTC' : 'HT');
            }}
            style={{ cursor: 'pointer', textAlign: 'center' }}
          >
            <strong>{t(`${resource}.form.total`)}</strong>
            <Title level={3} style={{ margin: 0 }}>
              {`${parseFloat(total).toFixed(2)}€`}
            </Title>
            <Button type="link">{totalMode}</Button>
          </Col>
          <Col span={4} offset={2}>
            <Button
              style={{ float: 'right' }}
              type="add"
              htmlType="submit"
              disabled={!(prestations.length > 0)}
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
            <Button
              style={{ float: 'right' }}
              type="link"
              danger
              onClick={() => history.goBack()}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
};

QuotationForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  initialValues: PropTypes.shape({
    order: PropTypes.shape({}),
    global_discount_value: PropTypes.number,
    site: PropTypes.shape({})
  }),
  fields1: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fields2: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  headerFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prestationsAvailable: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prestations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  addPrestation: PropTypes.func.isRequired,
  deletePrestation: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  settings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  resource: PropTypes.string.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
  setIsModalVisible: PropTypes.func.isRequired,
  contact: PropTypes.shape({}),
  options: PropTypes.arrayOf(PropTypes.shape({})),
  enums: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired
};

QuotationForm.defaultProps = {
  initialValues: null,
  contact: null,
  options: undefined
};

export default QuotationForm;
