import moment from 'moment';
import { Tag } from 'antd';
import React, { useEffect, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [companiesFilter, setCompaniesFilter] = useState(null);

  const fetchCompanies = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'companies' });
      setCompaniesFilter(
        data.map(({ name, _id }) => ({ text: name, value: _id }))
      );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchCompanies();
    })();
  }, [fetchCompanies]);
  return [
    {
      title: t('unpaid.list.column.title.commercial'),
      dataIndex: 'site',
      render: (site) =>
        `${site?.seller?.first_name || ''} ${site?.seller?.last_name || ''}`,
      sorter: true,
      width: 150
    },
    {
      title: t('unpaid.list.column.title.customer'),
      dataIndex: 'customer',
      render: (customer) => `${customer.name || ''}`,
      sorter: true,
      width: 150,
      filterSearch: true,
      filters: companiesFilter
    },

    {
      title: t('unpaid.list.column.title.site'),
      dataIndex: 'site',
      render: (site) => site.reference,
      sorter: true,
      width: 200
    },
    {
      title: t('unpaid.list.column.title.number'),
      dataIndex: 'number',
      render: (number) => number,
      sorter: true,
      width: 150
    },
    {
      title: t('unpaid.list.column.title.unpaid_since'),
      dataIndex: 'deadline_date',
      key: 'deadline_date',
      render: (deadline_date) => (
        <Tag color="red">{moment(deadline_date).format('DD/MM/YYYY')}</Tag>
      ),
      sorter: true,
      width: 200
    },
    {
      title: t('unpaid.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      key: 'emission_date',
      render: (emission_date) => (
        <Tag>{moment(emission_date).format('DD/MM/YYYY')}</Tag>
      ),
      sorter: true,
      width: 150
    },
    {
      title: t('unpaid.list.column.title.total'),
      dataIndex: 'total',
      render: (total) => `${total.toFixed(2)} €`,
      sorter: true,
      width: 150
    },
    {
      title: t('unpaid.list.column.title.holdback'),
      dataIndex: 'holdback',
      render: (holdback) => `${holdback} %`,
      sorter: true,
      width: 200
    },
    {
      title: t('unpaid.list.column.title.net_payable'),
      dataIndex: 'total',
      render: (_, record) => `${record.subTotal + record.total_VAT} €`,
      sorter: true,
      width: 150
    },
    {
      title: t('unpaid.list.column.title.deposit'),
      dataIndex: 'type',
      render: (type) => (type === 'DEPOSIT' ? <Tag>Oui</Tag> : <Tag>Non</Tag>),
      sorter: true,
      width: 100
    },

    {
      title: t('unpaid.list.column.title.comment'),
      dataIndex: 'comment',
      render: (comment) => (
        <div style={{ whiteSpace: 'pre-wrap' }}>{comment}</div>
      ),
      width: 300
    },
    {
      title: t('unpaid.list.column.title.type'),
      dataIndex: 'customer',
      render: (customer) =>
        customer.professional ? <Tag>Oui</Tag> : <Tag>Non</Tag>,
      sorter: true,
      width: 150
    },
    {
      title: t('unpaid.list.column.title.phone'),
      dataIndex: 'contact',
      render: (contact) => contact.phone_number_mobile,
      sorter: true,
      width: 150
    }
  ];
};
export default useColumns;
