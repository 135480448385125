import React, {
  useState,
  useEffect,
  createContext,
  useCallback,
  useContext
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useHistory, useLocation } from 'react-router-dom';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

export const DealsContext = createContext({});

export const DealsContextProvider = ({ children }) => {
  const history = useHistory();
  const { pathname } = useLocation();
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [visible, setVisible] = useState(false);
  const [deals, setDeals] = useState([]);
  const [deal, setCurrentDeal] = useState(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const [searchValue, setSearchValue] = useState(params.get('k'));
  const [kanbanCols, setKanbanCols] = useState([]);
  const [dataRefresh, setDataRefresh] = useState(false);
  const [users, setUsers] = useState([]);

  const fetchDealById = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals/${id}?populate=lead,status,companies,activities.author`
      });
      setCurrentDeal(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?k=${value}`
      });
    } else {
      history.push({
        pathname
      });
    }
  };

  useEffect(() => {
    setSearchValue(params.get('k'));
  }, [params]);

  const fetchData = useCallback(async () => {
    const searchUrl = searchValue
      ? `filter={"$text": {"$search":"${searchValue}"}}&`
      : null;
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals?${searchUrl || ''}&populate=lead`
      });
      const listSorted = data.sort((a, b) => {
        const statusOrder = ['HIGH', 'URGENT', 'NORMAL', 'LOW'];
        const aStatusIndex = statusOrder.indexOf(a.priority);
        const bStatusIndex = statusOrder.indexOf(b.priority);
        return aStatusIndex - bStatusIndex;
      });

      setDeals(listSorted);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  }, [searchValue, visible]);

  const delDeal = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `deals/${id}` });
      await fetchData();
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const updateDeal = async (id, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/deals/${id}`, body });
      await fetchData();
      setDataRefresh(!dataRefresh);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const fetchKanbanColumns = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users' });
      setUsers(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const fetch = useCallback(async () => {
    await fetchKanbanColumns();
    await getUsers();
  }, []);

  useEffect(() => {
    fetch();
  }, [fetch]);

  return (
    <DndProvider backend={HTML5Backend}>
      <DealsContext.Provider
        value={{
          fetchData,
          delDeal,
          updateDeal,
          deals,
          deal,
          setCurrentDeal,
          searchResource,
          searchValue,
          visible,
          setVisible,
          kanbanCols,
          fetchKanbanColumns,
          dataRefresh,
          setDataRefresh,
          users,
          fetchDealById
        }}
      >
        {children}
      </DealsContext.Provider>
    </DndProvider>
  );
};

export default () => useContext(DealsContext);
