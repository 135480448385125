import React from 'react';
import { Form, Input } from 'antd';
import { useTranslation } from 'react-i18next';

const useGenerateFormItem = () => {
  const { t } = useTranslation();

  return (
    resource,
    {
      label,
      name,
      initialValue,
      dependencies,
      hasFeedback,
      rules,
      input,
      layout,
      noLabel,
      valuePropName,
      translate = true,
      colon = true,
      shouldUpdate = false,
      help,
      validateStatus,
      style,
      hidden
    }
  ) => (
    <Form.Item
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...layout}
      key={name}
      name={name}
      initialValue={initialValue}
      dependencies={dependencies}
      style={style}
      label={
        !noLabel &&
        `${
          translate
            ? t(`${resource}.form.${label || name.join('.')}`)
            : `${label || name}`
        }`
      }
      hasFeedback={hasFeedback}
      rules={rules}
      valuePropName={valuePropName}
      colon={colon}
      shouldUpdate={shouldUpdate}
      help={help}
      validateStatus={validateStatus}
      hidden={hidden}
    >
      {input || <Input />}
    </Form.Item>
  );
};

export default useGenerateFormItem;
