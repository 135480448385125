import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Modal, Select, Row, Button } from 'antd';
import useMovementStockContext from './MovementStockContext';

const { Option } = Select;

const ModalToSetType = ({ visible, setVisible, purpose }) => {
  const history = useHistory();
  const {
    allDeposit,
    selectedDeposit,
    setSelectedDeposit,
    inventoryDeposit,
    setInventoryDeposit,
    user,
    isFromInventory,
    comments,
    getDepositByUserTruck
  } = useMovementStockContext();
  useEffect(() => {
    if (user.truck) {
      getDepositByUserTruck();
    }
  }, [user]);

  const DisableModal = () => {
    setVisible(false);
    history.push(
      `/production/stock-movements/${purpose}/${selectedDeposit?.value}/${
        purpose === 'inventory-transfer' ? `${inventoryDeposit?.value}/` : ''
      }${selectedDeposit?.children}/${isFromInventory}/${comments || null}`
    );
  };
  return (
    <Modal
      visible={visible}
      title="Choisir un dépôt"
      closable
      onCancel={() => setVisible(false)}
      destroyOnClose
      footer={[
        <Row justify="space-around">
          <Button
            key="back"
            onClick={() => {
              setVisible(false);
            }}
          >
            Annuler
          </Button>
          <Button
            onClick={() => DisableModal()}
            disabled={!selectedDeposit && !inventoryDeposit}
            key="submit"
            type="primary"
          >
            Valider
          </Button>
        </Row>
      ]}
    >
      <Row>
        <span style={{ marginRight: 20 }}>Sélectionner un dépôt {purpose === 'inventory-transfer' ? 'de sortie' : ''} :</span>
        <Select
          showSearch
          disabled={user.type === 'TECH'}
          style={{ width: 200 }}
          placeholder="Dépôt..."
          optionFilterProp="children"
          defaultValue={selectedDeposit && selectedDeposit?._id}
          onChange={(_, label) => setSelectedDeposit({ ...label })}
        >
          {allDeposit
            ?.filter(({ show }) => show === true)
            ?.map(({ _id, label }) => (
              <Option key={_id} value={_id}>
                {label}
              </Option>
            ))}
        </Select>
      </Row>
      {purpose === 'inventory-transfer' && (
        <Row>
          <span style={{ marginRight: 20, marginTop: 10 }}>
            Sélectionner un dépôt d'entrée :
          </span>
          <Select
            showSearch
            disabled={user.type === 'TECH'}
            style={{ width: 200, marginTop: 10 }}
            placeholder="Dépôt..."
            optionFilterProp="children"
            defaultValue={inventoryDeposit?._id}
            onChange={(_, label) => setInventoryDeposit({ ...label })}
          >
            {allDeposit
              ?.filter(({ show }) => show === true)
              ?.map(({ _id, label }) => (
                <Option key={_id} value={_id}>
                  {label}
                </Option>
              ))}
          </Select>
        </Row>
      )}
    </Modal>
  );
};

ModalToSetType.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  purpose: PropTypes.string
};

ModalToSetType.defaultProps = {
  purpose: null
};

export default ModalToSetType;
