import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row, message, Form, Spin } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useAuthContext from '../../../contexts/AuthContext';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import { noLabelLayout } from '../../../utils/constants/formLayout';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import moment from 'moment';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';

const CreateUpdateTool = ({ purpose }) => {
  const generateFormItem = useGenerateFormItem();
  const { basicFields, advancedFields, isFieldsLoading } = useFields();
  const generateFields = useGenerateFormItem(purpose);
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const config = {
    onCreateResource: {
      setBody: (data) => {
        return {
          ...data
        };
      }
    },
    onGetResource: {
      setFields: (data) => {
        return {
          ...data,
          buying_date: data.buying_date && moment(data.buying_date),
          commissioning_date:
            data.commissioning_date && moment(data.commissioning_date),
          maintenance_date:
            data.maintenance_date && moment(data.maintenance_date)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        return {
          ...data
        };
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="machines"
      resource="tools"
      config={config}
    />
  );
};

CreateUpdateTool.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateTool;
