import React from 'react';
import { Table, Tag } from 'antd';
import PropTypes from 'prop-types';

const ShowCstb = ({ data }) => {
  const columns = [
    {
      title: 'Local(s)',
      dataIndex: 'floor',
      key: 'floor'
    },
    {
      title: 'Canalisation',
      dataIndex: 'pipeline',
      key: 'pipeline'
    },
    {
      title: 'Surface(s)',
      dataIndex: 'area',
      key: 'area',
      render: (text) => <Tag color="geekblue">{`${text} m²`}</Tag>
    },
    {
      title: 'Épaisseur(s)',
      dataIndex: 'thickness',
      key: 'thickness',
      render: (thickness) => (
        <>
          {thickness.map((element) => (
            <Tag color="green" key={element}>
              {`${element} cm`}
            </Tag>
          ))}
        </>
      )
    },
    {
      title: 'Moyenne des épaisseurs',
      dataIndex: 'thickness',
      key: 'thickness',
      render: (thickness) => {
        if (thickness.length > 0) {
          return (
            <Tag color="geekblue">
              {`${(
                thickness.reduce((a, b) => a + b, 0) / thickness.length
              ).toFixed(2)} cm`}
            </Tag>
          );
        }
        return <Tag color="geekblue">0 cm</Tag>;
      }
    }
  ];

  return (
    <>
      <Table
        pagination={false}
        dataSource={data}
        columns={columns}
        scroll={{ x: 700 }}
      />
    </>
  );
};

ShowCstb.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ShowCstb.defaultProps = {
  data: null
};

export default ShowCstb;
