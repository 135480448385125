import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Row, Col, DatePicker, Button, Form, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  CalendarOutlined,
  EuroOutlined,
  InboxOutlined
} from '@ant-design/icons';
import ContentCustom from '../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import GraphLoss from './GraphLoss';
import GraphQteProj from './GraphQteProj';
import useAuthContext from '../../contexts/AuthContext';
import useErrorMessage from '../../utils/ErrorMessage';
import { routes, subRoutes } from '../../utils/constants/routes';
import ModalSearch from './ModalSearch';
import CSTBPendingTable from './CSTBPendingTable';

const { RangePicker } = DatePicker;

const Home = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [dates, setDates] = useState([
    moment().startOf('month'),
    moment().endOf('month')
  ]);
  const [graphQteData, setGraphQteData] = useState([]);
  const [graphWasteData, setGraphWasteData] = useState([]);
  const [tableCSTBData, setTableCSTBData] = useState([]);
  const [tableCSTBMovement, setTableCSTBMovement] = useState([]);

  const onChange = async (values) => {
    setDates(values);
  };

  const getGraphQteData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/dashboard/graph-qte?start_date=${dates[0]}&end_date=${dates[1]}`
      });
      setGraphQteData(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getGraphWasteData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/dashboard/graph-waste?start_date=${dates[0]}&end_date=${dates[1]}`
      });
      setGraphWasteData(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getTableCstbData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/dashboard/table/cstb`
      });
      setTableCSTBData(data.manager.cstb);
      setTableCSTBMovement(data.manager.movements);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    getGraphQteData();
    getGraphWasteData();
    getTableCstbData();
  }, [dates]);

  return (
    <>
      <PageHeaderCustom title={t('home.title')} />
      <ContentCustom>
        <Row justify="start" gutter={[0, 10]}>
          <Col style={{ marginRight: 24 }}>
            <Link
              to={{
                pathname: `${routes.INTERVENTION}${subRoutes.INTERVENTION.PLANNING}`
              }}
            >
              <Button type="primary">
                Planning <CalendarOutlined />
              </Button>
            </Link>
          </Col>
          <Col style={{ marginRight: 24 }}>
            <Link
              to={{
                pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.QUOTATIONS}`
              }}
            >
              <Button type="primary">
                Devis <InboxOutlined />
              </Button>
            </Link>
          </Col>
          <Col style={{ marginRight: 24 }}>
            <Link
              to={{
                pathname: `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.INVOICES}`
              }}
            >
              <Button type="primary">
                Factures <EuroOutlined />
              </Button>
            </Link>
          </Col>
          <Col style={{ marginRight: 24 }} xs={24} md={12} lg={10} xl={8}>
            <ModalSearch />
          </Col>
          <Col xs={24} xxl={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Form.Item label="Semaines affichées">
              <RangePicker
                onChange={onChange}
                defaultValue={[
                  moment().startOf('month'),
                  moment().endOf('month')
                ]}
                picker="week"
              />
            </Form.Item>
          </Col>
        </Row>
        <br />
        <Row justify="space-evenly" gutter={[24, 24]}>
          <Col xs={24} xl={12} xxl={8}>
            <Card
              title="Quantité projetée (en kg/semaine)"
              style={{
                height: 375,
                boxShadow: '5px 5px 5px rgba(0, 0, 0, .25)'
              }}
            >
              <GraphQteProj graphQteData={graphQteData} />
            </Card>
          </Col>
          <Col xs={24} xl={12} xxl={8}>
            <Card
              title="Pertes chantier"
              style={{
                height: 375,
                boxShadow: '5px 5px 5px rgba(0, 0, 0, .25)'
              }}
            >
              <GraphLoss graphWasteData={graphWasteData} />
            </Card>
          </Col>
          <Col xs={24} xl={12} xxl={8}>
            <Tabs>
              <TabPane tab={'CSTB'} key={'CSTB'}>
                <CSTBPendingTable
                  tableCSTBData={tableCSTBData}
                  movements={tableCSTBMovement}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
};

export default Home;
