import React, { useEffect, useState } from 'react';
import {
  Collapse,
  Button,
  Tag,
  Empty,
  List,
  Descriptions,
  Col,
  Row
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import ModaleEditContact from './ModaleEditContact';
import ModalToEditBuisness from './ModalToEditBuisness';

const { Panel } = Collapse;
const { Item } = Descriptions;

const DetailsDeal = ({ deal, fetchData }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const idDeal = deal && deal?.companies ? deal?.companies?._id : '';
  const idCompany = deal && deal?.companies ? deal?.companies?._id : null;
  const [contacts, setContacts] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [visibleBuisness, setVisibleBuisness] = useState(false);

  const getContacts = async () => {
    if (idCompany !== null) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/contacts?my_company=${idCompany}`
        });
        setContacts(data);
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    }
  };

  const listContacts = () => {
    const data = [];
    contacts.forEach((contact) => {
      const formattedGender = contact.gender === 'MISTER' ? 'M.' : 'Mme';
      data.push({
        name: `${formattedGender} ${contact.first_name} ${contact.last_name}`,
        mobile_number: contact.phone_number_mobile,
        email: contact.email
      });
    });

    if (contacts.length <= 0) return <Empty />;

    return (
      <List
        size="small"
        bordered
        dataSource={data}
        renderItem={({ name, mobile_number, email }) => (
          <List.Item style={{ justifyContent: 'space-around' }}>
            <span>{name}</span>
            <span>{mobile_number}</span>
            <span>{email}</span>
          </List.Item>
        )}
      />
    );
  };

  const editContacts = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (deal)
      (async () => {
        await getContacts();
      })();
  }, [deal]);

  return (
    <>
      <Collapse defaultActiveKey={['0']}>
        <Panel
          key="0"
          header={t('deals.show.information')}
          extra={
            <Button
              size="small"
              onClick={() => setVisibleBuisness(true)}
              type="primary"
            >
              <EditOutlined />
              Éditer
            </Button>
          }
        >
          <Descriptions column={3}>
            <Item span={2} label={t('deals.show.reference')}>
              {deal?.buisness?.reference || '-'}
            </Item>
            <Item label={t('deals.show.city')}>
              {deal?.buisness?.city || '-'}
            </Item>
            <Item label={t('deals.show.buisness_address')}>
              {deal?.buisness?.address_details_1 || '-'}
            </Item>
            <Item label={t('deals.show.buisness_address_details')}>
              {deal?.buisness?.address_details_2 || '-'}
            </Item>
            <Item label={t('deals.show.postal_code')}>
              {deal?.buisness?.postal_code || '-'}
            </Item>
            <Item span={3} label={t('deals.show.information')}>
              {deal?.buisness?.site_informations || '-'}
            </Item>
          </Descriptions>
        </Panel>

        <Panel key="1" header={t('deals.show.company_information')}>
          <Descriptions>
            <Item>
              <Tag
                style={{
                  color: deal?.companies?.professional
                    ? 'var(--addColor)'
                    : null
                }}
              >
                {deal?.companies?.professional
                  ? 'Professionnel'
                  : 'Particulier'}
              </Tag>
            </Item>
            <Item label={t('deals.show.company_name')}>
              <Link to={`/commercial/customers/show/${idDeal}`}>
                {(deal && deal.companies && deal.companies.name) || '-'}
              </Link>
            </Item>
            <Item span={2} label={t('deals.show.company_address')}>
              <Row>
                <Col span={24}>
                  {deal && deal.companies
                    ? `${deal.companies.address_details_1} ${deal.companies.postal_code} ${deal.companies.city}`
                    : '-'}
                </Col>
                <Col span={24}>
                  {deal && deal.companies && deal.companies.bill_address_use ? (
                    ''
                  ) : (
                    <Tag style={{ fontSize: 14, marginTop: 5 }} color="red">
                      {t(`deals.show.company_address_billing`)}
                    </Tag>
                  )}
                </Col>
              </Row>
            </Item>
            <Item label={t('deals.show.company_phone')}>
              {deal && deal.companies
                ? `${deal.companies.phone_number_mobile}`
                : '-'}
            </Item>
            <Item label={t('deals.show.company_email')}>
              {deal && deal.companies ? `${deal.companies.email}` : '-'}
            </Item>
          </Descriptions>
        </Panel>

        <Panel
          key="2"
          header="Contacts"
          extra={
            <Button size="small" type="primary" onClick={editContacts}>
              <EditOutlined />
              {t('deals.show.contacts_button')}
            </Button>
          }
        >
          {listContacts()}
        </Panel>
        <ModaleEditContact
          deal={deal}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
        <ModalToEditBuisness
          fetchData={fetchData}
          dataDeal={deal}
          visible={visibleBuisness}
          setVisible={setVisibleBuisness}
        />
      </Collapse>
    </>
  );
};
DetailsDeal.propTypes = {
  deal: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  fetchData: PropTypes.func.isRequired
};
DetailsDeal.defaultProps = { deal: null };

export default DetailsDeal;
