import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton, Table } from 'antd';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import useErrorMessage from '../../../utils/ErrorMessage';

const ShowPrimaryMatters = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [product, setProduct] = useState({});

  const columns = [
    {
      title: 'Numéro de lot',
      dataIndex: 'batch_number'
    },
    {
      title: 'Dépôt',
      dataIndex: 'deposit',
      render: (text) => text?.label
    },
    {
      title: 'Cuve',
      dataIndex: 'tank',
      render: (text) => text?.label
    },
    {
      title: 'Date de remplissage',
      dataIndex: 'opened_date',
      render: (date) => moment(date).format('DD/MM/YYYY')
    },
    {
      title: 'Date de fin',
      dataIndex: 'finished_date',
      render: (date) => moment(date).format('DD/MM/YYYY')
    }
  ];

  const getPrimaryMatters = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/primary-matters/${id}?populate=batch.tank deposit,unit`
      });
      setProduct(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getPrimaryMatters();
    })();
  }, [getPrimaryMatters]);

  return (
    <>
      <PageHeaderCustom
        title={`Archives des lots - ${product.label}` || ''}
        withSubRoutes
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <Table
            rowKey="_id"
            pagination={false}
            columns={columns}
            dataSource={
              product &&
              product?.batch?.filter(({ archived }) => archived === true)
            }
          />
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowPrimaryMatters;
