import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import DealOriginTable from './dealOriginTable';

const initialColumns = [
  {
    title: 'DEAL_ORIGIN',
    dataIndex: 'columns',
    key: 'columns'
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: text => <Input InitialValue={text} />
  }
];

const DealOriginSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [deals, setDeals] = useState([]);
  const [dealOriginCols, setDealOriginCols] = useState(initialColumns);
  const [newDealOriginCol] = useState({
    label: 'Nouvelle origine d\'affaire',
    show: true,
  });

  const fetchDeals = async () => {
    try {
      const data = await dispatchAPI('GET', { url: `/deals` });
      setDeals(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const getDealOriginCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/deal-origins' });
      setDealOriginCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const addDealOriginCol = async () => {
    try {
      await dispatchAPI('POST', { url: '/deal-origins', body: newDealOriginCol });
      getDealOriginCols();
    } catch (e) {
      message.error(e.message);
    }
  };
  const deleteDealOriginCol = async dealOriginCol => {
    if (deals.filter(deals => deals.dealOrigin === dealOriginCol.label).length > 0) {
      return message.error(
        t('configuration.dealOrigin.error-delete-column')
      );
    }
    try {
      await dispatchAPI('DELETE', {
        url: `/deal-origins/${dealOriginCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    await getDealOriginCols();
  };
  const editDealOriginCol = async (_id, row) => {
    const col = dealOriginCols.find(col => col._id === _id);
    const useCol = deals.filter(deals => deals.status === col.label);
    if (useCol.length !== 0 && row.label !== col.label) {
      return message.error(
        t('configuration.error-delete-column')
      );
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/deal-origins/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    await getDealOriginCols();
  };
  useEffect(() => {
    getDealOriginCols();
    fetchDeals();
  }, []);

  return (
    <>
      <DealOriginTable
        dealOriginCols={dealOriginCols}
        editDealOriginCol={editDealOriginCol}
        deleteDealOriginCol={deleteDealOriginCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addDealOriginCol}
      >
        {t('configuration.dealOrigin.add-dealOrigin-col')}
      </Button>
    </>
  );
};

export default DealOriginSettings;
