import React, { useState } from 'react';
import { Button, Form, Input, Popconfirm, Popover, Switch, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { CirclePicker } from 'react-color';

const EditableCell = ({
 editing,
 dataIndex,
 title,
 inputType,
 checkType,
 record,
 index,
 children,
 setColorPicker,
 colorPicker,
 selectType,
 ...restProps
}) => {
    const { t } = useTranslation();
    const popoverContent = (
        <CirclePicker
            color={colorPicker || '#fff'}
            onChangeComplete={color => {
                setColorPicker(color.hex);
            }}
        />
    );
    let inputNode;
    switch (inputType) {
        case 'text':
            inputNode =
                selectType === 'color' ? (
                    <Popover content={popoverContent} title={t('articles.category')}>
                        <Tag color={record.color}>{record.label.toUpperCase()}</Tag>
                    </Popover>
                ) : (
                    <Input />
                );
            break;
        case 'checkbox':
            inputNode = (
                <Switch
                    defaultChecked={
                        checkType === 'incomes' ? record.in_incomes : record.in_expenses
                    }
                />
            );

            break;
        default:
            break;
    }

    return (
        <td {...restProps}>
            {editing ? (
                <Form.Item
                    name={dataIndex}
                    style={{
                        margin: 0
                    }}
                    rules={[
                        {
                            required: true,
                            message: `Please Input ${title}!`
                        }
                    ]}
                >
                    {inputNode}
                </Form.Item>
            ) : (
                children
            )}
        </td>
    );
};

const PartsTypesTable = ({ partsTypes, editPartType, deletePartType }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [editingKey, setEditingKey] = useState('');
    const [colorPicker, setColorPicker] = useState('');
    const [switchLoading, setSwitchLoading] = useState(false)

    const isEditing = record => record._id === editingKey;

    const edit = record => {
        form.setFieldsValue({
            label: '',
            accounting_code: '',
            in_incomes: record.in_incomes,
            in_expenses: record.in_expenses,
            ...record
        });
        setEditingKey(record._id);
    };

    const cancel = () => {
        setEditingKey('');
    };
    const setColor = async ({ color }) => {
        await form.setFieldsValue({
            color: colorPicker || color
        });
    };

    const saveSwitch = async ( record ) => {
        try {
            await editPartType(record._id, record);
            setEditingKey('');
        } catch (e) {
            console.log("Error with Switch change: ", e)
        }
    }

    const save = async record => {
        await setColor(record);
        try {
            const row = await form.validateFields();
            await editPartType(record._id, row);
            setEditingKey('');
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const columns = [
        {
            title: t('settings.categoriestable.label'),
            dataIndex: 'label',
            width: '30%',
            editable: true
        },
        {
            title: t('settings.kanbantable.color'),
            dataIndex: 'color',
            width: '20%',
            editable: true,
            render: color => <Tag color={color}>{color}</Tag>
        },
        {
            title: t('settings.categoriestable.action'),
            dataIndex: 'operation',
            render: (_, record) => {
                const editable = isEditing(record);
                return editable ? (
                    <span>
            <Button href="javascript:;" onClick={() => save(record)}>
              {`${t('buttons.save')}`}
                <SaveOutlined />
            </Button>
            <Popconfirm title={t('settings.messages.confirm-deletion')} onConfirm={cancel}>
              <Button>
                {`${t('buttons.cancel')}`}
                  <CloseOutlined />
              </Button>
            </Popconfirm>
          </span>
                ) : (
                    <>
                        <Button
                            disabled={editingKey !== ''}
                            onClick={() => edit(record)}
                            type="primary"
                            style={{ borderRadius: 0}}
                        >
                            {t('buttons.edit')}
                            <EditOutlined />
                        </Button>
                        <Popconfirm
                            title={t('settings.messages.delete-line-confirm')}
                            onConfirm={() => deletePartType(record)}
                            disabled={editingKey !== ''}
                        >
                            <Button
                                disabled={editingKey !== ''}
                                type="danger"
                                style={{ borderRadius: 0}}
                            >
                                {t('buttons.delete')}
                                <DeleteOutlined />
                            </Button>
                        </Popconfirm>
                    </>
                );
            }
        }
    ];
    const mergedColumns = columns.map(col => {
        if (!col.editable) {
            return col;
        }

        return {
            ...col,
            onCell: record => ({
                record,
                inputType: ['in_incomes', 'in_expenses'].includes(col.dataIndex)
                    ? 'checkbox'
                    : 'text',
                selectType: col.dataIndex === 'color' ? 'color' : '',
                checkType: col.dataIndex === 'in_incomes' ? 'incomes' : '',
                dataIndex: col.dataIndex,
                title: col.title,
                colorPicker,
                setColorPicker,
                editing: isEditing(record)
            })
        };
    });

    return (
        <Form form={form} component={false}>
            <Table
                components={{
                    body: {
                        cell: EditableCell
                    }
                }}
                bordered
                dataSource={partsTypes}
                columns={mergedColumns}
                rowClassName="editable-row"
                pagination={false}
            />
        </Form>
    );
};

export default PartsTypesTable;
