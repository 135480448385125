import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import InvoiceRouter from './Invoices/InvoiceRouter';
import BankRouter from './Banks/BankRouter';
import VatAccountRouter from './VatsAccount/VatAccountRouter';
import UnpaidRouter from './Unpaid/UnpaidRouter';
import { AccountingContextProvider } from './AccountingContext';
// import BankReconciliationRouter from './BankReconciliation/BankReconciliationRouter';

const AccountRouter = () => {
  const { path } = useRouteMatch();
  return (
    <AccountingContextProvider>
      <Switch>
        {/* <Route
        path={`${path}${subRoutes.ACCOUNTING.BANK_RECONCILIATION}`}
        component={BankReconciliationRouter}
      /> */}

        <Route
          path={`${path}${subRoutes.ACCOUNTING.INVOICES}`}
          component={InvoiceRouter}
        />
        <Route
          path={`${path}${subRoutes.ACCOUNTING.BANK_SETTINGS}`}
          component={BankRouter}
        />
        <Route
          path={`${path}${subRoutes.ACCOUNTING.VAT_ACCOUNTS}`}
          component={VatAccountRouter}
        />
        <Route
          path={`${path}${subRoutes.ACCOUNTING.UNPAID}`}
          component={UnpaidRouter}
        />
      </Switch>
    </AccountingContextProvider>
  );
};

export default AccountRouter;
