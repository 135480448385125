import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Divider,
  Popconfirm,
  Skeleton,
  Table,
  Tabs,
  Row,
  Col,
  Badge
} from 'antd';
import { useTranslation } from 'react-i18next';
import { DeleteOutlined, WarningOutlined } from '@ant-design/icons';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import useErrorMessage from '../../../utils/ErrorMessage';
import setColumn from './columnsForSetData/UpdateInventory';
import {
  EditableRow,
  EditableCell
} from './updateInventory.jsx/contextEditable';

const { TabPane } = Tabs;

const ShowInventory = () => {
  const history = useHistory();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [inventories, setInventories] = useState([]);
  const [check, setCheck] = useState();
  const [invalidValue, setInvalidValue] = useState(false);

  const listCategories = [
    { label: t(`inventories.show.epis`), value: 'epis' },
    { label: t(`inventories.show.articles`), value: 'articles' },
    { label: t(`inventories.show.primary_matters`), value: 'primary_matters' }
  ];

  const deleteInventory = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/articles/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getInventory = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/inventories/${id}`
      });
      setInventories(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  const handleSaveInventory = async (desc) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/inventories/${id}`,
        body: { ...inventories, pending: desc !== 'validate' }
      });
      getInventory();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const handleSave = (row, type) => {
    const newData = [...inventories[type]];
    const index = newData.findIndex((item) => {
      return row._id === item._id;
    });
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...row });
    setInventories({
      ...inventories,
      [type]: newData
    });
  };

  const handleDifference = (row, type) => {
    const newData = [...inventories[type]];
    const index = newData.findIndex((item) => {
      return row._id === item._id;
    });
    newData.splice(index, 1, { ...row, real_quantity: row.quantity });
    setInventories({
      ...inventories,
      [type]: newData
    });
  };
  const columnsEdit = (translation, item) => {
    const result = [
      ...setColumn(translation, item, inventories?.pending, setInvalidValue)
    ].map((col) => {
      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col.editable,
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave: (row) => handleSave(row, item),
          handleDifference: (row) => handleDifference(row, item)
        })
      };
    });
    return result;
  };

  const checkInventory = () => {
    const list = ['epis', 'articles', 'primary_matters'];

    const result = {};
    list.forEach((ele) => {
      result[ele] = inventories[ele]?.filter(
        (category) => category.real_quantity === undefined
      ).length;
    });
    setCheck(result);
  };

  useEffect(() => {
    checkInventory();
  }, [inventories]);

  useEffect(() => {
    (async () => {
      await getInventory();
    })();
  }, [getInventory]);

  return (
    <>
      <PageHeaderCustom
        title={`${t('inventories.show.title')}
        ${moment(inventories.created_at).format('DD-MM-YYYY')}`}
        withSubRoutes
        extra={
          <>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteInventory}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <DescriptionList data={listContent(inventories)} translate />
          {inventories.pending ? (
            <>
              <Divider />
              <Row justify="space-around">
                <Col style={{ textAlign: 'center' }} span={12}>
                  <Button
                    onClick={() => handleSaveInventory()}
                    style={{ color: invalidValue ? 'red' : 'var(--addColor)' }}
                    disabled={invalidValue}
                    type="dashed"
                  >
                    {invalidValue
                      ? 'Les valeurs saisies sont erronées'
                      : 'Sauvegarder les changements'}
                  </Button>
                </Col>
                <Col style={{ textAlign: 'center' }} span={12}>
                  <Button
                    disabled={
                      check?.epis !== 0 ||
                      check?.articles !== 0 ||
                      check?.primary_matters !== 0 ||
                      invalidValue
                    }
                    onClick={() => handleSaveInventory('validate')}
                    type="primary"
                  >
                    Clôturer l&apos;inventaire
                  </Button>
                </Col>
              </Row>
            </>
          ) : null}

          <Tabs style={{ marginTop: 50 }} centered size="large" type="card">
            {listCategories.map(({ label, value }) => {
              return inventories[value] ? (
                <TabPane
                  tab={
                    <Badge count={check[value]}>
                      <span style={{ marginRight: 20 }}>{label}</span>
                    </Badge>
                  }
                  key={value}
                >
                  <Table
                    key={value}
                    components={components}
                    rowClassName={() => 'editable-row'}
                    rowKey="_id"
                    dataSource={inventories[value] || []}
                    columns={[...columnsEdit(t, value)]}
                    scroll={{ x: 800 }}
                  />
                </TabPane>
              ) : null;
            })}
          </Tabs>
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowInventory;
