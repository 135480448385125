import React from 'react';
import { Select, Input } from 'antd';
import { useTranslation } from 'react-i18next';
import useInvoiceContext from '../InvoiceContext';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

const { Option } = Select;

const useHeaderFields = (purpose, siteDisabled) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const {
    organizations,
    sites,
    setContact,
    contact,
    setCompany,
    company,
    setPrestations
  } = useInvoiceContext();
  const fetchData = async (idSite) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sites/${idSite}?populate=contact,company`
      });
      setContact(data.contact);
      setCompany(data.company);
      if (purpose !== 'edit') {
        setPrestations([]);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const headerFields = [
    {
      name: ['organization'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          style={{ width: '100%' }}
          placeholder={t('invoices.form.select_organization')}
          disabled
        >
          <Option value={null} />
          {organizations?.map(({ name, _id }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['site'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          style={{ width: '100%' }}
          placeholder={t('invoices.form.select_site')}
          onChange={(value) => fetchData(value)}
          disabled={siteDisabled}
        >
          {sites?.map(({ reference, _id }) => (
            <Option key={_id} value={_id}>
              {reference}
            </Option>
          ))}
        </Select>
      )
    },
    ...(contact && company
      ? [
          {
            name: ['customer'],
            input: <Input disabled hidden />,
            help: (
              <>
                <p>{company.name}</p>
                <p style={{ marginTop: '5%' }}>
                  {`${company.address_details_1} ${
                    company.address_details_2 ? company.address_details_2 : ''
                  }`}
                </p>
                <p>{`${company.postal_code} ${company.city}`}</p>
              </>
            )
          },
          {
            name: ['contact'],
            input: <Input value={contact._id} disabled hidden />,
            help: (
              <>
                <p>{`${contact.first_name} ${contact.last_name}`}</p>
                <p style={{ marginTop: '5%' }}>{contact.phone_number_mobile}</p>
                <p>{contact.email}</p>
              </>
            )
          }
        ]
      : [])
  ];

  return {
    headerFields
  };
};

export default useHeaderFields;
