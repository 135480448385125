import React, { useState } from 'react';
import { Button, Form, Popconfirm, Table, Row, Col, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  checkType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  enumsTrucks,

  ...restProps
}) => {
  let inputNode;
  switch (inputType) {
    case 'select':
      inputNode = (
        <Select
          mode="multiple"
          placeholder="Inserted are removed"
          style={{ width: '100%' }}
        >
          {enumsTrucks.map((item) => (
            <Select.Option key={item._id} value={item._id}>
              {item.label}
            </Select.Option>
          ))}
        </Select>
      );
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const ManagerTable = ({
  managersCols,
  trucks,
  editManagerCol,
  deleteManagersCol
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [switchShowLoading] = useState(false);

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      editManagerCol(record._id, row);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: t('configuration.manager.label'),
      dataIndex: 'label',
      width: '20%',
      editable: false,
      render: (value, record) => (
        <span>{`${record?.first_name} ${record?.last_name}`}</span>
      )
    },
    {
      title: t('configuration.manager.trucks'),
      dataIndex: 'managed_trucks',
      width: '20%',
      editable: true,
      render: (value, record) => (
        <>
          <Row>
            {record?.managed_trucks?.map((e, index) => (
              <Col span={12} key={index}>{e?.label}</Col>
            ))}
          </Row>
        </>
      )
    },
    {
      title: t('configuration.unit.action'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Button onClick={() => save(record)} style={{ borderRadius: 0 }}>
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button style={{ borderRadius: 0 }}>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <>
            <Button
              onClick={() => edit(record)}
              disabled={editingKey !== ''}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>

            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteManagersCol(record)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    const checkType = (value) => {
      if (value === 'managed_trucks') return 'select';
    };

    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          inputType: checkType(col.dataIndex),
          enumsTrucks: trucks,
          checkType: 'managed_trucks',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={managersCols}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 700 }}
      />
    </Form>
  );
};

export default ManagerTable;
