import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputNumber, Select, DatePicker, Switch } from 'antd';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import moment from 'moment';

const { TextArea } = Input;
const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [enums, setEnums] = useState({});

  const basicFields = [
    {
      name: ['code'],
      rules: [{ required: true }]
    },
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['condition'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.conditions || []).map((condition) => (
            <Option key={condition} value={condition}>
              {condition}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['quantity_min'],
      input: <InputNumber min="0" />
    },
    {
      name: ['buy_price'],
      rules: [{ required: true }],
      input: <InputNumber min="0" />
    },
    {
      name: ['buying_date'],
      rules: [{ required: true }],
      input: <DatePicker format={'DD-MM-YYYY'}/>
    },
    {
      name: ['commissioning_date'],
      rules: [{ required: true }],
      input: <DatePicker format={'DD-MM-YYYY'}/>
    },
    {
      name: ['maintenance'],
      rules: [{ required: true }],
      input: <Switch defaultChecked />
    },
    {
      name: ['maintenance_date'],
      input: <DatePicker format={'DD-MM-YYYY'}/>
    },
    {
      name: ['supplier'],
      input: (
        <Select loading={isFieldsLoading}>
          {suppliers.map(({ name, _id }) => (
            <Option key={name} value={_id}>
              {name.toUpperCase()}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['ref_supplier']
    },
    {
      name: ['description'],
      input: <TextArea />
    }
  ];

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies?type=SUPPLIERS'
      });
      setSuppliers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/tools/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getSuppliers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { basicFields, isFieldsLoading };
};

export default useFields;
