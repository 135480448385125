import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Axis, Tooltip, Coord, Legend, Label } from 'bizcharts';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';

const ChartQuotation = ({ data }) => {
  const { t } = useTranslation();

  const cols = {
    percent: {
      formatter: (val) => `${val * 100}%`
    },
    item: {
      formatter: (val) => `${t(`Commercial.home.quotation_status.${val}`)}`
    }
  };
  return (
    <>
      <Card title="Répartition des devis (en %)" className="home-card">
        {data && data.length > 0 ? (
          <Chart width={600} height={300} data={data} scale={cols} forceFit>
            <Coord type="theta" radius={0.9} />
            <Axis name="percent" />
            <Legend position="bottom" />
            <Tooltip
              showTitle={false}
              itemTpl='<li><span style="background-color:{color};" class="g2-tooltip-marker"></span>{name}: {value}</li>'
            />
            <Geom
              type="intervalStack"
              position="percent"
              color="item"
              tooltip={[
                'item*percent',
                (item, percent) => {
                  percent = `${(percent * 100).toFixed(0)}%`;
                  item = `${t(`Commercial.home.quotation_status.${item}`)}`;
                  return {
                    name: item,
                    value: percent
                  };
                }
              ]}
              style={{
                lineWidth: 1,
                stroke: '#fff'
              }}
            />
          </Chart>
        ) : null}
      </Card>
    </>
  );
};
ChartQuotation.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

ChartQuotation.defaultProps = {
  data: []
};

export default ChartQuotation;
