import React from 'react';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';

const ListMachines = () => {
  return (
    <SimpleResourceLandingLayout
      resourceName="machines"
      columns={[...columns()]}
      populate="family,company"
      withSubRoutes
      withUploadButton={false}
      resourceModelName="Machine"
      searchPlaceHolder="Entrer un Code ou un Nom"
    />
  );
};

export default ListMachines;
