import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListEPIs from './ListEPIs';
import CreateUpdateEPI from './CreateUpdateEPI';
import ShowEPI from './ShowEPI';

const EPIsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateEPI purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateEPI purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowEPI} />
      <Route path={`${path}`} component={ListEPIs} />
    </Switch>
  );
};

export default EPIsRouter;
