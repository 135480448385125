import React, { useState, useEffect } from 'react';
import {
  Modal,
  Button,
  Form,
  Select,
  InputNumber,
  Row,
  Card,
  message as warning
} from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import TextArea from 'antd/lib/input/TextArea';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import NewArticleDrawer from './NewArticleDrawer';

const { Option } = Select;

const MachinesModal = ({ articles, setArticles, purpose, id, inventory }) => {
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resources, setResources] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [label, setLabel] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);
  const [selectedArticle, setSelectedArticle] = useState();

  const listPurpose = (title) => {
    switch (title) {
      case 'enter':
        return 'Entrée de Machines';
      case 'exit':
        return 'Sortie de Machines';
      case 'inventory-transfer':
        return 'Transfert de Machines';
      default:
        return '';
    }
  };

  const getResources = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/machines`
      });
      setResources(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onChange = (values) => {
    const value = resources && resources.find(({ _id }) => _id === values);
    setSelectedArticle(value);
    const quantityForDeposit = value.stock.find(
      ({ deposit }) => deposit === id
    );
    setQuantity(quantityForDeposit?.quantity || 0);
    setLabel(value.label);
  };
  const onClose = () => {
    form.resetFields();
    setQuantity(0);
    setLabel();
    setIsModalVisible(false);
  };
  const onFinish = (values) => {
    if (purpose === 'enter') {
      if (quantity + values.quantity < selectedArticle.quantity_min) {
        warning.warning(
          "Vous êtes en dessous du seuil pour l'article sélectionné"
        );
      }
    } else if (quantity - values.quantity < selectedArticle.quantity_min) {
      warning.warning(
        "Vous êtes en dessous du seuil pour l'article sélectionné"
      );
    }
    const unit = { label: ' ' };
    const newArticle = {
      ...values,
      label,
      unit,
      category: 'machines',
      type: 'Machines'
    };
    setArticles([...articles, newArticle]);
    form.resetFields(['id', 'quantity']);
    onClose();
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    getResources();
  }, [forceRefresh]);
  return (
    <>
      <Card
        bordered={false}
        style={{
          width: '170px',
          backgroundColor: 'var(--primaryColor)',
          textAlign: 'center',
          color: 'white',
          borderRadius: '8px',
          cursor: 'pointer'
        }}
        onClick={showModal}
      >
        Machines
      </Card>
      <Modal
        title={listPurpose(purpose)}
        visible={isModalVisible}
        onOk={onClose}
        onCancel={onClose}
        footer={null}
      >
        {purpose === 'enter' && (
          <NewArticleDrawer
            type="machines"
            setForceRefresh={setForceRefresh}
            forceRefresh={forceRefresh}
          />
        )}
        <br />
        <Form onFinish={onFinish} form={form}>
          <Form.Item label="Article" name="id" rules={[{ required: true }]}>
            <Select onChange={onChange}>
              {resources?.map((resource) => {
                return (
                  <Option key={resource._id} value={resource._id}>
                    {resource.label}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Row>
            <Form.Item
              name="quantity"
              label={`Quantité ${
                purpose === 'enter' ? 'réceptionnée' : 'voulue'
              }`}
              rules={[{ required: true }]}
            >
              {purpose === 'exit' ? (
                <InputNumber max={quantity} min={1} />
              ) : (
                <InputNumber min={1} />
              )}
            </Form.Item>
            <span style={{ marginLeft: 30 }}>{null}</span>
          </Row>
          <Form.Item
            name="comments"
            label="Commentaires"
            rules={[{ required: inventory }]}
            help={
              inventory &&
              'Ce mouvement fait suite à un inventaire, veuillez saisir un commentaire.'
            }
          >
            <TextArea />
          </Form.Item>
          <Form.Item label="Quantité restante">
            <span style={{ fontWeight: 'bold' }}>{quantity}</span>
          </Form.Item>
          <Row justify="end">
            {purpose === 'exit' && quantity === 0 ? (
              <span style={{ color: 'red', marginRight: 50 }}>
                Aucune quantité disponible
              </span>
            ) : null}
            <Form.Item>
              <Button
                disabled={purpose === 'exit' && quantity === 0}
                type="add"
                htmlType="submit"
              >
                Sauvegarder
                <CheckOutlined />
              </Button>
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
MachinesModal.propTypes = {
  articles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setArticles: PropTypes.func.isRequired,
  purpose: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  inventory: PropTypes.bool.isRequired
};
MachinesModal.defaultProps = {
  articles: []
};

export default MachinesModal;
