import React, { forwardRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Divider, Spin, Radio, Row, Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useFields from '../fields/index';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useInvoiceContext from '../InvoiceContext';
import FormListPrestation from '../fields/FormListPrestation';

const NewInvoiceForm = forwardRef(
  ({ handleSubmit, onValueChange, purpose }, ref) => {
    const {
      invoice,
      setIsLoading,
      isLoading,
      listPrestations,
      setListPrestations,
      isVat,
      setIsVat
    } = useInvoiceContext();
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [siteDisabled, setSiteDisabled] = useState(true);

    const fields = useFields(
      purpose,
      form,
      invoice?.number || 'N/A',
      siteDisabled
    );
    const [typeLinesInvoice, setTypeLinesInvoice] = useState();

    const generateFields = useGenerateFormItem();
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 8 }
    };
    useEffect(() => {
      if (invoice) {
        setSiteDisabled(true);
        setIsLoading(true);
        form.setFieldsValue({
          ...invoice,
          site: invoice.site._id,
          emission_date: invoice.emission_date && moment(invoice.emission_date),
          deadline_date: invoice.deadline_date && moment(invoice.deadline_date),
          payment_date: invoice.payment_date && moment(invoice.payment_date),
          customer: invoice.customer._id,
          subject: invoice.site.reference,
          contact: invoice.contact._id,
          holdback: invoice.holdback || 5,
          prorata_account: 0,
          prorata_percentage: 0,
          vat: (invoice.vat && invoice.vat._id) || null
        });
        if (invoice.prestations.length > 0 || invoice.vat) {
          setListPrestations(invoice.prestations);
          setIsVat(true);
        }
        setIsLoading(false);
      } else {
        setSiteDisabled(false);
      }
    }, [invoice]);

    return (
      <Spin spinning={isLoading}>
        <Form
          {...layout}
          ref={ref}
          name="invoice"
          onValuesChange={onValueChange}
          onFinish={handleSubmit}
          form={form}
        >
          <Divider style={{ width: '50%' }}>Entête</Divider>
          {fields.header.map((field) => generateFields('invoices', field))}
          <Divider style={{ width: '50%' }}>Corps</Divider>
          {fields.body.map((field) => generateFields('invoices', field))}
          <Divider style={{ width: '50%' }}>Lignes de factures</Divider>
          <Row justify="center" style={{ margin: 20 }}>
            <Radio.Group
              disabled={!isVat}
              onChange={(e) => setTypeLinesInvoice(e.target.value)}
              //   value={value}
            >
              <Radio value="prestations">Lignes prestations</Radio>
              <Radio value="free">Lignes de facture libre</Radio>
            </Radio.Group>
          </Row>
          {isVat && (
            <>
              {listPrestations && <FormListPrestation />}
              {typeLinesInvoice && fields[typeLinesInvoice]}
            </>
          )}
          <Divider style={{ width: '50%' }}>Total</Divider>
          {fields.total}
          <Divider style={{ width: '50%' }} />
          <Row justify="space-around" style={{ marginTop: 50 }}>
            <Button
              style={{ float: 'right' }}
              type="danger"
              disabled={
                !(listPrestations.length > 0) &&
                !(invoice?.prestations.length > 0)
              }
              // icon={<CheckOutlined />}
            >
              {`${t('buttons.cancel')} `}
            </Button>
            <Button
              style={{ float: 'right' }}
              type="add"
              htmlType="submit"
              onClick={async () => {
                try {
                  await form.validateFields();
                } catch (errorInfo) {
                  message.error('Remplir tous les champs requis');
                }
              }}
              disabled={
                !(listPrestations.length > 0) &&
                !(invoice?.prestations.length > 0)
              }
              // icon={<CheckOutlined />}
            >
              {`${t('buttons.save')} `}
            </Button>
          </Row>
        </Form>
      </Spin>
    );
  }
);

NewInvoiceForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  id: PropTypes.string,
  onValueChange: PropTypes.func.isRequired
};
NewInvoiceForm.defaultProps = {
  purpose: null,
  id: null
};

export default NewInvoiceForm;
