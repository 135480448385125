import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tag, Row } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import moment from 'moment';
import { TruckIconFilled } from '../../../utils/constants/customTruckIcons';

const useListContent = ({
  category,
  deposit,
  inventory,
  truck,
  user,
  created_at,
  comments,
  machines,
  articles,
  epis,
  primary_matters
}) => {
  const { t } = useTranslation();
  const setCategory = {
    exit: { color: 'red', value: 'SORTIE' },
    enter: { color: 'var(--addColor)', value: 'ENTRÉE' },
    'inventory-transfer': { color: 'orange', value: 'TRANSFERT' },
    regularization: { color: 'orange', value: 'REGULARISATION' }
  };
  return [
    {
      label: t('stock-movements.show.type'),
      content: category && (
        <Tag color={setCategory[category].color || 'black'}>
          {setCategory[category].value}
        </Tag>
      ),
      span: 1
    },
    {
      label: t('stock-movements.show.deposit'),
      content: deposit?.label,
      span: 1
    },
    {
      label: t('stock-movements.show.inventory'),
      content: inventory ? 'Oui' : 'Non',
      span: 1
    },
    {
      label: t('stock-movements.show.truck'),
      content: (
        <Row style={{ marginTop: 10 }}>
          <TruckIconFilled />
          <span style={{ marginLeft: 10 }}>
            {truck !== null ? truck?.label : 'Aucun'}
          </span>
        </Row>
      ),
      span: 1
    },
    {
      label: t('stock-movements.show.user'),
      content: (
        <Row style={{ marginTop: 10 }}>
          <UserOutlined style={{ fontSize: 20, color: 'var(--addColor)' }} />
          <span style={{ marginLeft: 10 }}>
            {`${user?.first_name} ${user?.last_name} ( ${t(
              `users.type.${user?.type}`
            )})`}
          </span>
        </Row>
      ),
      span: 1
    },
    {
      label: t('stock-movements.show.created_at'),
      content: `le ${moment(created_at).format('DD/MM/YYYY à hh:mm')}`,
      span: 1
    },
    {
      label: t('stock-movements.show.comments'),
      content: comments !== null ? comments : 'Aucun',
      span: 2
    },
    {
      label: t('stock-movements.show.valuation'),
      content:
        articles?.length > 0 ||
        epis?.length > 0 ||
        machines?.length > 0 ||
        primary_matters?.length > 0 ? (
          <span
            style={{
              fontSize: 15,
              color: category === 'enter' ? 'var(--addColor)' : 'red'
            }}
          >
            {`${Math.round(
              articles
                ?.map((article) =>
                  article.id ? article.id.buy_price * article.quantity : 0
                )
                .reduce((curr, prev) => curr + prev, 0) +
                epis
                  ?.map((epi) => epi.id.buy_price * epi.quantity)
                  .reduce((curr, prev) => curr + prev, 0) +
                machines
                  ?.map((machine) => machine.id.buy_price * machine.quantity)
                  .reduce((curr, prev) => curr + prev, 0) +
                primary_matters
                  ?.map(
                    (matter) =>
                      matter.id &&
                      matter.id.buy_price &&
                      matter.id.buy_price * matter.quantity
                  )
                  .reduce((curr, prev) => curr + prev, 0)
            )} €`}
          </span>
        ) : null,
      span: 1
    }
  ];
};

export default useListContent;
