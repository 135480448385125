import React, { useEffect, useState } from 'react';
import useColumns from '../../Movements/ManageMovements/columns';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import { Button, Col, PageHeader, Divider, Row, Spin, Statistic } from 'antd';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../../utils/constants/routes';
import {
    EuroOutlined,
    FileDoneOutlined,
    InboxOutlined,
    MonitorOutlined,
} from '@ant-design/icons';
import ModalSearch from '../ModalSearch';
import moment from 'moment';
import { Datatable } from '../../../components';

const HomeAccounting = () => {
    const { t } = useTranslation();
    const [companies, setCompanies] = useState();
    const columns = useColumns(t, companies);
    const { dispatchAPI, user } = useAuthContext();
    const { message } = useErrorMessage();

    const [graphData, setGraphData] = useState([]);
    const [loading, setLoading] = useState(true);

    const getGraphQteData = async () => {
        try {
            const { data } = await dispatchAPI('GET', {
                url: `/dashboard/commercial/${user._id}`
            });
            setGraphData(data);
        } catch (e) {
            if (e.response) message(e.response.status);
        }
    };

    useEffect(() => {
        if (user._id) {
            (async () => {
                setLoading(true);
                await getGraphQteData();
                setLoading(false);
            })();
        }
    }, []);

    const getCompanies = async () => {
        try {
            const { data } = await dispatchAPI('GET', { url: '/companies' });
            setCompanies(data);
        } catch (e) {
            if (e.response) message(e.response.status);
        }
    };

    useEffect(() => {
        (async () => {
            await getCompanies();
        })();
    }, []);

    return (
        <>
            <PageHeaderCustom title={t('accounting.title')} />

            <Spin spinning={loading}>
                <ContentCustom>
                    <Row justify="space-around">
                        <Col style={{ marginRight: 24 }}>
                            <Link
                                to={{
                                    pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.QUOTATIONS}`
                                }}
                            >
                                <Button icon={<InboxOutlined />} type="primary">
                                    Devis
                                </Button>
                            </Link>
                        </Col>
                        <Col style={{ marginRight: 24 }}>
                            <Link
                                to={{
                                    pathname: `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.INVOICES}`
                                }}
                            >
                                <Button icon={<EuroOutlined />} type="primary">
                                    Factures
                                </Button>
                            </Link>
                        </Col>
                        <Col style={{ width: '35%' }}>
                            <ModalSearch />
                        </Col>
                    </Row>
                    <Divider />
                    <Row style={{ margin: 30 }} justify="space-around">
                        <Col style={{ textAlign: 'center' }} span={8}>
                            <Statistic
                                title="Factures payées (pour le mois en cours)"
                                value={
                                    graphData.invoices_accounting
                                    && graphData.invoices_accounting
                                        .filter(el => el.payment_date && moment(el.payment_date).format('MM-YYYY') === moment().format('MM-YYYY')).length
                                }
                                prefix={<FileDoneOutlined />}
                                valueStyle={{
                                    color: 'var(--addColor)',
                                    textAlign: 'center',
                                    fontWeight: 'bold'
                                }}
                            />
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={8}>
                            <Row jusity="space-between">
                                <Col span={12}>
                                    <Statistic
                                        title="Factures à venir"
                                        prefix={<MonitorOutlined />}
                                        value={
                                            graphData.invoices_accounting
                                            && graphData.invoices_accounting
                                                .filter(el => moment(el.deadline_date).isAfter(moment()) && !el.payment_date).length
                                        }
                                        valueStyle={{
                                            color: 'var(--addColor)',
                                            textAlign: 'center',
                                            fontWeight: 'bold'
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col style={{ textAlign: 'center' }} span={8}>
                            <Row jusity="space-between">
                                <Col span={12}>
                                    <Statistic
                                        title="Factures impayées"
                                        prefix={<MonitorOutlined />}
                                        value={
                                            graphData.invoices_accounting
                                            && graphData.invoices_accounting
                                            .filter(el => moment(el.deadline_date).isBefore(moment()) && !el.payment_date).length
                                        }
                                        valueStyle={{
                                            color: 'var(--addColor)',
                                            textAlign: 'center',
                                            fontWeight: 'bold'
                                        }}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Divider />
                    <PageHeader title={t('movements.title')} />
                    <Datatable
                        resourceName='movements'
                        path="/intervention/manage_interventions"
                        columns={columns}
                        extraQuery={`status=VALIDATED&sort=-created_at`}
                        //searchValue={searchValue}
                        customActionColumn
                        //forceRefresh={forceRefresh} 
                    />
                </ContentCustom>
            </Spin>
        </>
    );
};

export default HomeAccounting;