import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('primary-matters.column.ref_internal'),
      key: 'ref_internal',
      dataIndex: 'ref_internal',
      sorter: true
    },
    {
      title: t('primary-matters.column.type'),
      key: 'type',
      dataIndex: 'type',
      render: (cat) => cat && <Tag>{cat.toUpperCase()}</Tag>,
      sorter: true
    },
    {
      title: t('primary-matters.column.label'),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('articles.column.buy_price'),
      key: 'buy_price',
      dataIndex: 'buy_price',
      render: (e) => `${e} €`,
      sorter: true
    },
    {
      title: t('articles.column.sold_price'),
      key: 'sold_price',
      dataIndex: 'sold_price',
      render: (e) => `${e} €`,
      sorter: true
    },
    {
      title: t('articles.column.mark_up'),
      key: 'mark_up',
      render: (_, record) =>
        record.buy_price &&
        record.sold_price &&
        `${Math.round((record.sold_price / record.buy_price) * 100 - 100)} %`,
      sorter: true
    }
  ];
};

export default useColumns;
