import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import TankTable from './tankTable';

const initialColumns = [
  {
    title: 'TANK',
    dataIndex: 'columns',
    key: 'columns'
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const TankSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [tankCols, setTankCols] = useState(initialColumns);
  const [deposits, setDeposits] = useState([]);
  const [newTankCol] = useState({
    label: 'Nouvelle cuve'
  });

  const getTankCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/tanks?populate=deposit' });
      setTankCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const getDeposits = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/deposits' });
      setDeposits(data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const deleteTankCol = async (tankCol) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/tanks/${tankCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    await getTankCols();
  };
  const addTankCol = async () => {
    try {
      await dispatchAPI('POST', { url: '/tanks', body: newTankCol });
      getTankCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const editTankCol = async (_id, row) => {
    const col = tankCols.find((col) => col._id === _id);
    const useCol = [];
    if (useCol.length !== 0 && row.label !== col.label) {
      return message.error(t('configuration.error-delete-column'));
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/tanks/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    await getTankCols();
  };
  useEffect(() => {
    getTankCols();
    getDeposits();
  }, []);

  return (
    <>
      <TankTable
        tankCols={tankCols}
        editTankCol={editTankCol}
        deleteTankCol={deleteTankCol}
        deposits={deposits}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addTankCol}
      >
        {t('configuration.tank.add-tank-col')}
      </Button>
    </>
  );
};

export default TankSettings;
