import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Input, Row, Button, message, Spin } from 'antd';
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  convertFromHTML,
  ContentState
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
//import draftToHtml from 'draftjs-to-html';

import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

const { TextArea } = Input;

const ShowModalForSendMail = ({ data, visible, setVisible, getResource }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [fetchData, setFetchData] = useState({});
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [loadingSend, setLoadingSend] = useState(false);
  const [mailTo, setMailTo] = useState();
  const [templateEmail, setTemplateEmail] = useState();

  const fetchMail = async () => {
    setLoadingSend(true);
    try {
      const result = await dispatchAPI('POST', {
        url: `/quotations/sendmail/${data?._id}`,
        body: fetchData
      });
      setLoadingSend(false);
      setVisible(false);
      getResource();
      message.info(result.data);
    } catch (e) {
      setLoadingSend(false);
      if (e.response) errorMessage(e.response.status);
    }
  };

  const onChangeTo = ({ target }) => {
    setFetchData({
      ...fetchData,
      params: { ...fetchData.params, message: target.value }
    });
  };

  useEffect(() => {
    if (
      templateEmail !== undefined &&
      Object.keys(templateEmail).length &&
      templateEmail.data
    ) {
    //  templateEmail.subject && setMailSubject(templateEmail.subject);
      const html = templateEmail.data && convertFromHTML(templateEmail.data);
      const state =
        html &&
        ContentState.createFromBlockArray(html.contentBlocks, html.entityMap);
      setEditorState(EditorState.createWithContent(state));
    }
  }, [templateEmail]);

  const getSettingsEmail = async emailConfig => {
    try {
      const email = await dispatchAPI('GET', {
        url: 'emails?type=QUOTE'
      });
      setTemplateEmail(email.data[0]);
      setFetchData({
        ...emailConfig,
        subject: `Devis n° ${data?.number} pour le chantier ${data?.site?.reference}`,
        htmlContent: `<!DOCTYPE html><html><body>${email.data[0].data}</body></html>`,
      });
    } catch (e) {
        console.log('e', e)
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
      const emailConfig = {
          to: [
              {
                  email: mailTo || data?.contact?.email,
                  name: `${data?.contact?.first_name} ${data?.contact?.name}`
              }
          ],
          sender: {
              email: user?.email,
              name: `${user?.first_name} ${user?.last_name}`
          },
          params: {
              object: `Devis n° ${data?.number} pour le chantier ${data?.site?.reference}`,
              message: null
          }
      }
    getSettingsEmail(emailConfig);
      }, [data, mailTo]);

  return (
    <Modal
      title={`Envoyer devis n° ${data?.number} par mail`}
      visible={visible}
      width={800}
      destroyOnClose
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Annuler
        </Button>,
        <Button key="submit" type="primary" onClick={fetchMail}>
          Envoyer
        </Button>
      ]}
    >
      <Spin tip="Envoie du devis..." spinning={loadingSend}>
        <Row>
          <span style={{ fontWeight: 'bold' }}>
            {`Destinataire : ${data?.contact?.first_name} ${data?.contact?.last_name}`}
          </span>
          <Input
            onChange={(e) => setMailTo(e.target.value)}
            defaultValue={data?.contact?.email}
          />
        </Row>
        <Row style={{ marginTop: 20 }}>
          <span style={{ fontWeight: 'bold' }}>
            {`Expéditeur : ${user?.first_name} ${user?.last_name}`}
          </span>
          <Input disabled defaultValue={user?.email} />
        </Row>
        <Row style={{ marginTop: 10 }}>
          <span style={{ marginBottom: 10, fontWeight: 'bold' }}>
            {`Objet : Devis n° ${data?.number} pour le chantier ${data?.site?.reference}`}
          </span>
          <span style={{ marginTop: 20 }}>Message</span>
        </Row>
        <Row style={{ marginTop: 5 }}>
          <div style={{ border: '0.5px solid' }}>
            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
          </div>
        </Row>
      </Spin>
    </Modal>
  );
};

ShowModalForSendMail.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  getResource: PropTypes.func.isRequired
};
ShowModalForSendMail.defaultProps = {
  data: null
};

export default ShowModalForSendMail;
