import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { PageHeader } from 'antd';
import { routes } from '../../utils/constants/routes';

const PageHeaderCustom = ({ title, extra, withSubRoutes }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { pathname } = location;
  const splitPathname = pathname.split('/');
  const history = useHistory();
  const dynamicRoutes = [
    {
      path: routes.HOME,
      breadcrumbName: t('home.title')
    },
    ...(splitPathname[1] && splitPathname[1] !== ''
      ? [
          {
            path: `/${splitPathname[1]}`,
            breadcrumbName: withSubRoutes
              ? t(`basiclayout.menu.${splitPathname[1]}`)
              : t(`${splitPathname[1]}.title`)
          }
        ]
      : []),
    ...(splitPathname[2] && splitPathname[2] !== ''
      ? [
          {
            ...(withSubRoutes
              ? {
                  path: `/${splitPathname[1]}/${splitPathname[2]}`
                }
              : {}),
            breadcrumbName: withSubRoutes
              ? t(`${splitPathname[2]}.title`)
              : t(`breadcrumbs.${splitPathname[2]}`)
          }
        ]
      : []),
    ...(withSubRoutes && splitPathname[3] && splitPathname[3] !== ''
      ? [
          {
            breadcrumbName: t(`breadcrumbs.${splitPathname[3]}`)
          }
        ]
      : [])
  ];

  const itemRender = (
    // eslint-disable-next-line react/prop-types
    { path, breadcrumbName, state, search, ...route },
    params,
    routesList
  ) => {
    const last = routesList.indexOf(route) === routesList.length - 1;
    return last ? (
      <span key={path}>{breadcrumbName}</span>
    ) : (
      <Link key={path} to={{ pathname: path, state, search }}>
        {breadcrumbName}
      </Link>
    );
  };

  return (
    <>
      {dynamicRoutes.length > 2 ?
        (
          <PageHeader
            breadcrumb={{ routes: dynamicRoutes, itemRender }}
            title={title}
            onBack={() => history.goBack()}
            ghost={false}
            extra={extra}
          />
        ) : (
          <PageHeader
            breadcrumb={{ routes: dynamicRoutes, itemRender }}
            title={title}
            ghost={false}
            extra={extra}
          />
        )
      }
    </>
  );
};

PageHeaderCustom.propTypes = {
  title: PropTypes.string.isRequired,
  extra: PropTypes.element,
  withSubRoutes: PropTypes.bool
};

PageHeaderCustom.defaultProps = {
  extra: null,
  withSubRoutes: false
};

export default PageHeaderCustom;
