import React, { createContext, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const UnpaidContext = createContext({});

export const UnpaidContextProvider = ({ children }) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [contacts, setContacts] = useState();
  const [organizations, setOrganizations] = useState();
  const [companies, setCompanies] = useState();
  const [company, setCompany] = useState();
  const [contact, setContact] = useState();
  const [sites, setSites] = useState();
  const [prestations, setPrestations] = useState();
  const [settings, setSettings] = useState();
  const [invoice, setInvoice] = useState();
  const [enums, setEnums] = useState();
  const [vats, setVats] = useState();
  const [listPrestations, setListPrestations] = useState([]);
  const [isVat, setIsVat] = useState(false);
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();

  const getInvoice = async (purpose, idInvoice) => {
    setIsLoading(true);
    if (purpose === 'edit' && idInvoice) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/invoices/${idInvoice}?populate=customer,contact,quotation vat`
        });
        setInvoice(data);
        if (data.customer && data.contact) {
          setCompany(data.customer);
          setContact(data.contact);
        }
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    }
    setIsLoading(false);
  };

  const updateInvoice = async (idInvoice, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/invoices/${idInvoice}`, body });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createInvoice = async (body) => {
    try {
      await dispatchAPI('POST', { url: '/invoices', body });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPrestations = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prestations' });
      setPrestations(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/sites'
      });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getContacts = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts' });
      setContacts(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getOrganizations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/organizations'
      });
      setOrganizations(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSettings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?type=ACCOUNTING'
      });
      setSettings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/invoices/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchVats = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/vats'
      });
      setVats(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  return (
    <UnpaidContext.Provider
      value={{
        isLoading,
        setIsLoading,
        getInvoice,
        contacts,
        setContacts,
        companies,
        setCompanies,
        sites,
        setSites,
        prestations,
        setPrestations,
        updateInvoice,
        createInvoice,
        getPrestations,
        getSites,
        getCompanies,
        getContacts,
        settings,
        setSettings,
        getSettings,
        invoice,
        setInvoice,
        getOrganizations,
        organizations,
        setOrganizations,
        company,
        setCompany,
        contact,
        setContact,
        enums,
        setEnums,
        getEnums,
        listPrestations,
        setListPrestations,
        fetchVats,
        vats,
        isVat,
        setIsVat
      }}
    >
      {children}
    </UnpaidContext.Provider>
  );
};

export default () => useContext(UnpaidContext);
