import {
  Button,
  Form,
  message,
  Row,
  Spin,
  Descriptions,
  Card,
  Divider,
  Upload,
  Col,
  Modal
} from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  UploadOutlined,
  SendOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useFields from './Fields';
import { ContentCustom } from '../../../../components';
import useMovementContext from '../PostIntervention/MovementsContext';

import {
  formItemLayout,
  tailFormItemLayout
} from '../../../../utils/constants/formLayout';

const PreIntervention = (enums) => {
  const history = useHistory();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { dispatchAPI, user, token } = useAuthContext();
  const {
    visiblePic,
    funcPicToDisplay,
    pictures,
    getFilesCstb,
    CSTB,
    deleteFiles
  } = useMovementContext();
  const [isLoading, setIsLoading] = useState(false);
  const generateFields = useGenerateFormItem();
  const { fields } = useFields(enums);
  const { id } = useParams();
  const [site, setSite] = useState();
  const [visible, setVisible] = useState(false);

  const onChangeImg = () => {
    getFilesCstb(`/files?site_id=${CSTB?.site?._id}`);
  };

  const updateResource = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `sites/${site._id}`,
        body
      });
      message.success('Sauvegarde effectuée');
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const sendMail = async () => {
    try {
      const body = {
        to: [{ email: 'commercial@ovalpro.fr', name: 'Commercial Ovalpro' }],
        sender: {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`
        }
      };
      const result = await dispatchAPI('POST', {
        url: `movements/sendmail/${id}`,
        body
      });
      setVisible(false);
      message.info(result.data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const getSite = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `movements/${id}?populate=site,quotation`
      });
      setSite(data.site);

      form.setFieldsValue(data.site);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    (async () => {
      await getSite();
      if (CSTB) getFilesCstb(`/files?site_id=${CSTB?.site?._id}`);
    })();
  }, [CSTB]);

  const average_thickness = site?.details?.map(
    (e) => e.thickness.reduce((a, b) => a + b, 0) / e.thickness.length
  );

  return (
    <ContentCustom>
      <Spin spinning={isLoading}>
        {user?.type === 'SELLER' || user?.type === 'DIRECTION' ? (
          <Form {...formItemLayout} onFinish={updateResource} form={form}>
            {fields.map((field) => generateFields('sites', field))}

            <Divider />
            {CSTB && (
              <Row justify="center">
                <Col span={12}>
                  <Card
                    title="Importer des photos"
                    extra={
                      <Form.Item noStyle name="fileList">
                        <Upload
                          multiple
                          action={`${process.env.REACT_APP_API_URL}/files/tv/${CSTB?.site?._id}`}
                          data="picture"
                          headers={{ Authorization: `Bearer ${token}` }}
                          onChange={onChangeImg}
                          onRemove={(file) => {
                            deleteFiles(
                              file.response,
                              `files/${file.response}`
                            );
                            getFilesCstb(`/files?site_id=${CSTB?.site?._id}`);
                          }}
                        >
                          <Button icon={<UploadOutlined />}>Importer</Button>
                        </Upload>
                      </Form.Item>
                    }
                  >
                    <Row gutter={[16, 16]}>
                      {pictures.length > 0
                        ? pictures
                            .filter((filter) => filter.metadata.type === 'TV')
                            .map((el) => (
                              <div key={el._id}>
                                <Col span={24}>
                                  <Button
                                    icon={
                                      visiblePic ? (
                                        <Spin />
                                      ) : (
                                        <DownloadOutlined />
                                      )
                                    }
                                    onClick={() =>
                                      funcPicToDisplay(el._id, el.contentType)
                                    }
                                  >
                                    {`${el.metadata.originalName}...`}
                                  </Button>
                                </Col>
                              </div>
                            ))
                        : null}
                    </Row>
                  </Card>
                </Col>
              </Row>
            )}
            <Modal
              visible={visible}
              title="Envoyer un mail"
              onCancel={() => setVisible(false)}
              onOk={sendMail}
            >
              Voulez-vous signaler un problème de conformité entre le devis et la visite technique ?
            </Modal>
            <Divider />
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => history.goBack()}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button
                  type="add"
                  htmlType="submit"
                  style={{ margin: '0 10px' }}
                >
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
                <Button type="add" onClick={() => setVisible(true)}>
                  {`${t('buttons.send')} `}
                  <SendOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        ) : (
          <>
            <Card title="Mesures">
              {site?.details?.map((detail, index) => (
                <Descriptions title={detail?.floor}>
                  <Descriptions.Item label="Type de prestation">
                    {detail?.type}
                  </Descriptions.Item>
                  <Descriptions.Item label="Surface">
                    {`${detail?.area} m²`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Épaisseur">
                    <Row>{`${average_thickness[index]} cm`}</Row>
                  </Descriptions.Item>
                </Descriptions>
              ))}
            </Card>
            <Card style={{ marginTop: 20 }} title="Caractéristiques">
              <Descriptions title="Général">
                <Descriptions.Item label="A informer en cas de retard">
                  {site?.have_to_be_informed_about_delay ? 'Oui' : 'Non'}
                </Descriptions.Item>
                <Descriptions.Item label="Électricité">
                  {site?.electricity ? 'Oui' : 'Non'}
                </Descriptions.Item>
                <Descriptions.Item label="Eau">
                  {site?.water ? 'Oui' : 'Non'}
                </Descriptions.Item>
                <Descriptions.Item label="Construction neuve">
                  {site?.new_building ? 'Oui' : 'Non'}
                </Descriptions.Item>
                <Descriptions.Item label="Rénovation">
                  {site?.refurbished_building ? 'Oui' : 'Non'}
                </Descriptions.Item>
              </Descriptions>
              <Descriptions title="Annotations">
                {site?.annotations && (
                  <Descriptions.Item>{site?.annotations}</Descriptions.Item>
                )}
              </Descriptions>
            </Card>

            <Card style={{ marginTop: 20 }} title="Documents">
              <Row gutter={[16, 16]}>
                {pictures.length > 0
                  ? pictures
                      .filter((filter) => filter.metadata.type === 'TV')
                      .map((el) => (
                        <div key={el._id}>
                          <Col span={24}>
                            <Button
                              onClick={() =>
                                funcPicToDisplay(el._id, el.contentType)
                              }
                            >
                              {`${el.metadata.originalName.substring(
                                0,
                                10
                              )}...`}
                            </Button>
                          </Col>
                        </div>
                      ))
                  : null}
              </Row>
            </Card>
          </>
        )}
      </Spin>
    </ContentCustom>
  );
};

export default PreIntervention;
