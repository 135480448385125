import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const ListCompanies = () => {
  const { t } = useTranslation();
  const columns = useColumns();
  const { type } = useParams();
  const headers = [
    {
      label: 'Nom',
      key: 'name'
    },
    {
      label: 'Forme juridique',
      key: 'type'
    },
    {
      label: 'Forme juridique',
      key: 'legal_form'
    },
    {
      label: 'Adresse',
      key: 'address_details_1'
    },
    {
      label: "Complement d'addresse",
      key: 'address_details_2'
    },
    {
      label: 'Code postal',
      key: 'postal_code'
    },
    {
      label: 'Ville',
      key: 'city'
    },
    {
      label: 'SIREN',
      key: 'siren_number'
    },
    {
      label: 'APE',
      key: 'ape_number' || ''
    },
    {
      label: 'Capital social',
      key: 'share_capital' || ''
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      tradKey={t(`companies.title.${type}`)}
      populate="designated_commercial"
      resourceName="companies"
      extraQuery={type === 'suppliers' ? 'type=SUPPLIERS' : 'status=CUSTOMERS'}
      // Remove extra query to display all company
      // Types filter must be used for contacts
      headers={headers}
      withUploadButton={false}
    />
  );
};

export default ListCompanies;
