import React from 'react';
import { Button, Col, Form, Input, Row, Space } from 'antd';
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  PlusOutlined
} from '@ant-design/icons';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { tailFormItemLayout } from './formLayout';
import DangerPopconfirm from './customPopconfirm';

const { Search } = Input;

const ButtonAdd = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button type="primary" onClick={onClick}>
      <span style={{ fontSize: '15px' }}>{t('buttons.create')}</span>{' '}
      <PlusOutlined />
    </Button>
  );
};

const FormButton = ({ cancelButton, customLayout }) => {
  const { t } = useTranslation();
  const layout = customLayout || tailFormItemLayout;
  return (
    <Form.Item {...layout} style={{ display: 'flex', justifyContent: 'end' }}>
      <Button type="primary" htmlType="submit">
        {t('buttons.save')}
        <CheckOutlined />
      </Button>
      <Button
        onClick={cancelButton}
        style={{
          background: '#fff',
          color: 'var(--secondaryColor)',
          borderColor: 'var(--secondaryColor)',
          borderRadius: 3,
          float: 'right'
        }}
      >
        {t('buttons.cancel')}
        <CloseOutlined />
      </Button>
      <br />
    </Form.Item>
  );
};

const DetailsButton = ({ path, id, deleteRessource }) => {
  const { t } = useTranslation();
  return (
    <Space>
      <DangerPopconfirm deleteRessource={deleteRessource} id={id} />
      <Link to={path + id}>
        <Button type="primary">
          <span>{t('buttons.edit')}</span>
          <EditOutlined />
        </Button>
      </Link>
    </Space>
  );
};

const SearchAddBar = ({ addPath, setSearchValue, onClick }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const currentFilters = params.get('f');
  const currentSorter = params.get('s');
  const history = useHistory();

  const isReductScreen = useMediaQuery({ query: '(max-width: 900px)' });

  const searchResource = (value) => {
    setSearchValue(value)
    if (value) {
      history.push({
        pathname,
        search: `?p=1${currentSorter ? `&s=${currentSorter}` : ''}${
          currentFilters ? `&f=${currentFilters}` : ''
        }&k=${value}`
      });
    } else {
      history.push({
        pathname,
        search: `?p=1${currentSorter ? `&s=${currentSorter}` : ''}${
          currentFilters ? `&f=${currentFilters}` : ''
        }`
      });
    }
  };

  return (
    <Row style={{ marginBottom: 30, marginTop: 30 }}>
      <Col xs={15} sm={15} lg={21}>
        <Search
          allowClear
          placeholder={t('placeholder.search')}
          onSearch={(value) => searchResource(value)}
          style={{ width: isReductScreen ? '100%' : 500 }}
        />
      </Col>
      <Col xs={9} sm={9} lg={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link to={addPath}>
          <ButtonAdd type="link" onClick={onClick} />
        </Link>
      </Col>
    </Row>
  );
};

export { ButtonAdd, FormButton, DetailsButton, SearchAddBar };
