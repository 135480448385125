import React from 'react';
import useColumns from './Columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const Banks = () => {
  const columns = useColumns();

  const headers = [
    {
      label: 'libellé',
      key: 'name'
    },
    {
      label: 'Adresse',
      key: 'address'
    },
    {
      label: 'Ville',
      key: 'city'
    },
    {
      label: 'Téléphone',
      key: 'phone_number'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="bank-settings"
      withSubRoutes
      populate="file"
      headers={headers}
      resourceModelName="Bank"
      withUploadButton={false}
    />
  );
};

export default Banks;
