import React from 'react';
import { Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useAccountingContext from '../../AccountingContext';

const Container = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minHeight: 150px;
`;
const { Title } = Typography;

const Organization = ({ isLoading, display }) => {
  const { t } = useTranslation();
  const { collection, organization } = useAccountingContext();

  return (
    <Container>
      <Skeleton loading={isLoading} paragraph={{ rows: 1, width: '50%' }}>
        {display && (
          <>
            <Title level={5} style={{ marginBottom: '0', color: 'lightgrey' }}>
              {t(`${collection}.show.organization`)}
            </Title>
            <Title level={3} style={{ marginTop: '0' }}>
              {display.name}
            </Title>
            {`${display.channel_number} ${display.address_details}`}
            <br />
            {`${display.postal_code} ${display.city}`}
          </>
        )}
        {!display && organization && (
          <>
            <Title level={5} style={{ marginBottom: '0', color: 'lightgrey' }}>
              {t(`${collection}.show.supplier`)}
            </Title>
            <Title level={3} style={{ marginTop: '0' }}>
              {organization.name}
            </Title>
            {`${organization.channel_number} ${organization.address_details}}`}

            <br />
            {`${organization.postal_code} ${organization.city}`}
          </>
        )}
      </Skeleton>
    </Container>
  );
};

Organization.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  display: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  organization: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

Organization.defaultProps = {
  display: {},
  organization: {}
};

export default Organization;
