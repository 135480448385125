import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Table, Tag, Descriptions } from 'antd';
import {
  invoiceTypes,
  type_invoices
} from '../../../../utils/constants/tagColors';

const ListInvoices = ({ invoices, siteName }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: 'Numéro',
      dataIndex: 'number',
      render: (text, record) => (record.email_sent ? text : `P-${text}`)
    },
    {
      title: 'Sujet',
      dataIndex: 'subject'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type) =>
        invoiceTypes[type] ? (
          <Tag color={invoiceTypes[type]}>{t(`invoices.tags.${type}`)}</Tag>
        ) : (
          ''
        )
    },
    {
      title: 'Catégorie',
      dataIndex: 'type_invoice',
      render: (type_invoice) =>
        type_invoices[type_invoice] ? (
          <Tag color={type_invoices[type_invoice]}>
            {t(`invoices.tags.${type_invoice}`)}
          </Tag>
        ) : (
          ''
        )
    },

    {
      title: 'Total à payer',
      dataIndex: 'total',
      render: (total) => `${total ? total?.toFixed(2) : ''} €`
    },
    {
      title: 'Situation de paiment',
      dataIndex: 'payments',
      render: (payments) =>
        payments.map((pay) => (
          <p>
            {`${moment(pay.date).format('DD-MM-YYYY')}: Versement de ${
              pay?.amount_paid
            } € (${pay?.type || ''})`}
          </p>
        ))
    },
    {
      title: 'Total versé',
      dataIndex: 'total_paid',
      render: (total_paid) => `${total_paid} €`
    },
    {
      title: 'Échéance',
      dataIndex: 'deadline_date',
      render: (deadline_date) => moment(deadline_date).format('DD-MM-YYYY')
    },
    {
      title: 'Cloturé le',
      dataIndex: 'payment_date',
      render: (payment_date) =>
        payment_date && (
          <Tag color="green">{moment(payment_date).format('DD-MM-YYYY')}</Tag>
        )
    }
  ];
  return (
    <>
      <Descriptions title={`Listes des factures liées au chantier ${siteName}`}>
        <Descriptions.Item className="invoice-full-width">
          <Table columns={columns} dataSource={invoices} />
        </Descriptions.Item>
      </Descriptions>
    </>
  );
};

ListInvoices.propTypes = {
  invoices: PropTypes.oneOfType([PropTypes.array]).isRequired,
  siteName: PropTypes.string
};
ListInvoices.defaultProps = {
  siteName: ''
};

export default ListInvoices;
