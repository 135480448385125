import React from 'react';
import PropTypes from 'prop-types';
import { Chart, Geom, Axis, Tooltip, Label } from 'bizcharts';

import { Card } from 'antd';

const ChartValuation = ({ data }) => {
  const scales = {
    month: { formatter: (val) => `${val}` },
    sum: { formatter: (val) => `${val} €` }
  };
  return (
    <>
      <Card title="Valorisation des devis (validés)" className="home-card">
        {data && data.length > 0 ? (
          <Chart
            height={300}
            data={data}
            scale={scales}
            forceFit
          >
            <Axis name="sum" />
            <Axis name="month" />
            <Tooltip />
            <Geom
              type="interval"
              position="month*sum"
              color={['month', '#E6F6C8-#3376CB']}
              select={[
                true,
                {
                  mode: 'single',
                  style: {
                    stroke: '#2C9D61',
                    lineWidth: 3
                  },
                  cancelable: false,
                  animate: true
                }
              ]}
            >
              <Label content="sum" />
            </Geom>
          </Chart>
        ) : null}
      </Card>
    </>
  );
};
ChartValuation.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

ChartValuation.defaultProps = {
  data: []
};

export default ChartValuation;
