import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Tag } from 'antd';
import { quotationsStatus } from '../../../utils/constants/tagColors';
import useAuthContext from '../../../contexts/AuthContext';

const useColumns = (t, companies) => {
  const [enumsFilter, setEnumsFilter] = useState([]);
  const { dispatchAPI } = useAuthContext();

  const fetchEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'quotations/enums' });
      setEnumsFilter(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchEnums();
    })();
  }, []);

  return [
    {
      title: t('quotations.list.column.title.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true,
      render: (number, quotation) => {
        const obj = {
          children: number,
          props: {
            ...(['CANCELED', 'REFUSED', 'DUPLICATED'].includes(
              quotation.status
            ) && {
              style: { backgroundColor: '#d9d9d9' }
            })
          }
        };

        return obj;
      }
    },
    {
      title: t('quotations.list.column.title.subject'),
      dataIndex: 'subject',
      key: 'subject',
      sorter: true,
      render: (subject, quotation) => {
        const obj = {
          children: subject,
          props: {
            ...(['CANCELED', 'REFUSED', 'DUPLICATED'].includes(
              quotation.status
            ) && {
              style: { backgroundColor: '#d9d9d9' }
            })
          }
        };

        return obj;
      }
    },
    {
      title: t('quotations.list.column.title.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const obj = {
          children: (
            <Tag color={quotationsStatus[status]}>{t(`enums.${status}`)}</Tag>
          ),
          props: {
            ...(['CANCELED', 'REFUSED', 'DUPLICATED'].includes(status) && {
              style: { backgroundColor: '#d9d9d9' }
            })
          }
        };

        return obj;
      },
      sorter: true,
      filters: enumsFilter?.status?.map((e) => ({
        text: t(`enums.${e}`),
        value: e
      }))
    },
    {
      title: t('quotations.list.column.title.customer'),
      dataIndex: 'customer',
      key: 'customer',
      render: (customer, quotation) => {
        const obj = {
          children: customer && customer.name,
          props: {
            ...(['CANCELED', 'REFUSED', 'DUPLICATED'].includes(
              quotation.status
            ) && {
              style: { backgroundColor: '#d9d9d9' }
            })
          }
        };
        return obj;
      },
      sorter: true,
      filters:
        companies &&
        companies.map((c) => ({
          text: c.name,
          value: c._id
        }))
    },
    {
      title: t('quotations.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      key: 'emission_date',
      render: (date, quotation) => {
        const obj = {
          children: date && moment(date).format('DD/MM/YYYY'),
          props: {
            ...(['CANCELED', 'REFUSED', 'DUPLICATED'].includes(
              quotation.status
            ) && {
              style: { backgroundColor: '#d9d9d9' }
            })
          }
        };
        return obj;
      },
      sorter: true
    },
    {
      title: t('quotations.list.column.title.total'),
      dataIndex: 'total',
      render: (total, quotation) => {
        const obj = {
          children: `${parseFloat(total).toFixed(2)}€`,
          props: {
            ...(['CANCELED', 'REFUSED', 'DUPLICATED'].includes(
              quotation.status
            ) && {
              style: { backgroundColor: '#d9d9d9' }
            })
          }
        };
        return obj;
      },
      sorter: true,
      key: 'total'
    }
  ];
};

export default useColumns;
