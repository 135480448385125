import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import DetailsMonth from './DetailsMonth';
import ListHours from './ListHours';

const HoursRouter = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route path={`${path}/show/:id`} component={DetailsMonth} />
            <Route
                path={`${path}`}
                component={ListHours}
            />
        </Switch>
    );
};

export default HoursRouter;
