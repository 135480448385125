import React from 'react';
import { Badge } from 'antd';
import moment from 'moment';
import useInterventionContext from './InterventionContext';

const WeekNumber = () => {
  const {
    weekNumbers,
    start,
    startOfPeriod,
    getNewStartWithWeekNumber,
    displayToPlanned,
    year
  } = useInterventionContext();

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'scroll',
          width: '50%',
          margin: '1% auto'
        }}
      >
        {weekNumbers.map((weekNumber) => (
          <button
            type="button"
            autoFocus={start.week() === weekNumber}
            style={{
              color: start.week() === weekNumber ? '#fff' : '',
              backgroundColor:
                start.week() === weekNumber
                  ? 'var(--primaryColor)'
                  : 'transparent',
              minWidth: 40,
              minHeight: 40,
              margin: '20px 10px',
              border:
                startOfPeriod.week() === weekNumber && year === start.year()
                  ? '1px solid var(--primaryColor)'
                  : '1px solid transparent',
              borderRadius: '50%',
              textDecoration: 'none',
              outline: 'none',
              cursor: 'pointer'
            }}
            key={weekNumber}
            onClick={() => getNewStartWithWeekNumber(weekNumber)}
          >
            {displayToPlanned.find(
              (el) =>
                (el.quotation?.intervention_date_programmed === null
                  ? moment(
                      el.quotation?.intervention_date_expected
                    ).week()
                  : moment(
                      el.quotation?.intervention_date_programmed
                    ).week()) === weekNumber &&
                (el.quotation?.intervention_date_programmed === null
                  ? moment(
                      el.quotation?.intervention_date_expected
                    ).year()
                  : moment(
                      el.quotation?.intervention_date_programmed
                    ).year()) === year
            ) ? (
              <Badge dot offset={[-9, -15]} color="var(--primaryColor)">
                {weekNumber}
              </Badge>
            ) : (
              weekNumber
            )}
          </button>
        ))}
      </div>
    </div>
  );
};

export default WeekNumber;
