import React, { useCallback, useEffect } from 'react';
import useInvoiceContext from '../InvoiceContext';
import useHeaderFields from './headerFields';
import useBodyFields from './bodyFields';
import FreeLineInvoiceFields from './FreeLineInvoiceFields';
import PrestationsLinesInvoicesFields from './PrestationsLinesInvoicesFields';
import TotalFields from './totalFields';

const useFields = (purpose, form, number, siteDisabled) => {
  const { headerFields } = useHeaderFields(purpose, siteDisabled);
  const { bodyFields } = useBodyFields(purpose);
  const {
    getOrganizations,
    getSites,
    setIsLoading,
    getContacts,
    getEnums,
    getSettings,
    fetchVats,
    getBanks,
    getPrestations
  } = useInvoiceContext();

  const fields = {
    header: headerFields,
    body: bodyFields,
    prestations: <PrestationsLinesInvoicesFields form={form} />,
    free: <FreeLineInvoiceFields form={form} />,
    total: <TotalFields number={number} />
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await getOrganizations();
    await getSettings();
    await getSites();
    await getContacts();
    await getEnums();
    await fetchVats();
    await getBanks();
    await getPrestations();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return fields;
};

export default useFields;
