import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const QuotationContext = createContext({});

export const QuotationContextProvider = ({ children }) => {
  const { pathname } = useLocation();
  const [contact, setContact] = useState(null);
  const [company, setCompany] = useState({});
  const [sites, setSites] = useState([]);
  const [prestationsList, setPrestationsList] = useState([]);
  const [vat, setVat] = useState();
  const [vats, setVats] = useState();

  useEffect(() => {
    setPrestationsList([]);
    setContact(null);
    setCompany({});
  }, [pathname]);

  return (
    <QuotationContext.Provider
      value={{
        contact,
        setContact,
        company,
        setCompany,
        sites,
        setSites,
        prestationsList,
        setPrestationsList,
        vat,
        setVat,
        vats,
        setVats
      }}
    >
      {children}
    </QuotationContext.Provider>
  );
};

export default () => useContext(QuotationContext);
