import React from 'react';
import { Tag, Input, Table } from 'antd';
import moment from 'moment';
import {
  userRoles,
  userPermissions,
  userTypes
} from '../../utils/constants/tagColors';

const { TextArea } = Input;

const userList = (data, t) => {
  const {
    first_name,
    last_name,
    role,
    type,
    permission,
    address,
    date_of_birth,
    email,
    phone_number,
    last_login,
    creation_date,
    contract_end_date,
    email_signature,
    truck,
    organization,
    managed_trucks: managedTrucks
  } = data;

  const truckColumns = [
    {
      title: t('trucks.column.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('trucks.column.label'),
      key: 'nom',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('trucks.column.projection_unit'),
      key: 'projection_unit',
      dataIndex: 'projection_unit',
      sorter: true
    },
    {
      title: t('trucks.column.abbreviation'),
      key: 'abbreviation',
      dataIndex: 'abbreviation',
      sorter: true
    },
    {
      title: t('trucks.column.coefficient'),
      key: 'coefficient',
      dataIndex: 'coefficient',
      sorter: true
    }
  ];
  return [
    {
      label: 'users.form.role',
      span: 1,
      content:
        (role && (
          <Tag color={userRoles[role.split(':')[1]]}>
            {t(`users.tags.${role.split(':')[1]}`)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.permission',
      span: 1,
      content:
        (role && (
          <Tag color={userPermissions[permission?.permission_label]}>
            {t(permission?.permission_label)}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.type',
      span: 1,
      content:
        (type && (
          <Tag color={userTypes[type]}>{t(`users.type.${type}`)}</Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.organization',
      span: 1,
      content:
        (organization && (
          <Tag>
            {organization.name}
          </Tag>
        )) ||
        '-'
    },
    {
      label: 'users.form.last_name',
      span: 1,
      content: last_name || '-'
    },
    {
      label: 'users.form.first_name',
      span: 1,
      content: first_name || '-'
    },
    ...(type === 'TECH'
      ? [
          {
            label: 'users.form.truck',
            span: 3,
            content: (truck && truck.label) || '-'
          }
        ]
      : []),
    {
      label: 'users.form.email',
      span: 1,
      content: email || '-'
    },
    {
      label: 'users.form.phone_number_full',
      span: 2,
      content:
        phone_number && phone_number.number ? (
          <div>
            {`${phone_number.country_code} ${phone_number.number.slice(
              0,
              1
            )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                        `}
          </div>
        ) : (
          '-'
        )
    },
    {
      label: 'users.form.address_full',
      span: 3,
      content:
        address && address.number !== '' ? (
          <>
            {address.number}
            {address.street}
            <br />
            {address.additional}
            {address.additional && <br />}
            {address.postal_code}
            {address.city}
          </>
        ) : (
          '-'
        )
    },
    {
      label: 'users.form.date_of_birth',
      span: 3,
      content: date_of_birth ? moment(date_of_birth).format('ll') : '-'
    },
    {
      label: 'users.form.last_login',
      span: 1,
      content: last_login
        ? moment(last_login).format('DD/MM/YYYY à HH:mm')
        : '-'
    },
    {
      label: 'users.form.creation_date',
      span: 1,
      content: creation_date
        ? moment(creation_date).format('DD/MM/YYYY à HH:mm')
        : '-'
    },
    {
      label: 'users.form.contract_end_date',
      span: 1,
      content: contract_end_date
        ? moment(contract_end_date).format('DD/MM/YYYY à HH:mm')
        : '-'
    },
    {
      label: 'users.form.email_signature',
      span: 3,
      content:
        email_signature !== '' ? (
          <TextArea value={email_signature} rows={4} readOnly />
        ) : (
          '-'
        )
    },
    ...(type === 'MANAGER'
      ? [
          {
            label: 'users.form.managed_trucks',
            span: 3,
            content:
              managedTrucks !== [] ? (
                <Table
                  columns={truckColumns}
                  dataSource={
                    managedTrucks &&
                    managedTrucks.map(
                      ({
                        code,
                        label,
                        projection_unit,
                        abbreviation,
                        coefficient
                      }) => ({
                        code,
                        label,
                        projection_unit,
                        abbreviation,
                        coefficient
                      })
                    )
                  }
                  pagination={false}
                />
              ) : (
                '-'
              )
          }
        ]
      : [])
  ];
};

export default userList;
