import React from 'react';
import { Modal, Descriptions } from 'antd';
import PropTypes from 'prop-types';

const ShowModalContact = ({ setShowModal, showModal, rest }) => {
  return rest ? (
    <Modal
      title={`${rest?.subject} : Relance pour la facture n° ${rest?.number}`}
      visible={showModal}
      closable
      width={800}
      destroyOnClose
      onCancel={() => setShowModal(!showModal)}
      footer={false}
    >
      <Descriptions title={rest.customer.name}>
        <Descriptions.Item label="Nom">
          {rest.contact.last_name}
        </Descriptions.Item>
        <Descriptions.Item label="Prénom">
          {rest.contact.first_name}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {rest.customer.email}
        </Descriptions.Item>
        <Descriptions.Item label="Téléphone">
          {rest.customer.phone_number_mobile}
        </Descriptions.Item>
      </Descriptions>
    </Modal>
  ) : (
    <p>Error</p>
  );
};

ShowModalContact.propTypes = {
  setShowModal: PropTypes.func.isRequired,
  showModal: PropTypes.bool.isRequired,
  rest: PropTypes.oneOfType([PropTypes.object])
};
ShowModalContact.defaultProps = {
  rest: null
};

export default ShowModalContact;
