import React, { useState } from 'react';
import { Button, Form, Input, Popconfirm, Popover, Select, Switch, Table, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { kanbanIcon } from './utilsKanban';
import { ChromePicker } from 'react-color';
import { CloseOutlined, DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  checkType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  ...restProps
}) => {
  const popoverContent = (
    <ChromePicker
      color={colorPicker || '#fff'}
      onChangeComplete={color => {
        setColorPicker(color.hex);
      }}
    />
  );
  let inputNode;
  switch (inputType) {
    case 'text':
      inputNode = !checkType ? (
        <Input />
      ) : (
        <Switch
          defaultChecked={record.show}
        />
      );
      break;
    case 'select':
      if (selectType === 'icon') {
        inputNode = (
          <Select>
            {kanbanIcon.map(icon => (
              <Option key={icon} value={icon.label}>{icon.value}</Option>
            ))}
          </Select>
        );
      } else {
        inputNode = (
          <Popover content={popoverContent} title="Title">
            <Button
              style={{
                backgroundColor: colorPicker || record.color,
                border: 'none',
                color: '#fff'
              }}
              value={colorPicker}
            >
              {colorPicker || record.color}
            </Button>
          </Popover>
        );
      }
      break;
    default:
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const KanbanTable = ({ kanbanCols, editKanbanCol, deleteKanbanCol }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [colorPicker, setColorPicker] = useState('');
  const [switchLoading, setSwitchLoading] = useState(false)

  const isEditing = record => record._id === editingKey;

  const edit = record => {
    form.setFieldsValue({
      label: record.label,
      icon: record.icon,
      color: record.color,
      show: record.show,
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };
  const setColor = async ({ color }) => {
    await form.setFieldsValue({
      color: colorPicker || color
    });
  };

  const saveSwitch = async ( record ) => {
    try {
      editKanbanCol(record._id, record);
      setEditingKey('');
    } catch (e) {
      console.log("Error with Switch change: ", e)
    }
  }

  const save = async record => {
    await setColor(record);
    try {
      const row = await form.validateFields();
      editKanbanCol(record._id, row);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: t('settings.kanbantable.label'),
      dataIndex: 'label',
      width: '20%',
      editable: true
    },
    {
      title: t('settings.kanbantable.icon'),
      dataIndex: 'icon',
      width: '20%',
      editable: true,
      render: icon => {
        if (kanbanIcon.filter(ic => ic.label === icon).length > 0) {
          return kanbanIcon.filter(ic => ic.label === icon)[0].value;
        }
      }
    },
    {
      title: t('settings.kanbantable.color'),
      dataIndex: 'color',
      width: '20%',
      editable: true,
      render: color => <Tag color={color}>{color}</Tag>
    },
    {
      title: t('settings.kanbantable.show'),
      dataIndex: 'show',
      width: '20%',
      editable: true,
      render: (value, record) => <Switch loading={switchLoading} checked={value} onClick={async () => {
        setSwitchLoading(true)
        setTimeout(async function(){
          record.show = !value
          await saveSwitch(record)
          setSwitchLoading(false)
        }, 500);
      }}/>
    },
    {
      title: t('settings.kanbantable.action'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Button href="javascript:;" onClick={() => save(record)} style={{ borderRadius: 0}}>
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm title={t('settings.messages.confirm-deletion')} onConfirm={cancel}>
              <Button style={{ borderRadius: 0}}>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <>
            <Button
              onClick={() => edit(record)}
              disabled={editingKey !== ''}
              type="primary"
              style={{ borderRadius: 0}}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>

            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteKanbanCol(record)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0}}
              >
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: ['color', 'icon'].includes(col.dataIndex)
          ? 'select'
          : 'text',
        selectType: col.dataIndex === 'color' ? 'color' : 'icon',
        checkType: col.dataIndex === 'show',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        colorPicker: colorPicker,
        setColorPicker: setColorPicker
      })
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={kanbanCols}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export default KanbanTable;
