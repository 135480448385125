import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

const useColumnsCstb = () => {
  const { t } = useTranslation();

  const setColorTag = {
    VALIDATE: 'success',
    PENDING: 'processing',
    WAITING_VALIDATION: 'warning'
  };

  return [
    {
      title: t('cstb.list.column.title.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('cstb.list.column.title.status'),
      dataIndex: 'status',
      key: 'status',
      render: (text) => (
        <Tag color={setColorTag[text]}>{t(`cstb.status.${text}`)}</Tag>
      ),
      sorter: true
    },
    {
      title: t('cstb.list.column.title.details'),
      dataIndex: 'details',
      key: 'details',
      render: (details) =>
        `${details &&
          details.map((e) => e.area).reduce((a, b) => a + b, 0)} m²`,
      sorter: true
    },
    {
      title: t('cstb.list.column.title.cycle'),
      dataIndex: 'cycle',
      key: 'cycle',
      render: (cycle) => cycle,
      sorter: true
    }
  ];
};

export default useColumnsCstb;
