import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Modal,
  Select,
  Row,
  InputNumber,
  Input,
  Form,
  Descriptions
} from 'antd';
import { ProfileOutlined } from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

const { Option } = Select;
const CreateCreditInvoiceModal = ({
  setShowModalCredit,
  showModalCredit,
  getInvoices,
  quotationData
}) => {
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [vats, setVats] = useState([]);
  const [banks, setBanks] = useState([]);
  const [disabled, setDisabled] = useState();

  const createInvoice = async (body) => {
    try {
      const {
        contact,
        customer,
        number,
        organization,
        site,
        _id,
        bank_account,
        ...rest
      } = quotationData;
      const result = {
        ...rest,
        email_sent: false,
        emission_date: null,
        deadline_date: null,
        contact: contact?._id,
        customer: customer?._id,
        quotation: _id,
        organization: organization?._id,
        payment_bank: bank_account ? bank_account._id : body.bank_account,
        site: site?._id,
        type: 'CREDIT',
        type_invoice: 'CREDIT',
        prestations: [
          {
            label: body?.description,
            unit_price: body.subtotal,
            subtotal: body.subtotal,
            discount: null
          }
        ],
        subTotal: body.subtotal,
        vat: vats.filter((vat) => vat._id === body.tva)[0]._id,
        total_VAT:
          (vats.filter((vat) => vat._id === body.tva)[0].rate / 100) *
          body.subtotal,

        total_discount: 0,
        total: body.total
      };
      await dispatchAPI('POST', {
        url: `/invoices/`,
        body: {
          ...result
        }
      });
      getInvoices();
      setShowModalCredit(false);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getVats = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/vats'
      });
      setVats(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getBanks = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/bank-settings'
      });
      setBanks(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    getVats();
    getBanks();
  }, []);
  useEffect(() => {
    form.setFieldsValue({
      credit: {
        tva: vats?.filter((vat) => vat.rate === 0)[0]?._id,
        bank_account:
          (quotationData.bank_account && quotationData.bank_account._id) || null
      }
    });
  }, [quotationData]);

  const handleSubmit = (values) => {
    if (values.credit) {
      createInvoice(values.credit);
    }
  };
  const invoiceConfirm = () => {
    setDisabled(true);
  };
  const onChangeValue = (_, values) => {
    const { tva, price } = values.credit;
    if (price)
      form.setFieldsValue({
        credit: {
          subtotal: -price
        }
      });
    if (tva && price) {
      const rate = vats.filter((vat) => vat._id === tva)[0].rate;
      form.setFieldsValue({
        credit: {
          total: -((rate / 100) * price + price).toFixed(2)
        }
      });
    }
  };

  return (
    <Modal
      title={`Facturation d'avoir devis n79878998 ° ${quotationData?.number}`}
      visible={showModalCredit}
      width={1000}
      destroyOnClose
      onCancel={() => setShowModalCredit(false)}
      footer={false}
    >
      <Descriptions title="Informations devis" column={3}>
        <Descriptions.Item label="Montant global">
          {`${quotationData?.total} € TTC`}
        </Descriptions.Item>
        <Descriptions.Item label="Client">
          {`${quotationData?.customer?.name}`}
        </Descriptions.Item>
        <Descriptions.Item label="Banque">
          {`${quotationData?.bank_account?.name || 'Aucune banque saisie'}`}
        </Descriptions.Item>
        <Descriptions.Item label="Référence chantier">
          {`${quotationData?.site?.reference || 'Aucune référence'}`}
        </Descriptions.Item>
      </Descriptions>
      <Divider>Saisie Avoir</Divider>
      <Form
        form={form}
        onValuesChange={onChangeValue}
        onFinish={(values) => handleSubmit(values)}
      >
        <Descriptions>
          <Descriptions.Item label="Libellé">
            <Form.Item noStyle noLabel name={['credit', 'description']}>
              <Input
                style={{ maxWidth: 300 }}
                disabled={disabled}
                placeholder="Description..."
              />
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item label="Montant">
            <Form.Item noStyle noLabel name={['credit', 'price']}>
              <InputNumber
                disabled={disabled}
                formatter={(value) => value && `${value}€`}
                parser={(value) => value.replace('€', '')}
              />
            </Form.Item>
          </Descriptions.Item>

          <Descriptions.Item label="Taux TVA">
            <Form.Item noStyle noLabel name={['credit', 'tva']}>
              <Select
                disabled={disabled}
                placeholder="TVA"
                style={{ width: 200 }}
              >
                {vats?.map((e) => (
                  <Option value={e._id} key={e._id}>
                    {`Taux: ${e.rate} % - ${e.code_number}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Banque">
            <Form.Item noStyle noLabel name={['credit', 'bank_account']}>
              <Select
                disabled={disabled}
                placeholder="Saisir la banque"
                defaultValue={
                  quotationData && quotationData.bank_account
                    ? quotationData.bank_account._id
                    : null
                }
                style={{ width: 250 }}
              >
                {banks?.map((e) => (
                  <Option value={e._id} key={e._id}>
                    {`${e.name} - ${e.city}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>
        <Divider>Montant de la facture</Divider>
        <Descriptions>
          <Descriptions.Item span={2} label="Total HT">
            <Form.Item noStyle name={['credit', 'subtotal']}>
              <InputNumber
                formatter={(value) => value && `${value}€`}
                parser={(value) => value.replace('€', '')}
                bordered={false}
                readOnly
              />
            </Form.Item>
          </Descriptions.Item>
          <Descriptions.Item label="Total TTC">
            <Form.Item noStyle name={['credit', 'total']}>
              <InputNumber
                style={{ width: 200 }}
                formatter={(value) => value && `${value}€`}
                parser={(value) => value.replace('€', '')}
                bordered={false}
                readOnly
              />
            </Form.Item>
          </Descriptions.Item>
        </Descriptions>

        <Row style={{ marginTop: 10 }} justify="center">
          <Button
            onClick={() => invoiceConfirm()}
            disabled={disabled}
            type="primary"
            ghost
          >
            Valider une facture d&apos;acompte
          </Button>
          {disabled ? (
            <Button
              style={{ left: 10 }}
              type="secondary"
              danger
              onClick={() => setDisabled(false)}
            >
              Modifier
            </Button>
          ) : null}
        </Row>
        <Divider />
        <Row justify="center">
          <Button
            disabled={!disabled}
            type="primary"
            htmlType="submit"
            icon={<ProfileOutlined />}
          >
            Facturer
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

CreateCreditInvoiceModal.propTypes = {
  setShowModalCredit: PropTypes.func.isRequired,
  showModalCredit: PropTypes.bool.isRequired,
  getInvoices: PropTypes.func.isRequired,
  quotationData: PropTypes.oneOfType([PropTypes.object])
};
CreateCreditInvoiceModal.defaultProps = {
  quotationData: null
};
export default CreateCreditInvoiceModal;
