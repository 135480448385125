import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import General from './config';

const SuppliersRouter = () => {
  const { path } = useRouteMatch();

  return (
      <Switch>
        <Route path={path} component={General} />
      </Switch>
  );
};

export default SuppliersRouter;
