import {
  Button,
  Col,
  DatePicker,
  Divider,
  Modal,
  InputNumber,
  Row,
  Tag,
  Select
} from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

const { Option } = Select;

const ShowPaidModal = ({ setShowModal, showModal, rest, getInvoice }) => {
  const { t } = useTranslation();
  const { message: errorMessage } = useErrorMessage();
  const [selectedDate, setSelectedDate] = useState();
  const { dispatchAPI } = useAuthContext();
  const [payment, setPayment] = useState(0);
  const paymentList = [
    'Chèque',
    'Virement',
    'Espèces',
    'Carte Bleu',
    'A solder par'
  ];
  const [paymentType, setPaymentType] = useState();
  const [disabled, setDisabled] = useState(false);

  const patchPayment = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/invoices/${rest?._id}`,
        body: {
          payment: {
            date: selectedDate,
            amount_paid: payment,
            type: paymentType
          }
        }
      });
      setShowModal(false);
      getInvoice();
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  return (
    <Modal
      title={`${rest.subject} : Solder la facture n° ${rest?.number}`}
      visible={showModal}
      closable
      width={800}
      onCancel={() => setShowModal(!showModal)}
      footer={false}
    >
      <p>Veuillez selectionner une date de paiment</p>

      <DatePicker
        size="large"
        style={{ margin: 'auto', width: '100%' }}
        onChange={(date) => setSelectedDate(date)}
      />
      <Row style={{ marginTop: 50 }} justify="space-around">
        <span>Somme à régler :</span>
        <Tag>
          {` ${parseFloat(
            rest?.total_paid ? rest?.total - rest?.total_paid : rest?.total
          ).toFixed(2)} €`}
        </Tag>
      </Row>
      <Row style={{ margin: 50 }} justify="space-between">
        <Col span={10}>
          <span>Montant du paiment :</span>
        </Col>
        <Col span={14}>
          <Row justify="space-around">
            <InputNumber
              size="middle"
              onChange={(number) => setPayment(number)}
              min={0}
              max={parseFloat(
                rest?.total_paid ? rest?.total - rest?.total_paid : rest?.total
              ).toFixed(2)}
              value={payment}
            />
            <span>€</span>
            <Button
              onClick={() =>
                setPayment(
                  parseFloat(
                    rest?.total_paid
                      ? rest?.total - rest?.total_paid
                      : rest?.total
                  ).toFixed(2)
                )
              }
              style={{ marginLeft: 10 }}
              type="primary"
            >
              Régler la totalité
            </Button>
          </Row>
        </Col>
      </Row>
      <Row justify="center">
        <Col span={12}>
          <span>Type de paiment :</span>
        </Col>
        <Col span={12}>
          <Select
            style={{ width: '100%' }}
            size="large"
            onChange={(option) => setPaymentType(option)}
            defaultValue={rest?.customer?.payment_type}
          >
            {paymentList.map((e) => (
              <Option value={e} key={e}>
                {e}
              </Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider />
      <Row justify="end">
        <Col span={5}>
          <Button type="link" danger onClick={() => setShowModal(!showModal)}>
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
        </Col>
        <Col span={5}>
          <Button
            disabled={disabled || (!paymentType && !selectedDate)}
            onClick={() => {
              setDisabled(true);
              patchPayment();
            }}
            type="add"
          >
            {`${t('buttons.validate')} `}
            <CheckOutlined />
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

ShowPaidModal.propTypes = {
  setShowModal: PropTypes.func,
  getInvoice: PropTypes.func.isRequired,
  showModal: PropTypes.bool,
  rest: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string
  ])
};

ShowPaidModal.defaultProps = {
  setShowModal: null,
  showModal: null,
  rest: null
};

export default ShowPaidModal;
