import React, { useEffect, useState } from 'react';
import useAuthContext from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next';
import { Button, message } from 'antd';
import PartsTypesTable from './PartsTypesTable';

const PartTypeSettings = () => {
    const { dispatchAPI } = useAuthContext();
    const { t } = useTranslation();
    const [partsTypes, setPartsTypes] = useState([]);
    const [newPartType] = useState({
        label: 'label ...',
        accounting_code: 'code ...',
        in_incomes: false,
        in_expenses: false
    });
    const fetchPartsTypes = async () => {
        try {
            const data = await dispatchAPI('GET', {
                url: `/parts-types`
            });
            setPartsTypes(data.data);
        } catch (e) {
            message.error(e.message);
        }
    };
    const addPartType = async () => {
        try {
            await dispatchAPI('POST', { url: '/parts-types', body: newPartType });
        } catch (e) {
            message.error(e.message);
        }
        await fetchPartsTypes();
    };
    const deletePartType = async type => {
        try {
            await dispatchAPI('DELETE', {
                url: `/parts-types/${type._id}`
            });
        } catch (e) {
            message.error(t('settings.errors.part_type_delete'));
        }
        await fetchPartsTypes();
    };
    const editPartType = async (_id, row) => {
        try {
            await dispatchAPI('PATCH', {
                url: `/parts-types/${_id}`,
                body: row
            });
        } catch (e) {
            message.error(e.message);
        }
        await fetchPartsTypes();
    };
    useEffect(() => {
        (async () => {
            await fetchPartsTypes();
        })();
    }, []);    

    return (
        <>
            {partsTypes.length > 0 && (
                <>
                    <PartsTypesTable deletePartType={deletePartType} editPartType={editPartType} partsTypes={partsTypes} />
                </>
            )}
            <Button
                type="dashed"
                style={{ width: '100%', height: 50 }}
                onClick={addPartType}
            >
                {t('settings.partsTypesTable.addPart')}
            </Button>
        </>
    );
};

export default PartTypeSettings;