import React, { useEffect, useState } from 'react';
import { Button, Row } from 'antd';
import { PlusOutlined, MinusOutlined, SwapOutlined } from '@ant-design/icons';
import columns from './columns';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import Datatable from '../../../components/DataTable/Datatable';
import useMovementStockContext from './MovementStockContext';
import ModalToSetType from './ModalToSetType';

const ListMovementsStock = () => {
  const { user } = useAuthContext();
  const [purpose, setPurpose] = useState();
  const {
    visibleTypeOfMovement,
    setVisibleTypeOfMovement,
    getAllDeposit
  } = useMovementStockContext();

  useEffect(() => {
    getAllDeposit();
  }, [user]);

  return (
    <>
      <PageHeaderCustom title="Mouvements de stock" />
      <ContentCustom>
        <Row justify="end" gutter={[8, 16]}>
          {user.type !== 'TECH' && (
            <>
              <Button
                icon={<SwapOutlined />}
                type="add"
                style={{ marginRight: '8px' }}
                onClick={() => {
                  setPurpose('inventory-transfer');
                  setVisibleTypeOfMovement(true);
                }}
              >
                Transfert de stock
              </Button>

              <Button
                onClick={() => {
                  setPurpose('enter');
                  setVisibleTypeOfMovement(true);
                }}
                type="add"
                style={{ marginRight: '8px' }}
              >
                {`Nouvelle entrée`}
                &nbsp;
                <PlusOutlined />
              </Button>
            </>
          )}
          <Button
            onClick={() => {
              setPurpose('exit');
              setVisibleTypeOfMovement(true);
            }}
            type="add"
          >
            {`Nouvelle sortie`}
            &nbsp;
            <MinusOutlined />
          </Button>
        </Row>
        <Datatable
          style={{ marginTop: 24 }}
          resourceName="stock-movements"
          resourceModelName="StockMovement"
          columns={[...columns()]}
          populate="user,truck,deposit"
          extraQuery="sort=-created_at"
          withoutUpdateRoute
        />
        <ModalToSetType
          visible={visibleTypeOfMovement}
          setVisible={setVisibleTypeOfMovement}
          purpose={purpose}
        />
      </ContentCustom>
    </>
  );
};

export default ListMovementsStock;
