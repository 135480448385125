import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Menu } from 'antd';
import {
  EuroCircleOutlined,
  EditOutlined,
  SettingOutlined,
  MailOutlined,
  PrinterOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import ModalForSendMailInvoice from './ModalForSendMailInvoice';
import ShowPaidModal from './ShowPaidModal';

const { SubMenu } = Menu;

const ExtraShowActions = ({ invoice, getResource }) => {
  const [current, setCurrent] = useState();
  const [showModal, setShowModal] = useState(false);
  const [visibleMailModal, setVisibleMailModal] = useState(false);
  const { t } = useTranslation();
  const handleClick = (e) => {
    setCurrent(e.key);
  };
  return (
    <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
      <Menu.Item key="mail" icon={<MailOutlined />}>
        <Button
          type="link"
          disabled={invoice.total === 0 || invoice.total === undefined}
          onClick={() => setVisibleMailModal(!visibleMailModal)}
        >
          Envoyer la facture
        </Button>
        <ModalForSendMailInvoice
          data={invoice}
          visible={visibleMailModal}
          setVisible={setVisibleMailModal}
          getResource={getResource}
        />
      </Menu.Item>
      <SubMenu key="manage" icon={<SettingOutlined />} title="Gestion facture">
        <Menu.Item icon={<EditOutlined />} key="edit">
          <Link to={{ pathname: `/accounting/invoices/edit/${invoice._id}` }}>
            <Button disabled={invoice.email_sent} type="link">
              {t('buttons.edit')}
            </Button>
          </Link>
        </Menu.Item>
        <Menu.Item icon={<EuroCircleOutlined />} key="paid">
          <Button
            disabled={!invoice.email_sent}
            type="link"
            onClick={() => setShowModal(!showModal)}
          >
            {`${t('invoices.paid')} ${
              !invoice.email_sent ? "(La facture n'est pas émise)" : ''
            }`}
          </Button>
        </Menu.Item>
      </SubMenu>
      <SubMenu key="print" icon={<PrinterOutlined />} title="Impression">
        <Menu.Item icon={<PrinterOutlined />} key="print">
          <Button type="link">Facture</Button>
        </Menu.Item>

        <Menu.Item key="origin" icon={<PrinterOutlined />}>
          <Button type="link">Attestation TVA</Button>
        </Menu.Item>
      </SubMenu>
      <ShowPaidModal
        setShowModal={setShowModal}
        showModal={showModal}
        rest={invoice}
        getInvoice={getResource}
      />
    </Menu>
  );
};

ExtraShowActions.propTypes = {
  invoice: PropTypes.oneOfType([PropTypes.object]),
  getResource: PropTypes.func.isRequired
};
ExtraShowActions.defaultProps = {
  invoice: null
};

export default ExtraShowActions;
