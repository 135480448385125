import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Menu } from 'antd';
import { NavLink } from 'react-router-dom';
import navMenuLogos from '../../utils/constants/navMenuLogos';
import useHandleResize from '../../utils/HandleResize';
import { routes, subRoutes } from '../../utils/constants/routes';
import useAuthContext from '../../contexts/AuthContext';

const { SubMenu } = Menu;

const NavMenu = ({ path, setCollapsed }) => {
  const { t } = useTranslation();
  const { width } = useHandleResize();
  const authContext = useAuthContext();

  return (
    <Menu
      style={{
        padding: '16px 0',
        background: 'var(--clientColor)'
      }}
      selectedKeys={[path]}
      defaultOpenKeys={[path.split('/')[1]]}
      mode="inline"
      onSelect={width < 992 ? () => setCollapsed(true) : () => {}}
    >
      {Object.entries(routes)
        .filter(([resourceName]) =>
          authContext.checkShouldDisplayMenuItem(
            resourceName.toLowerCase(),
            true
          )
        )
        .map(([menuItem, p]) =>
          subRoutes[menuItem] ? (
            <SubMenu
              key={p}
              icon={navMenuLogos[menuItem]}
              title={t(`basiclayout.menu.${menuItem.toLowerCase()}`)}
            >
              {Object.entries(subRoutes[menuItem])
                .filter(([resourceName]) => {
                  return authContext.checkShouldDisplayMenuItem(
                    resourceName.toLowerCase(),
                    false
                  );
                })
                .map(([subMenuItem, subPath]) => (
                  <Menu.Item key={subPath} disabled={subPath === ''}>
                    <NavLink to={`${p}${subPath}`}>
                      <span>
                        {t(`basiclayout.submenu.${subMenuItem.toLowerCase()}`)}
                      </span>
                    </NavLink>
                  </Menu.Item>
                ))}
            </SubMenu>
          ) : (
            <Menu.Item key={p} disabled={p === ''}>
              <NavLink to={p}>
                {navMenuLogos[menuItem]}
                <span>{t(`basiclayout.menu.${menuItem.toLowerCase()}`)}</span>
              </NavLink>
            </Menu.Item>
          )
        )}
    </Menu>
  );
};

NavMenu.propTypes = {
  path: PropTypes.string.isRequired,
  setCollapsed: PropTypes.func.isRequired
};

export default NavMenu;
