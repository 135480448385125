import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListMachines from './ListMachines';
import CreateUpdateMachine from './CreateUpdateMachine';
import ShowMachine from './ShowMachine';

const MachinesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateMachine purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateMachine purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowMachine} />
      <Route path={`${path}`} component={ListMachines} />
    </Switch>
  );
};

export default MachinesRouter;
