import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Card, Divider } from 'antd';

import moment from 'moment';

const QuotePart = ({ quote }) => {
  return (
    <Col span={12}>
      <Card>
        <Col style={{ fontWeight: 'bold' }} span={24}>
          {`Devis n° ${quote?.quotation?.number} du ${moment(
            quote?.quotation?.created_at
          ).format('DD-MM-YYYY')}`}
        </Col>
        {quote.type_invoice !== 'UPPER' ? (
          <>
            <Col span={24}>
              {quote?.quotation?.prestations.map((e) => (
                <Row key={e._id}>
                  <Col span={18}>
                    <span>{`- ${e.label} `}</span>
                  </Col>
                  <Col span={6}>
                    <span style={{ fontWeight: 'bold' }}>
                      {`${e.subtotal} €`}
                    </span>
                  </Col>
                </Row>
              ))}
            </Col>
          </>
        ) : (
          <Col span={24}>
            <span>
              {`Surplus de surface de ${quote?.prestations
                ?.map((e) => e?.area)
                .reduce((a, b) => a + b, 0)} m²`}
            </span>
          </Col>
        )}
        <Divider />
        <Row justify="space-around">
          <Col span={8}>
            <Col span={24}>Total HT</Col>
            <Col style={{ fontWeight: 'bold' }} span={24}>
              {`${quote?.quotation?.subTotal} €`}
            </Col>
          </Col>
          <Col span={8}>
            <Col span={24}>TVA</Col>
            <Col style={{ fontWeight: 'bold' }} span={24}>
              {`${quote?.quotation?.total_VAT} €`}
            </Col>
          </Col>
          <Col span={8}>
            <Col span={24}>Surface en m²</Col>
            <Col style={{ fontWeight: 'bold' }} span={24}>
              {`${quote?.quotation?.total_area} m²`}
            </Col>
          </Col>
          {/* <Col span={6}>
    <Col span={24}>Total TTC restant</Col>
    <Col style={{ fontWeight: 'bold' }} span={24}>
      {remaining ? (
        <span style={{ color: 'var(--addColor' }}>
          {`${parseFloat(
            quote?.quotation?.total - remaining
          ).toFixed(2)} €`}
        </span>
      ) : (
        <Tag>{`${quote?.quotation?.total} €`}</Tag>
      )}
    </Col>
  </Col> */}
        </Row>
        <Divider />
        <Row justify="space-around">
          <Col span={18}>
            <span>Total TTC</span>
          </Col>
          <Col span={6}>
            <span style={{ fontWeight: 'bold' }}>
              {`${quote?.quotation?.total} €`}
            </span>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

QuotePart.propTypes = {
  quote: PropTypes.oneOfType([PropTypes.object])
};
QuotePart.defaultProps = {
  quote: null
};

export default QuotePart;
