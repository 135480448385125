import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Modal, Descriptions, Input, Select } from 'antd';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useSiteContext from '../SiteContext';

const { Item } = Descriptions;
const { Option } = Select;
const ModalToAddInvoice = ({
  dataSite,
  visible,
  setVisible,
  refresh,
  setRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { addInvoiceToSite } = useSiteContext();
  const [listOrganizations, setListOrganizations] = useState();
  const [subject, setSubject] = useState();
  const [organization, setOrganization] = useState();
  const [type, setType] = useState();
  const fetchOrganizations = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/organizations' });
      setListOrganizations(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const listType = ['DEPOSIT', 'PAY', 'CREDIT'];
  useEffect(() => {
    fetchOrganizations();
  }, []);

  const onChangeInput = (e) => {
    setSubject(e.target.value);
  };
  const postInvoice = async () => {
    await addInvoiceToSite({
      site: dataSite._id,
      customer: dataSite.company._id,
      contact: dataSite?.contact._id,
      subject,
      organization,
      type
    });
    setRefresh(!refresh);
    setVisible(!visible);
  };
  return (
    <Modal
      destroyOnClose
      centered
      visible={visible}
      onCancel={() => setVisible(!visible)}
      title={dataSite?.reference}
      okText="Créer la facture"
      okButtonProps={{ disabled: !type || !organization || !subject }}
      onOk={() => postInvoice()}
    >
      {dataSite && (
        <>
          <Descriptions title="Entête facture" column={2}>
            <Item label="Client">{dataSite?.company?.name}</Item>
            <Item label="Contact">
              {`${dataSite?.contact?.first_name || ''} ${dataSite?.contact
                ?.last_name || ''}`}
            </Item>
            <Item label="Chantier">{dataSite?.reference}</Item>
          </Descriptions>
          <div style={{ textAlign: 'center' }}>
            <p style={{ fontWeight: 'bold' }}>Organisme</p>
            <Select
              onChange={(value) => setOrganization(value)}
              style={{ width: 200, margin: 5 }}
              showSearch
            >
              {listOrganizations?.map((orga) => (
                <Option key={orga._id} value={orga._id}>
                  {orga.name}
                </Option>
              ))}
            </Select>
            <p style={{ fontWeight: 'bold' }}>Type de facture</p>
            <Select
              onChange={(value) => setType(value)}
              style={{ width: 200, margin: 5 }}
              showSearch
            >
              {listType.map((typ) => (
                <Option key={typ} value={typ}>
                  {t(`invoices.tags.${typ}`)}
                </Option>
              ))}
            </Select>
            <Input
              onChange={(e) => onChangeInput(e)}
              style={{ marginTop: 20 }}
              addonBefore="Sujet"
            />
          </div>
        </>
      )}
    </Modal>
  );
};

ModalToAddInvoice.propTypes = {
  dataSite: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  refresh: PropTypes.bool.isRequired,
  setRefresh: PropTypes.func.isRequired
};
ModalToAddInvoice.defaultProps = {
  dataSite: null
};

export default ModalToAddInvoice;
