import React, { useState, useEffect } from 'react';
import { Line } from '@ant-design/charts';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useAuthContext from '../../../../contexts/AuthContext';

const LineGraph = () => {
  const [data, setData] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();

  const getGraphData = async () => {
    setIsLoading(true);
    dispatchAPI('GET', {
      url: `/dashboard/line/projection`
    })
      .then((response) => {
        setData(
          response.data.map((depot) => ({
            label: depot._id.depot.label,
            value: depot.value,
            month: depot._id.month
          }))
        );
      })
      .catch((error) => {
        message(error.response);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getGraphData();
  }, []);

  const brandColor = `#4db3a2`;

  const config = {
    data,
    xField: 'month',
    yField: 'value',
    seriesField: 'label',
    meta: {
      month: {
        formatter: function formatter(val) {
          const dateFormatter = new Intl.DateTimeFormat('fr', {
            month: 'short'
          });
          return dateFormatter.format(new Date().setMonth(val));
        }
      }
    },
    color: function color() {
      return brandColor;
    },
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return parseFloat(v)
            .toFixed(2)
            .concat(' kg');
        }
      }
    },
    label: {
      formatter: function formatter(item) {
        return item.value.toFixed(2).concat(' kg');
      }
    },
    xAxis: {
      label: {
        formatter: (month) => {
          let formattedMonth;
          formattedMonth = month;
          if (month < 10) {
            formattedMonth = `0${month}`;
          }
          return formattedMonth;
        }
      }
    },

    legend: { position: 'top' },
    smooth: true,
    animation: {
      appear: {
        animation: 'path-in',
        duration: 5000
      }
    }
  };
  return <Line loading={isLoading} {...config} />;
};
LineGraph.propTypes = {};

export default LineGraph;
