export const routes = {
  HOME: '/',
  USERS: '/users',
  COMMERCIAL: '/commercial',
  INTERVENTION: '/intervention',
  PRODUCTION: '/production',
  ACCOUNTING: '/accounting',
  PURCHASES: '/purchases',
  CONFIGURATION: '/configuration'
};

export const subRoutes = {
  CONFIGURATION: {
    GENERAL: '/general',
    TRUCKS: '/trucks',
    ORGANIZATIONS: '/organizations',
    PRESTATIONS: '/prestations',
    TEMPLATES: '/templates',
    GED: '/ged',
    SETTINGS: '/settings'
  },
  COMMERCIAL: {
    DEALS: '/deals',
    CUSTOMERS: '/customers',
    SITES: '/sites',
    QUOTATIONS: '/quotations'
  },
  INTERVENTION: {
    MANAGE_INTERVENTIONS: '/manage_interventions',
    MANAGE_HOURS: '/manage_hours',
    PLANNING: '/global_planning'
  },
  PURCHASES: {
    SUPPLIERS: '/suppliers'
  },

  ACCOUNTING: {
    INVOICES: '/invoices',
    BANK_SETTINGS: '/banks_settings',
    VAT_ACCOUNTS: '/vat_accounts',
    UNPAID: '/unpaid'

    // BANK_RECONCILIATION: '/reconciliations'
  },
  PRODUCTION: {
    DEPOSITS: '/deposits',
    PRIMARY_MATTERS: '/primary-matters',
    EPI: '/epi',
    CONSUMABLES: '/consumables',
    TOOLS: '/tools',
    STOCK_MOVEMENTS: '/stock-movements',
    INVENTORIES: '/inventories'
  }
};

export const outOfNavRoutes = {
  LOGIN: '/login',
  CONFIRMATION: '/email-confirmation',
  PROFILE: '/profile'
};
