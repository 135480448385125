import React, { useState } from 'react';
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Switch,
  Table,
  Select,
  InputNumber
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  CloseOutlined,
  EditOutlined,
  SaveOutlined,
  DeleteOutlined
} from '@ant-design/icons';

const { Option } = Select;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  checkType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  deposits,
  ...restProps
}) => {
  let inputNode;
  switch (inputType) {
    case 'input':
      inputNode = <Input />;
      break;
    case 'inputNumber':
      inputNode = (
        <InputNumber
          parser={(value) => value.replace('L', '')}
          formatter={(value) => `${value}L`}
          min={0}
        />
      );
      break;
    case 'switch':
      inputNode = <Switch defaultChecked={record[dataIndex]} />;
      break;
    case 'select':
      inputNode = (
        <Select>
          {deposits &&
            deposits.map(({ label, _id }) => (
              <Option key={_id} value={_id}>
                {label}
              </Option>
            ))}
        </Select>
      );
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TankTable = ({ tankCols, editTankCol, deleteTankCol, deposits }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [switchShowLoading] = useState(false);

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: record.label,
      deposit: record.deposit?._id,
      quantity: record.quantity
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const saveSwitch = async (record) => {
    try {
      editTankCol(record._id, record);
      setEditingKey('');
    } catch (e) {
      console.log('Error with Switch change: ', e);
    }
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      editTankCol(record._id, row, record);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: t('configuration.tank.label'),
      dataIndex: 'label',
      width: '20%',
      editable: true
    },
    {
      title: t('configuration.tank.deposit'),
      dataIndex: 'deposit',
      width: '20%',
      editable: true,
      render: (deposit) => deposit && deposit.label
    },
    {
      title: t('configuration.tank.quantity'),
      dataIndex: 'quantity',
      width: '20%',
      editable: true,
      render: (quantity) => `${quantity} L`
    },
    {
      title: t('configuration.tank.action'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Button
              href="javascript:;"
              onClick={() => save(record)}
              style={{ borderRadius: 0 }}
            >
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button style={{ borderRadius: 0 }}>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <>
            <Button
              onClick={() => edit(record)}
              disabled={editingKey !== ''}
              type="primary"
              style={{ borderRadius: 0 }}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>

            {/* <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteTankCol(record)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>
            </Popconfirm> */}
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    const checkListCol = {
      label: 'input',
      deposit: 'select',
      quantity: 'inputNumber'
    };
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          inputType: checkListCol[col.dataIndex],
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: (values) => EditableCell({ ...values, deposits })
          }
        }}
        bordered
        dataSource={tankCols}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 600 }}
      />
    </Form>
  );
};

export default TankTable;
