import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch, Button, Upload } from 'antd';
import { Link } from 'react-router-dom';
import TextArea from 'antd/lib/input/TextArea';
import { google } from 'calendar-link';
import { CalendarOutlined, UploadOutlined } from '@ant-design/icons';
import { routes, subRoutes } from '../../../utils/constants/routes';
import useSiteContext from './SiteContext';
import useAuthContext from '../../../contexts/AuthContext';

const useListContent = ({
  _id,
  reference,
  contact,
  company,
  have_to_be_informed_about_delay,
  key,
  localisation,
  address_details_1,
  address_details_2,
  postal_code,
  city,
  electricity,
  water,
  new_building,
  refurbished_building,
  estimated_time,
  site_informations,
  annotations,
  seller,
  archived
}) => {
  const { t } = useTranslation();
  const { token } = useAuthContext();
  const { deleteFiles, getFilesForSite } = useSiteContext();
  const renderLink = (
    referenceArg,
    address_details_1Arg,
    address_details_2Arg,
    postal_codeArg,
    cityArg,
    estimated_timeArg
  ) => {
    let addressDescription = `${address_details_1Arg} ${address_details_2Arg} ${postal_codeArg} ${cityArg}`;

    addressDescription = addressDescription.replace('undefined ', '');

    const event = {
      title: `${referenceArg}`,
      location: addressDescription,
      description: `${company?.name} : ${addressDescription}`,
      start: Date(),
      duration: [estimated_timeArg, 'hour']
    };
    return google(event);
  };
  const onChangeImg = () => {
    getFilesForSite(_id);
  };
  return [
    {
      label: t('sites.form.reference'),
      content: reference,
      span: 1
    },
    {
      label: t('sites.form.seller'),
      content:
        seller && `${seller.first_name} ${seller.last_name.toUpperCase()}`,
      span: 1
    },
    {
      label: t('sites.form.upload'),
      content: !archived && (
        <Upload
          multiple
          action={`${process.env.REACT_APP_API_URL}/files/site/${_id}`}
          data="picture"
          headers={{ Authorization: `Bearer ${token}` }}
          onChange={onChangeImg}
          onRemove={(file) => {
            deleteFiles(file.response);
          }}
        >
          <Button icon={<UploadOutlined />}>Importer</Button>
        </Upload>
      ),
      span: 1
    },
    {
      label: t('sites.form.contact'),
      content: contact && (
        <Link
          to={{
            pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CUSTOMERS}/show/${company._id}/contacts/show/${contact._id}`
          }}
        >
          {`${contact.first_name} ${contact.last_name}`}
        </Link>
      ),
      span: 1
    },
    {
      label: t('sites.form.have_to_be_informed_about_delay'),
      content: have_to_be_informed_about_delay && (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={have_to_be_informed_about_delay}
          disabled
        />
      ),
      span: 1
    },
    {
      label: t('sites.form.company'),
      content: company && (
        <Link
          to={{
            pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CUSTOMERS}/show/${company._id}`
          }}
        >
          {company.name}
        </Link>
      ),
      span: 1
    },
    {
      label: t('sites.form.address_details_1'),
      content: address_details_1,
      span: 1
    },
    {
      label: t('sites.form.address_details_2'),
      content: address_details_2,
      span: 1
    },
    {
      label: t('sites.form.postal_code'),
      content: postal_code,
      span: 1
    },
    {
      label: t('sites.form.city'),
      content: city,
      span: 1
    },
    {
      label: t('sites.form.latitude'),
      content: localisation && localisation.map((l) => l.latitude),
      span: 1
    },
    {
      label: t('sites.form.longitude'),
      content: localisation && localisation.map((l) => l.longitude),
      span: 1
    },
    {
      label: t('sites.form.site_informations'),
      content: site_informations,
      span: 3
    },
    {
      label: t('sites.form.key'),
      content: key,
      span: 1
    },
    {
      label: t('sites.form.electricity'),
      content: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={electricity}
          disabled
        />
      ),
      span: 1
    },
    {
      label: t('sites.form.water'),
      content: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={water}
          disabled
        />
      ),
      span: 1
    },
    {
      label: t('sites.form.new_building'),
      content: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={new_building}
          disabled
        />
      ),
      span: 1
    },
    {
      label: t('sites.form.refurbished_building'),
      content: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={refurbished_building}
          disabled
        />
      ),
      span: 2
    },
    {
      label: t('sites.form.annotations'),
      content: (
        <TextArea
          value={annotations}
          disabled
          style={{
            width: '400px',
            border: '0px',
            backgroundColor: '#fff',
            resize: 'none'
          }}
        />
      ),
      span: 3
    },
    {
      label: t('sites.form.meeting'),
      content: (
        <Link
          to={{
            pathname: renderLink(
              reference,
              address_details_1,
              address_details_2,
              postal_code,
              city,
              estimated_time
            )
          }}
          target="_blank"
        >
          <Button type="primary" shape="round" icon={<CalendarOutlined />}>
            Prendre un RDV sur Google Agenda
          </Button>
        </Link>
      ),
      span: 3
    }
  ];
};

export default useListContent;
