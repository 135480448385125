import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';
import useAuthContext from '../../../contexts/AuthContext';

const iconSize = 18;

const ListArticles = () => {
  const location = useLocation();
  const { pathname } = location;
  const { user } = useAuthContext();

  const actionColumn = [
    {
      key: 'action',
      fixed: 'right',
      width: '5%',
      render: ({ key, number }) => {
        return (
          <div style={{ float: 'right' }}>
            <Link
              to={{
                pathname: `${pathname}/show/${key}`
              }}
            >
              <EyeOutlined
                style={{ fontSize: iconSize, color: 'var(--primaryColor)' }}
              />
            </Link>
          </div>
        );
      }
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="deposits"
      columns={[...columns(), ...actionColumn]}
      withSubRoutes
      extraQuery={`${
        user.type === 'TECH' && user.deposit ? `_id=${user.deposit}` : ''
      }&show=true`}
      withCreateButton={false}
      withUploadButton={false}
      customActionColumn
    />
  );
};

export default ListArticles;
