import { Col, Form, Select, InputNumber } from 'antd';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const useFields = ({ el, name }) => {
  const { t } = useTranslation();

  return (
    <>
      <Col span={12}>
        <Form.Item
          label={t(`movements.modal.area`)}
          name={[name, 'area']}
          style={{ width: '100%' }}
          rules={[
            { required: true, message: 'Merci de renseigner une surface' }
          ]}
        >
          <InputNumber style={{ width: '150%' }} min="0" />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          label={t(`movements.modal.thickness`)}
          name={[name, 'thickness']}
          style={{ width: '100%' }}
          rules={[
            { required: true, message: 'Merci de selectionner une épaisseur' }
          ]}
        >
          <Select style={{ width: '150%' }}>
            {el?.quotationPrestations?.map(({ prestation }) => (
              <Option value={prestation?.thickness} key={prestation?._id}>
                {prestation?.thickness}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </>
  );
};

useFields.propTypes = {
  el: PropTypes.shape({
    quotationPrestations: PropTypes.array,
    area: PropTypes.array
  }).isRequired,
  name: PropTypes.number.isRequired
};

export default useFields;
