import React from 'react';
import PropTypes from 'prop-types';
import {
  DeleteOutlined,
  FormOutlined,
  PhoneOutlined,
  CalendarOutlined,
  EditOutlined
} from '@ant-design/icons';
import moment from 'moment';
import { Button, Col, Row, Timeline } from 'antd';
import { google } from 'calendar-link';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import GoogleCalendarIcon from '../../../../assets/images/icons/google-calendar_icon.png';
import useDealsContext from '../List/DealsContext';

const Line = styled.div`
  position: relative;
  background-color: #f0f0f0;
  margin-right: 40px;
  padding: 15px;
  .type {
    font-weight: bold;
    font-size: 20px;
    text-transform: capitalize;
  }
`;

const DisplayActivity = ({ elem, editActivity, delActivity }) => {
  const dateTime = elem.dateTime ? new Date(elem.dateTime) : null;

  const renderLink = () => {
    const event = {
      title: elem.title,
      location: elem.address,
      description: elem.desc,
      start: elem.dateTime,
      duration: elem.duration
    };
    return google(event);
  };

  const selectActivityIcon = (type) => {
    switch (type) {
      case 'note':
        return <FormOutlined />;
      case 'call':
        return <PhoneOutlined />;
      case 'meeting':
        return <CalendarOutlined />;
      default:
        return <FormOutlined />;
    }
  };

  return (
    <Timeline.Item key={elem}>
      {elem.dateTime && (
        <div className="date">{moment(dateTime).format('LLLL')}</div>
      )}
      <Line>
        <EditOutlined
          onClick={() => editActivity(elem)}
          style={{
            position: 'absolute',
            top: 10,
            right: 30,
            cursor: 'pointer'
          }}
        />
        <DeleteOutlined
          onClick={() => delActivity(elem)}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            cursor: 'pointer'
          }}
        />
        <div className="type">
          {selectActivityIcon(elem.type)}

          {elem.title}
        </div>
        <Row>
          <Col span={20}>
            <div className="desc" style={{ margin: '10px 0px 10px 0px' }}>
              {elem.desc}
            </div>
            {elem.type === 'meeting' && (
              <div
                className="desc"
                style={{ fontStyle: 'italic', fontSize: 12 }}
              >
                {`Lieu : ${elem.address || '-'}`}
              </div>
            )}
            {elem.type === 'meeting' && (
              <div
                className="duration"
                style={{ fontStyle: 'italic', fontSize: 12 }}
              >
                {`Durée : ${elem.duration || '-'} ${elem.duration ? 'h' : ''}`}
              </div>
            )}
          </Col>
          {elem.type === 'meeting' && (
            <Col span={4}>
              <Link
                to={{ pathname: renderLink() }}
                target="_blank"
                style={{ marginRight: 16 }}
              >
                <Button
                  type="link"
                  shape="round"
                  style={{
                    height: 60,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <img
                    src={GoogleCalendarIcon}
                    alt="Google Calendar"
                    style={{ height: 60 }}
                  />
                </Button>
              </Link>
            </Col>
          )}
        </Row>
      </Line>
    </Timeline.Item>
  );
};
DisplayActivity.propTypes = {
  elem: PropTypes.oneOfType([PropTypes.object]),
  editActivity: PropTypes.func.isRequired,
  delActivity: PropTypes.func.isRequired
};
DisplayActivity.defaultProps = {
  elem: null
};

const ListDealActivity = ({
  listActivity,
  selectedTab,
  editActivity,
  delActivity
}) => {
  const { deal } = useDealsContext();
  const listActivitiesTimeline = () => {
    const sortedArray = deal?.activities.sort((a, b) => {
      return new Date(b.dateTime) - new Date(a.dateTime);
    });

    return sortedArray.map((elem) => (
      <DisplayActivity
        listActivity={listActivity}
        editActivity={editActivity}
        delActivity={delActivity}
        elem={elem}
        key={elem._id}
      />
    ));
  };

  if (deal?.activities) {
    return (
      <div style={{ paddingLeft: '14px', paddingTop: '20px', minHeight: 100 }}>
        {selectedTab === 'call' &&
          deal?.activities
            .filter((activity) => activity.type === 'call')
            .map((elem) => (
              <DisplayActivity
                editActivity={editActivity}
                delActivity={delActivity}
                elem={elem}
                key={elem._id}
              />
            ))}
        {selectedTab === 'note' &&
          deal?.activities
            .filter((activity) => activity.type === 'note')
            .map((elem) => (
              <DisplayActivity
                editActivity={editActivity}
                delActivity={delActivity}
                elem={elem}
                key={elem._id}
              />
            ))}
        {selectedTab === 'meeting' &&
          deal?.activities
            .filter((activity) => activity.type === 'meeting')
            .map((elem) => {
              return (
                <DisplayActivity
                  editActivity={editActivity}
                  delActivity={delActivity}
                  elem={elem}
                  key={elem._id}
                />
              );
            })}
        {selectedTab === 'activity' && (
          <Timeline>{listActivitiesTimeline()}</Timeline>
        )}
      </div>
    );
  }
  return null;
};
ListDealActivity.propTypes = {
  listActivity: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  selectedTab: PropTypes.string,
  editActivity: PropTypes.func.isRequired,
  delActivity: PropTypes.func.isRequired
};
ListDealActivity.defaultProps = {
  listActivity: [],
  selectedTab: 'activity'
};

export default ListDealActivity;
