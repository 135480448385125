import React from 'react';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';

const ListPrimaryMatters = () => {
  return (
    <SimpleResourceLandingLayout
      resourceName="primary-matters"
      columns={[...columns()]}
      populate="deposit"
      withSubRoutes
      resourceModelName="PrimaryMatter"
      withUploadButton={false}
    />
  );
};

export default ListPrimaryMatters;
