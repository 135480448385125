import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Row, Form, Checkbox, Divider, Tabs, Table } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import setColumn from './columnsForSetData/CreateInventory';

const CheckboxGroup = Checkbox.Group;
const { TabPane } = Tabs;

const CreateUpdateInventory = ({ purpose }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const generateFields = useGenerateFormItem();
  const { dispatchAPI } = useAuthContext();
  const { basicFields } = useFields();
  const [depositSelected, setDepositSelected] = useState();
  const [inventoryList, setInventoryList] = useState([]);
  const [allData, setAllData] = useState({});

  const setUrl = (item) => {
    switch (item) {
      case 'articles': {
        const articles = `/articles/deposit/${depositSelected}?populate=unit`;
        return articles;
      }
      case 'epis': {
        const epis = `/epis/bydeposit/${depositSelected}?populate=unit`;
        return epis;
      }
      case 'primary_matters': {
        const epis = `/primary-matters/deposit/${depositSelected}?populate=unit`;
        return epis;
      }
      default:
        return null;
    }
  };

  const getDataByDeposit = async (value) => {
    try {
      const url = setUrl(value);
      if (url !== null) {
        const { data } = await dispatchAPI('GET', {
          url
        });
        if (value === 'primary_matters') {
          const primaryMatters = data.filter((el) => el.archived === false);
          setAllData({ ...allData, [value]: primaryMatters });
        } else setAllData({ ...allData, [value]: data });
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createInventory = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', { url: '/inventories', body });
      history.push(`/production/inventories/show/${data.id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const listCategories = [
    { label: t(`inventories.show.epis`), value: 'epis' },
    { label: t(`inventories.show.articles`), value: 'articles' },
    { label: t(`inventories.show.primary_matters`), value: 'primary_matters' }
  ];

  const handleSubmit = (values) => {
    const restData = {};
    inventoryList.forEach((element) => {
      if (element !== 'articles' || element !== 'epis') {
        restData[element] = allData[element]?.map((ele) => ({
          quantity: ele.quantity || 0,
          unit: ele.unit._id,
          label: ele._id
        }));
      }
      if (element === 'primary_matters') {
        restData.primary_matters = allData.primary_matters?.map((ele) => ({
          quantity: ele.quantity || 0,
          unit: ele.unit._id,
          label: ele.tank._id,
          batch_number: ele?.batch_number
        }));
      }
    });
    const result = {
      ...values,
      deposit: depositSelected,
      pending: true,
      ...restData
    };
    createInventory(result);
  };

  const onChangeCheckbox = (values) => {
    const newValue = values.filter((el) => inventoryList.indexOf(el) === -1);
    setInventoryList(values);
    getDataByDeposit(newValue[0]);
  };

  const onValuesChange = (_, values) => {
    if (values.deposit && values.date && values.inventory_responsible) {
      setDepositSelected(values.deposit);
    }
  };
  useEffect(() => {
    setInventoryList([]);
  }, [depositSelected]);

  const isDisabled = () => {
    let empty;
    if (inventoryList.find((el) => el === 'primary_matters')) {
      empty = allData.primary_matters
        ? allData.primary_matters.find(({ quantity }) => quantity !== 0)
        : true;
    }
    if (empty) return false;
    if (inventoryList.find((el) => el === 'articles')) {
      empty = allData.articles
        ? allData.articles.find(({ quantity }) => quantity !== 0)
        : true;
    }
    if (empty) return false;
    if (inventoryList.find((el) => el === 'epis')) {
      empty = allData.epis
        ? allData.epis.find(({ quantity }) => quantity !== 0)
        : true;
    }
    if (empty) return false;
    return true;
  };

  return (
    <>
      <PageHeaderCustom title={t(`inventories.form.title.${purpose}`)} />
      <ContentCustom>
        <Form
          {...formItemLayout}
          name="inventories"
          form={form}
          onValuesChange={(_, values) => onValuesChange(_, values)}
          onFinish={(values) => handleSubmit(values)}
        >
          {basicFields.map((field) => generateFields('inventories', field))}
          {depositSelected ? (
            <>
              <Divider>Catégories</Divider>

              <CheckboxGroup
                options={listCategories}
                value={inventoryList}
                onChange={(value) => onChangeCheckbox(value)}
              />
              <Tabs style={{ marginTop: 50 }} centered size="large" type="card">
                {inventoryList.map((category) => (
                  <TabPane
                    tab={t(`inventories.show.${category}`)}
                    key={category}
                  >
                    <Table
                      rowKey="_id"
                      key={category}
                      dataSource={allData[category] || []}
                      columns={[...setColumn(t, category)]}
                    />
                  </TabPane>
                ))}
              </Tabs>
              <Divider />
              <Row justify="space-around">
                <Form.Item>
                  <Button
                    onClick={() => history.goBack()}
                    type="danger"
                    htmlType="submit"
                  >
                    Annuler
                    <CheckOutlined />
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button disabled={isDisabled()} type="add" htmlType="submit">
                    Générer un inventaire
                    <CheckOutlined />
                  </Button>
                </Form.Item>
              </Row>
            </>
          ) : null}
        </Form>
      </ContentCustom>
    </>
  );
};

CreateUpdateInventory.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateInventory;
