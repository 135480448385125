import React, { useState, useEffect } from 'react';
import { Column } from '@ant-design/charts';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useAuthContext from '../../../../contexts/AuthContext';

const ColumnGraph = () => {
  const [data, setData] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();

  const getGraphData = async () => {
    setIsLoading(true);
    dispatchAPI('GET', {
      url: `/dashboard/bar/ca`
    })
      .then((response) => {
        setData(
          response.data.map((report) => ({
            value: report.ca,
            month: report._id.month
          }))
        );
      })
      .catch((error) => {
        message(error.response);
      });
    setIsLoading(false);
  };

  useEffect(() => {
    getGraphData();
  }, []);
  const paletteSemanticRed = '#F4664A';
  const brandColor = `#4db3a2`;
  const config = {
    data,
    xField: 'month',
    yField: 'value',
    seriesField: '',
    meta: {
      month: {
        formatter: function formatter(val) {
          const dateFormatter = new Intl.DateTimeFormat('fr', {
            month: 'short'
          });
          return dateFormatter.format(new Date().setMonth(val));
        }
      }
    },
    color: function color(_ref) {
      const value = _ref.value;
      if (value < 8000) {
        return paletteSemanticRed;
      }
      return brandColor;
    },
    yAxis: {
      label: {
        formatter: function formatter(v) {
          return parseFloat(v)
            .toFixed(2)
            .concat(' €');
        }
      }
    },
    legend: true,
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false
      }
    },
    label: {
      position: 'middle',
      layout: [
        { type: 'interval-adjust-position' },
        { type: 'interval-hide-overlap' },
        { type: 'adjust-color' }
      ]
    }
  };
  return <Column loading={isLoading} {...config} />;
};

ColumnGraph.propTypes = {};

export default ColumnGraph;
