import React, { useState } from 'react';
import { Modal, Input, Table, Typography, Tag, message, Collapse } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';
import {
  quotationsStatus,
  movementsStatus,
  invoiceTypes
} from '../../utils/constants/tagColors';

const { Search } = Input;
// eslint-disable-next-line no-unused-vars
const { Title } = Typography;
const { Panel } = Collapse;

const ModalSearch = () => {
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [customer, setCustomer] = useState('');
  const [data, setData] = useState({});

  const showModal = async (value) => {
    try {
      // eslint-disable-next-line no-shadow
      const { data } = await dispatchAPI('GET', {
        url: `/dashboard/customer/${value}`
      });
      setIsModalVisible(true);
      setCustomer(value);
      setData(data);
    } catch (e) {
      message.error(`Aucune donnée correspondant à ${value}`);
    }
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const columnsQuot = [
    {
      title: t('quotations.list.column.title.number'),
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: t('quotations.list.column.title.subject'),
      dataIndex: 'subject',
      key: 'subject'
    },
    {
      title: t('quotations.list.column.title.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Tag color={quotationsStatus[status]}>{t(`enums.${status}`)}</Tag>
      )
    },
    {
      title: t('quotations.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      key: 'emission_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('quotations.list.column.title.total'),
      dataIndex: 'total',
      render: (total) => `${parseFloat(total).toFixed(2)}€`,
      key: 'total'
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key }) => (
        <span>
          <Link
            to={{
              pathname: `accounting/quotations/show/${key}`
            }}
          >
            <EyeOutlined
              style={{ fontSize: 18, color: 'var(--primaryColor)' }}
            />
          </Link>
        </span>
      )
    }
  ];

  const columnsInter = [
    {
      title: t('movements.columns.reference'),
      dataIndex: 'site',
      render: (site) => site && site.reference
    },
    {
      title: t('movements.columns.status'),
      dataIndex: 'status',
      render: (enums) => (
        <Tag color={movementsStatus[enums]}>{t(`enums.${enums}`)}</Tag>
      )
    },
    {
      title: t('movements.columns.date'),
      dataIndex: 'date',
      render: (date) =>
        date ? moment(date).format('DD/MM/YYYY') : 'Non défini'
    },
    {
      title: t('movements.columns.truck'),
      dataIndex: 'truck',
      render: (truck) => (truck?.label ? truck.label : 'Non défini')
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key }) => (
        <span>
          <Link
            to={{
              pathname: `intervention/manage_interventions/show/${key}`
            }}
          >
            <EyeOutlined
              style={{ fontSize: 18, color: 'var(--primaryColor)' }}
            />
          </Link>
        </span>
      )
    }
  ];

  const columnsInvoices = [
    {
      title: t('invoices.list.column.title.number'),
      dataIndex: 'number',
      key: 'number'
    },
    {
      title: t('invoices.list.column.title.type'),
      dataIndex: 'type',
      key: 'type',
      render: (type) => (
        <Tag color={invoiceTypes[type]}>{t(`invoices.tags.${type}`)}</Tag>
      )
    },
    {
      title: t('invoices.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      key: 'emission_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('invoices.list.column.title.payment_date'),
      dataIndex: 'payment_date',
      key: 'payment_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('invoices.list.column.title.total'),
      dataIndex: 'total',
      key: 'total',
      render: (total) => `${parseFloat(total).toFixed(2)}€`
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key }) => (
        <span>
          <Link
            to={{
              pathname: `accounting/invoices/show/${key}`
            }}
          >
            <EyeOutlined
              style={{ fontSize: 18, color: 'var(--primaryColor)' }}
            />
          </Link>
        </span>
      )
    }
  ];

  return (
    <>
      <Search placeholder="Chercher un client" onSearch={showModal} />
      <Modal
        title={`Résultats pour : ${customer}`}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={1000}
      >
        <Collapse defaultActiveKey="1">
          <Panel header="Devis" key="1">
            <Table
              pagination={false}
              columns={columnsQuot}
              dataSource={
                data &&
                data?.quotations?.map(
                  ({ number, subject, status, emission_date, total, _id }) => ({
                    number,
                    subject,
                    status,
                    emission_date,
                    total,
                    key: _id
                  })
                )
              }
              onRow={({ key }) => ({
                onDoubleClick: () =>
                  history.push(`/accounting/quotations/show/${key}`)
              })}
            />
          </Panel>
          <Panel header="Interventions" key="2">
            <Table
              pagination={false}
              columns={columnsInter}
              dataSource={
                data &&
                data?.movements?.map(({ site, status, date, truck, _id }) => ({
                  site,
                  status,
                  date,
                  truck,
                  key: _id
                }))
              }
              onRow={({ key }) => ({
                onDoubleClick: () =>
                  history.push(`/intervention/manage_interventions/show/${key}`)
              })}
            />
          </Panel>
          <Panel header="Factures" key="3">
            <Table
              pagination={false}
              columns={columnsInvoices}
              dataSource={
                data &&
                data?.invoices?.map(
                  ({
                    number,
                    type,
                    emission_date,
                    payment_date,
                    total,
                    _id
                  }) => ({
                    number,
                    type,
                    emission_date,
                    payment_date,
                    total,
                    key: _id
                  })
                )
              }
              onRow={({ key }) => ({
                onDoubleClick: () =>
                  history.push(`/accounting/invoices/show/${key}`)
              })}
            />
          </Panel>
        </Collapse>
      </Modal>
    </>
  );
};

export default ModalSearch;
