import React from 'react';
import { Input } from 'antd';

const useFields = () => {
  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['address']
    },
    {
      name: ['zip']
    },
    {
      name: ['city']
    },
    {
      name: ['phone_number']
    },
    {
      name: ['bank_rib'],
      input: <Input />
    },
    {
      name: ['transmitter'],
      input: <Input />
    },
    {
      name: ['journal'],
      input: <Input />
    },
    {
      name: ['accounting_account'],
      input: <Input />
    }
  ];

  return { fields };
};

export default useFields;
