import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Switch } from 'antd';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateCompany = ({ purpose }) => {
  const [isProfessional, setIsProfessional] = useState(true);
  const [numberAccount, setNumberAccount] = useState();
  const form = useRef();
  const { type = 'customers' } = useParams();
  const { fields, isFieldsLoading, advancedFields } = useFields(
    isProfessional,
    type,
    form,
    numberAccount
  );
  const { t } = useTranslation();

  const config = {
    onCreateResource: {
      setBody: (data) => {
        return {
          ...data,
          type: type.toUpperCase(),
          professional: isProfessional
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        return {
          ...data,
          type: type.toUpperCase(),
          professional: isProfessional
        };
      }
    },
    onGetResource: {
      setFields: (data) => {
        setIsProfessional(data.professional);
        return data;
      }
    }
  };
  const handleChangeFieldsValue = (_, values) => {
    const { first_name, last_name } = values;
    if (first_name && last_name && purpose === 'create') {
      const preNumberAccount = `${first_name.slice(0, 3)}${last_name.slice(
        0,
        3
      )}`.toUpperCase();
      setNumberAccount(preNumberAccount);
    }
    if (first_name && last_name && purpose === 'edit') {
      form.current.setFieldsValue({
        number_customer_account: `01`
      });
      const preNumberAccount = `${first_name.slice(0, 3)}${last_name.slice(
        0,
        3
      )}`.toUpperCase();
      setNumberAccount(preNumberAccount);
    }
  };

  return (
    <CreateUpdateContainer
      ref={form}
      fields={fields}
      loadingFields={isFieldsLoading}
      config={config}
      purpose={purpose}
      baseUrl="companies"
      resource={type === 'customers' ? 'customers' : 'suppliers'}
      withSubRoutes
      formExtraGenerate={advancedFields}
      onValuesChange={handleChangeFieldsValue}
      withSwitch={
        type === 'customers' ? (
          <Switch
            checkedChildren={t('customers.form.professional')}
            unCheckedChildren={t('customers.form.individual')}
            checked={isProfessional}
            onClick={() => setIsProfessional(!isProfessional)}
          />
        ) : null
      }
    />
  );
};

CreateUpdateCompany.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateCompany;
