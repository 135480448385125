import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Row,
  InputNumber,
  Select,
  Badge,
  Input,
  Divider,
  Space,
  Col
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

const useFormList = () => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [floorOptions, setFloorOptions] = useState();
  const [floorName, setFloorName] = useState();
  const [typeOptions, setTypeOptions] = useState();
  const [refresh, setRefresh] = useState(false);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/schemas/enums`
      });
      setTypeOptions(data.types);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getFloorsOptions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/parts-types`
      });
      setFloorOptions(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const addFloorOption = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: `/parts-types`,
        body
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onChangeFloor = (e) => {
    setFloorName(e.target.value);
  };

  const addItemFloor = async () => {
    const body = {
      label: floorName
    };
    await addFloorOption(body);
    setRefresh(!refresh);
  };

  useEffect(() => {
    (async () => {
      await getEnums();
      await getFloorsOptions();
    })();
  }, [refresh]);

  return (fields, { add, remove }, { errors }) => (
    <>
      {fields.map((field, index) => {
        const color = index % 2 ? '#4db3a240' : '#cccccc36';
        //  const visibility = fields.length > 1 ? '#4db3a240' : '#fff0';
        return (
          <Badge
            offset={[-20, 20]}
            key={field.key}
            count={
              <DeleteOutlined
                style={{ fontSize: 18, color: '#4db3a240' }}
                onClick={() => remove(field.name)}
              />
            }
          >
            <Row
              align="middle"
              justify="space-between"
              style={{
                backgroundColor: color,
                padding: '5%',
                marginBottom: 8
              }}
            >
              <Form.Item
                name={[field.name, 'floor']}
                validateTrigger={['onChange', 'onBlur']}
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
                label={t('sites.form.floor')}
                labelAlign="left"
                rules={[
                  { required: true, message: 'Merci de renseigner ce champs' }
                ]}
              >
                <Select
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <Divider style={{ margin: '4px 0' }} />
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'nowrap',
                          padding: 8
                        }}
                      >
                        <Input
                          style={{ flex: 'auto' }}
                          onChange={onChangeFloor}
                        />
                        <Button
                          style={{
                            flex: 'none',
                            padding: '8px',
                            display: 'block',
                            cursor: 'pointer',
                            color: 'var(--addColor)'
                          }}
                          onClick={addItemFloor}
                        >
                          <PlusOutlined />
                          {'Ajouter une pièce'}
                        </Button>
                      </div>
                    </div>
                  )}
                >
                  {(floorOptions || []).map((floor) => (
                    <Select.Option key={floor.label} value={floor.label}>
                      {floor.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Row justify="space-around" style={{ width: '100%' }}>
                <Form.Item
                  style={{
                    width: '90%',
                    display: 'flex'
                    // flexDirection: 'column'
                  }}
                  name={[field.name, 'area']}
                  validateTrigger={['onChange', 'onBlur']}
                  label={t('sites.form.area')}
                  labelAlign="left"
                  rules={[
                    { required: true, message: 'Merci de renseigner ce champ' }
                  ]}
                >
                  <InputNumber min={0} style={{ width: '100%' }} />
                </Form.Item>
                <span style={{ margin: 'auto' }}>m²</span>
              </Row>

              <Row align="middle" justify="start">
                <Form.List
                  name={[field.name, 'thickness']}
                  validateTrigger={['onChange', 'onBlur']}
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between'
                  }}
                  label={t('sites.form.thickness')}
                  labelAlign="left"
                  help={`Appuyer sur "entrée" après la saisie de chaque valeur`}
                >
                  {(fields2, { add: add2, remove: remove2 }) => (
                    <>
                      {fields2.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: 'flex',
                            marginBottom: 8,
                            justifyContent: 'space-around'
                          }}
                        >
                          <Form.Item
                            {...restField}
                            name={[name]}
                            fieldKey={[fieldKey]}
                            rules={[
                              {
                                required: true,
                                message: "Merci de renseigner l'épaisseurs"
                              }
                            ]}
                          >
                            <InputNumber
                              style={{ width: 150 }}
                              placeholder="Valeur..."
                            />
                          </Form.Item>
                          <span>cm</span>

                          <MinusCircleOutlined
                            style={{ marginLeft: -5, marginRight: 5 }}
                            onClick={() => remove2(name)}
                          />
                        </Space>
                      ))}
                      <Col span={24}>
                        <Form.Item>
                          <Button
                            type="dashed"
                            onClick={() => add2()}
                            block
                            icon={<PlusOutlined />}
                          >
                            Ajouter épaisseur
                          </Button>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Form.List>
              </Row>
            </Row>
          </Badge>
        );
      })}
      <Form.Item>
        <Button type="dashed" onClick={() => add()} style={{ width: '100%' }}>
          {`${t(`sites.quotlines.add_thickness_area`)} `}
          <PlusOutlined />
        </Button>
        <Form.ErrorList errors={errors} />
      </Form.Item>
    </>
  );
};

export default useFormList;
