import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Modal,
  Form,
  Input,
  InputNumber,
  Select,
  Row,
  Button,
  Divider
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useDealsContext from '../List/DealsContext';

const { TextArea } = Input;
const { Option } = Select;
const ModalToEditBuisness = ({ dataDeal, visible, setVisible }) => {
  const { t } = useTranslation();
  const generateFormItem = useGenerateFormItem();
  const { message: errorMessage } = useErrorMessage();
  const { fetchDealById } = useDealsContext();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const [departements, setDepartements] = useState([]);

  const fetchDepartements = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/departments` });
      setDepartements(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const patchDealBuisness = async (body) => {
    try {
      await dispatchAPI('PATCH', { url: `/deals/${dataDeal?._id}`, body });
      fetchDealById(dataDeal?._id);
      setVisible(false);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    fetchDepartements();
    if (dataDeal && dataDeal.buisness) form.setFieldsValue(dataDeal.buisness);
  }, [dataDeal]);

  const fields = [
    {
      name: ['reference'],
      rules: [
        {
          required: true,
          message:
            "Référence de l'affaire non reconnue. Veuillez modifier les informations de l'entreprise."
        }
      ],
      input: <Input disabled />
    },
    {
      name: ['address_details_1'],
      rules: [{ required: true }]
    },
    {
      name: ['address_details_2'],
      rules: [{ required: true }],
      input: <Input />
    },
    {
      name: ['postal_code'],
      rules: [{ required: true }],
      input: <Input />
    },
    {
      name: ['department'],
      rules: [{ required: true }],
      input: (
        <Select style={{ width: 200 }}>
          {departements &&
            departements?.map((department) => (
              <Option key={department._id} value={department._id}>
                {department?.label}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['city'],
      rules: [{ required: true }],
      input: <Input />
    },

    {
      name: ['site_informations'],
      rules: [{ required: false }],
      input: <TextArea rows={2} />
    }
  ];
  const handleSubmit = (values) => {
    patchDealBuisness({
      buisness: { ...values }
    });
  };
  const handleChangeValue = (_, values) => {
    const { postal_code, city } = values;
    if (postal_code && city && dataDeal && dataDeal?.companies?.name) {
      form.setFieldsValue({
        reference: `${dataDeal?.companies?.name.toUpperCase()} - ${postal_code} ${city}`
      });
    }
  };
  return (
    <Modal
      title="Modification de l'affaire"
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
    >
      <Form
        wrapperCol={{ span: 12 }}
        labelAlign="left"
        labelCol={{ span: 12 }}
        name="buisness"
        onFinish={handleSubmit}
        onValuesChange={handleChangeValue}
        form={form}
      >
        {fields.map((field) => generateFormItem('deals', field))}
        <Divider />
        <Row justify="center">
          <Button onClick={() => setVisible(false)}>
            {`${t('buttons.cancel')}`}
            <CloseOutlined />
          </Button>
          <Button style={{ margin: '0 10px' }} type="primary" htmlType="submit">
            {t('buttons.save')}
            <CheckOutlined />
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

ModalToEditBuisness.propTypes = {
  dataDeal: PropTypes.oneOfType([PropTypes.object]),
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};
ModalToEditBuisness.defaultProps = {
  dataDeal: null
};

export default ModalToEditBuisness;
