import React, { useEffect, useState } from 'react';
import { Button, Form, Input, message, Popconfirm, Select, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';
import { ChromePicker } from 'react-color';
import useAuthContext from '../../../../contexts/AuthContext';

const { Option } = Select;

const DepartmentTable = ({
  departmentCols,
  editDepartmentCol,
  deleteDepartmentCol
}) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [enums, setEnums] = useState();

  const getEnums = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/departments/enums' });
      setEnums(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  useEffect(() => {
    getEnums();
  }, []);

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: record.label,
      color: record.color,
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      editDepartmentCol(record._id, row);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    selectType,
    checkType,
    record,
    index,
    children,
    setColorPicker,
    colorPicker,
    ...restProps
  }) => {
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [color, setColor] = useState(record?.color);

    const onChangeColorPicker = (newColor) => {
      setColor(newColor.hex);
      form.setFieldsValue({ color: newColor.hex });
    };

    let inputNode;
    switch (inputType) {
      case 'select':
        inputNode = (
          <Select style={{ width: '100%' }} showSearch>
            {(enums || []).map(({ nom, code }) => (
              <Option value={`${code} - ${nom}`} key={code}>
                {`${code} - ${nom}`}
              </Option>
            ))}
          </Select>
        );
        break;
      case 'input':
        inputNode = (
          <Input value={color} onClick={() => setDisplayColorPicker(true)} />
        );
        break;
      default:
        break;
    }

    return (
      <td {...restProps}>
        {editing ? (
          <>
            <Form.Item
              name={dataIndex || 'color'}
              style={{
                margin: 0
              }}
              rules={[
                {
                  required: true,
                  message: `Merci de renseigner ${
                    title.includes('Couleur') ? 'une couleur' : 'ce champs'
                  } !`
                }
              ]}
            >
              {inputNode}
            </Form.Item>
            {displayColorPicker && (
              <ChromePicker
                color={color}
                onChangeComplete={onChangeColorPicker}
              />
            )}
          </>
        ) : (
          children
        )}
      </td>
    );
  };

  EditableCell.propTypes = {
    editing: PropTypes.func.isRequired,
    dataIndex: PropTypes.func.isRequired,
    title: PropTypes.func.isRequired,
    inputType: PropTypes.func.isRequired,
    selectType: PropTypes.func.isRequired,
    checkType: PropTypes.func.isRequired,
    record: PropTypes.func.isRequired,
    index: PropTypes.func.isRequired,
    children: PropTypes.func.isRequired,
    setColorPicker: PropTypes.func.isRequired,
    colorPicker: PropTypes.func.isRequired
  };

  const columns = [
    {
      title: t('configuration.department.name'),
      dataIndex: 'label',
      width: '30%',
      editable: true
    },
    {
      title: t('configuration.department.color'),
      width: '30%',
      editable: true,
      render: (color, record) => (
        <Input
          value={record.color}
          style={{
            backgroundColor: record.color,
            borderColor: record.color,
            color: '#fff',
            textAlign: 'center'
          }}
          readOnly
        />
      )
    },
    {
      title: t('configuration.department.action'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Button onClick={() => save(record)} style={{ borderRadius: 0 }}>
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button style={{ borderRadius: 0 }}>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <>
            <Button
              onClick={() => edit(record)}
              disabled={editingKey !== ''}
              type="primary"
              style={{ borderRadius: 0, marginRight: '1%' }}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>

            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteDepartmentCol(record)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          inputType: ['label'].includes(col.dataIndex) ? 'select' : 'input',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={departmentCols}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 700 }}
      />
    </Form>
  );
};

DepartmentTable.propTypes = {
  departmentCols: PropTypes.func.isRequired,
  editDepartmentCol: PropTypes.func.isRequired,
  deleteDepartmentCol: PropTypes.func.isRequired,
  record: PropTypes.shape({
    color: PropTypes.string.isRequired
  }).isRequired
};

export default DepartmentTable;
