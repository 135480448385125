import React, { useState, useEffect } from 'react';
import {
  Descriptions,
  Row,
  Col,
  Tag,
  Divider,
  Collapse,
  Card,
  Typography,
  Button,
  message,
  Image,
  Modal
} from 'antd';
import {
  FileProtectOutlined,
  CheckOutlined,
  StopOutlined
} from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import useMovementContext from '../PostIntervention/MovementsContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import MeasureTable from './MeasureTable';

const { Panel } = Collapse;
const { Title } = Typography;

const ShowCstb = ({ data, status, setVisible }) => {
  const { user, dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const {
    CSTB,
    getMovement,
    pictures,
    getFilesCstb,
    funcPicToDisplay,
    visiblePic,
    setVisiblePic,
    picToDisplay
  } = useMovementContext();
  const { t } = useTranslation();

  const [isLoadingDownloading, setLoadingDownloading] = useState(false);

  const validateCstb = async (value) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/cstb/${data?._id}`,
        body: { status: value }
      });
      getMovement(CSTB?._id);
      if (value === 'VALIDATE') message.success('CSTB validé');
      if (value === 'PENDING') message.success('CSTB en cours');
      setVisible(false);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const downloadPv = async () => {
    setLoadingDownloading(true);
    try {
      const response = await dispatchAPI('GET', {
        url: `/generate/cstb/${data?._id}`,
        responseType: 'arraybuffer'
      });
      const bufferArray = new Uint8Array(response.data);
      const blob = new Blob([bufferArray], {
        type: 'application/docx'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = response.headers['content-disposition']
        .split('filename=')[1]
        .split(';')[0];
      a.click();
      setLoadingDownloading(false);
    } catch (e) {
      setLoadingDownloading(false);
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    if (data) getFilesCstb(`/files?cstb_id=${data?._id}`);
  }, [data]);

  return (
    <>
      {status === 'VALIDATE' || status === 'WAITING_VALIDATION' ? (
        <Row justify="start">
          <Col span={24}>
            <Descriptions
              title={
                <Row>
                  <span>{`Chantier : ${CSTB?.site?.reference}`}</span>
                  <span style={{ marginLeft: 10 }}>{data?.number}</span>
                </Row>
              }
            >
              <Descriptions.Item label="Date">
                {moment(CSTB?.date).format('DD-MM-YYYY')}
              </Descriptions.Item>
              <Descriptions.Item label="Adresse">
                {CSTB?.site?.address_details_1}
              </Descriptions.Item>
              <Descriptions.Item label="Intervention associée">
                {moment(CSTB?.date).format('DD-MM-YYYY')}
              </Descriptions.Item>
            </Descriptions>
            {((user.type === 'MANAGER' &&
              user?.managed_trucks?.includes(CSTB?.truck?._id)) ||
              user.type === 'ADMIN' ||
              user.type === 'DIRECTION') && (
              <>
                <Divider />
                <Row justify="space-around">
                  {data.status === 'WAITING_VALIDATION' && (
                    <Col style={{ textAlign: 'center' }} span={6}>
                      <Button
                        onClick={() => validateCstb('VALIDATE')}
                        icon={<CheckOutlined />}
                        type="primary"
                      >
                        Valider la fiche
                      </Button>
                    </Col>
                  )}
                  {data.status === 'VALIDATE' && (
                    <Col style={{ textAlign: 'center' }} span={6}>
                      <Col span={24}>
                        <Button
                          onClick={() => validateCstb('WAITING_VALIDATION')}
                          style={{ color: 'var(--secondaryColor)' }}
                          icon={<StopOutlined />}
                          type="secondary"
                        >
                          Revoir la Fiche
                        </Button>
                      </Col>
                      <Col span={24}>
                        <span>
                          (Passer l&apos;intervention en status &apos;en
                          cours&apos;)
                        </span>
                      </Col>
                    </Col>
                  )}
                  <Col style={{ textAlign: 'center' }} span={6}>
                    <Button
                      loading={isLoadingDownloading}
                      onClick={() => downloadPv()}
                      icon={<FileProtectOutlined />}
                      type="primary"
                    >
                      Imprimer PV
                    </Button>
                  </Col>
                </Row>
              </>
            )}

            <Divider />
            <Collapse>
              <Panel header="Arrivée sur chantier">
                <Descriptions>
                  <Descriptions.Item label="Température support">
                    <Tag>{`${data.temperature} °C`}</Tag>
                  </Descriptions.Item>
                  {Object.entries(data?.support).map(([key, value]) => (
                    <Descriptions.Item key={key} label={t(`cstb.form.${key}`)}>
                      <Tag>{value ? 'Oui' : 'Non'}</Tag>
                    </Descriptions.Item>
                  ))}
                </Descriptions>
                <Divider />

                <Descriptions column={2}>
                  <Descriptions.Item label="Application sur support bois: liste de contrôle FORM PROD 21">
                    <Tag>{data?.wood ? 'Oui' : 'Non'}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Câblage et conduit correctement mis">
                    <Tag>{data?.cablage ? 'Oui' : 'Non'}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Horizontalité - pente régulière">
                    <Tag>{data?.horizontal ? 'Oui' : 'Non'}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Support présente cassure / rupture brutale">
                    <Tag>{data?.support_quality ? 'Oui' : 'Non'}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Chantier sans dégât apparent">
                    <Tag>{data?.site_quality ? 'Oui' : 'Non'}</Tag>
                  </Descriptions.Item>
                  <Descriptions.Item label="Acceptation du chantier">
                    <Tag>{data?.site_acceptation ? 'Oui' : 'Non'}</Tag>
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header="Contrôles des cycles">
                <Descriptions column={2}>
                  <Descriptions.Item label="Cycle de départ">
                    <span style={{ fontWeight: 'bold' }}>0</span>
                  </Descriptions.Item>
                  <Descriptions.Item label="Cycle de fin">
                    <span style={{ fontWeight: 'bold' }}>{data?.cycle}</span>
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header="Mesures chantier">
                <MeasureTable data={data?.details_after} />
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header="Projection">
                <Descriptions column={2}>
                  {Object.entries(data?.projection).map(([key, value]) => (
                    <Descriptions.Item
                      key={key}
                      label={t(`cstb.form.projection.${key}`)}
                    >
                      <Tag>{value}</Tag>
                    </Descriptions.Item>
                  ))}

                  <Descriptions.Item label="Type de support">
                    test
                  </Descriptions.Item>
                </Descriptions>
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header="Carotage">
                <Descriptions>
                  {Object.entries(data?.coring).map(([key, value]) =>
                    key === 'spray_quality' ||
                    key === 'pur_quality' ||
                    key === 'joining_quality' ? (
                      <Descriptions.Item
                        key={key}
                        label={t(`cstb.form.coring.${key}`)}
                      >
                        {value ? (
                          <Tag color="green">Oui</Tag>
                        ) : (
                          <Tag color="green">Non</Tag>
                        )}
                      </Descriptions.Item>
                    ) : (
                      (key === 'coring_start' ||
                        key === 'coring_medium' ||
                        key === 'coring_end') && (
                        <Descriptions.Item label={t(`cstb.form.coring.${key}`)}>
                          <Tag>{`${Math.round(value)} Kg/m³`}</Tag>
                        </Descriptions.Item>
                      )
                    )
                  )}
                </Descriptions>
                <Row justify="center">
                  <Col span={6}>
                    <Card style={{ textAlign: 'center' }}>
                      <Col style={{ marginBottom: 10 }} span={24}>
                        <span style={{ fontWeight: 'bold' }}>
                          Moyenne de la masse volumique
                        </span>
                      </Col>
                      <Col span={24}>
                        {`${Math.round(
                          (data?.coring?.coring_start +
                            data?.coring?.coring_medium +
                            data?.coring?.coring_end) /
                            3
                        )} kg/m³`}
                      </Col>
                    </Card>
                  </Col>
                </Row>
              </Panel>
            </Collapse>
            <Collapse>
              <Panel header="Photos">
                <Row gutter={[16, 16]}>
                  {pictures.length > 0
                    ? pictures
                        .filter((filter) => filter.cstb_id === CSTB?.CSTB?._id)
                        .map((el) => (
                          <div key={el._id}>
                            <Col span={24}>
                              <Button
                                onClick={() =>
                                  funcPicToDisplay(el._id, el.contentType)
                                }
                              >
                                {`${el.metadata.originalName.substring(
                                  0,
                                  10
                                )}...`}
                              </Button>
                            </Col>
                          </div>
                        ))
                    : null}
                </Row>
                <Modal
                  width={1000}
                  visible={visiblePic}
                  closable
                  footer={
                    <Button onClick={() => setVisiblePic(false)}>Ok</Button>
                  }
                  onCancel={() => setVisiblePic(false)}
                >
                  {picToDisplay && (
                    <Image alt={picToDisplay} src={picToDisplay} />
                  )}
                </Modal>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      ) : (
        <Title>La fiche CSTB indisponible, l&apos;intervention </Title>
      )}
    </>
  );
};

ShowCstb.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  status: PropTypes.string,
  setVisible: PropTypes.func.isRequired
};

ShowCstb.defaultProps = {
  data: null,
  status: null
};

export default ShowCstb;
