import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import MovementsStockRouter from './MovementsStock/MovementsStockRouter';
import ArticlesRouter from './Articles/ArticlesRouter';
import DepositsRouter from './Deposits/DepositsRouter';
import PrimaryMattersRouter from './PrimaryMatters/PrimaryMattersRouter';
import EPIsRouter from './EPIs/EPIsRouter';
import InventoriesRouter from './Inventories/InventoriesRouter';
import MachinesRouter from '../Tools/Machines/MachinesRouter';

const ProductionRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.PRODUCTION.CONSUMABLES}`}
        component={ArticlesRouter}
      />
      <Route
        path={`${path}${subRoutes.PRODUCTION.DEPOSITS}`}
        component={DepositsRouter}
      />
      <Route
        path={`${path}${subRoutes.PRODUCTION.PRIMARY_MATTERS}`}
        component={PrimaryMattersRouter}
      />
      <Route
        path={`${path}${subRoutes.PRODUCTION.EPI}`}
        component={EPIsRouter}
      />
      <Route
        path={`${path}${subRoutes.PRODUCTION.STOCK_MOVEMENTS}`}
        component={MovementsStockRouter}
      />
      <Route
        path={`${path}${subRoutes.PRODUCTION.INVENTORIES}`}
        component={InventoriesRouter}
      />
      <Route
        path={`${path}${subRoutes.PRODUCTION.TOOLS}`}
        component={MachinesRouter}
      />
      <Route
        path={path}
        render={() => (
          <Redirect to={`${path}${subRoutes.PRODUCTION.PRIMARY_MATTERS}`} />
        )}
      />
    </Switch>
  );
};

export default ProductionRouter;
