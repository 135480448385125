import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import CardsGraph from '../../../Production/Deposits/CardsGraph';

/**
 *  Component for showing taks status according to a given depot id
 *  @component
 *  @param {Object} props parents props
 *  @param {String}  props._id Mongo ObjectID of a depot
 *  @returns {any} react component
 */
function ShowTanksWrapper({ _id }) {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const [primaryMatters, setPrimaryMatters] = useState([]);
  const [tanks, setTanks] = useState([]);
  const [forceRefresh] = useState(false);

  const [, setIsLoading] = useState(false);

  const memoizedBatchFetching = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/primary-matters/bydeposit/${_id}`
      });
      setPrimaryMatters(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [_id]);

  const memoizedTankFetching = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/tanks?deposit=${_id}`
      });
      setTanks(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [_id]);

  useEffect(() => {
    memoizedBatchFetching();
    memoizedTankFetching();
  }, []);

  return (
    <>
      <CardsGraph
        batches={primaryMatters && primaryMatters}
        tanks={tanks}
        forceRefresh={forceRefresh}
      />
    </>
  );
}

ShowTanksWrapper.propTypes = {
  _id: PropTypes.string.isRequired
};

export default ShowTanksWrapper;
