import React, { useCallback, useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import {
  Button,
  Col,
  Row,
  Spin,
  Tabs,
  Descriptions,
  message,
  Tag,
  Collapse,
  Tooltip,
  Popconfirm
} from 'antd';
import {
  PlusCircleOutlined,
  EditOutlined,
  FileDoneOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';
import { dealsPriorities } from '../../../../utils/constants/tagColors';
import useAuthContext from '../../../../contexts/AuthContext';
import PageHeaderCustom from '../../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../../components/ContentCustom/ContentCustom';
import useErrorMessage from '../../../../utils/ErrorMessage';
import { SelectDealStatus } from './SelectDealStatus';
import DealDetails from './DetailsDeal';
import AddEditActivityForm from './AddEditActivityForm';
import ListDealActivity from './ListDealActivity';
import EditDrawer from '../List/EditDrawer';
import useDealsContext from '../List/DealsContext';
import useColumns from '../../Sites/Quotations/columns';
import Datatable from '../../../../components/DataTable/Datatable';
import { routes, subRoutes } from '../../../../utils/constants/routes';

const { TabPane } = Tabs;
const { Panel } = Collapse;

const successMessage = message.success;

const ShowDeal = () => {
  const { dispatchAPI } = useAuthContext();
  const { setVisible, deal, fetchDealById } = useDealsContext();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [formActivity, setFormActivity] = useState(false);
  const [listActivity, setListActivity] = useState(true);
  const [itemSelected, setItemSelected] = useState(null);
  const [selectedTab, setSelectedTab] = useState('activity');
  const [kanbanColumns, setKanbanCols] = useState([]);
  const { message: errorMessage } = useErrorMessage();
  const [purpose, setPurpose] = useState('create');
  const menuItems = [
    { name: t('deals.tabs.activities'), key: 'activity' },
    { name: t('deals.tabs.notes'), key: 'note' },
    { name: t('deals.tabs.calls'), key: 'call' },
    { name: t('deals.tabs.meetings'), key: 'meeting' }
  ];

  const validateDealAndCreateSite = async () => {
    if (deal.buisness.reference) {
      try {
        await dispatchAPI('PATCH', {
          url: `/deals/${id}`,
          body: { validate: true }
        });
        fetchDealById(id);
        message.success('Le chantier a été crée !');
      } catch (e) {
        if (e.response) errorMessage(e.response.status);
      }
    } else
      message.warning(
        "Informations manquante pour l'affaire, référence inconnue..."
      );
  };
  const EditDeal = () => {
    return (
      <>
        {deal?.status?.label !== 'Client' || !deal?.buisness?.reference ? (
          <Tooltip
            placement="bottom"
            title="Le chantier sera crée et l'entreprise sera définie comme client"
          >
            <Popconfirm
              placement="top"
              title="Voulez-vous valider cette affaire ?"
              onConfirm={validateDealAndCreateSite}
              okText="Oui"
              cancelText="Non"
            >
              <Button type="primary" icon={<FileDoneOutlined />}>
                Valider l&apos;affaire
              </Button>
            </Popconfirm>
          </Tooltip>
        ) : null}
        {deal?.site_id && (
          <Link to={`/commercial/sites/show/${deal?.site_id}`}>
            <Button>Voir le chantier</Button>
          </Link>
        )}

        <Button
          type="primary"
          onClick={() => {
            setVisible(true);
          }}
        >
          <EditOutlined />
          {t('deals.show.edit')}
        </Button>
      </>
    );
  };

  const titleDivider = (
    <div style={{ width: '110px' }}>
      <hr
        style={{
          backgroundColor: 'black',
          height: '3px',
          border: 'none',
          marginBottom: '18px'
        }}
      />
    </div>
  );

  const getKanbans = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsLoading(true);
    await fetchDealById(id);
    await getKanbans();
    setIsLoading(false);
  }, [id]);

  const editStatus = async (value) => {
    let newStatusId = '';
    kanbanColumns.forEach((col, index) => {
      if (index === value) newStatusId = col._id;
    });

    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${id}`,
        body: {
          status: newStatusId
        }
      });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const addActivity = async (values) => {
    // eslint-disable-next-line no-param-reassign
    values._id = uuid();

    await dispatchAPI('PATCH', {
      url: `/deals/${id}`,
      body: {
        activities: [...deal.activities, values]
      }
    });
    setFormActivity(false);
    setListActivity(true);
  };

  const delActivity = async (values) => {
    setListActivity(false);
    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${id}`,
        body: {
          activities: deal.activities.filter(
            (activity) => activity._id !== values._id
          )
        }
      });
      successMessage(t('deals.show.del_activity'));
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
    setListActivity(true);
    setFormActivity(false);
  };

  const updateActivity = async (values) => {
    const newActivities = deal.activities.map((activity) => {
      if (activity._id === itemSelected._id)
        return { ...values, _id: activity._id };
      return activity;
    });

    try {
      await dispatchAPI('PATCH', {
        url: `/deals/${id}`,
        body: {
          activities: newActivities
        }
      });
      successMessage(t('deals.show.update_activity'));
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
    setListActivity(true);
    setFormActivity(false);
  };

  const goToAddActivity = () => {
    setPurpose('create');
    setListActivity(false);
    setFormActivity(true);
  };

  const editActivity = (elem) => {
    setItemSelected(elem);
    setPurpose('edit');
    setListActivity(false);
    setFormActivity(true);
    setSelectedTab('activity');
  };

  const showActivities = (form) => {
    if (form) {
      return (
        <AddEditActivityForm
          purpose={purpose}
          addActivity={addActivity}
          updateActivity={updateActivity}
          listActivity={listActivity}
          setFormActivity={setFormActivity}
          setListActivity={setListActivity}
          itemSelected={itemSelected}
        />
      );
    }
    return (
      <ListDealActivity
        listActivity={listActivity}
        selectedTab={selectedTab}
        editActivity={editActivity}
        delActivity={delActivity}
      />
    );
  };

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions, listActivity, formActivity]);

  return (
    <>
      <PageHeaderCustom
        title={t('deals.show.title')}
        withSubRoutes
        extra={<EditDeal />}
      />
      <Spin spinning={isLoading}>
        <ContentCustom>
          <Row>
            <Col span={24}>
              {deal && (
                <SelectDealStatus
                  editStatus={editStatus}
                  dealStatusIndex={deal.status ? deal.status._id : 0}
                  kanbanCols={kanbanColumns}
                />
              )}
            </Col>
          </Row>
          <Row gutter={[16, 12]}>
            <Col xl={10} xs={24}>
              <Collapse style={{ backgroundColor: 'white', border: 'none' }}>
                <Panel
                  showArrow={false}
                  collapsible="disabled"
                  header={
                    <Descriptions column={4}>
                      <Descriptions.Item label={t('deals.column.manager')}>
                        <Link to={`/users/show/${deal && deal?.lead?._id}`}>
                          {deal?.lead?.first_name}
                          {deal?.lead?.last_name}
                        </Link>
                      </Descriptions.Item>

                      <Descriptions.Item label={t('deals.column.priority')}>
                        <Tag color={dealsPriorities[deal?.priority]}>
                          {t(`deals.tags.${deal?.priority}`)}
                        </Tag>
                      </Descriptions.Item>
                      <Descriptions.Item
                        span={2}
                        label={t('deals.show.global_information')}
                      >
                        {`${(deal && deal.global_information) || ''}`}
                      </Descriptions.Item>
                    </Descriptions>
                  }
                />
              </Collapse>
            </Col>
            <Col
              xl={13}
              xs={24}
              style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-end'
              }}
            >
              <Tabs
                onChange={(key) => {
                  setSelectedTab(key);
                }}
              >
                {menuItems.map((item) => (
                  <TabPane tab={item.name} key={item.key} />
                ))}
              </Tabs>
              <Button
                type="primary"
                style={{
                  position: 'absolute',
                  bottom: 28,
                  right: 0,
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={goToAddActivity}
              >
                <PlusCircleOutlined style={{ fontSize: 20, marginTop: 2 }} />
                {t('deals.show.add_activity')}
              </Button>
            </Col>
          </Row>

          <Row style={{ flexWrap: 'wrap-reverse' }} gutter={[20, 24]}>
            <Col xl={10} xs={24}>
              <DealDetails fetchData={fetchDealById} deal={deal} />
            </Col>

            <Col
              xl={13}
              xs={24}
              style={{ border: '2px solid var(--primaryColor)' }}
            >
              {showActivities(formActivity)}
            </Col>
          </Row>
          <Row gutter={[20, 24]} style={{ marginTop: '2%' }}>
            <Col>
              {t('deals.show.quote')}
              {titleDivider}
              <Datatable
                resourceName="quotations"
                columns={useColumns()}
                extraQuery={
                  deal && deal.companies ? `customer=${deal.companies._id}` : ''
                }
                path={`${routes.COMMERCIAL}${subRoutes.COMMERCIAL.QUOTATIONS}`}
                searchValue
                populate="customer, site"
                customActionColumn
              />
            </Col>
          </Row>
        </ContentCustom>
        <EditDrawer />
      </Spin>
    </>
  );
};

export default ShowDeal;
