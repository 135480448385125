import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDrop } from 'react-dnd';
import Repository from './DealCard';
import useBoxContext from './DealsContext';
import { kanbanIcon } from '../../../Settings/utilsKanban';

const Col = styled.div`
  height: 600px;
  position: relative;
  border-radius: 2px;
  min-width: 250px;
  margin: 5px;
  overflow-y: auto;
`;

const Title = styled.div`
  position: absolute;
  display: flex;
  justify-content: left;
  align-items: center;
  top: 0;
  background-color: var(--textColor);
  color: #fff;
  box-shadow: 0px 9px 16px -6px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  width: 96%;
  text-align: left;
  height: 42px;
  #icon {
    padding: 0 20px;
  }
  #label {
    text-transform: uppercase;
    letter-spacing: 0.1em;
  }
`;
const CardContainer = styled.div`
  position: absolute;
  top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const KanbanColumn = ({ column: { _id, label, icon } }) => {
  const { updateDeal, deals } = useBoxContext();
  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop: async (item) => {
      await updateDeal(item.id, { status: _id });
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  return (
    <div>
      <Col
        ref={drop}
        style={{
          borderRight: '1px solid #ebedf2'
        }}
      >
        <Title>
          <div id="icon">
            {kanbanIcon.filter((ic) => ic.label === icon)[0]?.value}
          </div>
          <div id="label">{label}</div>
        </Title>
        <CardContainer>
          {deals
            .filter((deal) => deal.status === _id)
            .map((deal) => (
              <Repository deal={deal} key={deal.name} />
            ))}
        </CardContainer>
      </Col>
    </div>
  );
};

KanbanColumn.propTypes = {
  column: PropTypes.shape({
    _id: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string
  }).isRequired
};

export default KanbanColumn;
