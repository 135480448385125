import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import { noLabelLayout } from '../../../utils/constants/formLayout';

const CreateUpdatePrimaryMatters = ({ purpose }) => {
  const generateFormItem = useGenerateFormItem();
  const [density, setDensity] = useState();
  const form = useRef();
  const { basicFields, advancedFields, isFieldsLoading } = useFields(
    density,
    form
  );
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  const config = {
    onCreateResource: {
      setBody: (data) => {
        return {
          ...data
        };
      }
    },
    onGetResource: {
      setFields: (data) => {
        return {
          ...data,
          mark_up:
            data.sold_price &&
            data.buy_price &&
            Math.round((data.sold_price / data.buy_price) * 100 - 100)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        return {
          ...data
        };
      }
    }
  };
  const advancedOptions = (
    <>
      <Row style={{ marginTop: 30, marginBottom: 30 }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Col {...noLabelLayout.wrapperCol}>
          <Button type="link" onClick={() => setVisible(!visible)}>
            {t('primary-matters.form.options')}
            {visible ? (
              <UpOutlined style={{ marginLeft: 10 }} />
            ) : (
              <DownOutlined style={{ marginLeft: 10 }} />
            )}
          </Button>
        </Col>
      </Row>
      {visible &&
        advancedFields.map((field) =>
          generateFormItem('primary-matters', field)
        )}
    </>
  );
  const onValuesChange = (_, { type, buy_price, mark_up, sold_price }) => {
    let markup;
    if (mark_up) markup = mark_up;
    else markup = 0;
    setDensity(type);
    form.current.setFieldsValue({ density: type === 'POLYOL' ? 1.18 : 1.23 });
    if (_.buy_price || _.mark_up) {
      form.current.setFieldsValue({
        sold_price: (buy_price + (markup * buy_price) / 100).toFixed(2)
      });
    }
    if (_.sold_price) {
      form.current.setFieldsValue({
        buy_price: (sold_price - (markup * buy_price) / 100).toFixed(2)
      });
    }
  };

  return (
    <CreateUpdateContainer
      ref={form}
      fields={basicFields}
      purpose={purpose}
      baseUrl="primary-matters"
      resource="primary-matters"
      loadingFields={isFieldsLoading}
      formExtra={advancedOptions}
      withSubRoutes
      onValuesChange={onValuesChange}
      config={config}
    />
  );
};
CreateUpdatePrimaryMatters.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdatePrimaryMatters;
