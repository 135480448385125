const setColumn = (t, type) => {
  if (type === 'articles' || type === 'epis') {
    return [
      {
        title: t('inventories.column.code'),
        dataIndex: 'code',
        key: 'code'
      },
      {
        title: t('inventories.column.label'),
        dataIndex: 'label',
        key: 'label'
      },
      {
        title: t('inventories.column.quantity_updated'),
        dataIndex: 'quantity',
        key: 'quantity',
        render: (quantity) => quantity || 0
      },
      {
        title: t('inventories.column.unit'),
        dataIndex: 'unit',
        key: 'unit',
        render: (unit) => unit?.label
      }
    ];
  }

  if (type === 'primary_matters') {
    return [
      {
        title: t('inventories.column.tank'),
        dataIndex: 'tank',
        key: 'code',
        render: (tank) => tank && tank.label
      },
      {
        title: t('inventories.column.batch_number'),
        dataIndex: 'batch_number',
        key: 'batch_number'
      },
      {
        title: t('inventories.column.quantity_updated'),
        dataIndex: 'quantity',
        key: 'quantity',
        render: (quantity) => quantity || 0
      },
      {
        title: t('inventories.column.unit'),
        dataIndex: 'unit',
        key: 'unit',
        render: (unit) => unit?.label
      }
    ];
  }
  return [];
};

export default setColumn;
