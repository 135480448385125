import React from 'react';
import PropTypes from 'prop-types';
import useFields from './fields';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';

const CreateUpdateMovements = ({ purpose }) => {
  const { basicFields, isFieldsLoading } = useFields();

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="movements"
      resource="movements"
      withSubRoutes
    />
  );
};

CreateUpdateMovements.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateMovements;
