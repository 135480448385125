import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Form, message, Modal, Row } from 'antd';
import {
  ContentState,
  EditorState,
  convertToRaw,
  convertFromHTML
} from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import { formItemLayout } from '../../../utils/constants/formLayout';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import useFields from './fields';

const CreateUpdateEmail = ({
  purpose,
  visible,
  setVisible,
  dataTemplate,
  setForceRefresh
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [email, setEmail] = useState({});
  const [form] = Form.useForm();
  const generateFields = useGenerateFormItem();
  const { fields } = useFields();

  const getEmail = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/emails/${dataTemplate.key}`
      });
      setEmail(data);
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  useEffect(() => {
    if (dataTemplate) {
      (async () => {
        await getEmail();
      })();
    }
  }, [dataTemplate]);

  useEffect(() => {
    if (Object.keys(email).length && email.data) {
      const html = email.data && convertFromHTML(email.data);
      const state =
        html &&
        ContentState.createFromBlockArray(html.contentBlocks, html.entityMap);
      setEditorState(EditorState.createWithContent(state));
      form.setFieldsValue(email);
    }
  }, [email]);

  const updateModelEmail = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/emails/${dataTemplate.key}`,
        body
      });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const createModelEmail = async (body) => {
    try {
      await dispatchAPI('POST', {
        url: '/emails',
        body
      });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const handleSubmit = async (values) => {
    const htmlBody = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    const body = {
      ...email,
      ...values,
      data: htmlBody
    };

    if (purpose === 'edit') {
      await updateModelEmail(body);
    } else {
      await createModelEmail(body);
    }
    setForceRefresh();
    setVisible(!visible);
  };

  return (
    <Modal
      width={1000}
      style={{ height: 800 }}
      visible={visible}
      onCancel={() => setVisible(!visible)}
      footer={null}
    >
      <Form
        style={{ margin: 50 }}
        onFinish={handleSubmit}
        {...formItemLayout}
        form={form}
      >
        {fields.map((field) => generateFields('emails' || 'emails', field))}

        <Row style={{ minHeight: 500 }}>
          <Col offset={2} span={18}>
            <Editor
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              editorState={editorState}
              onEditorStateChange={setEditorState}
            />
          </Col>
        </Row>
        <Button
          type="add"
          htmlType="submit"
          style={{
            marginLeft: 10,
            float: 'right'
          }}
        >
          {t('buttons.save')}
          <CheckOutlined />
        </Button>
        <Button
          onClick={() => setVisible(!visible)}
          style={{
            background: '#fff',
            color: 'red',
            borderColor: 'red',
            borderRadius: 3,
            float: 'right'
          }}
        >
          {`${t('buttons.cancel')}`}
          <CloseOutlined />
        </Button>
      </Form>
    </Modal>
  );
};
CreateUpdateEmail.propTypes = {
  dataTemplate: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  purpose: PropTypes.string,
  setForceRefresh: PropTypes.func.isRequired
};
CreateUpdateEmail.defaultProps = {
  dataTemplate: null,
  purpose: null
};

export default CreateUpdateEmail;
