import React, { useState } from 'react';
import { Divider, Layout } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import Datatable from '../../../components/DataTable/Datatable';
import { SearchAddBar } from '../../../utils/constants/customButton';

const { Content } = Layout;
const iconSize = 18;

const CompanySitesTable = ({ id }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState();

  const columns = [
    {
      title: t('sites.column.reference'),
      dataIndex: 'reference',
      key: 'reference',
      sorter: true
    },
    {
      title: t('sites.column.contact'),
      dataIndex: 'contact',
      render: (contact) => `${contact.first_name} ${contact.last_name}`
    },
    {
      title: t('sites.column.city'),
      dataIndex: 'city'
    },
    {
      key: 'action',
      fixed: 'right',
      width: '8%',
      render: ({ key }) => {
        return (
          <span>
            <Link
              to={{
                pathname: `/commercial/sites/show/${key}`
              }}
            >
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
            <Divider type="vertical" />
            <Link
              to={{
                pathname: `/commercial/sites/edit/${key}`
              }}
            >
              <EditOutlined style={{ fontSize: iconSize }} />
            </Link>
          </span>
        );
      }
    }
  ];

  return (
    <>
      <Content
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Datatable
          resourceName="sites"
          columns={columns}
          populate="contact"
          path="/commercial/sites"
          extraQuery={`company=${id}&sort=-created_at`}
          searchValue={searchValue}
          customActionColumn
          withCreateButton={false}
        />
      </Content>
    </>
  );
};

export default CompanySitesTable;
