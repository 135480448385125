import React from 'react';
import { Layout, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import Datatable from '../../../components/DataTable/Datatable';
import { invoiceTypes } from '../../../utils/constants/tagColors';

const { Content } = Layout;
const iconSize = 18;

const CompanyUnpaidTable = ({ id }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('invoices.list.column.title.number'),
      key: 'number',
      dataIndex: 'number',
      sorter: true
    },
    {
      title: t('invoices.list.column.title.type'),
      dataIndex: 'type',
      render: (type) => (
        <Tag color={invoiceTypes[type]}>{t(`invoices.tags.${type}`)}</Tag>
      )
    },
    {
      title: t('invoices.list.column.title.subject'),
      dataIndex: 'subject'
    },
    {
      title: t('invoices.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('invoices.list.column.title.payment_date'),
      dataIndex: 'payment_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('invoices.list.column.title.total'),
      dataIndex: 'total',
      render: (total) => `${parseFloat(total).toFixed(2)}€`
    },
    {
      key: 'action',
      fixed: 'right',
      width: '5%',
      render: ({ key, payment_date }) => {
        return (
          <>
            <Link
              to={{
                pathname: `/accounting/invoices/show/${key}`
              }}
            >
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
          </>
        );
      }
    }
  ];

  return (
    <>
      <Content
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Datatable
          resourceName="invoices"
          path="/accounting/invoices"
          columns={columns}
          extraQuery={`customer=${id}&deadline_date<${moment()
            .endOf('day')
            .toISOString()}&payment_date=null&sort=-created_at`}
          customActionColumn
          withCreateButton={false}
        />
      </Content>
    </>
  );
};

export default CompanyUnpaidTable;
