import React from 'react';
import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const OptionsRow = ({ range, setRange }) => {
  return (
    <RangePicker
      value={range}
      onChange={(dates) => setRange(dates)}
      style={{ marginTop: 8 }}
      format="DD/MM/YYYY"
    />
  );
};

export default OptionsRow;
