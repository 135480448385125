import { Row, Form, Input, Button } from 'antd';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import useAuthContext from '../../../../contexts/AuthContext';
import useMovementContext from './MovementsContext';

const { TextArea } = Input;
const ModalMail = ({ dataMovement }) => {
  const {
    current,
    sendMail,
    setVisible,
    getManagerForToMail,
    managers
  } = useMovementContext();
  const { user } = useAuthContext();
  const [form] = Form.useForm();
  const listStep = [
    "A l'arrivée",
    'Mise en route',
    'Vérifications des mesures',
    'Projection',
    'Carotage',
    'Vérification',
    'Conformité du chantier',
    'Saisie des heures'
  ];

  const handleSubmit = async (values) => {
    await sendMail({ ...values, to: managers });
    setVisible(false);
  };

  const setValues = async () => {
    form.setFieldsValue({
      sender: {
        name: `${user?.first_name} ${user?.last_name}`,
        email: user?.email
      },
      to: {
        name: `${await managers.map(({ name }) => name).join(';')}`,
        email: await managers.map(({ email }) => email).join(';')
      },
      purpose: `Signalement pour ${dataMovement?.site?.reference} | Étape: ${listStep[current]}`
    });
  };

  useEffect(() => {
    (async () => {
      await setValues();
    })();
  }, [current, managers]);
  useEffect(() => {
    if (dataMovement && dataMovement.truck._id)
      getManagerForToMail(dataMovement.truck._id);
  }, [dataMovement]);

  return (
    <Form form={form} onFinish={(values) => handleSubmit(values)}>
      <Form.Item noStyle name={['sender', 'name']}>
        <Input hidden disabled />
      </Form.Item>
      <Form.Item name={['sender', 'email']} label="Expéditeur">
        <Input disabled />
      </Form.Item>
      <Form.Item noStyle name={['to', 'name']}>
        <Input hidden disabled />
      </Form.Item>
      <Form.Item name={['to', 'email']} label="Destinataire">
        <Input disabled />
      </Form.Item>
      <Form.Item name="purpose" label="Objet">
        <Input disabled />
      </Form.Item>
      <Form.Item name="message" label="Message">
        <TextArea />
      </Form.Item>
      <Row justify="center">
        <Form.Item>
          <Button type="add" htmlType="submit">
            Envoyer
          </Button>
        </Form.Item>
      </Row>
    </Form>
  );
};
ModalMail.propTypes = {
  dataMovement: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
ModalMail.defaultProps = {
  dataMovement: null
};

export default ModalMail;
