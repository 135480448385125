import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { outOfNavRoutes, routes } from '../utils/constants/routes';
import Login from './login';
import Home from './home/Home';
import Exception from '../components/Exceptions/Exception';
import useAuthContext from '../contexts/AuthContext';
import BasicLayout from '../components/layouts/BasicLayout';
import ProfileRouter from './profile/ProfileRouter';
import Confirmation from './emailConfirmation/Confirmation';
import ConfigurationRouter from './Configuration/ConfigurationRouter';
import Settings from './Settings/settings';
import UserRouter from './users/UserRouter';
import ProductionRouter from './Production/ProductionRouter';
import CommercialRouter from './Commercial/CommercialRouter';
import AccountRouter from './Accounting/AccountRouter';
import InterventionsRouter from './Movements/InterventionRouter';
import ToolsRouter from './Tools/ToolsRouter';
import HomeCommercial from './home/Commercial/HomeCommercial';
import HomeTech from './home/Tech/HomeTech';
import PurchaseRouter from './purchases/PurchasesRouter';
import HomeDirection from './home/Direction/HomeDirection';
import HomeAccounting from './home/Accounting/HomeAccounting';

export const PrivateRoute = ({
  location,
  component: Component,
  ...restProps
}) => {
  const authContext = useAuthContext();
  const splitPath = location.pathname.split('/');
  const splitPathGrant = location.pathname.split('/').filter((e) => e !== '');
  const path = `/${
    splitPath[2] && !['show', 'edit', 'create'].includes(splitPath[2])
      ? splitPath[2]
      : splitPath[1]
  }`;

  const grantFromPath =
    Symbol.iterator in Object(splitPathGrant)
      ? splitPathGrant.join('.').toLowerCase()
      : splitPathGrant.toLowerCase();

  const checkToRender = (props, resourceToCheck) => {
    if (!authContext.isValid) {
      return (
        <Redirect
          to={{
            pathname: outOfNavRoutes.LOGIN,
            state: { from: props.location }
          }}
        />
      );
    }

    if (!authContext.checkIsGranted(resourceToCheck)) {
      return (
        <Redirect
          to={{
            pathname: routes.HOME,
            state: { from: props.location }
          }}
        />
      );
    }

    return (
      <BasicLayout path={path}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Component {...props} />
      </BasicLayout>
    );
  };

  return (
    <Route
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...restProps}
      render={(props) => checkToRender(props, grantFromPath)}
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    state: PropTypes.shape({
      type: PropTypes.string,
      model: PropTypes.shape({
        name: PropTypes.string
      })
    })
  })
};

PrivateRoute.defaultProps = {
  location: { pathname: '/' }
};
const getRole = (type) => {
  switch (type) {
    case 'ACCOUNTING':
      return HomeAccounting;
    case 'SELLER':
      return HomeCommercial;
    case 'MANAGER':
      return Home;
    case 'TECH':
      return HomeTech;
    case 'DIRECTION':
      return HomeDirection;
    case 'ADMIN':
      return () => <Redirect to={routes.USERS} />;
    default:
      return Home;
  }
};

const Router = () => {
  const { user } = useAuthContext();
  return (
    <BrowserRouter>
      <Switch>
        <Route path={`${outOfNavRoutes.LOGIN}/:token`} component={Login} />
        <Route path={outOfNavRoutes.LOGIN} component={Login} />
        <Route
          path={`${outOfNavRoutes.CONFIRMATION}/:token`}
          component={Confirmation}
        />
        <PrivateRoute path={outOfNavRoutes.PROFILE} component={ProfileRouter} />
        <PrivateRoute
          exact
          path={routes.HOME}
          component={getRole(user?.type)}
        />
        <PrivateRoute path={routes.USERS} component={UserRouter} />
        <PrivateRoute
          path={routes.CONFIGURATION}
          component={ConfigurationRouter}
        />
        <PrivateRoute path={routes.COMMERCIAL} component={CommercialRouter} />
        <PrivateRoute path={`${routes.ACCOUNTING}`} component={AccountRouter} />
        <PrivateRoute path={routes.PRODUCTION} component={ProductionRouter} />
        <PrivateRoute path={routes.PURCHASES} component={PurchaseRouter} />
        <PrivateRoute
          path={routes.INTERVENTION}
          component={InterventionsRouter}
        />

        <PrivateRoute component={Exception} />
      </Switch>
    </BrowserRouter>
  );
};

export default Router;
