import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';
import { Button, message, Popconfirm, Skeleton, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import { routes, subRoutes } from '../../../utils/constants/routes';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import { SearchAddBar } from '../../../utils/constants/customButton';
import Datatable from '../../../components/DataTable/Datatable';
import useColumns from '../Contacts/columns';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import NewContactDrawer from '../Contacts/NewContactDrawer';
import CompanyQuotationsTable from './CompanyQuotationsTable';
import CompanyInvoicesTable from './CompanyInvoicesTable';
import CompanySitesTable from './CompanySitesTable';
import CompanyPaymentsTable from './CompanyPaymentsTable';
import CompanyUnpaidTable from './CompanyUnpaidTable';

const { TabPane } = Tabs;

const DetailCompany = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const [extraResources, setExtraResources] = useState([]);
  const [company, setCompany] = useState({});
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsloading] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [visible, setVisible] = useState(false);
  const columns = useColumns();
  const [forceRefresh, setForceRefresh] = useState(false);
  const { pathname } = useLocation();
  const listToDisplayForTabs = {
    quotations: CompanyQuotationsTable,
    invoices: CompanyInvoicesTable,
    sites: CompanySitesTable,
    payments: CompanyPaymentsTable,
    unpaid: CompanyUnpaidTable
  };

  const getCompany = useCallback(async () => {
    setIsloading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${id}?populate=designated_commercial`
      });
      setCompany(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsloading(false);
  }, [id]);

  const deleteCompany = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/companies/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };
  const configRowSelection = {
    type: 'radio',
    onChange: async (selectedRowKeys, selectedRows) => {
      if (selectedRows[0].key !== undefined) {
        setForceRefresh(false);
        try {
          await dispatchAPI('PATCH', {
            url: `/contacts/${selectedRows[0].key}`,
            body: { is_default_contact: true, my_company: id }
          });
          setForceRefresh(true);
        } catch (e) {
          if (e.response) message.error(e.response.data.message);
        }
      }
    },
    columnTitle: 'Défault',
    columnWidth: '100px',
    selectedRowKeys: extraResources
      .filter((el) => el.is_default_contact)
      .map((ele) => ele.key)
  };
  useEffect(() => {
    setSearchValue(null);
    (async () => {
      await getCompany();
    })();
  }, [getCompany, pathname]);

  return (
    <>
      <PageHeaderCustom
        title={t(`companies.show.title`)}
        withSubRoutes
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.SITES}/create/${id}`
              }}
            >
              <Button>Créer un chantier</Button>
            </Link>

            {company.type === 'SUPPLIERS' ? (
              <Link
                to={{
                  pathname: `${routes.PURCHASES}${subRoutes.PURCHASES.SUPPLIERS}/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
            ) : (
              <>
                <Link
                  to={{
                    pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CUSTOMERS}/edit/${id}`
                  }}
                >
                  <Button type="primary">
                    {`${t('buttons.edit')} `}
                    <EditOutlined />
                  </Button>
                </Link>
              </>
            )}
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteCompany}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <DescriptionList column={4} data={listContent(company)} translate />
        </Skeleton>
        <Tabs type="card" style={{ marginTop: 20 }}>
          <TabPane tab={t('companies.show.contactListTitle')} key="contact">
            <SearchAddBar
              addPath={`${routes.COMMERCIAL}/${
                company.type ? company.type.toLowerCase() : 'customer'
              }/show/${company._id}/contacts/create`}
              setSearchValue={setSearchValue}
              onClick={() => {
                setVisible(!visible);
              }}
            />
            <NewContactDrawer
              isVisible={visible}
              setVisibilityContactDrawer={setVisible}
              setForceRefresh={setForceRefresh}
            />
            <Datatable
              setExtraResources={setExtraResources}
              resourceName="contacts"
              path={`${routes.COMMERCIAL}/${
                company.type ? company.type.toLowerCase() : 'customer'
              }/show/${company._id}/contacts`}
              columns={columns}
              populate="my_company"
              extraQuery={`my_company=${id}`}
              searchValue={searchValue}
              forceRefresh={forceRefresh}
              rowSelection={configRowSelection}
            />
          </TabPane>
          {Object.entries(listToDisplayForTabs).map(([key, Value]) => (
            <TabPane tab={t(`companies.show.${key}`)} key={key}>
              <Value id={id} />
            </TabPane>
          ))}
        </Tabs>
      </ContentCustom>
    </>
  );
};

export default DetailCompany;
