import React, { useEffect, useState } from 'react';
import { Steps, Button, Form, Modal, Spin, Row, Col, message } from 'antd';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import useMovementContext from './MovementsContext';
import useListContentStep from './ListContentStep';
import Subheader from './Subheader';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import useAuthContext from '../../../../contexts/AuthContext';
import ModalMail from './ModalMail';

const { Step } = Steps;

const useSteps = ({ enums, cstbId, fetchMovement }) => {
  const {
    updateResource,
    form,
    config,
    CSTB,
    setCoringHeavy,
    visible,
    setVisible,
    current,
    setCurrent,
    valuesState,
    setValuesState,
    setActiveKeyTab,
    setStartHeavy,
    setMediumHeavy,
    setEndHeavy,
    getMovement,
    getCstbs,
    getDeposit,
    batch,
    startHeavy,
    mediumHeavy,
    endHeavy,
    managers,
    sendMail,
    getManagerForToMail
  } = useMovementContext();
  const { user } = useAuthContext();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const handleSubmit = async () => {
    updateResource({ status: 'WAITING_VALIDATION' }, cstbId._id);
    message.success('Fiche CSTB saisie !');
    await getMovement(CSTB?._id);
    await fetchMovement();
    getCstbs();
    setActiveKeyTab('5');
  };
  const { steps } = useListContentStep(enums, handleSubmit);
  const listStep = [
    "A l'arrivée",
    'Mise en route',
    'Vérifications des mesures',
    'Projection',
    'Carottage',
    'Vérification',
    'Conformité du chantier',
    'Saisie des heures'
  ];
  const setValues = () => {
    if (cstbId && config !== null) {
      form.setFieldsValue(
        config.onGetResource && config.onGetResource.setFields
          ? config.onGetResource.setFields(cstbId)
          : cstbId
      );
    }
  };

  const info = () => {
    Modal.info({
      title: 'Cycle des machines à 0',
      content: (
        <div>
          Avant de continuer, veuillez mettre le cycle de vos machines à zéro.
        </div>
      ),
      onOk() {}
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    await getMovement(id);
    setIsLoading(false);
  };
  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    if (CSTB) {
      getManagerForToMail(CSTB?.truck?._id);
      getDeposit(CSTB?.truck?.deposit?._id);
    }
  }, [CSTB]);

  useEffect(() => {
    if (config !== null && cstbId) {
      setValues();
    }
  }, [cstbId]);

  const next = async (body) => {
    switch (current) {
      case 0:
        if (body.temperature === null) {
          message.warning('Veuillez remplir les champs obligatoire!');
          return false;
        }
        if (body.temperature <= -3) {
          await sendMail({
            sender: {
              name: `${user?.first_name} ${user?.last_name}`,
              email: user?.email
            },
            to: managers,
            purpose: `Signalement pour ${CSTB?.site?.reference} | Étape: ${listStep[current]}`,
            message: `Signalement automatique sur le chantier ${CSTB?.site?.reference}, problème sur température support: ${body.temperature} °C.`
          });
          message.warning('Un message a été envoyé au responsable !');
        }
        updateResource(body, cstbId._id);
        break;
      case 1:
        break;
      case 2:
        if (body.details.length === 0) {
          message.warning('Veuillez saisir au moins une mesure');
          return false;
        }
        if (body.details && body.details[0]) {
          const result = [];
          body.details.forEach((value) => {
            if (
              value.floor &&
              value.area &&
              value.thickness &&
              value.thickness.length > 0
            ) {
              result.push({ ...value });
            }
          });
          if (result.length === body.details.length) {
            updateResource({ ...body, batch }, cstbId._id);
            info();
          } else {
            message.warning('Veuillez remplir les champs obligatoire!');
            return false;
          }
        }
        break;
      case 4:
        if (
          body.coring.coring_end &&
          body.coring.coring_medium &&
          body.coring.coring_start
        ) {
          const result =
            (body.coring.coring_medium +
              body.coring.coring_start +
              body.coring.coring_end) /
            3;

          if (result < 32 || result > 50) {
            await sendMail({
              sender: {
                name: `${user?.first_name} ${user?.last_name}`,
                email: user?.email
              },
              to: managers,
              purpose: `Signalement pour ${CSTB?.site?.reference} | Étape: ${listStep[current]}`,
              message: `Signalement automatique sur le chantier ${CSTB?.site?.reference}, problème sur la masse volumique: ${result} kg/m3.`
            });
            message.warning('Un message a été envoyé au responsable !');
          }
          updateResource(body, cstbId._id);
        } else {
          message.warning('Veuillez remplir les champs obligatoire!');
          return false;
        }

        break;
      case 5:
        if (
          body.details_after.length === 0 ||
          !body.cycle ||
          body.cycle === 0
        ) {
          message.warning('Veuillez remplir champs obligatoire');
          return false;
        }
        if (body.details_after && body.details_after[0]) {
          const result = [];
          body.details_after.forEach((value) => {
            if (
              value.floor &&
              value.area &&
              value.thickness &&
              value.thickness.length > 0
            ) {
              result.push({ ...value });
            }
          });
          if (result.length === body.details_after.length) {
            updateResource({ ...body, batch }, cstbId._id);
          } else {
            message.warning('Veuillez remplir les champs obligatoire!');
            return false;
          }
        }
        break;
      default:
        updateResource(body, cstbId._id);
        break;
    }
    setCurrent(current + 1);
    getMovement(id);
  };

  const prev = () => {
    setCurrent(current - 1);
    getMovement(id);
  };
  const getTotalHeavy = async () => {
    await setCoringHeavy(
      Math.round(
        ((startHeavy || CSTB?.CSTB?.coring.coring_start) +
          (mediumHeavy || CSTB?.CSTB?.coring.coring_medium) +
          (endHeavy || CSTB?.CSTB?.coring.coring_end)) /
          3
      )
    );
  };

  useEffect(() => {
    getTotalHeavy();
  }, [startHeavy, mediumHeavy, endHeavy, CSTB]);

  const onChange = (_, values) => {
    const R = 0.076 * 0.076;

    setValuesState({ ...valuesState, ...values });
    if (values.coring) {
      const {
        coring_start_heavy,
        coring_start_indic,
        coring_medium_heavy,
        coring_medium_indic,
        coring_end_heavy,
        coring_end_indic
      } = values.coring;

      if (coring_start_heavy && coring_start_indic) {
        const result = coring_start_heavy / (3.14 * R * coring_start_indic);
        setStartHeavy(result);
        form.setFieldsValue({ coring: { coring_start: Math.round(result) } });
      }

      if (coring_medium_heavy && coring_medium_indic) {
        const result = coring_medium_heavy / (3.14 * R * coring_medium_indic);
        setMediumHeavy(result);
        form.setFieldsValue({ coring: { coring_medium: Math.round(result) } });
      }
      if (coring_end_heavy && coring_end_indic) {
        const result = coring_end_heavy / (3.14 * R * coring_end_indic);
        setEndHeavy(result);
        form.setFieldsValue({ coring: { coring_end: Math.round(result) } });
      }

      setCoringHeavy(Math.round((startHeavy + mediumHeavy + endHeavy) / 3));
    }
  };

  const onChangeStep = (currentStep) => {
    setCurrent(currentStep);
  };
  return (
    <>
      <Spin spinning={isLoading}>
        {CSTB?.CSTB?.status === 'PENDING' ||
        (user.type === 'MANAGER' &&
          user?.managed_trucks?.includes(CSTB?.truck?._id)) ||
        user.type === 'DIRECTION' ? (
          <Form
            {...formItemLayout}
            form={form}
            onFinish={() => handleSubmit()}
            onValuesChange={onChange}
          >
            <Modal
              title={`Contacter le responsable : ${managers &&
                managers.map(({ name }) => name).join(' , ')}`}
              visible={visible}
              footer={null}
              width={800}
              onOk={() => setVisible(false)}
              onCancel={() => setVisible(false)}
            >
              <ModalMail dataMovement={CSTB} />
            </Modal>
            <Subheader data={CSTB} />
            <Steps
              labelPlacement="vertical"
              onChange={onChangeStep}
              current={current}
            >
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
            <div
              style={{
                minHeight: '200px',
                marginTop: '16px',
                paddingTop: '80px',
                paddingBottom: '80px',
                textAlign: 'center',
                backgroundColor: '#fafafa',
                border: '1px dashed #e9e9e9',
                borderRadius: '2px'
              }}
            >
              {steps[current].content}
            </div>
            <Row
              style={{ marginTop: 20 }}
              justify="space-between"
              gutter={[8, 10]}
            >
              <Col style={{ textAlign: 'end' }} span={12}>
                <Button danger onClick={() => setVisible(true)}>
                  Envoyer un mail au responsable
                </Button>
              </Col>
              <Col style={{ textAlign: 'end' }} span={12}>
                {current > 0 && (
                  <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                    Précédent
                  </Button>
                )}
                {current < steps.length - 1 && (
                  <Button
                    type="primary"
                    // disabled={form.getFieldsValue('temperature') === null}
                    onClick={() => next(form.getFieldsValue())}
                  >
                    Suivant
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        ) : (
          <span>La fiche est validée</span>
        )}
      </Spin>
    </>
  );
};

useSteps.propTypes = {
  enums: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  cstbId: PropTypes.shape({
    _id: PropTypes.string
  }),
  fetchMovement: PropTypes.func.isRequired
};

useSteps.defaultProps = {
  cstbId: null
};

export default useSteps;
