import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import numeral from 'numeral';

const { Column } = Table;
const DetailsPrestationsInvoice = ({ prestations, t }) => {
  return (
    <Table
      dataSource={(prestations || []).map(({ _id, ...article }) => ({
        ...article,
        key: _id
      }))}
      pagination={false}
    >
      <Column
        title={t('invoices.showInvoice.prestationsTable.label')}
        dataIndex="label"
        render={(label, record) => (
          <>
            <p>{label}</p>
            <p
              style={{
                textAlign: 'center',
                color: 'grey'
              }}
            >
              {record.comment}
            </p>
          </>
        )}
      />
      <Column
        title={t('invoices.showInvoice.prestationsTable.area')}
        dataIndex="area"
        align="right"
        render={(area) => `${numeral(area).format('0,0.00')}m²`}
      />
      <Column
        title={t('invoices.showInvoice.prestationsTable.unit_price')}
        dataIndex="unit_price"
        align="right"
        render={(price) => `${numeral(price).format('0,0.00')}€`}
      />

      <Column
        title={t('invoices.showInvoice.prestationsTable.discount')}
        dataIndex="discount"
        align="right"
        render={(discount) =>
          discount
            ? `${numeral(discount.value).format('0,0.00')}${
                discount.type === 'PERCENT' ? '%' : '€'
              }`
            : 'N/A'
        }
      />
      <Column
        title={t('invoices.showInvoice.prestationsTable.subTotal')}
        rowKey="_id"
        align="right"
        render={({ subtotal }) => {
          return `${numeral(subtotal).format('0,0.00')}€`;
        }}
      />
    </Table>
  );
};
DetailsPrestationsInvoice.propTypes = {
  t: PropTypes.func.isRequired,
  prestations: PropTypes.oneOfType([PropTypes.array])
};
DetailsPrestationsInvoice.defaultProps = {
  prestations: []
};

export default DetailsPrestationsInvoice;
