import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../contexts/AuthContext';
import PermissionsTable from './PermissionsTable';

const PermissionSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [permissions, setPermissions] = useState([]);
  const [newPermission] = useState({
    permission_label: 'label ...',
    grant: []
  });
  const fetchPermissions = async () => {
    try {
      const data = await dispatchAPI('GET', {
        url: `/permissions`
      });
      setPermissions(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const addPermission = async () => {
    try {
      await dispatchAPI('POST', { url: '/permissions', body: newPermission });
    } catch (e) {
      message.error(e.message);
    }
    fetchPermissions();
  };
  const deletePermission = async Permission => {
    try {
      await dispatchAPI('DELETE', {
        url: `/permissions/${Permission._id}`
      });
    } catch (e) {
      message.error(t('settings.errors.Permission_delete'));
    }
    fetchPermissions();
  };
  const editPermission = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/permissions/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    fetchPermissions();
  };
  useEffect(() => {
    fetchPermissions();
  }, []);
  return (
    <>
      {permissions.length > 0 && (
        <PermissionsTable
          permissions={permissions}
          editPermission={editPermission}
          deletePermission={deletePermission}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addPermission}
      >
        {t('settings.permissionstable.addPermission')}
      </Button>
    </>
  );
};

export default PermissionSettings;
