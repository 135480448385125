import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import FamilyTable from './familyTable';

const initialColumns = [
  {
    title: 'FAMILY',
    dataIndex: 'columns',
    key: 'columns'
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: text => <Input InitialValue={text} />
  }
];

const FamilySettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [articles, setArticles] = useState([]);
  const [familyCols, setFamilyCols] = useState(initialColumns);
  const [newFamilyCol] = useState({
    label: 'Nouvelle famille',
    rank: 0,
    show: true,
    in_incomes: true,
    in_expenses: true
  });

  const fetchArticles = async () => {
    try {
      const data = await dispatchAPI('GET', { url: `/articles` });
      setArticles(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const getFamilyCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/families' });
      setFamilyCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const addFamilyCol = async () => {
    try {
      await dispatchAPI('POST', { url: '/families', body: newFamilyCol });
      getFamilyCols();
    } catch (e) {
      message.error(e.message);
    }
  };
  const deleteFamilyCol = async familyCol => {
    if (articles.filter(articles => articles.family === familyCol.label).length > 0) {
      return message.error(
        t('configuration.family.error-delete-column')
      );
    }
    try {
      await dispatchAPI('DELETE', {
        url: `/families/${familyCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    await getFamilyCols();
  };
  const editFamilyCol = async (_id, row) => {
    const col = familyCols.find(col => col._id === _id);
    const useCol = articles.filter(article => article.status === col.label);
    if (useCol.length !== 0 && row.label !== col.label) {
      return message.error(
        t('configuration.error-delete-column')
      );
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/families/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    await getFamilyCols();
  };
  useEffect(() => {
    getFamilyCols();
    fetchArticles();
  }, []);

  return (
    <>
      <FamilyTable
        familyCols={familyCols}
        editFamilyCol={editFamilyCol}
        deleteFamilyCol={deleteFamilyCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addFamilyCol}
      >
        {t('configuration.family.add-family-col')}
      </Button>
    </>
  );
};

export default FamilySettings;
