import React, { useState, useEffect } from 'react';
import {
  Descriptions,
  Tag,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
  Input,
  Switch,
  TimePicker,
  Tooltip
} from 'antd';
import { useTranslation } from 'react-i18next';
import { ClockCircleTwoTone } from '@ant-design/icons';
import moment from 'moment';
import PropTypes from 'prop-types';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useMovementContext from '../PostIntervention/MovementsContext';
import { TruckIconFilled } from '../../../../utils/constants/customTruckIcons';

const ModalEditHoursControl = ({ data, visible, setVisible }) => {
  const { dispatchAPI } = useAuthContext();
  const { CSTB, getMovement } = useMovementContext();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const { message: errorMessage } = useErrorMessage();
  const [timeIntervention, setTimeIntervention] = useState(null);
  const [timeTruck, setTimeTruck] = useState(null);
  const [breakTime, setBreakTime] = useState(moment('00:30', 'HH:mm'));
  const taskList = [
    'start_truck',
    'start_movement',
    'due_movement',
    'due_truck'
  ];
  const updateResource = async (body) => {
    const result = CSTB?.CSTB?.hours_control.filter(
      (ele) => ele.technician_id !== body.technician_id
    );
    try {
      // eslint-disable-next-line no-shadow
      const { data } = await dispatchAPI('PATCH', {
        url: `cstb/${CSTB?.CSTB?._id}`,
        body: { hours_control: [...result, body] }
      });
      if (data.error)
        Modal.error({
          title: 'Erreur !',
          content:
            'Vous êtes pas autorisé à faire cette action, seul le technicien concerné peut saisir ses heures'
        });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      hour_record: {
        basket_night: false,
        basket: true,
        break: moment('00:30', 'HH:mm'),
        start_truck: moment('06:30', 'HH:mm')
      }
    });
  }, []);

  const diffHours = (time_a, time_b) => {
    const hour_a = Number(time_a.split(':')[0]);
    const hour_b = Number(time_b.split(':')[0]);
    const minute_a = Number(time_a.split(':')[1]);
    const minute_b = Number(time_b.split(':')[1]);
    const duration = hour_a - hour_b;
    let minutes = (minute_a - minute_b) % 60;
    // eslint-disable-next-line radix
    let hours = parseInt((minute_a - minute_b) / 60) + duration;
    if (minutes < 0) {
      minutes += 60;
      hours -= 1;
    }
    return `${hours}:${minutes}`;
  };

  const truckTime = (time_a, time_b, time_c, time_d) => {
    const timeT = diffHours(time_a, time_b);
    const timeTr = diffHours(time_c, time_d);
    const hour_a = Number(timeT.split(':')[0]);
    const hour_b = Number(timeTr.split(':')[0]);
    const minute_a = Number(timeT.split(':')[1]);
    const minute_b = Number(timeTr.split(':')[1]);
    const duration = hour_a + hour_b;
    let minutes = (minute_a + minute_b) % 60;
    // eslint-disable-next-line radix
    let hours = parseInt((minute_a + minute_b) / 60) + duration;
    if (minutes < 0) {
      minutes += 60;
      hours -= 1;
    }
    return `${hours}:${minutes}`;
  };

  const onChange = (_, values) => {
    const {
      start_movement,
      due_movement,
      start_truck,
      due_truck
    } = values.hour_record;
    if (
      due_truck &&
      start_truck &&
      due_movement &&
      start_movement &&
      values.hour_record.break
    ) {
      const timeTru = truckTime(
        start_movement.format('HH:mm'),
        start_truck.format('HH:mm'),
        due_truck.format('HH:mm'),
        due_movement.format('HH:mm')
      );
      const timeInt = diffHours(
        due_movement.format('HH:mm'),
        start_movement.format('HH:mm')
      );
      const timeI = diffHours(
        timeInt,
        moment(values.hour_record.break).format('HH:mm')
      );
      setTimeTruck(timeTru);
      setTimeIntervention(timeI);
      form.setFieldsValue({
        hour_record: {
          time_truck: timeTru,
          time_intervention: timeI
        }
      });
    }
    if (values.hour_record?.break !== null) {
      setBreakTime(values?.hour_record?.break);
    } else {
      setBreakTime(null);
    }
  };

  const handleSubmit = async (values) => {
    if (values.hour_record) {
      const result = {
        ...values.hour_record,
        technician_id: data?._id,
        movement_id: CSTB?._id
      };
      await updateResource(result);
      getMovement(CSTB?._id);
      setVisible(false);
    }
  };

  // const checkTime = (_, time) => {
  //   return new Promise((resolve, reject) => {
  //     const regexTime = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
  //     if (time.match(regexTime)) {
  //       return resolve();
  //     }
  //     return reject(new Error('Merci de renseigner un temps valide.'));
  //   });
  // };

  return (
    <Modal
      closable
      width={1000}
      visible={visible}
      footer={null}
      onCancel={() => setVisible(false)}
    >
      <Row justify="start">
        <Form
          form={form}
          onFinish={(values) => handleSubmit(values)}
          onValuesChange={onChange}
        >
          <Col span={24}>
            <Card style={{ border: 'none' }}>
              <Row>
                <Col
                  style={{ color: 'var(--addColor)', textAlign: 'start' }}
                  span={24}
                >
                  <>
                    <TruckIconFilled />
                    <span style={{ color: 'black', marginLeft: 20 }}>
                      {`${CSTB?.truck?.label} - Unité de projection : ${CSTB?.truck?.projection_unit}`}
                    </span>
                  </>
                </Col>
              </Row>
              <Descriptions
                colon={false}
                column={4}
                extra={<Tag>Infos du technicien</Tag>}
                style={{ fontSize: 20, flex: 'none' }}
              >
                <Descriptions.Item label="Nom:">
                  <span style={{ fontWeight: 'bold' }}>{data?.last_name}</span>
                </Descriptions.Item>
                <Descriptions.Item label="Prénom:">
                  <span style={{ fontWeight: 'bold' }}>{data?.first_name}</span>
                </Descriptions.Item>
                <Descriptions.Item label="Date de l'intervention:">
                  <span style={{ fontWeight: 'bold' }}>
                    {moment(Date.now()).format('DD/MM/YYYY')}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="N° de devis:">
                  <span style={{ fontWeight: 'bold' }}>
                    {CSTB?.quotation?.number}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Temps de transport:">
                  <Form.Item noStyle name={['hour_record', 'time_truck']}>
                    <Input hidden />
                  </Form.Item>
                  <span disabled style={{ fontWeight: 'bold' }}>
                    {timeTruck ? `${timeTruck} Heures` : null}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Temps de l'intervention:">
                  <Form.Item
                    hidden
                    noStyle
                    name={['hour_record', 'time_intervention']}
                  >
                    <Input hidden />
                  </Form.Item>
                  <span disabled style={{ fontWeight: 'bold' }}>
                    {timeIntervention ? `${timeIntervention} Heures` : null}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Temps de pause:">
                  <span style={{ fontWeight: 'bold' }}>
                    {breakTime
                      ? `${breakTime.format('HH:mm')} Heures`
                      : '00:30 Heures'}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Panier:">
                  <Form.Item noStyle name={['hour_record', 'basket']}>
                    <Switch defaultChecked />
                  </Form.Item>
                </Descriptions.Item>
                <Descriptions.Item label="Panier de nuit:">
                  <Form.Item noStyle name={['hour_record', 'basket_night']}>
                    <Switch defaultChecked={false} />
                  </Form.Item>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
          <Col span={24}>
            <Row justify="center" style={{ width: '100%' }}>
              {taskList.map((e) => (
                <Col
                  style={{ justifyContent: 'space-between', maxWidth: '20%' }}
                  key={e}
                  span={5}
                >
                  <Card
                    style={{ padding: 10 }}
                    title={
                      <Tooltip title={t(`cstb.form.hour_record.${e}`)}>
                        {t(`cstb.form.hour_record.${e}`)}
                      </Tooltip>
                    }
                  >
                    <Col span={24}>
                      <Form.Item
                        name={['hour_record', e]}
                        rules={[
                          {
                            required: true,
                            message: 'Veuillez saisir une heure'
                          }
                        ]}
                        className="truck-hour-form-item"
                      >
                        <TimePicker
                          bordered={false}
                          suffixIcon={
                            <ClockCircleTwoTone style={{ fontSize: 30 }} />
                          }
                          defaultValue={
                            e === 'start_truck'
                              ? moment('06:30', 'HH:mm')
                              : null
                          }
                          autoFocus={e === 'start_truck' || false}
                          placeholder={null}
                          size="large"
                          format="HH:mm"
                        />
                      </Form.Item>
                    </Col>
                  </Card>
                </Col>
              ))}
              <Col span={5} style={{ maxWidth: '20%' }}>
                <Card style={{ padding: 10 }} title="Pause">
                  <Form.Item
                    style={{ marginRight: 40, justifyContent: 'center' }}
                    name={['hour_record', 'break']}
                    className="truck-hour-form-item"
                  >
                    <TimePicker
                      bordered={false}
                      popupStyle={{ left: 20 }}
                      defaultValue={moment(breakTime, 'HH:mm')}
                      format="HH:mm"
                      suffixIcon={
                        <ClockCircleTwoTone style={{ fontSize: 30 }} />
                      }
                      placeholder={null}
                      size="large"
                    />
                  </Form.Item>
                </Card>
              </Col>
            </Row>
          </Col>
          <Row style={{ marginTop: 50 }} justify="center">
            <Button type="primary" htmlType="submit">
              Enregistrer
            </Button>
          </Row>
        </Form>
      </Row>
    </Modal>
  );
};

ModalEditHoursControl.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

ModalEditHoursControl.defaultProps = {
  data: null
};

export default ModalEditHoursControl;
