/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Col, Row, Select, Skeleton, Typography, Card, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../utils/ErrorMessage';
import RemainingToPay from './Components/RemainingToPay';
import Organization from './Components/Organization';
import Customer from './Components/Customer';
import Contact from './Components/Contact';
import ExtraShowActions from './Components/ExtraShowActions';
import DetailsTotalInvoiceFooter from './Components/DetailsTotalInvoiceFooter';
import TimeLineInvoice from './Components/TimeLineInvoice';
import DetailsPrestationsInvoice from './Components/DetailsPrestationsInvoice';
import TimeLineForCheckIfDataExist from './Components/TimeLineForCheckIfDataExist';

const { Option } = Select;

const ShowInvoice = ({ resource }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message: errorMessage } = useErrorMessage();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [displayedResource, setResource] = useState({});
  const [specificsMentions, setSpecificsMentions] = useState([]);
  const [selectedMentions, setSelectedMentions] = useState([]);

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}/${id}?populate=customer,contact,quotation.invoices,site,organization,vat,deposit_invoice`
      });
      setResource(data);
      if (resource === 'quotations') {
        setSelectedMentions(data.mentions);
      } else {
        setSelectedMentions(data.mentions);
      }
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getSpecificsMentions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotations/enums`
      });
      setSpecificsMentions(data.legal_mentions);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const fetchResource = useCallback(async () => {
    setLoading(true);
    await getResource();
    await getSpecificsMentions();
    setLoading(false);
  }, [id]);

  const downloadInvoice = async () => {
    setLoading(true);
    try {
      const response = await dispatchAPI('GET', {
        url: `/generate/invoices/${id}`,
        responseType: 'arraybuffer'
      });
      const bufferArray = new Uint8Array(response.data);
      const blob = new Blob([bufferArray], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = response.headers['content-disposition']
        .split('filename=')[1]
        .split(';')[0];
      a.click();
      setLoading(false);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    fetchResource();
  }, [fetchResource, id]);

  const saveMentions = async (mentions) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/invoices/${id}`,
        body: { mentions }
      });
      setSelectedMentions(mentions);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  const CheckIsExistAndReturnColor = (value) => {
    if (value) return 'green';
    return 'red';
  };
  return (
    <>
      <PageHeaderCustom
        title={t(`${resource}.show.number`, {
          number: `${!displayedResource.email_sent ? 'P-' : ''}${
            displayedResource.number
          }`
        })}
        extra={
          <>
            <ExtraShowActions
              downloadFile={downloadInvoice}
              invoice={displayedResource}
              getResource={getResource}
            />
          </>
        }
      />
      <Card style={{ margin: 20 }}>
        <ContentCustom>
          <Row justify="space-between" style={{ marginBottom: 20 }}>
            <Col span={6}>
              <Organization
                display={displayedResource.organization}
                isLoading={isLoading}
                resource="invoices"
              />
            </Col>
            <Col span={6}>
              <Customer
                customer={displayedResource.customer}
                isLoading={isLoading}
                resource="invoices"
              />
            </Col>
            <Col span={6}>
              <Contact
                isLoading={isLoading}
                contact={displayedResource.contact}
                resource="invoices"
              />
            </Col>
          </Row>
          <Row
            style={{ marginTop: 20, marginBottom: 20 }}
            justify="space-between"
          >
            {resource === 'invoices' && (
              <Col span={6}>
                <RemainingToPay
                  isLoading={isLoading}
                  invoice={displayedResource}
                />
              </Col>
            )}
            <Col span={6}>
              <TimeLineForCheckIfDataExist
                t={t}
                displayedResource={displayedResource}
                CheckIsExistAndReturnColor={CheckIsExistAndReturnColor}
              />
            </Col>
          </Row>
          <TimeLineInvoice
            t={t}
            displayedResource={displayedResource}
            resource={resource}
          />
          {displayedResource.type_invoice !== 'UPPER' ? (
            <Row justify="space-between">
              <Col span={24}>
                <Card bordered={false}>
                  {displayedResource?.quotation?.invoices.map((e) => (
                    <Row justify="space-between" key={e._id}>
                      <Col span={10}>
                        <Row justify="space-between">
                          <span>{`- Facture n° ${e.number}`}</span>
                          <Tag
                            color={
                              e.type_invoice === 'GLOBAL'
                                ? 'var(--addColor)'
                                : null
                            }
                          >
                            {t(`invoices.tags.${e.type_invoice}`)}
                          </Tag>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <span>
                          {e?.emission_date &&
                            `Émis le ${moment(e?.emission_date).format(
                              'DD-MM-YYYY'
                            )}`}
                        </span>
                      </Col>
                      <Col span={4}>
                        <span>{`${e?.total} €`}</span>
                      </Col>
                      <Col span={6}>
                        {e?.pay_off ? <span>{e?.pay_off}</span> : null}
                      </Col>
                    </Row>
                  ))}
                </Card>
              </Col>
            </Row>
          ) : null}

          <Skeleton
            active
            title={false}
            loading={isLoading}
            paragraph={{
              rows: 4,
              width: ['100%', '100%', '100%', '100%']
            }}
          >
            <DetailsPrestationsInvoice
              t={t}
              prestations={displayedResource.prestations}
            />
          </Skeleton>
          <Row style={{ marginTop: 50 }}>
            <Col span={24}>
              <p>INCLUS :</p>
              <Select
                mode="tags"
                style={{ width: '45%' }}
                value={selectedMentions}
                optionLabelProp="label"
                onChange={saveMentions}
              >
                {specificsMentions?.map((el) => (
                  <Option key={el} value={el}>
                    {el}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>
          <DetailsTotalInvoiceFooter
            t={t}
            isLoading={isLoading}
            displayedResource={displayedResource}
          />
          <Row style={{ margin: '30px 0' }}>
            <Col xs={{ offset: 8, span: 16 }} md={{ offset: 14, span: 10 }}>
              <Skeleton active loading={isLoading} paragraph={0}>
                <Row type="flex" style={{ height: '100%' }}>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Typography.Text strong style={{ fontSize: '18px' }}>
                      {t('invoices.showInvoice.total_remaining', {
                        date: displayedResource.payment_date
                          ? `${moment(displayedResource.payment_date).format(
                              'L'
                            )}`
                          : ''
                      })}
                    </Typography.Text>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      textAlign: 'right',
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Typography.Text strong style={{ fontSize: '18px' }}>
                      {`${numeral(
                        displayedResource.total ||
                          0 + displayedResource.VAT ||
                          0
                      ).format('0,0.00')}€`}
                    </Typography.Text>
                  </Col>
                </Row>
              </Skeleton>
            </Col>
          </Row>
        </ContentCustom>
      </Card>
    </>
  );
};

ShowInvoice.propTypes = {
  resource: PropTypes.string.isRequired
};

export default ShowInvoice;
