import React, { useState, useEffect } from 'react';
import { Switch, Divider, Select } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;
const useFields = () => {
  const [enums, setEnums] = useState([]);
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const fields = [
    {
      name: ['code_number'],
      rules: [{ required: true }]
    },
    {
      name: ['description']
    },
    {
      name: ['rate'],
      rules: [{ required: true }],
      input: (
        <Select showSearch>
          {enums &&
            enums.map((rate) => (
              <Option value={rate} key={rate}>
                {rate}
              </Option>
            ))}
        </Select>
      )
    },
    {
      name: ['lettrable'],
      input: <Switch checkedChildren="Oui" unCheckedChildren="Non" />
    },
    {
      colon: false,
      name: ['separate_1'],
      input: <Divider orientation="left">Compte de produit de vente</Divider>
    },
    {
      name: ['code_init_customer'],
      rules: [{ required: true }]
    },
    {
      name: ['sales_product_account']
    },
    {
      colon: false,

      name: ['separate_2'],
      input: (
        <Divider orientation="left">
          Compte de produit de vente sous traitance
        </Divider>
      )
    },
    {
      name: ['sales_product_account_subcontracting']
    },
    {
      colon: false,
      name: ['separate_3'],
      input: (
        <Divider orientation="left">
          Compte de produit de TVA - Facture d&apos;acompte
        </Divider>
      )
    },
    {
      name: ['vat_account_deposit_invoice']
    }
  ];
  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/quotations/enums' });
      setEnums(data.tva);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  return { fields };
};

export default useFields;
