import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import '../../../assets/styles/SCSS/cardResizable.scss';
import { useDrag } from 'react-dnd';
import { Row, Col, Popconfirm, DatePicker } from 'antd';
import {
  ColumnWidthOutlined,
  ExpandOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useInterventionContext from './InterventionContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const CardList = ({ el, color, isLoading, setIsLoading }) => {
  const history = useHistory();
  const [dateValue, setDateValue] = useState();
  const { refresh, setRefresh } = useInterventionContext();
  const { dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  // eslint-disable-next-line no-unused-vars
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'card',
      id: el._id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging() && setIsLoading({ [el._id]: true })
    })
  });

  const style = {
    height: '80px',
    margin: '5px',
    padding: '10px',
    backgroundColor: 'transparent',
    border: `1px solid ${el.type === 'QUALITY' ? 'blue' : color}`,
    cursor: 'grab',
    color,
    textAlign: 'center',
    borderRadius: 5,
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  };

  const totalArea =
    typeof el !== 'string'
      ? el?.prestas?.reduce((total, cv) => {
          return total + parseFloat(cv.area);
        }, 0)
      : 0;

  const reduceThickness =
    typeof el !== 'string'
      ? el?.prestas?.reduce((unique, cv) => {
          return unique.includes(cv.thickness)
            ? unique
            : [...unique, cv.thickness];
        }, [])
      : 0;

  const updateDateMovement = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/quotations/${el.quotation._id}`,
        body: { intervention_date_programmed: dateValue }
      });
      setDateValue();
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  return (
    <>
      {!isLoading[el._id] && (
        <div
          style={style}
          ref={drag}
          onDoubleClick={() =>
            history.push({
              pathname: `/intervention/manage_interventions/show/${el._id}`
            })
          }
        >
          <div
            style={{
              marginTop: 57,
              left: 165,
              position: 'absolute',
              color: el.type === 'TECHNICIANS' ? 'green' : 'blue'
            }}
          />
          <div>{`${el.site?.reference}`}</div>
          <div>{el.description}</div>
          <div>{`${el.quotation?.number}`}</div>
          <Row justify="center">
            <Col span={12}>
              <ExpandOutlined />
              {` ${totalArea} m² `}
            </Col>
            <Col span={12}>
              <ColumnWidthOutlined />
              {` ${reduceThickness} cm`}
            </Col>
            <Col style={{ color: 'var(--addColor)' }} span={12}>
              {`S ${moment(
                el?.quotation?.intervention_date_programmed ||
                  el?.quotation?.intervention_date_expected
              ).week()}`}
            </Col>
            <Col style={{ color: 'var(--addColor)' }} span={12}>
              <Popconfirm
                placement="right"
                title={
                  <DatePicker
                    defaultValue={moment(
                      el?.quotation?.intervention_date_programmed ||
                        el?.quotation?.intervention_date_expected
                    )}
                    picker="week"
                    placeholder="..."
                    onChange={(date) => setDateValue(date)}
                    getPopupContainer={(triggerNode) => {
                      return triggerNode.parentNode;
                    }}
                  />
                }
                onConfirm={() => updateDateMovement()}
                okButtonProps={{ disabled: !dateValue }}
                okText="Valider"
                cancelText="Annuler"
              >
                <CalendarOutlined />
              </Popconfirm>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

CardList.propTypes = {
  el: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array
  ]),
  color: PropTypes.string.isRequired,
  isLoading: PropTypes.shape({}).isRequired,
  setIsLoading: PropTypes.func.isRequired
};
CardList.defaultProps = {
  el: null
};

export default CardList;
