import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Button, Form, Input, message } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import useAuthContext from '../../contexts/AuthContext';

const ForgotPwdForm = ({ switchForm }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setLoading] = useState(false);

  const askForPwdReset = async email => {
    setLoading(true);
    try {
      await dispatchAPI('GET', { url: `reset-pwd/${email}` });
      message.success(t('login.resetPwdEmailSent', { email }));
    } catch (e) {
      message.error(t('login.resetPwdEmailError'));
    }
    setLoading(false);
  };

  const handleSubmit = async values => {
    await askForPwdReset(values.username.toLowerCase());
  };

  return (
    <Form onFinish={handleSubmit}>
      <Form.Item
        name="username"
        rules={[{ required: true, message: t('login.usernameMissing') }]}
      >
        <Input
          prefix={
            <UserOutlined style={{ color: 'var(--textColorSecondary)' }} />
          }
          placeholder={t('login.username')}
        />
      </Form.Item>
      <Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          style={{ width: '100%' }}
          loading={isLoading}
        >
          {t('login.resetPwd')}
        </Button>
      </Form.Item>
      <Form.Item>
        <Button type="link" block style={{ width: '100%'}} onClick={() => switchForm('login')}>
          {t('buttons.back')}
        </Button>
      </Form.Item>
    </Form>
  );
};

ForgotPwdForm.propTypes = {
  switchForm: PropTypes.func.isRequired
};

export default ForgotPwdForm;
