import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ColumnHeightOutlined, ExpandOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import Checkbox from 'antd/lib/checkbox/Checkbox';

const RecapModal = ({ el, showField, setShowField }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center'
      }}
    >
      <div>
        <Checkbox
          onChange={() => setShowField(!showField)}
          checked={!showField}
        >
          {t(`movements.modal.keeptruck`)}
        </Checkbox>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          border: '1px dashed var(--primaryColor)',
          margin: '0 0 0 auto',
          width: '40%',
          padding: '10px'
        }}
      >
        <strong style={{ color: 'var(--textColor)', marginBottom: '5px' }}>
          Récapitulatif Chantier :
        </strong>
        {el?.quotationPrestations?.map(({ area, prestation, _id }) =>
          area !== undefined && prestation.thickness !== undefined ? (
            <Row justify="start" key={_id}>
              <Col span={12}>
                <ExpandOutlined />
                {` ${area} m² `}
              </Col>
              <Col span={12}>
                <ColumnHeightOutlined />
                {` ${prestation.thickness} cm `}
              </Col>
            </Row>
          ) : null
        )}
      </div>
    </div>
  );
};

RecapModal.propTypes = {
  el: PropTypes.shape({
    prestations: PropTypes.shape({
      map: PropTypes.func.isRequired
    }).isRequired,
    quotationPrestations: PropTypes.arrayOf(PropTypes.func)
  }).isRequired,
  showField: PropTypes.bool.isRequired,
  setShowField: PropTypes.func.isRequired
};

export default RecapModal;
