import { Col, Row } from 'antd';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Contact from '../../../Accounting/Invoices/Components/Contact';
import Customer from '../../../Accounting/Invoices/Components/Customer';
import Site from '../../../Accounting/Invoices/Components/Site';

const Subheader = ({ data }) => {
  const [isLoading, setLoading] = useState(false);

  return (
    <Row style={{ margin: '40px 0' }} justify="space-between" gutter={[16, 16]}>
      <Col xs={24} lg={12} xl={10} style={{ paddingBottom: '10px' }}>
        <Site isLoading={isLoading} movements={data} resource="cstb" />
      </Col>
      <Col xs={12} lg={6} xl={7} style={{ paddingBottom: '10px' }}>
        <Customer
          isLoading={isLoading}
          customer={data?.customer}
          resource="cstb"
        />
      </Col>

      <Col xs={12} lg={6} xl={7}>
        <Contact
          isLoading={isLoading}
          contact={data?.works_supervisor}
          resource="cstb"
        />
      </Col>
    </Row>
  );
};
Subheader.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Subheader.defaultProps = {
  data: null
};

export default Subheader;
