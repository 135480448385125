import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, Row, Select, Spin, Typography } from 'antd';
import useScanContext from './ScanContext';
import { useTranslation } from 'react-i18next';
import {
  AimOutlined,
  CheckOutlined,
  LoadingOutlined,
  PlusOutlined
} from '@ant-design/icons';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../../utils/constants/routes';

const { Title } = Typography;

const spin = (
  <Spin
    indicator={
      <LoadingOutlined spin style={{ fontSize: 16, color: 'grey' }} />
    }
  />
);

const creationRoutes = {
  Company: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CUSTOMERS}/create`,
  Contact: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CONTACTS}/create`,
};

const ScanOutput = ({ onSubmit }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [form] = Form.useForm();
  const {
    output,
    fields,
    zoningField,
    setZoningField,
    loadingFields,
    setNextField
  } = useScanContext();
  const [inputs, setInputs] = useState([]);
  const [options, setOptions] = useState({});

  const handleZoneRecord = key => {
    if (zoningField === key) return setZoningField(null);
    if (
      !form.getFieldValue([zoningField]) &&
      !loadingFields.includes(zoningField)
    ) {
      setNextField(zoningField);
    }
    setZoningField(key);
  };

  const getOptions = async collection => {
    try {
      const { data } = await dispatchAPI('GET', { url: `/${collection}` });
      return data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getInput = (type, element, aim, spin, collection) => {
    const formItem = input => (
      <Row key={`row_${element[0]}`}>
        <Col span={6} style={{ textAlign: 'right' }}>
          <div style={{ marginTop: '4px' }}>
            {element[1].required && <span style={{ color: 'var(--secondaryColor)' }}>*</span>}
            &nbsp;{t(`${type.toLowerCase()}.form.${element[0]}`)}&nbsp;:&nbsp;
          </div>
        </Col>
        <Col span={12}>
          <Form.Item
            name={[element[0]]}
            key={element[0]}
            rules={[{ required: element[1].required }]}
          >
            {input}
          </Form.Item>
        </Col>
        <Col span={6}>
          {element[1].ref && (element[1].type === 'ObjectID' || element[1].type === 'ObjectId') && (
            <Link to={creationRoutes[element[1].ref]}>
              <Button type="add">
                {`${t('buttons.create')} `}
                <PlusOutlined />
              </Button>
            </Link>
          )}
        </Col>
      </Row>
    );

    if (!element[1]) return;

    if (typeof element[1].type === 'object') {
      const aim = (k) => (
        <AimOutlined
          style={{
            cursor: 'pointer',
            color: zoningField === k ? 'red' : 'inherit'
          }}
          onClick={() => handleZoneRecord(k)}
        />
      );

      switch (element[0]) {
        case 'phone_number':
        case 'address':
          return Object.keys(element[1].type).map(k => {
            return getInput(type, [`${element[0]}.${k}`, element[1].type[k]], aim(k), spin);
          });
      }
    }

    switch (element[1].type) {
      // default:
      case 'Number':
        return formItem(
          <Input
            type="number"
            suffix={aim}
            addonAfter={
              loadingFields && loadingFields.includes(element[0]) && spin
            }
          />
        );
      case 'String':
        if (element[1].enum) {
          return formItem(
            <Select
              options={element[1].enum.map(enumType => {
                return {
                  label: t(`${type.toLowerCase()}.tags.${enumType}`),
                  value: enumType
                };
              })}
            />
          );
        }
        return formItem(
          <Input
            suffix={aim}
            addonAfter={
              loadingFields && loadingFields.includes(element[0]) && spin
            }
          />
        );
      case 'Date':
        return formItem(
          <Input
            suffix={aim}
            addonAfter={
              loadingFields && loadingFields.includes(element[0]) && spin
            }
          />
        );
      case 'ObjectID':
      case 'ObjectId':
        return element[1].required && (
          formItem(
            <Select
              options={
                options[collection] &&
                options[collection].map(o => {
                  if (!o.name && o.first_name) {
                    return {
                      label: `${o.first_name} ${o.last_name}`,
                      value: o._id
                    };
                  }
                  return {
                    label: o.name,
                    value: o._id
                  };
                })
              }
            />
          )
        );
    }
  };

  useEffect(() => {
    if (fields && !options.fakeField) {
      const type = Object.keys(fields)[0];
      let tmpOptions = {};

      const mapOnFields = async () => {
        const promises = Object.entries(fields[type]).map(async element => {
          if ((element[1].type === 'ObjectId' || element[1].type === 'ObjectID') && element[1].required) {
            let collection = element[1].ref.endsWith('y') ? `${element[1].ref.slice(0, -1).toLowerCase()}ies` : `${element[1].ref.toLowerCase()}s`;
            if (element[1].ref === 'TicketType') {
              collection = 'ticketType';
            }
            if (element[1].ref === 'TicketStatus') {
              collection = 'ticketStatus'
            }

            const options = await getOptions(collection);
            tmpOptions = { ...tmpOptions, [collection]: options };
          }
        });
        await Promise.all(promises);
        setOptions(tmpOptions);
      };

      mapOnFields();
    }
  }, [fields, zoningField, loadingFields.length]);

  useEffect(() => {
    if (output) {
      form.setFieldsValue(output.values);
    }
  }, [output]);

  useEffect(() => {
    if (fields) {
      const tmpInputs = [];
      const type = Object.keys(fields)[0];

      Object.entries(fields[type]).map(element => {
        if (element[1].scan !== false) {
          let collection = null;
          if (element[1].ref) {
            collection = element[1].ref.endsWith('y') ? `${element[1].ref.slice(0, -1).toLowerCase()}ies` : `${element[1].ref.toLowerCase()}s`;
          }
          const aim = (
            <AimOutlined
              style={{
                cursor: 'pointer',
                color: zoningField === element[0] ? 'red' : 'inherit'
              }}
              onClick={() => handleZoneRecord(element[0])}
            />
          );
          tmpInputs.push(getInput(`${type.endsWith('y') ? `${type.slice(0, -1).toLowerCase()}ie` : type}s`, element, aim, spin, collection));
        }
      });
      setInputs(tmpInputs);
    }
  }, [options, fields, zoningField, loadingFields.length]);

  return (
    <Form
      form={form}
      name="output"
      onFinish={onSubmit}
      onFinishFailed={onSubmit}
    >
      {inputs.length > 0 && (
        <>
          <Col span={12} offset={6}>
            <Title level={4}>{t('scan.document.output')}</Title>
          </Col>
          {inputs}
          <Col span={12} offset={6}>
            <Form.Item key="save">
              <Button type="add" htmlType="submit">
                {`${t('buttons.save')} `}
                <CheckOutlined />
              </Button>
            </Form.Item>
          </Col>
        </>
      )}
    </Form>
  );
};

export default ScanOutput;
