import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';

const useColumns = () => {
  const { t } = useTranslation();
  const [trucksFilter, setTrucksFilter] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const fetchTrucks = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'trucks' });
      setTrucksFilter(
        data.map(({ label, _id }) => ({ text: label, value: _id }))
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchTrucks();
    })();
  }, []);

  return [
    {
      title: t('users.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name'
    },
    {
      title: t('users.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name'
    },
    {
      title: <span style={{ marginRight: 30 }}>{t('users.form.truck')}</span>,
      render: (text) => text && text.label,
      dataIndex: 'truck',
      onFilter: (value, record) =>
        record.truck &&
        record.truck !== null &&
        record.truck._id.indexOf(value) === 0,
      filters: trucksFilter,
      width: '20%',
      fixed: 'right'
    },
    {
      title: t('users.form.email'),
      key: 'email',
      dataIndex: 'email',
      width: '20%'
    }
  ];
};

export default useColumns;
