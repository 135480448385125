import { useTranslation } from 'react-i18next';
import React from 'react';
import { Link } from 'react-router-dom';
import { Tag } from 'antd';
import moment from 'moment';
import { EyeOutlined } from '@ant-design/icons';

import {
  movementsStatus,
  movementsTypes
} from '../../../utils/constants/tagColors';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('movements.columns.quotation'),
      dataIndex: 'quotation',
      render: (e) => e?.number,
      //sorter: (a, b) => (a.quotation.number < b.quotation.number)
    },
    {
      title: t('movements.columns.type'),
      dataIndex: 'type',
      render: (type) => (
        <Tag color={movementsTypes[type]}>
          {t(`movements.columns.types.${type}`)}
        </Tag>
      ),
    },
    {
      title: t('movements.columns.reference'),
      dataIndex: 'site',
      render: (site) => site && site.reference,
    },
    {
      title: t('movements.columns.intervention_date_expected'),
      dataIndex: 'quotation',
      render: (quotation) =>
        `Semaine ${moment(
          quotation?.intervention_date_expected
        ).week()}`,
    },
    {
      title: t('movements.columns.status'),
      dataIndex: 'status',
      render: (enums) => (
        <Tag color={movementsStatus[enums]}>{t(`enums.${enums}`)}</Tag>
      ),
      sorter: true,
      filters: Object.keys(movementsStatus).map((r) => ({
        text: t(`enums.${r}`),
        value: r
      }))
    },
    {
      title: t('movements.columns.date'),
      dataIndex: 'date',
      render: (date) =>
        date ? moment(date).format('DD/MM/YYYY') : 'Non défini',
    },
    {
      title: t('movements.columns.truck'),
      dataIndex: 'truck',
      render: (truck) => (truck?.label ? truck.label : 'Non défini'),
    },
    {
      key: 'action',
      render: ({ key }) => (
        <span>
          <Link
            to={{
              pathname: `manage_interventions/show/${key}`
            }}
          >
            <EyeOutlined style={{ fontSize: 18 }} />
          </Link>
        </span>
      )
    }
  ];
};

export default useColumns;
