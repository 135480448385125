import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import VatAccounts from './VatAccounts';
import CreateUpdateVatAccount from './CreateUpdateVatAccount';
import ShowVatAccount from './ShowVatAccount ';

const VatAccountRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateVatAccount purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdateVatAccount purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowVatAccount} />
      <Route path={path} component={VatAccounts} />
    </Switch>
  );
};

export default VatAccountRouter;
