import React from 'react';
import { Layout, PageHeader, Tabs } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useHandleResize from '../../../utils/HandleResize';
import FamilySettings from './family/familySettings';
import UnitSettings from './unit/unitSettings';
import DepositSettings from './deposit/depositSettings';
import TankSettings from './tanks/tankSettings';
import DealOriginSettings from './deal-origin/dealOriginSettings';
import FoamSettings from './foam/foamSettings';
import DepartmentSettings from './departments/departementSettings';
import ManagerSettings from './manager/ManagerSettings';

const { TabPane } = Tabs;
const { Content } = Layout;
const LineSetting = styled.div`
  background-color: #f0f2f5;
  padding: 10px;
  width: 100%;
  margin-bottom: 30px;
  p {
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
  }
`;

const Config = () => {
  const { t } = useTranslation();
  const { width } = useHandleResize();
  const routes = [
    {
      path: '/',
      breadcrumbName: t('home.title')
    },
    {
      path: '/configuration',
      breadcrumbName: t('configuration.title')
    }
  ];
  return (
    <>
      <PageHeader
        breadcrumb={{
          routes,
          itemRender: ({ path, breadcrumbName }) => (
            <Link to={path}>{breadcrumbName}</Link>
          )
        }}
        title={t('configuration.title')}
        onBack={() => window.history.back()}
        ghost={false}
      />
      <Content
        className="site-layout-background"
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Tabs
          tabPosition={width > 992 ? 'left' : 'top'}
          activeTabBarStyle={{ color: 'var(--primaryColor)' }}
        >
          <TabPane tab={t('configuration.tabs.department')} key="6">
            <LineSetting>
              <DepartmentSettings />
            </LineSetting>
          </TabPane>
          {/* <TabPane tab={t('configuration.tabs.family')} key="1">
            <LineSetting>
              <FamilySettings />
            </LineSetting>
          </TabPane> */}

          <TabPane tab={t('configuration.tabs.unit')} key="2">
            <LineSetting>
              <UnitSettings />
            </LineSetting>
          </TabPane>
          <TabPane tab={t('configuration.tabs.deposit')} key="3">
            <LineSetting>
              <DepositSettings />
            </LineSetting>
          </TabPane>
          <TabPane tab={t('configuration.tabs.deal-origin')} key="4">
            <LineSetting>
              <DealOriginSettings />
            </LineSetting>
          </TabPane>
          <TabPane tab={t('configuration.tabs.foam')} key="5">
            <LineSetting>
              <FoamSettings />
            </LineSetting>
          </TabPane>

          <TabPane tab="Cuves" key="8">
            <LineSetting>
              <TankSettings />
            </LineSetting>
          </TabPane>
          <TabPane tab="Chefs d'équipe" key="9">
            <LineSetting>
              <ManagerSettings />
            </LineSetting>
          </TabPane>
        </Tabs>
      </Content>
    </>
  );
};

export default Config;
