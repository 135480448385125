import React from 'react';
import { Button, Col, Drawer, Form, message, Row, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { drawerFormLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';

const { Title } = Typography;

const NewContactDrawer = ({
  isVisible,
  setVisibilityContactDrawer,
  selectedCompany,
  contacts,
  getContact
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const generateFields = useGenerateFormItem();
  const { fields, config } = useFields(selectedCompany);
  const [form] = Form.useForm();
  const { onCreateResource } = config;
  const onFinish = async (values) => {
    const body = { ...values, my_company: selectedCompany?._id };
    try {
      const isInBDD = contacts.find(
        (c) =>
          c.phone_number_mobile === body.phone_number_mobile &&
          c.email === body.email
      );
      if (!isInBDD) {
        const { data } = await dispatchAPI('POST', {
          url: '/contacts',
          body:
            onCreateResource && onCreateResource.setBody
              ? onCreateResource.setBody(body)
              : body
        });
        message.success(t('contacts.message.success.new'));
        getContact();
        setVisibilityContactDrawer(false);
        form.setFieldsValue({
          contact: data._id
        });
      } else {
        message.error(t('companies.message.error.already_exist'));
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onFinishFailed = (error) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', error);
    message.error(t('contacts.message.error'));
  };

  return (
    <Drawer
      visible={isVisible}
      onClose={() => {
        setVisibilityContactDrawer();
      }}
      width="40%"
    >
      <Col style={{ width: '100%', marginBottom: '5%' }}>
        <Title level={3}>{t(`contacts.form.title.create`)}</Title>
      </Col>

      <Form
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...drawerFormLayout}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        {fields.map((field) => generateFields('contacts', field))}
        <Form.Item>
          <Row justify="end">
            <Button style={{ margin: '0 10px' }} type="add" htmlType="submit">
              {t('buttons.save')}
              <CheckOutlined />
            </Button>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={() => {
                setVisibilityContactDrawer();
              }}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

NewContactDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisibilityContactDrawer: PropTypes.func.isRequired,
  selectedCompany: PropTypes.shape([PropTypes.object]),
  contacts: PropTypes.arrayOf(PropTypes.shape({})),
  getContact: PropTypes.func.isRequired
};

NewContactDrawer.defaultProps = {
  selectedCompany: {},
  contacts: []
};

export default NewContactDrawer;
