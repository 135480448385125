import {
  Button,
  Col,
  Divider,
  Modal,
  Descriptions,
  Row,
  Tag,
  Tooltip,
  Badge
} from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ProfileOutlined } from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useMovementContext from '../PostIntervention/MovementsContext';

const ShowGlobalInvoice = ({
  setShowModal,
  showModal,
  getInvoices,
  listInvoices
}) => {
  const { t } = useTranslation();
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { CSTB, cstblist } = useMovementContext();
  const [cstbs, setCstbs] = useState();
  const createInvoice = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/invoices/`,
        body: {
          global: {
            quotation_id: CSTB?.quotation?._id,
            type: 'PAY'
          }
        }
      });
      getInvoices();
      setShowModal(false);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    if ((cstblist, CSTB)) {
      setCstbs(cstblist);
    }
  }, [cstblist]);

  useEffect(() => {
    (async () => {
      await getInvoices();
    })();
  }, []);

  return (
    <Modal
      title={` Facturation globale devis n° ${CSTB?.quotation?.number}`}
      visible={showModal}
      width={800}
      onCancel={() => setShowModal(false)}
      footer={false}
    >
      <Row>
        <Col span={24}>
          <Descriptions column={2} title="Récapitulatif du devis">
            <Descriptions.Item label="Client">
              <span style={{ fontWeight: 'bold' }}>{CSTB?.customer?.name}</span>
            </Descriptions.Item>
            <Descriptions.Item label="Chantier">
              <span style={{ fontWeight: 'bold' }}>
                {`${CSTB?.site?.reference}`}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Surface totale du devis">
              <span style={{ fontWeight: 'bold' }}>
                {`${CSTB?.quotation?.total_area} m²`}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Surface totale fiches CSTB">
              <span style={{ fontWeight: 'bold' }}>
                {`${cstbs
                  ?.map((e) =>
                    e?.details_after
                      .map((f) => f?.area)
                      .reduce((a, b) => a + b, 0)
                  )
                  .reduce((c, d) => c + d, 0)} m²`}
              </span>
            </Descriptions.Item>
            <Descriptions.Item label="Total HT du devis">
              <span style={{ fontWeight: 'bold' }}>
                {`${CSTB?.quotation?.subTotal} €`}
              </span>
            </Descriptions.Item>
            {cstblist
              ?.map((e) =>
                e?.details_after.map((f) => f?.area).reduce((a, b) => a + b, 0)
              )
              .reduce((a, b) => a + b, 0) > CSTB?.quotation?.total_area ? (
              <Descriptions.Item label="Dépassement de surface">
                <Tag style={{ color: 'var(--secondaryColor)' }}>
                  {`${cstblist
                    ?.map((e) =>
                      e?.details_after
                        .map((f) => f?.area)
                        .reduce((a, b) => a + b, 0)
                    )
                    .reduce((a, b) => a + b, 0) -
                    CSTB?.quotation?.total_area} m²`}
                </Tag>
              </Descriptions.Item>
            ) : null}
          </Descriptions>
        </Col>
        <Divider />
        <Row justify="space-around">
          <Col span={6}>
            <span style={{ marginRight: 10 }}>Factures créées</span>
            <Tag>{CSTB?.quotation?.invoices.length}</Tag>
          </Col>

          <Col span={18}>
            <Row justify="space-between">
              {listInvoices.length > 0 ? (
                listInvoices
                  ?.filter(
                    (invoiceQuote) =>
                      invoiceQuote.quotation === CSTB?.quotation._id
                  )
                  ?.map((e) => (
                    <Descriptions key={e._id} column={4}>
                      <Descriptions.Item style={{ paddingRight: 20 }}>
                        <Badge
                          style={{
                            marginTop: 5,
                            right: -5
                          }}
                          dot={!e.email_sent}
                        >
                          {e?.number}
                        </Badge>
                      </Descriptions.Item>
                      <Descriptions.Item>
                        <Tag
                          style={
                            e?.type === 'DEPOSIT' &&
                            e?.total_paid !== e?.subTotal
                              ? { color: '#1890ff' }
                              : null
                          }
                        >
                          {e?.type}
                        </Tag>
                      </Descriptions.Item>
                      <Descriptions.Item>
                        <Tag
                          style={
                            e?.type === 'DEPOSIT' &&
                            e?.total_paid !== e?.subTotal
                              ? { color: '#1890ff' }
                              : null
                          }
                        >
                          {e?.payment_date
                            ? t(`invoices.show.payment`, {
                                date: moment(e?.payment_date).format(
                                  'DD-MM-YYYY'
                                )
                              })
                            : `${t(`invoices.show.sum_paid`, {
                                value: e?.total_paid
                              })} €`}
                        </Tag>
                      </Descriptions.Item>
                      <Descriptions.Item label="Montant">
                        {`${e?.subTotal} € (HT)`}
                      </Descriptions.Item>
                    </Descriptions>
                  ))
              ) : (
                <Descriptions column={4}>
                  <Descriptions.Item>-</Descriptions.Item>
                  <Descriptions.Item>-</Descriptions.Item>
                  <Descriptions.Item>-</Descriptions.Item>
                  <Descriptions.Item label="Montant">-</Descriptions.Item>
                </Descriptions>
              )}
            </Row>
          </Col>
        </Row>

        {listInvoices.length > 0 &&
        CSTB?.quotation?.subTotal -
          listInvoices
            .filter(
              (invoiceQuote) => invoiceQuote.quotation === CSTB?.quotation._id
            )
            ?.map((e) => e.total_paid || 0)
            .reduce((a, b) => a + b, 0) >=
          0 ? (
          <Row justify="space-around">
            <Col span={24}>
              <span style={{ textAlign: 'center' }}>
                <Badge style={{ margin: '0px 10px 0px 0px' }} dot />
                <span>
                  Facture non émise (ne sera prise en compte sur la facture
                  finale)
                </span>
              </span>
            </Col>
            <Col span={24}>
              <span
                style={{ textAlign: 'center', color: 'var(--secondaryColor)' }}
              >
                (Les factures non payées seront soldées à la facturation)
              </span>
            </Col>
          </Row>
        ) : null}
      </Row>
      <Divider />
      <Row justify="space-between">
        <Col span={10}>
          <Col span={24}>
            <span>Montant restant à facturer :</span>
            <Tag color="var(--addColor)">
              {`${
                listInvoices.filter((e) => e?.type_invoice === 'GLOBAL')
                  .length > 0
                  ? 0
                  : CSTB?.quotation?.subTotal -
                    listInvoices
                      ?.map((e) =>
                        e.total_paid > e.subTotal ? e.subTotal : e.total_paid
                      )
                      .reduce((a, b) => a + b, 0)
              } €`}
            </Tag>
          </Col>
        </Col>
        <Col span={14}>
          <Col span={24}>
            <Tooltip placement="top">
              {listInvoices.filter(
                (e) =>
                  e?.type_invoice === 'GLOBAL' ||
                  e?.quotation?._id === CSTB?.quotation?._id
              ).length > 0 ? (
                <Button disabled type="secondary" icon={<ProfileOutlined />}>
                  Le devis a été facturé en totalité
                </Button>
              ) : (
                <>
                  <Button
                    onClick={() => createInvoice()}
                    type="primary"
                    icon={<ProfileOutlined />}
                  >
                    Facturer la totalité
                  </Button>
                </>
              )}
            </Tooltip>
          </Col>
        </Col>
      </Row>
    </Modal>
  );
};

ShowGlobalInvoice.propTypes = {
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  getInvoices: PropTypes.func.isRequired,
  listInvoices: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

ShowGlobalInvoice.defaultProps = {
  setShowModal: null,
  showModal: null,
  listInvoices: []
};

export default ShowGlobalInvoice;
