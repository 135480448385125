import React, { useState } from 'react';
import moment from 'moment';
import { EditOutlined } from '@ant-design/icons';
import Datatable from '../../components/DataTable/Datatable';
import CreateUpdateEmail from './ComponentForEmailTemplate/CreateUpdateEmail';

const iconSize = 18;
const EmailsTemplateTableSettings = () => {
  const [visibleModal, setVisibleModal] = useState(false);
  const [dataToModal, setDataToModal] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);
  const columns = [
    {
      title: 'Intitulé',
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: 'Date de création',
      key: 'created_date',
      dataIndex: 'created_date',
      render: (value) => moment(value).format('DD-MM-YYYY'),
      sorter: true
    },
    {
      key: 'action',
      fixed: 'right',
      width: '9%',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <EditOutlined
            onClick={() => {
              setDataToModal(record);
              setVisibleModal(!visibleModal);
            }}
            style={{ fontSize: iconSize }}
          />
        </div>
      )
    }
  ];
  return (
    <>
      <Datatable
        forceRefresh={forceRefresh}
        customActionColumn
        resourceName="emails"
        columns={columns}
      />
      <CreateUpdateEmail
        purpose="edit"
        setForceRefresh={() => setForceRefresh(!forceRefresh)}
        dataTemplate={dataToModal}
        visible={visibleModal}
        setVisible={setVisibleModal}
      />
    </>
  );
};

export default EmailsTemplateTableSettings;
