import React, { useState } from 'react';
import { Switch } from 'antd';
import { ProjectOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import KanbanBoard from './KanbanBoard';
import ListDeals from './ListDeals';
import PageHeaderCustom from '../../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../../components/ContentCustom/ContentCustom';
import { DealsContextProvider } from './DealsContext';

const SwitchKanban = styled.div`
  font-size: 17px;
  color: var(--primaryColor);
  .anticon {
    margin-right: 10px;
  }
  .ant-switch {
    margin-left: 10px;
    margin-bottom: 5px;
  }
`;

const Deals = () => {
  const { t } = useTranslation();
  const [kanbanView, setKanbanView] = useState(true);

  return (
    <>
      <PageHeaderCustom
        title={t('deals.show.title')}
        withSubRoutes
        extra={
          <SwitchKanban>
            <ProjectOutlined />
            Kanban
            <Switch
              onChange={() => setKanbanView(!kanbanView)}
              checked={kanbanView}
            />
          </SwitchKanban>
        }
      />
      <ContentCustom>
        <DealsContextProvider>
          {kanbanView ? <KanbanBoard /> : <ListDeals />}
        </DealsContextProvider>
      </ContentCustom>
    </>
  );
};

export default Deals;
