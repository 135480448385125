import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { EyeOutlined } from '@ant-design/icons';
import Datatable from '../../../components/DataTable/Datatable';

const { Content } = Layout;
const iconSize = 18;

const CompanyPaymentsTable = ({ id }) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t('invoices.list.column.title.payment_date'),
      key: 'date',
      dataIndex: 'date',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('invoices.list.column.title.amount_paid'),
      dataIndex: 'amount_paid',
      render: (total) => `${parseFloat(total).toFixed(2)} €`
    },
    {
      title: t('invoices.list.column.title.payment_choice'),
      dataIndex: 'type'
    },
    {
      title: t('invoices.list.column.title.number_invoice'),
      dataIndex: 'number_invoice'
    },
    {
      key: 'action',
      fixed: 'right',
      width: '12%',
      render: ({ invoice_id }) => {
        return (
          <>
            <Link to={{ pathname: `/accounting/invoices/show/${invoice_id}` }}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
          </>
        );
      }
    }
  ];

  return (
    <>
      <Content
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        <Datatable
          resourceName="invoices/payments"
          path="/accounting/invoices"
          columns={columns}
          extraQuery={`customer=${id}&sort=-created_at`}
          customActionColumn
          withCreateButton={false}
        />
      </Content>
    </>
  );
};

export default CompanyPaymentsTable;
