import React from 'react';

const TruckIconFilled = () => {
  return (
    <span
      style={{ height: 23 }}
      role="img"
      className="anticon anticon-file-text"
      aria-label="file-text"
    >
      <svg
        height="22.47"
        width="32.25"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 32.25 22.47"
      >
        <circle cx="25.13" cy="19.82" r="2.64" fill="currentColor" />
        <circle cx="8.22" cy="19.82" r="2.64" fill="currentColor" />
        <path
          d="M26.36,4.67a2,2,0,0,1,.84.14S31.73,9.19,32,9.61a1.83,1.83,0,0,1,.21,1.05s.07,6.3,0,8a1.66,1.66,0,0,1-1.6,1.71H29.49s.26-4.94-4.34-5-4.34,5-4.34,5H12.53s.12-4.94-4.33-4.9-4.35,4.9-4.35,4.9H1.91A2,2,0,0,1,0,18.58s.07-15,0-16.89S2,0,2,0h15.6c2.12,0,1.84,1.69,1.84,1.69v3ZM21.69,6.93v5.65h8.09V10.74L26.09,6.93Z"
          fill="currentColor"
        />
      </svg>
    </span>
  );
};

const TruckIconOutlined = () => {
  return (
    <span
      style={{ height: 23 }}
      role="img"
      className="anticon anticon-file-text"
      aria-label="file-text"
    >
      <svg
        height="22.47"
        width="32.25"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 33.25 23.32"
      >
        <circle
          cx="25.63"
          cy="20.32"
          r="2.5"
          style="fill:none;stroke:#fff;stroke-miterlimit:10"
        />
        <circle
          cx="8.72"
          cy="20.32"
          r="2.5"
          fill="none"
          stroke="currentColor"
          strokeMiterlimit="10"
        />
        <path
          d="M26.86,5.17a2,2,0,0,1,.84.14s4.53,4.38,4.81,4.8a1.83,1.83,0,0,1,.21,1.05s.07,6.3,0,8a1.66,1.66,0,0,1-1.6,1.71H30s.26-4.94-4.34-5-4.34,5-4.34,5H13s.12-4.94-4.33-4.9-4.35,4.9-4.35,4.9H2.41A2,2,0,0,1,.5,19.08S.57,4,.5,2.19,2.49.5,2.49.5h15.6c2.12,0,1.84,1.69,1.84,1.69v3ZM22.19,7.43v5.65h8.09V11.24L26.59,7.43Z"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </span>
  );
};

export { TruckIconFilled, TruckIconOutlined };
