import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { Modal } from 'antd';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useTruckContext from './TruckContext';

const CreateUpdateTruck = ({ purpose }) => {
  const { confirm } = Modal;
  const { id } = useParams();
  const { basicFields } = useFields();
  const {
    ExclamationCircleOutlined,
    isFieldsLoading,
    errorMessage,
    dispatchAPI,
    users
  } = useTruckContext();

  const updateUser = async (userId) => {
    const body = { truck: null };
    try {
      await dispatchAPI('PATCH', { url: `/users/${userId}`, body });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const showConfirm = (selectedUser, form) => {
    confirm({
      title: `${selectedUser?.first_name} ${selectedUser?.last_name} est déjà affecté au camion ${selectedUser?.truck?.label}.`,
      icon: <ExclamationCircleOutlined />,
      content: `Voulez-vous l'affecter à ce camion?`,
      onOk() {
        updateUser(selectedUser._id);
      },
      onCancel() {
        form.setFieldsValue({
          users: form
            .getFieldValue('users')
            .filter((u) => u !== selectedUser._id)
        });
      }
    });
  };

  const checkTruck = (userId, form) => {
    const selectedUser = users.find((u) => u._id === userId);
    if (selectedUser?.truck !== null && selectedUser?.truck !== undefined) {
      if (selectedUser?.truck?._id !== id) {
        showConfirm(selectedUser, form);
      }
    }
  };

  const intermediateCheck = (changedValues, values, form) => {
    if (changedValues.users) {
      checkTruck(changedValues?.users[changedValues?.users.length - 1], form);
    }
  };

  return (
    <CreateUpdateContainer
      fields={basicFields}
      purpose={purpose}
      baseUrl="trucks"
      resource="trucks"
      loadingFields={isFieldsLoading}
      withSubRoutes
      onValuesChange={intermediateCheck}
    />
  );
};

CreateUpdateTruck.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateTruck;
