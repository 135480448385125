import React, { useEffect } from 'react';
import { Tag, Row, Card, Col, Descriptions, Collapse } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import GraphLiquid from './GraphLiquid';

const { Item } = Descriptions;
const { Panel } = Collapse;

const CardsGraph = ({ batches, tanks }) => {
  useEffect(() => {}, [batches]);

  return (
    <Collapse defaultActiveKey={['1']}>
      {tanks &&
        tanks.map((tank) => {
          const quantityTank = [];
          batches.map(({ batch }) =>
            batch
              ?.filter(
                (el) => el.tank?._id === tank?._id && el?.archived === false
              )
              .map(({ quantity }) => quantityTank.push(quantity))
          );
          const percent =
            (quantityTank.reduce((a, b) => a + b, 0) * 100) / tank.quantity;
          return (
            <Panel
              header={
                <Descriptions>
                  <Item label="Numéro de cuve">{tank.label}</Item>
                  <Item label="Taux de remplissage">{`${percent} %`}</Item>
                  <Item label="Quantité restante">
                    {`${quantityTank.reduce((a, b) => a + b, 0)} L`}
                  </Item>
                </Descriptions>
              }
              key={tank._id}
            >
              <Row align="middle">
                <Col span={14}>
                  {batches.map(({ batch, label }) =>
                    batch
                      ?.filter(
                        (el) =>
                          el.tank?._id === tank?._id && el?.archived === false
                      )
                      ?.map(({ batch_number, opened_date, quantity }) => (
                        <>
                          <Card key={batch_number}>
                            <Row>
                              <Descriptions title={<Tag>{label}</Tag>}>
                                <Item
                                  label="Numéro de lot"
                                  contentStyle={{
                                    fontWeight: 'bold'
                                  }}
                                >
                                  {batch_number}
                                </Item>
                                <Item
                                  label="Date de remplissage"
                                  contentStyle={{
                                    fontWeight: 'bold'
                                  }}
                                >
                                  {moment(opened_date).format('DD/MM/YYYY')}
                                </Item>
                                <Item
                                  label="Quantité restante"
                                  contentStyle={{
                                    fontWeight: 'bold'
                                  }}
                                >
                                  {`${quantity} L`}
                                </Item>
                              </Descriptions>
                            </Row>
                          </Card>
                          <br />
                        </>
                      ))
                  )}
                </Col>
                <Col span={10}>
                  <GraphLiquid percent={percent / 100} />
                </Col>
              </Row>
            </Panel>
          );
        })}
    </Collapse>
  );
};

CardsGraph.propTypes = {
  batches: PropTypes.arrayOf(PropTypes.object).isRequired,
  tanks: PropTypes.arrayOf(PropTypes.any).isRequired
};

export default CardsGraph;
