import React, { useCallback, useEffect, useState } from 'react';
import { Input, InputNumber, Select, Divider, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;
const { TextArea } = Input;

const useFields = (isProfessional, type, form, numberAccount) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [origins, setOrigins] = useState([]);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [commercial, setCommercial] = useState();

  const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  // const phoneNumberFormat = /^(?:(?:\+|00)33|0)\s*[1-9](?:[\s.-]*\d{2}){4}$/gim;
  /**
   * CheckSiret validation function, check if a given value is indeed a siret or not
   * @param {*} _
   * @param {Number} siret value of input
   * @returns {boolean} true if siret ; false if not a number or a valid siret
   *
   */

  // const checkSiret = (_, siret) => {
  //   return new Promise((resolve, reject) => {
  //     if (siret && (Number.isNaN(siret) || siret.length !== 14)) {
  //       return reject(
  //         new Error('Merci de renseigner un numéro de siret Valide.')
  //       );
  //     } else if (siret) {
  //       let bal = 0;
  //       let total = 0;
  //       // eslint-disable-next-line no-plusplus
  //       for (let i = 14 - 1; i >= 0; i--) {
  //         const step = (siret.charCodeAt(i) - 48) * (bal + 1);
  //         total += step > 9 ? step - 9 : step;
  //         bal = 1 - bal;
  //       }
  //       if (total % 10 === 0) {
  //         return resolve();
  //       }

  //       return reject(
  //         new Error('Merci de renseigner un numéro de siret Valide.')
  //       );
  //     }
  //     return resolve();
  //   });
  // };
  const fields = [
    ...(isProfessional
      ? [
          {
            colon: false,
            name: ['separate_1'],
            input: (
              <Divider orientation="left">
                {t(`customers.form.identity`)}
              </Divider>
            )
          },
          {
            name: ['name'],
            rules: [{ required: true }]
          },
          {
            name: ['siret_number']
          },
          {
            name: ['designated_commercial'],
            rules: [{ required: true }],
            input: (
              <Select loading={isFieldsLoading}>
                {(commercial || []).map(({ last_name, first_name, _id }) => (
                  <Option key={last_name} value={_id}>
                    {`${first_name.toUpperCase()} ${last_name.toUpperCase()}`}
                  </Option>
                ))}
              </Select>
            )
          },
          ...(type !== 'suppliers'
            ? [
                {
                  name: ['status'],
                  input: (
                    <Select disabled loading={isFieldsLoading}>
                      {(enums.status || []).map((stat) => (
                        <Select.Option key={stat} value={stat}>
                          {t(`companies.types.${stat}`)}
                        </Select.Option>
                      ))}
                    </Select>
                  )
                }
              ]
            : []),
          {
            name: ['activity']
          },
          {
            colon: false,
            name: ['separate_6'],
            input: (
              <Divider orientation="left">
                {t(`customers.form.contact`)}
              </Divider>
            )
          },
          {
            name: ['address_details_1'],
            rules: [{ required: true }]
          },
          {
            name: ['address_details_2']
          },
          {
            name: ['postal_code'],
            rules: [{ required: true }]
          },
          {
            name: ['city'],
            rules: [{ required: true }]
          },
          {
            colon: false,
            name: ['separate_7'],
            input: (
              <Divider orientation="left">
                {t(`customers.form.contact_name`)}
              </Divider>
            )
          },
          {
            name: ['gender'],
            rules: [{ required: true }],
            input: (
              <Select>
                {(enums.genders || []).map((title) => (
                  <Option key={title} value={title}>
                    {t(`contacts.form.${title}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['last_name'],
            rules: [{ required: true }]
          },
          {
            name: ['first_name'],
            rules: [{ required: true }]
          },
          {
            name: ['phone_number_mobile'],
            rules: [{ required: true }]
          },
          {
            name: ['email'],
            rules: [
              { required: true },
              () => ({
                validator(_, value) {
                  if (value) {
                    if (mailformat.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Addresse invalide'));
                  }
                  return Promise.resolve();
                }
              })
            ]
          },
          {
            name: ['website']
          },
          { noLabel: true, input: <></> }
        ]
      : [
          {
            colon: false,
            name: ['separate_2'],
            input: (
              <Divider orientation="left">
                {t(`customers.form.identity`)}
              </Divider>
            )
          },
          {
            name: ['gender'],
            rules: [{ required: true }],
            input: (
              <Select loading={isFieldsLoading}>
                {(enums.genders || []).map((title) => (
                  <Option key={title} value={title}>
                    {t(`contacts.form.${title}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['last_name'],
            rules: [{ required: true }]
          },
          {
            name: ['first_name'],
            rules: [{ required: true }]
          },
          {
            name: ['origin'],
            input: (
              <Select loading={isFieldsLoading}>
                {origins?.map((origin) => (
                  <Option key={origin._id} value={origin.label}>
                    {origin.label}
                  </Option>
                ))}
              </Select>
            )
          },

          {
            name: ['designated_commercial'],
            rules: [{ required: user?.role !== 'admins:SUPER-ADMIN' }],
            input: (
              <Select
                loading={isFieldsLoading}
                disabled={user.role !== 'admins:SUPER-ADMIN'}
              >
                {(commercial || []).map(({ last_name, first_name, _id }) => (
                  <Option key={last_name} value={_id}>
                    {`${first_name.toUpperCase()} ${last_name.toUpperCase()}`}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['status'],
            input: (
              <Select disabled loading={isFieldsLoading}>
                {(enums.status || []).map((stat) => (
                  <Select.Option key={stat} value={stat}>
                    {t(`companies.types.${stat}`)}
                  </Select.Option>
                ))}
              </Select>
            )
          },
          {
            colon: false,
            name: ['separate_3'],
            input: (
              <Divider orientation="left">
                {t(`customers.form.contact`)}
              </Divider>
            )
          },
          {
            name: ['address_details_1'],
            rules: [{ required: true }]
          },
          {
            name: ['address_details_2']
          },
          {
            name: ['postal_code'],
            rules: [{ required: true }]
          },
          {
            name: ['city'],
            rules: [{ required: true }]
          },
          { noLabel: true, input: <></> },

          {
            name: ['phone_number_mobile'],
            rules: [{ required: true }]
          },
          {
            name: ['phone_number_office']
          },
          {
            name: ['email'],
            rules: [
              { required: true },
              () => ({
                validator(_, value) {
                  if (value) {
                    if (mailformat.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Addresse invalide'));
                  }
                  return Promise.resolve();
                }
              })
            ]
          }
        ])
  ];

  const advancedFields = [
    ...(isProfessional
      ? [
          {
            name: ['ape_number'],
            input: <InputNumber style={{ width: '100%' }} />
          },
          {
            name: ['tva_number'],
            input: <InputNumber style={{ width: '100%' }} />
          },
          {
            name: ['legal_form'],
            input: (
              <Select loading={isFieldsLoading}>
                {(enums.legalForms || []).map((stat) => (
                  <Option key={stat} value={stat}>
                    {t(`companies.legalForms.${stat}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['deadline'],
            rules: [{ required: true }],
            input: (
              <Select loading={isFieldsLoading}>
                {(enums.deadlines || []).map((deadline) => (
                  <Option key={deadline} value={deadline}>
                    {t(`companies.deadLines.${deadline}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['other_time_granted'],
            input: <InputNumber type="number" style={{ width: '100%' }} />
          },
          ...(type !== 'suppliers'
            ? [
                {
                  colon: false,
                  name: ['separate_4'],
                  input: (
                    <Divider orientation="left">
                      {t(`customers.form.billing`)}
                    </Divider>
                  )
                },
                {
                  name: ['payment_type'],
                  input: (
                    <Select loading={isFieldsLoading}>
                      {(enums.paymentTypes || []).map((paymentType) => (
                        <Option key={paymentType} value={paymentType}>
                          {t(`companies.paymentTypes.${paymentType}`)}
                        </Option>
                      ))}
                    </Select>
                  )
                },
                {
                  name: ['outstanding_amount'],
                  input: <InputNumber style={{ width: '100%' }} min="0" />
                },
                {
                  name: ['number_customer_account'],
                  input: <Input addonBefore={numberAccount} />
                },
                {
                  name: ['holdbacks'],
                  input: (
                    <InputNumber
                      defaultValue="0"
                      style={{ width: '100%' }}
                      min="0"
                    />
                  )
                },
                {
                  name: ['observations'],
                  input: <TextArea style={{ width: '100%' }} />
                },
                {
                  name: ['billing_address_use'],
                  valuePropName: 'checked',
                  input: <Checkbox />
                },
                {
                  name: ['billing_address_1']
                },
                {
                  name: ['billing_address_2']
                },
                {
                  name: ['billing_postal_code']
                },
                {
                  name: ['billing_city']
                }
              ]
            : [])
        ]
      : [
          {
            colon: false,
            name: ['separate_5'],
            input: (
              <Divider orientation="left">
                {t(`customers.form.billing`)}
              </Divider>
            )
          },
          {
            name: ['payment_type'],
            input: (
              <Select loading={isFieldsLoading}>
                {(enums.paymentTypes || []).map((paymentType) => (
                  <Option key={paymentType} value={paymentType}>
                    {t(`companies.paymentTypes.${paymentType}`)}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['outstanding_amount'],
            input: <InputNumber style={{ width: '100%' }} min="0" />
          },
          {
            name: ['number_customer_account']
          },
          {
            name: ['holdbacks'],
            input: (
              <InputNumber defaultValue="0" style={{ width: '100%' }} min="0" />
            )
          },
          {
            name: ['observations'],
            input: <TextArea style={{ width: '100%' }} />
          },
          {
            name: ['billing_address_use'],
            input: <Checkbox />
          },
          {
            name: ['billing_address_1']
          },
          {
            name: ['billing_address_2']
          },
          {
            name: ['billing_postal_code']
          },
          {
            name: ['billing_city']
          }
        ])
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies/enums' });
      setEnums(data);
      form.current.setFieldsValue({
        status: data?.status?.filter((el) => el === 'CUSTOMERS')[0]
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCommercial = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users?type=SELLER,DIRECTION,BUY`
      });
      setCommercial(data);
      form.current.setFieldsValue({
        designated_commercial: data.filter((e) => e._id === user._id)[0]._id
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOrigins = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deal-origins`
      });
      setOrigins(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getCommercial();
    await getOrigins();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { fields, advancedFields, isFieldsLoading };
};

export default useFields;
