import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Col, Row } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import { noLabelLayout } from '../../../utils/constants/formLayout';

const CreateUpdatePrestation = ({ purpose }) => {
  const generateFormItem = useGenerateFormItem();
  const { t } = useTranslation();
  const { basicFields, advancedFields, isFieldsLoading, config } = useFields();
  const [visible, setVisible] = useState(true);

  const advancedOptions = (
    <>
      <Row style={{ marginTop: 30, marginBottom: 30 }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Col {...noLabelLayout.wrapperCol}>
          <Button type="link" onClick={() => setVisible(!visible)}>
            {t('prestations.form.add_prices')}
            {visible ? (
              <UpOutlined style={{ marginLeft: 10 }} />
            ) : (
              <DownOutlined style={{ marginLeft: 10 }} />
            )}
          </Button>
        </Col>
      </Row>
      {visible &&
        advancedFields.map((field) => generateFormItem('prestations', field))}
    </>
  );
  return (
    <CreateUpdateContainer
      fields={basicFields}
      formExtra={advancedOptions}
      purpose={purpose}
      config={config}
      baseUrl="prestations"
      resource="prestations"
      loadingFields={isFieldsLoading}
      withSubRoutes
    />
  );
};

CreateUpdatePrestation.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdatePrestation;
