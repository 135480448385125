import moment from 'moment';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation, useParams } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const InterventionContext = createContext({});

export const InterventionContextProvider = ({ children }) => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const [refresh, setRefresh] = useState(false);
  const [movesToPlanned, setMovesToPlanned] = useState([]);
  const [movesPlanned, setMovesPlanned] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const [trucks, setTrucks] = useState([]);
  const [displayToPlanned, setDisplayToPlanned] = useState(movesToPlanned);

  const [allMoves, setAllMoves] = useState([]);
  useEffect(() => {
    setDisplayToPlanned(movesToPlanned);
  }, [movesToPlanned]);

  const fetchMoves = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/movements/planning?populate=customer,quotation.prestations.prestation,site.department,works_supervisor&sort=date`
      });
      setAllMoves(data);
      const movesUnaffect = [];
      const movesTechPlanned = [];
      data.map((move) => {
        if (move.truck !== null) {
          if (move.status !== 'WAITING') {
            movesTechPlanned.push(move);
          }
        } else if (move.truck === undefined || move.truck === null) {
          movesUnaffect.push(move);
        }
      });
      setMovesToPlanned(movesUnaffect);
      setMovesPlanned(movesTechPlanned);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading({});
  }, [refresh]);

  const fetchTrucks = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/trucks?populate=users`
      });
      setTrucks(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      await fetchTrucks();
      await fetchMoves();
      setLoading(false);
    })();
  }, [fetchMoves, fetchTrucks, pathname]);

  const plannedMove = async (start, date, item) => {
    const move = allMoves.filter((e) => e._id === item.id)[0];
    const tech = trucks
      .filter((tr) => tr._id === start)
      .map((t) => t.users.map((u) => u._id));
    const body = {
      type: move.type,
      truck: start,
      date,
      status: 'PLANNED',
      technicians: tech[0]
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/movements/${item.id}`,
        body
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const unPlannedMove = async (moveId) => {
    setLoading(true);
    const body = {
      status: 'WAITING',
      date: '',
      truck: null,
      technicians: []
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/movements/${moveId}`,
        body
      });
      const mail = {
        to: [{ email: 'planning@ovalpro.fr' }],
        sender: {
          email: user.email,
          name: `${user.first_name} ${user.last_name}`
        },
        params: {
          object: 'Intervention à planifier',
          message: null
        },
        subject: 'Intervention à planifier',
        htmlContent: 'Intervention à planifier'
      };
      await dispatchAPI('POST', {
        url: '/movements/sendmail',
        body: mail
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setLoading(false);
  };

  // Setup weeks selection
  moment.locale('fr');
  const startOfPeriod = moment();
  const begin = moment(startOfPeriod)
    .isoWeekday(1)
    .startOf('isoWeek');

  const [start, setStart] = useState(begin);
  const [year, setYear] = useState(startOfPeriod.year());

  const getNewStartWithWeekNumber = (weekNumber) => {
    const goodWeek = moment(start)
      .day('lundi')
      .week(weekNumber);
    setStart(goodWeek);
  };

  const getNewStartWithYear = (yearNumber) => {
    const goodWeek = moment(start)
      .day('lundi')
      .year(yearNumber)
      .startOf('isoWeek');
    setStart(goodWeek);
    setYear(yearNumber);
  };

  const weekNumbers = [];
  for (let i = 1; i < 53; i += 1) {
    weekNumbers.push(i);
  }
  const toBeAffectToWeekX = displayToPlanned.filter(
    (dtp) =>
      (dtp?.status === 'WAITING' && dtp?.quotation?.intervention_date_programmed
        ? moment(dtp?.quotation?.intervention_date_programmed).week()
        : moment(dtp?.quotation?.intervention_date_expected).week()) ===
      start.week()
  );

  const toBeAffect = displayToPlanned.filter(
    (dtp) => dtp?.status === 'WAITING'
  );
  return (
    <InterventionContext.Provider
      value={{
        id,
        refresh,
        setRefresh,
        movesToPlanned,
        plannedMove,
        setMovesToPlanned,
        movesPlanned,
        setMovesPlanned,
        isLoading,
        setIsLoading,
        dispatchAPI,
        unPlannedMove,
        trucks,
        displayToPlanned,
        setDisplayToPlanned,
        setStart,
        startOfPeriod,
        getNewStartWithWeekNumber,
        begin,
        start,
        weekNumbers,
        toBeAffectToWeekX,
        toBeAffect,
        getNewStartWithYear,
        year,
        loading,
        setLoading
      }}
    >
      {children}
    </InterventionContext.Provider>
  );
};

export default () => useContext(InterventionContext);
