import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, Select, Tag, Button, Form } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import { tasksPriorities } from '../../../../utils/constants/tagColors';
import DrawerNewCompany from '../../Companies/DrawerNewCompany';

const { Option } = Select;
const { TextArea } = Input;

const useFields = (visible) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState({});
  const [kanbanCols, setKanbanCols] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [isCompanyDrawerVisible, setVisibilityCompanyDrawer] = useState(false);

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['priority'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.priorities || []).map((priority) => (
            <Option value={priority} key={priority}>
              <Tag color={tasksPriorities[priority]}>
                {t(`deals.tags.${priority}`)}
              </Tag>
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {kanbanCols.map(({ _id, label }) => (
            <Option value={_id} key={_id}>
              {label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['companies'],
      rules: [{ required: true }],
      input: (
        <Input.Group style={{ display: 'flex' }}>
          <DrawerNewCompany
            isVisible={isCompanyDrawerVisible}
            setVisibilityCompanyDrawer={setVisibilityCompanyDrawer}
            getCompanies={getCompanies}
            companies={companies}
          />
          <Form.Item style={{ width: '100%' }} name={['companies']}>
            <Select
              showSearch
              style={{ width: '100%' }}
              loading={isFieldsLoading}
              optionFilterProp="children"
              filterOption={(input, option) =>
option?.children?.toLowerCase()?.includes(input?.toLowerCase())
              }
            >
              {companies.map(({ _id, name }) => (
                <Option value={_id} key={_id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="add"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setVisibilityCompanyDrawer(!isCompanyDrawerVisible);
              }}
            >
              {`${t('buttons.create')} `}
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['lead'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(users || []).map((user) => (
            <Option value={user._id} key={user._id}>
              {`${user.first_name} ${user.last_name}`}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['global_information'],
      rules: [{ required: false }],
      input: <TextArea rows={8} />
    }
  ];

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?type=SELLER,DIRECTION'
      });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/deals/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getKanbans = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/kanbans' });
      setKanbanCols(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getCompanies();
    await getUsers();
    await getKanbans();
    setIsFieldsLoading(false);
  }, [visible]);

  useEffect(() => {
    if (visible)
      (async () => {
        await getSelectOptions();
      })();
  }, [getSelectOptions]);

  return { fields, isFieldsLoading };
};

export default useFields;
