import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  Modal,
  Row,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Button
} from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import { google } from 'calendar-link';
import { Link } from 'react-router-dom';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import GoogleCalendarIcon from '../../../assets/images/icons/google-calendar_icon.png';
import { routes, subRoutes } from '../../../utils/constants/routes';

const ModalForm = ({ data, getData, visible, setVisible, setLoading }) => {
  const { dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [date, setDate] = useState();

  const renderLink = () => {
    const event = {
      title: data?.site?.reference,
      location: `${data?.site?.address_details_1} ${data?.site?.address_details_2} ${data?.site?.city}`,
      description: data?.description !== null || '',
      start: data?.date,
      duration: data.date + 2
    };
    return google(event);
  };

  const patchDate = async () => {
    if (date) {
      setLoading(true);
      try {
        await dispatchAPI('PATCH', {
          url: `/meeting/${data._id}`,
          body: { date }
        });
        setVisible(false);
        getData();
        setLoading(false);
      } catch (e) {
        setLoading(false);
        if (e.response) errorMessage(e.response.status);
      }
    }
  };
  const deleteDate = async () => {
    setLoading(true);
    try {
      await dispatchAPI('DELETE', {
        url: `/meeting/${data._id}`
      });
      setVisible(false);
      getData();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      if (e.response) errorMessage(e.response.status);
    }
  };

  return (
    <Modal
      title="Modifier la date de l'intervention technique"
      closable
      destroyOnClose
      width={800}
      visible={visible}
      onOk={() => patchDate()}
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" onClick={() => setVisible(false)}>
          Annuler
        </Button>,
        <Button key="submit" type="primary" onClick={() => patchDate()}>
          Changer la date de rendez-vous
        </Button>,
        <Button type="danger" onClick={() => deleteDate()}>
          Supprimer
        </Button>
      ]}
    >
      <Descriptions>
        <Descriptions.Item label="Référence chantier">
          {data?.site?.reference}
        </Descriptions.Item>
        <Descriptions.Item label="Devis n°">
          {data?.quotation?.number}
        </Descriptions.Item>
        <Descriptions.Item label="Adresse">
          {`${data?.site?.address_details_1} ${data?.site?.city}`}
        </Descriptions.Item>
        <Descriptions.Item span={2} label="Contact">
          {`${data?.site?.contact?.last_name || ''} ${data?.site?.contact
            ?.first_name || ''}: ${data?.site?.contact?.phone_number_mobile ||
            ''}`}
        </Descriptions.Item>
        <Descriptions.Item>
          <Link
            to={{ pathname: renderLink() }}
            target="_blank"
            style={{ marginRight: 16 }}
          >
            <Button
              type="link"
              shape="round"
              style={{
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <img
                src={GoogleCalendarIcon}
                alt="Google Calendar"
                style={{ height: 60 }}
              />
            </Button>
          </Link>
        </Descriptions.Item>
        <Descriptions.Item>
          <Link
            to={{
              pathname: `${routes.INTERVENTION}${subRoutes.INTERVENTION.MANAGE_INTERVENTIONS}/show/${data?.movement}`
            }}
          >
            <Button icon={<EyeOutlined style={{ color: 'var(--addColor)' }} />}>
              Consulter l&apos;intervention
            </Button>
          </Link>
        </Descriptions.Item>
      </Descriptions>
      <Divider />
      <Row justify="center">
        <Col span={12}>
          <DatePicker
            showTime={{ format: 'hh:mm' }}
            defaultValue={moment(data?.date)}
            // onChange={onChange}
            onOk={(value) => setDate(value)}
          />
        </Col>
        <Col span={12}>
          <span>Rendez-vous actuel :</span>
          <span style={{ fontWeight: 'bold' }}>
            {` ${moment(data?.date).format('ddd DD / MM / YYYY - hh:mm')}`}
          </span>
        </Col>
      </Row>
    </Modal>
  );
};
ModalForm.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  getData: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired
};

ModalForm.defaultProps = {
  data: []
};

export default ModalForm;
