import React, { useState } from 'react';
import propTypes from 'prop-types';
import { Button, Popconfirm, Menu, message, Badge } from 'antd';
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  WarningOutlined,
  CopyOutlined,
  SettingOutlined,
  MailOutlined,
  PrinterOutlined,
  AuditOutlined,
  BankOutlined,
  EuroOutlined,
  FileSyncOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DuplicateQuotationButton from './DuplicateQuotationButton';
import ValidateQuotationModal from '../ValidateQuotationModal';
import CreateDeposit from '../CreateDeposit';
import BankManagementModal from './BankManagementModal';
import CreateCreditInvoiceModal from '../Components/CreateCreditInvoiceModal';

const { SubMenu } = Menu;

const ExtraShowComponent = ({
  id,
  isLoading,
  displayedResource,
  resource,
  deleteDocument,
  setShowModalUpload,
  validateQuotation,
  changeQuotationStatus,
  showModalUpload,
  getResource,
  downloadFile,
  setVisibleSendMail,
  DisplayQuotationMedia,
  setShowDeposit,
  showDeposit,
  setShowCreditInvoice,
  showCreditInvoice
}) => {
  const [current, setCurrent] = useState();
  const [visibleBankModal, setVisibleBankModal] = useState(false);
  const { t } = useTranslation();
  const handleClick = (e) => {
    setCurrent(e.key);
  };
  return (
    <>
      <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
        <Menu.Item
          disabled={
            displayedResource.status === 'CANCELED' ||
            displayedResource?.status === 'DUPLICATED' ||
            displayedResource?.status === 'ARCHIVED' ||
            !displayedResource.customer
          }
          key="mail"
          icon={<MailOutlined />}
        >
          <Button
            disabled={
              displayedResource.status === 'CANCELED' ||
              displayedResource?.status === 'DUPLICATED' ||
              displayedResource?.status === 'ARCHIVED' ||
              !displayedResource.customer
            }
            type="link"
            onClick={() => setVisibleSendMail(true)}
          >
            <Badge dot={!displayedResource.email_sent}>Envoyer le devis</Badge>
          </Button>
        </Menu.Item>
        <SubMenu
          disabled={displayedResource?.status === 'ARCHIVED'}
          key="manage"
          icon={<SettingOutlined />}
          title={
            <Badge
              dot={
                !displayedResource.bank_account &&
                displayedResource.status === 'VALIDATE'
              }
            >
              <span>Gestion devis</span>
            </Badge>
          }
        >
          {displayedResource.status !== 'VALIDATE' &&
            displayedResource.status !== 'CANCELED' &&
            displayedResource?.status !== 'DUPLICATED' &&
            displayedResource?.status !== 'ARCHIVED' && (
              <Menu.Item icon={<CopyOutlined />} key="duplicate">
                <DuplicateQuotationButton id={displayedResource._id} />
              </Menu.Item>
            )}
          {displayedResource?.status !== 'DUPLICATED' &&
            displayedResource?.status !== 'ARCHIVED' && (
              <Menu.Item icon={<CheckOutlined />} key="validation">
                <Button type="link" onClick={() => setShowModalUpload(true)}>
                  {displayedResource.status === 'REQUEST'
                    ? t('buttons.validate')
                    : 'Importer devis signé'}
                </Button>
              </Menu.Item>
            )}
          {displayedResource?.status === 'VALIDATE' &&
            displayedResource?.status !== 'ARCHIVED' && (
              <Menu.Item icon={<BankOutlined />} key="bank_account">
                <Button
                  type="link"
                  onClick={() => setVisibleBankModal(!visibleBankModal)}
                >
                  <Badge dot={!displayedResource.bank_account}>
                    Rattacher une banque
                  </Badge>
                </Button>
              </Menu.Item>
            )}

          {displayedResource?.status === 'QUOTATION_PENDING' && (
            <Menu.Item icon={<CheckOutlined />} key="change_status">
              <Button type="link" onClick={() => changeQuotationStatus()}>
                {displayedResource.status === 'REQUEST'
                  ? t('buttons.validateQuotation')
                  : 'Importer devis signé'}
              </Button>
            </Menu.Item>
          )}

          {!isLoading && (
            <>
              {!displayedResource?.email_sent &&
                displayedResource.status !== 'VALIDATE' &&
                displayedResource.status !== 'DUPLICATED' && (
                  <Menu.Item icon={<EditOutlined />} key="edit">
                    <Link
                      to={{ pathname: `/commercial/${resource}/edit/${id}` }}
                    >
                      <Button type="link">{t('buttons.edit')}</Button>
                    </Link>
                  </Menu.Item>
                )}

              <Menu.Item icon={<DeleteOutlined />} key="delete">
                <Popconfirm
                  title={t(`${resource}.action.archive.title`)}
                  okText={t(`${resource}.action.archive.ok`)}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t(`${resource}.action.archive.cancel`)}
                  onConfirm={() =>
                    deleteDocument(id, () => message.success('Devis archivé !'))
                  }
                  icon={<WarningOutlined />}
                >
                  <Button type="link" danger>
                    {t('buttons.archive')}
                  </Button>
                </Popconfirm>
              </Menu.Item>
            </>
          )}
        </SubMenu>
        <SubMenu
          disabled={displayedResource?.status === 'REQUEST'}
          key="print"
          icon={<PrinterOutlined />}
          title="Impression"
        >
          {displayedResource?.signed_quotation_file && (
            <Menu.Item icon={<PrinterOutlined />} key="signed">
              <Button
                type="link"
                loading={isLoading}
                onClick={() =>
                  downloadFile(`${displayedResource?.number}-signed`)
                }
              >
                {t('quotations.show.print_signed')}
              </Button>
            </Menu.Item>
          )}
          <Menu.Item key="origin" icon={<PrinterOutlined />}>
            <Button
              type="link"
              loading={isLoading}
              onClick={() =>
                DisplayQuotationMedia(
                  `/generate/quotations/${id}`,
                  displayedResource?.number
                )
              }
            >
              Original
            </Button>
          </Menu.Item>
        </SubMenu>
        {displayedResource?.status === 'VALIDATE' && (
          <SubMenu key="invoice" icon={<AuditOutlined />} title="Factures">
            <>
              <Menu.Item
                key="depsoit_invoice"
                icon={<EuroOutlined />}
                title="Facture d'acompte"
              >
                <Button
                  type="link"
                  disabled={
                    displayedResource.invoices.find(
                      (invoice) => invoice.type_invoice === 'GLOBAL'
                    ) ||
                    displayedResource.invoices.find(
                      (invoice) => invoice.type === 'DEPOSIT'
                    )
                  }
                  onClick={() => setShowDeposit(true)}
                >
                  Facture d&apos;acompte
                  {(displayedResource.invoices.find(
                    (invoice) => invoice.type_invoice === 'GLOBAL'
                  ) ||
                    displayedResource.invoices.find(
                      (invoice) => invoice.type === 'DEPOSIT'
                    )) && (
                    <span style={{ color: 'red', marginLeft: 5 }}>
                      (Facture finale ou facture d&apos;acompte créée)
                    </span>
                  )}
                </Button>
              </Menu.Item>
              <Menu.Item
                key="amount_invoice"
                icon={<FileSyncOutlined />}
                title="Facture d'avoir"
              >
                <Button
                  disabled={displayedResource.status !== 'VALIDATE'}
                  type="link"
                  onClick={() => setShowCreditInvoice(true)}
                >
                  Facture d&apos;avoir
                </Button>
              </Menu.Item>
              <SubMenu key="all_invoices" title="Factures rattachées">
                {displayedResource.invoices.map((invoice) => (
                  <Menu.Item key={invoice._id}>
                    <Link
                      to={{
                        pathname: `/accounting/invoices/show/${invoice._id}`
                      }}
                    >
                      <Button type="link">
                        {`${invoice.number} ${
                          invoice.type === 'DEPOSIT' ? '(A)' : ''
                        }${invoice.type_invoice === 'GLOBAL' ? '(F)' : ''}`}
                      </Button>
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            </>
          </SubMenu>
        )}
      </Menu>
      <CreateCreditInvoiceModal
        setShowModalCredit={setShowCreditInvoice}
        showModalCredit={showCreditInvoice}
        getInvoices={getResource}
        quotationData={displayedResource}
      />
      <ValidateQuotationModal
        setShowModal={setShowModalUpload}
        showModal={showModalUpload}
        quotationId={id}
        getResource={getResource}
        resource={displayedResource}
        downloadFile={downloadFile}
        validateQuotation={validateQuotation}
      />
      <CreateDeposit
        setShowModal={setShowDeposit}
        showModal={showDeposit}
        getInvoices={getResource}
        quotationData={displayedResource}
      />

      <BankManagementModal
        visible={visibleBankModal}
        setVisible={setVisibleBankModal}
        dataQuote={displayedResource}
        getResource={getResource}
      />
    </>
  );
};

ExtraShowComponent.propTypes = {
  id: propTypes.string,
  isLoading: propTypes.bool.isRequired,
  displayedResource: propTypes.oneOfType([propTypes.object]).isRequired,
  resource: propTypes.oneOfType([propTypes.string]),
  deleteDocument: propTypes.func.isRequired,
  setShowModalUpload: propTypes.func.isRequired,
  validateQuotation: propTypes.func.isRequired,
  changeQuotationStatus: propTypes.func.isRequired,
  showModalUpload: propTypes.bool.isRequired,
  getResource: propTypes.func.isRequired,
  downloadFile: propTypes.func.isRequired,
  setVisibleSendMail: propTypes.func.isRequired,
  setShowDeposit: propTypes.func.isRequired,
  showDeposit: propTypes.bool.isRequired,
  DisplayQuotationMedia: propTypes.func.isRequired,
  showCreditInvoice: propTypes.bool.isRequired,
  setShowCreditInvoice: propTypes.func.isRequired
};
ExtraShowComponent.defaultProps = {
  resource: undefined,
  id: null
};
export default ExtraShowComponent;
