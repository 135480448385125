import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  DatePicker,
  Dropdown,
  Input,
  Menu,
  Row,
  Table
} from 'antd';
import { DownloadOutlined, EyeOutlined, MenuOutlined } from '@ant-design/icons';
import moment from 'moment';
import { Link, useHistory, useLocation } from 'react-router-dom';
import useColumns from './columns';
import Columns from './hoursColumns';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Search } = Input;
const { RangePicker } = DatePicker;

const ListHours = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const keyword = params.get('k');
  const { dispatchAPI } = useAuthContext();
  const { pathname } = useLocation();
  const history = useHistory();
  const { message: errorMessage } = useErrorMessage();
  const columns = useColumns();
  const hoursColumns = Columns();
  const [technicians, setTechnicians] = useState([]);
  const [dataSet, setDataSet] = useState([]);
  const [monthsRange, setMonthsRange] = useState([]);
  const [range, setRange] = useState(false);
  const [searchValue, setSearchValue] = useState(keyword);

  hoursColumns.push({
    key: 'action',
    // eslint-disable-next-line react/prop-types
    render: ({ id_tech, month }) => (
      <div style={{ float: 'right' }}>
        <Link
          to={{
            pathname: `${pathname}/show/${id_tech}?month=${month}`
          }}
        >
          <EyeOutlined style={{ fontSize: 18 }} />
        </Link>
      </div>
    )
  });

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?&k=${value}`
      });
    } else {
      history.push({
        pathname,
        search: ''
      });
    }
  };

  useEffect(() => {
    setSearchValue(null);
  }, [pathname]);

  useEffect(() => {
    if (keyword) setSearchValue(keyword);
    else setSearchValue(null);
  }, [keyword]);

  const getTechnicians = async () => {
    try {
      const searchURL = searchValue ? `/search/${searchValue}` : null;
      const { data } = await dispatchAPI('GET', {
        url: `/users${searchURL || ''}?type=TECH&populate=truck`
      });
      setTechnicians(
        data.map((el) => ({
          ...el,
          key: el._id
        }))
      );
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getDataSet = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/hours-report?start_month=${moment(monthsRange[0]).format(
          'YYYY-MM-DD'
        )}&end_month=${moment(monthsRange[1]).format(
          'YYYY-MM-DD'
        )}&range=${range}`
      });
      setDataSet(
        data.map((el) => ({
          ...el,
          key: el._id
        }))
      );
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const onMonthsChange = (values) => {
    if (values) {
      setRange(true);
      setMonthsRange(values);
    } else {
      setRange(false);
      setMonthsRange([]);
    }
  };

  useEffect(() => {
    (async () => {
      await getTechnicians();
      await getDataSet();
    })();
  }, [monthsRange, range, searchValue]);

  const exportData = async (type) => {
    try {
      const searchURL = searchValue ? `/search/${searchValue}` : null;
      const response = await dispatchAPI('GET', {
        url: `/hours-report/${
          type === 'day' ? 'export-data-day' : 'export-data'
        }${searchURL || ''}?start_month=${moment(monthsRange[0]).format(
          'YYYY-MM-DD'
        )}&end_month=${moment(monthsRange[1]).format(
          'YYYY-MM-DD'
        )}&range=${range}`,
        responseType: 'arraybuffer'
      });
      const bufferArray = new Uint8Array(response.data);
      const blob = new Blob([bufferArray], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `export_${moment().format('DD-MM-YYYY_HH:mm')}`;
      a.target = '_blank';
      a.click();
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  const menu = (
    <Menu>
      <Menu.Item>
        <Button type="link" onClick={() => exportData('day')}>
          <DownloadOutlined style={{ fontSize: '14px' }} />
          Exporter les données par jour
        </Button>
      </Menu.Item>
      <Menu.Item>
        <Button type="link" onClick={() => exportData('month')}>
          <DownloadOutlined style={{ fontSize: '14px' }} />
          Exporter les données par mois
        </Button>
      </Menu.Item>
    </Menu>
  );

  const expandedRowRender = (record) => {
    const months = dataSet.filter((el) => el.id_tech === record._id);
    return (
      <Table
        columns={hoursColumns}
        dataSource={months}
        bordered
        pagination={false}
      />
    );
  };

  return (
    <>
      <PageHeaderCustom title={t('movements.manage_hours.title')} />
      <ContentCustom>
        <Row justify="space-between" gutter={[8, 16]}>
          <Col>
            <Search
              allowClear
              style={{ width: 300 }}
              placeholder="Entrer un nom, prénom ou email"
              defaultValue={searchValue}
              onSearch={(value) => searchResource(value)}
            />
          </Col>
          <Col>
            <RangePicker picker="month" onChange={onMonthsChange} />
            <Dropdown overlay={menu}>
              <Button type="link">
                <MenuOutlined
                  style={{ fontSize: 16, color: 'var(--textColor)' }}
                />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <Table
          size="middle"
          style={{ marginTop: 24 }}
          dataSource={technicians}
          columns={columns}
          expandable={{
            expandedRowRender: (record) => expandedRowRender(record)
          }}
        />
      </ContentCustom>
    </>
  );
};

export default ListHours;
