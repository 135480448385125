import React from 'react';
import {
  G2,
  Chart,
  Geom,
  Axis,
  Tooltip,
  Coord,
  Label,
  Legend,
  View,
  Guide,
  Shape,
  Facet,
  Util
} from 'bizcharts';

const GraphQteProj = ({ graphQteData }) => {

  const cols = {
    Quantité: {
      tickInterval: 500
    }
  };

  return (
    <Chart height={300} data={graphQteData} scale={cols} forceFit>
      <Axis name="Semaine" />
      <Axis name="Quantité" />
      <Tooltip />
      <Geom type="interval" position="Semaine*Quantité" />
    </Chart>
  );
};

export default GraphQteProj;
