import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListDeposits from './ListDeposits';
import ShowDeposit from './ShowDeposit';

const DepositsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/show/:id`} component={ShowDeposit} />
      <Route path={`${path}`} component={ListDeposits} />
    </Switch>
  );
};

export default DepositsRouter;
