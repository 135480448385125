import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();
  return [
    {
      title: t('contacts.form.last_name'),
      key: 'last_name',
      dataIndex: 'last_name',
      sorter: true
    },
    {
      title: t('contacts.form.first_name'),
      key: 'first_name',
      dataIndex: 'first_name',
      sorter: true
    },
    {
      title: t('companies.form.phone_number_mobile'),
      key: 'phone_number_mobile',
      dataIndex: 'phone_number_mobile',
      sorter: true
    },
    {
      title: t('contacts.form.email'),
      key: 'email',
      dataIndex: 'email',
      sorter: true
    }
  ];
};

export default useColumns;
