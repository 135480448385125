import React from 'react';
import { Col, Row, Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import numeral from 'numeral';

const borderBottom = '1px solid var(--borderColor)';
const DetailsTotalInvoiceFooter = ({ t, isLoading, displayedResource }) => {
  return (
    <Row
      style={{
        marginTop: 50
      }}
    >
      <Col
        style={{
          background: 'var(--bodyBackground)',
          border: '1px solid var(--borderColor)',
          padding: 8
        }}
        xs={{
          offset: 8,
          span: 16
        }}
        md={{
          offset: 14,
          span: 10
        }}
      >
        <Skeleton active loading={isLoading} paragraph={0}>
          {displayedResource.deposit_invoice && (
            <Row
              style={{
                borderBottom
              }}
            >
              <Col
                span={12}
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography.Text
                  style={{
                    fontSize: '16px',
                    color: 'var(--addColor)'
                  }}
                >
                  {`${t('invoices.showInvoice.is_deposit')} (${
                    displayedResource.deposit_invoice.number
                  })`}
                </Typography.Text>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: 'right'
                }}
              >
                <Typography.Text
                  style={{
                    fontSize: '16px'
                  }}
                >
                  {`-${numeral(
                    displayedResource.deposit_invoice.subTotal || 0
                  ).format('0,0.00')} €`}
                </Typography.Text>
              </Col>
            </Row>
          )}
          <Row
            style={{
              borderBottom
            }}
          >
            <Col
              span={8}
              style={{
                textAlign: 'left'
              }}
            >
              <Typography.Text
                style={{
                  fontSize: '16px'
                }}
              >
                {`${t('invoices.showInvoice.subTotal')} `}
              </Typography.Text>
            </Col>
            <Col
              span={16}
              style={{
                textAlign: 'right'
              }}
            >
              <Typography.Text
                style={{
                  fontSize: '16px'
                }}
              >
                {`${numeral(displayedResource.subTotal || 0).format(
                  '0,0.00'
                )}€`}
              </Typography.Text>
            </Col>
          </Row>
          <Row
            style={{
              borderBottom
            }}
          >
            <Col
              span={8}
              style={{
                textAlign: 'left'
              }}
            >
              <Typography.Text
                style={{
                  fontSize: '16px'
                }}
              >
                {`${t('invoices.showInvoice.vat')} (${displayedResource?.vat
                  ?.rate || ''} %)`}
              </Typography.Text>
            </Col>
            <Col
              span={16}
              style={{
                textAlign: 'right'
              }}
            >
              <Typography.Text
                style={{
                  fontSize: '16px'
                }}
              >
                {`${numeral(displayedResource.total_VAT || 0).format(
                  '0,0.00'
                )}€`}
              </Typography.Text>
            </Col>
          </Row>
          {displayedResource.holdback !== 0 && (
            <Row
              style={{
                borderBottom
              }}
            >
              <Col
                span={12}
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography.Text
                  style={{
                    fontSize: '16px'
                  }}
                >
                  {t('invoices.showInvoice.holdback')}
                </Typography.Text>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: 'right'
                }}
              >
                <Typography.Text
                  style={{
                    fontSize: '16px'
                  }}
                >
                  {`${numeral(displayedResource.holdback || 0).format(
                    '0,0.00'
                  )} %`}
                </Typography.Text>
              </Col>
            </Row>
          )}
          {displayedResource.prorata_account !== 0 && (
            <Row
              style={{
                borderBottom
              }}
            >
              <Col
                span={12}
                style={{
                  textAlign: 'left'
                }}
              >
                <Typography.Text
                  style={{
                    fontSize: '16px'
                  }}
                >
                  {t('invoices.showInvoice.prorata_account')}
                </Typography.Text>
              </Col>
              <Col
                span={12}
                style={{
                  textAlign: 'right'
                }}
              >
                <Typography.Text
                  style={{
                    fontSize: '16px'
                  }}
                >
                  {`${numeral(displayedResource.prorata_account || 0).format(
                    '0,0.00'
                  )} €`}
                </Typography.Text>
              </Col>
            </Row>
          )}
          <Row>
            <Col
              span={8}
              style={{
                textAlign: 'left'
              }}
            >
              <Typography.Text
                style={{
                  fontSize: '16px'
                }}
              >
                {t('invoices.showInvoice.discount')}
              </Typography.Text>
            </Col>
            <Col
              span={16}
              style={{
                textAlign: 'right'
              }}
            >
              <Typography.Text
                style={{
                  fontSize: '16px'
                }}
              >
                {`${numeral(displayedResource.total_discount || 0).format(
                  '0,0.00'
                )}€`}
              </Typography.Text>
            </Col>
          </Row>
        </Skeleton>
      </Col>
    </Row>
  );
};

DetailsTotalInvoiceFooter.propTypes = {
  t: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  displayedResource: PropTypes.oneOfType([PropTypes.object])
};
DetailsTotalInvoiceFooter.defaultProps = {
  displayedResource: null
};

export default DetailsTotalInvoiceFooter;
