import moment from 'moment';
import React from 'react';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import useRows from './useRows';
import TableCell from './dndTable';
import useInterventionContext from './InterventionContext';
import poids from '../../../assets/images/poids.png';
import metrecarre from '../../../assets/images/metrecarre.png';

const useColumns = () => {
  const {
    plannedMove,
    isLoading,
    setIsLoading,
    start,
    setStart,
    begin,
    movesPlanned
  } = useInterventionContext();

  const rows = useRows(begin);

  const listmoves = movesPlanned.filter(
    (mp) => moment(mp.date).week() === start.week()
  );

  const columns = [
    {
      title: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          <LeftCircleOutlined
            style={{ fontSize: 30, float: 'left' }}
            onClick={() => setStart(moment(start).subtract(7, 'days'))}
          />
          {`Semaine ${start.week()}`}
        </div>
      ),
      dataIndex: 'trucks',
      width: 130,
      render: (truck) => {
        let totalAreaPerWeek = 0;
        const areas = listmoves
          .filter((element) => element.truck === truck._id)
          ?.map(({ prestas }) => prestas.map(({ area }) => area));

        areas.forEach((item) => {
          item.forEach((value) => {
            totalAreaPerWeek += parseFloat(value);
          });
        });

        const totalWeightPerWeek = listmoves
          .filter((element) => element.truck === truck._id)
          .map(({ prestas }) =>
            prestas.map(({ area, thickness }) =>
              area !== undefined && thickness !== undefined
                ? area * thickness * 0.42
                : 0
            )
          );
        let tot = 0;
        for (let i = 0; i < totalWeightPerWeek?.length; i += 1) {
          for (let j = 0; j < totalWeightPerWeek[i].length; j += 1) {
            tot += Number(totalWeightPerWeek[i][j]);
          }
        }

        return (
          <>
            <h4>{truck.label.toUpperCase()}</h4>
            {truck.users &&
              truck.users.map((user) => (
                <div key={user._id} style={{ fontStyle: 'italic' }}>
                  {`${user.first_name} ${user.last_name}`}
                </div>
              ))}
            <br />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100px',
                justifyContent: 'space-around',
                alignItems: 'flex-start',
                margin: 'auto',
                width: '50%'
              }}
            >
              <div
                style={{
                  color: totalAreaPerWeek > 600 ? 'var(--secondaryColor)' : ''
                }}
              >
                <img
                  src={metrecarre}
                  alt="mettrecarre"
                  style={{ width: '30px', marginRight: '5px', opacity: '0.5' }}
                />
                {`${totalAreaPerWeek} m²`}
              </div>
              <div
                style={{
                  color: tot > 2000 ? 'var(--secondaryColor)' : ''
                }}
              >
                <img
                  src={poids}
                  alt="poids"
                  style={{ width: '30px', marginRight: '5px', opacity: '0.5' }}
                />
                {`${tot.toFixed(2)} kg`}
              </div>
            </div>
          </>
        );
      },
      align: 'center'
    },
    {
      title: moment(start).format('dddd Do MMMM YYYY'),
      dataIndex: moment(start).format('YYYY-MM-DD'),
      width: 150,
      align: 'center',
      render: (el) => ({
        props: {
          style: {
            padding: 0,
            margin: 0,
            height: 60
          }
        },
        children: (
          <TableCell
            key={el}
            day={moment(start)}
            plannedMove={plannedMove}
            el={el}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )
      })
    },
    {
      title: moment(start)
        .add(1, 'days')
        .format('dddd Do MMMM YYYY'),
      dataIndex: moment(start)
        .add(1, 'days')
        .format('YYYY-MM-DD'),
      width: 150,
      align: 'center',
      render: (el) => ({
        props: {
          style: {
            padding: 0,
            margin: 0,
            height: 60
          }
        },
        children: (
          <TableCell
            day={moment(start).add(1, 'days')}
            start={el}
            plannedMove={plannedMove}
            el={el}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )
      })
    },
    {
      title: moment(start)
        .add(2, 'days')
        .format('dddd Do MMMM YYYY'),
      dataIndex: moment(start)
        .add(2, 'days')
        .format('YYYY-MM-DD'),
      width: 150,
      align: 'center',
      render: (el) => ({
        props: {
          style: {
            padding: 0,
            margin: 0,
            height: 60
          }
        },
        children: (
          <TableCell
            day={moment(start).add(2, 'days')}
            start={el}
            plannedMove={plannedMove}
            el={el}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )
      })
    },
    {
      title: moment(start)
        .add(3, 'days')
        .format('dddd Do MMMM YYYY'),
      dataIndex: moment(start)
        .add(3, 'days')
        .format('YYYY-MM-DD'),
      width: 150,
      align: 'center',
      render: (el) => ({
        props: {
          style: {
            padding: 0,
            margin: 0,
            height: 60
          }
        },
        children: (
          <TableCell
            day={moment(start).add(3, 'days')}
            start={el}
            plannedMove={plannedMove}
            el={el}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )
      })
    },
    {
      title: (
        <div>
          {moment(start)
            .add(4, 'days')
            .format('dddd Do MMMM YYYY')}
        </div>
      ),
      dataIndex: moment(start)
        .add(4, 'days')
        .format('YYYY-MM-DD'),
      width: 150,
      align: 'center',
      render: (el) => ({
        props: {
          style: {
            padding: 0,
            margin: 0,
            height: 60
          }
        },
        children: (
          <TableCell
            day={moment(start).add(4, 'days')}
            start={el}
            plannedMove={plannedMove}
            el={el}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )
      })
    },
    {
      title: (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center'
          }}
        >
          {moment(start)
            .add(5, 'days')
            .format('dddd Do MMMM YYYY')}

          <RightCircleOutlined
            style={{ fontSize: 30, float: 'right' }}
            onClick={() => setStart(moment(start).add(7, 'days'))}
          />
        </div>
      ),
      dataIndex: moment(start)
        .add(5, 'days')
        .format('YYYY-MM-DD'),
      width: 150,
      align: 'center',
      render: (el) => ({
        props: {
          style: {
            padding: 0,
            margin: 0,
            height: 60
          }
        },
        children: (
          <TableCell
            day={moment(start).add(5, 'days')}
            start={el}
            plannedMove={plannedMove}
            el={el}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        )
      })
    }
  ];

  return {
    columns,
    rows
  };
};

export default useColumns;
