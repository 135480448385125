import {
  Button,
  Col,
  Modal,
  Row,
  Steps,
  Divider,
  DatePicker,
  message
} from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import Dragger from 'antd/lib/upload/Dragger';
import useAuthContext from '../../../contexts/AuthContext';

const { Step } = Steps;

const ValidateQuotationModal = ({
  setShowModal,
  showModal,
  quotationId,
  getResource,
  resource,
  downloadFile,
  validateQuotation
}) => {
  const { t } = useTranslation();
  const { token, dispatchAPI } = useAuthContext();
  const [selectedDate, setSelectedDate] = useState();
  const [uploadedID, setUploadedID] = useState('');
  const deleteFileToQuotation = async (idFile) => {
    try {
      await dispatchAPI('GET', {
        url: `/quotations/file-quotation/${idFile}`
      });
      getResource();
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };
  const props = {
    name: 'file',
    multiple: false,
    // disabled,
    action: `${process.env.REACT_APP_API_URL}/files/quotations`,
    data: {
      type: 'signed_quotation'
    },
    headers: { Authorization: `Bearer ${token}` },

    onChange(info) {
      const { status } = info.file;
      if (info.file.response) {
        setUploadedID(info.file.response.description._id);
      }

      if (status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    showUploadList: {
      showRemoveIcon: false
    }
  };
  const [current, setCurrent] = useState(0);
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const disabledDate = (curr) => {
    return curr && curr < moment().startOf('week');
  };
  const steps = [
    {
      title: 'Importer le devis signé',
      content: (
        <>
          <Row justify="space-between">
            <Col span={8} offset={1}>
              <p>Veuillez rajouter le devis signé par le client.</p>
              {resource?.signed_quotation_file &&
                resource?.signed_quotation_file !== null && (
                  <Row justify="space-between">
                    <Button
                      type="link"
                      onClick={() =>
                        downloadFile(`/files/${resource.signed_quotation_file}`)
                      }
                    >
                      Un document existe déjà !
                    </Button>
                    <Button
                      onClick={() =>
                        deleteFileToQuotation(resource.signed_quotation_file)
                      }
                      type="danger"
                      icon={<DeleteOutlined />}
                    />
                  </Row>
                )}
              <div style={{ width: 200, height: 150 }}>
                {resource && !resource?.signed_quotation_file ? (
                  <Dragger {...props}>
                    <InboxOutlined />

                    <p style={{ fontWeight: 'bold' }}>
                      {t('files.create.action')}
                    </p>
                    <p>{t('quotations.show.upload.formats')}</p>
                  </Dragger>
                ) : null}
              </div>
            </Col>
            <Col style={{ marginTop: 80 }} span={2}>
              <span style={{ fontWeight: 'bold' }}>Ou</span>
            </Col>
            <Col style={{ marginTop: 30 }} span={8} offset={1}>
              <Button
                onClick={() => next()}
                type="ghost"
                style={{ marginTop: 50 }}
              >
                Ignorer cette étape...
              </Button>
            </Col>
          </Row>
        </>
      )
    },
    {
      title: 'Planifier intervention',
      content:
        resource &&
        (resource.status === 'REQUEST' || resource.status === 'CANCELED') ? (
          <>
            <p>{`Veuillez selectionner une semaine souhaitée pour l'intervention ${resource?.site?.reference}.`}</p>
            <DatePicker
              size="large"
              picker="week"
              style={{ margin: 'auto', width: '100%' }}
              disabledDate={disabledDate}
              onChange={(date) => setSelectedDate(date)}
            />
            <Divider />
          </>
        ) : (
          <div style={{ margin: 50, textAlign: 'center' }}>
            Intervention planifiée
          </div>
        )
    }
  ];

  const addSignedFileToQuotation = async (validatedFile) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/quotations/${quotationId}`,
        body: { signed_quotation_file: validatedFile }
      });
    } catch (e) {
      if (e.response) message.error(e.response.status);
    }
  };

  const title = (
    <div style={{ display: 'flex', textAlign: 'center' }}>
      <p style={{ margin: 'auto 0' }}>{t('quotations.show.upload.title')}</p>
    </div>
  );

  useEffect(() => {
    if (uploadedID) {
      addSignedFileToQuotation(uploadedID);
      getResource();
    }
  }, [uploadedID]);

  return (
    <Modal
      title={title}
      visible={showModal}
      width={800}
      destroyOnClose
      onCancel={() => setShowModal(false)}
      footer={false}
    >
      <Steps current={current}>
        {steps.map((item) => (
          <Step key={item.title} title={item.title} />
        ))}
      </Steps>
      <div>{steps[current].content}</div>
      <Row justify="end">
        {current < steps.length - 1 && (
          <Button type="primary" onClick={() => next()}>
            Suivant
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            disabled={!selectedDate || resource?.status === 'VALIDATE'}
            onClick={() => {
              validateQuotation(
                resource,
                quotationId,
                selectedDate,
                'VALIDATE'
              );
              setShowModal(!showModal);
            }}
          >
            Valider le devis
          </Button>
        )}
        {current > 0 && (
          <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
            Précédent
          </Button>
        )}
      </Row>
    </Modal>
  );
};

ValidateQuotationModal.propTypes = {
  setShowModal: PropTypes.func,
  validateQuotation: PropTypes.func,
  quotationId: PropTypes.string,
  showModal: PropTypes.bool,
  getResource: PropTypes.func.isRequired,

  resource: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  downloadFile: PropTypes.func.isRequired
};

ValidateQuotationModal.defaultProps = {
  setShowModal: null,
  validateQuotation: null,
  quotationId: null,
  showModal: null
};

export default ValidateQuotationModal;
