import React, { useEffect, useState } from 'react';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { ContentCustom } from '../../../components';
import { Table } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import useColumns from './interventionColumns';
import useErrorMessage from '../../../utils/ErrorMessage';

const DetailsMonth = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { dispatchAPI } = useAuthContext();
    const columns = useColumns(id.split('?')[0]);
    const location = useLocation();
    const { message: errorMessage } = useErrorMessage();
    const params = new URLSearchParams(location.search);
    const [interventions, setInterventions] = useState([])
    const month = params.get('month');

    const getInterventions = async () => {
        try {
            const { data } = await dispatchAPI('GET', {
                url: `/hours-report/get-details-month?month=${month ? month : id.split('?')[1].split('=')[1]}&tech=${id.split('?')[0]}`
            });
            setInterventions(data.map(el => ({
                ...el,
                key: el._id
            })).sort((a,b) => new Date(a.date) - new Date(b.date)));
        } catch (e) {
            if (e.response) errorMessage(e.response.status);
        }
    };

    useEffect(() => {
        (async () => {
          await getInterventions();
        })();
    }, [month]);

    return (
        <>
            <PageHeaderCustom
                title={t('movements.manage_hours.show.title', {
                    title: month ? month : id.split('?')[1].split('=')[1],
                })}
            />
            <ContentCustom>
                <Table scroll={{ x: true }} dataSource={interventions} columns={columns} />
            </ContentCustom>
        </>
    )
};

export default DetailsMonth;