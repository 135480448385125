import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import useAuthContext from '../../../contexts/AuthContext';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('articles.column.wording'),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    }
  ];
};

export default useColumns;
