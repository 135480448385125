import React from 'react';
import { Col, Row, Timeline, Typography } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import QuotePart from './QuotePart';

const { Title } = Typography;
const TimeLineInvoice = ({ t, displayedResource, resource }) => {
  return (
    <Row jusity="center">
      <Col span={12}>
        <Title level={3}>
          {t(`${resource}.show.title`)}
          &nbsp;
          {displayedResource.subject}
        </Title>
        {displayedResource.comment && (
          <i>
            {t(`${resource}.show.comment`)}
            &nbsp;
            {displayedResource.comment}
          </i>
        )}
        <Timeline>
          <Timeline.Item>
            {t(`${resource}.show.created_at`, {
              date: moment(displayedResource.created_at).format('LL')
            })}
          </Timeline.Item>
          {displayedResource.emission_date && (
            <Timeline.Item>
              {t(`${resource}.show.emission`, {
                date: moment(displayedResource.emission_date).format('LL')
              })}
            </Timeline.Item>
          )}
          {displayedResource.deadline_date && (
            <Timeline.Item>
              {t(`${resource}.show.deadline`, {
                date: moment(displayedResource.deadline_date).format('LL')
              })}
            </Timeline.Item>
          )}
          {displayedResource.payment_date && (
            <Timeline.Item>
              {t(`${resource}.show.payment`, {
                date: moment(displayedResource.payment_date).format('LL')
              })}
            </Timeline.Item>
          )}
          {displayedResource.total_paid &&
          displayedResource.total_paid !== displayedResource.total ? (
            <Timeline.Item>
              {displayedResource.total_paid &&
              displayedResource.total_paid !== displayedResource.total
                ? t(`${resource}.show.remaining`, {
                    amount:
                      displayedResource.total - displayedResource.total_paid
                  })
                : ''}
            </Timeline.Item>
          ) : (
            ''
          )}
          {displayedResource.type === 'CREDIT' &&
            displayedResource.email_sent && (
              <Timeline.Item>
                {`Attribué le ${moment(displayedResource.emission_date).format(
                  'LL'
                )}`}
              </Timeline.Item>
            )}
        </Timeline>
      </Col>
      {displayedResource.quotation && <QuotePart quote={displayedResource} />}
    </Row>
  );
};

TimeLineInvoice.propTypes = {
  t: PropTypes.func.isRequired,
  displayedResource: PropTypes.oneOfType([PropTypes.object]),
  resource: PropTypes.string
};
TimeLineInvoice.defaultProps = {
  displayedResource: [],
  resource: null
};
export default TimeLineInvoice;
