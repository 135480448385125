import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('bank.name'),
      dataIndex: 'name'
    },
    {
      title: t('bank.address'),
      dataIndex: 'address'
    },
    {
      title: t('bank.city'),
      dataIndex: 'city'
    },
    {
      title: t('bank.phone_number'),
      dataIndex: 'phone_number'
    }
  ];
};

export default useColumns;
