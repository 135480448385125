import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Select,
  Typography,
  Row,
  Switch,
  InputNumber
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { drawerFormLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';

const { Title } = Typography;
const { Option } = Select;

const NewSupplierDrawer = ({
  isSupplierDrawerVisible,
  setVisibleSupplierDrawer,
  getSuppliers,
  suppliers
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldLoading, setIsFieldLoading] = useState(true);
  const [isProfessional, setIsProfessional] = useState(true);
  const [form] = Form.useForm();
  const [enums, setEnums] = useState([]);
  const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;

  const cancelButton = () => {
    setVisibleSupplierDrawer(false);
  };

  const onFinish = async (values) => {
    try {
      const isInBDD = suppliers.find(
        (c) =>
          c.phone_number_mobile === values.phone_number_mobile &&
          c.email === values.email
      );
      if (!isInBDD) {
        await dispatchAPI('POST', {
          url: '/companies',
          body: {
            ...values,
            professional: isProfessional,
            status: 'CUSTOMERS',
            type: 'SUPPLIERS'
          }
        });
        message.success(t('suppliers.message.success.new'));
        setVisibleSupplierDrawer(false);
        await getSuppliers();
      } else {
        message.error(t('companies.message.error.already_exist'));
      }
    } catch (e) {
      message.error(t('companies.message.error'));
    }
  };
  const onFinishFailed = (error) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', error);
    message.error(t('sites.message.error'));
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies/enums' });
      setEnums(data);
      form.current.setFieldsValue({
        status: data?.status?.filter((el) => el === 'CUSTOMERS')[0]
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      setIsFieldLoading(true);
      await getEnums();
      await getSuppliers();
      setIsFieldLoading(false);
    })();
  }, []);

  return (
    <>
      <Drawer
        visible={isSupplierDrawerVisible}
        onClose={() => {
          setVisibleSupplierDrawer();
        }}
        width="40%"
      >
        <Col style={{ width: '100%' }}>
          <Title level={3}>{t(`suppliers.form.title.create`)}</Title>
        </Col>
        <Form
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...drawerFormLayout}
          name="test"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ width: '100%' }}
          form={form}
        >
          <Switch
            checkedChildren={t(`companies.switch.professional`)}
            unCheckedChildren={t(`companies.switch.individual`)}
            checked={isProfessional}
            onClick={() => setIsProfessional(!isProfessional)}
            style={{ margin: '5% 0' }}
          />

          {isProfessional ? (
            <>
              <Form.Item
                label={t('companies.form.name')}
                name="name"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez remplir ce champ'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('companies.form.legal_form')}
                name="legal_form"
                style={{ width: '100%' }}
              >
                <Select loading={isFieldLoading}>
                  {(enums.legalForms || []).map((legalform) => (
                    <Select.Option key={legalform} value={legalform}>
                      {t(`companies.legalForms.${legalform}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item
                label={t('companies.form.siret_number')}
                name="siret_number"
              >
                <Input style={{ width: '100%' }} />
              </Form.Item>

              <Form.Item label={t('companies.form.ape')} name="ape_number">
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
              <br />
              <Form.Item
                label={t('customers.form.gender')}
                name="gender"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez remplir ce champ'
                  }
                ]}
              >
                <Select>
                  {(enums.genders || []).map((title) => (
                    <Option key={title} value={title}>
                      {t(`contacts.form.${title}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('customers.form.last_name')}
                name="last_name"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez remplir ce champ'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('customers.form.first_name')}
                name="first_name"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez remplir ce champ'
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </>
          ) : (
            <>
              <Form.Item
                label={t('customers.form.gender')}
                name="gender"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez remplir ce champ'
                  }
                ]}
              >
                <Select>
                  {(enums.genders || []).map((title) => (
                    <Option key={title} value={title}>
                      {t(`contacts.form.${title}`)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                label={t('customers.form.last_name')}
                name="name"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez remplir ce champ'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('customers.form.first_name')}
                name="first_name"
                style={{ width: '100%' }}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez remplir ce champ'
                  }
                ]}
              >
                <Input />
              </Form.Item>
              <br />
            </>
          )}
          <Form.Item
            label={t('customers.form.email')}
            name="email"
            style={{ width: '100%' }}
            rules={[
              { required: true },
              () => ({
                validator(_, value) {
                  if (value) {
                    if (mailformat.test(value)) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Addresse invalide'));
                  }
                  return Promise.resolve();
                }
              })
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('customers.form.phone_number_mobile')}
            name="phone_number_mobile"
            style={{ width: '100%' }}
            rules={[
              {
                required: true,
                message: 'Veuillez remplir ce champ'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('customers.form.phone_number_office')}
            name="phone_number_office"
          >
            <Input />
          </Form.Item>
          <br />

          <Form.Item
            label={t('companies.form.address_details_1')}
            name="address_details_1"
            rules={[
              {
                required: true,
                message: 'Veuillez remplir ce champ'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('companies.form.address_details_2')}
            name="address_details_2"
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('companies.form.postal_code')}
            name="postal_code"
            rules={[
              {
                required: true,
                message: 'Veuillez remplir ce champ'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t('companies.form.city')}
            name="city"
            rules={[
              {
                required: true,
                message: 'Veuillez remplir ce champ'
              }
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="primary"
              htmlType="submit"
            >
              {t('buttons.save')}
              <CheckOutlined />
            </Button>
            <Button onClick={cancelButton}>
              {`${t('buttons.cancel')}`}
              <CloseOutlined />
            </Button>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

NewSupplierDrawer.propTypes = {
  isSupplierDrawerVisible: PropTypes.bool.isRequired,
  setVisibleSupplierDrawer: PropTypes.func.isRequired,
  getSuppliers: PropTypes.func.isRequired,
  suppliers: PropTypes.oneOfType([PropTypes.array]).isRequired
};

export default NewSupplierDrawer;
