import React from 'react';
import { Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useAccountingContext from '../../AccountingContext';
import { routes, subRoutes } from '../../../../utils/constants/routes';

const Container = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  minheight: 150px;
`;
const { Title } = Typography;

const Customer = ({ isLoading, customer, resource }) => {
  const { t } = useTranslation();
  const { collection } = useAccountingContext();

  return (
    <Container style={{ marginBottom: 10 }}>
      <Skeleton loading={isLoading} paragraph={{ rows: 1, width: '50%' }}>
        {customer && (
          <>
            <Title level={5} style={{ marginBottom: '0', color: 'lightgrey' }}>
              {t(`${collection || resource}.show.customer`)}
            </Title>
            <Title level={3} style={{ marginTop: '0' }}>
              <Link
                style={{ color: 'var(--addColor)' }}
                to={{
                  pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CUSTOMERS}/show/${customer._id}`
                }}
              >
                {customer?.professional
                  ? customer.name
                  : `${customer.first_name} ${customer.name}`}
              </Link>
            </Title>
            {customer.address_details_1}
            {customer.address_details_2}
            <br />
            {customer.postal_code} {customer.city}
          </>
        )}
      </Skeleton>
    </Container>
  );
};

Customer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  resource: PropTypes.string,
  customer: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

Customer.defaultProps = {
  resource: undefined,
  customer: null
};

export default Customer;
