import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Select, Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Option } = Select;

const useFields = (selectedCompany) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [enums, setEnums] = useState({});
  const [isRequired, setIsRequired] = useState();

  const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data,
        recurrency_contact: isRequired,
        my_company: id !== undefined ? id : selectedCompany._id
      })
    },
    onGetResource: {
      setFields: (data) => ({
        ...data,
        recurrency_contact: isRequired
      })
    },
    onUpdateResource: {
      setBody: (data) => ({ ...data, recurrency_contact: isRequired })
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/contacts/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fetchData = useCallback(async () => {
    setIsFieldsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contacts/${id}`
      });
      setIsRequired(data.recurrency_contact);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsFieldsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      if (id !== undefined) await fetchData();
    })();
  }, [fetchData]);

  const fields = [
    {
      name: ['gender'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.genders || []).map((title) => (
            <Option key={title} value={title}>
              {t(`contacts.form.${title}`)}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['last_name'],
      rules: [{ required: true }]
    },

    {
      name: ['first_name'],
      rules: [{ required: true }]
    },
    {
      name: ['email'],
      rules: [
        { required: true },
        () => ({
          validator(_, value) {
            if (value) {
              if (mailformat.test(value)) {
                return Promise.resolve();
              }
              return Promise.reject(new Error('Addresse invalide'));
            }
            return Promise.resolve();
          }
        })
      ]
    },
    {
      name: ['phone_number_mobile'],
      rules: [{ required: true }]
    },
    {
      name: ['phone_number_office']
    },
    {
      name: ['role']
    },
    {
      name: ['recurrency_contact'],
      input: (
        <Checkbox
          onChange={() => setIsRequired(!isRequired)}
          checked={isRequired}
        />
      )
    },
    {
      name: ['particularities']
    }
  ];

  return {
    fields,
    isFieldsLoading,
    config
  };
};

export default useFields;
