import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListMovementsStock from './ListMovementsStock';
import CreateUpdateMovementStock from './CreateUpdateMovementStock';
import { MovementStockContextProvider } from './MovementStockContext';
import ShowMovementStock from './ShowMovementStock';

const MovementsStockRouter = () => {
  const { path } = useRouteMatch();

  return (
    <MovementStockContextProvider>
      <Switch>
        <Route path={`${path}/show/:id`} component={ShowMovementStock} />
        <Route
          path={`${path}/exit/:id/:label/:inventory/:comments`}
          render={() => <CreateUpdateMovementStock purpose="exit" />}
        />
        <Route
          path={`${path}/enter/:id/:label/:inventory/:comments`}
          render={() => <CreateUpdateMovementStock purpose="enter" />}
        />
        <Route
          path={`${path}/inventory-transfer/:id/:sid/:label/:inventory/:comments`}
          render={() => (
            <CreateUpdateMovementStock purpose="inventory-transfer" />
          )}
        />
        <Route path={`${path}`} component={ListMovementsStock} />
      </Switch>
    </MovementStockContextProvider>
  );
};

export default MovementsStockRouter;
