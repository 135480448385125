import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Drawer, Form, Spin } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import useFields from './fields';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useDealContext from './DealsContext';

const EditDrawer = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { dispatchAPI, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { message } = useErrorMessage();
  const generateFormItem = useGenerateFormItem();
  const {
    visible,
    setVisible,
    deal,
    dataRefresh,
    setDataRefresh,
    fetchDealById
  } = useDealContext();
  const { fields, isFieldsLoading } = useFields(visible);

  const onClose = () => {
    form.resetFields();
    if (deal !== null) fetchDealById(deal._id);
    setVisible(false);
    setDataRefresh(!dataRefresh);
  };

  const onFinishForm = async (values) => {
    try {
      await dispatchAPI(deal ? 'PATCH' : 'POST', {
        url: `/deals${deal ? `/${deal._id}` : ''}`,
        body: values
      });
      onClose();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchDeal = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deals/${deal._id}`
      });
      form.setFieldsValue(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [deal, isFieldsLoading]);

  useEffect(() => {
    if (deal && !isFieldsLoading)
      (async () => {
        await fetchDeal();
      })();
  }, [fetchDeal]);

  useEffect(() => {
    form.setFieldsValue({ lead: user._id });
  }, [user, dataRefresh]);

  return (
    <Drawer
      destroyOnClose
      title={t(`deals.show.${deal ? 'edit' : 'add'}`)}
      width={480}
      onClose={onClose}
      visible={visible}
      getContainer={false}
    >
      <Spin spinning={isLoading}>
        <Form
          wrapperCol={{ span: 24 }}
          labelCol={{ span: 24 }}
          form={form}
          onFinish={onFinishForm}
        >
          {fields.map((field) => generateFormItem('deals', field))}
          <Form.Item style={{ marginTop: 16 }}>
            <Button
              style={{ margin: '0 10px' }}
              type="link"
              danger
              onClick={onClose}
            >
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
            <Button type="add" htmlType="submit">
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
};

export default EditDrawer;
