import React from 'react';
import { Table, Spin } from 'antd';
import useColumns from './columns';
import ListMoves from './list';
import WeekNumber from './WeekNumber';
import useInterventionContext from './InterventionContext';

const DailyTable = () => {
  const { columns, rows } = useColumns();
  const { loading } = useInterventionContext();

  return (
    <>
      <Spin spinning={loading}>
        <div>
          <ListMoves />
        </div>
        <WeekNumber />
        <div style={{ padding: 8, margin: 'auto' }}>
          <Table
            bordered
            columns={columns}
            dataSource={rows}
            pagination={false}
            tableLayout="fixed"
            sticky
            scroll={{ x: 1200 }}
          />
        </div>
      </Spin>
    </>
  );
};

export default DailyTable;
