import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Skeleton, Space } from 'antd';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useFields from './fields';
import QuotationForm from './QuotationForm';
import useQuotationContext from './QuotationContext';

const CreateUpdateQuotation = ({ purpose }) => {
  const history = useHistory();
  const { id, idSite } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const {
    contact,
    company,
    prestationsList,
    setPrestationsList,
    setSites,
    setContact,
    setCompany,
    setVat
  } = useQuotationContext();
  const [isLoading, setIsLoading] = useState(false);
  const [quotation, setQuotation] = useState({});

  const { createFields, editFields, fields2, headerFields } = useFields(
    purpose,
    purpose === 'create' && idSite
  );
  const [prestationsAvailable, setPrestations] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [settings, setSettings] = useState([]);
  const [enums, setEnums] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const updateQuotation = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/quotations/${id}`,
        body: { ...body, customer: company._id, contact: contact._id }
      });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createQuotation = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: '/quotations',
        body: { ...body, customer: company._id, contact: contact._id }
      });
      history.replace(`/commercial/quotations/show/${data._id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getPrestations = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prestations' });
      setPrestations(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSites = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/sites?archived=false'
      });
      setSites(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSettings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?type=ACCOUNTING'
      });
      setSettings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/quotations/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getQuotation = useCallback(async () => {
    setIsLoading(true);
    await getPrestations();
    await getCompanies();
    await getSettings();
    await getSites();
    await getEnums();
    if (purpose === 'edit' && id) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/quotations/${id}?populate=customer,site,contact,prestations.prestation,organization,vat`
        });
        const { prestations, ...rest } = data;
        setQuotation({
          ...rest,
          emission_date: data.emission_date && moment(data.emission_date),
          deadline_date: data.deadline_date && moment(data.deadline_date),
          vat: data.vat ? data.vat._id : undefined,
          global_discount_type: data.global_discount
            ? data.global_discount.type
            : 'CURRENCY',
          global_discount_value: data.global_discount
            ? data.global_discount.value
            : 0,
          global_discount_comment: data.global_discount
            ? data.global_discount.comment
            : '',
          customer: data.customer && data.customer.name,
          address_details_1: data.customer && data.customer.address_details_1,
          address_details_2: data.customer && data.customer.address_details_2,
          postal_code: data.customer && data.customer.postal_code,
          city: data.customer && data.customer.city,
          site: data.site && data.site._id,
          organization: data.organization && data.organization._id,
          contact:
            data.contact && `${data.contact.first_name} ${data.contact.name}`,
          status: data.status
        });
        setContact(data.contact);
        setCompany(data.customer);
        setVat(data.vat);
        setPrestationsList(
          prestations.map(
            ({
              prestation: { _id, price },

              label,
              area,
              unit_price,
              discount,
              comment
            }) => {
              const discountValue = discount ? discount.value : 0;
              const discountType = discount ? discount.type : 'CURRENCY';
              let lineDiscount = 0;

              if (discountValue > 0) {
                lineDiscount =
                  discountType === 'CURRENCY'
                    ? discountValue
                    : (unit_price * area * discountValue) / 100;
              }

              return {
                label,
                area,

                unit_price:
                  price?.[data.customer.professional ? 'proPrice' : 'partPrice']
                    ?.unitaire,
                forfait_price:
                  price?.[data.customer.professional ? 'proPrice' : 'partPrice']
                    ?.forfait,
                price: unit_price,
                prestationId: _id,
                discount_type: discountType,
                discount: discountValue,
                subtotal: unit_price * area - lineDiscount,
                comment
              };
            }
          )
        );
      } catch (e) {
        if (e.response) message(e.response.status);
      }
    }
    if (purpose === 'create' && history.location.state) {
      if (history.location.state.newlyCreated) {
        const site = history.location.state.newlyCreated;
        setQuotation({
          site: site && site._id
        });
      }
    }
    setIsLoading(false);
  }, [purpose, id]);

  useEffect(() => {
    getQuotation();
  }, [getQuotation, purpose, id]);

  useEffect(() => {
    if (
      history.location.search &&
      history.location.search.split('?')[1].split('=')[0] === 'customer' &&
      purpose !== 'edit'
    ) {
      const search = history.location.search.split('?')[1];
      const params = search.split('&');
      const customer = companies.find((c) => c._id === params[0].split('=')[1]);
      setQuotation({
        customer: customer ? customer._id : null,
        address_details_1: customer ? customer.address_details_1 : null,
        address_details_2: customer ? customer.address_details_2 : null,
        postal_code: customer ? customer.postal_code : null,
        city: customer ? customer.city : null,
        contact: contact ? contact._id : null
      });
    }
  }, [companies]);

  const handleSubmit = async (values) => {
    const comments = {
      author: JSON.parse(localStorage.getItem('user'))._id,
      date: new Date(),
      status: values.status,
      comment: values.comment
    };
    if (!values.comment) values.comments = comments;

    const formattedPrestations = [];

    let global_discount = null;

    Object.values(values.prestations).map((element) => {
      formattedPrestations.push({
        ...element,
        discount: element.discount
          ? {
              type: element.discount_type,
              value: element.discount
            }
          : null
      });
    });
    if (values.global_discount_value) {
      global_discount = {
        type: values.global_discount_type,
        value: values.global_discount_value,
        comment: values.global_discount_comment
      };
    }

    const formattedValues = {
      ...values,
      global_discount,
      prestations: formattedPrestations,
      organization: user.organization._id
    };

    if (purpose === 'edit') await updateQuotation(formattedValues);
    if (purpose === 'create') await createQuotation(formattedValues);
  };

  const addPrestation = (prestationId) => {
    let prestation = {};
    const tmp = prestationsList;
    const isInList = tmp.find((a) => a.prestationId === prestationId);

    if (!isInList) {
      prestationsAvailable.map((element) => {
        if (element._id === prestationId) {
          prestation = element;
        }
      });
      tmp.push({
        prestationId,
        label: prestation.label,
        thickness: prestation.thickness,
        unit_price:
          prestation?.price?.[company.professional ? 'proPrice' : 'partPrice']
            ?.unitaire,
        forfait_price:
          prestation?.price?.[company.professional ? 'proPrice' : 'partPrice']
            ?.forfait,
        subtotal: prestation.sold_price
      });
      setPrestationsList(tmp);
    }
    setIsModalVisible(false);
  };

  const deletePrestation = (key) => {
    setPrestationsList(
      prestationsList.filter(({ prestationId }) => prestationId !== key)
    );
  };

  return (
    <>
      <PageHeaderCustom title={t(`quotations.form.title.${purpose}`)} />
      <ContentCustom>
        {isLoading ? (
          <Space direction="vertical" style={{ width: '100%' }} align="center">
            {[
              ...createFields,
              ...editFields,
              ...fields2,
              ...prestationsList
            ].map((f) => (
              <Skeleton.Input key={`${f.name}`} style={{ width: 300 }} active />
            ))}
          </Space>
        ) : (
          <>
            <QuotationForm
              purpose={purpose}
              resource="quotations"
              isLoading={isLoading}
              initialValues={quotation}
              handleSubmit={handleSubmit}
              fields1={purpose === 'edit' ? editFields : createFields}
              fields2={fields2}
              headerFields={headerFields}
              contact={contact}
              settings={settings}
              enums={enums}
              prestations={prestationsList}
              prestationsAvailable={prestationsAvailable}
              addPrestation={addPrestation}
              deletePrestation={deletePrestation}
              isModalVisible={isModalVisible}
              setIsModalVisible={setIsModalVisible}
            />
          </>
        )}
      </ContentCustom>
    </>
  );
};

CreateUpdateQuotation.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateQuotation;
