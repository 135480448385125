import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Divider, Popconfirm } from 'antd';
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const iconSize = 18;
const useColumns = (deleteResource) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [companiesFilter, setCompaniesFilter] = useState(null);

  const fetchCompanies = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'companies' });
      setCompaniesFilter(
        data.map(({ name, _id }) => ({ text: name, value: _id }))
      );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  }, []);

  useEffect(() => {
    (async () => {
      await fetchCompanies();
    })();
  }, [fetchCompanies]);
  return [
    {
      title: t('sites.column.reference'),
      key: 'reference',
      dataIndex: 'reference',
      sorter: true
    },
    {
      title: t('sites.column.company'),
      key: 'company',
      dataIndex: 'company',
      render: (company) => company && company.name,
      sorter: true,
      filters: companiesFilter
    },
    {
      title: t('sites.column.contact'),
      key: 'contact',
      dataIndex: 'contact',
      render: (contact) =>
        contact && `${contact.first_name} ${contact.last_name}`,
      sorter: true
    },
    {
      title: t('sites.column.phone_number_mobile'),
      key: 'contact',
      dataIndex: 'contact',
      render: (contact) => contact && `${contact.phone_number_mobile}`,
      sorter: true
    },
    {
      title: t('sites.column.address_details_1'),
      key: 'address_details_1',
      dataIndex: 'address_details_1',
      sorter: true
    },
    {
      title: t('sites.column.postal_code'),
      key: 'postal_code',
      dataIndex: 'postal_code',
      sorter: true
    },
    {
      title: t('sites.column.city'),
      key: 'city',
      dataIndex: 'city',
      sorter: true
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      fixed: 'right',
      width: '9%',
      render: (record) => (
        <div style={{ float: 'right' }}>
          <Link
            to={{
              pathname: `sites/show/${record.key}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          {!record.archived && (
            <>
              <Divider type="vertical" />
              <Link
                to={{
                  pathname: `sites/edit/${record.key}`
                }}
              >
                <EditOutlined style={{ fontSize: iconSize }} />
              </Link>
              <Divider type="vertical" />
              <Popconfirm
                title={t('datatable.column.action.archive.title')}
                okText={t('datatable.column.action.archive.ok')}
                okButtonProps={{ type: 'danger' }}
                cancelText={t('datatable.column.action.archive.cancel')}
                onConfirm={() => deleteResource(record.key)}
                icon={<WarningOutlined />}
              >
                <DeleteOutlined
                  style={{
                    color: 'var(--secondaryColor)',
                    fontSize: iconSize
                  }}
                  type="delete"
                />
              </Popconfirm>
            </>
          )}
        </div>
      )
    }
  ];
};

export default useColumns;
