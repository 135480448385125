import React from 'react';
import PropTypes from 'prop-types';
import { Row, Input, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import useInvoiceContext from '../InvoiceContext';

const FreeLineInvoiceFields = ({ form }) => {
  const { t } = useTranslation();
  const { listPrestations, setListPrestations } = useInvoiceContext();
  const addLabelForPrestation = () => {
    const label = form.getFieldValue('select_free');
    const tmp = listPrestations;
    tmp.push({
      label,
      unit_price: 0,
      subtotal: 0
    });
    setListPrestations([...tmp]);
    form.setFieldsValue({ prestations: [...tmp] });
  };
  return (
    <Row
      style={{
        background: 'var(--bodyBackground)',
        border: '1px solid var(--borderColor)',
        marginTop: 8,
        padding: '1%',
        width: '100%'
      }}
      align="middle"
      justify="space-between"
    >
      <Form.Item
        style={{
          background: 'var(--bodyBackground)',
          border: '1px solid var(--borderColor)',
          marginTop: 8,
          padding: '1%',
          width: '80%'
        }}
        wrapperCol={16}
        name={['select_free']}
        label={t(`invoices.form.prestations.select_free`)}
      >
        <Input />
      </Form.Item>
      <Button type="primary" onClick={() => addLabelForPrestation()}>
        Ajouter la ligne
      </Button>
    </Row>
  );
};
FreeLineInvoiceFields.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired
};
export default FreeLineInvoiceFields;
