import React, { createContext, useContext, useState } from 'react';
import { Form } from 'antd';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const SiteContext = createContext({});

export const SiteContextProvider = ({ children }) => {
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [site, setSite] = useState({});
  const [form] = Form.useForm();
  const [pictures, setPictures] = useState([]);
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(0);
  const [valuesState, setValuesState] = useState();
  const [filesSite, setFilesSite] = useState([]);
  const [cstblist, setCstbList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visiblePic, setVisiblePic] = useState(false);
  const [picToDisplay, setPicToDisplay] = useState();

  const getFilesCstb = async (url) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url
      });
      setPictures(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const deleteFiles = async (id) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/cstb/${id}`
      });
      getFilesCstb();
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getFilesToDisplay = async (url, typeFile, name) => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url,
        responseType: 'arraybuffer'
      });
      const bufferArray = new Uint8Array(data);
      const blob = new Blob([bufferArray], {
        type: typeFile
      });
      if (typeFile === 'application/pdf') {
        const urlModal = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = urlModal;
        a.download = name || 'Document';
        a.click();
        setIsLoading(false);
        return true;
      }
      setIsLoading(false);
      return URL.createObjectURL(blob);
    } catch (e) {
      if (e.response) return errorMessage(e.response.status);
      setIsLoading(false);
    }
    return false;
  };
  const funcPicToDisplay = async (idPic, type) => {
    const dataPic = await getFilesToDisplay(`/files/${idPic}`, type);
    setPicToDisplay(dataPic);
    setVisiblePic(true);
  };

  const getFilesForSite = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files?site_id=${id}&populate=cstb_id`
      });
      setFilesSite(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getSite = async (id) => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sites/${id}?populate=quotation,contact,details,company,seller`
      });
      setSite(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
    setIsLoading(false);
  };

  const addInvoiceToSite = async (dataSite) => {
    try {
      await dispatchAPI('POST', {
        url: '/invoices',
        body: dataSite
      });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  return (
    <SiteContext.Provider
      value={{
        form,
        deleteFiles,
        pictures,
        setPictures,
        visible,
        setVisible,
        current,
        setCurrent,
        valuesState,
        setValuesState,
        cstblist,
        setCstbList,
        getFilesToDisplay,
        getFilesCstb,
        visiblePic,
        setVisiblePic,
        funcPicToDisplay,
        picToDisplay,
        site,
        getFilesForSite,
        setFilesSite,
        filesSite,
        getSite,
        isLoading,
        setIsLoading,
        addInvoiceToSite
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

export default () => useContext(SiteContext);
