import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ShowUnpaid from './ShowUnpaid';
import ListUnpaid from './ListUnpaid';
import { UnpaidContextProvider } from './UnpaidContext';

const UnpaidRouter = () => {
  const { path } = useRouteMatch();

  return (
    <UnpaidContextProvider>
      <Switch>
        {/* <Route
          path={`${path}/create`}
          render={() => <CreateUpdateInvoice purpose="create" />}
        />
        <Route
          path={`${path}/edit/:id`}
          render={() => <CreateUpdateInvoice purpose="edit" />}
        /> */}
        <Route
          path={`${path}/show/:id`}
          render={() => <ShowUnpaid resource="unpaid" />}
        />
        <Route path={path} component={ListUnpaid} />
      </Switch>
    </UnpaidContextProvider>
  );
};

export default UnpaidRouter;
