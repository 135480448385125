import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, Typography } from 'antd';
import { register } from '../../serviceWorker';

const { Title } = Typography;

const UpdateAlert = ({ path }) => {
  const [waitingServiceWorker, setWaitingServiceWorker] = useState(null);
  const [isUpdateAvailable, setUpdateAvailable] = useState(false);

  useEffect(() => {
    register({
      onUpdate: (registration) => {
        setWaitingServiceWorker(registration.waiting);
        setUpdateAvailable(true);
      },
      onWaiting: (waiting) => {
        setWaitingServiceWorker(waiting);
        setUpdateAvailable(true);
      }
    });
  }, [path]);

  useEffect(() => {
    // reload after getting update
    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload();
        }
      });
    }
  }, [waitingServiceWorker]);

  const value = useMemo(
    () => ({
      isUpdateAvailable,
      updateCache: () => {
        if (waitingServiceWorker) {
          waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' });
        }
      }
    }),
    // eslint-disable-next-line
    [isUpdateAvailable, waitingServiceWorker]
  );

  return isUpdateAvailable ? (
    <Card
      style={{
        position: 'fixed',
        zIndex: 1000,
        bottom: 10,
        right: 10,
        background: '#ffffff'
      }}
    >
      <Title level={4}>Une nouvelle version est disponible</Title>
      <Button
        type="primary"
        onClick={value.updateCache}
        style={{ width: '100%' }}
      >
        Mettre à jour maintenant
      </Button>
    </Card>
  ) : (
    ''
  );
};

UpdateAlert.propTypes = {
  path: PropTypes.string.isRequired
};

export default UpdateAlert;
