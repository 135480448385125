import React, { useState, useEffect } from 'react';
import { Descriptions, Row, Col } from 'antd';

const TotalContent = ({ listData, vat }) => {
  const [dataFormated, setDataFormated] = useState([]);
  const listTypeOfArticle = ['epis', 'articles', 'primary_matters'];

  const formatValue = () => {
    if (listData) {
      const resultFormat = [];
      listTypeOfArticle.forEach(
        (type) =>
          listData[type] &&
          listData[type].forEach((ele) => resultFormat.push(ele))
      );
      setDataFormated(resultFormat);
    }
  };
  useEffect(() => {
    if (listData) formatValue();
  }, [listData]);
  return (
    <Row justify="center">
      <Col span={10}>
        <Descriptions bordered>
          <Descriptions.Item span={3} label="Total HT">
            {`${dataFormated
              .map((data) => data.sold_price * data.quantity)
              .reduce((a, b) => a + b, 0)
              .toFixed(2)} €`}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Total TVA">
            {vat &&
              `${(
                dataFormated
                  .map((data) => data.sold_price * data.quantity)
                  .reduce((a, b) => a + b, 0) *
                (vat.rate / 100)
              ).toFixed(2)} €`}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Total HT">
            {`${(
              dataFormated
                .map((data) => data.sold_price * data.quantity)
                .reduce((a, b) => a + b, 0) +
              (vat
                ? dataFormated
                    .map((data) => data.sold_price * data.quantity)
                    .reduce((a, b) => a + b, 0) *
                  (vat.rate / 100)
                : 0)
            ).toFixed(2)} €`}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};
export default TotalContent;
