import { useTranslation } from 'react-i18next';

const useEmailPattern = () => {
  const { t } = useTranslation();

  return [
    {
      pattern: new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/),
      message: t('login.invalidMailFormat')
    }
  ]
}

export default useEmailPattern;
