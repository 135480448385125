import React, { useState } from 'react';
import { Input, InputNumber, Form, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import useFormList from './FormListdetails';
import useMovementContext from './MovementsContext';

const useFields = (enums) => {
  const { t } = useTranslation();
  const { valuesState } = useMovementContext();
  const [horizontal, setHorizontal] = useState(false);
  const [qualitySupport, setQualitySupport] = useState(false);
  const [qualitySite, setQualitySite] = useState(true);
  const [temperature, setTemperature] = useState();

  const fieldsPreIntervention = [
    {
      label: 'temperature',
      name: ['temperature'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          onChange={(values) => setTemperature(values)}
          style={{ width: 100 }}
        />
      ),
      help:
        temperature <= -3 || temperature === null ? (
          <p style={{ color: 'var(--secondaryColor)' }}>
            Vous devez faire appel à un responsable pour cause de
            Non-Conformité.
          </p>
        ) : null
    },
    {
      name: ['support'],
      valuePropName: 'checked',
      input: (
        <Input.Group style={{ display: 'flex' }}>
          <Form.Item
            valuePropName="checked"
            name={['support', 'clean']}
            label={t('cstb.form.clean')}
            style={{
              width: '50%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              marginRight: '20px'
            }}
          >
            <Switch
              checkedChildren="Oui"
              unCheckedChildren="Non"
              defaultChecked={false}
            />
          </Form.Item>
          <Form.Item
            valuePropName="checked"
            name={['support', 'dry']}
            label={t('cstb.form.dry')}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center'
            }}
          >
            <Switch
              checkedChildren="Oui"
              unCheckedChildren="Non"
              defaultChecked={false}
            />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      label: 'wood',
      name: ['wood'],
      valuePropName: 'checked',
      input: (
        <Switch
          style={{ display: 'flex' }}
          checkedChildren="Oui"
          unCheckedChildren="Non"
          defaultChecked={false}
        />
      )
    },
    {
      label: 'cablage',
      name: ['cablage'],
      valuePropName: 'checked',
      input: (
        <Switch
          style={{ display: 'flex' }}
          checkedChildren="Oui"
          unCheckedChildren="Non"
          defaultChecked={false}
        />
      )
    },
    {
      label: 'horizontal',
      name: ['horizontal'],
      valuePropName: 'checked',
      input: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            style={{ display: 'flex', marginRight: '10px' }}
            checkedChildren="Oui"
            unCheckedChildren="Non"
            defaultChecked={false}
            onChange={(checked) =>
              checked === true ? setHorizontal(true) : setHorizontal(false)
            }
          />
          <div>
            {horizontal === true
              ? "Les modalités s'appliquent sur la totalité de la surface du local."
              : ''}
          </div>
        </div>
      )
    },
    {
      label: 'support_quality',
      name: ['support_quality'],
      valuePropName: 'checked',
      input: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            style={{ display: 'flex', marginRight: '10px' }}
            checkedChildren="Oui"
            unCheckedChildren="Non"
            defaultChecked={false}
            onChange={(checked) =>
              checked === true
                ? setQualitySupport(true)
                : setQualitySupport(false)
            }
          />
          <div style={{ color: 'var(--secondaryColor)' }}>
            {qualitySupport === true
              ? 'Veuillez séparer le local en zones distinctes.'
              : ''}
          </div>
        </div>
      )
    },
    {
      label: 'site_quality',
      name: ['site_quality'],
      valuePropName: 'checked',
      input: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Switch
            style={{ display: 'flex', marginRight: '10px' }}
            checkedChildren="Oui"
            unCheckedChildren="Non"
            defaultChecked
            onChange={(checked) =>
              checked === true ? setQualitySite(true) : setQualitySite(false)
            }
          />
          <div style={{ color: 'var(--secondaryColor)' }}>
            {qualitySite === false
              ? 'Veuillez prendre des photos et prévenir votre responsable.'
              : ''}
          </div>
        </div>
      )
    },
    {
      label: 'site_acceptation',
      name: ['site_acceptation'],
      valuePropName: 'checked',
      input: (
        <Switch
          style={{ display: 'flex' }}
          checkedChildren="Oui"
          unCheckedChildren="Non"
          defaultChecked={false}
        />
      )
    }
  ];

  const fieldsPreMesurement = [
    {
      name: ['details'],
      rules: [{ required: true }],
      input: <Form.List name="details">{useFormList(enums, 'cstb')}</Form.List>
    }
  ];

  const fieldsProjectionPol = [
    {
      name: ['projection', 'temp_polyol'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          parser={(value) => value.replace('°C', '')}
          formatter={(value) => `${value}°C`}
          size="large"
          min={0}
        />
      )
    },
    {
      name: ['projection', 'pressure_polyol'],
      rules: [{ required: true }],
      initialValue: 0,
      input: (
        <InputNumber formatter={(value) => `${value}`} size="large" min={0} />
      )
    }
  ];

  const fieldsProjectionIso = [
    {
      name: ['projection', 'temp_iso'],
      rules: [{ required: true }],
      initialValue: 0,
      input: (
        <InputNumber
          parser={(value) => value.replace('°C', '')}
          formatter={(value) => `${value}°C`}
          size="large"
          min={0}
        />
      )
    },
    {
      name: ['projection', 'pressure_iso'],
      rules: [{ required: true }],
      initialValue: 0,
      input: <InputNumber size="large" min={0} />
    }
  ];
  const fieldsProjectionSheath = [
    {
      name: ['projection', 'sheath'],
      rules: [{ required: true }],
      initialValue: 0,
      style: { width: '100vw' },
      input: (
        <InputNumber
          parser={(value) => value.replace('°C', '')}
          formatter={(value) => `${value}°C`}
          size="large"
          min={0}
        />
      )
    }
  ];

  const fieldsCoring = [
    {
      name: ['coring', 'spray_quality'],
      valuePropName: 'checked',
      validateStatus: 'error',
      help:
        valuesState?.coring?.spray_quality === false
          ? 'Veuillez prévenir votre responsable.'
          : null,
      initialValue: true,
      input: (
        <Switch checkedChildren="Oui" unCheckedChildren="Non" defaultChecked />
      )
    },
    {
      name: ['coring', 'pur_quality'],
      valuePropName: 'checked',
      validateStatus: 'error',
      help:
        valuesState?.coring?.pur_quality === false
          ? 'Veuillez prévenir votre responsable.'
          : null,
      initialValue: true,
      input: (
        <Switch checkedChildren="Oui" unCheckedChildren="Non" defaultChecked />
      )
    },
    {
      name: ['coring', 'joining_quality'],
      valuePropName: 'checked',
      initialValue: true,
      validateStatus: 'error',
      help:
        valuesState?.coring?.joining_quality === false
          ? 'Veuillez prévenir votre responsable.'
          : null,
      input: (
        <Switch checkedChildren="Oui" unCheckedChildren="Non" defaultChecked />
      )
    }
  ];

  const fieldsCoringMeasure = [
    {
      name: ['coring', 'coring_start'],
      style: { width: '100%' },
      rules: [{ required: true }],
      input: <InputNumber bordered={false} />
    },
    {
      name: ['coring', 'coring_medium'],
      style: { width: '100%' },
      rules: [{ required: true }],
      input: <InputNumber bordered={false} />
    },
    {
      name: ['coring', 'coring_end'],
      style: { width: '100%' },
      rules: [{ required: true }],
      input: <InputNumber bordered={false} />
    }
  ];

  const fieldsPostMesurement = [
    {
      name: ['details_after'],
      rules: [{ required: true }],
      input: (
        <Form.List name="details_after">{useFormList(enums, 'cstb')}</Form.List>
      )
    }
  ];

  const fieldsControl = [
    {
      name: ['control', 'quality'],
      valuePropName: 'checked',
      noLabel: true,
      input: (
        <Switch
          style={{ fontSize: 100 }}
          checkedChildren="Conforme"
          unCheckedChildren="Non conforme"
          defaultChecked
        />
      )
    }
  ];

  return {
    fieldsPreIntervention,
    fieldsPreMesurement,
    fieldsProjectionPol,
    fieldsProjectionIso,
    fieldsProjectionSheath,
    fieldsCoring,
    fieldsPostMesurement,
    fieldsControl,
    fieldsCoringMeasure
  };
};

export default useFields;
