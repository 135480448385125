import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker, Input, InputNumber, Select } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import useQuotationContext from './QuotationContext';

const { TextArea } = Input;
const { Option } = Select;

const useFields = (purpose, idSite) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const {
    sites,
    company,
    setCompany,
    contact,
    setContact,
    setPrestationsList,
    vats,
    setVats
  } = useQuotationContext();
  const [isLoading, setIsLoading] = useState(false);
  const [settings, setSettings] = useState([]);
  const [status, setStatus] = useState([]);
  const [organizations, setOrganizations] = useState();

  const editFields = [
    {
      name: ['number'],
      input: <Input disabled />
    },
    { name: ['subject'], rules: [{ required: true }] },
    {
      name: ['status'],
      rules: [{ required: true }],
      input: (
        <Select
          options={
            status &&
            status.map((item) => ({
              label: t(`quotations.form.enums.${item}`),
              value: item
            }))
          }
          loading={isLoading}
        />
      )
    },
    { name: ['comment'], input: <TextArea /> },
    {
      name: ['emission_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM/YYYY" />,
      initialValue: moment()
    },
    {
      name: ['deadline_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM/YYYY" />,
      initialValue:
        settings.find(
          (setting) => setting.label === 'default_quotation_deadline'
        ) &&
        moment().add(
          parseInt(
            settings.find(
              (setting) => setting.label === 'default_quotation_deadline'
            ).value,
            10
          ),
          'days'
        )
    },
    {
      name: ['vat'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '100%' }}
          options={
            vats &&
            vats.map((item) => ({
              label: `${item?.description} : ${item?.rate}`,
              value: item._id
            }))
          }
          loading={isLoading}
        />
      )
    }
  ];

  const createFields = [
    { name: ['subject'], rules: [{ required: true }] },
    { name: ['comment'], input: <TextArea /> },
    {
      name: ['emission_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM/YYYY" />,
      initialValue: moment()
    },
    {
      name: ['deadline_date'],
      rules: [{ required: true }],
      input: <DatePicker format="DD/MM/YYYY" />,
      initialValue:
        settings.find(
          (setting) => setting.label === 'default_quotation_deadline'
        ) &&
        moment().add(
          parseInt(
            settings.find(
              (setting) => setting.label === 'default_quotation_deadline'
            ).value,
            10
          ),
          'days'
        )
    },
    {
      name: ['vat'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '100%' }}
          options={
            vats &&
            vats.map((item) => ({
              label: `${item?.description} : ${item?.rate} %`,
              value: item._id
            }))
          }
          loading={isLoading}
        />
      )
    }
  ];

  const fields2 = [
    {
      name: ['total_discount'],
      input: (
        <InputNumber
          disabled
          formatter={(value) => value && `${parseFloat(value).toFixed(2)} €`}
          parser={(value) => value.replace('€', '')}
          style={{ width: 'fit-content' }}
        />
      )
    },
    {
      name: ['subTotal'],
      input: (
        <InputNumber
          disabled
          formatter={(value) => value && `${parseFloat(value).toFixed(2)} €`}
          parser={(value) => value.replace('€', '')}
          style={{ width: 'fit-content' }}
        />
      )
    },
    {
      name: ['total_VAT'],
      input: (
        <InputNumber
          disabled
          formatter={(value) => value && `${parseFloat(value).toFixed(2)} €`}
          parser={(value) => value.replace('€', '')}
          style={{ width: 'fit-content' }}
        />
      )
    },
    {
      name: ['total'],
      input: (
        <InputNumber
          style={{ visibility: 'hidden' }}
          disabled
          formatter={(value) => value && `${value}€`}
          parser={(value) => value.replace('€', '')}
        />
      ),
      noLabel: true
    },
    {
      name: ['total_area'],
      input: <InputNumber style={{ visibility: 'hidden' }} disabled />,
      noLabel: true
    }
  ];

  const fetchData = async (id) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sites/${idSite || id}?populate=contact,company`
      });
      setContact(data.contact);
      setCompany(data.company);
      if (purpose !== 'edit') {
        setPrestationsList([]);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const headerFields = [
    {
      name: ['organization'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          disabled
          style={{ width: '100%' }}
          placeholder={t('quotations.form.select_organization')}
        >
          <Option value={null} />
          {organizations?.map(({ name, _id }) => (
            <Option key={_id} value={_id}>
              {name}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['site'],
      rules: [{ required: true }],
      input: (
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          style={{ width: '100%' }}
          placeholder={t('quotations.form.select_site')}
          onChange={(values) => fetchData(values)}
        >
          {sites.map(({ reference, _id }) => (
            <Option key={_id} value={_id}>
              {reference}
            </Option>
          ))}
        </Select>
      )
    },
    ...(contact
      ? [
          {
            name: ['customer'],
            input: (
              <Input value={company._id} placeholder={company.name} disabled />
            ),
            help: (
              <>
                <p style={{ marginTop: '5%' }}>
                  {`${company.address_details_1} ${
                    company.address_details_2 ? company.address_details_2 : ''
                  }`}
                </p>
                <p>{`${company.postal_code} ${company.city}`}</p>
              </>
            )
          },
          {
            name: ['contact'],
            input: (
              <Input
                value={contact._id}
                placeholder={`${contact.first_name} ${contact.last_name}`}
                disabled
              />
            ),
            help: (
              <>
                <p style={{ marginTop: '5%' }}>{contact.phone_number_mobile}</p>
                <p>{contact.email}</p>
              </>
            )
          }
        ]
      : [])
  ];

  const fetchStatus = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/quotations/enums'
      });
      setStatus(data.status);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, []);

  const fetchSettings = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?type=ACCOUNTING'
      });
      setSettings(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchOrganizations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/organizations'
      });
      setOrganizations(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchVats = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/vats'
      });
      setVats(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSettings = useCallback(async () => {
    setIsLoading(true);
    await fetchSettings();
    await fetchStatus();
    await fetchOrganizations();
    await fetchVats();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    if (idSite) fetchData(idSite);
  }, [idSite]);

  return {
    createFields,
    editFields,
    fields2,
    headerFields
  };
};

export default useFields;
