import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const useListContent = ({
  inventory_responsible,
  deposit,
  date,
  pending,
  comments
}) => {
  const { t } = useTranslation();

  return [
    {
      label: t('inventories.column.responsible'),
      content: inventory_responsible && (
        <>
          {`${inventory_responsible.first_name} ${inventory_responsible.last_name}`}
        </>
      ),
      span: 1
    },
    {
      label: t('inventories.column.depot'),
      content: deposit?.label,
      span: 1
    },
    {
      label: t('inventories.column.date'),
      content: moment(date).format('DD/MM/YYYY'),
      span: 1
    },
    {
      label: t('inventories.column.status'),
      content: pending ? <Tag color="red">En cours</Tag> : <Tag>Validé</Tag>,
      span: 1
    },
    {
      label: t('inventories.column.comments'),
      content: comments && comments,
      span: 1
    }
  ];
};

export default useListContent;
