import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateBank = ({ purpose }) => {
  const form = useRef();
  const { fields } = useFields(form);
  const config = {
    onGetResource: {
      setFields: ({ created_at, ...data }) => ({
        ...data
      })
    }
  };

  return (
    <CreateUpdateContainer
      ref={form}
      fields={fields}
      withSubRoutes
      purpose={purpose}
      baseUrl="bank-settings"
      resource="bank"
      config={config}
    />
  );
};

CreateUpdateBank.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateBank;
