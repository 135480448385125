import React from 'react';
import { Progress, Skeleton, Typography, Tag, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
  border: 1px solid lightgrey;
  padding: 10px;
  width: 350px;
`;
const { Title } = Typography;

const RemainingToPay = ({ isLoading, invoice }) => {
  const { t } = useTranslation();
  const remaining =
    invoice.total?.toFixed(2) - (invoice.total_paid ? invoice.total_paid : 0);

  return (
    <Container>
      <Skeleton loading={isLoading} paragraph={{ rows: 1, width: '50%' }}>
        <Title level={5} style={{ marginBottom: '0', color: 'lightgrey' }}>
          {t('invoices.showInvoice.payments')}
        </Title>
        <Title level={4} style={{ marginTop: '0' }}>
          {t('invoices.show.remaining', {
            value: `${remaining}€`
          })}
        </Title>

        <Tag color={invoice?.type === 'PAY' ? 'var(--addColor)' : null}>
          {t(`invoices.tags.${invoice?.type}`)}
        </Tag>

        <Progress
          style={{ float: 'right', marginTop: -60 }}
          type="circle"
          percent={parseInt(
            ((invoice.total - remaining) * 100) / invoice.total,
            10
          )}
          width={65}
        />
        <ul>
          {invoice.payments &&
            invoice.payments.map((payment) => (
              <Row key={payment._id}>
                <Col span={16}>
                  {new Date(payment.date).toLocaleDateString()}-
                  {numeral(payment.amount_paid).format('0,0.00')}€
                </Col>
                <Col span={8}>
                  <span>{payment?.type}</span>
                </Col>
              </Row>
            ))}
        </ul>
      </Skeleton>
    </Container>
  );
};

RemainingToPay.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  invoice: PropTypes.shape({
    payments: PropTypes.array,
    type: PropTypes.string,
    total: PropTypes.number,
    total_paid: PropTypes.number
  }).isRequired
};

export default RemainingToPay;
