import React, { useEffect } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import KanbanColumn from './KanbanColumn';
import EditDrawer from './EditDrawer';
import useBoxContext from './DealsContext';

const { Search } = Input;

const KanbanBoard = () => {
  const { t } = useTranslation();
  const {
    deal,
    searchResource,
    searchValue,
    visible,
    setVisible,
    fetchKanbanColumns,
    kanbanCols,
    fetchData,
  } = useBoxContext();

  const sortedKanbanCols = kanbanCols
    .sort(function(a, b) {
      return b.index - a.index;
    });

  const onClose = () => {
    setVisible(false);
  };

  useEffect(() => {
    (async () => {
      await fetchData();
      await fetchKanbanColumns();
    })();
  }, [fetchData]);

  return (
    <>
      <Row justify="space-between" align="middle" gutter={[8, 16]} style={{ marginBottom: 16 }}>
        <Col>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={value => searchResource(value)}
            style={{marginLeft: 5, width: 240 }}
          />
        </Col>
        <Col>
          <Button type="add" onClick={() => setVisible(true)}>
            {t('buttons.create')}
            <PlusOutlined />
          </Button>
        </Col>
      </Row>
      <div
        style={{
          display: 'flex',
          justifyContent: 'left',
          flexWrap: 'nowrap',
          overflowX: 'scroll',
          background: 'rgb(255,255,255)',
          background: 'linear-gradient(0deg, rgba(77,179,162,1) 0%, rgba(255,255,255,1) 100%)'
        }}>
        {sortedKanbanCols
          .filter(col => col.show)
          .map((col, index) => (
            <KanbanColumn key={index} column={col} key={col.label} />
          ))}
      </div>
      <EditDrawer visible={visible} dealId={deal} close={onClose} />
    </>
  );
};

export default KanbanBoard;
