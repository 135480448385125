import React, { useState } from 'react';
import { Button, DatePicker, Form, Input, Select, InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;

const layout = {
  labelCol: { span: 4, offset: 1 },
  wrapperCol: { span: 16 }
};
const tailLayout = {
  wrapperCol: { offset: 8, span: 16 }
};

const AddEditActivityForm = ({
  purpose,
  addActivity,
  updateActivity,
  setFormActivity,
  setListActivity,
  itemSelected
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState(itemSelected?.type || '');

  const initValues = () => {
    let values = {};

    if (purpose === 'edit') {
      values = { ...itemSelected };
      values.dateTime = moment(values.dateTime);
    } else values.dateTime = moment();
    return values;
  };

  const finish = (values) => {
    if (purpose === 'create') addActivity(values);
    else updateActivity(values);
    setListActivity(true);
    setFormActivity(false);
  };

  return (
    <div style={{ marginTop: 20 }}>
      <Form {...layout} initialValues={initValues()} onFinish={finish}>
        <Form.Item name="title" label="Titre" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Catégorie" rules={[{ required: true }]}>
          <Select onChange={(event) => setType(event)}>
            <Option value="note">Note</Option>
            <Option value="call">Appel</Option>
            <Option value="meeting">RDV</Option>
          </Select>
        </Form.Item>
        <Form.Item name="desc" label="Description">
          <TextArea rows={4} />
        </Form.Item>
        {type === 'meeting' && (
          <Form.Item name="address" label="Adresse">
            <Input />
          </Form.Item>
        )}
        {type === 'meeting' && (
          <Form.Item name="duration" label="Durée (en heures)">
            <InputNumber />
          </Form.Item>
        )}
        <Form.Item name="dateTime" label="Date" rules={[{ required: true }]}>
          <DatePicker showTime format="YYYY-MM-DD HH:mm:ss" />
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button
            danger
            style={{ marginRight: 20 }}
            onClick={() => {
              setFormActivity(false);
              setListActivity(true);
            }}
          >
            {t('buttons.cancel')}
          </Button>
          <Button type="primary" htmlType="submit">
            {t('buttons.add')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditActivityForm;
