import React from 'react';
import { Steps } from 'antd';

const { Step } = Steps;

export const SelectDealStatus = ({
  editStatus,
  dealStatusIndex,
  kanbanCols,
}) => {

  const getDealStatusIndex = () => {
    let indexCol = 0;
    kanbanCols.map((col, index) => {
      if(col._id === dealStatusIndex) indexCol = index;
    });
    return indexCol;
  };

  const onSelectChange = value => editStatus(value);

  return (
    <div style={{marginBottom : 20}}>
      <Steps current={getDealStatusIndex()} progressDot onChange={onSelectChange}>
        {kanbanCols &&
        kanbanCols.map((item) => {
            return <Step title={item.label} key={item._id} />;
          })}
      </Steps>
    </div>
  );
};
