import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
// import { subRoutes } from '../../utils/constants/routes';
// import QuotationMatterRouter from './QuotationMatters/QuotationMatterRouter';
import SuppliersRouter from './SuppliersRouter';

const PurchaseRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      {/* <Route
        path={`${path}${subRoutes.PURCHASES.QUOTATIONS_MATTERS}`}
        component={QuotationMatterRouter}
      /> */}
      <Route path={`${path}`} component={SuppliersRouter} />
    </Switch>
  );
};

export default PurchaseRouter;
