import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm } from 'antd';

const useListContentBank = (
  {
    name,
    address,
    zip,
    city,
    phone_number,
    bank_rib,
    transmitter,
    journal,
    accounting_account,
    file
  },
  deleteFileToBank,
  getFilesToDisplay
) => {
  const { t } = useTranslation();
  return [
    {
      label: t('bank.name'),
      content: name,
      span: 1
    },
    {
      label: t('bank.address'),
      content: address,
      span: 1
    },
    {
      label: t('bank.zip'),
      content: zip,
      span: 1
    },
    {
      label: t('bank.city'),
      content: city && city,
      span: 1
    },
    {
      label: t('bank.phone_number'),
      content: phone_number && phone_number,
      span: 1
    },
    {
      label: t('bank.bank_rib'),
      content: bank_rib && bank_rib.replace(/.(?=.{4,}$)/g, '#'),
      span: 2
    },
    {
      label: t('bank.file'),
      content: file ? (
        <Popconfirm
          placement="right"
          title="Choisir une action ?"
          onCancel={() => deleteFileToBank(file)}
          onConfirm={() => getFilesToDisplay(file)}
          cancelButtonProps={{ type: 'danger' }}
          okText="Imprimer"
          cancelText="Supprimer"
        >
          <Button type="link">{file && file?.metadata?.originalName}</Button>
        </Popconfirm>
      ) : (
        '-'
      ),
      span: 1
    },
    {
      label: t('bank.transmitter'),
      content: transmitter,
      span: 1
    },
    {
      label: t('bank.journal'),
      content: journal,
      span: 1
    },
    {
      label: t('bank.accounting_account'),
      content: accounting_account,
      span: 1
    }
  ];
};

export default useListContentBank;
