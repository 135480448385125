import React from 'react';
import { Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { routes, subRoutes } from '../../../utils/constants/routes';

const useListContent = ({
  gender,
  last_name,
  first_name,
  email,
  phone_number_mobile,
  phone_number_office,
  role,
  recurrency_contact,
  particularities,
  my_company
}) => {
  const { t } = useTranslation();

  return my_company
    ? [
        {
          label: 'contacts.form.full_name',
          content:
            last_name &&
            first_name &&
            `${gender === 'MISTER' ? 'M.' : 'Mme'} ${first_name} ${last_name}`,
          span: 3
        },
        {
          label: 'contacts.form.company',
          content: my_company && (
            <Link
              to={{
                pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CUSTOMERS}/show/${my_company._id}`
              }}
            >
              {my_company.name}
            </Link>
          ),
          span: 3
        },
        {
          label: 'contacts.form.recurrency_contact',
          content: recurrency_contact && (
            <Switch
              checkedChildren={t('sites.tags.true')}
              unCheckedChildren={t('sites.tags.false')}
              checked={recurrency_contact}
              disabled
            />
          ),
          span: 3
        },
        {
          label: 'contacts.form.email',
          content: email,
          span: 3
        },
        {
          label: 'contacts.form.phone_number_mobile',
          content: phone_number_mobile,
          span: 3
        },
        {
          label: 'contacts.form.phone_number_office',
          content: phone_number_office,
          span: 3
        },

        {
          label: 'contacts.form.role',
          content: role,
          span: 3
        },
        {
          label: 'contacts.form.particularities',
          content: particularities,
          span: 3
        }
      ]
    : [
        {
          label: 'contacts.form.full_name',
          content:
            last_name &&
            first_name &&
            `${gender === 'MISTER' ? 'M.' : 'Mme'} ${first_name} ${last_name}`,
          span: 3
        },
        {
          label: 'contacts.form.email',
          content: email,
          span: 3
        },
        {
          label: 'contacts.form.phone_number_mobile',
          content: phone_number_mobile,
          span: 3
        },
        {
          label: 'contacts.form.phone_number_office',
          content: phone_number_office,
          span: 3
        },
        {
          label: 'contacts.form.role',
          content: role,
          span: 3
        },
        {
          label: 'contacts.form.particularities',
          content: particularities,
          span: 3
        }
      ];
};

export default useListContent;
