import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Popconfirm } from 'antd';

const useListContentVatAccount = (
  {
    code_number,
    description,
    lettrable,
    sales_product_account,
    sales_product_account_subcontracting,
    vat_account_deposit_invoice,
    rate,
    file
  },
  deleteFileToBank,
  getFilesToDisplay
) => {
  const { t } = useTranslation();
  return [
    {
      label: t('vat_account.code_number'),
      content: code_number,
      span: 2
    },
    {
      label: t('vat_account.description'),
      content: description,
      span: 1
    },
    {
      label: t('vat_account.lettrable'),
      content: lettrable ? <span>Oui</span> : <span>Non</span>,
      span: 2
    },
    {
      label: t('vat_account.rate'),
      content: `${rate} %`,
      span: 1
    },
    {
      label: t('vat_account.sales_product_account'),
      content: sales_product_account,
      span: 1
    },
    {
      label: t('vat_account.sales_product_account_subcontracting'),
      content: sales_product_account_subcontracting,
      span: 1
    },
    {
      label: t('vat_account.vat_account_deposit_invoice'),
      content: vat_account_deposit_invoice,
      span: 1
    },
    {
      label: t('vat_account.file'),
      content: file ? (
        <Popconfirm
          placement="right"
          title="Choisir une action ?"
          onCancel={() => deleteFileToBank(file)}
          onConfirm={() => getFilesToDisplay(file)}
          cancelButtonProps={{ type: 'danger' }}
          okText="Imprimer"
          cancelText="Supprimer"
        >
          <Button type="link">{file && file.metadata.originalName}</Button>
        </Popconfirm>
      ) : (
        '-'
      ),
      span: 1
    }
  ];
};

export default useListContentVatAccount;
