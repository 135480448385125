import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import {
  Button,
  Popconfirm,
  Skeleton,
  Table,
  Tabs,
  Row,
  Typography
} from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import useErrorMessage from '../../../utils/ErrorMessage';
import useColumnsForDetailSite from './ColumnsForDetailSite';
import { SearchAddBar } from '../../../utils/constants/customButton';
import Datatable from '../../../components/DataTable/Datatable';
import useColumns from './Quotations/columns';
import useColumnsInvoice from './Invoices/columnsInvoice';
import useColumnsCstb from './Cstb/columnCstb';
import DatatableCstb from './Cstb/DataTableCstb';
import ShowDocuments from './Documents/ShowDocuments';
import useSiteContext from './SiteContext';
import ModalToAddInvoice from './Invoices/ModalToAddInvoice';

const { TabPane } = Tabs;
const { Title } = Typography;

const ShowSite = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { isLoading, setIsLoading, site, getSite } = useSiteContext();
  const { id } = useParams();
  const [localisation, setLocalisation] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [visibleInvoiceAdd, setVisibleInvoiceAdd] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const deleteSite = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/sites/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getLocalisation = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/sites/${id}`
      });
      setLocalisation(data.localisation[0]);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getSite(id);
      await getLocalisation();
    })();
  }, [id]);

  const searchResource = (value) => {
    if (value) {
      history.push({
        pathname,
        search: `?p=1&k=${value}`
      });
    } else {
      history.push({
        pathname,
        search: `?p=1`
      });
    }
  };

  useEffect(() => {
    searchResource(searchValue);
  }, [searchValue]);

  return (
    <>
      <PageHeaderCustom
        title={t(`sites.show.title`)}
        withSubRoutes
        extra={
          !site.archived ? (
            <>
              <Link
                to={{
                  pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.SITES}/edit/${id}`
                }}
              >
                <Button type="primary">
                  {`${t('buttons.edit')} `}
                  <EditOutlined />
                </Button>
              </Link>
              <Popconfirm
                title={t('datatable.column.action.delete.title')}
                okText={t('datatable.column.action.delete.ok')}
                okButtonProps={{ danger: true }}
                cancelText={t('datatable.column.action.delete.cancel')}
                onConfirm={deleteSite}
                icon={<WarningOutlined />}
              >
                <Button type="link" danger>
                  {`${t('buttons.delete')} `}
                  <DeleteOutlined />
                </Button>
              </Popconfirm>
            </>
          ) : (
            <Title
              level={4}
              style={{ color: 'red', border: '0.5px solid', padding: 5 }}
            >
              Archivé
            </Title>
          )
        }
      />
      <ContentCustom>
        <div className="card-container">
          <Tabs type="card">
            <TabPane tab={`${t('sites.title')}`} key="1">
              <Skeleton loading={isLoading}>
                <DescriptionList data={listContent(site)} skipEmpty translate />
              </Skeleton>
              <Skeleton loading={isLoading}>
                <div
                  style={{
                    overflow: 'hidden',
                    marginBottom: '2%'
                  }}
                >
                  {localisation && localisation.latitude ? (
                    <iframe
                      title="map"
                      style={{
                        width: '400px',
                        height: '250px',
                        border: 'none'
                      }}
                      scrolling="yes"
                      src={`https://maps.google.com/maps?q=${localisation.latitude},${localisation.longitude}&hl=fr&z=10&amp&output=embed`}
                    />
                  ) : (
                    <> </>
                  )}
                </div>
              </Skeleton>
              <ContentCustom>
                <Skeleton loading={isLoading}>
                  <Table
                    keyRow={site._id}
                    rowKey="_id"
                    dataSource={site.details}
                    columns={[...useColumnsForDetailSite()]}
                    scroll={{ x: 1200 }}
                  />
                </Skeleton>
              </ContentCustom>
            </TabPane>
            <TabPane tab={`${t('quotations.title')}`} key="2">
              {!site.archived && (
                <SearchAddBar
                  addPath={`${routes.COMMERCIAL}${subRoutes.COMMERCIAL.QUOTATIONS}/create/${id}`}
                  setSearchValue={setSearchValue}
                />
              )}

              <Datatable
                resourceName="quotations"
                columns={useColumns()}
                extraQuery={`site=${id}`}
                path={`${routes.COMMERCIAL}${subRoutes.COMMERCIAL.QUOTATIONS}`}
                searchValue
                populate="customer"
                customActionColumn
              />
            </TabPane>
            <TabPane tab="Fiches CSTB" key="3">
              <DatatableCstb
                resourceName="cstb"
                columns={useColumnsCstb()}
                extraQuery={id}
                path="cstb"
                populate="quotation_id,site_id"
                customActionColumn
              />
            </TabPane>
            <TabPane tab="Factures" key="4">
              {!site.archived && (
                <>
                  <Row style={{ margin: 20 }} justify="end">
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={() => setVisibleInvoiceAdd(!visibleInvoiceAdd)}
                    >
                      Ajouter une facture
                    </Button>
                  </Row>
                  <ModalToAddInvoice
                    dataSite={site !== {} && site}
                    visible={visibleInvoiceAdd}
                    setVisible={setVisibleInvoiceAdd}
                    setRefresh={setRefresh}
                    refresh={refresh}
                  />
                </>
              )}

              <Datatable
                resourceName="invoices"
                columns={useColumnsInvoice()}
                extraQuery={`site=${id}`}
                path={`${routes.ACCOUNTING}${subRoutes.ACCOUNTING.INVOICES}`}
                populate="quotation,customer"
                customActionColumn
                forceRefresh={refresh}
              />
            </TabPane>
            <TabPane tab="Documents" key="5">
              <ShowDocuments />
            </TabPane>
          </Tabs>
        </div>
      </ContentCustom>
    </>
  );
};

export default ShowSite;
