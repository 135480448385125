/* eslint-disable react/jsx-props-no-spreading */
import { useTranslation } from 'react-i18next';

const useFields = () => {
  const { t } = useTranslation();

  const fields = [
    {
      label: 'name',
      name: ['name'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    },
    {
      label: 'subject',
      name: ['subject'],
      rules: [{ required: true, message: t('errors.message.missing_field') }]
    }
  ];

  return { fields };
};
export default useFields;
