import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Badge,
  Row,
  Col,
  Button,
  Calendar,
  Collapse,
  Statistic,
  Divider,
  Spin,
  Tag
} from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  EuroOutlined,
  InboxOutlined,
  ProjectOutlined,
  ContactsOutlined,
  EuroCircleOutlined,
  FileDoneOutlined,
  MonitorOutlined
} from '@ant-design/icons';
import styled from 'styled-components';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';
import useErrorMessage from '../../../utils/ErrorMessage';
import ModalSearch from '../ModalSearch';
import ChartQuotation from './ChartQuotation';
import ChartValuation from './ChartValuation';
import ModalForm from './ModalForm';

const { Panel } = Collapse;

const HomeCommercial = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [movementsPlanned, setMovementPlanned] = useState([]);
  const [dataForm, setDataForm] = useState();

  const Cell = styled.div`
    list-style: none;
    user-select: none;
    width: 100%;
    height: 100%;
  `;
  const getGraphQteData = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/dashboard/commercial/${user._id}`
      });
      setGraphData(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getmovements = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/meeting?user=${user?._id}&populate=quotation,site.contact&sort=-date`
      });
      setMovementPlanned(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    if (user._id) {
      (async () => {
        setLoading(true);
        await getGraphQteData();
        await getmovements();
        setLoading(false);
      })();
    }
  }, []);

  const setDataToForm = (value) => {
    setDataForm(value);
    setVisible(true);
  };
  const colors = { COMMERCIAL: 'warning' };
  const dateCellRender = (value) => {
    return (
      <Cell>
        {movementsPlanned
          .sort((a, b) => new Date(a?.date) - new Date(b?.date))
          .map((e) => {
            return moment(e?.date).format('DD-MM-YYYY') ===
              value.format('DD-MM-YYYY') ? (
              <li
                style={{ fontSize: 10, paddingBottom: 5, textAlign: 'center' }}
              >
                <Tag onClick={() => setDataToForm(e)} color={colors.COMMERCIAL}>
                  {`À ${moment(e?.date).format('HH:mm')}`}
                </Tag>
                <p>{e?.site?.reference}</p>
              </li>
            ) : null;
          })}
      </Cell>
    );
  };

  return (
    <>
      <PageHeaderCustom title={t('Commercial.title')} />
      <Spin spinning={loading}>
        <ContentCustom>
          <Row justify="space-around" gutter={[0, 10]}>
            <Col xs={10} md={5} lg={4} xl={3}>
              <Link
                to={{
                  pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.QUOTATIONS}`
                }}
              >
                <Button icon={<InboxOutlined />} type="primary">
                  Devis
                </Button>
              </Link>
            </Col>
            <Col xs={10} md={5} lg={4} xl={3}>
              <Link
                to={{
                  pathname: `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.INVOICES}`
                }}
              >
                <Button icon={<EuroOutlined />} type="primary">
                  Factures
                </Button>
              </Link>
            </Col>
            <Col xs={10} md={5} lg={4} xl={3}>
              <Link
                to={{
                  pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.SITES}`
                }}
              >
                <Button icon={<ProjectOutlined />} type="primary">
                  Chantiers
                </Button>
              </Link>
            </Col>
            <Col xs={10} md={5} lg={4} xl={3}>
              <Link
                to={{
                  pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.DEALS}`
                }}
              >
                <Button icon={<ContactsOutlined />} type="primary">
                  CRM
                </Button>
              </Link>
            </Col>
            <Col xs={24} lg={8}>
              <ModalSearch />
            </Col>
          </Row>
          <Divider />
          <Row style={{ margin: 30 }} justify="space-around" gutter={[0, 8]}>
            <Col style={{ textAlign: 'center' }} lg={6} md={12} xs={24}>
              <Statistic
                title="Devis validés"
                value={graphData?.quotations?.length}
                prefix={<FileDoneOutlined />}
                valueStyle={{
                  color: 'var(--addColor)',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              />
            </Col>
            <Col lg={6} md={12} xs={24} style={{ textAlign: 'center' }}>
              <Statistic
                title="Devis facturés en totalité"
                prefix={<MonitorOutlined />}
                value={graphData?.invoices?.length}
                valueStyle={{
                  color: 'var(--addColor)',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              />
            </Col>
            <Col lg={6} md={12} xs={24} style={{ textAlign: 'center' }}>
              <Statistic
                title="Valorisation des factures payées (paiement partiel et total)"
                value={`${graphData?.invoices
                  ?.map((e) => (e.total_paid > 0 ? e.total_paid : 0))
                  .reduce((a, b) => a + b, 0)}`}
                suffix={<EuroCircleOutlined />}
                valueStyle={{
                  color: 'var(--addColor)',
                  textAlign: 'center'
                }}
              />
            </Col>
            <Col style={{ textAlign: 'center' }} lg={6} md={12} xs={24}>
              <Statistic
                title="Prospects prioritaires"
                value={graphData?.deals?.length}
                valueStyle={{
                  color: 'red',
                  textAlign: 'center',
                  fontWeight: 'bold'
                }}
              />
            </Col>
          </Row>

          <Row justify="center">
            <Collapse style={{ width: '100%' }}>
              <Panel
                header={
                  <Row>
                    <Col span={20}>
                      <span>Planning intervention technique</span>
                    </Col>
                    <Col span={4}>
                      <span
                        style={{
                          marginRight: 10,
                          color: 'var(--disabledColor)'
                        }}
                      >
                        Interventions non réalisées
                      </span>
                      <Badge
                        //   className="site-badge-count-109"
                        count={
                          movementsPlanned?.filter(
                            (e) =>
                              moment(e?.date).format('DD-MM-YYYY') >
                              moment(Date.now()).format('DD-MM-YYYY')
                          )?.length
                        }
                        style={{ backgroundColor: '#52c41a' }}
                      />
                    </Col>
                  </Row>
                }
                key="1"
              >
                <Calendar
                  dateCellRender={dateCellRender}
                  // headerRender={headerRender}
                  mode="month"
                  style={{ marginRight: 25, padding: 10, userSelect: 'none' }}
                />
              </Panel>
            </Collapse>
          </Row>
          <Row
            style={{ marginTop: 30 }}
            justify="space-between"
            gutter={[0, 16]}
          >
            <Col xl={11} xs={24}>
              <ChartValuation data={graphData?.chartDataBuisness} />
            </Col>
            <Col xl={11} xs={24}>
              <ChartQuotation data={graphData?.chartDataQuotation} />
            </Col>
          </Row>
          <ModalForm
            loading={loading}
            data={dataForm}
            setLoading={setLoading}
            getData={getmovements}
            visible={visible}
            setVisible={setVisible}
          />
        </ContentCustom>
      </Spin>
    </>
  );
};

export default HomeCommercial;
