import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { Popconfirm, Divider, Tag } from 'antd';
import { useDrag } from 'react-dnd';
import {
  EyeOutlined,
  CloseOutlined,
  WarningOutlined,
  EditOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import useDealContext from './DealsContext';
import { tasksPriorities } from '../../../../utils/constants/tagColors';

const Card = styled.div`
  background-color: #fff;
  margin-top: 0px;
  margin-bottom: 10px;
  height: 150px;
  width: 96%;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 9px 16px -6px rgba(0, 0, 0, 0.25);
  padding: 10px 10px 10px 16px;
  position: relative;

  a,
  .anticon:not(.anticon-close) {
    color: #7c7c7c;
  }

  .anticon:not(.anticon-close) {
    transition: all 0.3s;
    &:hover {
      font-size: 24px;
    }
  }
`;
const Close = styled.div`
  position: absolute;
  top: 10px;
  right: 12px;
  color: var(--secondaryColor);
  font-size: 12px;
  cursor: pointer;
  height: 24px;
  display: flex;
  align-items: center;

  .anticon-close {
    transition: all 0.3s;
    &:hover {
      font-size: 16px;
    }
  }
`;
const EditInfo = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 20px;
`;

const DealCard = ({ deal }) => {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { _id, name, lead, priority } = deal;
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'card',
      id: _id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging()
    })
  });

  const { fetchData, delDeal, setVisible, setCurrentDeal } = useDealContext();

  const [visibility, setVisibility] = React.useState('hidden');
  const styles = {
    visibility: `${visibility}`
  };

  return (
    <Card
      ref={drag}
      style={{ opacity: isDragging ? '0.5' : '1' }}
      onMouseEnter={() => setVisibility('visible')}
      onMouseLeave={() => setVisibility('hidden')}
    >
      <div style={{ fontSize: 20, marginBottom: 20 }}>{name}</div>
      <div>
        <span style={{ fontWeight: 'bold', marginRight: 10 }}>
          {`${t(`deals.list.responsible`)} :`}
        </span>
        <span>{lead && `${lead.last_name} ${lead.first_name}`}</span>
      </div>
      <div>
        <span style={{ fontWeight: 'bold', marginRight: 10 }}>
          {`${t(`deals.list.priority`)} :`}
        </span>
        <span>
          <Tag color={tasksPriorities[priority]}>
            {t(`deals.tags.${priority}`)}
          </Tag>
        </span>
      </div>
      <Popconfirm
        title={t(`deals.list.popconfirm.title`)}
        okText={t(`deals.list.popconfirm.okText`)}
        cancelText={t(`deals.list.popconfirm.cancelText`)}
        onConfirm={() => {
          delDeal(_id);
          fetchData();
        }}
        icon={<WarningOutlined />}
      >
        <Close style={styles}>
          <CloseOutlined />
        </Close>
      </Popconfirm>
      <EditInfo style={styles}>
        <Link
          to={{
            pathname: `${pathname}/show/${_id}`
          }}
        >
          <EyeOutlined />
        </Link>
        <Divider type="vertical" />
        <EditOutlined
          onClick={() => {
            setCurrentDeal(deal);
            setVisible(true);
          }}
        />
      </EditInfo>
    </Card>
  );
};

DealCard.propTypes = {
  deal: PropTypes.shape({
    _id: PropTypes.string,
    name: PropTypes.string,
    lead: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string
    }),
    priority: PropTypes.string
  }).isRequired
};

export default DealCard;
