import React, { useState } from 'react';
import { Drawer, Button, Row, Form, message, Col, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CheckOutlined,
  CloseOutlined,
  UpOutlined,
  DownOutlined
} from '@ant-design/icons';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import useAuthContext from '../../../contexts/AuthContext';
import useFields from '../PrimaryMatters/fields';
import { noLabelLayout } from '../../../utils/constants/formLayout';

const NewPrimaryMattersDrawer = ({
  setForceRefresh,
  forceRefresh,
  getResources
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const { basicFields, advancedFields } = useFields(form);
  const [visible, setVisible] = useState(false);
  const [visibleOptions, setVisibleOptions] = useState(false);

  const onFinish = async (values) => {
    try {
      await dispatchAPI('POST', {
        url: `/primary-matters`,
        body: { ...values }
      });
      message.success(`Matière première enregistré !`);
      setVisible(false);
      getResources();
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message.error('Erreur serveur');
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const cancelButton = () => {
    setVisible(false);
  };

  const handleValueChange = (_, { type }) => {
    if (type === 'POLYOL') form.setFieldsValue({ density: 1.18 });
    if (type === 'ISOCYANATE') form.setFieldsValue({ density: 1.23 });
  };
  return (
    <>
      <Row justify="start">
        <Button type="primary" onClick={showDrawer}>
          Créer une matière première
        </Button>
      </Row>
      <Drawer
        title="Créer une matière première"
        placement="right"
        closable={false}
        onClose={onClose}
        destroyOnClose
        visible={visible}
        width={500}
      >
        <Form
          form={form}
          onValuesChange={(_, values) => handleValueChange(_, values)}
          onFinish={onFinish}
        >
          {basicFields.map((field) => generateFields('primary-matters', field))}
          <Divider />
          <Row style={{ marginTop: 30, marginBottom: 30 }}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Col {...noLabelLayout.wrapperCol}>
              <Button
                type="link"
                onClick={() => setVisibleOptions(!visibleOptions)}
              >
                {t('primary-matters.form.options')}
                {visibleOptions ? (
                  <UpOutlined style={{ marginLeft: 10 }} />
                ) : (
                  <DownOutlined style={{ marginLeft: 10 }} />
                )}
              </Button>
            </Col>
          </Row>
          {visibleOptions &&
            advancedFields.map((field) =>
              generateFields('primary-matters', field)
            )}
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="primary"
              htmlType="submit"
            >
              {t('buttons.save')}
              <CheckOutlined />
            </Button>
            <Button onClick={cancelButton}>
              {`${t('buttons.cancel')}`}
              <CloseOutlined />
            </Button>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

NewPrimaryMattersDrawer.propTypes = {
  forceRefresh: PropTypes.bool.isRequired,
  setForceRefresh: PropTypes.func.isRequired,
  getResources: PropTypes.func.isRequired
};

export default NewPrimaryMattersDrawer;
