import React from 'react';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';

const useColumns = (id) => {
    const { t } = useTranslation();

    return [
        {
            title: t('movements.columns.show_month.subject'),
            key: 'number',
            render: ({quotation}) => quotation && quotation.number,
        },
        {
            title: t('movements.columns.show_month.date'),
            key: 'date',
            render: (intervention) => intervention && moment(intervention.date).format('DD-MM-YYYY'),
        },
        {
            title: t('movements.columns.show_month.truck_departure'),
            key: 'departure',
            render: ({CSTB}) => {
                const hours = CSTB.hours_control.find(el => el.technician_id === id);
                return hours && moment(hours.start_truck).format('HH:mm');
            },
        },
        {
            title: t('movements.columns.show_month.intervention_start'),
            key: 'intervention_start',
            render: ({CSTB}) => {
                const hours = CSTB.hours_control.find(el => el.technician_id === id);
                return hours && moment(hours.start_movement).format('HH:mm');
            },
        },
        {
            title: t('movements.columns.show_month.intervention_end'),
            key: 'intervention_end',
            render: ({CSTB}) => {
                const hours = CSTB.hours_control.find(el => el.technician_id === id);
                return hours && moment(hours.due_movement).format('HH:mm');
            },
        },
        {
            title: t('movements.columns.show_month.truck_return'),
            key: 'truck_return',
            render: ({CSTB}) => {
                const hours = CSTB.hours_control.find(el => el.technician_id === id);
                return hours && moment(hours.due_truck).format('HH:mm');
            },
        },
        {
            title: t('movements.columns.transport_duration'),
            key: 'transport_duration',
            render: ({CSTB}) => {
                const hours = CSTB.hours_control.find(el => el.technician_id === id);
                return hours && hours.time_truck;
            },
        },
        {
            title: t('movements.columns.intervention_duration'),
            key: 'intervention_duration',
            render: ({CSTB}) => {
                const hours = CSTB.hours_control.find(el => el.technician_id === id);
                return hours && hours.time_intervention;
            },
        },
        {
            title: t('movements.columns.break_duration'),
            key: 'break_duration',
            render: ({CSTB}) => {
                const hours = CSTB.hours_control.find(el => el.technician_id === id);
                return hours && moment(hours.break).format('HH:mm');
            },
        },
        {
            title: t('movements.columns.cart_night'),
            key: 'basket_night',
            render: ({CSTB}) => {
                const hours = CSTB.hours_control.find(el => el.technician_id === id);
                return hours && (hours.basket_night ? 'Oui' : 'Non');
            },
        },
    ];
};

export default useColumns;
