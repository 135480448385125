/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import {
  Col,
  Row,
  Select,
  Skeleton,
  Table,
  Typography,
  Card,
  Tag,
  Timeline
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import useAuthContext from '../../../contexts/AuthContext';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../utils/ErrorMessage';
import RemainingToPay from './Components/RemainingToPay';
import Organization from './Components/Organization';
import Customer from './Components/Customer';
import Contact from './Components/Contact';
import QuotePart from './Components/QuotePart';
import ExtraShowActions from './Components/ExtraShowActions';

const { Title } = Typography;
const { Column } = Table;
const { Option } = Select;

const borderBottom = '1px solid var(--borderColor)';

const ShowUnpaid = ({ resource }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message: errorMessage } = useErrorMessage();
  const { id } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [displayedResource, setResource] = useState({});
  const [specificsMentions, setSpecificsMentions] = useState([]);
  const [selectedMentions, setSelectedMentions] = useState([]);
  const [remaining, setRemaining] = useState();

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/invoices/${id}?type=PAY,DEPOSIT&populate=customer,contact,quotation.invoices,site,organization,vat`
      });
      setResource(data);
      setSelectedMentions(data.quotation.mentions);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getSpecificsMentions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotations/enums`
      });
      setSpecificsMentions(data.legal_mentions);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const fetchResource = useCallback(async () => {
    setLoading(true);
    await getResource();
    await getSpecificsMentions();
    setLoading(false);
  }, [id]);

  const getRemaining = () => {
    if (Object.keys(displayedResource).length !== 0) {
      const result = displayedResource?.quotation?.invoices
        .map((e) =>
          e.total_paid > e.subTotal ? e.total_paid - e.total_VAT : e.total_paid
        )
        .reduce((a, b) => a + b, 0);
      setRemaining(result);
    }
  };

  useEffect(() => {
    getRemaining();
  }, [displayedResource]);

  useEffect(() => {
    fetchResource();
  }, [fetchResource, id]);

  const saveMentions = async (mentions) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/quotations/${id}`,
        body: { mentions }
      });
      setSelectedMentions(mentions);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  return (
    <>
      <PageHeaderCustom
        title={t(`${resource}.show.number`, {
          number: `${!displayedResource.email_sent ? 'P-' : ''}${
            displayedResource.number
          }`
        })}
        extra={
          <>
            <ExtraShowActions
              invoice={displayedResource}
              getResource={getResource}
            />
          </>
        }
      />
      <Card style={{ margin: 20 }}>
        <ContentCustom>
          <Row justify="space-between" style={{ marginBottom: 20 }}>
            <Col span={6}>
              <Organization
                display={displayedResource.organization}
                isLoading={isLoading}
                resource="invoices"
              />
            </Col>
            <Col span={6}>
              <Customer
                customer={displayedResource.customer}
                isLoading={isLoading}
                resource="invoices"
              />
            </Col>
            <Col span={6}>
              <Contact
                isLoading={isLoading}
                contact={displayedResource.contact}
                resource="invoices"
              />
            </Col>
          </Row>
          <Row style={{ marginTop: 20, marginBottom: 20 }}>
            {resource === 'invoices' && (
              <Col span={6}>
                <RemainingToPay
                  isLoading={isLoading}
                  invoice={displayedResource}
                />
              </Col>
            )}
          </Row>
          <Row jusity="center">
            <Col span={12}>
              <Title level={3}>
                {t(`${resource}.show.title`)}&nbsp;{displayedResource.subject}
              </Title>
              {displayedResource.comment && (
                <i>
                  {t(`${resource}.show.comment`)}&nbsp;
                  {displayedResource.comment}
                </i>
              )}
              <Timeline>
                <Timeline.Item>
                  {t(`${resource}.show.created_at`, {
                    date: moment(displayedResource.created_at).format('LL')
                  })}
                </Timeline.Item>
                {displayedResource.emission_date && (
                  <Timeline.Item>
                    {t(`${resource}.show.emission`, {
                      date: moment(displayedResource.emission_date).format('LL')
                    })}
                  </Timeline.Item>
                )}
                {displayedResource.payment_date && (
                  <Timeline.Item>
                    {t(`${resource}.show.payment`, {
                      date: moment(displayedResource.payment_date).format('LL')
                    })}
                  </Timeline.Item>
                )}
                {displayedResource.total_paid &&
                displayedResource.total_paid !== displayedResource.total ? (
                  <Timeline.Item>
                    {displayedResource.total_paid &&
                    displayedResource.total_paid !== displayedResource.total
                      ? t(`${resource}.show.remaining`, {
                          amount:
                            displayedResource.total -
                            displayedResource.total_paid
                        })
                      : ''}
                  </Timeline.Item>
                ) : (
                  ''
                )}
              </Timeline>
            </Col>
            {displayedResource.quotation && (
              <QuotePart quote={displayedResource} />
            )}
          </Row>
          {displayedResource.type_invoice !== 'UPPER' ? (
            <Row justify="space-between">
              <Col span={24}>
                <Card bordered={false}>
                  {displayedResource?.quotation?.invoices.map((e) => (
                    <Row justify="space-between" key={e._id}>
                      <Col span={10}>
                        <Row justify="space-between">
                          <span>{`- Facture n° ${e.number}`}</span>
                          <Tag
                            color={
                              e.type_invoice === 'GLOBAL'
                                ? 'var(--addColor)'
                                : null
                            }
                          >
                            {t(`invoices.tags.${e.type_invoice}`)}
                          </Tag>
                        </Row>
                      </Col>
                      <Col span={4}>
                        <span>
                          {e?.emission_date &&
                            `Émis le ${moment(e?.emission_date).format(
                              'DD-MM-YYYY'
                            )}`}
                        </span>
                      </Col>
                      <Col span={4}>
                        <span>{`${e?.total} €`}</span>
                      </Col>
                      <Col span={6}>
                        {e?.pay_off ? <span>{e?.pay_off}</span> : null}
                      </Col>
                    </Row>
                  ))}
                </Card>
              </Col>
            </Row>
          ) : null}

          <Skeleton
            active
            title={false}
            loading={isLoading}
            paragraph={{
              rows: 4,
              width: ['100%', '100%', '100%', '100%']
            }}
          >
            <Table
              dataSource={(
                displayedResource.prestations || []
              ).map(({ _id, ...article }) => ({ ...article, key: _id }))}
              pagination={false}
            >
              <Column
                title={t('invoices.showInvoice.prestationsTable.label')}
                dataIndex="label"
              />

              <Column
                title={t('invoices.showInvoice.prestationsTable.unit_price')}
                dataIndex="unit_price"
                align="right"
                render={(price) => `${numeral(price).format('0,0.00')}€`}
              />

              <Column
                title={t('invoices.showInvoice.prestationsTable.discount')}
                dataIndex="discount"
                align="right"
                render={(discount) =>
                  discount
                    ? `${numeral(discount.value).format('0,0.00')}${
                        discount.type === 'PERCENT' ? '%' : '€'
                      }`
                    : 'N/A'
                }
              />
              <Column
                title={t('invoices.showInvoice.prestationsTable.subTotal')}
                rowKey="_id"
                align="right"
                render={({ subtotal }) => {
                  return `${numeral(subtotal).format('0,0.00')}€`;
                }}
              />
            </Table>
          </Skeleton>

          <Row style={{ marginTop: 50 }}>
            <Col span={24}>
              <p>INCLUS :</p>
              <Select
                mode="tags"
                style={{ width: '45%' }}
                value={selectedMentions}
                optionLabelProp="label"
                onChange={saveMentions}
              >
                {specificsMentions?.map((el) => (
                  <Option key={el} value={el}>
                    {el}
                  </Option>
                ))}
              </Select>
            </Col>
          </Row>

          <Row style={{ marginTop: 50 }}>
            <Col
              style={{
                background: 'var(--bodyBackground)',
                border: '1px solid var(--borderColor)',
                padding: 8
              }}
              xs={{ offset: 8, span: 16 }}
              md={{ offset: 14, span: 10 }}
            >
              <Skeleton active loading={isLoading} paragraph={0}>
                <Row style={{ borderBottom }}>
                  <Col span={8} style={{ textAlign: 'left' }}>
                    <Typography.Text style={{ fontSize: '16px' }}>
                      {`${t('invoices.showInvoice.subTotal')} `}
                    </Typography.Text>
                  </Col>
                  <Col span={16} style={{ textAlign: 'right' }}>
                    <Typography.Text style={{ fontSize: '16px' }}>
                      {`${numeral(displayedResource.subTotal || 0).format(
                        '0,0.00'
                      )}€`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row style={{ borderBottom }}>
                  <Col span={8} style={{ textAlign: 'left' }}>
                    <Typography.Text style={{ fontSize: '16px' }}>
                      {`${t('invoices.showInvoice.vat')} (${displayedResource
                        ?.vat?.rate || ''} %)`}
                    </Typography.Text>
                  </Col>
                  <Col span={16} style={{ textAlign: 'right' }}>
                    <Typography.Text style={{ fontSize: '16px' }}>
                      {`${numeral(displayedResource.total_VAT || 0).format(
                        '0,0.00'
                      )}€`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row style={{ borderBottom }}>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Typography.Text style={{ fontSize: '16px' }}>
                      {t('invoices.showInvoice.holdback')}
                    </Typography.Text>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Typography.Text style={{ fontSize: '16px' }}>
                      {`${numeral(displayedResource.holdback || 0).format(
                        '0,0.00'
                      )} %`}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row>
                  <Col span={8} style={{ textAlign: 'left' }}>
                    <Typography.Text style={{ fontSize: '16px' }}>
                      {t('invoices.showInvoice.discount')}
                    </Typography.Text>
                  </Col>
                  <Col span={16} style={{ textAlign: 'right' }}>
                    <Typography.Text style={{ fontSize: '16px' }}>
                      {`${numeral(displayedResource.total_discount || 0).format(
                        '0,0.00'
                      )}€`}
                    </Typography.Text>
                  </Col>
                </Row>
              </Skeleton>
            </Col>
          </Row>
          <Row style={{ margin: '30px 0' }}>
            <Col xs={{ offset: 8, span: 16 }} md={{ offset: 14, span: 10 }}>
              <Skeleton active loading={isLoading} paragraph={0}>
                <Row type="flex" style={{ height: '100%' }}>
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Typography.Text strong style={{ fontSize: '18px' }}>
                      {t('invoices.showInvoice.total_remaining', {
                        date: displayedResource.payment_date
                          ? `${moment(displayedResource.payment_date).format(
                              'L'
                            )}`
                          : ''
                      })}
                    </Typography.Text>
                  </Col>
                  <Col
                    span={12}
                    style={{
                      textAlign: 'right',
                      display: 'flex',
                      alignItems: 'flex-end',
                      justifyContent: 'flex-end'
                    }}
                  >
                    <Typography.Text strong style={{ fontSize: '18px' }}>
                      {`${numeral(
                        displayedResource.total ||
                          0 + displayedResource.VAT ||
                          0
                      ).format('0,0.00')}€`}
                    </Typography.Text>
                  </Col>
                </Row>
              </Skeleton>
            </Col>
          </Row>
        </ContentCustom>
      </Card>
    </>
  );
};

ShowUnpaid.propTypes = {
  resource: PropTypes.string.isRequired
};

export default ShowUnpaid;
