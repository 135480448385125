import moment from 'moment';
import useInterventionContext from './InterventionContext';

const useRows = (begin) => {
  const { trucks, movesPlanned, start } = useInterventionContext();
  const date = start !== undefined ? start : begin;

  const rows = trucks.map((truck) => ({
    key: truck._id,
    trucks: truck,
    [moment(date).format('YYYY-MM-DD')]: truck._id,
    [moment(date)
      .add(1, 'days')
      .format('YYYY-MM-DD')]: truck._id,
    [moment(date)
      .add(2, 'days')
      .format('YYYY-MM-DD')]: truck._id,
    [moment(date)
      .add(3, 'days')
      .format('YYYY-MM-DD')]: truck._id,
    [moment(date)
      .add(4, 'days')
      .format('YYYY-MM-DD')]: truck._id,
    [moment(date)
      .add(5, 'days')
      .format('YYYY-MM-DD')]: truck._id
  }));

  // eslint-disable-next-line array-callback-return
  movesPlanned.map((move) => {
    for (let i = 0; i < rows.length; i += 1) {
      if (rows[i].trucks?._id === move.truck) {
        // eslint-disable-next-line no-restricted-syntax
        for (const [key] of Object.entries(rows[i])) {
          if (key === moment(move.date).format('YYYY-MM-DD')) {
            if (typeof rows[i][key] === 'string') {
              rows[i][key] = [
                {
                  id: move._id,
                  status: move.status,
                  reference: move.site?.reference,
                  quotation: move.quotation?.number,
                  works_supervisor: `${move.works_supervisor?.first_name} ${move.works_supervisor?.last_name}`,
                  contact: move.works_supervisor?.phone_number_mobile,
                  address: move.site?.address_details_1,
                  users: move.technicians?.length,
                  area: move.prestas.map((e) => e.area),
                  thickness: move.prestas.map((e) => e.thickness),
                  prestas: move.prestas,
                  quotationPrestations: move?.quotation?.prestations,
                  annotations: move?.site?.annotations,
                  truck: move.truck,
                  color: move?.site?.department?.color,
                  duplications: move.duplications,
                  initial: move.initial,
                  type: move.type,
                  date: move?.date
                }
              ];
            } else {
              rows[i][key].push({
                id: move._id,
                reference: move.site?.reference,
                quotation: move.quotation?.number,
                works_supervisor: `${move.works_supervisor?.first_name} ${move.works_supervisor?.last_name}`,
                contact: move.works_supervisor?.phone_number_mobile,
                address: move.site?.address_details_1,
                users: move.technicians?.length,
                area: move.prestas.map((e) => e.area),
                thickness: move.prestas.map((e) => e.thickness),
                prestas: move.prestas,
                quotationPrestations: move?.quotation?.prestations,
                annotations: move?.site?.annotations,
                truck: move.truck,
                color: move?.site?.department?.color,
                duplications: move.duplications,
                initial: move.initial,
                type: move.type,
                date: move?.date
              });
            }
          }
        }
      }
    }
  });
  return rows;
};

export default useRows;
