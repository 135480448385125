import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import { Button, Menu } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { SubMenu } = Menu;

const ExportButton = () => {
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const generateExportToCSV = async (purpose) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/invoices/generate-export/${purpose}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/xlsx'
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${response.headers['content-disposition'].slice(
        21,
        -5
      )}.xlsx`;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status, 6);
    }
  };

  return (
    <Menu style={{ width: 256 }} mode="inline">
      <SubMenu
        key="sub1"
        icon={<DownloadOutlined />}
        title="Exporter au format XLSX"
      >
        <Menu.Item>
          <Button onClick={() => generateExportToCSV('month')} type="link">
            Mois en cours
          </Button>
        </Menu.Item>
        <Menu.Item>
          <Button onClick={() => generateExportToCSV('all')} type="link">
            Toutes les factures
          </Button>
        </Menu.Item>
      </SubMenu>
    </Menu>
  );
};
export default ExportButton;
