/* eslint-disable no-restricted-globals */
/* eslint-disable react/no-unescaped-entities */
import React, { useEffect, useState, useCallback } from 'react';
import {
  Descriptions,
  Divider,
  Button,
  Spin,
  Row,
  Col,
  Card,
  Modal,
  message,
  Popconfirm,
  Tag
} from 'antd';
import { SafetyOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import useMovementContext from '../PostIntervention/MovementsContext';
import ShowHourControl from './ShowHourControl';
import ShowCstb from './ShowCstb';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import ShowGlobalInvoice from './ShowGlobalInvoice';
import ShowUpperArea from './ShowUpperArea';
import ListInvoices from './ListInvoices';

const ShowInvoicing = () => {
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [hourVisible, setHourVisible] = useState(false);
  const [cstbData, setCstbData] = useState();
  const [visibleCstb, setVisibleCstb] = useState();
  const [hourData, setHourData] = useState(null);
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [showGobal, setShowGlobal] = useState(false);
  const [showUpper, setShowUpper] = useState(false);
  const { CSTB, getMovement, cstblist } = useMovementContext();
  const [movementsToSite, setMovementToSite] = useState([]);
  const [listInvoices, setListInvoices] = useState();

  const allInterventions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/movements/search/${CSTB?.quotation?.number}`
      });
      setMovementToSite(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const createInvoice = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/invoices/intervention`,
        body: CSTB?.CSTB
      });
      getMovement(id);
      message.success('Facture crée');
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getInvoices = useCallback(async () => {
    if (CSTB?.quotation) {
      try {
        const { data } = await dispatchAPI('GET', {
          url: `/invoices?site=${CSTB?.site?._id}`
        });
        setListInvoices(data);
      } catch (e) {
        if (e.response) errorMessage(e.response.status);
      }
    }
  }, [CSTB]);

  useEffect(() => {
    if (CSTB) {
      allInterventions();
      getInvoices();
    }
  }, [CSTB]);

  useEffect(() => {
    (async () => {
      await setLoading(true);
      await getMovement(id);
      await setLoading(false);
    })();
  }, [id]);
  const checkIsNaN = (value) => {
    if (isNaN(value)) return 0;
    return value;
  };

  const getEstimatedKilo = (purpose, cstb) => {
    if (purpose === 'one') {
      const totalArea = cstb
        .map((area) => area.area)
        .reduce((a, b) => a + b, 0);
      const totalThickness = cstb
        .map(
          (thick) =>
            thick.thickness.reduce((a, b) => a + b, 0) / thick.thickness.length
        )
        .reduce((a, b) => a + b, 0);
      const result = totalArea * totalThickness * 0.42;
      return parseFloat(isNaN(result) ? 0 : result).toFixed(2);
    }
    if (purpose === 'all') {
      const totalArea = cstblist
        .map((area) =>
          area.details_after.map((el) => el.area).reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0);
      const totalThickness =
        cstblist
          .map((detail) =>
            detail.details_after.length > 0
              ? detail.details_after
                  .map(
                    (ele) =>
                      ele.thickness.reduce((a, b) => a + b, 0) /
                      ele.thickness.length
                  )
                  .reduce((a, b) => a + b, 0) / detail.details_after.length
              : 0
          )
          .reduce((a, b) => a + b, 0) / cstblist.length;
      const result = totalArea * totalThickness * 0.42;
      return parseFloat(isNaN(result) ? 0 : result).toFixed(2);
    }
    return false;
  };

  const average_thickness = () => {
    const result = CSTB?.site?.details
      ?.map((e) => e.thickness.reduce((a, b) => a + b, 0) / e.thickness.length)
      .reduce((a, b) => a + b, 0);
    return result ? parseFloat(result) / CSTB?.site?.details.length : 0;
  };
  const getNumber = (purpose) => {
    if (CSTB?.quotation?.prestations && purpose === 'density') {
      const result =
        CSTB?.quotation?.prestations
          .map((e) => e.thickness)
          .reduce((a, b) => a + b, 0) / CSTB?.quotation?.prestations.length;
      return result;
    }
    if (purpose === 'passed' && cstblist) {
      const result = movementsToSite
        .map((e) => e.CSTB?.cycle * e?.truck?.coefficient)

        .reduce((a, b) => a + b, 0);
      return parseFloat(isNaN(result) ? 0 : result).toFixed(2);
    }
    if (CSTB?.site?.details && purpose === 'kgQB' && cstblist) {
      const resultArea = CSTB?.site?.details
        .map((e) => e.area)
        .reduce((a, b) => {
          return a + b;
        }, 0);
      return parseFloat(resultArea * average_thickness() * 0.42).toFixed(2);
    }
    return 0;
  };
  const getDetails = (data) => {
    setHourData(data);
    setHourVisible(true);
  };
  const showCstb = (data) => {
    setCstbData(data);
    setVisibleCstb(true);
  };
  const ecart = (getNumber('density') - average_thickness()).toFixed(2);
  const overArea = () => {
    const result =
      cstblist
        ?.map((e) =>
          e.details_after?.map((a) => a.area).reduce((a, b) => a + b, 0)
        )
        .reduce((a, b) => a + b, 0) - CSTB?.quotation?.total_area;
    return result;
  };
  const ecartThickness = () => {
    const resultQuotation =
      CSTB.quotation.prestations
        .map((presta) => presta.area * (presta.thickness / 100))
        .reduce((a, b) => a + b, 0) / CSTB.quotation.prestations.length;
    const resultReal =
      cstblist
        .map(
          (cstb) =>
            cstb.details_after
              .map(
                (ele) =>
                  ele.area *
                  (ele.thickness.reduce((a, b) => a + b, 0) /
                    ele.thickness.length /
                    100)
              )
              .reduce((a, b) => a + b, 0) / cstb.details_after.length
        )
        .reduce((a, b) => a + b, 0) / cstblist.length;
    const result = resultReal - resultQuotation;
    return parseFloat(isNaN(result) ? 0 : result).toFixed(2);
  };
  const overWeight = () => {
    const totalArea = cstblist
      .map((area) =>
        area.details_after.map((el) => el.area).reduce((a, b) => a + b, 0)
      )
      .reduce((a, b) => a + b, 0);
    const totalThickness =
      cstblist
        .map((detail) =>
          detail.details_after.length > 0
            ? detail.details_after
                .map(
                  (ele) =>
                    ele.thickness.reduce((a, b) => a + b, 0) /
                    ele.thickness.length
                )
                .reduce((a, b) => a + b, 0) / detail.details_after.length
            : 0
        )
        .reduce((a, b) => a + b, 0) / cstblist.length;
    const result = totalArea * totalThickness * 0.42;
    const realPassed = parseFloat(
      checkIsNaN(CSTB?.CSTB?.cycle * CSTB?.truck?.coefficient)
    ).toFixed(2);
    return parseFloat(realPassed - result).toFixed(2);
  };
  return (
    <>
      <Spin spinning={loading}>
        <Card style={{ borderRadius: 0 }}>
          <Row>
            <Col span={8}>
              <span style={{ fontWeight: 'bold' }}>
                Fiche(s) CSTB liée(s) au chantier
              </span>
            </Col>
            <Col span={16}>
              {cstblist
                ? cstblist.map((j) =>
                    j?.status === 'VALIDATE' ||
                    j?.status === 'WAITING_VALIDATION' ? (
                      <Button
                        key={j._id}
                        type={
                          j._id === CSTB?.CSTB?._id ? 'primary' : 'secondary'
                        }
                        icon={
                          j?.status === 'WAITING_VALIDATION' ? (
                            <SafetyOutlined
                              style={{ color: 'var(--secondaryColor)' }}
                            />
                          ) : null
                        }
                        onClick={() => showCstb(j)}
                        style={{ margin: 5 }}
                      >
                        {`Fiche n° ${j?.number} ${
                          j?.status === 'WAITING_VALIDATION'
                            ? `(A valider)`
                            : ''
                        }`}
                      </Button>
                    ) : (
                      <Button
                        type={
                          j._id === CSTB?.CSTB?._id ? 'primary' : 'secondary'
                        }
                      >
                        {`${j?.number} : Intervention en cours`}
                      </Button>
                    )
                  )
                : null}
            </Col>
          </Row>
        </Card>
        <Modal
          destroyOnClose
          closable
          onCancel={() => setVisibleCstb(false)}
          footer={null}
          visible={visibleCstb}
          width={1200}
        >
          <ShowCstb
            setVisible={setVisibleCstb}
            visible={visibleCstb}
            data={cstbData}
            status={cstbData?.status}
          />
        </Modal>
        <Row>
          <Col lg={12} sm={24} xs={24}>
            <Card style={{ height: '100%', borderRadius: 0 }}>
              <Descriptions colunm={1} title="Informations devis">
                <Descriptions.Item label="Surface">
                  <span style={{ fontWeight: 'bold' }}>
                    {`${CSTB?.quotation?.total_area} m²`}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Épaisseur (en cm)">
                  <span style={{ fontWeight: 'bold' }}>
                    {`${getNumber('density')} cm`}
                  </span>
                </Descriptions.Item>
                <Descriptions.Item label="Kg totale prévu">
                  <span style={{ fontWeight: 'bold' }}>
                    {`${Math.round(
                      CSTB?.quotation?.total_area * getNumber('density') * 0.42
                    )} kg`}
                  </span>
                </Descriptions.Item>
              </Descriptions>
              <Descriptions colum={2} title="Détails">
                {CSTB.quotation.prestations.map((prestation) => (
                  <>
                    <Descriptions.Item span={2} label="Surface">
                      {`${prestation.area} m²`}
                    </Descriptions.Item>
                    <Descriptions.Item label="Épaisseur">
                      {`${prestation.thickness} cm`}
                    </Descriptions.Item>
                  </>
                ))}
              </Descriptions>
            </Card>
          </Col>
          <Col lg={12} sm={24} xs={24}>
            <Card style={{ height: '100%', borderRadius: 0 }}>
              <Descriptions
                colon={false}
                colum={4}
                title={
                  <>
                    <span>Fiche relevés technicien</span>
                    {cstblist.filter((ele) => ele.status === 'VALIDATE')
                      .length !== cstblist.length ? (
                      <Tag color="red" style={{ marginLeft: 10 }}>
                        Intervention en cours
                      </Tag>
                    ) : (
                      ''
                    )}
                  </>
                }
              >
                {cstblist.map((details) => (
                  <>
                    <Descriptions.Item span={4} label={details.number} />

                    <Descriptions.Item label="Surfaces">
                      {`${details.details_after
                        .map((el) => el.area)
                        .reduce((a, b) => a + b, 0)} m²`}
                    </Descriptions.Item>
                    <Descriptions.Item label="Épaisseurs">
                      {details.details_after.length > 0 ? (
                        details.details_after.map((el) => (
                          <p style={{ marginLeft: 5 }}>
                            {`${parseFloat(
                              checkIsNaN(
                                el.thickness.reduce((a, b) => a + b, 0) /
                                  el.thickness.length
                              )
                            ).toFixed(2)} cm `}
                          </p>
                        ))
                      ) : (
                        <p>0 cm</p>
                      )}
                    </Descriptions.Item>
                    <Descriptions.Item label="Kg estimés">
                      {`${getEstimatedKilo('one', details.details_after)} Kg`}
                    </Descriptions.Item>
                  </>
                ))}
              </Descriptions>
              <Divider>Total</Divider>
              <Descriptions>
                <Descriptions.Item label="Total surfaces">
                  <p style={{ fontWeight: 'bold' }}>
                    {`${cstblist
                      .map((cstb) =>
                        cstb.details_after
                          .map((el) => el.area)
                          .reduce((a, b) => a + b, 0)
                      )
                      .reduce((a, b) => a + b, 0)} m²`}
                  </p>
                </Descriptions.Item>
                <Descriptions.Item label="Moyenne pondérée épaisseurs">
                  <p style={{ fontWeight: 'bold' }}>
                    {`${parseFloat(
                      checkIsNaN(
                        cstblist
                          .map((cstb) =>
                            cstb.details_after.length > 0
                              ? cstb.details_after
                                  .map(
                                    (ele) =>
                                      ele.thickness.reduce((a, b) => a + b, 0) /
                                      ele.thickness.length
                                  )
                                  .reduce((a, b) => a + b, 0) /
                                cstb.details_after.length
                              : 0
                          )
                          .reduce((a, b) => a + b, 0) / cstblist.length
                      )
                    ).toFixed(2)} cm`}
                  </p>
                </Descriptions.Item>
                <Descriptions.Item label="Total Kg estimés">
                  <p style={{ fontWeight: 'bold' }}>
                    {`${getEstimatedKilo('all')} Kg`}
                  </p>
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </Col>
        </Row>
        <Divider>Informations sur l'intervention</Divider>
        <Row>
          <Col span={24}>
            <Descriptions column={3}>
              <Descriptions.Item label="Cycles">
                <span style={{ fontWeight: 'bold' }}>
                  {`${CSTB?.CSTB?.cycle}`}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Kg passés">
                <span style={{ fontWeight: 'bold' }}>
                  {`${parseFloat(
                    checkIsNaN(CSTB?.CSTB?.cycle * CSTB?.truck?.coefficient)
                  ).toFixed(2)} Kg`}
                </span>
              </Descriptions.Item>
            </Descriptions>
          </Col>

          <Divider>Récapitulatif</Divider>
          <Col span={24}>
            <Descriptions style={{ justifyContent: 'space-around' }} column={2}>
              <Descriptions.Item label="Total Kg passés sur le chantier">
                <span style={{ fontWeight: 'bold' }}>
                  {`${getNumber('passed')} Kg`}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Surconsommation">
                <span
                  style={{
                    fontWeight: 'bold',
                    color: overWeight !== 0 ? 'red' : 'green'
                  }}
                >
                  {`${overWeight()} Kg`}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Ecart epaisseur">
                <span
                  style={{
                    fontWeight: 'bold',
                    color: ecart !== 0 ? 'red' : 'green'
                  }}
                >
                  {`${ecartThickness()} cm (moyenne pondérée)`}
                </span>
              </Descriptions.Item>

              <Descriptions.Item label="Surplus de surface">
                <span style={{ fontWeight: 'bold' }}>
                  {`${overArea()}  m²`}
                </span>
              </Descriptions.Item>
            </Descriptions>
          </Col>
          <Divider />

          <Col span={10}>
            <span style={{ fontWeight: 'bold' }}>Fiches des heures :</span>
          </Col>
          <Col span={14}>
            {CSTB?.technicians.map((e) =>
              CSTB?.CSTB?.hours_control.map((j) =>
                e._id === j.technician_id ? (
                  <Button
                    onClick={() => getDetails(j)}
                    style={{ margin: 10 }}
                    key={j._id}
                  >
                    {`${e?.first_name} ${e?.last_name}`}
                  </Button>
                ) : null
              )
            )}
          </Col>
        </Row>
        <Divider />
        {user?.type === 'ACCOUNTING' || user?.type === 'DIRECTION' ? (
          <Row users justify="space-around">
            <Col style={{ justifyContent: 'center' }} span={6}>
              {CSTB?.CSTB?.status === 'VALIDATE' ? (
                <>
                  {cstblist.filter(
                    (e) =>
                      e.status !== 'PENDING' &&
                      e.status !== 'WAITING_VALIDATION'
                  ).length === cstblist.length ? null : (
                    <Popconfirm
                      disabled={
                        CSTB?.CSTB?.invoice && CSTB?.CSTB?.invoice !== null
                      }
                      placement="top"
                      title={`Voulez-vous facturer l'intervention : ${CSTB?.CSTB?.number}`}
                      onConfirm={() => createInvoice()}
                      okText="Oui"
                      cancelText="Annuler"
                    >
                      <Button
                        disabled={
                          CSTB?.CSTB?.invoice && CSTB?.CSTB?.invoice !== null
                        }
                        style={{
                          fontWeight: 'bold',
                          color: 'var(--addColor)'
                        }}
                      >
                        {`Facturer l'intervention`}
                      </Button>
                    </Popconfirm>
                  )}
                  {CSTB?.CSTB?.invoice && CSTB?.CSTB?.invoice !== null ? (
                    <span
                      style={{ color: 'var(--disabledColor)', marginLeft: 10 }}
                    >
                      (Intervention facturée)
                    </span>
                  ) : null}
                </>
              ) : null}
            </Col>
            {user?.type !== 'TECH' && listInvoices?.length > 0 ? (
              <Col span={6}>
                <Col span={24}>
                  <Button
                    onClick={() => setShowUpper(true)}
                    style={{
                      fontWeight: 'bold',
                      color: 'var(--secondaryColor)'
                    }}
                  >
                    Facturer un surplus
                  </Button>
                </Col>
                <Col span={24}>
                  <span style={{ color: 'var(--disabledColor)' }}>
                    (La surface totale des interventions dépasse celle du devis)
                  </span>
                </Col>
              </Col>
            ) : null}

            <ShowUpperArea
              setShowModal={setShowUpper}
              showModal={showUpper}
              getInvoices={getInvoices}
              listInvoices={listInvoices}
            />
            <Col style={{ justifyContent: 'end' }} span={6}>
              {cstblist?.filter((e) => e?.invoice === null).length === 0 ? (
                <Col span={24}>
                  <span style={{ color: 'var(--secondaryColor)' }}>
                    La totalité du devis a été facturé
                  </span>
                </Col>
              ) : null}
              {cstblist.filter(
                (e) =>
                  e?.status === 'PENDING' || e?.status === 'WAITING_VALIDATION'
              ).length === 0 ? (
                <Button
                  disabled={
                    cstblist?.filter((e) => e?.invoice === null).length === 0
                  }
                  onClick={() => setShowGlobal(true)}
                  style={{ fontWeight: 'bold', color: 'var(--addColor)' }}
                >
                  Facturation finale
                </Button>
              ) : null}

              <span style={{ color: 'var(--disabledColor)', marginLeft: 10 }}>
                {`(Total interventions ${
                  cstblist.filter(
                    (e) => e?.quotation_id === CSTB?.quotation?._id
                  )?.length
                })`}
              </span>
              {cstblist.filter((e) => e?.status === 'PENDING').length === 0 ? (
                <ShowGlobalInvoice
                  setShowModal={setShowGlobal}
                  showModal={showGobal}
                  getInvoices={getInvoices}
                  listInvoices={listInvoices}
                />
              ) : null}
              <ShowGlobalInvoice
                setShowModal={setShowGlobal}
                showModal={showGobal}
                getInvoices={getInvoices}
                listInvoices={listInvoices}
              />
            </Col>
          </Row>
        ) : null}
        <Divider />
        <ListInvoices
          siteName={CSTB?.site?.reference}
          invoices={listInvoices}
        />
        <Modal
          title="Fiche d'heures"
          closable
          width={1200}
          visible={hourVisible}
          footer={null}
          onCancel={() => setHourVisible(false)}
        >
          <ShowHourControl data={hourData} />
        </Modal>
      </Spin>
    </>
  );
};

export default ShowInvoicing;
