import React, { useState } from 'react';
import { Button, Form, Input, Popconfirm, Switch, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';


const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  checkType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  ...restProps
}) => {
  let inputNode;
  switch (inputType) {
    case 'input':
      inputNode = <Input />;
      break;
    case 'switch':
      inputNode = <Switch defaultChecked={record[dataIndex]} />;
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const DepositTable = ({ depositCols, editDepositCol, deleteDepositCol }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [switchShowLoading] = useState(false);

  const isEditing = record => (record._id === editingKey);

  const edit = record => {
    form.setFieldsValue({
      label: record.label,
      show: record.show,
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const saveSwitch = async ( record ) => {
    try {
      editDepositCol(record._id, record);
      setEditingKey('');
    } catch (e) {
      console.log("Error with Switch change: ", e)
    }
  }

  const save = async record => {
    try {
      const row = await form.validateFields();
      editDepositCol(record._id, row);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };


  const columns = [
    {
      title: t('configuration.deposit.label'),
      dataIndex: 'label',
      width: '20%',
      editable: true
    },
    {
      title: t('configuration.deposit.show'),
      dataIndex: 'show',
      width: '20%',
      editable: true,
      render: (value, record) =>
        <Switch
          loading={switchShowLoading}
          checked={value}
          onClick={async () => {
            record.show = !value;
            await saveSwitch(record);
          }}
        />
    },
    {
      title: t('configuration.deposit.action'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Button href="javascript:;" onClick={() => save(record)} style={{ borderRadius: 0}}>
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm title={t('settings.messages.confirm-deletion')} onConfirm={cancel}>
              <Button style={{ borderRadius: 0}}>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <>
            <Button
              onClick={() => edit(record)}
              disabled={editingKey !== ''}
              type="primary"
              style={{ borderRadius: 0}}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>

            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteDepositCol(record)}
              disabled={editingKey !== ''}
            >
              {/*<Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0}}
              >
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>*/}
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => {
        return ({
          record,
          inputType: ['label'].includes(col.dataIndex) ? 'input' : 'switch',
          checkType: 'switch',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        });
      }
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={depositCols}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 600 }}
      />
    </Form>
  );
};

export default DepositTable;
