import React from 'react';
import PropTypes from 'prop-types';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateOrganization = ({ purpose }) => {
  const { basicFields } = useFields();

  return (
    <CreateUpdateContainer
      fields={basicFields}
      purpose={purpose}
      baseUrl="organizations"
      resource="organizations"
      withSubRoutes
    />
  );
};

CreateUpdateOrganization.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateOrganization;
