import React, { useState } from 'react';
import { Form, Input, Button, InputNumber, Card, Row, Select, Col } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

const { TextArea } = Input;

const FormListPrestation = () => {
  const [displayDiscount, setDisplayDiscount] = useState([]);
  return (
    <Form.List name="prestations">
      {(fields, { remove }) => (
        <>
          {fields.map((field) => (
            <Card style={{ margin: 10, padding: -20 }} key={field.key}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, curValues) =>
                  prevValues.area !== curValues.area ||
                  prevValues.subtotal !== curValues.subtotal
                }
              >
                {() => (
                  <Form.Item
                    {...field}
                    label="Libellé"
                    name={[field.name, 'label']}
                    rules={[{ required: true, message: 'Intitulé manquant' }]}
                  >
                    <Input style={{ width: 500 }} />
                  </Form.Item>
                )}
              </Form.Item>

              <Form.Item
                {...field}
                label="Surface (en m²)"
                name={[field.name, 'area']}
              >
                <InputNumber min={0} />
              </Form.Item>

              <Form.Item
                {...field}
                label="Prix (en €)"
                name={[field.name, 'unit_price']}
              >
                <InputNumber />
              </Form.Item>
              <Row justify="center">
                <Button
                  {...field}
                  type="link"
                  onClick={() => {
                    if (displayDiscount.includes(field.key)) {
                      const temp = displayDiscount.filter(
                        (el) => el !== field.key
                      );
                      setDisplayDiscount(temp);
                    } else {
                      setDisplayDiscount([...displayDiscount, field.key]);
                    }
                  }}
                >
                  Remise
                </Button>

                {displayDiscount.includes(field.key) && (
                  <Row justify="space-around">
                    <Form.Item
                      {...field}
                      name={[field.name, 'discount', 'value']}
                      style={{ marginRight: 50 }}
                    >
                      <InputNumber
                        style={{ width: 200 }}
                        placeholder="Valeur en € ou %"
                        min={0}
                      />
                    </Form.Item>
                    <Form.Item
                      {...field}
                      name={[field.name, 'discount', 'type']}
                    >
                      <Select
                        style={{ width: 200 }}
                        options={[
                          {
                            label: '€ HT',
                            value: 'CURRENCY'
                          },
                          {
                            label: '%',
                            value: 'PERCENT'
                          }
                        ]}
                      />
                    </Form.Item>
                  </Row>
                )}
              </Row>

              <Form.Item
                {...field}
                label="Description"
                name={[field.name, 'comment']}
              >
                <TextArea style={{ width: '100%' }} />
              </Form.Item>

              <Row justify="center">
                <Form.Item
                  {...field}
                  name={[field.name, 'subtotal']}
                  label="Ligne total"
                  style={{ minWidth: '100%', fontWeight: 'bold' }}
                >
                  <InputNumber
                    style={{
                      fontSize: 25,
                      fontWeight: 'bold',
                      width: '100%'
                    }}
                    bordered={false}
                    formatter={(value) =>
                      value && `${parseFloat(value, 10).toFixed(2)} €`
                    }
                    readOnly
                  />
                </Form.Item>
              </Row>
              <Col style={{ textAlign: 'end' }} span={24}>
                <DeleteOutlined
                  style={{ color: 'var(--secondaryColor)', fontSize: 30 }}
                  onClick={() => {
                    remove(field.name);
                  }}
                />
              </Col>
            </Card>
          ))}
        </>
      )}
    </Form.List>
  );
};

export default FormListPrestation;
