import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Statistic, Card, Row, Col } from 'antd';
import {
  PauseOutlined,
  CheckOutlined,
  FileDoneOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

/**
 *  Display  simple percentage for quotation status
 *  @component
 *  @returns {any} react component
 */
function QuotationCardPercentage() {
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [quotations, setQuotations] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [validatedCount, setValidatedCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getQuotations = async () => {
    let quotationsData;
    try {
      const result = await dispatchAPI('GET', { url: `/quotations` });
      setQuotations(result.data);
      quotationsData = result.data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return quotationsData;
  };

  const getPendingQuotationCount = (quotationArray) => {
    return quotationArray
      ?.filter(
        (quotation) => !['DUPLICATED', 'VALIDATE'].includes(quotation.status)
      )
      .reduce((prevQuotation) => {
        return prevQuotation + 1;
      }, 0);
  };
  const getValidatedQuotationCount = (quotationArray) => {
    return quotationArray
      .filter((quotation) => quotation.status === 'VALIDATE')
      .reduce((prevQuotation) => {
        return prevQuotation + 1;
      }, 0);
  };
  useEffect(() => {
    setIsLoading(true);
    getQuotations().then((quotations) => {
      const pendingCountVal = getPendingQuotationCount(quotations);
      setPendingCount(pendingCountVal);
      const validatedCountVal = getValidatedQuotationCount(quotations);
      setValidatedCount(validatedCountVal);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      <Row gutter={16}>
        <Col>
          <Card>
            <Statistic
              loading={isLoading}
              title="Validé"
              value={(validatedCount / quotations.length) * 100}
              precision={2}
              prefix={<FileDoneOutlined />}
              valueStyle={{
                color: 'var(--addColor)',
                textAlign: 'center',
                fontWeight: 'bold'
              }}
              // prefix={<CheckOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
        <Col>
          <Card>
            <Statistic
              title="En Attente"
              loading={isLoading}
              value={(pendingCount / quotations.length) * 100}
              precision={2}
              valueStyle={{
                color: 'red',
                textAlign: 'center',
                fontWeight: 'bold'
              }}
              prefix={<PauseOutlined />}
              suffix="%"
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

QuotationCardPercentage.propTypes = {};

export default QuotationCardPercentage;
