import React, { createContext, useContext } from 'react';
import LightTheme from '../assets/styles/Theme/LightTheme';
import DarkTheme from '../assets/styles/Theme/DarkTheme';
import { useStateWithLocalStorage } from '../utils';

const ThemeContext = createContext({});

export const ThemeContextProvider = ({ children }) => {
  const [darkMode, setDarkMode] = useStateWithLocalStorage('darkTheme', false);

  return (
    <ThemeContext.Provider value={{ darkMode, setDarkMode }}>
      {darkMode ? <DarkTheme /> : <LightTheme />}
      {children}
    </ThemeContext.Provider>
  );
};

export default () => useContext(ThemeContext);
