import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import CreateUpdateContainer from '../../../components/CreateUpdateContainer/CreateUpdateContainer';
import useFields from './fields';

const CreateUpdateEPI = ({ purpose }) => {
  const { basicFields, isFieldsLoading } = useFields();
  const form = useRef();

  const config = {
    onCreateResource: {
      setBody: (data) => {
        return {
          ...data
        };
      }
    },
    onGetResource: {
      setFields: (data) => {
        return {
          ...data,
          mark_up:
            data.sold_price &&
            data.buy_price &&
            Math.round((data.sold_price / data.buy_price) * 100 - 100)
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        return {
          ...data
        };
      }
    }
  };
  const onValuesChange = (_, values) => {
    const { buy_price, mark_up, sold_price } = values;
    let markup;
    if (mark_up) markup = mark_up;
    else markup = 0;
    if (_.buy_price || _.mark_up) {
      form.current.setFieldsValue({
        sold_price: (buy_price + (markup * buy_price) / 100).toFixed(2)
      });
    }
    if (_.sold_price) {
      form.current.setFieldsValue({
        buy_price: (sold_price - (markup * buy_price) / 100).toFixed(2)
      });
    }
  };

  return (
    <CreateUpdateContainer
      fields={basicFields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="epis"
      resource="epi"
      config={config}
      ref={form}
      onValuesChange={onValuesChange}
    />
  );
};

CreateUpdateEPI.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateEPI;
