import React, { useState, useEffect } from 'react';
import { Liquid, measureTextWidth } from '@ant-design/charts';

const GraphLiquid = ({ percent }) => {
  var ref;

  var config = {
    percent,
    shape: 'rect',
    outline: {
      border: 2,
      distance: 4
    },
    wave: { length: 128 },
    statistic: {
      title: {
        formatter: function formatter() {
          return 'Stock disponible';
        },
        style: function style(_ref) {
          var percent = _ref.percent;
          return { fill: 'rgba(44,53,66,0.85)' };
        }
      },
      content: {
        style: function style(_ref2) {
          var percent = _ref2.percent;
          return {
            fontSize: 60,
            lineHeight: 1,
            fill: 'rgba(44,53,66,0.85)'
          };
        },
        customHtml: (container, view, { percent }) => {
          const { width, height } = container.getBoundingClientRect();
          const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2));
          const text = `${(percent * 100).toFixed(0)}%`;
          const textWidth = measureTextWidth(text, { fontSize: 60 });
          const scale = Math.min(d / textWidth, 1);
          return `<div style="width:${d}px;display:flex;align-items:center;justify-content:center;font-size:${scale}em;line-height:${
            scale <= 1 ? 1 : 'inherit'
          }">${text}</div>`;
        }
      }
    },
    liquidStyle: function liquidStyle(_ref4) {
      var percent = _ref4.percent;
      return {
        fill:
          percent > 0.4 ? '#4db3a2' : percent > 0.15 ? '#f07d00' : '#ea4a6c',
        stroke:
          percent > 0.4 ? '#4db3a2' : percent > 0.15 ? '#f07d00' : '#ea4a6c'
      };
    },
    color: function color() {
      return '#4db3a2';
    }
  };

  return (
    <Liquid
      autofit
      radius={0.7}
      {...config}
      chartRef={(chartRef) => (ref = chartRef)}
    />
  );
};

export default GraphLiquid;
