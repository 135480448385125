import { useTranslation } from 'react-i18next';

const useListContent = ({
  name,
  channel_number,
  address_details,
  postal_code,
  city,
  siren_number
}) => {
  const { t } = useTranslation();

  return [
    {
      label: t('organizations.form.name'),
      content: name,
      span: 3
    },
    {
      label: t('organizations.form.channel_number'),
      content: channel_number,
      span: 3
    },
    {
      label: t('organizations.form.address_details'),
      content: address_details,
      span: 3
    },
    {
      label: t('organizations.form.postal_code'),
      content: postal_code,
      span: 3
    },
    {
      label: t('organizations.form.city'),
      content: city,
      span: 3
    },
    {
      label: t('organizations.form.siren_number'),
      content: siren_number,
      span: 3
    }
  ];
};

export default useListContent;
