import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const [responsibleFilter, setResponsibleFilter] = useState([]);
  const isPending = [
    { text: 'Validé', value: false },
    { text: 'En cours', value: true }
  ];

  const fetchResponsible = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'users' });
      setResponsibleFilter(
        data.map(({ first_name, last_name, _id }) => ({
          text: `${first_name} ${last_name}`,
          value: _id
        }))
      );
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchResponsible();
    })();
  }, []);

  return [
    {
      title: t('inventories.column.time'),
      key: 'created_at',
      dataIndex: 'created_at',
      render: (date) => date && moment(date).format('LLL'),
      sorter: true,
      width: '10%'
    },
    {
      title: t('inventories.column.responsible'),
      key: 'inventory_responsible',
      dataIndex: 'inventory_responsible',
      render: (user) =>
        user && (
          <Tag color={user.color}>{`${user.first_name} ${user.last_name}`}</Tag>
        ),
      sorter: true,
      filters: responsibleFilter,
      width: '15%'
    },
    {
      title: t('inventories.column.depot'),
      key: 'deposit',
      dataIndex: 'deposit',
      sorter: true,
      render: (deposit) => deposit.label,
      width: '5%'
    },
    {
      title: t('inventories.column.status'),
      key: 'pending',
      dataIndex: 'pending',
      sorter: true,
      render: (pending) =>
        pending ? (
          <Tag color="red">En cours</Tag>
        ) : (
          <Tag color="var(--addColor)">Validé</Tag>
        ),
      filters: isPending,
      width: '5%'
    },
    {
      title: t('inventories.column.comments'),
      key: 'comments',
      dataIndex: 'comments',
      sorter: true,
      render: (comments) =>
        <p style={{ whiteSpace: 'normal' }}>{comments}</p> || 'N/A',
      filters: isPending,
      width: '20%'
    }
  ];
};

export default useColumns;
