import React, { createContext, useContext } from 'react';

const TemplatesContext = createContext({});

export const TemplatesContextProvider = ({ children }) => {
  const listMenuToDisplay = [
    'other',
    'CSTB',
    'TV',
    'signed_quotation',
    'Template_invoice',
    'Template_pv',
    'Template_quotation',
    'Template_transfer_stock'
  ];
  return (
    <TemplatesContext.Provider value={{ listMenuToDisplay }}>
      {children}
    </TemplatesContext.Provider>
  );
};

export default () => useContext(TemplatesContext);
