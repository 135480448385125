import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select, Input, Form } from 'antd';
import { useTranslation } from 'react-i18next';
import useInvoiceContext from '../InvoiceContext';

const PrestationsLinesInvoicesFields = ({ form }) => {
  const { t } = useTranslation();
  const {
    getPrestations,
    prestations,
    setIsLoading,
    isLoading,
    setListPrestations,
    listPrestations
  } = useInvoiceContext();
  useEffect(() => {
    setIsLoading(true);
    getPrestations();
    setIsLoading(false);
  }, []);
  const addPrestation = ({ label, option, value }) => {
    const tmp = listPrestations;
    tmp.push({
      value,
      prestation: option._id,
      label,
      thickness: option.thickness,
      unit_price: 0,
      subtotal: 0
    });
    setListPrestations([...tmp]);
    form.setFieldsValue({ prestations: [...tmp] });
  };

  return (
    <>
      <Form.Item
        style={{
          background: 'var(--bodyBackground)',
          border: '1px solid var(--borderColor)',
          marginTop: 8,
          padding: '1%'
        }}
        // {...formItemLayout}
        wrapperCol={16}
        label={t(`invoices.form.prestations.select`)}
      >
        <Input.Group>
          <Form.Item name="select_presta" noStyle>
            <Select
              allowClear
              showSearch
              options={prestations?.map(
                ({ reference, label, _id, ...rest }) => ({
                  label: `${reference} - ${label}`,
                  value: _id,
                  option: rest
                })
              )}
              loading={isLoading}
              filterOption={(input, option) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              onSelect={async (_, option) => {
                await addPrestation(option);
                form.resetFields(['select_presta']);
              }}
              style={{ width: '70%', marginRight: '1%' }}
            />
          </Form.Item>
        </Input.Group>
      </Form.Item>
    </>
  );
};

PrestationsLinesInvoicesFields.propTypes = {
  form: PropTypes.oneOfType([PropTypes.object]).isRequired
};

export default PrestationsLinesInvoicesFields;
