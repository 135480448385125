import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('organizations.column.name'),
      key: 'name',
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('organizations.column.channel_number'),
      key: 'channel_number',
      dataIndex: 'channel_number',
      sorter: true
    },
    {
      title: t('organizations.column.address_details'),
      key: 'address_details',
      dataIndex: 'address_details',
      sorter: true
    },
    {
      title: t('organizations.column.postal_code'),
      key: 'postal_code',
      dataIndex: 'postal_code',
      sorter: true
    },
    {
      title: t('organizations.column.city'),
      key: 'city',
      dataIndex: 'city',
      sorter: true
    },
    {
      title: t('organizations.column.siren_number'),
      key: 'siren_number',
      dataIndex: 'siren_number',
      sorter: true
    }
  ];
};

export default useColumns;
