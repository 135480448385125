import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  ColumnHeightOutlined,
  DeleteOutlined,
  EditOutlined,
  ExpandOutlined,
  TeamOutlined,
  WarningOutlined,
  ClockCircleOutlined,
  IssuesCloseOutlined,
  CheckCircleOutlined,
  CalendarOutlined,
  AlertOutlined
} from '@ant-design/icons';
import { useDrag } from 'react-dnd';
import { Col, Popconfirm, Row, Tooltip, TimePicker, DatePicker } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';
import planningEuroEmpty from '../../../assets/images/planningEuroEmpty.svg';
import SplitInterventionModal from './Modal/SplitInterventionModal';
import useErrorMessage from '../../../utils/ErrorMessage';
import useInterventionContext from './InterventionContext';

const setIconToStatus = {
  PLANNED: (
    <ClockCircleOutlined
      style={{
        fontSize: 34,
        color: 'white'
      }}
    />
  ),
  WAITING_VALIDATION: (
    <IssuesCloseOutlined
      style={{
        fontSize: 34,
        color: 'orange'
      }}
    />
  ),
  VALIDATED: (
    <CheckCircleOutlined
      style={{
        fontSize: 34,
        color: 'blue'
      }}
    />
  )
};

const CardPlanning = ({ el, color, isLoading, setIsLoading }) => {
  // eslint-disable-next-line no-unused-vars
  const [{ isDragging }, drag] = useDrag({
    item: {
      type: 'card',
      id: el.id
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging() && setIsLoading({ [el.id]: true }),
      isNotDragging: !monitor.isDragging() && setIsLoading({ [el.id]: false })
    })
  });
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { refresh, setRefresh } = useInterventionContext();
  const [showSiteMeasures, setShowSiteMeasures] = useState(false);
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [dateTimeValue, setDateTimeValue] = useState();
  const [dateValue, setDateValue] = useState();

  const style = {
    display: 'flex',
    height: 'auto',
    width: '98%',
    backgroundColor: color,
    borderRadius: 5,
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'grab',
    color: '#fff',
    padding: '5px',
    marginTop: '1%',
    marginLeft: '1%'
  };

  const totalArea =
    typeof el !== 'string'
      ? el?.area?.reduce((total, cv) => {
          return total + parseFloat(cv);
        }, 0)
      : 0;

  const reduceThickness =
    typeof el !== 'string'
      ? el?.thickness?.reduce((unique, cv) => {
          return unique.includes(cv) ? unique : [...unique, cv];
        }, [])
      : 0;

  const handleDeleteIntervention = async () => {
    try {
      await dispatchAPI('DELETE', {
        url: `/movements/${el.id}`
      });
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const updateDateTimeMovement = async (movement) => {
    try {
      const value = moment(movement.date);
      value.set({
        h: dateTimeValue.format('HH'),
        m: dateTimeValue.format('mm')
      });
      await dispatchAPI('PATCH', {
        url: `/movements/${el.id}`,
        body: { date: value }
      });
      setDateTimeValue();
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  const updateDateMovement = async () => {
    try {
      await dispatchAPI('PATCH', {
        url: `/movements/${el.id}`,
        body: { date: dateValue }
      });
      setDateValue();
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  return (
    <>
      {!isLoading[el.id] && (
        <Tooltip
          placement="leftBottom"
          title={el.annotations}
          color="var(--primaryColor)"
          mouseEnterDelay="0.5"
        >
          <div
            style={style}
            ref={drag}
            onDoubleClick={() =>
              history.push({
                pathname: `/intervention/manage_interventions/show/${el.id}`
              })
            }
          >
            <Row
              gutter={10}
              justify="space-evenly"
              align="middle"
              style={{ padding: '6px 0', minWidth: '100%', maxWidth: '100%' }}
            >
              <Col xs={12} sm={14} md={16} lg={18} xl={20} xxl={22}>
                <span
                  style={{
                    whiteSpace: 'normal'
                  }}
                >
                  {`${el.reference}`}
                </span>
              </Col>
              <Col xs={12} sm={10} md={8} lg={6} xl={4} xxl={2}>
                <Popconfirm
                  placement="right"
                  title={
                    <DatePicker
                      defaultValue={moment(el?.date)}
                      placeholder="..."
                      format="DD-MM-YYYY HH:mm"
                      showTime={{
                        defaultValue: moment('00:00:00', 'HH:mm')
                      }}
                      onChange={(date) => setDateValue(date)}
                      getPopupContainer={(triggerNode) => {
                        return triggerNode.parentNode;
                      }}
                    />
                  }
                  onConfirm={() => updateDateMovement()}
                  okButtonProps={{ disabled: !dateValue }}
                  okText="Valider"
                  cancelText="Annuler"
                >
                  <CalendarOutlined
                    style={{
                      fontSize: 18,
                      color: 'white',
                      float: 'right'
                    }}
                  />
                </Popconfirm>
              </Col>
            </Row>

            <Row
              style={{
                width: '100%',
                maxWidth: '100%',
                flexWrap: 'wrap'
              }}
              gutter={10}
              align="middle"
              justify="space-evenly"
            >
              <Col style={{ display: 'flex', flexDirection: 'column' }}>
                <span style={{ whiteSpace: 'normal' }}>
                  {`${el?.quotation}`}
                </span>
                <span style={{ whiteSpace: 'normal' }}>
                  {el.works_supervisor}
                </span>
                <span style={{ whiteSpace: 'normal' }}>{`${el.contact}`}</span>
              </Col>
              <Col>{setIconToStatus[el.status]}</Col>
            </Row>
            <div
              style={{
                margin: '10px auto 0px',
                width: '100%',
                cursor: 'pointer'
              }}
              onKeyPress={() => setShowSiteMeasures(!showSiteMeasures)}
              onClick={() => setShowSiteMeasures(!showSiteMeasures)}
              role="button"
              tabIndex={0}
            >
              {showSiteMeasures &&
                el?.quotationPrestations?.map((e) =>
                  e.area !== undefined &&
                  e.prestation?.thickness !== undefined ? (
                    <Row justify="start" key={e._id}>
                      <Col span={12}>
                        <ExpandOutlined style={{ color: 'white' }} />
                        {` ${e.area} m²`}
                      </Col>
                      <Col span={12}>
                        <ColumnHeightOutlined style={{ color: 'white' }} />
                        {` ${e.prestation?.thickness} cm`}
                      </Col>
                    </Row>
                  ) : null
                )}

              {!showSiteMeasures && (
                <Row justify="start">
                  <Col span={12}>
                    <ExpandOutlined style={{ color: 'white' }} />
                    {` ${totalArea} m² `}
                  </Col>
                  <Col span={12}>
                    <ColumnHeightOutlined style={{ color: 'white' }} />
                    {` ${reduceThickness} cm`}
                  </Col>
                </Row>
              )}
            </div>
            <div
              style={{
                margin: '10px auto 5px',
                width: '100%',
                cursor: 'pointer'
              }}
            >
              <Row justify="start">
                <Col span={12}>
                  <TeamOutlined style={{ color: 'white' }} />

                  {` ${el.users}`}
                </Col>
                <Col span={12}>
                  <Popconfirm
                    placement="right"
                    title={
                      <TimePicker
                        defaultValue={moment(el?.date)}
                        placeholder="..."
                        format="HH:mm"
                        onChange={(time) => setDateTimeValue(time)}
                        getPopupContainer={(triggerNode) => {
                          return triggerNode.parentNode;
                        }}
                      />
                    }
                    onConfirm={() => updateDateTimeMovement(el)}
                    okButtonProps={{ disabled: !dateTimeValue }}
                    okText="Valider"
                    cancelText="Annuler"
                  >
                    <AlertOutlined style={{ color: '#dbdf7f' }} />
                    <span style={{ marginLeft: 5 }}>
                      {moment(el?.date).format('HH:mm')}
                    </span>
                  </Popconfirm>
                </Col>
              </Row>
            </div>
            <Row justify="center">
              {el.initial && (
                <div style={{ margin: 'auto 10px auto auto' }}>
                  <Popconfirm
                    title={t('movements.modal.action.delete')}
                    okText={t('movements.modal.action.ok')}
                    okButtonProps={{ type: 'danger' }}
                    cancelText={t('movements.modal.action.cancel')}
                    onConfirm={handleDeleteIntervention}
                    icon={<WarningOutlined />}
                  >
                    <DeleteOutlined
                      style={{
                        fontSize: 18,
                        marginLeft: '10px',
                        color: 'white'
                      }}
                    />
                  </Popconfirm>
                </div>
              )}
              {/* {totalArea > 100 || el.duplications.length > 0 ? ( */}
              <div
                style={{
                  margin: 'auto 10px auto auto'
                }}
              >
                <EditOutlined
                  style={{
                    fontSize: 18,
                    color: 'white'
                  }}
                  onClick={() => setShowModal(true)}
                />
              </div>
              {/* ) : null} */}
            </Row>
          </div>
        </Tooltip>
      )}
      {showModal && (
        <SplitInterventionModal
          showModal={showModal}
          setShowModal={setShowModal}
          el={el}
        />
      )}
    </>
  );
};

CardPlanning.propTypes = {
  el: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object])
    .isRequired,
  color: PropTypes.string.isRequired,
  isLoading: PropTypes.shape({}).isRequired,
  setIsLoading: PropTypes.func.isRequired
};

export default CardPlanning;
