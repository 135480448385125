import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Popconfirm, Skeleton, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  EditOutlined,
  WarningOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import useErrorMessage from '../../../utils/ErrorMessage';

const ShowArticle = () => {
  const history = useHistory();
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [product, setProduct] = useState({});

  const colomnsForEpiStock = [
    {
      title: t('articles.column.quantity'),
      key: 'stock.quantity',
      dataIndex: 'quantity',
      sorter: true
    },
    {
      title: t('articles.column.unit'),
      key: 'unit',
      dataIndex: 'unit',
      render: () => product?.unit?.label,
      sorter: true
    },
    {
      title: t('articles.column.deposit'),
      key: 'stock.deposit',
      dataIndex: 'deposit',
      render: (e) => e?.label,
      sorter: true
    },
    {
      title: t('articles.column.stock'),
      render: (data) =>
        data.quantity < product.quantity_min && (
          <p style={{ color: 'red' }}>{product.quantity_min - data.quantity}</p>
        )
    }
  ];
  const deleteArticle = async () => {
    try {
      await dispatchAPI('DELETE', { url: `/articles/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getArticle = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/articles/${id}?populate=family,supplier,stock.deposit,unit`
      });
      setProduct(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getArticle();
    })();
  }, [getArticle]);

  return (
    <>
      <PageHeaderCustom
        title={product.label || ''}
        withSubRoutes
        extra={
          <>
            <Link
              to={{
                pathname: `${routes.PRODUCTION}${subRoutes.PRODUCTION.CONSUMABLES}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteArticle}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <DescriptionList data={listContent(product)} translate />
        </Skeleton>
        <Table
          rowKey="_id"
          dataSource={product.stock}
          columns={colomnsForEpiStock}
        />
      </ContentCustom>
    </>
  );
};

export default ShowArticle;
