import React, { useEffect, useCallback, useState } from 'react';
import { Select, message, DatePicker } from 'antd';
import moment from 'moment';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import useInterventionContext from './InterventionContext';

const FiltersMoves = ({ setFilter }) => {
  const { start, getNewStartWithYear } = useInterventionContext();
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [orders, setOrders] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchOrders = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/orders`
      });
      setOrders(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
    setIsLoading(false);
  }, []);

  const fetchCustomers = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies?type=CUSTOMERS`
      });
      setCompanies(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchOrders();
      await fetchCustomers();
    })();
  }, [fetchOrders, fetchCustomers]);

  const filterOrder = (value) => {
    setFilter({ order: value });
  };
  const filterCustomer = (value) => {
    setFilter({ customer: value });
  };

  return (
    <>
      <Select
        isLoading={isLoading}
        showSearch
        allowClear
        onClear={() => setFilter({})}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={orders.map(({ number, _id }) => ({
          label: number,
          value: _id
        }))}
        style={{ width: '100%' }}
        placeholder="Commande"
        onSelect={filterOrder}
      />
      <Select
        showSearch
        allowClear
        onClear={() => setFilter({})}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        options={companies.map(({ first_name, name, _id }) => ({
          label: `${name} ${first_name === undefined ? '' : first_name}`,
          value: _id
        }))}
        style={{ width: '100%' }}
        placeholder="Client"
        onSelect={filterCustomer}
      />
      <DatePicker
        style={{ width: '100%' }}
        value={moment(start, 'YYYY')}
        picker="year"
        bordered
        onChange={(value) => getNewStartWithYear(moment(value).year())}
      />
    </>
  );
};

export default FiltersMoves;
