import React from 'react';
import { Row, Col, Descriptions, Card, Tag, Divider, Button } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import useMovementContext from '../PostIntervention/MovementsContext';
import useAuthContext from '../../../../contexts/AuthContext';

const { Item } = Descriptions;

const ShowQuotation = () => {
  const { CSTB, getFilesToDisplay } = useMovementContext();

  const { user } = useAuthContext();

  return (
    <Row justify="space-between">
      <Col span={24}>
        <Descriptions
          column={4}
          title={`Numéro de devis: ${CSTB?.quotation?.number}`}
        >
          <Item label="Chantier">{CSTB?.site?.reference}</Item>
          <Item label="Objet">{CSTB?.quotation?.subject}</Item>
          <Item label="Client">{CSTB?.customer?.name}</Item>
          <Item label="Téléphone Mobile">
            {CSTB?.customer?.phone_number_mobile}
          </Item>
          {user && user.type === 'MANAGER' && (
            <Item label="Consulter devis">
              <Button
                disbled={!CSTB.quotation.signed_quotation_file}
                type="primary"
                icon={<EyeOutlined />}
                onClick={() =>
                  getFilesToDisplay(
                    `/files/${CSTB.quotation.signed_quotation_file}`,
                    'application/pdf'
                  )
                }
              >
                {CSTB?.quotation?.number}
              </Button>
            </Item>
          )}
        </Descriptions>
      </Col>
      <Divider />
      <Col span={24}>
        <Card title="Prestations">
          {CSTB?.quotation?.prestations.map((presta, index) => (
            <Descriptions title={index + 1} key={presta?._id}>
              <Item label="Désignation">{presta?.label}</Item>
              <Item label="Surface">
                <Tag>{`${presta?.area} m²`}</Tag>
              </Item>
              <Item label="Total">{`${presta?.subtotal} €`}</Item>
              <Item label="Commentaire">{presta?.comment}</Item>
            </Descriptions>
          ))}
        </Card>
      </Col>
      <Col span={24}>
        <Card title="Récapitulatif">
          <Descriptions>
            <Item label="Surface total">{`${CSTB?.quotation?.total_area} m²`}</Item>
            <Item label="Total HT">
              <Tag>{`${CSTB?.quotation?.subTotal} €`}</Tag>
            </Item>
            <Item label="Remise">{`${CSTB?.quotation?.total_discount} €`}</Item>
            <Item label="Nombre de factures crées">
              {CSTB?.quotation?.invoices.length}
            </Item>
          </Descriptions>
        </Card>
      </Col>

      <Divider />
      <Descriptions column={2} title="Mentions">
        {CSTB?.quotation?.mentions.map((mention) => (
          <Item key={mention}>{mention}</Item>
        ))}
      </Descriptions>
    </Row>
  );
};

export default ShowQuotation;
