import React from 'react';
import template_absences from '../../assets/CSV_template/template_absences.csv';
import template_articles from '../../assets/CSV_template/template_articles.csv';
import template_contacts from '../../assets/CSV_template/template_contact.csv';
import template_deliveries from '../../assets/CSV_template/template_deliveries.csv';
import template_employee from '../../assets/CSV_template/template_employee.csv';
import template_task from '../../assets/CSV_template/template_task.csv';
import template_tickets from '../../assets/CSV_template/template_tickets.csv';
import template_orders from '../../assets/CSV_template/template_order.csv';
import template_users from '../../assets/CSV_template/template_user.csv';
import template_projects from '../../assets/CSV_template/template_project.csv';
import template_expenses from '../../assets/CSV_template/template_expenses.csv';
import { Card, Avatar } from 'antd';
import {
  CarOutlined,
  ContactsOutlined,
  EuroOutlined, FieldTimeOutlined,
  ProjectOutlined,
  StockOutlined, TagsOutlined,
  UserOutlined
} from '@ant-design/icons';
import ButtonDownload from '../../components/ButtonDownload';

const { Meta } = Card;

const getTemplateCard = (title, file, fileName, icon) => (
  <Card
    title={(
      <Meta
        avatar={<Avatar icon={icon} />}
        title={title}
      />
    )}
    extra={(<a href={file} download={fileName}><ButtonDownload /></a>)}
    bodyStyle={{ display: 'none' }}
    style={{ marginBottom: 5 }}
  />
);

const ImportTemplate = () => {

  return (
    <div>
      {getTemplateCard('Template d\'absence', template_absences, 'template_absences.csv', <FieldTimeOutlined />)}
      {getTemplateCard('Template d\'article', template_articles, 'template_articles.csv', <StockOutlined />)}
      {getTemplateCard('Template de contact', template_contacts, 'template_contacts.csv', <ContactsOutlined />)}
      {getTemplateCard('Template de livraison', template_deliveries, 'template_deliveries.csv', <CarOutlined />)}
      {getTemplateCard('Template de tâche', template_task, 'template_task.csv', <ProjectOutlined />)}
      {getTemplateCard('Template de ticket', template_tickets, 'template_tickets.csv', <TagsOutlined />)}
      {getTemplateCard('Template de commande', template_orders, 'template_orders.csv', <EuroOutlined />)}
      {getTemplateCard('Template de salarié', template_employee, 'template_employees.csv', <UserOutlined />)}
      {getTemplateCard('Template d\'utilisateur', template_users, 'template_users.csv', <UserOutlined />)}
      {getTemplateCard('Template de projet', template_projects, 'template_projects.csv', <ProjectOutlined />)}
      {getTemplateCard('Template de dépense', template_expenses, 'template_expenses.csv', <EuroOutlined />)}
    </div>
  );
};

export default ImportTemplate;
