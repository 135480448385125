import React from 'react';
import { Card, Timeline } from 'antd';
import PropTypes from 'prop-types';

const TimeLineForCheckIfDataExist = ({
  t,
  displayedResource,
  CheckIsExistAndReturnColor
}) => {
  return (
    <Card>
      <Timeline mode="right">
        <Timeline.Item
          color={CheckIsExistAndReturnColor(displayedResource.vat)}
        >
          TVA
        </Timeline.Item>
        <Timeline.Item
          color={CheckIsExistAndReturnColor(displayedResource.code_account)}
        >
          {`Compte de produit ${
            displayedResource.code_account
              ? `( ${t(`invoices.form.${displayedResource.code_account}`)} )`
              : ''
          }`}
        </Timeline.Item>
        <Timeline.Item
          color={CheckIsExistAndReturnColor(displayedResource.email_sent)}
        >
          Envoyé par email
        </Timeline.Item>
        <Timeline.Item
          color={CheckIsExistAndReturnColor(displayedResource.payment_bank)}
        >
          Banque rattachée
        </Timeline.Item>
      </Timeline>
    </Card>
  );
};

TimeLineForCheckIfDataExist.propTypes = {
  t: PropTypes.func.isRequired,
  displayedResource: PropTypes.oneOfType([PropTypes.object]),
  CheckIsExistAndReturnColor: PropTypes.func.isRequired
};
TimeLineForCheckIfDataExist.defaultProps = {
  displayedResource: null
};

export default TimeLineForCheckIfDataExist;
