import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import useAuthContext from '../../contexts/AuthContext';
import TicketsTypeTable from './ticketsTypeTable';
import { useTranslation } from 'react-i18next';

const TicketsTypeSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [ticket, setTicket] = useState([]);
  const [newTicketType] = useState({
    wording: 'name...',
    type: 'PICKUP',
    color: 'green'
  });

  const fetchTicketType = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/ticketType' });
      setTicket(data.data);
    } catch (e) {
      message.error(e);
    }
  };

  const addTicketType = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/ticketType',
        body: newTicketType
      });
    } catch (e) {
      message.error(e);
    }
    await fetchTicketType();
  };

  const deleteTicketType = async item => {
    try {
      await dispatchAPI('DELETE', {
        url: `/ticketType/${item._id}`
      });
    } catch (e) {
      message.error('Ticket exist with this type');
    }
    await fetchTicketType();
  };

  const editTicketType = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/ticketType/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e);
    }
    await fetchTicketType();
  };

  useEffect(() => {
    fetchTicketType();
  }, []);

  return (
    <>
      {ticket.length > 0 && (
        <TicketsTypeTable
          tickets={ticket}
          editTicketType={editTicketType}
          deleteTicketType={deleteTicketType}
        />
      )}
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addTicketType}
      >
        {`${t('settings.tickets.add_type')} `}
      </Button>
    </>
  );
};

export default TicketsTypeSettings;
