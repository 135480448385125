import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { LanguageContextProvider } from '../contexts/LanguageContext';
import { AuthContextProvider } from '../contexts/AuthContext';
import { ThemeContextProvider } from '../contexts/ThemeContext';
import Router from '../routes/Router';
import '../assets/styles/SCSS/index.scss';

Spin.setDefaultIndicator(<LoadingOutlined spin />);

const App = () => {
  return (
    <LanguageContextProvider>
      <AuthContextProvider>
        <ThemeContextProvider>
          <Router />
        </ThemeContextProvider>
      </AuthContextProvider>
    </LanguageContextProvider>
  );
};

export default App;
