import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  Button,
  Form,
  Select,
  InputNumber,
  Row,
  Card,
  Col,
  Divider,
  Input,
  Descriptions,
  Tag
} from 'antd';
import {
  CheckOutlined,
  ArrowRightOutlined,
  WarningOutlined,
  PlusOutlined
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import TextArea from 'antd/lib/input/TextArea';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import NewPrimaryMattersDrawer from './NewPrimaryMattersDrawer';

const { Option } = Select;

const PrimaryMatterModal = ({ articles, setArticles, id, sid, purpose }) => {
  const { dispatchAPI } = useAuthContext();
  const { message: errorMessage } = useErrorMessage();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [resources, setResources] = useState([]);
  const [secondResources, setSecondResources] = useState([]);
  const [quantityExit, setQuantityExit] = useState(0);
  const [secondQuantityExit, setSecondQuantityExit] = useState(0);
  const [tanks, setTanks] = useState([]);
  const [secondTanks, setSecondTanks] = useState([]);
  const [primaryMatters, setPrimaryMatters] = useState([]);
  const [otherBatch, setOtherBatch] = useState([]);
  const [selectedTank, setSelectedTank] = useState();
  const [secondTank, setSecondTank] = useState();
  const [PrimaryMatterSelected, setPrimaryMatterSelected] = useState();
  const [tankLabel, setTankLabel] = useState('-');
  const [secondTankLabel, setSecondTankLabel] = useState('-');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [listOfBatch, setListOfBatch] = useState([]);
  const [newBatch, setNewBatch] = useState();
  const [batchOfTank, setBatchOfTank] = useState([]);
  const [secondBatchOfTank, setSecondBatchOfTank] = useState([]);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const listPurpose = (title) => {
    switch (title) {
      case 'enter':
        return "Entrée d'une matière première";
      case 'exit':
        return "Sortie d'une matière première";
      case 'inventory-transfer':
        return "Transfert d'une matière première";
      default:
        return '';
    }
  };

  const getResources = async (idPM, setResource) => {
    try {
      const batchNumbers = [];
      const { data } = await dispatchAPI('GET', {
        url: `/primary-matters?batch.deposit=${idPM}&populate=unit,tank`
      });
      data.forEach((pm) => {
        const { batch } = pm;
        batch.forEach((element) =>
          batchNumbers.push({ ...element, type: pm?.type, label: pm?.label })
        );
      });
      setResource(batchNumbers);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  const getBatchByPrimaryMatters = async (idPM) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/primary-matters/${idPM}`
      });
      setListOfBatch(data?.batch);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };
  const getPrimaryMattersByTank = async (idPM, setTank) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/primary-matters?batch.tank=${idPM}&populate=unit&batch.archived=false`
      });
      let newData = data;
      if (data.length > 1) {
        newData = data.filter(
          ({ batch }) =>
            batch.filter(
              ({ tank, archived }) => tank === idPM && archived === false
            ).length > 0
        );
      }
      setTank(newData[0]);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getPrimaryMatters = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/primary-matters?populate=unit&sort=-created_at`
      });
      setPrimaryMatters(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  });

  const getFirstTanks = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/tanks?deposit=${id}`
      });
      const tanksDeposit = data.filter(({ deposit }) => deposit === id);
      setTanks(tanksDeposit);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  });

  const getSecondTanks = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/tanks?deposit=${sid}`
      });
      const tanksDeposit = data.filter(({ deposit }) => deposit === sid);
      setSecondTanks(tanksDeposit);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  });

  const showModal = () => {
    setIsModalVisible(true);
  };

  const onClose = () => {
    form.resetFields();
    setSelectedTank();
    setSecondTank();
    setQuantityExit(0);
    setSecondQuantityExit(0);
    setTankLabel('-');
    setSecondTankLabel('-');
    setOtherBatch();
    setIsModalVisible(false);
  };

  const onFinish = (values) => {
    if (purpose === 'enter') {
      const checkSameMatter = articles.filter(
        (matter) =>
          matter.tank === values.tank &&
          matter.primary_matter !== values.primary_matter
      );

      if (checkSameMatter.length > 0) {
        Modal.error({
          title: 'Erreur !',
          content: 'Une cuve ne peut contenir deux matières différentes !'
        });
        return false;
      }
      const newArticle = {
        ...values,
        id: values?.primary_matter || selectedTank?._id,
        tank: values?.tank,
        label: PrimaryMatterSelected.label || selectedTank?.label,
        category: 'primary_matters',
        type: 'PrimaryMatter',
        batch_numbers: [values?.batchNb],
        unit: PrimaryMatterSelected || selectedTank,
        comments: values?.comments
      };
      setArticles([...articles, newArticle]);
    }
    if (purpose === 'exit' || purpose === 'inventory-transfer') {
      const batchs = batchOfTank.map((el) => el.batch_number);
      const newArticle = {
        ...values,
        id: values?.primary_matter || selectedTank?._id,
        tank: values?.tank,
        sold_price: selectedTank.sold_price,
        label: PrimaryMatterSelected?.label || selectedTank?.label,
        unit: selectedTank?.unit,
        category: 'primary_matters',
        type: 'PrimaryMatter',
        batch_numbers: values?.batchNb ? [values?.batchNb] : batchs,
        comments: values?.comments
      };
      setArticles([...articles, newArticle]);
    }

    form.resetFields(['id', 'quantity']);
    onClose();
    return true;
  };

  const onBatchChange = () => {
    if (newBatch) {
      setListOfBatch([...listOfBatch, { batch_number: newBatch }]);
    }
  };

  useEffect(() => {
    (async () => {
      await getFirstTanks();
      if (purpose === 'inventory-transfer') {
        await getSecondTanks();
      }
      await getPrimaryMatters();
      await getResources(id, setResources);
      await getResources(sid, setSecondResources);
      form.setFieldsValue({ quantity: 0 });
    })();
  }, [id]);

  const handleValuesChange = (
    _,
    { gross_quantity, batchNb, primary_matter, tank, second_tank }
  ) => {
    if (_.primary_matter) {
      form.resetFields(['tank', 'batchNb']);
      form.setFieldsValue({ quantity: 0 });
      setTankLabel('-');
      setSecondTankLabel('-');
      setSelectedTank('-');
      setTotalQuantity(0);
      setOtherBatch();
      setBatchOfTank([]);
    }

    if (_.gross_quantity && batchNb && purpose === 'enter') {
      form.setFieldsValue({
        quantity: Math.round(
          _.gross_quantity /
            (PrimaryMatterSelected.type.includes('POL') ? 1.18 : 1.24)
        )
      });
    }
    if (primary_matter) {
      setPrimaryMatterSelected(
        primaryMatters.filter((element) => element._id === primary_matter)[0]
      );
    }

    if (tank && primary_matter && batchNb) {
      const batches = [];
      resources
        .filter(
          (el) =>
            el.tank === tank &&
            el.archived === false &&
            el.batch_number !== batchNb &&
            el.type !== PrimaryMatterSelected?.type
        )
        .forEach((el) => batches.push({ ...el, primaryMatterId: el._id }));
      setTankLabel(tanks.filter((el) => el._id === tank)[0]);
      setQuantityExit(
        resources.filter((el) => el.tank === tank && el.archived === false)
          ?.quantity || 0
      );
      setBatchOfTank(
        resources.filter((el) => el.tank === tank && el.archived === false)
      );
      if (second_tank) {
        setSecondTankLabel(
          secondTanks.filter((el) => el._id === second_tank)[0]
        );
        setSecondQuantityExit(
          secondResources.filter(
            (el) => el.tank === second_tank && el.archived === false
          )?.quantity || 0
        );
        setSecondBatchOfTank(
          secondResources.filter(
            (el) => el.tank === second_tank && el.archived === false
          )
        );
      }
      setTotalQuantity(
        resources
          .filter((el) => el.tank === tank && el.archived === false)
          .reduce((a, b) => a + b.quantity, 0) +
          // eslint-disable-next-line no-restricted-globals
          (isNaN(
            Math.round(
              gross_quantity /
                (PrimaryMatterSelected.type.includes('POL') ? 1.18 : 1.24)
            )
          )
            ? 0
            : Math.round(
                gross_quantity /
                  (PrimaryMatterSelected.type.includes('POL') ? 1.18 : 1.24)
              ))
      );
      setOtherBatch(batches);
    }
    if (_.tank) {
      getPrimaryMattersByTank(tank, setSelectedTank);
      setTankLabel(tanks.filter((element) => element._id === tank)[0]);
      setQuantityExit(
        resources
          .filter((el) => el.tank === tank)
          .map((qty) => qty.quantity)
          .reduce((curr, prev) => curr + prev, 0)
      );
      setBatchOfTank(resources.filter((el) => el.tank === tank));
    }
    if (_.second_tank && purpose === 'inventory-transfer') {
      getPrimaryMattersByTank(_.second_tank, setSecondTank);
      setSecondTankLabel(secondTanks.filter((el) => el._id === second_tank)[0]);
      setSecondQuantityExit(
        secondResources
          .filter((el) => el.tank === second_tank)
          .map((qty) => qty.quantity)
          .reduce((curr, prev) => curr + prev, 0)
      );
      setSecondBatchOfTank(
        secondResources.filter(
          (el) => el.tank === second_tank && el.archived === false
        )
      );
    }
  };
  return (
    <>
      <Card
        bordered={false}
        style={{
          width: '170px',
          backgroundColor: 'var(--primaryColor)',
          textAlign: 'center',
          color: 'white',
          borderRadius: '8px',
          cursor: 'pointer'
        }}
        onClick={showModal}
      >
        Matières premières
      </Card>
      <Modal
        title={listPurpose(purpose)}
        width={800}
        visible={isModalVisible}
        onOk={onClose}
        onCancel={onClose}
        footer={null}
      >
        {purpose === 'enter' && (
          <Row justify="space-around">
            <NewPrimaryMattersDrawer
              getResources={getPrimaryMatters}
              setForceRefresh={setForceRefresh}
              forceRefresh={forceRefresh}
            />
          </Row>
        )}

        <Form
          onValuesChange={(_, values) => handleValuesChange(_, values)}
          style={{ marginTop: 20 }}
          onFinish={onFinish}
          form={form}
        >
          <Row justify="center">
            {purpose === 'enter' && (
              <>
                <Col span={18}>
                  <Form.Item
                    name="primary_matter"
                    label="Matières premières"
                    rules={[{ required: true }]}
                  >
                    <Select
                      onChange={(value) => getBatchByPrimaryMatters(value)}
                    >
                      {primaryMatters &&
                        primaryMatters.map(({ label, _id }) => (
                          <Option key={_id} value={_id}>
                            {label}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={18}>
                  <Form.Item
                    label="Numéro de lot"
                    name="batchNb"
                    rules={[{ required: true }]}
                  >
                    <Select
                      dropdownRender={(menu) => (
                        <div>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <Row>
                            <Input
                              style={{ width: '60%', marginRight: 10 }}
                              onChange={({ target }) =>
                                setNewBatch(target.value)
                              }
                            />
                            <Button
                              type="primary"
                              icon={<PlusOutlined />}
                              onClick={onBatchChange}
                            >
                              Ajouter un lot
                            </Button>
                          </Row>
                        </div>
                      )}
                    >
                      {listOfBatch?.map((resource) => {
                        return (
                          <Option
                            key={resource.batch_number}
                            value={resource.batch_number}
                          >
                            {resource.batch_number}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}

            <Col span={18}>
              <Form.Item name="tank" label="Cuve" rules={[{ required: true }]}>
                <Select>
                  {tanks &&
                    tanks.map(({ label, _id }) => (
                      <Option key={_id} value={_id}>
                        {label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              {purpose === 'inventory-transfer' && (
                <Form.Item
                  name="second_tank"
                  label="Cuve d'entrée"
                  rules={[{ required: true }]}
                >
                  <Select>
                    {secondTanks &&
                      secondTanks.map(({ label, _id }) => (
                        <Option key={_id} value={_id}>
                          {label}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
            <Col span={18}>
              {((selectedTank &&
                secondTank &&
                selectedTank.label !== secondTank.label) ||
                (otherBatch && otherBatch[0])) && (
                <Row style={{ marginTop: 20 }} justify="center">
                  <Col style={{ textAlign: 'center' }} span={24}>
                    <WarningOutlined
                      style={{
                        color: 'var(--secondaryColor)',
                        fontSize: 30,
                        marginRight: 30,
                        marginBottom: 20
                      }}
                    />
                  </Col>
                  <Col style={{ textAlign: 'center' }} span={24}>
                    <span style={{ marginRight: 10 }}>
                      Autre lot présent dans la cuve :
                    </span>
                    <span style={{ fontWeight: 'bold' }}>
                      {purpose === 'enter'
                        ? otherBatch[0].batch_number
                        : secondTank.batch_number}
                    </span>
                  </Col>
                  <Col style={{ textAlign: 'center' }} span={24}>
                    <ArrowRightOutlined
                      style={{ marginLeft: 20, marginRight: 20 }}
                    />
                    <span style={{ marginLeft: 20, marginRight: 20 }}>
                      Quantité:
                    </span>
                    <span style={{ fontWeight: 'bold', marginLeft: 20 }}>
                      {`${
                        purpose === 'enter'
                          ? otherBatch[0]?.quantity
                          : secondTank?.quantity
                      } Litres`}
                    </span>
                  </Col>
                  <Row style={{ marginTop: 10 }} justify="center">
                    <span
                      style={{
                        fontSize: 12,
                        textAlign: 'center',
                        color: 'var(--secondaryColor)'
                      }}
                    >
                      {`IMPOSSIBLE D'ENREGISTRER UN LOT DE
                        ${
                          purpose === 'enter'
                            ? PrimaryMatterSelected.label.toUpperCase()
                            : selectedTank.label.toUpperCase()
                        } DANS CETTE
                        CUVE`}
                    </span>
                  </Row>
                  <Row justify="center">
                    <span
                      style={{
                        fontWeight: 'bold',
                        fontSize: 12,
                        textAlign: 'center',
                        color: 'var(--secondaryColor)'
                      }}
                    >
                      {`Du ${
                        purpose === 'enter'
                          ? otherBatch[0].type
                          : secondTank.type
                      } existe déjà !`}
                    </span>
                  </Row>
                </Row>
              )}
            </Col>
            <Row justify="center">
              {purpose === 'enter' ? (
                <>
                  <Col span={22}>
                    <Form.Item
                      name="gross_quantity"
                      label="Quantité entrée en kg"
                      rules={[{ required: true }]}
                    >
                      <InputNumber min={1} />
                    </Form.Item>
                  </Col>
                  <Col span={20}>
                    <Row justify="center">
                      <Form.Item
                        style={{ width: '75%' }}
                        name="quantity"
                        label="Conversion litre (valeur prise en compte)"
                      >
                        <InputNumber size="small" disabled />
                      </Form.Item>
                      <span>Litres</span>
                    </Row>
                  </Col>
                </>
              ) : (
                <Col span={24}>
                  {quantityExit <= 0 ? (
                    <span style={{ color: 'red' }}>Quantité indisponible</span>
                  ) : (
                    <Form.Item
                      name="quantity"
                      label="Quantité voulue en litres"
                      rules={[{ required: true }]}
                    >
                      <InputNumber
                        disabled={quantityExit <= 0}
                        max={quantityExit}
                        min={1}
                      />
                    </Form.Item>
                  )}
                </Col>
              )}
            </Row>
            <Divider />
            <Row justify="center">
              <Col span={18}>
                <Descriptions column={4} style={{ color: 'var(--textColor)' }}>
                  <Descriptions.Item label="Matière">
                    {selectedTank?.label || PrimaryMatterSelected?.label}
                  </Descriptions.Item>
                  <Descriptions.Item label="Cuve">
                    {tankLabel?.label}
                  </Descriptions.Item>
                  <Descriptions.Item label="Capacité">
                    {`${tankLabel?.quantity} L`}
                  </Descriptions.Item>
                  <Descriptions.Item label="Quantité">
                    {`${purpose !== 'enter' ? quantityExit : totalQuantity} L`}
                    {totalQuantity > tankLabel.quantity && (
                      <span style={{ color: 'red' }}>
                        Quantité supérieur à la capacité !
                      </span>
                    )}
                  </Descriptions.Item>
                </Descriptions>
                {batchOfTank && batchOfTank.length > 0 && (
                  <>
                    <Divider />

                    <Descriptions
                      column={3}
                      title={`Situation de la cuve ${tankLabel?.label}`}
                    >
                      {batchOfTank.map((ele) => {
                        if (ele.archived === false) {
                          return (
                            <React.Fragment key={ele?._id}>
                              <Descriptions.Item label="Matière">{`${ele?.label}`}</Descriptions.Item>
                              <Descriptions.Item label="Quantité restantes">{`${ele?.quantity} L`}</Descriptions.Item>
                              <Descriptions.Item label="Lots">
                                <Tag style={{ margin: 5 }}>
                                  {ele?.batch_number}
                                </Tag>
                              </Descriptions.Item>
                            </React.Fragment>
                          );
                        }
                      })}
                    </Descriptions>
                  </>
                )}
              </Col>
            </Row>
            <Divider />
            {purpose === 'inventory-transfer' && (
              <>
                <Row justify="center">
                  <Col span={18}>
                    <Descriptions
                      column={4}
                      style={{ color: 'var(--textColor)' }}
                    >
                      <Descriptions.Item label="Matière">
                        {secondTank?.label || PrimaryMatterSelected?.label}
                      </Descriptions.Item>
                      <Descriptions.Item label="Cuve">
                        {secondTankLabel?.label}
                      </Descriptions.Item>
                      <Descriptions.Item label="Capacité">
                        {`${secondTankLabel?.quantity} L`}
                      </Descriptions.Item>
                      <Descriptions.Item label="Quantité">
                        {`${secondQuantityExit} L`}
                        {totalQuantity > tankLabel.quantity && (
                          <span style={{ color: 'red' }}>
                            Quantité supérieur à la capacité !
                          </span>
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                    {secondBatchOfTank && secondBatchOfTank.length > 0 && (
                      <>
                        <Divider />

                        <Descriptions
                          column={3}
                          title={`Situation de la cuve ${secondTankLabel?.label}`}
                        >
                          {secondBatchOfTank.map((ele) => {
                            if (ele.archived === false) {
                              return (
                                <React.Fragment key={ele?._id}>
                                  <Descriptions.Item label="Matière">{`${ele?.label}`}</Descriptions.Item>
                                  <Descriptions.Item label="Quantité restantes">{`${ele?.quantity} L`}</Descriptions.Item>
                                  <Descriptions.Item label="Lots">
                                    <Tag style={{ margin: 5 }}>
                                      {ele?.batch_number}
                                    </Tag>
                                  </Descriptions.Item>
                                </React.Fragment>
                              );
                            }
                          })}
                        </Descriptions>
                      </>
                    )}
                  </Col>
                </Row>
                <Divider />
              </>
            )}
            <Col span={20}>
              <Form.Item name="comments" label="Commentaires">
                <TextArea />
              </Form.Item>
            </Col>
            <Divider />
            <Row justify="center">
              <Col span={12}>
                {totalQuantity > tankLabel.quantity || (
                  <Form.Item>
                    <Button
                      disabled={
                        otherBatch?.length > 0 ||
                        (selectedTank && secondTank
                          ? selectedTank.label !== secondTank.label
                          : false)
                      }
                      type="add"
                      htmlType="submit"
                    >
                      Sauvegarder
                      <CheckOutlined />
                    </Button>
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
PrimaryMatterModal.propTypes = {
  articles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setArticles: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  sid: PropTypes.string,
  purpose: PropTypes.string.isRequired
};
PrimaryMatterModal.defaultProps = {
  articles: [],
  sid: ''
};
export default PrimaryMatterModal;
