import React, { useCallback, useEffect, useState } from 'react';
import {
  Select,
  Form,
  Switch,
  Input,
  Divider,
  Button,
  InputNumber
} from 'antd';
import { useTranslation } from 'react-i18next';
import { PlusOutlined } from '@ant-design/icons';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import formList from './formList';
import NewContactDrawer from '../Contacts/NewContactDrawer';
import NewCompanyDrawer from '../Companies/NewCompanyDrawer';

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const useFields = (form, idCustomer) => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [companies, setCompanies] = useState([]);
  const [enums, setEnums] = useState([]);
  const [delayed, setDelayed] = useState(true);
  const [hasElectricity, setHasElectricity] = useState(false);
  const [hasWater, setHasWater] = useState(false);
  const [isNewBuilding, setIsNewBuilding] = useState(false);
  const [isRefurbishedBuilding, setIsRefurbishedBuilding] = useState(false);
  const [isContactDrawerVisible, setVisibilityContactDrawer] = useState(false);
  const [isCompanyDrawerVisible, setVisibilityCompanyDrawer] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [sellers, setSellers] = useState([]);
  const [speContact, setSpecificsContacts] = useState([]);
  const [defaultContact, setDefaultContact] = useState();
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const [selectedSeller, setSelectedSeller] = useState(null);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/schemas/enums`
      });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/users`
      });
      const sellersList = data.filter(
        (el) => el.type === 'SELLER' || el.type === 'DIRECTION'
      );
      setSellers(sellersList);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getContacts = async (companyId) => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/contacts?my_company=${companyId}`
      });
      setContacts(data);
      const { data: result } = await dispatchAPI('GET', {
        url: `/contacts?populate=my_company&my_company=${companyId}`
      });
      const defaultContactValue = result.find(
        (contact) =>
          contact.is_default_contact === true &&
          contact.my_company._id === companyId
      );
      setDefaultContact(defaultContactValue);
      setSpecificsContacts(data);
      if (defaultContactValue) {
        setSelectedContact(defaultContactValue);
        form.setFieldsValue({
          contact: defaultContactValue._id
        });
      }
      if (selectedCompany) {
        setSelectedContact(null);
      }
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies?status=CUSTOMERS'
      });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCustomer = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/companies/${idCustomer}`
      });
      await getContacts(idCustomer);
      setSelectedCompany(data);
      form.setFieldsValue({
        company: data._id,
        seller: data.designated_commercial
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    if (idCustomer) {
      await getCustomer();
    } else {
      form.setFieldsValue({ seller: user?._id });
    }
    await getCompanies();
    await getEnums();
    await getUsers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const filteredOptions = contacts.filter(
    (c) => !speContact?.map((u) => u._id).includes(c._id)
  );

  const config = {
    onCreateResource: {
      setBody: (data) => {
        return {
          ...data,
          localisation: [
            { latitude: data?.latitude, longitude: data?.longitude }
          ]
        };
      }
    },
    onGetResource: {
      setFields: (data) => {
        setSelectedCompany(data.company);
        setSelectedContact(data.contact);
        getContacts(data.company._id);
        return {
          ...data,
          company: data.company && data.company._id,
          contact:
            data.contact &&
            `${data.contact.first_name} ${data.contact.last_name}`,
          localisation: [{ latitude: data.latitude, longitude: data.longitude }]
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        return {
          ...data,
          company: selectedCompany._id,
          contact: selectedContact ? selectedContact._id : data.contact,
          seller: selectedSeller ? selectedSeller._id : data.seller,
          localisation: [{ latitude: data.latitude, longitude: data.longitude }]
        };
      }
    }
  };

  const basicFields = [
    {
      name: ['company'],
      rules: [{ required: true }],
      input: (
        <Input.Group style={{ display: 'flex' }}>
          <NewCompanyDrawer
            isVisible={isCompanyDrawerVisible}
            setVisibilityCompanyDrawer={setVisibilityCompanyDrawer}
            getContacts={getContacts}
            getCompanies={getCompanies}
            setSelectedCompany={setSelectedCompany}
            form={form}
            companies={companies}
          />

          <Form.Item name={['company']} style={{ width: '100%' }}>
            <Select
              showSearch
              loading={isFieldsLoading}
              optionFilterProp="children"
              searchBy="value"
              style={{ width: '100%' }}
              placeholder={t('sites.form.select_company')}
              onSelect={(values) => {
                getContacts(values);
              }}
              onChange={(companyId) => {
                const company = companies.find((c) => c._id === companyId);
                setSelectedCompany(company);
                if (company.professional !== true)
                  form.setFieldsValue({
                    reference: `${company.first_name} ${company.last_name}`
                  });
                else form.setFieldsValue({ reference: '' });
              }}
            >
              {companies.map(({ name, _id }) => (
                <Option key={_id} value={_id}>
                  {name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          {!idCustomer ? (
            <Form.Item>
              <Button
                type="add"
                style={{ marginLeft: '10px' }}
                onClick={() => {
                  setVisibilityCompanyDrawer(!isCompanyDrawerVisible);
                }}
              >
                {`${t('buttons.create')} `}
                <PlusOutlined />
              </Button>
            </Form.Item>
          ) : null}
        </Input.Group>
      ),
      help: selectedCompany && (
        <>
          <p>{selectedCompany.address_details_1}</p>
          <p>{`${selectedCompany.postal_code} ${selectedCompany.city}`}</p>
        </>
      )
    },
    {
      name: ['contact'],
      rules: [{ required: true }],
      input: (
        <Input.Group style={{ display: 'flex' }}>
          <NewContactDrawer
            isVisible={isContactDrawerVisible}
            setVisibilityContactDrawer={setVisibilityContactDrawer}
            getContact={() => getContacts(selectedCompany._id)}
            form={form}
            selectedCompany={selectedCompany}
            setSelectedContact={setSelectedContact}
            contacts={contacts}
          />
          <Form.Item name={['contact']} style={{ width: '100%' }}>
            <Select
              showSearch
              optionFilterProp="children"
              loading={isFieldsLoading}
              searchBy="value"
              style={{ width: '100%' }}
              placeholder={t('sites.form.select_contact')}
              onChange={(values) =>
                setSelectedContact(
                  contacts.find((contact) => contact._id === values)
                )
              }
              disabled={selectedCompany === null}
            >
              {defaultContact && (
                <OptGroup label="Contact par défaut">
                  <Option key={defaultContact?._id} value={defaultContact?._id}>
                    {`${defaultContact.first_name ||
                      ''} ${defaultContact.last_name || ''}`}
                  </Option>
                </OptGroup>
              )}
              {speContact.length > 0 && (
                <OptGroup label={t('sites.form.direct_contact')}>
                  {speContact.map(({ _id, last_name, first_name }) => (
                    <Option key={_id} value={_id}>
                      {`${first_name} ${last_name}`}
                    </Option>
                  ))}
                </OptGroup>
              )}
              <OptGroup label={t('sites.form.other')}>
                {filteredOptions.map(({ _id, last_name, first_name }) => (
                  <Option key={_id} value={_id}>
                    {`${first_name} ${last_name}`}
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="add"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setVisibilityContactDrawer(!isContactDrawerVisible);
              }}
              disabled={selectedCompany === null || selectedCompany.length < 0}
            >
              {`${t('buttons.create')} `}
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Input.Group>
      ),
      help: selectedContact ? (
        <>
          <p>{selectedContact.email}</p>
          <p>{selectedContact.phone_number_mobile}</p>
        </>
      ) : null
    },
    {
      name: ['seller'],
      rules: [{ required: user?.role !== 'admins:SUPER-ADMIN' }],
      input:
        user?.role !== 'admins:SUPER-ADMIN' ? (
          <Input.Group style={{ display: 'flex' }}>
            <Form.Item name={['seller']} style={{ width: '100%' }}>
              <Select
                disabled={user?.type !== 'DIRECTION'}
                loading={isFieldsLoading}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                style={{ width: '100%' }}
                placeholder="Sélectionnez un commercial"
                onChange={(key) =>
                  setSelectedSeller(sellers.find((s) => s._id === key))
                }
              >
                {sellers.map(({ first_name, _id, last_name }) => (
                  <Option key={_id} value={_id}>
                    {`${first_name} ${last_name.toUpperCase()}`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Input.Group>
        ) : (
          <Form.Item name={['seller']} style={{ width: '100%' }}>
            <Select
              loading={isFieldsLoading}
              disabled={user?.type !== 'DIRECTION'}
            >
              {sellers.map(({ first_name, _id, last_name }) => (
                <Option key={_id} value={_id}>
                  {`${first_name} ${last_name.toUpperCase()}`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        ),
      help: selectedSeller && (
        <>
          <p>{selectedSeller.email}</p>
          <p>{selectedSeller.phone_number?.number}</p>
        </>
      )
    },
    {
      name: ['reference'],
      rules: [{ required: true }],
      input: <Input disabled={selectedCompany?.professional !== true} />
    },
    {
      colon: false,
      name: ['separate'],
      input: <Divider orientation="left">{t(`sites.form.locality`)}</Divider>
    },
    {
      name: ['address_details_1'],
      rules: [{ required: true }]
    },
    {
      name: ['address_details_2']
    },
    {
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      name: ['localisation'],
      input: (
        <Input.Group>
          <Form.Item name={['latitude']}>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              placeholder="Latitude"
            />
          </Form.Item>
          <Form.Item name={['longitude']}>
            <InputNumber
              style={{ width: '100%' }}
              min={0}
              placeholder="Longitude"
            />
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['site_informations'],
      input: <TextArea style={{ height: '100px' }} />
    },
    {
      colon: false,
      name: ['separate_2'],
      input: (
        <Divider orientation="left">{t(`sites.form.intervention`)}</Divider>
      )
    },
    {
      name: ['details'],
      input: <Form.List name="details">{formList(enums, 'sites')}</Form.List>
    },
    {
      name: ['key']
    },
    {
      name: ['electricity'],
      valuePropName: 'checked',
      input: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={hasElectricity}
          onClick={() => setHasElectricity(!hasElectricity)}
        />
      )
    },
    {
      name: ['water'],
      valuePropName: 'checked',
      input: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={hasWater}
          onClick={() => setHasWater(!hasWater)}
        />
      )
    },
    {
      name: ['new_building'],
      valuePropName: 'checked',
      input: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={isNewBuilding}
          onClick={() => setIsNewBuilding(!isNewBuilding)}
        />
      )
    },
    {
      name: ['refurbished_building'],
      valuePropName: 'checked',
      input: (
        <Switch
          checkedChildren={t('sites.tags.true')}
          unCheckedChildren={t('sites.tags.false')}
          checked={isRefurbishedBuilding}
          onClick={() => setIsRefurbishedBuilding(!isRefurbishedBuilding)}
        />
      )
    },
    {
      name: ['annotations'],
      input: <TextArea style={{ height: '100px' }} />
    }
  ];
  const advancedFields = [];

  return { basicFields, advancedFields, isFieldsLoading, config };
};

export default useFields;
