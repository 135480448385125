import React, { useState } from 'react';
import { Drawer, Button, Row, Form, message } from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import useFieldsEPI from '../EPIs/fields';
import useFieldsConso from '../Articles/fields';
import useFieldsMachines from '../../Tools/Machines/fields';
import useAuthContext from '../../../contexts/AuthContext';

const NewArticleDrawer = ({ type, setForceRefresh, forceRefresh }) => {
  const { t } = useTranslation();
  const { basicFields } = useFieldsEPI();
  const { basicFieldsConso } = useFieldsConso();
  const machine = useFieldsMachines();
  const { dispatchAPI } = useAuthContext();
  const generateFields = useGenerateFormItem();
  const [visible, setVisible] = useState(false);

  const onFinish = async (values) => {
    try {
      await dispatchAPI('POST', {
        url: `${
          type === 'conso'
            ? '/articles'
            : type === 'epi'
            ? '/epis'
            : '/machines'
        }`,
        body: { ...values }
      });
      message.success(
        `${
          type === 'conso' ? 'Consommable' : type === 'epi' ? 'EPI' : 'Machine'
        } enregistré !`
      );
      setVisible(false);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      message.error('Erreur serveur');
    }
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const cancelButton = () => {
    setVisible(false);
  };

  return (
    <>
      <Row justify="start">
        <Button type="primary" onClick={showDrawer}>
          {`Créer ${
            type === 'conso'
              ? 'un nouveau consommable'
              : type === 'epi'
              ? 'un nouvel EPI'
              : 'une nouvelle machine'
          }`}
        </Button>
      </Row>
      <Drawer
        title={`Créer ${
          type === 'conso'
            ? 'un nouveau consommable'
            : type === 'epi'
            ? 'un nouvel EPI'
            : 'une nouvelle machine'
        }`}
        placement="right"
        closable={false}
        onClose={onClose}
        visible={visible}
        width={500}
      >
        <Form onFinish={onFinish}>
          {type === 'epi'
            ? basicFields.map((field) => generateFields('epi', field))
            : type === 'conso'
            ? basicFieldsConso.map((field) => generateFields('articles', field))
            : machine.basicFields.map((field) =>
                generateFields('tools', field)
              )}
          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="primary"
              htmlType="submit"
            >
              {t('buttons.save')}
              <CheckOutlined />
            </Button>
            <Button onClick={cancelButton}>
              {`${t('buttons.cancel')}`}
              <CloseOutlined />
            </Button>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

export default NewArticleDrawer;
