import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Col, Divider, Input, Popconfirm, Row, Tag } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  PlusOutlined,
  WarningOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import DataTable from '../../../../components/DataTable/Datatable';
import EditDrawer from './EditDrawer';
import useDealsContext from './DealsContext';
import { ticketsPrioritiesColor } from '../../../../utils/constants/tagColors';

const { Search } = Input;
const iconSize = 18;

const ListDeals = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const {
    delDeal,
    setCurrentDeal,
    setVisible,
    searchValue,
    searchResource,
    dataRefresh,
    kanbanCols,
    users
  } = useDealsContext();

  const columns = [
    {
      title: t('deals.form.name'),
      dataIndex: 'name',
      sorter: true
    },
    {
      title: t('deals.form.assign'),
      dataIndex: 'lead',
      render: (lead) => (lead ? `${lead.first_name} ${lead.last_name}` : 'N/A'),
      sorter: true,
      filters: users.map((e) => ({
        text: `${e.first_name} ${e.last_name}`,
        value: e._id
      }))
    },
    {
      title: t('deals.form.priority'),
      dataIndex: 'priority',
      sorter: true,
      render: (priority) =>
        priority && (
          <Tag color={ticketsPrioritiesColor[priority]}>
            {t(`deals.tags.${priority}`)}
          </Tag>
        ),
      filters: [
        { text: t('deals.tags.LOW'), value: 'LOW' },
        { text: t('deals.tags.NORMAL'), value: 'NORMAL' },
        { text: t('deals.tags.HIGH'), value: 'HIGH' },
        { text: t('deals.tags.URGENT'), value: 'URGENT' }
      ]
    },
    {
      title: t('deals.form.status'),
      dataIndex: 'status',
      render: (status) =>
        status && <Tag color={status.color}>{status.label}</Tag>,
      sorter: true,
      filters: kanbanCols.map((k) => ({ text: k.label, value: k._id }))
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      fixed: 'right',
      width: '10%',
      render: (deal) => (
        <span>
          <Link
            to={{
              pathname: `${pathname}/show/${deal._id}`
            }}
          >
            <EyeOutlined style={{ fontSize: iconSize }} />
          </Link>
          <Divider type="vertical" />
          <Button
            type="link"
            onClick={() => {
              setCurrentDeal(deal);
              setVisible(true);
            }}
            style={{ padding: '4px 0' }}
          >
            <EditOutlined style={{ fontSize: iconSize }} />
          </Button>
          <Divider type="vertical" />
          <Popconfirm
            title={t('datatable.column.action.delete.title')}
            okText={t('datatable.column.action.delete.ok')}
            cancelText={t('datatable.column.action.delete.cancel')}
            onConfirm={() => delDeal(deal._id)}
            icon={<WarningOutlined />}
          >
            <DeleteOutlined
              style={{ color: 'var(--secondaryColor)', fontSize: iconSize }}
              type="delete"
            />
          </Popconfirm>
        </span>
      )
    }
  ];

  return (
    <>
      <Row
        justify="space-between"
        gutter={[8, 16]}
        style={{ marginBottom: 16 }}
      >
        <Col>
          <Search
            allowClear
            placeholder={t('placeholder.search')}
            defaultValue={searchValue}
            onSearch={(value) => searchResource(value)}
          />
        </Col>
        <Col>
          <Button type="add" onClick={() => setVisible(true)}>
            {t('buttons.create')}
            <PlusOutlined />
          </Button>
        </Col>
      </Row>
      <DataTable
        resourceName="deals"
        columns={columns}
        searchValue={searchValue}
        forceRefresh={dataRefresh}
        populate="lead,status"
        customActionColumn
      />
      <EditDrawer />
    </>
  );
};

export default ListDeals;
