import React, { useState } from 'react';
import { Card, Col, Collapse, Row, Skeleton, Tabs } from 'antd';
import { PageHeaderCustom } from '../../../components';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import ShowTanksWrapper from './Tanks/ShowTanksWrapper';
import QuotationCardCounter from './Quotations/QuotationCardCounter';
import QuotationCardPercentage from './Quotations/QuotationCardPercentage';
import LineGraph from './Projection/LineGraph';
import ColumnGraph from './ca/ColumnGraph';

const { Panel } = Collapse;
const { TabPane } = Tabs;

/**
 *  Component for showing Complete dashboard for any user with the direction role
 *  @component
 *  @returns {any} react component
 */
function HomeDirection() {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [deposits, setDeposits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getDeposit = async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deposits`
      });
      setDeposits(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  };

  const generateTabs = () => {
    getDeposit();
  };
  return (
    <>
      <PageHeaderCustom title="Tableau de bord Direction" />
      <ContentCustom>
        <Row justify="start" gutter={[20, 20]}>
          <Col xl={10} xs={24}>
            <Card
              title="Devis et taux de transformation"
              style={{ marginBottom: 20 }}
              className="home-card"
            >
              <QuotationCardPercentage />
            </Card>
            <Card title="Nombre de devis et status" className="home-card">
              <QuotationCardCounter />
            </Card>
          </Col>
          <Col xl={14} xs={24}>
            <Card title="CA €" className="home-card">
              <ColumnGraph />
            </Card>
          </Col>
        </Row>

        <Row style={{ marginTop: 30 }} justify="space-around">
          <Collapse
            onChange={(key) => key.length && generateTabs()}
            style={{ width: '100%' }}
          >
            <Panel
              header="Les cuves en France"
              key="1"
              style={{ fontSize: '18px' }}
            >
              {isLoading ? (
                <Skeleton />
              ) : (
                <Tabs destroyInactiveTabPane type="card">
                  {deposits.map((depot) => (
                    <TabPane tab={depot.label} key={depot._id}>
                      <ShowTanksWrapper _id={depot._id} />
                    </TabPane>
                  ))}
                </Tabs>
              )}
            </Panel>
          </Collapse>
        </Row>
        <Row style={{ marginTop: 30 }} justify="space-around">
          <Col span={24}>
            <Card title=" Kilo projeté par dépôt" className="home-card">
              <LineGraph />
            </Card>
          </Col>
        </Row>
      </ContentCustom>
    </>
  );
}

HomeDirection.propTypes = {};

export default HomeDirection;
