import {
  Button,
  Divider,
  Modal,
  Select,
  Row,
  InputNumber,
  Input,
  Form
} from 'antd';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ProfileOutlined } from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import { formItemLayout } from '../../../../utils/constants/formLayout';
import useMovementContext from '../PostIntervention/MovementsContext';

const { Option } = Select;
const ShowUpperArea = ({ setShowModal, showModal, getInvoices }) => {
  const { message: errorMessage } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const { CSTB, cstblist } = useMovementContext();
  const [cstbs, setCstbs] = useState();
  const [upperArea, setUpperArea] = useState();
  const [form] = Form.useForm();
  const [upperInvoice, setUpperInvoice] = useState();
  const [disabled, setDisabled] = useState();
  const [enums, setEnums] = useState();

  const createInvoice = async () => {
    try {
      await dispatchAPI('POST', {
        url: `/invoices/`,
        body: {
          upper: {
            quotation_id: CSTB?.quotation?._id,
            cstb: CSTB?.CSTB?._id,
            type: 'PAY',
            upper_invoice: { ...upperInvoice }
          }
        }
      });
      getInvoices();
      setShowModal(false);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/vats'
      });
      setEnums(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  useEffect(() => {
    getEnums();
    if ((cstblist, CSTB)) {
      setCstbs(cstblist);
      setUpperArea(
        cstblist
          .map((e) => e?.details.map((f) => f.area).reduce((a, b) => a + b, 0))
          .reduce((a, b) => a + b, 0) - CSTB?.quotation?.total_area
      );
    }
  }, [cstblist]);

  useEffect(() => {
    (async () => {
      await getInvoices();
    })();
  }, []);

  const handleSubmit = (values) => {
    setUpperInvoice(values);
    setDisabled(true);
  };

  const onChangeValue = (_, values) => {
    const { value, tva } = values.upper_area;
    if (value && tva) {
      const vatSelected = enums.filter((vat) => vat._id === tva)[0];
      form.setFieldsValue({
        upper_area: { total: value * (vatSelected.rate / 100) + value }
      });
    }
  };

  return (
    <Modal
      title=" Facturation surplus de surface"
      visible={showModal}
      width={1000}
      onCancel={() => setShowModal(false)}
      footer={false}
    >
      <Form
        {...formItemLayout}
        form={form}
        onFinish={(values) => handleSubmit(values)}
        onValuesChange={onChangeValue}
      >
        <Form.Item label="Description" name={['upper_area', 'description']}>
          <Input disabled={disabled} />
        </Form.Item>

        <Form.Item label="Surface / Épaisseur" name={['upper_area', 'area']}>
          <InputNumber style={{ width: '100%' }} disabled={disabled} />
        </Form.Item>

        <Form.Item label="Taux de TVA" name={['upper_area', 'tva']}>
          <Select disabled={disabled}>
            {enums?.map((e) => (
              <Option value={e._id} key={e._id}>
                {`${e.rate || 0} %`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Montant HT" name={['upper_area', 'value']}>
          <InputNumber style={{ width: '100%' }} disabled={disabled} />
        </Form.Item>
        <Divider />
        <Form.Item
          style={{ marginTop: 50 }}
          label="Montant TTC"
          name={['upper_area', 'total']}
        >
          <InputNumber style={{ width: '100%' }} disabled={disabled} />
        </Form.Item>

        <Row style={{ marginTop: 50 }} justify="center">
          <Button disabled={disabled} htmlType="submit" type="primary" ghost>
            Valider une facture pour le surplus de surface
          </Button>
          {disabled ? (
            <Button
              style={{ left: 10 }}
              type="secondary"
              danger
              onClick={() => setDisabled(false)}
            >
              Modifier
            </Button>
          ) : null}
        </Row>
        <Divider />
        <Row justify="center">
          <Button
            onClick={() => createInvoice()}
            type="primary"
            icon={<ProfileOutlined />}
          >
            Facturer
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

ShowUpperArea.propTypes = {
  setShowModal: PropTypes.func,
  showModal: PropTypes.bool,
  getInvoices: PropTypes.func.isRequired
};

ShowUpperArea.defaultProps = {
  setShowModal: null,
  showModal: null
};

export default ShowUpperArea;
