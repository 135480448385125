import { useTranslation } from 'react-i18next';

const useListContent = ({
  reference,
  support,
  type,
  foam,
  thermal_resistance,
  thickness,
  label,
  isObsolete
}) => {
  const { t } = useTranslation();

  return type === 'ISOLATION'
    ? [
        {
          label: t('prestations.form.reference'),
          content: reference,
          span: 3
        },
        {
          label: t('prestations.form.type'),
          content: type,
          span: 1
        },
        {
          label: t('prestations.form.support'),
          content: support,
          span: 2
        },
        {
          label: t('prestations.form.foam'),
          content: foam,
          span: 3
        },
        {
          label: t('prestations.form.thermal_resistance'),
          content: `R=${thermal_resistance}`,
          span: 1
        },
        {
          label: t('prestations.form.thickness'),
          content: `${thickness} cm`,
          span: 2
        },
        {
          label: t('prestations.form.isObsolete'),
          content:
            isObsolete === true
              ? t('prestations.tags.true')
              : t('prestations.tags.false'),
          span: 3
        }
      ]
    : [
        {
          label: t('prestations.form.reference'),
          content: reference,
          span: 3
        },
        {
          label: t('prestations.form.type'),
          content: type,
          span: 1
        },
        {
          label: t('prestations.form.support'),
          content: support,
          span: 2
        },
        {
          label: t('prestations.form.label'),
          content: label,
          span: 3
        },
        {
          label: t('prestations.form.isObsolete'),
          content:
            isObsolete === true
              ? t('prestations.tags.true')
              : t('prestations.tags.false'),
          span: 3
        }
      ];
};

export default useListContent;
