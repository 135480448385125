import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import DepositTable from './depositTable';

const initialColumns = [
  {
    title: 'DEPOSIT',
    dataIndex: 'columns',
    key: 'columns'
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: text => <Input InitialValue={text} />
  }
];

const DepositSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [depositCols, setDepositCols] = useState(initialColumns);
  const [newDepositCol] = useState({
    label: 'Nouveau Dépôt'
  });

  const getDepositCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/deposits' });
      setDepositCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const addDepositCol = async () => {
    try {
      await dispatchAPI('POST', { url: '/deposits', body: newDepositCol });
      getDepositCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const editDepositCol = async (_id, row) => {
    const col = depositCols.find(col => col._id === _id);
    const useCol = [];
    if (useCol.length !== 0 && row.label !== col.label) {
      return message.error(
        t('configuration.error-delete-column')
      );
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/deposits/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    await getDepositCols();
  };
  useEffect(() => {
    getDepositCols();
  }, []);

  return (
    <>
      <DepositTable
        depositCols={depositCols}
        editDepositCol={editDepositCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addDepositCol}
      >
        {t('configuration.deposit.add-deposit-col')}
      </Button>
    </>
  );
};

export default DepositSettings;
