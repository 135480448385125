import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Skeleton, Tabs, Table, Tag, Badge } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import listContent from './listContent';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import useErrorMessage from '../../../utils/ErrorMessage';

const { TabPane } = Tabs;
const ShowMovementStock = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const list = ['articles', 'epis', 'primary_matters', 'machines'];

  const columns = [
    {
      title: 'Label',
      dataIndex: 'id',
      render: (text) => text?.label
    },
    {
      title: 'Quantité',
      dataIndex: 'quantity'
    },
    {
      title: 'Unité de mesure',
      dataIndex: 'id',
      render: (text) => text?.unit?.label
    },
    {
      title: 'Valorisation/unité',
      dataIndex: 'id',
      render: (text) => `${text?.buy_price} €`
    },
    {
      title: 'Valorisation total',
      dataIndex: 'valuation',
      render: (_, record) => (
        <Tag color="var(--addColor)">
          {`${record?.id?.buy_price * record?.quantity} €`}
        </Tag>
      )
    },
    {
      title: 'Numéro de lot',
      dataIndex: 'batch_numbers',
      render: (nbs) => nbs?.map((nb) => <Tag key={nb}>{nb}</Tag>)
    },
    {
      title: 'Cuve',
      dataIndex: 'tank',
      render: (text) => text?.label
    },
    {
      title: 'Commentaires',
      dataIndex: 'comments',
      render: (text) => <p style={{ whiteSpace: 'normal' }}>{text}</p>
    }
  ];

  const getMovementStock = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/stock-movements/${id}?populate=user,truck,deposit,articles.id.unit,epis.id.unit,machines.id,primary_matters.id tank.unit`
      });
      setProduct(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      await getMovementStock();
    })();
  }, [getMovementStock]);

  return (
    <>
      <PageHeaderCustom
        title={
          `Mouvement de stock pour le dépôt ${
            product?.deposit?.label
          }, le ${moment(product?.created_at).format('DD/MM/YYYY')}` || ''
        }
        withSubRoutes
      />
      <ContentCustom>
        <Skeleton loading={isLoading}>
          <DescriptionList data={listContent(product)} translate />
          <Tabs>
            {list.map((type) => (
              <TabPane
                tab={
                  <Badge
                    style={{ backgroundColor: 'var(--addColor)' }}
                    count={product[type]?.length}
                  >
                    <span style={{ marginRight: 20 }}>
                      {t(`stock-movements.${type}`)}
                    </span>
                    {}
                  </Badge>
                }
                key={type}
              >
                <Table
                  rowKey="_id"
                  columns={columns}
                  dataSource={product[type]}
                  pagination={false}
                />
              </TabPane>
            ))}
          </Tabs>
        </Skeleton>
      </ContentCustom>
    </>
  );
};

export default ShowMovementStock;
