import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, List, Collapse, Modal, Image, Descriptions, Spin } from 'antd';
import { EyeOutlined } from '@ant-design/icons';
import useSiteContext from '../SiteContext';

const { Panel } = Collapse;

const ShowDocuments = () => {
  const {
    filesSite,
    getFilesForSite,
    funcPicToDisplay,
    visiblePic,
    setVisiblePic,
    picToDisplay,
    setPicToDisplay,
    site,
    getFilesToDisplay,
    isLoading
  } = useSiteContext();
  const { id } = useParams();

  useEffect(() => {
    getFilesForSite(id);
  }, [id]);
  return (
    <Spin spinning={isLoading}>
      <Collapse defaultActiveKey={['1']}>
        <Panel header="Chantier" key="1">
          <Descriptions column={4}>
            <Descriptions.Item span={4} label="Devis">
              {site?.quotation?.map((quote) => (
                <Button
                  key={quote._id}
                  onClick={() =>
                    getFilesToDisplay(
                      quote.signed_quotation_file
                        ? `/files/${quote.signed_quotation_file}`
                        : `/generate/quotations/${quote._id}`,
                      'application/pdf',
                      quote?.number
                    )
                  }
                  style={{ marginRight: 10, marginTop: 10 }}
                  icon={<EyeOutlined />}
                >
                  {quote?.number}
                </Button>
              ))}
            </Descriptions.Item>
            <Descriptions.Item span={4} label="Autres">
              {filesSite &&
                filesSite
                  .filter(
                    (filterSiteDoc) => filterSiteDoc.metadata.type === 'other'
                  )
                  .map((ele) => (
                    <Button
                      style={{ marginRight: 10, marginTop: 10 }}
                      onClick={() => {
                        if (ele.contentType === 'application/pdf')
                          getFilesToDisplay(
                            `/files/${ele._id}`,
                            ele.contentType,
                            ele.metadata?.originalName
                          );
                        else funcPicToDisplay(ele._id, ele.contentType);
                      }}
                    >
                      {`${ele.metadata.originalName.substring(0, 10)}[...]`}
                    </Button>
                  ))}
            </Descriptions.Item>
          </Descriptions>
        </Panel>
        <Panel header="Visite technique" key="2">
          <div>
            {filesSite &&
              filesSite
                .filter((filterSiteDoc) => filterSiteDoc.metadata.type === 'TV')
                .map((ele) => (
                  <Button
                    key={ele._id}
                    onClick={() => funcPicToDisplay(ele._id, ele.contentType)}
                    style={{ marginRight: 10, marginTop: 10 }}
                  >
                    {`${ele.metadata.originalName.substring(0, 10)}[...]`}
                  </Button>
                ))}
          </div>
        </Panel>
        <Panel header="Fiches CSTB" key="3">
          <List
            itemLayout="horizontal"
            dataSource={
              filesSite &&
              filesSite
                .filter(
                  (filterSiteDoc) => filterSiteDoc.metadata.type === 'CSTB'
                )
                .sort((a, b) => a.cstb_id.number - b.cstb_id.number)
            }
            renderItem={(item) => (
              <List.Item
                key={item._id}
                extra={
                  <Button
                    onClick={() => funcPicToDisplay(item._id, item.contentType)}
                  >
                    {`${item.metadata.originalName.substring(0, 10)}[...]`}
                  </Button>
                }
              >
                <List.Item.Meta title={item.cstb_id.number} />
              </List.Item>
            )}
          />
        </Panel>
      </Collapse>
      <Modal
        destroyOnClose
        width={1000}
        visible={visiblePic}
        closable
        footer={<Button onClick={() => setVisiblePic(false)}>Ok</Button>}
        onCancel={() => {
          setVisiblePic(false);
        }}
      >
        {picToDisplay && <Image alt={picToDisplay} src={picToDisplay} />}
      </Modal>
    </Spin>
  );
};

ShowDocuments.propTypes = {};

export default ShowDocuments;
