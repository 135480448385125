import React, { useEffect, useState } from 'react';
import { Button, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import DepartmentTable from './departmentTable';

const DepartmentSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [departmentCols, setDepartmentCols] = useState();
  const [newDepartmentCol] = useState({
    label: '00 - Département'
  });

  const getDepartmentCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/departments' });
      setDepartmentCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const addDepartmentCol = async () => {
    try {
      await dispatchAPI('POST', {
        url: '/departments',
        body: newDepartmentCol
      });
      getDepartmentCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const deleteDepartmentCol = async (departmentCol) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/departments/${departmentCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    await getDepartmentCols();
  };

  const editDepartmentCol = async (_id, row) => {
    try {
      const col = departmentCols.filter((dc) => dc._id !== _id);
      const isInBDD = col.find((c) => c.label === row.label);
      if (!isInBDD) {
        await dispatchAPI('PATCH', {
          url: `/departments/${_id}`,
          body: row
        });
        message.success(t('configuration.department.message.success'));
      } else {
        message.error(t('configuration.department.message.error'));
      }
    } catch (e) {
      message.error(e.message);
    }
    await getDepartmentCols();
  };

  useEffect(() => {
    getDepartmentCols();
  }, []);

  return (
    <>
      <DepartmentTable
        departmentCols={departmentCols}
        editDepartmentCol={editDepartmentCol}
        deleteDepartmentCol={deleteDepartmentCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addDepartmentCol}
      >
        {t('configuration.department.add-department-col')}
      </Button>
    </>
  );
};

export default DepartmentSettings;
