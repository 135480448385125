import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { subRoutes } from '../../utils/constants/routes';
import MovementsRouter from './ManageMovements/MovementsRouter';
import WeekPlanning from './DailyTable/index';
import { InterventionContextProvider } from './DailyTable/InterventionContext';
import ListHours from './ManageHours/ListHours';
import DetailsMonth from './ManageHours/DetailsMonth';
import HoursRouter from './ManageHours/HoursRouter';

const InterventionsRouter = () => {
  const { path } = useRouteMatch();
  return (
    <InterventionContextProvider>
      <Switch>
        <DndProvider backend={HTML5Backend}>
            <Route
                path={`${path}${subRoutes.INTERVENTION.MANAGE_HOURS}`}
                component={HoursRouter}
            />
            <Route
            path={`${path}${subRoutes.INTERVENTION.MANAGE_INTERVENTIONS}`}
            component={MovementsRouter}
          />
          <Route path={`${path}/global_planning`} component={WeekPlanning} />
        </DndProvider>
      </Switch>
    </InterventionContextProvider>
  );
};

export default InterventionsRouter;
