import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import UnitTable from './unitTable';

const initialColumns = [
  {
    title: 'UNIT',
    dataIndex: 'columns',
    key: 'columns'
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: text => <Input InitialValue={text} />
  }
];

const UnitSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [articles, setArticles] = useState([]);
  const [unitCols, setUnitCols] = useState(initialColumns);
  const [newUnitCol] = useState({
    label: 'Nouvelle unité'
  });

  const fetchArticles = async () => {
    try {
      const data = await dispatchAPI('GET', { url: `/articles` });
      setArticles(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const getUnitCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/units' });
      setUnitCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const addUnitCol = async () => {
    try {
      await dispatchAPI('POST', { url: '/units', body: newUnitCol });
      getUnitCols();
    } catch (e) {
      message.error(e.message);
    }
  };
  const deleteUnitCol = async unitCol => {
    if (articles.filter(articles => articles.unit === unitCol.label).length > 0) {
      return message.error(
        t('configuration.error-delete-column')
      );
    }
    try {
      await dispatchAPI('DELETE', {
        url: `/units/${unitCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    await getUnitCols();
  };
  const editUnitCol = async (_id, row) => {
    const col = unitCols.find(col => col._id === _id);
    const useCol = articles.filter(article => article.status === col.label);
    if (useCol.length !== 0 && row.label !== col.label) {
      return message.error(
        t('configuration.error-delete-column')
      );
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/units/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    await getUnitCols();
  };
  useEffect(() => {
    getUnitCols();
    fetchArticles();
  }, []);

  return (
    <>
      <UnitTable
        unitCols={unitCols}
        editUnitCol={editUnitCol}
        deleteUnitCol={deleteUnitCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addUnitCol}
      >
        {t('configuration.unit.add-unit-col')}
      </Button>
    </>
  );
};

export default UnitSettings;
