import React from 'react';
import { Tag } from 'antd';
import { userRoles } from '../../utils/constants/tagColors';

const listContent = (
  { first_name, last_name, role, phone_number, email, address },
  t
) => [
  {
    label: 'profile.personalInfo.first_name',
    content: first_name,
    span: 3
  },
  {
    label: 'profile.personalInfo.last_name',
    content: last_name,
    span: 3
  },
  {
    label: 'profile.personalInfo.role',
    content: role && (
      <Tag color={userRoles[role.split(':')[1]]}>
        {t(`users.tags.${role.split(':')[1]}`)}
      </Tag>
    ),
    span: 3
  },
  {
    label: 'profile.form.phone_number',
    span: 3,
    content:
      phone_number && phone_number.number ? (
        <div>
          {`${phone_number.country_code} ${phone_number.number.slice(
            0,
            1
          )} ${phone_number.number.slice(1).replace(/(.{2})/g, '$1 ')}
                    `}
        </div>
      ) : (
        '-'
      )
  },
  {
    label: 'profile.personalInfo.email',
    content: email,
    span: 3
  },
  {
    label: 'profile.form.address',
    span: 3,
    content:
      address ? (
        <div>
          <p>
            {address.number}
            {address.street}
            <br />
            {address.additional}
            <br />
            {address.postal_code}
            {address.city}
          </p>
        </div>
      ) : (
        '-'
      )
  }
];

export default listContent;
