import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { Tabs, Table, Button, Popconfirm } from 'antd';
import { HddOutlined } from '@ant-design/icons/lib';
import PropTypes from 'prop-types';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { TabPane } = Tabs;

const DepositsTabs = ({ products, forceRefresh, product, setForceRefresh }) => {
  const { message, success } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [deposits, setDeposits] = useState([]);

  const archivePrimaryMatters = async (record) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/primary-matters/archived/${product._id}/${record._id}`,
        body: {
          ...record,
          tank: record.tank?._id,
          deposit: record?.deposit?._id,
          archived: true,
          finished_date: moment()
        }
      });
      success(`Le numéro de lot ${record?.batch_number} a été archivé !`);
      setForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getDeposits = useCallback(async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/deposits?show=true`
      });
      setDeposits(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  });
  const columns = [
    {
      title: 'Numéro de lot',
      dataIndex: 'batch_number'
    },
    {
      title: 'Dépôt',
      dataIndex: 'deposit',
      render: (text) => text?.label
    },
    {
      title: 'Cuve',
      dataIndex: 'tank',
      render: (text) => text?.label
    },
    {
      title: 'Date de remplissage',
      dataIndex: 'opened_date',
      render: (date) => moment(date).format('DD/MM/YYYY')
    },
    {
      title: 'Quantité en L',
      dataIndex: 'quantity',
      render: (text) => `${text} L`
    },
    {
      title: 'Archiver',
      dataIndex: 'archived',
      render: (_, record) =>
        record.quantity === 0 && (
          <Popconfirm
            placement="top"
            title="Voulez-vous archiver ce numéro de lot ?"
            onConfirm={() => archivePrimaryMatters(record)}
            okText="Oui"
            cancelText="Non"
          >
            <Button icon={<HddOutlined />} />
          </Popconfirm>
        )
    }
  ];

  useEffect(() => {
    (async () => {
      await getDeposits();
    })();
  }, [forceRefresh]);

  return (
    <Tabs defaultActiveKey={1}>
      <TabPane tab="Tous les dépôts" key={1}>
        <Table
          rowKey="_id"
          columns={columns}
          dataSource={
            products && products.filter(({ archived }) => archived === false)
          }
          pagination={false}
          scroll={{ x: 900 }}
        />
      </TabPane>
      {deposits &&
        deposits.map(({ label, _id }) => (
          <TabPane tab={label} key={_id}>
            <Table
              rowKey="_id"
              columns={columns}
              dataSource={
                products &&
                products.filter(
                  ({ deposit, archived }) =>
                    archived === false && deposit._id === _id
                )
              }
              pagination={false}
              scroll={{ x: 900 }}
            />
          </TabPane>
        ))}
    </Tabs>
  );
};
DepositsTabs.propTypes = {
  products: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  forceRefresh: PropTypes.bool.isRequired,
  product: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  setForceRefresh: PropTypes.func.isRequired
};
DepositsTabs.defaultProps = {
  products: null
};

export default DepositsTabs;
