import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';

const useColumnsInvoice = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('invoices.list.column.title.number'),
      dataIndex: 'number',
      key: 'number',
      render: (number, record) => `${!record.email_sent ? 'P-' : ''}${number}`,
      sorter: true
    },
    {
      title: t('invoices.list.column.title.subject'),
      dataIndex: 'subject',
      key: 'subject',
      sorter: true
    },
    {
      title: t('invoices.list.column.title.customer'),
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => customer && customer.name,
      sorter: true
    },
    {
      title: t('invoices.list.column.title.deadline_date'),
      dataIndex: 'deadline_date',
      key: 'deadline_date',
      render: (date) =>
        date ? (
          <Tag color={moment(date) < moment() ? 'red' : null}>
            {moment(date).format('DD/MM/YYYY')}
          </Tag>
        ) : (
          <Tag color="red">N/A</Tag>
        ),
      sorter: true
    },
    {
      title: t('invoices.list.column.title.total'),
      dataIndex: 'total',
      render: (total) =>
        total ? `${parseFloat(total).toFixed(2)}€` : <Tag color="red">N/A</Tag>,
      sorter: true,
      key: 'total'
    }
  ];
};

export default useColumnsInvoice;
