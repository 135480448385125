import moment from 'moment';
import { Tag } from 'antd';
import React from 'react';
import {
  invoiceTypes,
  type_invoices
} from '../../../utils/constants/tagColors';

const columns = (t, companies) => [
  {
    title: t('invoices.list.column.title.number'),
    dataIndex: 'number',
    key: 'number',
    render: (number, record) => `${!record.email_sent ? 'P-' : ''}${number}`,
    sorter: true
  },
  {
    title: t('invoices.list.column.title.type'),
    dataIndex: 'type',
    key: 'type',
    render: (type) => (
      <Tag color={invoiceTypes[type]}>{t(`invoices.tags.${type}`)}</Tag>
    ),
    filters: Object.keys(invoiceTypes).map((type) => ({
      text: t(`invoices.tags.${type}`),
      value: type
    })),
    sorter: true
  },
  {
    title: 'Catégorie',
    dataIndex: 'type_invoice',
    render: (type_invoice) =>
      type_invoices[type_invoice] ? (
        <Tag color={type_invoices[type_invoice]}>
          {t(`invoices.tags.${type_invoice}`)}
        </Tag>
      ) : (
        ''
      ),
    filters: Object.keys(type_invoices).map((type) => ({
      text: t(`invoices.tags.${type}`),
      value: type
    })),
    sorter: true
  },
  {
    title: t('invoices.list.column.title.subject'),
    dataIndex: 'subject',
    key: 'subject',
    sorter: true
  },
  {
    title: t('invoices.list.column.title.customer'),
    dataIndex: 'customer',
    key: 'customer',
    render: (customer) => customer && customer.name,
    sorter: true,
    filters:
      companies &&
      companies.map((c) => ({
        text: c.name,
        value: c._id
      }))
  },
  {
    title: t('invoices.list.column.title.emission_date'),
    dataIndex: 'emission_date',
    key: 'emission_date',
    render: (date) =>
      date ? (
        <Tag>{moment(date).format('DD/MM/YYYY')}</Tag>
      ) : (
        <Tag color="red">N/A</Tag>
      ),
    sorter: true
  },
  {
    title: t('invoices.list.column.title.payment_date'),
    dataIndex: 'payment_date',
    key: 'payment_date',
    render: (date) =>
      date ? (
        <Tag>{moment(date).format('DD/MM/YYYY')}</Tag>
      ) : (
        <Tag color="red">N/A</Tag>
      ),
    sorter: true
  },
  {
    title: "Date d'échéance",
    dataIndex: 'deadline_date',
    key: 'deadline_date',
    render: (date) =>
      date ? (
        <Tag color={moment(date) < moment() ? 'red' : null}>
          {moment(date).format('DD/MM/YYYY')}
        </Tag>
      ) : (
        <Tag color="red">N/A</Tag>
      ),
    sorter: true
  },
  {
    title: t('invoices.list.column.title.total'),
    dataIndex: 'total',
    key: 'total',
    render: (total) =>
      total ? `${parseFloat(total).toFixed(2)}€` : <Tag color="red">N/A</Tag>,
    sorter: true
  }
];

export default columns;
