import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListQuotations from './ListQuotations';
import CreateUpdateQuotation from './CreateUpdateQuotation';
import ShowQuotation from './Show/ShowQuotation';
import { AccountingContextProvider } from '../AccountingContext';
import { QuotationContextProvider } from './QuotationContext';

const QuotationRouter = () => {
  const { path } = useRouteMatch();

  return (
    <AccountingContextProvider collection="quotations">
      <QuotationContextProvider>
        <Switch>
          <Route
            path={`${path}/create/:idSite`}
            render={() => <CreateUpdateQuotation purpose="create" />}
          />
          <Route
            path={`${path}/create`}
            render={() => <CreateUpdateQuotation purpose="create" />}
          />
          <Route
            path={`${path}/edit/:id`}
            render={() => <CreateUpdateQuotation purpose="edit" />}
          />
          <Route
            path={`${path}/show/:id`}
            render={() => <ShowQuotation resource="quotations" />}
          />
          <Route path={path} component={ListQuotations} />
        </Switch>
      </QuotationContextProvider>
    </AccountingContextProvider>
  );
};

export default QuotationRouter;
