import { Tooltip, Badge, message } from 'antd';
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect } from 'react';
import useAuthContext from '../../../contexts/AuthContext';

const useColumns = () => {
  const { t } = useTranslation();
  const [unitsFilter, setUnitsFilter] = useState([]);
  const { dispatchAPI } = useAuthContext();
  const fetchUnits = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'units' });
      setUnitsFilter(
        data.map(({ label, _id }) => ({ text: label, value: _id }))
      );
    } catch (e) {
      message.error(
        `${t(`errors.message.${e.response.status}`)} : Liste des unités `
      );
    }
  };

  useEffect(() => {
    (async () => {
      await fetchUnits();
    })();
  }, []);

  return [
    {
      title: t('articles.column.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('articles.column.wording'),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('articles.column.unit'),
      key: 'unit',
      dataIndex: 'unit',
      render: (e) => e.label,
      sorter: true,
      filters: unitsFilter
    },
    {
      title: t('articles.column.buy_price'),
      key: 'buy_price',
      dataIndex: 'buy_price',
      render: (e) => `${e || 0} €`,
      sorter: true
    },
    {
      title: t('articles.column.sold_price'),
      key: 'sold_price',
      dataIndex: 'sold_price',
      render: (e) => `${e || 0} €`,
      sorter: true
    },
    {
      title: t('articles.column.mark_up'),
      key: 'mark_up',
      render: (_, record) =>
        record.buy_price &&
        record.sold_price &&
        `${Math.round((record.sold_price / record.buy_price) * 100 - 100)} %`,
      sorter: true
    },
    {
      title: t('articles.column.tva'),
      key: 'tva',
      dataIndex: 'tva',
      sorter: true
    },
    {
      title: t('articles.column.stock_global'),
      key: 'stock',
      dataIndex: 'stock',
      render: (e) => (
        <Tooltip
          style={{ left: 20 }}
          placement="top"
          title="Nombre de dépôts concernés"
        >
          <Badge
            style={{ backgroundColor: 'var(--addColor)', left: 20 }}
            size="small"
            count={e.length}
          >
            {e
              ?.map((ele) => ele.quantity)
              .reduce((curr, prev) => curr + prev, 0)}
          </Badge>
        </Tooltip>
      ),
      sorter: true
    }
  ];
};

export default useColumns;
