import { Tag } from 'antd';
import React from 'react';
import { useTranslation } from 'react-i18next';

const useListContent = ({
  code,
  label,
  projection_unit,
  abbreviation,
  coefficient,
  deposit,
  users
}) => {
  const { t } = useTranslation();

  return [
    {
      label: t('trucks.form.code'),
      content: code,
      span: 3
    },
    {
      label: t('trucks.form.label'),
      content: label,
      span: 3
    },
    {
      label: t('trucks.form.projection_unit'),
      content: projection_unit,
      span: 3
    },
    {
      label: t('trucks.form.abbreviation'),
      content: abbreviation,
      span: 3
    },
    {
      label: t('trucks.form.coefficient'),
      content: coefficient,
      span: 3
    },
    {
      label: t('trucks.form.deposit'),
      content: deposit && deposit.label ? <div>{deposit.label}</div> : '-',
      span: 3
    },
    {
      label: t('trucks.form.users'),
      content:
        users &&
        users.map(({ last_name, first_name, _id, type }) => (
          <Tag key={_id}>
            {`${first_name} ${last_name.toUpperCase()} ${
              type === 'SELLER' ? 'commercial' : ''
            }`}
          </Tag>
        )),
      span: 3
    }
  ];
};

export default useListContent;
