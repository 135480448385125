import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Col,
  Drawer,
  Form,
  message,
  Typography,
  Row,
  Switch
} from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { drawerFormLayout } from '../../../utils/constants/formLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useFields from './fields';
import useGenerateFormItem from '../../../utils/GenerateFormItem';

const { Title } = Typography;

const NewCompanyDrawer = ({
  isVisible,
  setVisibilityCompanyDrawer,
  getCompanies,
  companies
}) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isProfessional, setIsProfessional] = useState(true);
  const generateFields = useGenerateFormItem();
  const [form] = Form.useForm();
  const { fields, advancedFields } = useFields(
    isProfessional,
    'customers',
    form
  );

  // const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const cancelButton = () => {
    setVisibilityCompanyDrawer(false);
  };

  const onFinish = async (values) => {
    try {
      const isInBDD = companies.find(
        (c) =>
          c.phone_number_mobile === values.phone_number_mobile &&
          c.email === values.email
      );
      if (!isInBDD) {
        const { data } = await dispatchAPI('POST', {
          url: '/companies',
          body: { ...values, professional: isProfessional, status: 'CUSTOMERS' }
        });
        message.success(t('companies.message.success.new'));
        setVisibilityCompanyDrawer(false);
        await getCompanies();
        form.setFieldsValue({ company: data._id });
      } else {
        message.error(t('companies.message.error.already_exist'));
      }
    } catch (e) {
      message.error(t('companies.message.error.other'));
    }
  };

  const onFinishFailed = (error) => {
    // eslint-disable-next-line no-console
    console.log('Failed:', error);
    message.error(t('companies.message.error.form'));
  };

  return (
    <>
      <Drawer
        visible={isVisible}
        onClose={() => {
          setVisibilityCompanyDrawer();
        }}
        width="40%"
      >
        <Col style={{ width: '100%' }}>
          <Title level={3}>{t(`customers.form.title.create`)}</Title>
        </Col>
        <Form
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...drawerFormLayout}
          name="test"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          style={{ width: '100%' }}
          form={form}
        >
          <Switch
            checkedChildren={t(`companies.switch.professional`)}
            unCheckedChildren={t(`companies.switch.individual`)}
            checked={isProfessional}
            onClick={() => setIsProfessional(!isProfessional)}
            style={{ margin: '5% 0' }}
          />

          {fields.map((field) => generateFields('customers', field))}
          {advancedFields.map((field) => generateFields('customers', field))}

          <Row justify="end">
            <Button
              style={{ margin: '0 10px' }}
              type="primary"
              htmlType="submit"
            >
              {t('buttons.save')}
              <CheckOutlined />
            </Button>
            <Button onClick={cancelButton}>
              {`${t('buttons.cancel')}`}
              <CloseOutlined />
            </Button>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

NewCompanyDrawer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  setVisibilityCompanyDrawer: PropTypes.func.isRequired,
  getCompanies: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({}))
};

NewCompanyDrawer.defaultProps = {
  companies: []
};

export default NewCompanyDrawer;
