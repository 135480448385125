import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListPrestations from './ListPrestations';
import CreateUpdatePrestation from './CreateUpdatePrestation';
import ShowPrestation from './ShowPrestation';

const PrestationRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdatePrestation purpose="create" />}
      />
      <Route
        path={`${path}/edit/:id`}
        render={() => <CreateUpdatePrestation purpose="edit" />}
      />
      <Route path={`${path}/show/:id`} component={ShowPrestation} />
      <Route path={path} component={ListPrestations} />
    </Switch>
  );
};

export default PrestationRouter;
