import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Col } from 'antd';
import useColumns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useMovementContext from './PostIntervention/MovementsContext';
import OptionsRow from '../../Commercial/Quotations/OptionsRow';

const ListCustomers = () => {
  const columns = useColumns();
  const { range, setRange } = useMovementContext();
  const [extraQuery, setExtraQuery] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);

  useEffect(() => {
    if (range && range.length) {
      setExtraQuery(
        `date>=${moment(range[0])
          .startOf('day')
          .toISOString()}&date<=${moment(range[1])
          .endOf('day')
          .toISOString()}&sort=-date`
      );
    } else setExtraQuery('sort=-date');
    setForceRefresh(!forceRefresh);
  }, [range]);

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="movements"
      resourceModelName="Movement"
      populate="quotation,customer,truck,site"
      customActionColumn
      withCreateButton={false}
      extraQuery={extraQuery}
      withSubRoutes
    >
      <Col>
        <OptionsRow
          range={range}
          setRange={setRange}
          style={{ float: 'right' }}
        />
      </Col>
    </SimpleResourceLandingLayout>
  );
};

export default ListCustomers;
