import React from 'react';
import { InputNumber } from 'antd';

const useFields = () => {
  const basicFields = [
    {
      name: ['name'],
      rules: [{ required: true }]
    },
    {
      name: ['channel_number'],
      rules: [{ required: true }],
      input: <InputNumber style={{ width: '100%' }} />
    },
    {
      name: ['address_details'],
      rules: [{ required: true }]
    },
    {
      name: ['postal_code'],
      rules: [{ required: true }]
    },
    {
      name: ['city'],
      rules: [{ required: true }]
    },
    {
      name: ['siren_number'],
      rules: [{ required: true }],
      input: <InputNumber style={{ width: '100%' }} />
    }
  ];

  return { basicFields };
};

export default useFields;
