import { useTranslation } from 'react-i18next';

const usePwdPattern = () => {
  const { t } = useTranslation();

  return [
    {
      pattern: new RegExp(/(?=.*[0-9])/),
      message: t('login.pwdDigit')
    },
    {
      pattern: new RegExp(/(?=.*[a-z])/),
      message: t('login.pwdLowerCaseChar')
    },
    {
      pattern: new RegExp(/(?=.*[A-Z])/),
      message: t('login.pwdUpperCaseChar')
    },
    {
      pattern: new RegExp(/(?=.*[!@#$%^&*])/),
      message: t('login.pwdSpecialChar')
    },
    {
      pattern: new RegExp(/.{8,32}/),
      message: t('login.pwdLength')
    }
  ];
};

export default usePwdPattern;
