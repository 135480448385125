import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import useFields from './fields';
import CreateUpdateContainer from '../../components/CreateUpdateContainer/CreateUpdateContainer';
import useAuthContext from '../../contexts/AuthContext';

const CreateUpdateUser = ({ purpose }) => {
  const [isTech, setIsTech] = useState(false);
  const { fields, isFieldsLoading } = useFields(isTech, setIsTech);
  const { user, setUser } = useAuthContext();

  const config = {
    onCreateResource: {
      setBody: (data) => ({
        ...data
      })
    },
    onGetResource: {
      setFields: (data) => {
        if (data.type === 'TECH') {
          setIsTech(true);
        }
        return {
          ...data,
          date_of_birth: data.date_of_birth && moment(data.date_of_birth),
          contract_end_date:
            data.contract_end_date && moment(data.contract_end_date)
        };
      },
      onUpdateResource: {
        setBody: (data) => {
          if (data._id === user._id)
            setUser({ ...user, ...data, permission: user.permission });

          return data;
        }
      }
    }
  };

  return (
    <CreateUpdateContainer
      fields={fields}
      loadingFields={isFieldsLoading}
      purpose={purpose}
      baseUrl="users"
      resource="users"
      config={config}
    />
  );
};

CreateUpdateUser.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateUser;
