import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDrop } from 'react-dnd';
import CardPlanning from './cardPlanning';
import poids from '../../../assets/images/poids.png';
import metrecarre from '../../../assets/images/metrecarre.png';
import useInterventionContext from './InterventionContext';

const TableCell = ({ day, plannedMove, el, isLoading, setIsLoading }) => {
  const [start, setStart] = useState();
  const { movesPlanned } = useInterventionContext();
  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop: async (item) => {
      const result = movesPlanned.filter((ele) => ele._id === item.id)[0];
      if (result && result.date !== null && result.date) {
        day.set({
          h: moment(result.date).format('HH'),
          m: moment(result.date).format('mm')
        });
      }
      if (
        result &&
        result.status !== 'WAITING_VALIDATION' &&
        result.status !== 'VALIDATED'
      ) {
        await plannedMove(start, day, item);
      } else {
        await plannedMove(start, day, item);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  const overStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: '#4db3a221',
    justifyContent: 'start',
    flexWrap: 'wrap',
    position: 'relative',
    top: '20px'
  };

  const basicStyle = {
    width: '100%',
    height: 'auto',
    marginTop: '10px',
    minHeight: '200px',
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    marginBottom: '30px'
  };

  useEffect(() => {
    if (typeof el !== 'string') {
      setStart(el?.[0].truck);
    } else {
      setStart(el);
    }
  }, [el]);

  const totalArea =
    typeof el !== 'string'
      ? el?.reduce((total, cv) => {
          return (
            total +
            parseFloat(
              cv.area.reduce((acc, currentV) => {
                return acc + currentV;
              })
            )
          );
        }, 0)
      : 0;

  const totalWeight =
    typeof el !== 'string'
      ? el?.map(({ prestas }) =>
          prestas.map(({ area, thickness }) => area * thickness * 0.42)
        )
      : 0;

  let tot = 0;
  for (let i = 0; i < totalWeight?.length; i += 1) {
    for (let j = 0; j < totalWeight[i]?.length; j += 1) {
      tot += Number(totalWeight[i][j]);
    }
  }

  return (
    <React.Fragment key={el}>
      <div ref={drop} style={isOver ? overStyle : basicStyle}>
        {typeof el !== 'string'
          ? el?.map((it) => (
              <CardPlanning
                key={it}
                el={it}
                color={
                  it?.type === 'TECHNICIANS' ? 'var(--primaryColor)' : '#1890ff'
                }
                isLoading={isLoading}
                setIsLoading={setIsLoading}
              />
            ))
          : null}
      </div>

      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          height: '30px',
          width: '100%',
          borderTop: '1px solid var(--itemHoverBackground)',
          position: 'absolute',
          bottom: '0',
          justifyContent: 'space-around',
          marginTop: '5px'
        }}
      >
        <span style={{ color: totalArea > 100 ? 'var(--secondaryColor)' : '' }}>
          <img
            src={metrecarre}
            alt="mettrecarre"
            style={{ width: '20px', marginRight: '5px', opacity: '0.3' }}
          />
          {`${totalArea} m²`}
        </span>
        <span style={{ color: tot > 600 ? 'var(--secondaryColor)' : '' }}>
          <img
            src={poids}
            alt="poids"
            style={{ width: '20px', marginRight: '5px', opacity: '0.3' }}
          />
          {`${tot.toFixed(2)} kg`}
        </span>
      </div>
    </React.Fragment>
  );
};

TableCell.propTypes = {
  day: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  plannedMove: PropTypes.func.isRequired,
  el: PropTypes.shape({
    truck: PropTypes.string,
    map: PropTypes.func,
    reduce: PropTypes.func.isRequired
  }).isRequired
};

export default TableCell;
