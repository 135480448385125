import React, { useEffect, useState } from 'react';
import { Row, Col, Descriptions, Card, Tag, Button, Modal } from 'antd';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment';
import useMovementContext from '../PostIntervention/MovementsContext';
import ModalEditHoursControl from './ModalEditHoursControl';
import useAuthContext from '../../../../contexts/AuthContext';

const ShowHourControl = () => {
  const { CSTB, getMovement } = useMovementContext();
  const { user } = useAuthContext();
  const { t } = useTranslation();
  const { id } = useParams();
  const { Item } = Descriptions;
  const [visible, setVisible] = useState(false);
  const [dataToModal, setDataToModal] = useState();

  useEffect(() => {
    getMovement(id);
  }, [id]);

  const setModal = (value) => {
    if (
      user._id === value._id ||
      (user.managed_trucks && user.managed_trucks.includes(value.truck)) ||
      user.type === 'DIRECTION'
    ) {
      setDataToModal(value);
      setVisible(true);
    } else {
      Modal.error({
        title: 'Erreur !',
        content:
          'Vous êtes pas autorisé à faire cette action, seul le technicien concerné, son manager ou un directeur peut saisir ses heures'
      });
    }
  };

  return (
    <Row justify="space-around" gutter={[16, 16]}>
      {CSTB?.technicians?.map((tech) => (
        <Col key={tech} xxl={8} md={12} xs={24}>
          <Card
            title={
              <Row justify="space-between">
                <span>{`${tech?.first_name} ${tech?.last_name}`}</span>
                <Tag>{`${t(`users.type.${tech?.type}`)}`}</Tag>
              </Row>
            }
          >
            <Row justify="space-around">
              {CSTB?.CSTB?.hours_control.map((hourlist) =>
                tech?._id === hourlist?.technician_id ? (
                  <Descriptions
                    column={3}
                    key={hourlist?.technician_id}
                    title={`Fiche de ${tech?.first_name}`}
                  >
                    <Item label="Temps de transport">
                      {hourlist?.time_truck}
                    </Item>
                    <Item label="Temps d'intervention">
                      {hourlist?.time_intervention}
                    </Item>
                    <Item label="Temps de pause">
                      {moment(hourlist?.break).format('HH:mm')}
                    </Item>
                  </Descriptions>
                ) : null
              )}
              <Col style={{ textAlign: 'center' }} span={24}>
                <Button
                  disabled={
                    CSTB?.CSTB?.hours_control.filter(
                      (hour) => hour?.technician_id === tech?._id
                    ).length > 0 &&
                    !(user?.type === 'MANAGER' || user?.type === 'DIRECTION')
                  }
                  onClick={() => setModal(tech)}
                  type="primary"
                >
                  {`Saisir les heures de ${tech?.first_name}`}
                </Button>
              </Col>
            </Row>
          </Card>
        </Col>
      ))}
      <ModalEditHoursControl
        data={dataToModal}
        visible={visible}
        setVisible={setVisible}
      />
    </Row>
  );
};

export default ShowHourControl;
