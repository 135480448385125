import React from 'react';
import { InputNumber, Select } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import useTruckContext from './TruckContext';

const { Option } = Select;

const useFields = () => {
  const { deposits, users, isFieldsLoading } = useTruckContext();

  const basicFields = [
    {
      name: ['code'],
      rules: [{ required: true }]
    },
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['projection_unit']
    },
    {
      name: ['abbreviation']
    },
    {
      name: ['coefficient'],
      rules: [{ required: true }],
      input: <InputNumber />
    },
    {
      name: ['deposit'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {deposits.map(({ label, _id }) => (
            <Option key={label} value={_id}>
              {label}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['users'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading} mode="tags">
          {users.map(({ first_name, last_name, _id, type, truck }) => (
            <Option key={_id} value={_id}>
              {`${first_name} ${last_name} ${
                type === 'SELLER' ? '(Commercial)' : ''
              } `}
              {truck && <WarningOutlined style={{ color: 'orange' }} />}
            </Option>
          ))}
        </Select>
      )
    }
  ];

  return { basicFields };
};

export default useFields;
