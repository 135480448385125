import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Row, Spin } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import useFields from './fields';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import { ContentCustom, PageHeaderCustom } from '../../../components';
import useGenerateFormItem from '../../../utils/GenerateFormItem';
import {
  formItemLayout,
  tailFormItemLayout
} from '../../../utils/constants/formLayout';
import { routes, subRoutes } from '../../../utils/constants/routes';

const CreateUpdateSite = ({ purpose }) => {
  const history = useHistory();
  const { id, idCustomer } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const generateFields = useGenerateFormItem(purpose);
  const [form] = Form.useForm();
  const { basicFields, isFieldsLoading, config } = useFields(form, idCustomer);
  const { onGetResource, onCreateResource, onUpdateResource } = config;

  const updateResource = async (body) => {
    onUpdateResource.setBody(body);
    try {
      await dispatchAPI('PATCH', {
        url: `/sites/${id}`,
        body:
          onUpdateResource && onUpdateResource.setBody
            ? onUpdateResource.setBody(body)
            : body
      });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createResource = async (body) => {
    let createdSite;
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/sites`,
        body:
          onCreateResource && onCreateResource.setBody
            ? onCreateResource.setBody(body)
            : body
      });
      createdSite = data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    if (createdSite) {
      const isUSerAskingForQuotationCreation = window.confirm(
        'Souhaitez-vous créer un devis lié à a ce chantier ?'
      );
      if (isUSerAskingForQuotationCreation) {
        return history.push({
          pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.QUOTATIONS}/create/${createdSite?._id}`,
          state: {
            newlyCreated: createdSite
          }
        });
      }
      return history.push(
        `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.SITES}/show/${createdSite?._id}`
      );
    }
    return history.goBack();
  };
  const getResource = useCallback(async () => {
    if (id) {
      setIsLoading(true);
      try {
        const { data } = await dispatchAPI('GET', {
          url: `sites/${id}?populate=contact,company`
        });
        form.setFieldsValue(
          onGetResource && onGetResource.setFields
            ? onGetResource.setFields(data)
            : data
        );
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        if (e.response) message(e.response.status);
      }
    }
  }, [purpose, id, isFieldsLoading]);

  useEffect(() => {
    if (purpose === 'edit' && id) {
      setIsLoading(true);
      if (!isFieldsLoading)
        (async () => {
          await getResource();
        })();
    }
  }, [getResource]);

  const handleChange = (changedValues) => {
    if (changedValues.company) {
      form.resetFields(['contact']);
    }
  };

  const handleSubmit = async (values) => {
    if (purpose === 'edit') await updateResource(values);
    if (purpose === 'create') await createResource(values);
    if (purpose === 'createByCustomer') await createResource(values);
  };

  return (
    <>
      <PageHeaderCustom
        title={t(`sites.form.title.${purpose}`)}
        withSubRoutes
      />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <Form
            /* eslint-disable-next-line react/jsx-props-no-spreading */
            {...formItemLayout}
            onFinish={handleSubmit}
            form={form}
            onValuesChange={handleChange}
          >
            {basicFields?.map((field) => generateFields('sites', field))}
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <Form.Item {...tailFormItemLayout}>
              <Row justify="end">
                <Button
                  style={{ margin: '0 10px' }}
                  type="link"
                  danger
                  onClick={() => history.goBack()}
                >
                  {`${t('buttons.cancel')} `}
                  <CloseOutlined />
                </Button>
                <Button type="add" htmlType="submit">
                  {`${t('buttons.save')} `}
                  <CheckOutlined />
                </Button>
              </Row>
            </Form.Item>
          </Form>
        </Spin>
      </ContentCustom>
    </>
  );
};

CreateUpdateSite.propTypes = {
  purpose: PropTypes.string.isRequired,
  isFieldsLoading: PropTypes.bool,
  config: PropTypes.shape({
    onGetResource: PropTypes.shape({
      setFields: PropTypes.func
    }),
    onCreateResource: PropTypes.shape({
      setBody: PropTypes.func
    }),
    onUpdateResource: PropTypes.shape({
      setBody: PropTypes.func
    })
  }),
  withSubRoutes: PropTypes.bool,
  submitLabel: PropTypes.string,
  customSubmit: PropTypes.func,
  isParentLoading: PropTypes.bool
};

CreateUpdateSite.defaultProps = {
  config: {},
  isFieldsLoading: false,
  withSubRoutes: false,
  submitLabel: null,
  customSubmit: null,
  isParentLoading: false
};

export default CreateUpdateSite;
