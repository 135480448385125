import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import Profile from './Profile';
import UpdateProfile from './UpdateProfile';
import UpdatePwd from './UpdatePwd';

const ProfileRouter = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.path}/edit/:id`} component={UpdateProfile} />
      <Route path={`${match.path}/change-pwd`} component={UpdatePwd} />
      <Route path={match.path} component={Profile} />
    </Switch>
  );
};

export default ProfileRouter;
