import moment from 'moment';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('quotations.list.column.title.number'),
      dataIndex: 'number',
      key: 'number',
      sorter: true
    },
    {
      title: t('quotations.list.column.title.subject'),
      dataIndex: 'subject',
      key: 'subject',
      sorter: true
    },
    {
      title: t('quotations.list.column.title.reference'),
      dataIndex: 'site',
      key: 'site',
      render: (site) => site.reference,
      sorter: true
    },
    {
      title: t('quotations.list.column.title.customer'),
      dataIndex: 'customer',
      key: 'customer',
      render: (customer) => customer && customer.name,
      sorter: true
    },
    {
      title: t('quotations.list.column.title.status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) =>
        t(`quotations.list.column.quotation_status.${status}`),
      sorter: true
    },
    {
      title: t('quotations.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      key: 'emission_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: t('quotations.list.column.title.total'),
      dataIndex: 'total',
      render: (total) => `${parseFloat(total).toFixed(2)}€`,
      sorter: true,
      key: 'total'
    }
  ];
};

export default useColumns;
