import React from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';

const setColumn = (t, type, status, setInvalidValue) => {
  if (type === 'articles' || type === 'epis') {
    return [
      {
        title: t('inventories.column.code'),
        dataIndex: 'label',
        key: 'code',
        render: (label) => label?.code
      },
      {
        title: t('inventories.column.label'),
        dataIndex: 'label',
        key: 'label',
        render: (label) => label?.label
      },
      {
        title: t('inventories.column.quantity_updated'),
        dataIndex: 'quantity',
        key: 'quantity',
        render: (quantity) => quantity || 0
      },
      {
        title: t('inventories.column.unit'),
        dataIndex: 'unit',
        key: 'unit',
        render: (unit) => unit?.label
      },
      {
        title: t('inventories.column.real_quantity'),
        dataIndex: 'real_quantity',
        key: 'real_quantity',
        render: (text) => {
          if (text || text === 0) {
            return text;
          }
          return <ExclamationCircleOutlined style={{ color: 'red' }} />;
        },

        editable: !!status
      },
      {
        title: t('inventories.column.difference'),
        dataIndex: 'difference',
        key: 'difference',
        render: (_, { real_quantity, quantity }) => {
          if ((real_quantity && quantity) || real_quantity === 0) {
            const result = real_quantity - quantity;
            return (
              <span
                style={{
                  color: result < 0 ? 'red' : 'var(--addColor)'
                }}
              >
                {result}
              </span>
            );
          }
          if ((real_quantity && quantity) || quantity === 0) {
            return (
              <span
                style={{
                  color: 'var(--addColor)'
                }}
              >
                {real_quantity}
              </span>
            );
          }
          return 0;
        }
      },
      {
        title: t('inventories.column.no_difference'),
        dataIndex: 'setup',
        key: 'setup',
        editable: !!status
      }
    ];
  }

  if (type === 'primary_matters') {
    return [
      {
        title: t('inventories.column.tank'),
        dataIndex: 'label',
        key: 'code',
        render: (tank) => tank && tank.label
      },
      {
        title: t('inventories.column.batch_number'),
        dataIndex: 'batch_number',
        key: 'batch_number'
      },
      {
        title: t('inventories.column.quantity_updated'),
        dataIndex: 'quantity',
        key: 'quantity',
        render: (quantity) => quantity || 0
      },
      {
        title: t('inventories.column.unit'),
        dataIndex: 'unit',
        key: 'unit',
        render: (unit) => unit?.label
      },
      {
        title: t('inventories.column.capacity'),
        key: 'capacity',
        render: (record) => `${record.label.quantity} L`
      },
      {
        title: t('inventories.column.real_quantity'),
        dataIndex: 'real_quantity',
        key: 'real_quantity',
        render: (text) => {
          if (text || text === 0) {
            return text;
          }
          return <ExclamationCircleOutlined style={{ color: 'red' }} />;
        },
        editable: !!status
      },
      {
        title: t('inventories.column.difference'),
        dataIndex: 'difference',
        key: 'difference',
        render: (_, { real_quantity, quantity, label }) => {
          if ((real_quantity && quantity) || real_quantity === 0) {
            const result = real_quantity - quantity;
            if (real_quantity > label.quantity) {
              setInvalidValue(true);
              return (
                <span
                  style={{
                    color: 'red'
                  }}
                >
                  Hors capacité
                </span>
              );
            }
            setInvalidValue(false);
            return (
              <span
                style={{
                  color: result < 0 ? 'red' : 'var(--addColor)'
                }}
              >
                {result}
              </span>
            );
          }
          if ((real_quantity && quantity) || quantity === 0) {
            return (
              <span
                style={{
                  color: 'var(--addColor)'
                }}
              >
                {real_quantity}
              </span>
            );
          }

          return 0;
        }
      },
      {
        title: t('inventories.column.no_difference'),
        dataIndex: 'setup',
        key: 'setup',
        editable: !!status
      }
    ];
  }
  return [];
};

export default setColumn;
