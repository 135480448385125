import {
  Button,
  Typography,
  Row,
  Col,
  Tag,
  Upload,
  Form,
  Card,
  Select,
  InputNumber,
  Popconfirm,
  Image,
  Modal,
  Spin,
  Divider
} from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import PowerStop from '../../../../assets/images/powerStop.png';
import PowerGo from '../../../../assets/images/powerGo.png';
import useGenerateFormItem from '../../../../utils/GenerateFormItem';
import useFields from './Fields';
import useMovementContext from './MovementsContext';
import useAuthContext from '../../../../contexts/AuthContext';

const { Title } = Typography;
const { Option } = Select;

const useListContentStep = (enums, handleSubmit) => {
  const [start, setStart] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const generateFields = useGenerateFormItem();
  const {
    CSTB,
    deleteFiles,
    pictures,
    coringHeavy,
    setBatch,
    batch,
    getFilesCstb,
    picToDisplay,
    funcPicToDisplay,
    visiblePic,
    setVisiblePic
  } = useMovementContext();
  const {
    fieldsPreIntervention,
    fieldsPreMesurement,
    fieldsProjectionPol,
    fieldsProjectionIso,
    fieldsProjectionSheath,
    fieldsCoring,
    fieldsPostMesurement,
    fieldsControl,
    fieldsCoringMeasure
  } = useFields(enums, CSTB || null);
  const { token } = useAuthContext();
  useEffect(() => {
    if (CSTB) {
      getFilesCstb(`/files?cstb_id=${CSTB?.CSTB?._id}`);
      setBatch(CSTB?.truck?.stock);
    }
  }, [CSTB]);

  const listHeavy = [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22, 24];

  const onChangeImg = async () => {
    setIsDownloading(true);
    await getFilesCstb(`/files?cstb_id=${CSTB?.CSTB?._id}`);
    setIsDownloading(false);
  };

  const steps = [
    {
      title: 'Arrivée',
      content: (
        <>
          <Row>
            <Col style={{ textAlign: 'center' }} span={24}>
              {fieldsPreIntervention.map((field) =>
                generateFields('cstb', field)
              )}
            </Col>
          </Row>
        </>
      )
    },
    {
      title: 'Mise en route',
      content: (
        <div>
          <Button
            type="button"
            style={{
              background: 'none',
              color: 'inherit',
              border: 'none',
              padding: '0',
              font: 'inherit',
              cursor: 'pointer',
              outline: 'inherit',
              height: '100%',
              boxShadow: 'none'
            }}
            onClick={() => setStart(!start)}
          >
            {!start && <img src={PowerStop} alt="powerStop" />}
            {start && <img src={PowerGo} alt="powerGo" />}
          </Button>

          {start === false ? (
            <Title level={2} style={{ marginTop: '50px' }}>
              {`Machines à l'arrêt`}
            </Title>
          ) : (
            <Title level={2} style={{ marginTop: '50px' }}>
              Mise en route des machines et cachage
            </Title>
          )}
        </div>
      )
    },
    {
      title: 'Mesures avant',
      content: (
        <>
          <Row>
            <Col span={24}>
              {fieldsPreMesurement.map((field) =>
                generateFields('cstb', field)
              )}
            </Col>
          </Row>
        </>
      )
    },
    {
      title: 'Projection',
      content: (
        <>
          <Row style={{ marginBottom: 50 }} gutter={[0, 20]}>
            <Col style={{ textAlign: 'center' }} xs={24} xl={12}>
              <Title style={{ marginBottom: 50 }} level={4}>
                <span>N° de lot Polyol :</span>
                {batch?.polyol.map((e) => (
                  <Tag style={{ marginLeft: 10 }} key={e} color="red">
                    {e}
                  </Tag>
                ))}
              </Title>
              {fieldsProjectionPol.map((field) =>
                generateFields('cstb', field)
              )}
            </Col>
            <Col style={{ textAlign: 'center' }} xs={24} xl={12}>
              <Title style={{ marginBottom: 50 }} level={4}>
                <span>N° de lot Isocyanate :</span>
                {batch?.isocyanate.map((e) => (
                  <Tag style={{ marginLeft: 10 }} key={e} color="red">
                    {e}
                  </Tag>
                ))}
              </Title>
              {fieldsProjectionIso.map((field) =>
                generateFields('cstb', field)
              )}
            </Col>
          </Row>
          <Row style={{ width: '100%' }}>
            <Col span={24}>
              {fieldsProjectionSheath.map((field) =>
                generateFields('cstb', field)
              )}
            </Col>
          </Row>
        </>
      )
    },
    {
      title: 'Carottage',
      content: (
        <>
          <Row justify="space-around">
            <Col style={{ textAlign: 'center' }} xs={24} xl={24}>
              <Card title="Qualité d'application">
                <Row justify="space-around">
                  {fieldsCoring.map((field) => (
                    <Col style={{ justifyContent: 'center' }} span={6}>
                      {generateFields('cstb', field)}
                    </Col>
                  ))}
                </Row>
              </Card>
            </Col>
            <Divider />
            <Col style={{ textAlign: 'center' }} xs={24} xl={24}>
              <Card title="Masse volumique durant le chantier">
                <Row style={{ width: '100%' }} justify="center">
                  <Col
                    style={{ justifyContent: 'space-between' }}
                    xs={18}
                    xxl={14}
                  >
                    {fieldsCoringMeasure.map((field) => (
                      <Row
                        key={field.name[1]}
                        justify="space-between"
                        style={{ width: '100%' }}
                      >
                        <Col xxl={8} xs={16}>
                          <div style={{ display: 'flex', textAlign: 'start' }}>
                            {generateFields('cstb', field)}
                            <span style={{ marginLeft: -20 }}> kg/m³</span>
                          </div>
                        </Col>
                        <Col span={6}>
                          <Form.Item
                            name={['coring', `${field.name[1]}_indic`]}
                          >
                            <Select style={{ width: 65 }}>
                              {listHeavy.map((e, index) => (
                                <Option key={e} value={e}>
                                  {`${index + 1} mm`}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={6}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-around'
                            }}
                          >
                            <Form.Item
                              name={['coring', `${field.name[1]}_heavy`]}
                            >
                              <InputNumber />
                            </Form.Item>
                            <span>kg/m³</span>
                          </div>
                        </Col>
                      </Row>
                    ))}
                  </Col>
                  <Col span={6}>
                    <span style={{ fontWeight: 'bold' }}>Masse volumique</span>
                    <Card>
                      <Title level={3}>
                        {coringHeavy !== null
                          ? `${coringHeavy} kg/m³`
                          : `${0} kg/m³`}
                      </Title>
                      {(coringHeavy < 34 && coringHeavy !== null) ||
                      (coringHeavy > 50 && coringHeavy !== null) ? (
                        <div style={{ color: 'var(--secondaryColor)' }}>
                          {
                            "La valeur n'est pas comprise entre 32 et 50. Veuillez prévenir votre responsable."
                          }
                        </div>
                      ) : null}
                    </Card>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </>
      )
    },
    {
      title: 'Mesures après',
      content: (
        <>
          <Row>
            <Col style={{ textAlign: 'start' }} span={24}>
              <Form.Item
                name={['cycle']}
                rules={[
                  {
                    required: true,
                    message: 'Veuillez saisir le nombre de cycles'
                  }
                ]}
                label="Contrôles cycles"
              >
                <InputNumber min={0} />
              </Form.Item>
            </Col>
            <Col span={24}>
              {fieldsPostMesurement.map((field) =>
                generateFields('cstb', field)
              )}
            </Col>
          </Row>
        </>
      )
    },
    {
      title: 'Conformité',
      content: (
        <>
          <Row justify="space-around" style={{ margin: 50 }} gutter={[0, 16]}>
            <Col xs={24} xl={8}>
              <Card
                style={{ textAlign: 'center' }}
                title="Qualité de l'intervention"
              >
                <Row justify="center">
                  {fieldsControl.map((field) => generateFields('cstb', field))}
                </Row>
              </Card>
            </Col>
            <Col xs={24} xl={14}>
              <Card
                title="Importer des photos"
                extra={
                  <Form.Item noStyle name="fileList">
                    <Upload
                      multiple
                      action={`${process.env.REACT_APP_API_URL}/files/cstb/${CSTB?.CSTB?._id}/${CSTB?.site?._id}`}
                      data="picture"
                      headers={{ Authorization: `Bearer ${token}` }}
                      onChange={() => onChangeImg()}
                      showUploadList={false}
                      onRemove={(file) => {
                        deleteFiles(file.response, `files/${file.response}`);
                        getFilesCstb(`/files?cstb_id=${CSTB?.CSTB?._id}`);
                      }}
                    >
                      <Button icon={<UploadOutlined />}>Importer</Button>
                    </Upload>
                  </Form.Item>
                }
              >
                {' '}
                <Spin spinning={isDownloading}>
                  <Row gutter={[16, 16]}>
                    {pictures.length > 0
                      ? pictures
                          .filter(
                            (filter) => filter.cstb_id === CSTB?.CSTB?._id
                          )
                          .map((el) => (
                            <div key={el._id}>
                              <Col span={24}>
                                <Popconfirm
                                  placement="top"
                                  title="Choisir une action"
                                  onConfirm={() =>
                                    funcPicToDisplay(el._id, el.contentType)
                                  }
                                  onCancel={async () => {
                                    await deleteFiles(
                                      el._id,
                                      `files/${el._id}`
                                    );
                                    await getFilesCstb(
                                      `/files?cstb_id=${CSTB?.CSTB?._id}`
                                    );
                                  }}
                                  okText="Voir"
                                  cancelText="Supprimer"
                                >
                                  <Button>
                                    {`${el.metadata.originalName.substring(
                                      0,
                                      10
                                    )}...`}
                                  </Button>
                                </Popconfirm>
                              </Col>
                            </div>
                          ))
                      : null}
                  </Row>
                </Spin>
              </Card>
              <Modal
                width={1000}
                visible={visiblePic}
                closable
                footer={
                  <Button onClick={() => setVisiblePic(false)}>Ok</Button>
                }
                onCancel={() => setVisiblePic(false)}
              >
                {picToDisplay && (
                  <Image alt={picToDisplay} src={picToDisplay} />
                )}
              </Modal>
            </Col>
          </Row>
        </>
      )
    },
    {
      title: 'Confirmation',
      content: (
        <>
          <Row justify="center">
            <Col span={24}>
              <Title level={2}>La fiche CSTB est saisie.</Title>
              <Title level={3}>
                Une demande de validation sera envoyée au responsable.
              </Title>
              <Popconfirm
                title="Voulez-vous clôturer la saisie ？"
                okText="Oui"
                cancelText="Non"
                onConfirm={() => handleSubmit()}
              >
                <Button htmlType="submit" type="primary">
                  Confirmer
                </Button>
              </Popconfirm>
            </Col>
          </Row>
        </>
      )
    }
  ];

  return { steps };
};

export default useListContentStep;
