import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
import {
  CloseOutlined,
  CheckOutlined,
  DeleteOutlined,
  WarningOutlined
} from '@ant-design/icons';
import {
  Row,
  Button,
  Col,
  Table,
  Tag,
  Popconfirm,
  Divider,
  Card,
  Tabs,
  Badge,
  Select,
  Input,
  Spin
} from 'antd';
import { useTranslation } from 'react-i18next';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import PrimaryMatterModal from './PrimaryMatterModal';
import EPIModal from './EPIModal';
import ConsumablesModal from './ConsumablesModal';
import MachinesModal from './MachinesModal';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import TotalContent from './InventoryTransfer/TotalContent';
import MovementStockContext from './MovementStockContext';

const iconSize = 18;
const { TabPane } = Tabs;

const CreateUpdateMovementStock = ({ purpose }) => {
  const { dispatchAPI, user } = useAuthContext();
  const { id, sid, label, inventory, comments } = useParams();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const history = useHistory();
  const [articles, setArticles] = useState([]);
  const [epis, setEpis] = useState([]);
  const [primary_matters, setPrimaryMatters] = useState([]);
  const [machines, setMachines] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [listOfTanks, setListOfTanks] = useState([]);
  const { getVats, vats } = MovementStockContext();
  const [vatForTransfer, setVatForTransfer] = useState();
  const [objectToTransfer, setObjectToTransfer] = useState(null);
  const [referenceToTransfer, setReferenceToTransfer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const deleteResource = (index, item) => {
    const arr = dataSource[item];
    arr.splice(index, 1);
    switch (item) {
      case 'primary_matters':
        setPrimaryMatters([...arr]);
        break;
      case 'articles':
        setArticles([...arr]);
        break;
      case 'epis':
        setEpis([...arr]);
        break;
      default:
        break;
    }
  };
  const list = ['primary_matters', 'articles', 'epis', 'machines'];

  const getTanks = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/tanks?deposit=${id}`
      });
      setListOfTanks(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  useEffect(() => {
    getTanks();
    getVats();
  }, [id]);

  const finishMovement = async () => {
    setIsLoading(true);
    const body = {
      user: user._id,
      truck: user.truck,
      deposit: id,
      second_deposit: purpose === 'inventory-transfer' ? sid : null,
      articles,
      epis,
      primary_matters,
      machines,
      inventory,
      comments: comments === 'null' ? null : comments,
      category: purpose,
      vat: purpose === 'inventory-transfer' ? vatForTransfer.value : null,
      object: objectToTransfer,
      reference: referenceToTransfer
    };
    try {
      const result = await dispatchAPI('POST', {
        url: `/stock-movements/${purpose}`,
        ...(purpose === 'inventory-transfer' && {
          responseType: 'arraybuffer'
        }),
        body
      });
      if (purpose === 'inventory-transfer') {
        const bfArray = new Uint8Array(result.data.data);
        const blob = new Blob([bfArray], {
          type: 'application/pdf'
        });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${objectToTransfer}.pdf`;
        a.click();
      }
      setIsLoading(false);
      history.push('/production/stock-movements');
    } catch (e) {
      setIsLoading(false);
      if (e.response) message(e.response.status);
    }
  };

  const columns = [
    {
      title: 'Label',
      dataIndex: 'label'
    },
    {
      title: 'Quantité',
      dataIndex: 'quantity',
      render: (text, record) => `${text} ${record?.unit?.label || 'Litres'}`
    },
    {
      title: 'Numéro de lot',
      dataIndex: 'batch_numbers',
      render: (nbs) => nbs?.map((nb) => <Tag key={nb}>{nb}</Tag>)
    },
    {
      title: 'Cuve',
      dataIndex: 'tank',
      render: (text) =>
        listOfTanks?.filter((tank) => tank?._id === text)[0]?.label
    },
    {
      title: 'Commentaires',
      dataIndex: 'comments'
    },
    {
      key: 'action',
      // eslint-disable-next-line react/prop-types
      render: ({ key, category }) => (
        <Popconfirm
          title={t('datatable.column.action.delete.title')}
          okText={t('datatable.column.action.delete.ok')}
          okButtonProps={{ type: 'danger' }}
          cancelText={t('datatable.column.action.delete.cancel')}
          onConfirm={() => deleteResource(key, category)}
          icon={<WarningOutlined />}
        >
          <DeleteOutlined
            style={{ color: 'var(--secondaryColor)', fontSize: iconSize }}
            type="delete"
          />
        </Popconfirm>
      )
    }
  ];

  useEffect(() => {
    setDataSource({
      articles: articles.map((article, index) => {
        return {
          ...article,
          batch_numbers: article.batch_number || null,
          tank: article.tankLabel || null,
          key: index
        };
      }),
      epis: epis.map((epi, index) => {
        return {
          ...epi,
          batch_numbers: epi.batch_number || null,
          tank: epi.tankLabel || null,
          key: index
        };
      }),
      primary_matters: primary_matters.map((primary, index) => {
        return {
          ...primary,
          batch_numbers: primary.batch_numbers || null,
          tank: primary.tank || null,

          key: index
        };
      }),
      machines: machines.map((machine, index) => {
        return {
          ...machine,
          key: index
        };
      })
    });
  }, [articles, epis, primary_matters, machines]);

  return (
    <Spin spinning={isLoading}>
      <PageHeaderCustom
        title={`${t(
          `movementsStock.form.title.${purpose}`
        )} pour le dépôt ${label}`}
      />
      <ContentCustom>
        <Row gutter={[16, 16]} justify="space-around">
          <Col>
            <PrimaryMatterModal
              articles={primary_matters}
              setArticles={setPrimaryMatters}
              inventory={JSON.parse(inventory)}
              purpose={purpose}
              id={id}
              sid={sid}
            />
          </Col>
          <Col>
            <ConsumablesModal
              articles={articles}
              setArticles={setArticles}
              purpose={purpose}
              inventory={JSON.parse(inventory)}
              id={id}
            />
          </Col>
          <Col>
            <EPIModal
              articles={epis}
              setArticles={setEpis}
              purpose={purpose}
              inventory={JSON.parse(inventory)}
              id={id}
            />
          </Col>
          <Col>
            <MachinesModal
              articles={machines}
              setArticles={setMachines}
              purpose={purpose}
              inventory={JSON.parse(inventory)}
              id={id}
            />
          </Col>
        </Row>
        <Divider />
        <Row justify="center">
          <Card style={{ width: '80%' }}>
            <Tabs>
              {list.map((element) => (
                <TabPane
                  tab={
                    <Badge
                      style={{ backgroundColor: 'var(--addColor)' }}
                      count={dataSource[element]?.length}
                    >
                      <span style={{ marginRight: 20 }}>
                        {t(`stock-movements.${element}`)}
                      </span>
                    </Badge>
                  }
                  key={element}
                >
                  <Table
                    rowKey={element}
                    columns={columns}
                    dataSource={dataSource[element]}
                    pagination={false}
                  />
                </TabPane>
              ))}
            </Tabs>
          </Card>
        </Row>
        {purpose === 'inventory-transfer' && (
          <>
            <Divider />
            <Row style={{ marginBottom: 10 }}>
              <Col span={12} offset={6}>
                <Row justify="space-between" style={{ margin: 10 }}>
                  <span style={{ marginRight: 20 }}>Taux de TVA ( en % )</span>
                  <Select
                    showSearch
                    optionFilterProp="children"
                    options={vats?.map(({ reference, _id, ...rest }) => ({
                      label: `${rest.rate || 0} %`,
                      value: _id,
                      option: rest
                    }))}
                    style={{ width: 400 }}
                    placeholder="Choisir un taux pour la TVA"
                    onChange={(value, option) =>
                      setVatForTransfer({ value, ...option.option })
                    }
                  />
                </Row>
              </Col>
              <Col span={12} offset={6}>
                <Row justify="space-between" style={{ margin: 10 }}>
                  <span style={{ marginRight: 20 }}>Objet</span>
                  <Input
                    style={{ width: 400 }}
                    onChange={(e) => setObjectToTransfer(e.target.value)}
                  />
                </Row>
              </Col>
              <Col span={12} offset={6}>
                <Row justify="space-between" style={{ margin: 10 }}>
                  <span style={{ marginRight: 20 }}>Référence</span>
                  <Input
                    style={{ width: 400 }}
                    onChange={(e) => setReferenceToTransfer(e.target.value)}
                  />
                </Row>
              </Col>
            </Row>
            {/* <Row justify="space-between"></Row> */}
            <TotalContent listData={dataSource} vat={vatForTransfer} />
          </>
        )}

        <Divider />
        <Row justify="end">
          <Button
            style={{ margin: '0 10px' }}
            type="link"
            danger
            onClick={() => history.push('/production/stock-movements')}
          >
            {`${t('buttons.cancel')} `}
            <CloseOutlined />
          </Button>
          <Button
            disabled={purpose === 'inventory-transfer' && !vatForTransfer}
            type="add"
            onClick={finishMovement}
          >
            {`${t('buttons.save')} `}
            <CheckOutlined />
          </Button>
        </Row>
      </ContentCustom>
    </Spin>
  );
};

CreateUpdateMovementStock.propTypes = {
  purpose: PropTypes.string.isRequired
};

export default CreateUpdateMovementStock;
