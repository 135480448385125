import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListSites from './ListSites';
import CreateUpdateSite from './CreateUpdateSite';
import ShowSite from './ShowSite';
import { SiteContextProvider } from './SiteContext';

const SitesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <SiteContextProvider>
      <Switch>
        <Route
          path={`${path}/create/:idCustomer`}
          render={() => <CreateUpdateSite purpose="createByCustomer" />}
        />
        <Route
          path={`${path}/create`}
          render={() => <CreateUpdateSite purpose="create" />}
        />

        <Route
          path={`${path}/edit/:id`}
          render={() => <CreateUpdateSite purpose="edit" />}
        />
        <Route path={`${path}/show/:id`} component={ShowSite} />
        <Route path={path} component={ListSites} />
      </Switch>
    </SiteContextProvider>
  );
};

export default SitesRouter;
