import React from 'react';
import { Skeleton, Typography } from 'antd';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { routes, subRoutes } from '../../../../utils/constants/routes';

const Container = styled.div`
  background-color: var(--contrastBackground);
  padding: 10px;
  min-height: 150px;
`;
const { Title } = Typography;

const Site = ({ isLoading, movements, resource }) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Skeleton loading={isLoading} paragraph={{ rows: 1, width: '50%' }}>
        {movements?.site && (
          <>
            <Title level={5} style={{ marginBottom: '0', color: 'lightgrey' }}>
              {t(`${resource}.show.site`)}
            </Title>
            <Title level={3} style={{ marginTop: '0' }}>
              <Link
                style={{ color: 'var(--addColor)' }}
                to={{
                  pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.CUSTOMERS}/show/${movements?.customer?._id}`
                }}
              >
                {movements?.site?.reference}
              </Link>
            </Title>
            <Link
              style={{ color: 'var(--addColor)' }}
              to={{
                pathname: `${routes.COMMERCIAL}${subRoutes.COMMERCIAL.QUOTATIONS}/show/${movements?.quotation?._id}`
              }}
            >
              {movements?.quotation?.number}
            </Link>
            <br />
            {movements?.site?.address_details_1}
            {movements?.site?.address_details_2 === undefined
              ? ''
              : movements?.site?.address_details_2}
            <br />
            {`${movements?.site?.postal_code} ${movements?.site?.city}`}
          </>
        )}
      </Skeleton>
    </Container>
  );
};

Site.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  resource: PropTypes.string.isRequired,
  movements: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
Site.defaultProps = {
  movements: null
};
export default Site;
