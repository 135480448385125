import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import GeneralConfigRouter from './General/GeneralConfigRouter';
import TruckRouter from './Trucks/TrucksRouter';
import PrestationsRouter from './Prestations/PrestationsRouter';
import OrganizationsRouter from './Organizations/OrganizationsRouter';
import DocumentsRouter from '../Documents/DocumentsRouter';
import Settings from '../Settings/settings';

const AccountRouter = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        path={`${path}${subRoutes.CONFIGURATION.GENERAL}`}
        component={GeneralConfigRouter}
      />
      <Route
        path={`${path}${subRoutes.CONFIGURATION.ORGANIZATIONS}`}
        component={OrganizationsRouter}
      />
      <Route
        path={`${path}${subRoutes.CONFIGURATION.PRESTATIONS}`}
        component={PrestationsRouter}
      />
      <Route
        path={`${path}${subRoutes.CONFIGURATION.TRUCKS}`}
        component={TruckRouter}
      />
      <Route
        path={`${path}${subRoutes.CONFIGURATION.SETTINGS}`}
        component={Settings}
      />
      <Route path={`${path}`} component={DocumentsRouter} />
    </Switch>
  );
};

export default AccountRouter;
