import React, { useEffect, useState } from 'react';
import { Button, Input, message } from 'antd';
import { useTranslation } from 'react-i18next';
import useAuthContext from '../../../../contexts/AuthContext';
import FoamTable from './foamTable';

const initialColumns = [
  {
    title: 'FOAM',
    dataIndex: 'columns',
    key: 'columns'
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => <Input InitialValue={text} />
  }
];

const FoamSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [foamCols, setFoamCols] = useState(initialColumns);
  const [newFoamCol] = useState({
    label: 'Nouvelle mousse'
  });

  const getFoamCols = async () => {
    try {
      const data = await dispatchAPI('GET', { url: '/foams' });
      setFoamCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const addFoamCol = async () => {
    try {
      await dispatchAPI('POST', { url: '/foams', body: newFoamCol });
      getFoamCols();
    } catch (e) {
      message.error(e.message);
    }
  };

  const deleteFoamCol = async (foamCol) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/foams/${foamCol._id}`
      });
    } catch (e) {
      message.error(e.message);
    }
    await getFoamCols();
  };

  const editFoamCol = async (_id, row) => {
    const col = foamCols.find((col) => col._id === _id);
    const useCol = [];
    if (useCol.length !== 0 && row.label !== col.label) {
      return message.error(t('configuration.error-delete-column'));
    }
    try {
      await dispatchAPI('PATCH', {
        url: `/foams/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    await getFoamCols();
  };
  useEffect(() => {
    getFoamCols();
  }, []);

  return (
    <>
      <FoamTable
        foamCols={foamCols}
        editFoamCol={editFoamCol}
        deleteFoamCol={deleteFoamCol}
      />
      <Button
        type="dashed"
        style={{ width: '100%', height: 50 }}
        onClick={addFoamCol}
      >
        {t('configuration.foam.add-foam-col')}
      </Button>
    </>
  );
};

export default FoamSettings;
