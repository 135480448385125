import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListTrucks from './ListTrucks';
import CreateUpdateTruck from './CreateUpdateTruck';
import ShowTruck from './ShowTruck';
import { TruckContextProvider } from './TruckContext';

const OrderRouter = () => {
  const { path } = useRouteMatch();

  return (
    <TruckContextProvider>
      <Switch>
        <Route
          path={`${path}/create`}
          render={() => <CreateUpdateTruck purpose="create" />}
        />
        <Route
          path={`${path}/edit/:id`}
          render={() => <CreateUpdateTruck purpose="edit" />}
        />
        <Route path={`${path}/show/:id`} component={ShowTruck} />
        <Route path={path} component={ListTrucks} />
      </Switch>
    </TruckContextProvider>
  );
};

export default OrderRouter;
