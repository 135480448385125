import { Tag, Badge, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import React from 'react';
import moment from 'moment';

const useColumns = () => {
  const { t } = useTranslation();
  const statusFilter = [
    { text: 'VALIDÉ', value: 'VALIDÉ' },
    { text: 'EN PANNE', value: 'EN PANNE' },
    { text: 'OBSOLÈTE', value: 'OBSOLÈTE' }
  ];

  return [
    {
      title: t('articles.column.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('articles.column.wording'),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('articles.column.commissioning_date'),
      key: 'commissioning_date',
      dataIndex: 'commissioning_date',
      render: (date) => moment(date).format('DD/MM/YYYY'),
      sorter: true
    },
    {
      title: (
        <p style={{ marginRight: 20, marginTop: 15 }}>
          {t('articles.column.condition')}
        </p>
      ),
      key: 'condition',
      dataIndex: 'condition',
      render: (condition) => <Tag>{condition}</Tag>,
      filters: statusFilter
    },
    {
      title: t('articles.column.buy_price'),
      key: 'buy_price',
      dataIndex: 'buy_price',
      render: (price) => `${price} €`,
      sorter: true,
      fixed: 'right'
    },
    {
      title: t('articles.column.stock_global'),
      key: 'stock',
      dataIndex: 'stock',
      render: (e) => (
        <Tooltip
          style={{ left: 20 }}
          placement="top"
          title="Nombre de dépôts concernés"
        >
          <Badge
            style={{ backgroundColor: 'var(--addColor)', left: 20 }}
            size="small"
            count={e.length}
          >
            {e
              ?.map((ele) => ele.quantity)
              .reduce((curr, prev) => curr + prev, 0)}
          </Badge>
        </Tooltip>
      ),
      sorter: true
    }
  ];
};

export default useColumns;
