import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, message } from 'antd';

import { useHistory } from 'react-router-dom';
import useAuthContext from '../../../../contexts/AuthContext';

const ExtraShowComponent = ({ id }) => {
  const history = useHistory();
  const { dispatchAPI } = useAuthContext();
  const [currentQuotation, setCurrentQuotation] = useState(null);

  const postDuplicateQuotation = async (body) => {
    try {
      const { data } = await dispatchAPI('POST', {
        url: `/quotations/`,
        body: { ...body, email_sent: false }
      });
      history.replace(`/commercial/quotations/show/${data._id}`);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const duplicateQuotation = async () => {
    const newQuotation = { ...currentQuotation, duplicate: true };
    delete newQuotation._id;
    delete newQuotation._v;
    delete newQuotation.created_at;
    delete newQuotation.updated_at;
    newQuotation.status = 'REQUEST';

    try {
      await postDuplicateQuotation(newQuotation);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getQuotation = async () => {
    try {
      const result = await dispatchAPI('GET', { url: `/quotations/${id}` });
      setCurrentQuotation(result.data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    if (id) {
      getQuotation();
    }
  }, [id]);

  return (
    <>
      <Button type="link" onClick={duplicateQuotation}>
        Dupliquer
      </Button>
    </>
  );
};

ExtraShowComponent.propTypes = {
  id: PropTypes.string
};
ExtraShowComponent.defaultProps = {
  id: undefined
};
export default ExtraShowComponent;
