import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import Deals from './Deals/List/Deals';
import ShowDeal from './Deals/Show/ShowDeal';
import ListCompanies from './Companies/ListCompanies';
import ShowCompany from './Companies/ShowCompany';
import CreateUpdateCompany from './Companies/CreateUpdateCompany';
import ContactRouter from './Contacts/ContactRouter';
import SitesRouter from './Sites/SitesRouter';
import QuotationRouter from './Quotations/QuotationRouter';
import { DealsContextProvider } from './Deals/List/DealsContext';

const CommercialRouter = () => {
  const { path } = useRouteMatch();

  return (
    <DealsContextProvider>
      <Switch>
        <Route
          path={`${path}/:type/show/:id/contacts`}
          component={ContactRouter}
        />
        <Route
          path={`${path}${subRoutes.COMMERCIAL.SITES}`}
          component={SitesRouter}
        />
        <Route
          path={`${path}${subRoutes.COMMERCIAL.QUOTATIONS}`}
          component={QuotationRouter}
        />
        <Route
          path={`${path}${subRoutes.COMMERCIAL.DEALS}/show/:id`}
          component={ShowDeal}
        />
        <Route
          path={`${path}${subRoutes.COMMERCIAL.DEALS}`}
          component={Deals}
        />
        <Route
          path={`${path}/:type/create`}
          render={() => <CreateUpdateCompany purpose="create" />}
        />
        <Route
          path={`${path}/:type/edit/:id`}
          render={() => <CreateUpdateCompany purpose="edit" />}
        />
        <Route path={`${path}/:type/show/:id`} component={ShowCompany} />
        <Route
          path={`${path}/:type`}
          render={() => <ListCompanies companyType="CUSTOMERS" />}
        />
        <Route
          path={path}
          render={() => (
            <Redirect to={`${path}${subRoutes.COMMERCIAL.CUSTOMERS}`} />
          )}
        />
      </Switch>
    </DealsContextProvider>
  );
};

export default CommercialRouter;
