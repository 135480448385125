import React from 'react';
import { Form, InputNumber, Select } from 'antd';
import { useTranslation } from 'react-i18next';

const TotalFields = (number) => {
  const { t } = useTranslation();
  const percentageList = [0, 1, 1.5, 2];

  return (
    <div style={{ textAlign: 'center' }}>
      <Form.Item
        initialValue={0}
        name={['deposit_invoice', 'subTotal']}
        label={`${t(`invoices.form.is_deposit`)} (${number.number})`}
      >
        <InputNumber
          style={{
            top: 10,
            fontSize: 17,
            width: 150,
            fontWeight: 'bold'
          }}
          formatter={(value) =>
            value && `-${parseFloat(value, 10).toFixed(2)} €`
          }
          readOnly
          bordered={false}
        />
      </Form.Item>
      <Form.Item
        initialValue={0}
        name={['subTotal']}
        label={t(`invoices.form.subTotal`)}
      >
        <InputNumber
          style={{
            top: 10,
            fontSize: 17,
            width: 150,
            fontWeight: 'bold'
          }}
          formatter={(value) =>
            value && `${parseFloat(value, 10).toFixed(2)} €`
          }
          readOnly
          bordered={false}
        />
      </Form.Item>
      <Form.Item
        initialValue={0}
        name={['total_VAT']}
        label={t(`invoices.form.total_VAT`)}
      >
        <InputNumber
          style={{
            top: 10,
            fontSize: 17,
            width: 150,
            fontWeight: 'bold'
          }}
          formatter={(value) =>
            value && `${parseFloat(value, 10).toFixed(2)} €`
          }
          readOnly
          bordered={false}
        />
      </Form.Item>
      <Form.Item
        initialValue={0}
        name={['total_discount']}
        label={t(`invoices.form.total_discount`)}
      >
        <InputNumber
          formatter={(value) =>
            value && `${parseFloat(value, 10).toFixed(2)} €`
          }
          style={{
            top: 10,
            fontSize: 17,
            width: 150,
            fontWeight: 'bold'
          }}
          readOnly
          bordered={false}
        />
      </Form.Item>

      <Form.Item
        initialValue={0}
        name={['holdback']}
        label={t(`invoices.form.holdback`)}
      >
        <InputNumber
          formatter={(value) => value && `${value} %`}
          initialValues={5}
          controls={false}
          style={{
            top: 10,
            fontSize: 17,
            width: 150,
            fontWeight: 'bold'
          }}
          bordered={false}
        />
      </Form.Item>
      <Form.Item
        name={['prorata_percentage']}
        label={t(`invoices.form.prorata_percentage`)}
      >
        <Select style={{ width: 150, top: 10 }}>
          {percentageList.map((ele) => (
            <Select.Option key={ele} value={ele}>{`${ele} %`}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        initialValue={0}
        name={['prorata_account']}
        label={t(`invoices.form.prorata`)}
      >
        <InputNumber
          formatter={(value) =>
            value && `${parseFloat(value, 10).toFixed(2)} €`
          }
          initialValues={0}
          style={{
            top: 10,
            fontSize: 17,
            width: 150,
            fontWeight: 'bold'
          }}
          bordered={false}
          controls={false}
        />
      </Form.Item>

      <Form.Item
        initialValue={0}
        style={{ fontSize: 18 }}
        colon={false}
        name={['total']}
        label={<p style={{ fontSize: 18 }}>{t(`invoices.form.total`)}</p>}
      >
        <InputNumber
          formatter={(value) =>
            value && `${parseFloat(value, 10).toFixed(2)} €`
          }
          style={{
            top: 10,
            fontSize: 20,
            width: 150,
            fontWeight: 'bold'
          }}
          readOnly
          bordered={false}
        />
      </Form.Item>
    </div>
  );
};

export default TotalFields;
