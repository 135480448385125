import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Input, InputNumber, Select, Form, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';
import NewSupplierDrawer from '../Articles/NewSupplierDrawer';

const { TextArea } = Input;
const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [suppliers, setSuppliers] = useState([]);
  const [units, setUnits] = useState([]);
  const [isSupplierDrawerVisible, setVisibleSupplierDrawer] = useState(false);

  const getSuppliers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/companies?type=SUPPLIERS'
      });
      setSuppliers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const basicFields = [
    {
      name: ['code'],
      rules: [{ required: true }]
    },
    {
      name: ['label'],
      rules: [{ required: true }]
    },
    {
      name: ['quantity_min'],
      rules: [
        { required: true },
        {
          type: 'number',
          min: 0,
          message: 'La valeur ne doit pas être inférieure à 0'
        }
      ],
      input: <InputNumber style={{ width: 150 }} min={0} />
    },
    {
      name: ['buy_price'],
      rules: [
        { required: true },
        {
          type: 'number',
          min: 0,
          message: 'La valeur ne doit pas être inférieure à 0'
        }
      ],
      input: (
        <InputNumber
          style={{ width: 150 }}
          min={0}
          formatter={(value) => `${value}€`}
          parser={(value) => value.replace('€', '')}
        />
      )
    },
    {
      name: ['mark_up'],
      input: (
        <InputNumber
          style={{ width: 150 }}
          min={0}
          formatter={(value) => `${value}%`}
          parser={(value) => value.replace('%', '')}
        />
      )
    },
    {
      name: ['sold_price'],
      rules: [{ required: true }],
      input: (
        <InputNumber
          style={{ width: 150 }}
          min={0}
          formatter={(value) => `${value}€`}
          parser={(value) => value.replace('€', '')}
        />
      )
    },
    {
      name: ['unit'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {units.map(({ label, _id }) => (
            <Option key={_id} value={_id}>
              {label.toUpperCase()}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['supplier'],
      input: (
        <Input.Group style={{ display: 'flex' }}>
          <NewSupplierDrawer
            isSupplierDrawerVisible={isSupplierDrawerVisible}
            setVisibleSupplierDrawer={setVisibleSupplierDrawer}
            getSuppliers={getSuppliers}
            suppliers={suppliers}
          />
          <Form.Item name={['supplier']} style={{ width: '100%' }}>
            <Select style={{ width: '100%' }} loading={isFieldsLoading}>
              {suppliers.map(({ name, _id }) => (
                <Option key={name} value={_id}>
                  {name.toUpperCase()}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              type="add"
              style={{ marginLeft: '10px' }}
              onClick={() => {
                setVisibleSupplierDrawer(!isSupplierDrawerVisible);
              }}
            >
              {`${t('buttons.create')} `}
              <PlusOutlined />
            </Button>
          </Form.Item>
        </Input.Group>
      )
    },
    {
      name: ['ref_supplier']
    },
    {
      name: ['description'],
      input: <TextArea />
    }
  ];

  const getUnits = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/units'
      });
      setUnits(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getSuppliers();
    await getUnits();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  return { basicFields, isFieldsLoading };
};

export default useFields;
