import React, { useState } from 'react';
import { Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchAddBar } from '../../../../utils/constants/customButton';
import { routes } from '../../../../utils/constants/routes';
import NewContactDrawer from '../../Contacts/NewContactDrawer';
import Datatable from '../../../../components/DataTable/Datatable';
import useColumns from '../../Contacts/columns';

const ModaleEditContact = ({ deal, isModalVisible, setIsModalVisible }) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState('');
  const [forceRefresh, setForceRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const columns = useColumns();
  const company = deal?.companies ? deal?.companies : {};
  const closeModale = () => {
    setIsModalVisible(false);
  };

  return (
    <Modal
      title={t('deals.show.contacts.edit')}
      width={1000}
      visible={isModalVisible}
      onOk={closeModale}
      onCancel={closeModale}
    >
      <SearchAddBar
        addPath={`${routes.COMMERCIAL}/${
          company.type ? company.type.toLowerCase() : 'customer'
        }/show/${company._id}/contacts/create`}
        setSearchValue={setSearchValue}
        onClick={() => {
          setVisible(!visible);
        }}
      />
      <NewContactDrawer
        isVisible={visible}
        setVisibilityContactDrawer={setVisible}
        setForceRefresh={setForceRefresh}
      />
      <Datatable
        resourceName="contacts"
        path={`${routes.COMMERCIAL}/${
          company.type ? company.type.toLowerCase() : 'customer'
        }/show/${company._id}/contacts`}
        columns={columns}
        populate="my_company"
        extraQuery={`my_company=${company._id}`}
        searchValue={searchValue}
        forceRefresh={forceRefresh}
      />
    </Modal>
  );
};

export default ModaleEditContact;
