import React, { useState } from 'react';
import { Checkbox, message } from 'antd';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import columns from './columns';

const ListSites = () => {
  const [showArchived, setShowArchived] = useState(false);
  const { dispatchAPI } = useAuthContext();
  const [forceRefresh, SetForceRefresh] = useState(false);
  const headers = [
    {
      label: 'Code',
      key: 'code'
    },
    {
      label: 'Nom',
      key: 'label' || ''
    },
    {
      label: 'Unité de projection',
      key: 'projection_unit'
    },
    {
      label: 'Abbreviation',
      key: 'abbreviation' || ''
    },
    {
      label: 'Coefficient',
      key: 'coefficient' || ''
    },
    {
      label: 'Dépôt',
      key: 'deposit' || ''
    }
  ];
  const deleteResource = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/sites/${id}` });
      SetForceRefresh(!forceRefresh);
    } catch (e) {
      if (e.response) message.error(e.response.data.message);
    }
  };
  const onChangeCheckbox = (e) => {
    console.log(`checked = ${e.target.checked}`);
    setShowArchived(e.target.checked);
  };
  return (
    <SimpleResourceLandingLayout
      resourceName="sites"
      columns={[...columns(deleteResource)]}
      populate="quotation,contact,company"
      extraQuery={`archived=${showArchived}`}
      withSubRoutes
      customActionColumn
      withUploadButton={false}
      forceRefresh={forceRefresh}
      headers={headers}
      extraButtons={
        <Checkbox style={{ marginRight: 50 }} onChange={onChangeCheckbox}>
          Archivés / Annulés
        </Checkbox>
      }
      resourceModelName="Site"
    />
  );
};

export default ListSites;
