import React from 'react';
import { Select, Input, Tag, DatePicker } from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import useInvoiceContext from '../InvoiceContext';

import { invoiceTypes } from '../../../../utils/constants/tagColors';

const { TextArea } = Input;

const useBodyFields = (purpose) => {
  const { t } = useTranslation();
  const { enums, isLoading, settings, vats, banks } = useInvoiceContext();
  const listAccount = ['sold', 'subtractor'];

  const bodyFields = [
    {
      name: ['payment_bank'],
      rules: [{ required: true }],
      input: (
        <Select
          options={banks.map((bank) => ({
            label: `${bank.name} - ${bank.city || ''}`,
            value: bank._id
          }))}
          loading={isLoading}
        />
      )
    },
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          options={(enums?.types || []).map((type) => ({
            label: (
              <Tag key={type} color={invoiceTypes[type]}>
                {t(`invoices.tags.${type}`)}
              </Tag>
            ),
            value: type
          }))}
          loading={isLoading}
        />
      )
    }
  ];
  if (purpose === 'edit' && settings) {
    bodyFields.push({
      name: ['number'],
      input: <Input disabled />
    });
  }
  bodyFields.push(
    { name: ['subject'], rules: [{ required: true }] },
    {
      name: ['comment'],
      input: (
        <TextArea placeholder="Maximum de 200 caractères" maxLength={200} />
      )
    },
    {
      name: ['emission_date'],
      input: <DatePicker format="DD/MM/YYYY" />,
      initialValue: moment()
    },
    {
      name: ['deadline_date'],
      input: <DatePicker format="DD/MM/YYYY" />,
      initialValue:
        settings?.find(
          (setting) => setting.label === 'default_invoice_deadline'
        ) &&
        moment().add(
          parseInt(
            settings?.find(
              (setting) => setting.label === 'default_invoice_deadline'
            ).value,
            10
          ),
          'days'
        )
    }
  );
  // if (purpose === 'edit') {
  //   bodyFields.push({
  //     name: ['payment_date'],
  //     input: <DatePicker />
  //   });
  // }
  bodyFields.push(
    {
      name: ['vat'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '100%' }}
          options={
            vats &&
            vats.map((item) => ({
              label: `${item?.description} : ${item?.rate} %`,
              value: item._id
            }))
          }
          loading={isLoading}
        />
      )
    },
    {
      name: ['code_account'],
      rules: [{ required: true }],
      input: (
        <Select
          style={{ width: '100%' }}
          options={listAccount.map((item) => ({
            label: `${t(`invoices.form.${item}`)}`,
            value: item
          }))}
          loading={isLoading}
        />
      )
    }
  );
  return {
    bodyFields
  };
};

export default useBodyFields;
