import React, { useState, useEffect } from 'react';
import { Row, Col, Collapse, Card, Calendar, Badge, Descriptions } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { WarningOutlined, EyeOutlined } from '@ant-design/icons';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import useAuthContext from '../../../contexts/AuthContext';
import { routes, subRoutes } from '../../../utils/constants/routes';

const { Panel } = Collapse;

const HomeTech = () => {
  const { dispatchAPI, user } = useAuthContext();
  const [movements, setMovements] = useState([]);
  const [cstbs, setCstbs] = useState([]);

  const getMovements = async () => {
    const cstbsList = [];
    const { data } = await dispatchAPI('GET', {
      url: `/movements?technicians=${user._id}`
    });
    data.forEach((el) => moment(el.date) <= moment() && cstbsList.push(el));
    setCstbs(cstbsList);
    setMovements(data);
  };

  const incomingMovements =
    movements &&
    movements.filter(
      (el) =>
        moment(el.date).format('DD-MM-YYYY') ===
          moment().format('DD-MM-YYYY') && el.status !== 'VALIDATED'
    );

  const hoursControlmissing = () => {
    const result = [];
    if (cstbs) {
      cstbs.forEach((cstb) => {
        if (cstb.CSTB?.hours_control.length === 0) return result.push(cstb);
        if (
          cstb.CSTB?.hours_control.filter(
            (ele) => ele.technician_id !== user._id
          ).length !== 0
        )
          return result.push(cstb);
        return false;
      });
    }
    return result;
  };

  useEffect(() => {
    if (user._id) {
      getMovements();
    }
  }, []);

  const colors = { COMMERCIAL: 'warning', TECHNICIANS: 'blue' };

  const dateCellRender = (value) => {
    return (
      <div className="events">
        {movements
          .filter(
            (el) =>
              moment(el.date).format('DD-MM-YYYY') ===
                moment().format('DD-MM-YYYY') && el.status !== 'VALIDATED'
          )
          .map((e) => {
            return (
              moment(e?.date).format('DD-MM-YYYY') ===
                value.format('DD-MM-YYYY') && (
                <Row key={e?._id}>
                  <Col span={24}>
                    <Badge
                      key={e?._id}
                      status={colors[e?.type]}
                      text={e?.site?.reference}
                    />
                  </Col>
                  <Col span={12}>
                    <Link
                      to={{
                        pathname: `${routes.INTERVENTION}${subRoutes.INTERVENTION.MANAGE_INTERVENTIONS}/show/${e?._id}`
                      }}
                    >
                      <EyeOutlined style={{ color: 'var(--addColor)' }} />
                    </Link>
                  </Col>
                </Row>
              )
            );
          })}
      </div>
    );
  };

  return (
    <>
      <PageHeaderCustom title="Tableau de bord" />
      <ContentCustom>
        <Row justify="end">
          <Link
            to={{
              pathname: `${routes.PRODUCTION}${subRoutes.PRODUCTION.STOCK_MOVEMENTS}/create`
            }}
          >
            <Card
              bordered={false}
              style={{
                width: '170px',
                backgroundColor: 'var(--primaryColor)',
                textAlign: 'center',
                color: 'white',
                borderRadius: '8px'
              }}
            >
              <span style={{ fontSize: '18px' }}>Sortie de stock</span>
            </Card>
          </Link>
        </Row>
        <br />
        <Collapse defaultActiveKey={[1, 2]}>
          <Panel
            header="Interventions du jour"
            key="1"
            style={{ fontSize: '18px' }}
          >
            <Row gutter={[16, 16]}>
              {incomingMovements &&
                incomingMovements.map(
                  ({ date, truck, customer, site, _id, quotation }) => (
                    <Col span={24} key={_id}>
                      <Link
                        to={{
                          pathname: `${routes.INTERVENTION}${subRoutes.INTERVENTION.MANAGE_INTERVENTIONS}/show/${_id}`
                        }}
                      >
                        <Card
                          key={_id}
                          style={{
                            borderRadius: '8px',
                            boxShadow: '5px 5px 5px rgba(0, 0, 0, .25)'
                          }}
                        >
                          <Row
                            justify="space-between"
                            style={{
                              fontWeight: 'bold',
                              color: 'var(--primaryColor)'
                            }}
                          >
                            <Col>
                              <p>{truck.label}</p>
                            </Col>
                            <Col>
                              <p style={{ fontSize: '18px' }}>
                                {moment(date).format('DD/MM/YYYY')}
                              </p>
                            </Col>
                          </Row>
                          <Row>
                            <Descriptions>
                              <Descriptions.Item label="Devis">
                                {`${quotation.number} - ${quotation.subject}`}
                              </Descriptions.Item>
                              <Descriptions.Item label="Client">
                                {customer.name}
                              </Descriptions.Item>
                            </Descriptions>
                          </Row>
                          <Row>
                            <Descriptions>
                              <Descriptions.Item label="Chantier">
                                {site.reference}
                              </Descriptions.Item>
                              <Descriptions.Item label="Adresse">
                                {`${site.address_details_1} ${site.postal_code}`}
                              </Descriptions.Item>
                            </Descriptions>
                          </Row>
                        </Card>
                      </Link>
                    </Col>
                  )
                )}
            </Row>
          </Panel>

          <Panel
            header="Fiches d'heures en attente"
            key="2"
            style={{ fontSize: '18px' }}
          >
            <Row gutter={[16, 16]}>
              {hoursControlmissing().map(
                ({ date, truck, customer, site, _id, quotation }) => (
                  <Col span={24} key={_id}>
                    <Link
                      to={{
                        pathname: `${routes.INTERVENTION}${subRoutes.INTERVENTION.MANAGE_INTERVENTIONS}/show/${_id}/5`
                      }}
                    >
                      <Card
                        key={_id}
                        style={{
                          borderRadius: '8px',
                          boxShadow: '5px 5px 5px rgba(0, 0, 0, .25)'
                        }}
                      >
                        <Row
                          justify="space-between"
                          style={{
                            fontWeight: 'bold',
                            color: 'var(--primaryColor)'
                          }}
                        >
                          <Col>
                            <p>
                              <WarningOutlined
                                style={{ color: 'red', fontSize: '22px' }}
                              />
                              {truck.label}
                            </p>
                          </Col>
                          <Col>
                            <p style={{ fontSize: '18px' }}>
                              {moment(date).format('DD/MM/YYYY')}
                            </p>
                          </Col>
                        </Row>
                        <Row>
                          <Descriptions>
                            <Descriptions.Item label="Devis">
                              {`${quotation.number} - ${quotation.subject}`}
                            </Descriptions.Item>
                            <Descriptions.Item label="Client">
                              {customer.name}
                            </Descriptions.Item>
                          </Descriptions>
                        </Row>

                        <Row>
                          <Descriptions>
                            <Descriptions.Item label="Chantier">
                              {site.reference}
                            </Descriptions.Item>
                            <Descriptions.Item label="Adresse">
                              {`${site.address_details_1} ${site.postal_code}`}
                            </Descriptions.Item>
                          </Descriptions>
                        </Row>
                      </Card>
                    </Link>
                  </Col>
                )
              )}
            </Row>
          </Panel>
        </Collapse>
        <br />
        <Row>
          <Card
            style={{
              borderRadius: '8px',
              boxShadow: '5px 5px 5px rgba(0, 0, 0, .25)'
            }}
          >
            <Calendar
              dateCellRender={dateCellRender}
              validRange={[moment().startOf('week'), moment().endOf('week')]}
            />
          </Card>
        </Row>
      </ContentCustom>
    </>
  );
};

export default HomeTech;
