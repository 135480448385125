import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useListContent = ({
  family,
  label,
  code,
  unit,

  quantity_min,
  buy_price,
  sold_price,
  tva,
  description,
  supplier
}) => {
  const { t } = useTranslation();
  return [
    {
      label: t('articles.form.code'),
      content: code,
      span: 1
    },
    {
      label: t('articles.form.label'),
      content: label,
      span: 1
    },
    {
      label: t('articles.form.family'),
      content: family && family.label,
      span: 1
    },
    {
      label: t('articles.form.quantity_min'),
      content: quantity_min,
      span: 1
    },
    {
      label: t('articles.form.unit'),
      content: unit?.label,
      span: 2
    },
    {
      label: t('articles.form.buy_price'),
      content: `${buy_price} €`,
      span: 1
    },
    {
      label: t('articles.form.sold_price'),
      content: `${sold_price} €`,
      span: 1
    },
    {
      label: t('articles.form.mark_up'),
      content:
        sold_price &&
        buy_price &&
        `${Math.round((sold_price / buy_price) * 100 - 100)} %`,
      span: 1
    },
    {
      label: t('articles.form.tva'),
      content: tva && `${tva} %`,
      span: 1
    },
    {
      label: t('articles.form.supplier'),
      content: supplier && (
        <Link to={`/purchases/suppliers/show/${supplier._id}`}>
          {supplier.name}
        </Link>
      ),
      span: 3
    },
    {
      label: t('articles.form.description'),
      content: description,
      span: 3
    }
  ];
};

export default useListContent;
