import React from 'react';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';

const ListOrganizations = () => {
  const headers = [
    {
      label: 'Name',
      key: 'name'
    },
    {
      label: 'channel_number',
      key: 'channel_number'
    },
    {
      label: 'address_details',
      key: 'address_details'
    },
    {
      label: 'postal_code',
      key: 'postal_code'
    },
    {
      label: 'city',
      key: 'city'
    },
    {
      label: 'siren_number',
      key: 'siren_number'
    }
  ];
  return (
    <SimpleResourceLandingLayout
      resourceName="organizations"
      columns={[...columns()]}
      withSubRoutes
      headers={headers}
      resourceModelName="Organization"
    />
  );
};

export default ListOrganizations;
