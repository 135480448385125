import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Tag, message } from 'antd';
import useTruckContext from './TruckContext';

const useColumns = () => {
  const { t } = useTranslation();
  const [depositsFilter, setDepositsFilter] = useState([]);
  const { dispatchAPI } = useTruckContext();
  const fetchDeposits = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: 'deposits' });
      setDepositsFilter(
        data.map(({ label, _id }) => ({ text: label, value: _id }))
      );
    } catch (e) {
      message.error(
        `${t(`errors.message.${e.response.status}`)} : Liste des dépôts `
      );
    }
  };

  useEffect(() => {
    (async () => {
      await fetchDeposits();
    })();
  }, []);

  return [
    {
      title: t('trucks.column.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('trucks.column.label'),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('trucks.column.projection_unit'),
      key: 'projection_unit',
      dataIndex: 'projection_unit',
      sorter: true
    },
    {
      title: t('trucks.column.abbreviation'),
      key: 'abbreviation',
      dataIndex: 'abbreviation',
      sorter: true
    },
    {
      title: t('trucks.column.coefficient'),
      key: 'coefficient',
      dataIndex: 'coefficient',
      sorter: true
    },
    {
      title: t('trucks.column.deposit'),
      key: 'deposit',
      dataIndex: 'deposit',
      render: (deposit) => deposit && deposit.label,
      sorter: true,
      filters: depositsFilter
    },
    {
      title: t('trucks.column.users'),
      key: 'users',
      dataIndex: 'users',
      width: '30%',
      sorter: true,
      render: (users) =>
        users.map(({ first_name, last_name, _id }) => (
          <Tag key={_id}>{`${first_name} ${last_name.toUpperCase()}`}</Tag>
        ))
    }
  ];
};

export default useColumns;
