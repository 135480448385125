import React from 'react';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import columns from './columns';

const ListArticles = () => {
  const headers = [
    {
      label: 'Code',
      key: 'code' || ''
    },
    {
      label: 'Label',
      key: 'label'
    },
    {
      label: 'Quantity',
      key: 'quantity'
    },
    {
      label: 'Unit',
      key: 'unit'
    },
    {
      label: 'Family',
      key: 'family'
    },
    {
      label: 'Solde price',
      key: 'sold_price' || ''
    },
    {
      label: 'Buy price',
      key: 'buy_price' || ''
    },
    {
      label: 'TVA',
      key: 'tva'
    },
    {
      label: 'Description',
      key: 'description'
    },
    {
      label: 'Supplier',
      key: 'supplier'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      resourceName="articles"
      columns={[...columns()]}
      populate="family,company,unit"
      withSubRoutes
      headers={headers}
      resourceModelName="Article"
      withUploadButton={false}
      searchPlaceHolder="Entrer un Code ou Nom"
    />
  );
};

export default ListArticles;
