import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Layout } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import Logo from '../../assets/images/logostrategin.png';
import LogoCollapsed from '../../assets/images/logostrateginCollapsed.png';
import HeaderLayout from './HeaderLayout/HeaderLayout';
import useHandleResize from '../../utils/HandleResize';
import NavMenu from './NavMenu';
import UpdateAlert from '../UpdateAlert/UpdateAlert';
import pack from '../../../package.json';

const { Content, Footer, Sider } = Layout;

const StyledLayout = styled.div`
  height: 100vh;
`;

const StyledSider = styled.div`
  height: 100%;
  z-index: 10;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  overflow-x: hidden;
  position: fixed;
  left: 0;
`;

const LogoDiv = styled.div`
  position: relative;
  height: 72px;
  padding-left: 0px;
  overflow: hidden;
  line-height: 72px;
  transition: all 0.3s;
  z-index: 900;
  display: flex;
  justify-content: center;
  img {
    display: inline-block;
    height: 64px;
    vertical-align: middle;
  }

  @media (max-width: 992px) {
    height: 64px;
    line-height: 64px;
    img {
      height: 48px;
    }
  }
`;

const StyledContent = styled.div`
  margin: 64px 0 0 256px;
  overflow: auto;
  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }
`;

const StyledFooter = styled.footer`
  padding: 8px 25px;
  text-align: center;
  margin-left: 255px;
  background-color: var(--bodyBackground);
  color: var(--textColor);
  box-shadow: 0 -1px 4px var(--borderColor);

  a {
    color: var(--primaryColor);
  }

  @media (max-width: 992px) {
    margin-left: 80px;
  }

  @media (max-width: 576px) {
    margin-left: 0;
  }
`;

const BasicLayout = ({ children, path }) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(window.innerWidth < 992);
  const { width, height } = useHandleResize();
  const [collapseWidth, setCollapseWidth] = useState(
    window.innerWidth < 576 ? 0 : 80
  );

  const onCollapse = (newCollapsed) => {
    setCollapsed(newCollapsed);
  };

  useEffect(() => {
    if (width < 576) {
      setCollapseWidth(0);
    } else {
      setCollapseWidth(80);
    }
  }, [width, height]);

  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);

  return (
    <StyledLayout as={Layout}>
      <UpdateAlert path={path} />
      <StyledSider
        as={Sider}
        width={256}
        breakpoint="lg"
        trigger={null}
        collapsible
        collapsedWidth={collapseWidth}
        collapsed={collapsed}
        onCollapse={onCollapse}
      >
        <LogoDiv>
          <Link to="/">
            <img alt="Logo" src={collapsed ? LogoCollapsed : Logo} />
          </Link>
        </LogoDiv>
        <NavMenu path={path} setCollapsed={setCollapsed} />
      </StyledSider>
      <Layout>
        <HeaderLayout
          collapsed={collapsed}
          collapseSider={setCollapsed}
          collapseWidth={collapseWidth}
        />
        <StyledContent as={Content}>{children}</StyledContent>
        <StyledFooter as={Footer}>
          {`${t('global.title')} ©${moment().year()} powered by `}
          <a href="https://strateg.in">Strateg.in</a>
          {pack.version}
        </StyledFooter>
      </Layout>
    </StyledLayout>
  );
};

BasicLayout.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired
};

export default BasicLayout;
