import React from 'react';
import { Chart, Axis, Tooltip, Geom, Legend } from 'bizcharts';

const GraphLoss = ({ graphWasteData }) => {
  const data_reel = graphWasteData?.map((el) => ({
    type: 'Kg gaspillé',
    week: el.week,
    value: el.kg_wasted
  }));
  const data_obj8 = graphWasteData?.map((el) => ({
    type: 'Obj 8%',
    week: el.week,
    value: el.obj_8
  }));
  const data_obj10 = graphWasteData?.map((el) => ({
    type: 'Obj 10%',
    week: el.week,
    value: el.obj_10
  }));

  const cols = {
    value: { min: 0 },
    week: { range: [0, 1] }
  };

  const data = [...data_reel, ...data_obj8, ...data_obj10];

  return (
    <Chart height={300} data={data} scale={cols} forceFit>
      <Axis name="week" />
      <Axis name="value" />
      <Legend />
      <Tooltip crosshairs={{ type: 'y' }} />
      <Geom type="line" position="week*value" size={2} color="type" />
      <Geom
        type="point"
        position="week*value"
        size={4}
        shape={'circle'}
        style={{ stroke: '#fff', lineWidth: 1 }}
        color="type"
      />
    </Chart>
  );
};

export default GraphLoss;
