import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Layout, PageHeader, Popconfirm } from 'antd';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  CheckOutlined,
  DeleteOutlined,
  EditOutlined,
  EyeOutlined,
  WarningOutlined
} from '@ant-design/icons';
import Datatable from '../../../components/DataTable/Datatable';
import { SearchAddBar } from '../../../utils/constants/customButton';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';

const { Content } = Layout;
const iconSize = 18;

const CompanyQuotationsTable = ({ id }) => {
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [searchValue, setSearchValue] = useState();
  const [forceRefresh, setForceRefresh] = useState(false);

  const deleteQuotation = async (id) => {
    try {
      await dispatchAPI('DELETE', { url: `/quotations/${id}` });
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const createOrder = async (body) => {
    try {
      await dispatchAPI('POST', { url: '/orders', body });
      setForceRefresh(true);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const columns = [
    {
      title: t('quotations.list.column.title.number'),
      key: 'number',
      dataIndex: 'number',
      sorter: true
    },
    {
      title: t('quotations.list.column.title.subject'),
      dataIndex: 'subject'
    },
    {
      title: t('quotations.list.column.title.emission_date'),
      dataIndex: 'emission_date',
      render: (date) => date && moment(date).format('DD/MM/YYYY')
    },
    {
      title: t('quotations.list.column.title.total'),
      dataIndex: 'total',
      render: (total) => `${parseFloat(total).toFixed(2)}€`
    },
    {
      key: 'action',
      fixed: 'right',
      width: '12%',
      render: ({ key, order, status, ...rest }) => {
        return (
          <>
            <Link to={{ pathname: `/commercial/quotations/show/${key}` }}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
            {status !== 'VALIDATE' && !order && (
              <>
                <Divider type="vertical" />
                <Link
                  to={{
                    pathname: `/commercial/quotations/edit/${key}`
                  }}
                >
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Link>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.validate.title')}
                  okText={t('datatable.column.action.validate.ok')}
                  okButtonProps={{ type: 'primary' }}
                  cancelText={t('datatable.column.action.validate.cancel')}
                  onConfirm={async () => {
                    setForceRefresh(false);
                    await createOrder({
                      ...rest,
                      quotation: key,
                      emission_date: moment()
                    });
                  }}
                  icon={<WarningOutlined />}
                >
                  <CheckOutlined
                    style={{ color: 'var(--primaryColor)', fontSize: iconSize }}
                    type="validate"
                  />
                </Popconfirm>
                <Divider type="vertical" />
                <Popconfirm
                  title={t('datatable.column.action.delete.title')}
                  okText={t('datatable.column.action.delete.ok')}
                  okButtonProps={{ type: 'danger' }}
                  cancelText={t('datatable.column.action.delete.cancel')}
                  onConfirm={async () => {
                    setForceRefresh(false);
                    await deleteQuotation(key);
                  }}
                  icon={<WarningOutlined />}
                >
                  <DeleteOutlined
                    style={{
                      color: 'var(--secondaryColor)',
                      fontSize: iconSize
                    }}
                    type="delete"
                  />
                </Popconfirm>
              </>
            )}
          </>
        );
      }
    }
  ];

  return (
    <>
      <Content
        style={{
          marginTop: 2,
          padding: 24,
          backgroundColor: '#fff'
        }}
      >
        {/* <SearchAddBar
          addPath="/quotations/create"
          setSearchValue={setSearchValue}
        /> */}

        <Datatable
          resourceName="quotations"
          path="/commercial/quotations"
          columns={columns}
          extraQuery={`customer=${id}&sort=-created_at`}
          searchValue={searchValue}
          customActionColumn
          forceRefresh={forceRefresh}
        />
      </Content>
    </>
  );
};

export default CompanyQuotationsTable;
