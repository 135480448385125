import React from 'react';
import {
  CalendarOutlined,
  CarOutlined,
  ContactsOutlined,
  EuroOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  HomeOutlined,
  ProjectOutlined,
  SettingOutlined,
  StockOutlined,
  TagsOutlined,
  UserOutlined,
  CopyOutlined,
  InboxOutlined,
  EnvironmentOutlined
} from '@ant-design/icons';

const navMenuLogos = {
  HOME: <HomeOutlined />,
  USERS: <UserOutlined />,
  CONFIGURATION: <SettingOutlined />,
  ACCOUNTING: <EuroOutlined />,
  COMMERCIAL: <ContactsOutlined />,
  INTERVENTION: <CalendarOutlined />,
  CONTRACTS: <FileDoneOutlined />,
  SITES: <EnvironmentOutlined />,
  QUOTES: <FileTextOutlined />,
  CUSTOMERS: <ContactsOutlined />,
  DELIVERIES: <CarOutlined />,
  PROJECTS: <ProjectOutlined />,
  THEME: <SettingOutlined />,
  PRODUCTION: <StockOutlined />,
  TICKETS: <TagsOutlined />,
  DOCUMENTS: <CopyOutlined />,
  PURCHASES: <InboxOutlined />
};

export default navMenuLogos;
