import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  Button,
  Tag,
  Select,
  message,
  Input,
  Modal,
  Form,
  DatePicker
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CheckOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { routes, subRoutes } from '../../../utils/constants/routes';
import { movementsStatus } from '../../../utils/constants/tagColors';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import calendar from '../../../assets/images/calendar.png';

const { Option, OptGroup } = Select;
const { TextArea } = Input;

const useListContent = (
  {
    _id,
    truck,
    status,
    quotation,
    date,
    customer,
    works_supervisor,
    site,
    technicians
  },
  setRefresh,
  refresh,
  movements
) => {
  const { message: errorMessage } = useErrorMessage();
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const { confirm } = Modal;
  const [form] = Form.useForm();

  const filteredOptions = users.filter(
    (user) => !truck?.users.map((u) => u._id).includes(user._id)
  );

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/users?populate=truck&type=TECH,SELLER'
      });
      setUsers(data);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getUsers();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const editSite = async (body) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/sites/${site._id}`,
        body
      });
      message.success(t('movements.show.success.annotations'));
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const saveDate = async (newDate) => {
    const body = {
      status: 'WAITING',
      date: '',
      truck: null,
      technicians: []
    };
    try {
      if (status === 'PLANNED') {
        await dispatchAPI('PATCH', {
          url: `/movements/${_id}`,
          body
        });
      }
      await dispatchAPI('PATCH', {
        url: `/quotations/${quotation?._id}`,
        body: { intervention_date_programmed: newDate }
      });
      message.success(t('movements.show.success.date'));
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const onFinish = async () => {
    const body = {
      technicians: form.getFieldValue('technicians'),
      truck: truck._id
    };
    try {
      await dispatchAPI('PATCH', {
        url: `/movements/${_id}`,
        body
      });
      message.success(t('movements.show.success.technicians'));
      setRefresh(!refresh);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const showConfirm = (selectedUser, movement) => {
    confirm({
      title:
        movement?.date === date
          ? `${selectedUser?.first_name} ${
              selectedUser?.last_name
            } est déjà affecté(e) pour le chantier ${
              movement?.site?.reference
            } programmée le ${moment(date).format('LL')}.`
          : `${selectedUser?.first_name} ${selectedUser?.last_name} est déjà affecté(e) au camion ${selectedUser?.truck?.label}.`,
      icon: <ExclamationCircleOutlined />,
      content: `Voulez-vous l'affecter à cette intervention ?`,
      onOk() {
        onFinish();
      },
      onCancel() {
        form.setFieldsValue({
          technicians: form
            .getFieldValue('technicians')
            .filter((u) => u !== selectedUser?._id)
        });
      }
    });
  };

  const checkTruck = (techId) => {
    const selectedUser = users.find((u) => u._id === techId);
    const movementFoundWithSelectedUser = movements.find((m) =>
      m?.technicians.find((tech) => tech === selectedUser?._id)
    );
    if (selectedUser) {
      if (selectedUser?.truck !== null && selectedUser?.truck !== undefined) {
        if (
          (movementFoundWithSelectedUser?.date === date &&
            selectedUser?.truck?._id !== truck._id) ||
          selectedUser?.truck?._id !== truck._id
        ) {
          showConfirm(selectedUser, movementFoundWithSelectedUser);
        }
      }
    }
  };

  const intermediateCheck = (changedValues, values) => {
    if (changedValues?.technicians) {
      checkTruck(
        changedValues?.technicians[changedValues?.technicians.length - 1]
      );
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('week');
  };

  return [
    {
      label: 'movements.show.site',
      content: (
        <Link
          to={`${routes.COMMERCIAL}${subRoutes.COMMERCIAL.SITES}/show/${site &&
            site._id}`}
        >
          {site && site.reference}
        </Link>
      ),
      span: 1
    },
    {
      label: 'movements.show.expected_date',
      span: 1,
      content:
        quotation?.intervention_date_expected &&
        `Semaine ${moment(quotation?.intervention_date_expected).week()}`
    },
    {
      label: 'movements.show.status',
      content: (
        <Tag color={movementsStatus[status]}>{t(`enums.${status}`)}</Tag>
      ),
      span: 1
    },
    {
      label: 'movements.show.quotation',
      content: (
        <Link
          to={`${routes.COMMERCIAL}${
            subRoutes.COMMERCIAL.QUOTATIONS
          }/show/${quotation && quotation._id}`}
        >
          {quotation && quotation.number}
        </Link>
      ),
      span: 1
    },
    {
      label: 'movements.show.new_date',
      span: 1,
      content: !date ? (
        <DatePicker
          disabledDate={disabledDate}
          size="large"
          picker="week"
          style={{ margin: 'auto', width: 250 }}
          defaultValue={
            quotation?.intervention_date_programmed &&
            moment(quotation?.intervention_date_programmed)
          }
          onChange={saveDate}
        />
      ) : null
    },

    {
      label: 'movements.show.truck',
      span: 1,
      content: truck?.label
    },

    {
      label: 'movements.show.works_supervisor',
      content: works_supervisor ? (
        <>
          <Link
            to={`${routes.COMMERCIAL}${
              subRoutes.COMMERCIAL.CUSTOMERS
            }/show/${customer && customer._id}/contacts/show/${
              works_supervisor._id
            }`}
          >
            <p>{`${works_supervisor.first_name} ${works_supervisor.last_name}`}</p>
          </Link>
          <p>{works_supervisor.phone_number_mobile}</p>
          <p>{works_supervisor.email}</p>
        </>
      ) : (
        '-'
      ),
      span: 2
    },
    {
      label: 'movements.show.date',
      span: 1,
      content: (
        <>
          {date ? (
            <>
              <div style={{ display: 'flex' }}>
                <Link
                  style={
                    user?.type !== 'PLANNING' || user?.type !== 'MANAGER'
                      ? { cursor: 'default' }
                      : 'pointer'
                  }
                  onClick={(event) =>
                    user?.type !== 'PLANNING' || user?.type !== 'MANAGER'
                      ? event.preventDefault()
                      : null
                  }
                  to="/intervention/global_planning"
                >
                  <img style={{ width: 50 }} src={calendar} alt="planning" />
                </Link>
                <div style={{ margin: 'auto 20px' }}>
                  {moment(date).format('LL')}
                </div>
              </div>
            </>
          ) : (
            <Link
              to={{
                pathname: `/intervention/global_planning`,
                state: {
                  moveId: _id
                }
              }}
            >
              <img style={{ width: 50 }} src={calendar} alt="planning" />
            </Link>
          )}
        </>
      )
    },

    {
      label: 'movements.show.customer',
      content: customer ? (
        <Link
          to={`${routes.COMMERCIAL}${
            subRoutes.COMMERCIAL.CUSTOMERS
          }/show/${customer && customer._id}`}
        >
          {customer.first_name === undefined
            ? customer.name
            : `${customer.first_name} ${customer.last_name}`}
        </Link>
      ) : (
        '-'
      ),
      span: 1
    },
    {
      label: 'movements.show.technicians',
      span: 2,
      content: (
        <Form
          onFinish={onFinish}
          form={form}
          onValuesChange={(changedValues, values) =>
            intermediateCheck(changedValues, values)
          }
          style={{ display: 'flex' }}
        >
          <Form.Item
            name={['technicians']}
            initialValue={technicians}
            style={{ width: '100%', marginRight: '1%' }}
          >
            <Select
              disabled={
                user?.type === 'TECH' ||
                user?.type === 'SELLER' ||
                status === 'VALIDATED'
              }
              loading={isFieldsLoading}
              mode="tags"
              defaultValue={technicians}
              style={{ width: 400 }}
            >
              <OptGroup label={t(`movements.show.technicians_by_truck`)}>
                {truck?.users.map(({ _id, first_name, last_name }) => (
                  <Option key={_id} value={_id}>
                    {`${first_name} ${last_name}`}
                  </Option>
                ))}
              </OptGroup>
              <OptGroup label={t(`movements.show.all_technicians`)}>
                {filteredOptions.map(({ first_name, last_name, _id }) => (
                  <Option key={_id} value={_id}>
                    {`${first_name} ${last_name}`}
                  </Option>
                ))}
              </OptGroup>
            </Select>
          </Form.Item>
          <Form.Item>
            <Button
              disabled={
                user?.type === 'TECH' ||
                user?.type === 'SELLER' ||
                status === 'VALIDATED'
              }
              htmlType="submit"
            >
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Form>
      )
    },
    {
      label: 'movements.show.address',
      content: site && (
        <div>
          <p>{site.address_details_1}</p>
          <p>
            {site.address_details_2 === undefined ? '' : site.address_details_2}
          </p>
          <p>{`${site.postal_code} ${site.city}`}</p>
        </div>
      ),
      span: 1
    },
    {
      label: 'movements.show.site_informations',
      content: (
        <Form onFinish={editSite} style={{ display: 'flex' }}>
          <Form.Item
            name={['site_informations']}
            initialValue={site?.site_informations}
            style={{ width: 400, marginRight: '1%' }}
          >
            <TextArea style={{ width: '100%', height: 60 }} />
          </Form.Item>
          <Form.Item>
            <Button htmlType="submit" style={{ height: 60 }}>
              <CheckOutlined />
            </Button>
          </Form.Item>
        </Form>
      ),
      span: 3
    }
  ];
};

export default useListContent;
