import { useTranslation } from 'react-i18next';

const useColumnsForPrices = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('prestations.column.proPrice'),
      width: 500,
      children: [
        {
          title: t('prestations.column.forfait'),
          width: 150,
          key: 'price',
          render: (price) =>
            price && price.proPrice && `${price.proPrice.forfait.toFixed(2)} €`
        },
        {
          title: t('prestations.column.unitaire'),
          width: 150,
          key: 'price',
          render: (price) =>
            price &&
            price.proPrice &&
            `${price.proPrice.unitaire.toFixed(2)} € / m²`
        }
      ]
    },
    {
      title: t('prestations.column.partPrice'),
      width: 500,
      children: [
        {
          title: t('prestations.column.forfait'),
          width: 150,
          key: 'price',
          render: (price) =>
            price &&
            price.partPrice &&
            `${price.partPrice.forfait.toFixed(2)} €`
        },
        {
          title: t('prestations.column.unitaire'),
          width: 150,
          key: 'price',
          render: (price) =>
            price &&
            price.partPrice &&
            `${price.partPrice.unitaire.toFixed(2)} € / m²`
        }
      ]
    }
  ];
};

export default useColumnsForPrices;
