import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Button } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  DownloadOutlined,
  EyeOutlined,
  LoadingOutlined,
  WhatsAppOutlined,
  InboxOutlined,
  MessageOutlined
} from '@ant-design/icons';
import useAccountingContext from '../AccountingContext';
import columns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import ShowModalContact from './Components/ShowModalContact';
import UpdateComment from './Components/UpdateComment';
import ModalForSendMailInvoice from './Components/ModalForSendMailInvoice';

const iconSize = 18;

const ListUnpaid = () => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { message } = useErrorMessage();
  const { template } = useAccountingContext();
  const location = useLocation();
  const { pathname } = location;
  const [forceRefresh, setForceRefresh] = useState(false);
  const [isDownloading, setIsDownloading] = useState({});
  const [visibleContact, setVisibleContact] = useState(false);
  const [dataToModal, setDataToModal] = useState();
  const [visibleCommentUpdate, setVisibleCommentUpdate] = useState(false);
  const [visibleSendMail, setVisibleSendMail] = useState(false);
  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/invoice/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const actionColumn = [
    {
      title: 'Actions',
      key: 'action',
      fixed: 'right',
      width: 200,
      render: (record) => {
        return (
          <>
            <Link to={{ pathname: `${pathname}/show/${record.key}` }}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>
            {template && (
              <>
                <Divider type="vertical" />
                <Button
                  style={{ padding: 0 }}
                  type="link"
                  onClick={async () => {
                    if (!isDownloading[record.key]) {
                      setIsDownloading({
                        ...isDownloading,
                        [record.key]: true
                      });
                      await downloadFile(
                        record.key,
                        `${record.number}.pdf`,
                        () =>
                          setIsDownloading({
                            ...isDownloading,
                            [record.key]: false
                          })
                      );
                    }
                  }}
                >
                  {isDownloading[record.key] ? (
                    <LoadingOutlined />
                  ) : (
                    <DownloadOutlined />
                  )}
                </Button>
              </>
            )}
            {!record.payment_date && (
              <>
                <Divider type="vertical" />
                <WhatsAppOutlined
                  onClick={() => {
                    setDataToModal(record);
                    setVisibleContact(!visibleContact);
                  }}
                  style={{ fontSize: iconSize }}
                />
                <Divider type="vertical" />
                <InboxOutlined
                  onClick={() => {
                    setDataToModal(record);
                    setVisibleSendMail(!visibleSendMail);
                  }}
                  style={{ fontSize: iconSize }}
                />
              </>
            )}
            <Divider type="vertical" />
            <MessageOutlined
              onClick={() => {
                setDataToModal(record);
                setVisibleCommentUpdate(!visibleCommentUpdate);
              }}
              style={{ fontSize: iconSize }}
            />
          </>
        );
      }
    }
  ];

  return (
    <>
      <SimpleResourceLandingLayout
        dataToFetch="invoices"
        resourceName="unpaid"
        columns={[...columns(t), ...actionColumn]}
        populate="customer,contact,site,site.seller"
        customActionColumn
        withCreateButton={false}
        forceRefresh={forceRefresh}
        searchPlaceHolder="Entrer un Numéro ou Sujet"
      />
      <ShowModalContact
        setShowModal={setVisibleContact}
        showModal={visibleContact}
        rest={dataToModal}
      />
      <UpdateComment
        data={dataToModal}
        visible={visibleCommentUpdate}
        setVisible={setVisibleCommentUpdate}
        getResource={() => setForceRefresh(!forceRefresh)}
      />
      <ModalForSendMailInvoice
        data={dataToModal}
        visible={visibleSendMail}
        setVisible={setVisibleSendMail}
        getResource={() => setForceRefresh(!forceRefresh)}
      />
    </>
  );
};

export default ListUnpaid;
