import React, { useState } from 'react';
import { Button, Form, Input, Popconfirm, Switch, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined
} from '@ant-design/icons';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  selectType,
  checkType,
  record,
  index,
  children,
  setColorPicker,
  colorPicker,
  ...restProps
}) => {
  let inputNode;
  switch (inputType) {
    case 'input':
      inputNode = <Input />;
      break;
    case 'switch':
      inputNode = <Switch defaultChecked={record[dataIndex]} />;
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const FoamTable = ({ foamCols, editFoamCol, deleteFoamCol }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [switchShowLoading] = useState(false);

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: record.label,
      show: record.show,
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const saveSwitch = async (record) => {
    try {
      editFoamCol(record._id, record);
      setEditingKey('');
    } catch (e) {
      console.log('Error with Switch change: ', e);
    }
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      editFoamCol(record._id, row);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: t('configuration.foam.label'),
      dataIndex: 'label',
      width: '20%',
      editable: true
    },
    {
      title: t('configuration.foam.show'),
      dataIndex: 'show',
      width: '20%',
      editable: true,
      render: (checked, record) => (
        <Switch
          loading={switchShowLoading}
          checked={checked}
          onClick={async () => {
            record.show = !checked;
            await saveSwitch(record);
          }}
        />
      )
    },
    {
      title: t('configuration.foam.action'),
      dataIndex: 'operation',
      width: '20%',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div>
            <Button
              href="javascript:;"
              onClick={() => save(record)}
              style={{ borderRadius: 0 }}
            >
              {t('buttons.save')}
              <SaveOutlined />
            </Button>
            <Popconfirm
              title={t('settings.messages.confirm-deletion')}
              onConfirm={cancel}
            >
              <Button style={{ borderRadius: 0 }}>
                {t('buttons.cancel')}
                <CloseOutlined />
              </Button>
            </Popconfirm>
          </div>
        ) : (
          <>
            <Button
              onClick={() => edit(record)}
              disabled={editingKey !== ''}
              type="primary"
              style={{ borderRadius: 0, marginRight: '1%' }}
            >
              {`${t('buttons.edit')}`}
              <EditOutlined />
            </Button>

            <Popconfirm
              title={t('settings.messages.delete-line-confirm')}
              onConfirm={() => deleteFoamCol(record)}
              disabled={editingKey !== ''}
            >
              <Button
                disabled={editingKey !== ''}
                type="danger"
                style={{ borderRadius: 0 }}
              >
                {`${t('buttons.delete')}`}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </>
        );
      }
    }
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => {
        return {
          record,
          inputType: ['label'].includes(col.dataIndex) ? 'input' : 'switch',
          checkType: 'switch',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record)
        };
      }
    };
  });
  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={foamCols}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
        scroll={{ x: 600 }}
      />
    </Form>
  );
};

FoamTable.protoTypes = {
  foamCols: PropTypes.func.isRequired,
  editFoamCol: PropTypes.func.isRequired,
  deleteFoamCol: PropTypes.func.isRequired
};

EditableCell.protoTypes = {
  editing: PropTypes.func.isRequired,
  dataIndex: PropTypes.func.isRequired,
  title: PropTypes.func.isRequired,
  inputType: PropTypes.func.isRequired,
  selectType: PropTypes.func.isRequired,
  checkType: PropTypes.func.isRequired,
  record: PropTypes.func.isRequired,
  index: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  setColorPicker: PropTypes.func.isRequired,
  colorPicker: PropTypes.func.isRequired
};

export default FoamTable;
