import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Button, Space } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import columns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import ExportButton from './ExportButton';

const iconSize = 18;

const ListInvoices = () => {
  const { t } = useTranslation();
  const { dispatchAPI, user } = useAuthContext();
  const { message } = useErrorMessage();
  const location = useLocation();
  const { pathname } = location;
  const [isDownloading, setIsDownloading] = useState({});
  const [companies, setCompanies] = useState();

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/invoice/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  useEffect(() => {
    (async () => {
      await getCompanies();
    })();
  }, []);

  const actionColumn = [
    {
      key: 'action',
      fixed: 'right',
      render: ({ key, number, emission_date }) => {
        return (
          <>
            <Link to={{ pathname: `${pathname}/show/${key}` }}>
              <EyeOutlined style={{ fontSize: iconSize }} />
            </Link>

            <Divider type="vertical" />
            <Button
              style={{ padding: 0 }}
              type="link"
              onClick={async () => {
                if (!isDownloading[key]) {
                  setIsDownloading({ ...isDownloading, [key]: true });
                  await downloadFile(key, `${number}.pdf`, () =>
                    setIsDownloading({ ...isDownloading, [key]: false })
                  );
                }
              }}
            >
              {isDownloading[key] ? <LoadingOutlined /> : <DownloadOutlined />}
            </Button>

            {!emission_date && (
              <>
                <Divider type="vertical" />
                <Link to={{ pathname: `${pathname}/edit/${key}` }}>
                  <EditOutlined style={{ fontSize: iconSize }} />
                </Link>
              </>
            )}
          </>
        );
      }
    }
  ];

  return (
    <SimpleResourceLandingLayout
      extraButtons={
        <Space size="middle" style={{ marginRight: 8 }}>
          <ExportButton />
        </Space>
      }
      extraQuery="sort=payment_date"
      resourceName="invoices"
      columns={[...columns(t, companies), ...actionColumn]}
      populate="customer,contact,project,order"
      customActionColumn
      withUploadButton={false}
      withCreateButton={user.type !== 'SELLER'}
      searchPlaceHolder="Entrer un Numéro ou Sujet"
    />
  );
};

export default ListInvoices;
