import React, { useCallback, useEffect, useState } from 'react';
import { Button, Popconfirm, Spin, Upload, Row, message } from 'antd';
import { useHistory, useParams, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  DeleteOutlined,
  WarningOutlined,
  EditOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { routes, subRoutes } from '../../../utils/constants/routes';
import useAuthContext from '../../../contexts/AuthContext';
import PageHeaderCustom from '../../../components/PageHeader/PageHeader';
import ContentCustom from '../../../components/ContentCustom/ContentCustom';
import listContentBank from './listContentBank';
import DescriptionList from '../../../components/DescriptionList/DescriptionList';
import useErrorMessage from '../../../utils/ErrorMessage';

const ShowBank = () => {
  const history = useHistory();
  const { id } = useParams();
  const { custom } = useErrorMessage();
  const { dispatchAPI, token } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [bank, setBank] = useState({});
  const { t } = useTranslation();

  const getFilesToDisplay = async (fileData) => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/files/${fileData._id}`,
        responseType: 'arraybuffer'
      });
      const bufferArray = new Uint8Array(data);
      const blob = new Blob([bufferArray], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      setIsLoading(false);
      const a = document.createElement('a');
      a.href = url;
      a.download = fileData.metadata.originalName;
      a.click();
      return URL.createObjectURL(blob);
    } catch (e) {
      if (e.response) return custom(e.response.status);
    }
    return false;
  };
  const deleteBank = () => {
    try {
      dispatchAPI('DELETE', { url: `/bank-settings/${id}` });
      history.goBack();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getBank = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/bank-settings/${id}?populate=file`
      });
      setBank(data);
      setIsLoading(false);
    } catch (e) {
      if (e.response) message(e.response.status);
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    (async () => {
      await getBank();
    })();
  }, [getBank]);

  const deleteFileToBank = async (file) => {
    try {
      await dispatchAPI('DELETE', {
        url: `/files/${file._id}`
      });
      await dispatchAPI('PATCH', {
        url: `/bank-settings/${bank._id}`,
        body: { file: undefined }
      });
      getBank();
    } catch (e) {
      if (e.response)
        message.success(
          `${file.name ||
            file.metadata.originalName ||
            'Le document'} a été supprimé !`
        );
    }
  };

  const props = {
    name: 'file',
    action: `${process.env.REACT_APP_API_URL}/files`,
    headers: {
      authorization: `Bearer ${token}`
    },
    maxCount: 1,
    showUploadList: false,
    onRemove: async (file) => {
      setIsLoading(true);
      await deleteFileToBank(file.response.description);
      setIsLoading(false);
    },
    onChange: async (info) => {
      if (info.file.status !== 'uploading') {
        setIsLoading(true);
      }
      if (info.file.status === 'done') {
        await dispatchAPI('PATCH', {
          url: `/bank-settings/${bank._id}`,
          body: { file: info.file.response.description._id }
        });
        getBank();
        setIsLoading(false);
        message.success(`${info.file.name} Importer avec succès !`, 6);
      } else if (info.file.status === 'error') {
        custom(`${info.file.name} Échec de l'importation.`, 6);
      }
    }
  };

  return (
    <>
      <PageHeaderCustom
        title={t('bank.title')}
        withSubRoutes
        extra={
          <Row justify="space-around">
            {bank && !bank.file && (
              <Upload {...props}>
                <Button icon={<UploadOutlined />}>Importer un document</Button>
              </Upload>
            )}

            <Link
              style={{ marginLeft: 20 }}
              to={{
                pathname: `${routes.ACCOUNTING}${subRoutes.ACCOUNTING.BANK_SETTINGS}/edit/${id}`
              }}
            >
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>

            <Popconfirm
              title={t('datatable.column.action.delete.title')}
              okText={t('datatable.column.action.delete.ok')}
              okButtonProps={{ danger: true }}
              cancelText={t('datatable.column.action.delete.cancel')}
              onConfirm={deleteBank}
              icon={<WarningOutlined />}
            >
              <Button type="link" danger>
                {`${t('buttons.delete')} `}
                <DeleteOutlined />
              </Button>
            </Popconfirm>
          </Row>
        }
      />
      <ContentCustom>
        <Spin spinning={isLoading}>
          <DescriptionList
            data={listContentBank(bank, deleteFileToBank, getFilesToDisplay)}
            translate
          />
        </Spin>
      </ContentCustom>
    </>
  );
};

export default ShowBank;
