import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ShowCompany from '../Commercial/Companies/ShowCompany';
import ListCompanies from '../Commercial/Companies/ListCompanies';
import CreateUpdateCompany from '../Commercial/Companies/CreateUpdateCompany';

const SuppliersRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/:type/create`}
        render={() => <CreateUpdateCompany purpose="create" />}
      />
      <Route
        path={`${path}/:type/edit/:id`}
        render={() => <CreateUpdateCompany purpose="edit" />}
      />
      <Route path={`${path}/:type/show/:id`} component={ShowCompany} />
      <Route path={`${path}/:type`} component={ListCompanies} />
    </Switch>
  );
};

export default SuppliersRouter;
