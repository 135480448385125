import React, { useCallback, useEffect, useState } from 'react';
import { Select, DatePicker, Input } from 'antd';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const { Option } = Select;
const { TextArea } = Input;

const useFields = () => {
  const { message } = useErrorMessage();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [depots, setDepots] = useState([]);

  const basicFields = [
    {
      name: ['inventory_responsible'],
      input: (
        <Select loading={isFieldsLoading}>
          {users.map(({ last_name, first_name, _id }) => (
            <Option key={last_name} value={_id}>
              {`${first_name.toUpperCase()} ${last_name.toUpperCase()}`}
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    {
      name: ['deposit'],
      input: (
        <Select loading={isFieldsLoading}>
          {depots.map(({ label, _id }) => (
            <Option key={_id} label={label} value={_id}>
              {label.toUpperCase()}
            </Option>
          ))}
        </Select>
      ),
      rules: [{ required: true }]
    },
    { name: ['comments'], input: <TextArea row={1} /> },
    { name: ['date'], rules: [{ required: true }], input: <DatePicker /> }
  ];

  const getUsers = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/users' });
      setUsers(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getDepot = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/deposits' });
      setDepots(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(() => {
    setIsFieldsLoading(true);
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
      await getUsers();
      await getDepot();
    })();
  }, [getSelectOptions]);

  return { basicFields, isFieldsLoading };
};

export default useFields;
