import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Statistic, Card, Row, Col } from 'antd';
import {
  PauseOutlined,
  CheckOutlined,
  FileDoneOutlined
} from '@ant-design/icons';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';

/**
 *  Display  simple counter for quotation status
 *  @component
 *  @returns {any} react component
 */
function QuotationCardCounter() {
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [quotations, setQuotations] = useState([]);
  const [pendingCount, setPendingCount] = useState(0);
  const [validatedCount, setValidatedCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const getQuotations = async () => {
    let quotations;
    try {
      const result = await dispatchAPI('GET', { url: `/quotations` });
      setQuotations(result.data);
      quotations = result.data;
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    return quotations;
  };

  const getPendingQuotationCount = (quotations) => {
    return quotations
      .filter(
        (quotation) => !['DUPLICATED', 'VALIDATE'].includes(quotation.status)
      )
      .reduce((prevQuotation, currentQuotation) => {
        return prevQuotation + 1;
      }, 0);
  };
  const getValidatedQuotationCount = (quotations) => {
    return quotations
      .filter((quotation) => quotation.status === 'VALIDATE')
      .reduce((prevQuotation, currentQuotation) => {
        return prevQuotation + 1;
      }, 0);
  };
  useEffect(() => {
    setIsLoading(true);
    getQuotations().then((quotations) => {
      const pendingCount = getPendingQuotationCount(quotations);
      setPendingCount(pendingCount);
      const validatedCount = getValidatedQuotationCount(quotations);
      setValidatedCount(validatedCount);
      setIsLoading(false);
    });
  }, []);

  return (
    <div>
      <Row gutter={16}>
        <Col>
          <Card>
            <Statistic
              loading={isLoading}
              title="Validé"
              value={validatedCount}
              precision={2}
              prefix={<FileDoneOutlined />}
              valueStyle={{
                color: 'var(--addColor)',
                textAlign: 'center',
                fontWeight: 'bold'
              }}
            />
          </Card>
        </Col>
        <Col>
          <Card>
            <Statistic
              title="En Attente"
              loading={isLoading}
              value={pendingCount}
              precision={2}
              valueStyle={{
                color: 'red',
                textAlign: 'center',
                fontWeight: 'bold'
              }}
              prefix={<PauseOutlined />}
            />
          </Card>
        </Col>
      </Row>
    </div>
  );
}

QuotationCardCounter.propTypes = {};

export default QuotationCardCounter;
