import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import {
  EditOutlined,
  EyeOutlined,
  CheckOutlined,
  LoadingOutlined,
  DownloadOutlined,
  ExceptionOutlined
} from '@ant-design/icons';
import { Button, Divider, Col, Switch, Tooltip } from 'antd';
import moment from 'moment';
import useAccountingContext from '../AccountingContext';
import columns from './columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';
import useAuthContext from '../../../contexts/AuthContext';
import useErrorMessage from '../../../utils/ErrorMessage';
import OptionsRow from './OptionsRow';
import ValidateQuotationModal from './ValidateQuotationModal';

const iconSize = 18;

const ListQuotations = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { message } = useErrorMessage();
  const { range, setRange } = useAccountingContext();
  const { dispatchAPI } = useAuthContext();
  const { pathname } = location;
  const [extraQuery, setExtraQuery] = useState(null);
  const [forceRefresh, setForceRefresh] = useState(false);
  const [_, setIsLoading] = useState(false);
  const [isDownloading, setIsDownloading] = useState({});
  const [template, setTemplate] = useState();
  const [companies, setCompanies] = useState();
  const [showModal, setShowModal] = useState(false);
  const [quotationId, setQuotationId] = useState(null);
  const [quotation, setQuotation] = useState();
  const [isArchived, setIsArchived] = useState(false);

  const openModal = (id, quot) => {
    setQuotationId(id);
    setQuotation(quot);
    setShowModal(true);
  };

  const updateQuotation = async (id, body) => {
    try {
      await dispatchAPI('PATCH', { url: `/quotations/${id}`, body });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTemplateSetting = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/settings?label=template_quotations'
      });
      setTemplate(data[0].value);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getCompanies = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/companies' });
      setCompanies(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const downloadFile = async (id, name) => {
    try {
      const response = await dispatchAPI('GET', {
        url: `/files/generate/quotation/${id}`,
        responseType: 'blob'
      });
      const blob = new Blob([response.data], {
        contentType: 'application/pdf'
      });

      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsDownloading({ ...isDownloading, [id]: false });
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getTemplateSetting();
    await getCompanies();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const validateQuotation = async (
    currentQuotation,
    key,
    selectedDate,
    status
  ) => {
    setForceRefresh(false);
    setShowModal(false);
    if (
      currentQuotation.global_discount &&
      currentQuotation.prestations.filter(
        ({ label }) => label === 'Remise globale'
      ).length === 0
    ) {
      currentQuotation.prestations.push({
        label: 'Remise globale',
        VAT: 0,
        unit_price:
          currentQuotation.global_discount.type === 'CURRENCY'
            ? -currentQuotation.global_discount.value
            : -(
                (currentQuotation.subTotal *
                  currentQuotation.global_discount.value) /
                100
              ),
        subtotal:
          currentQuotation.global_discount.type === 'CURRENCY'
            ? -currentQuotation.global_discount.value
            : -(
                (currentQuotation.subTotal *
                  currentQuotation.global_discount.value) /
                100
              ),
        comment: currentQuotation.global_discount.comment
      });
    }
    if (status === 'VALIDATE') {
      const quotationBody = {
        prestations: currentQuotation.prestations,
        intervention_date_expected: selectedDate,
        intervention_date_programmed: null,
        status: 'VALIDATE'
      };
      await updateQuotation(key, quotationBody);
    } else if (status === 'QUOTATION_PENDING') {
      const quotationBody = {
        prestations: currentQuotation.prestations,
        intervention_date_expected: selectedDate,
        intervention_date_programmed: null,
        status: 'QUOTATION_PENDING'
      };
      await updateQuotation(key, quotationBody);
    }
    setForceRefresh(!forceRefresh);
  };

  const actionColumn = [
    {
      key: 'action',
      fixed: 'right',
      render: ({ key, order, number, ...rest }) => {
        return (
          <span>
            <Tooltip placement="top" title="Voir le devis">
              <Link
                to={{
                  pathname: `${pathname}/show/${key}`
                }}
              >
                <EyeOutlined style={{ fontSize: iconSize }} />
              </Link>
            </Tooltip>

            {template && (
              <>
                <Divider type="vertical" />
                <Tooltip placement="top" title="Télécharger le devis">
                  <Button
                    style={{ padding: 0 }}
                    type="link"
                    onClick={async () => {
                      if (!isDownloading[key]) {
                        setIsDownloading({ ...isDownloading, [key]: true });
                        await downloadFile(key, `${number}.pdf`);
                      }
                    }}
                  >
                    {isDownloading[key] ? (
                      <LoadingOutlined />
                    ) : (
                      <DownloadOutlined />
                    )}
                  </Button>
                </Tooltip>

                {rest?.status === 'VALIDATE' &&
                  (!rest?.signed_quotation_file ||
                    rest?.signed_quotation_file === null) && (
                    <>
                      <Divider type="vertical" />
                      <Tooltip placement="top" title="Importer le devis signé">
                        <ExceptionOutlined
                          style={{ fontSize: iconSize }}
                          type="validate"
                          onClick={() => openModal(key, rest)}
                        />
                      </Tooltip>
                    </>
                  )}
              </>
            )}
            {rest?.status !== 'VALIDATE' &&
              rest?.status !== 'QUOTATION_PENDING' &&
              rest?.status !== 'DUPLICATED' &&
              rest?.status !== 'ARCHIVED' && (
                <>
                  {rest.email_sent === false && (
                    <>
                      <Divider type="vertical" />
                      <Tooltip placement="top" title="Modifier le devis">
                        <Link
                          to={{
                            pathname: `${pathname}/edit/${key}`
                          }}
                        >
                          <EditOutlined style={{ fontSize: iconSize }} />
                        </Link>
                      </Tooltip>
                    </>
                  )}

                  <Divider type="vertical" />
                  {rest?.status !== 'VALIDATE' && (
                    <Tooltip placement="top" title="Valider le devis">
                      <CheckOutlined
                        style={{ fontSize: iconSize }}
                        type="validate"
                        onClick={() => openModal(key, rest)}
                      />
                    </Tooltip>
                  )}
                </>
              )}
          </span>
        );
      }
    }
  ];

  const headers = [
    {
      label: 'Quotation number',
      key: 'number'
    },
    {
      label: 'emission date',
      key: 'emission_date'
    },
    {
      label: 'deadline date',
      key: 'deadline_date'
    },
    {
      label: 'Subject',
      key: 'subject'
    },
    {
      label: 'Comments',
      key: 'comment'
    },
    {
      label: 'Subtotal',
      key: 'subTotal'
    },
    {
      label: 'Total VAT',
      key: 'total_VAT'
    },
    {
      label: 'Total discount',
      key: 'total_discount'
    },
    {
      label: 'Final total',
      key: 'total'
    },
    {
      label: 'Customers',
      key: 'customer.name'
    },
    {
      label: 'Contact first name',
      key: 'contact.first_name'
    },
    {
      label: 'Contact last name',
      key: 'contact.last_name'
    },
    {
      label: 'Contact email',
      key: 'contact.email'
    }
  ];

  useEffect(() => {
    if (range && range.length) {
      setExtraQuery(
        `archived=${isArchived}&emission_date>=${moment(range[0])
          .startOf('day')
          .toISOString()}&emission_date<=${moment(range[1])
          .endOf('day')
          .toISOString()}&sort=-created_at`
      );
    } else setExtraQuery(`archived=${isArchived}&sort=-created_at`);
    setForceRefresh(!forceRefresh);
  }, [range, isArchived]);

  const archivedButton = (
    <Col style={{ marginRight: 10 }}>
      <Switch
        checkedChildren="Archives"
        unCheckedChildren="Archives"
        checked={isArchived}
        onChange={() => setIsArchived(!isArchived)}
      />
    </Col>
  );
  return (
    <>
      <SimpleResourceLandingLayout
        resourceName="quotations"
        columns={[...columns(t, companies), ...actionColumn]}
        populate="customer,contact,order,site"
        customActionColumn
        headers={headers}
        extraButtons={archivedButton}
        extraQuery={extraQuery}
        forceRefresh={forceRefresh}
        withUploadButton={false}
      >
        <Col>
          <OptionsRow
            range={range}
            setRange={setRange}
            style={{ float: 'right' }}
          />
        </Col>
      </SimpleResourceLandingLayout>
      <ValidateQuotationModal
        setShowModal={setShowModal}
        showModal={showModal}
        getResource={() => setForceRefresh(!forceRefresh)}
        resource={quotation}
        validateQuotation={validateQuotation}
        quotationId={quotationId}
        downloadFile={downloadFile}
      />
    </>
  );
};

export default ListQuotations;
