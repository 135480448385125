import React from 'react';
import useColumns from './Columns';
import SimpleResourceLandingLayout from '../../../components/layouts/SimpleResourceLandingLayout';

const VatAccounts = () => {
  const columns = useColumns();

  const headers = [
    {
      label: 'Code',
      key: 'code_number'
    },
    {
      label: 'Libellé',
      key: 'description'
    },
    {
      label: 'lettrable',
      key: 'lettrable'
    }
  ];

  return (
    <SimpleResourceLandingLayout
      columns={columns}
      resourceName="vats"
      withSubRoutes
      headers={headers}
      resourceModelName="Vat"
      withUploadButton={false}
    />
  );
};

export default VatAccounts;
