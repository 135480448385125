import React, { createContext, useContext, useState } from 'react';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const MovementStockContext = createContext({});

export const MovementStockContextProvider = ({ children }) => {
  const { message } = useErrorMessage();
  const { dispatchAPI, user } = useAuthContext();
  const [loading, setLoading] = useState(false);
  const [visibleTypeOfMovement, setVisibleTypeOfMovement] = useState(false);
  const [allDeposit, setAllDeposit] = useState([]);
  const [selectedDeposit, setSelectedDeposit] = useState();
  const [inventoryDeposit, setInventoryDeposit] = useState();
  const [isFromInventory, setIsFromInventory] = useState(false);
  const [comments, setComments] = useState();
  const [organizations, setOrganizations] = useState([]);
  const [vats, setVats] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [listOfTanks, setListOfTanks] = useState([]);

  const getDepositByUserTruck = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/trucks/${user.truck}?populate=deposit`
      });
      setSelectedDeposit({
        ...data.deposit,
        value: data.deposit._id,
        children: data.deposit.label
      });
      setInventoryDeposit({
        ...data.deposit,
        value: data.deposit._id,
        children: data.deposit.label
      });
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getAllDeposit = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/deposits' });
      setAllDeposit(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getOrganizations = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/organizations'
      });
      setOrganizations(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getVats = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/vats'
      });
      setVats(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getDeposits = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/deposits'
      });
      setDeposits(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getTanks = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: '/tanks'
      });
      setListOfTanks(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  return (
    <MovementStockContext.Provider
      value={{
        loading,
        setLoading,
        user,
        visibleTypeOfMovement,
        setVisibleTypeOfMovement,
        getAllDeposit,
        allDeposit,
        selectedDeposit,
        setSelectedDeposit,
        inventoryDeposit,
        setInventoryDeposit,
        getDepositByUserTruck,
        isFromInventory,
        setIsFromInventory,
        comments,
        setComments,
        getOrganizations,
        organizations,
        getVats,
        vats,
        getDeposits,
        deposits,
        getTanks,
        listOfTanks
      }}
    >
      {children}
    </MovementStockContext.Provider>
  );
};

export default () => useContext(MovementStockContext);
