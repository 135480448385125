import { Tag } from 'antd';
import React from 'react';
import moment from 'moment';

const useColumns = () => {
  const isInventory = [
    { text: 'Oui', value: true },
    { text: 'Non', value: false }
  ];
  const setCategory = {
    exit: { color: 'red', value: 'SORTIE' },
    enter: { color: 'var(--addColor)', value: 'ENTRÉE' },
    'inventory-transfer': { color: 'orange', value: 'TRANSFERT' },
    regularization: { color: 'orange', value: 'REGULARISATION' }
  };
  return [
    {
      title: 'Utilisateur',
      key: 'user',
      dataIndex: 'user',
      render: (user) => user && `${user.first_name} ${user.last_name}`,
      sorter: true,
      width: '10%'
    },
    {
      title: 'Catégorie',
      key: 'category',
      dataIndex: 'category',
      render: (cat) => (
        <Tag color={setCategory[cat].color || 'black'}>
          {setCategory[cat].value}
        </Tag>
      ),
      sorter: true,
      width: '10%'
    },
    {
      title: 'Date',
      key: 'created_at',
      dataIndex: 'created_at',
      render: (date) => moment(date).format('DD/MM/YYYY'),
      sorter: true,
      width: '10%'
    },
    {
      title: 'Suite à inventaire',
      key: 'inventory',
      dataIndex: 'inventory',
      render: (text) =>
        text ? <span style={{ color: 'var(--addColor)' }}>Oui</span> : null,
      sorter: true,
      filters: isInventory,
      width: '5%'
    },
    {
      title: 'Commentaires',
      key: 'comments',
      dataIndex: 'comments',
      render: (text) =>
        text && text !== 'null' && text !== null ? (
          <p style={{ color: 'red', whiteSpace: 'normal' }}>{text}</p>
        ) : null,
      width: '20%'
    }
  ];
};

export default useColumns;
