import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Input, InputNumber, Select, Switch, Form, Tooltip } from 'antd';
import useErrorMessage from '../../../utils/ErrorMessage';
import useAuthContext from '../../../contexts/AuthContext';

const { Option } = Select;

const useFields = () => {
  const { message } = useErrorMessage();
  const { t } = useTranslation();
  const { id } = useParams();
  const { dispatchAPI } = useAuthContext();
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const [enums, setEnums] = useState([]);
  const [foams, setFoams] = useState([]);
  const [isolation, setIsolation] = useState(false);
  const [isObsolete, setIsObsolete] = useState(false);

  const config = {
    onCreateResource: {
      setBody: (data) => {
        if (data.type === 'ISOLATION') {
          const type = data.type.substr(0, 3).toUpperCase();
          const support = data.support.charAt(0).toUpperCase();
          const thermal_resistance = data.thermal_resistance
            .toString()
            .replace('.', '');
          const foam = data.foam.includes('+')
            ? `${data.foam.substr(0, 3).toUpperCase()}+`
            : data.foam.substr(0, 4).toUpperCase();
          const thickness = data.thickness.toString().replace('.', '');
          const reference = `${type}-${support}-${thickness}-${thermal_resistance}-${foam}`;
          const label = `${data.type} ${data.support} - Epaisseur : ${data.thickness} cm - R = ${data.thermal_resistance}`;
          const partPriceForfait = data.price.proPrice.forfait + 200;
          const partPriceUnit = data.price.proPrice.unitaire + 2;

          return {
            ...data,
            reference,
            label,
            price: {
              proPrice: {
                forfait: data.price.proPrice.forfait,
                unitaire: data.price.proPrice.unitaire
              },
              partPrice: { forfait: partPriceForfait, unitaire: partPriceUnit }
            }
          };
        }
        if (data.type === 'AUTRES PRESTATIONS') {
          const type = data.type.substr(0, 5).toUpperCase();
          const support = data.support.charAt(0).toUpperCase();
          const reference = `${type}-${support}`;
          return {
            ...data,
            reference
          };
        }
        return data;
      }
    },
    onGetResource: {
      setFields: (data) => {
        if (data.type === 'ISOLATION') {
          setIsolation(true);
        }
        return {
          ...data
          /* price: {
            proPrice: {
              forfait: data.price.proPrice.forfait,
              unitaire: data.price.proPrice.unitaire
            }
          } */
        };
      }
    },
    onUpdateResource: {
      setBody: (data) => {
        if (data.type === 'ISOLATION') {
          const type = data.type.substr(0, 3).toUpperCase();
          const support = data.support.charAt(0).toUpperCase();
          const thermal_resistance = data.thermal_resistance
            .toString()
            .replace('.', '');
          const foam = data.foam.includes('+')
            ? `${data.foam.substr(0, 3).toUpperCase()}+`
            : data.foam.substr(0, 4).toUpperCase();
          const thickness = data.thickness.toString().replace('.', '');
          const reference = `${type}-${support}-${thickness}-${thermal_resistance}-${foam}`;
          const label = `${data.type} ${data.support} - Epaisseur : ${data.thickness} cm - R = ${data.thermal_resistance}`;
          const partPriceForfait = data.price.proPrice.forfait + 200;
          const partPriceUnit = data.price.proPrice.unitaire + 2;

          return {
            ...data,
            reference,
            label,
            price: {
              proPrice: {
                forfait: data.price.proPrice.forfait,
                unitaire: data.price.proPrice.unitaire
              },
              partPrice: { forfait: partPriceForfait, unitaire: partPriceUnit }
            }
          };
        }
        if (data.type === 'AUTRES PRESTATIONS') {
          const type = data.type.substr(0, 5).toUpperCase();
          const support = data.support.charAt(0).toUpperCase();
          const reference = `${type}-${support}`;
          return {
            ...data,
            reference
          };
        }
        return data;
      }
    }
  };

  const getSpecificsFields = (values) => {
    if (values === 'ISOLATION') {
      setIsolation(true);
    } else setIsolation(false);
  };

  const basicFields = [
    {
      name: ['type'],
      rules: [{ required: true }],
      input: (
        <Select
          loading={isFieldsLoading}
          onChange={(values) => getSpecificsFields(values)}
        >
          {(enums.types || []).map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      )
    },
    {
      name: ['support'],
      rules: [{ required: true }],
      input: (
        <Select loading={isFieldsLoading}>
          {(enums.supports || []).map((support) => (
            <Option key={support} value={support}>
              {support}
            </Option>
          ))}
        </Select>
      )
    },
    ...(isolation
      ? [
          {
            name: ['thickness'],
            rules: [{ required: true }],
            input: (
              <InputNumber
                min={0}
                max={25}
                step={0.5}
                addonAfter="mm"
                style={{ width: '100%' }}
              />
            )
          },
          {
            name: ['thermal_resistance'],
            rules: [{ required: true }],
            input: (
              <InputNumber
                min={1}
                max={10}
                step={0.05}
                style={{ width: '100%' }}
              />
            )
          },
          {
            name: ['foam'],
            rules: [{ required: true }],
            input: (
              <Select loading={isFieldsLoading}>
                {(foams || []).map(({ label, _id }) => (
                  <Option key={_id} value={label}>
                    {label}
                  </Option>
                ))}
              </Select>
            )
          },
          {
            name: ['label'],
            noLabel: true,
            hidden: true,
            input: <></>
          }
        ]
      : [
          {
            name: ['label'],
            rules: [{ required: true }]
          }
        ]),
    {
      name: ['reference'],
      noLabel: true,
      hidden: true,
      input: <></>
    },
    {
      name: ['isObsolete'],
      valuePropName: 'checked',
      input: (
        <Switch
          checkedChildren="Oui"
          unCheckedChildren="Non"
          checked={isObsolete}
          onClick={() => setIsObsolete(!isObsolete)}
        />
      )
    }
  ];

  const advancedFields = [
    {
      name: ['price'],
      rules: [{ required: true }],
      input: (
        <Input.Group
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between'
          }}
        >
          <Tooltip
            title={t('prestations.form.PartPriceForfait')}
            placement="bottomLeft"
          >
            <Form.Item
              name={['price', 'proPrice', 'forfait']}
              label={t('prestations.form.forfait')}
              tooltip={t('prestations.form.up_to_60m2')}
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginRight: '10px'
              }}
            >
              <InputNumber style={{ width: '100%' }} step={0.05} min={0} />
            </Form.Item>
          </Tooltip>
          <Tooltip
            title={t('prestations.form.PartPriceUnitaire')}
            placement="right"
          >
            <Form.Item
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
              name={['price', 'proPrice', 'unitaire']}
              label={t('prestations.form.unitaire')}
              tooltip={t('prestations.form.from_61m2')}
            >
              <InputNumber style={{ width: '100%' }} step={0.05} min={0} />
            </Form.Item>
          </Tooltip>
        </Input.Group>
      )
    }
  ];

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prestations/enums' });
      setEnums(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };
  const getFoams = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/foams' });
      setFoams(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const getSelectOptions = useCallback(async () => {
    setIsFieldsLoading(true);
    await getEnums();
    await getFoams();
    setIsFieldsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await getSelectOptions();
    })();
  }, [getSelectOptions]);

  const fetchData = useCallback(async () => {
    setIsFieldsLoading(true);
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/prestations/${id}`
      });
      setIsObsolete(data.isObsolete);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
    setIsFieldsLoading(false);
  }, [id]);

  useEffect(() => {
    (async () => {
      if (id) await fetchData();
    })();
  }, [fetchData]);

  return { basicFields, advancedFields, isFieldsLoading, config };
};

export default useFields;
