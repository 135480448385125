import React from 'react';
import { Route, Redirect, Switch, useRouteMatch } from 'react-router-dom';
import { subRoutes } from '../../utils/constants/routes';
import DocumentTemplates from './Templates/DocumentTemplates';
import { TemplatesContextProvider } from './Templates/TemplatesContext';
import CreateTemplate from './Templates/CreateTemplate';
import SignDocument from './Templates/SignDocument';
import DocumentsManager from './GED/DocumentsManager';
import CreateFile from './GED/CreateFile';

const DocumentsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <TemplatesContextProvider>
      <Switch>
        <Route
          exact
          path={`${path}${subRoutes.CONFIGURATION.TEMPLATES}`}
          component={DocumentTemplates}
        />
        <Route
          exact
          path={`${path}${subRoutes.CONFIGURATION.GED}`}
          component={DocumentsManager}
        />
        <Route
          exact
          path={`${path}${subRoutes.CONFIGURATION.GED}/create`}
          component={CreateFile}
        />
        <Route
          exact
          path={`${path}${subRoutes.CONFIGURATION.TEMPLATES}/create`}
          component={CreateTemplate}
        />
        <Route
          exact
          path={`${path}${subRoutes.CONFIGURATION.TEMPLATES}/sign`}
          component={SignDocument}
        />
        <Redirect from="*" to={`${path}${subRoutes.CONFIGURATION.TEMPLATES}`} />
      </Switch>
    </TemplatesContextProvider>
  );
};

export default DocumentsRouter;
