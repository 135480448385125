/* eslint-disable react/jsx-one-expression-per-line */
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useParams } from 'react-router-dom';
import {
  Col,
  message,
  Typography,
  Row,
  Select,
  Skeleton,
  Steps,
  Table,
  Spin,
  Card
} from 'antd';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import numeral from 'numeral';
import { CloseOutlined, DatabaseOutlined } from '@ant-design/icons/lib';
import useAuthContext from '../../../../contexts/AuthContext';
import ContentCustom from '../../../../components/ContentCustom/ContentCustom';
import PageHeaderCustom from '../../../../components/PageHeader/PageHeader';
import useErrorMessage from '../../../../utils/ErrorMessage';
import RemainingToPay from '../Components/RemainingToPay';
import useAccountingContext from '../../AccountingContext';
import Organization from '../Components/Organization';
import Customer from '../Components/Customer';
import Contact from '../Components/Contact';
import ExtraShowComponent from './ExtraShowComponent';
import ShowModalForSendMail from './ShowModalForSendMail';

const { Title } = Typography;
const { Column } = Table;
const { Step } = Steps;
const { Option } = Select;

const borderBottom = '1px solid var(--borderColor)';

const ShowQuotation = ({ resource }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const { message: errorMessage } = useErrorMessage();
  const { id } = useParams();
  const location = useLocation();
  const { template, updateDocument, deleteDocument } = useAccountingContext();
  const [isLoading, setLoading] = useState(false);
  const [isLoadingMedia, setLoadingMedia] = useState(false);
  const [displayedResource, setResource] = useState();
  const params = new URLSearchParams(location.search);
  const type = params.get('type');
  const [specificsMentions, setSpecificsMentions] = useState([]);
  const [selectedMentions, setSelectedMentions] = useState([]);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [visibleSendMail, setVisibleSendMail] = useState(false);
  const [showCreditInvoice, setShowCreditInvoice] = useState(false);
  const [showDeposit, setShowDeposit] = useState(false);

  const getResource = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/${resource}/${id}?populate=customer,contact,order.quotation,site,organization,bank_account,vat,invoices`
      });
      setResource(data);
      if (resource === 'quotations') {
        setSelectedMentions(data.mentions);
      } else {
        setSelectedMentions(data.order.quotation.mentions);
      }
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const getSpecificsMentions = async () => {
    try {
      const { data } = await dispatchAPI('GET', {
        url: `/quotations/enums`
      });
      setSpecificsMentions(data.legal_mentions);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const deleteMovement = async (movementId) => {
    try {
      await dispatchAPI('DELETE', { url: `/movements/${movementId}` });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const fetchResource = useCallback(async () => {
    setLoading(true);
    if (id) {
      await getResource();
    }
    await getSpecificsMentions();
    setLoading(false);
  }, [id]);

  const unvalidateQuotation = async () => {
    if (displayedResource.prestations) {
      displayedResource.prestations = displayedResource?.prestations.filter(
        (q) => q.name !== 'Remise globale'
      );
    }

    await updateDocument(displayedResource?._id, {
      prestations: displayedResource?.prestations,
      order: null,
      status: 'REQUEST'
    });
    await deleteMovement(displayedResource?.order.movement);
    fetchResource();
  };

  const getCurrentStep = (resources) => {
    let step = 1;
    if (['CANCELED', 'REFUSED', 'DUPLICATED'].includes(resources?.status)) {
      step = 0;
    }
    if (
      ['TO_NUMBER', 'REQUEST', 'QUOATION_PENDING', 'TO_SEND'].includes(
        resources?.status
      )
    ) {
      step = 1;
    }
    if (['VALIDATE', 'READY'].includes(resources?.status)) {
      step = 2;
    }
    if (['ARCHIVED'].includes(resources?.status)) {
      step = 4;
    }
    return step;
  };

  useEffect(() => {
    if (id) {
      fetchResource();
    }
  }, [fetchResource, id]);

  const saveMentions = async (mentions) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/quotations/${id}`,
        body: { mentions }
      });
      setSelectedMentions(mentions);
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const validateQuotation = async (_, __, selectedDate, status) => {
    if (
      displayedResource.global_discount &&
      !displayedResource.prestations.filter(
        ({ label }) => label === 'Remise globale'
      ).length > 1
    ) {
      displayedResource.prestations.push({
        label: 'Remise globale',
        VAT: 0,
        unit_price:
          displayedResource.global_discount.type === 'CURRENCY'
            ? -displayedResource.global_discount.value
            : -(
                (displayedResource.subTotal *
                  displayedResource.global_discount.value) /
                100
              ),
        subtotal:
          displayedResource.global_discount.type === 'CURRENCY'
            ? -displayedResource.global_discount.value
            : -(
                (displayedResource.subTotal *
                  displayedResource.global_discount.value) /
                100
              ),
        comment: displayedResource.global_discount.comment
      });
    }
    if (status === 'VALIDATE') {
      const quotationBody = {
        prestations: displayedResource.prestations,
        intervention_date_expected: selectedDate,
        intervention_date_programmed: null,
        status: 'VALIDATE'
      };
      await updateDocument(displayedResource._id, quotationBody);
    } else if (status === 'QUOTATION_PENDING') {
      const quotationBody = {
        prestations: displayedResource.prestations,
        intervention_date_expected: selectedDate,
        intervention_date_programmed: null,
        status: 'QUOTATION_PENDING'
      };
      await updateDocument(displayedResource._id, quotationBody);
    }
    message.success(
      `Le devis a été validé et l'intervention ajoutée au planning.`
    );
    getResource();
    // history.push({ pathname: `../../quotations` });
  };

  const changeQuotationStatus = () => {
    updateDocument(displayedResource._id, { status: 'VALIDATE' });
    fetchResource();
  };

  const downloadQuotation = async (name) => {
    setLoadingMedia(true);
    try {
      let response;
      if (displayedResource?.signed_quotation_file) {
        response = await dispatchAPI('GET', {
          url: `/files/${displayedResource?.signed_quotation_file}`,
          responseType: 'arraybuffer'
        });
      } else {
        response = await dispatchAPI('GET', {
          url: `/generate/quotations/${id}`,
          responseType: 'arraybuffer'
        });
      }
      const bufferArray = new Uint8Array(response.data);
      const blob = new Blob([bufferArray], {
        type: 'application/pdf'
      });
      const url = window.URL.createObjectURL(blob);
      setLoadingMedia(false);
      const a = document.createElement('a');
      a.href = url;
      a.download = name;
      a.click();
    } catch (e) {
      setLoadingMedia(false);
      if (e.response) errorMessage(e.response.status);
    }
  };

  const DisplayQuotationMedia = async (url, name) => {
    setLoadingMedia(true);
    try {
      const response = await dispatchAPI('GET', {
        url,
        responseType: 'arraybuffer'
      });
      const bufferArray = new Uint8Array(response.data);
      const blob = new Blob([bufferArray], {
        type: 'application/pdf'
      });
      setLoadingMedia(false);
      const urlModal = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = urlModal;
      a.download = name || 'Document';
      a.click();
    } catch (e) {
      setLoadingMedia(false);
      if (e.response) errorMessage(e.response.status);
    }
  };
  return (
    <>
      {displayedResource && (
        <>
          <ShowModalForSendMail
            data={displayedResource}
            visible={visibleSendMail}
            setVisible={setVisibleSendMail}
            getResource={getResource}
          />
          <PageHeaderCustom
            title={t(`${resource}.show.number`, {
              number: displayedResource?.number
            })}
            extra={
              <>
                <ExtraShowComponent
                  type={type}
                  id={id}
                  isLoading={isLoadingMedia}
                  setLoading={setLoadingMedia}
                  displayedResource={displayedResource}
                  resource={resource}
                  deleteDocument={deleteDocument}
                  downloadFile={downloadQuotation}
                  template={template}
                  setVisibleSendMail={setVisibleSendMail}
                  setShowModalUpload={setShowModalUpload}
                  validateQuotation={validateQuotation}
                  changeQuotationStatus={changeQuotationStatus}
                  showModalUpload={showModalUpload}
                  DisplayQuotationMedia={DisplayQuotationMedia}
                  unvalidateQuotation={unvalidateQuotation}
                  getResource={getResource}
                  setShowDeposit={setShowDeposit}
                  showDeposit={showDeposit}
                  showCreditInvoice={showCreditInvoice}
                  setShowCreditInvoice={setShowCreditInvoice}
                />
              </>
            }
          />
          <Spin spinning={isLoadingMedia}>
            <ContentCustom background="var(--borderColor)">
              {resource === 'quotations' && (
                <Row justify="center">
                  <Col span={16}>
                    <Steps
                      style={{ backgroundColor: 'white' }}
                      type="navigation"
                      className="site-navigation-steps"
                      current={getCurrentStep(displayedResource)}
                    >
                      {['CANCELED', 'REFUSED', 'DUPLICATED'].includes(
                        displayedResource?.status
                      ) && (
                        <Step
                          icon={<CloseOutlined />}
                          title="Annulé"
                          subTitle={moment(
                            displayedResource?.created_at
                          ).format('DD/MM/YYYY')}
                        />
                      )}
                      <Step
                        title="Créé le"
                        subTitle={moment(displayedResource?.created_at).format(
                          'DD/MM/YYYY'
                        )}
                      />

                      <Step
                        title={
                          displayedResource?.status === 'VALIDATE'
                            ? 'Validé'
                            : 'En attente de validation'
                        }
                      />
                      <Step
                        title={
                          displayedResource.invoices &&
                          displayedResource?.invoices.find(
                            (ele) => ele.type_invoice === 'GLOBAL'
                          )
                            ? 'Facturé le'
                            : 'En attente de facturation'
                        }
                        subTitle={
                          displayedResource?.invoices.find(
                            (ele) => ele.type_invoice === 'GLOBAL'
                          )
                            ? moment(
                                displayedResource?.invoices.filter(
                                  (ele) => ele.type_invoice === 'GLOBAL'
                                )[0].created_at
                              ).format('DD/MM/YYYY')
                            : ''
                        }
                      />
                      {['ARCHIVED'].includes(displayedResource?.status) && (
                        <Step
                          icon={<DatabaseOutlined />}
                          title="Archivé le"
                          subTitle={moment(
                            displayedResource?.updated_at
                          ).format('DD/MM/YYYY')}
                        />
                      )}
                    </Steps>
                  </Col>
                </Row>
              )}
              <Card style={{ margin: 20 }}>
                <Row justify="space-between" gutter={[10, 10]}>
                  <Col xs={24} md={12} xl={6}>
                    <Organization
                      display={displayedResource?.organization}
                      isLoading={isLoading}
                    />
                    {displayedResource.bank_account && (
                      <p
                        style={{
                          marginTop: 20,
                          fontSize: 17,
                          color: 'var(--primaryColor)'
                        }}
                      >
                        {`Compte banque : ${displayedResource.bank_account.name}`}
                      </p>
                    )}
                  </Col>

                  <Col
                    xs={24}
                    md={12}
                    xl={6}
                    style={{
                      paddingBottom: '10px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'end'
                    }}
                  >
                    <Customer
                      customer={displayedResource?.customer}
                      isLoading={isLoading}
                    />

                    {resource === 'invoices' && (
                      <RemainingToPay
                        isLoading={isLoading}
                        invoice={displayedResource}
                      />
                    )}

                    <Contact
                      isLoading={isLoading}
                      contact={displayedResource?.contact}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Title level={3}>
                      {t(`${resource}.show.title`)}&nbsp;
                      {displayedResource?.subject}
                    </Title>
                    {displayedResource?.comment && (
                      <i>
                        {t(`${resource}.show.comment`)}&nbsp;
                        {displayedResource?.comment}
                      </i>
                    )}
                    <p>
                      {t(`${resource}.show.emission`, {
                        date: moment(displayedResource?.emission_date).format(
                          'LL'
                        )
                      })}
                      <br />
                      {displayedResource?.payment_date &&
                        t(`${resource}.show.payment`, {
                          date: moment(displayedResource?.payment_date).format(
                            'LL'
                          )
                        })}
                      <br />
                      {displayedResource?.total_paid &&
                        displayedResource?.total_paid !==
                          displayedResource?.total &&
                        t(`${resource}.show.remaining`, {
                          amount:
                            displayedResource?.total -
                            displayedResource?.total_paid
                        })}
                      {displayedResource?.order && resource === 'quotations' && (
                        <>
                          {displayedResource?.updated_at &&
                            t(`${resource}.show.validated`, {
                              date: moment(
                                displayedResource?.updated_at
                              ).format('LL')
                            })}
                          &nbsp;-&nbsp;
                          {displayedResource?.order.number}
                        </>
                      )}
                    </p>
                  </Col>
                </Row>

                <Skeleton
                  active
                  title={false}
                  loading={isLoading}
                  paragraph={{
                    rows: 4,
                    width: ['100%', '100%', '100%', '100%']
                  }}
                >
                  <Table
                    dataSource={(displayedResource?.prestations || []).map(
                      ({ _id, ...article }) => ({
                        ...article,
                        key: _id
                      })
                    )}
                    pagination={false}
                  >
                    <Column
                      title={t('invoices.showInvoice.prestationsTable.label')}
                      dataIndex="label"
                    />
                    <Column
                      title={t('invoices.showInvoice.prestationsTable.area')}
                      dataIndex="area"
                      align="right"
                      render={(area) =>
                        area === undefined ? '' : `${area} m²`
                      }
                    />
                    <Column
                      title={t(
                        'invoices.showInvoice.prestationsTable.unit_price'
                      )}
                      dataIndex="unit_price"
                      align="right"
                      render={(price) => `${numeral(price).format('0,0.00')}€`}
                    />
                    <Column
                      title={t(
                        'invoices.showInvoice.prestationsTable.discount'
                      )}
                      dataIndex="discount"
                      align="right"
                      render={(discount) =>
                        discount
                          ? `${numeral(discount.value).format('0,0.00')}${
                              discount.type === 'PERCENT' ? '%' : '€'
                            }`
                          : 'N/A'
                      }
                    />

                    <Column
                      title={t(
                        'invoices.showInvoice.prestationsTable.subTotal'
                      )}
                      rowKey="_id"
                      align="right"
                      render={({ area, unit_price, discount }) => {
                        let tmpDiscount = 0;
                        let setArea;
                        if ((area && area <= 60) || area === undefined) {
                          setArea = 1;
                        } else {
                          setArea = area;
                        }

                        if (discount && discount.value && discount.value > 0) {
                          tmpDiscount =
                            discount.type === 'CURRENCY'
                              ? discount.value
                              : (setArea * unit_price * discount.value) / 100;
                        }

                        return `${numeral(
                          setArea * unit_price - tmpDiscount
                        ).format('0,0.00')}€`;
                      }}
                    />
                  </Table>
                </Skeleton>

                <Row style={{ marginTop: 50 }}>
                  <Col>
                    <p>INCLUS :</p>
                    <Select
                      mode="tags"
                      style={{ width: '45%' }}
                      value={selectedMentions}
                      optionLabelProp="label"
                      onChange={saveMentions}
                      disabled={displayedResource?.status === 'VALIDATE'}
                    >
                      {specificsMentions?.map((el) => (
                        <Option key={`spec_${el}`} value={el}>
                          {el}
                        </Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <Row style={{ marginTop: 50 }}>
                  <Col
                    style={{
                      background: 'var(--bodyBackground)',
                      border: '1px solid var(--borderColor)',
                      padding: 8
                    }}
                    xs={{ offset: 8, span: 16 }}
                    md={{ offset: 14, span: 10 }}
                  >
                    <Skeleton active loading={isLoading} paragraph={0}>
                      <Row style={{ borderBottom }}>
                        <Col span={8} style={{ textAlign: 'left' }}>
                          <Typography.Text style={{ fontSize: '16px' }}>
                            {`${t('invoices.showInvoice.subTotal')} `}
                          </Typography.Text>
                        </Col>
                        <Col span={16} style={{ textAlign: 'right' }}>
                          <Typography.Text style={{ fontSize: '16px' }}>
                            {`${numeral(
                              displayedResource?.subTotal || 0
                            ).format('0,0.00')}€`}
                          </Typography.Text>
                        </Col>
                      </Row>
                      <Row style={{ borderBottom }}>
                        <Col span={8} style={{ textAlign: 'left' }}>
                          <Typography.Text style={{ fontSize: '16px' }}>
                            {`${t('invoices.showInvoice.vat')} : ${
                              displayedResource?.vat?.rate
                            } %`}
                          </Typography.Text>
                        </Col>
                        <Col span={16} style={{ textAlign: 'right' }}>
                          <Typography.Text style={{ fontSize: '16px' }}>
                            {`${numeral(
                              displayedResource?.total_VAT || 0
                            ).format('0,0.00')}€`}
                          </Typography.Text>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={8} style={{ textAlign: 'left' }}>
                          <Typography.Text style={{ fontSize: '16px' }}>
                            {t('invoices.showInvoice.discount')}
                          </Typography.Text>
                        </Col>
                        <Col span={16} style={{ textAlign: 'right' }}>
                          <Typography.Text style={{ fontSize: '16px' }}>
                            {`${numeral(
                              displayedResource?.total_discount || 0
                            ).format('0,0.00')}€`}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Skeleton>
                  </Col>
                </Row>
                <Row style={{ margin: '30px 0' }}>
                  <Col
                    xs={{ offset: 8, span: 16 }}
                    md={{ offset: 14, span: 10 }}
                  >
                    <Skeleton active loading={isLoading} paragraph={0}>
                      <Row type="flex" style={{ height: '100%' }}>
                        <Col span={8} style={{ textAlign: 'left' }}>
                          <Typography.Text strong style={{ fontSize: '18px' }}>
                            {t('invoices.showInvoice.totalDue', {
                              date: displayedResource?.payment_date
                                ? `${moment(
                                    displayedResource?.payment_date
                                  ).format('L')}`
                                : ''
                            })}
                          </Typography.Text>
                        </Col>
                        <Col
                          span={16}
                          style={{
                            textAlign: 'right',
                            display: 'flex',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end'
                          }}
                        >
                          <Typography.Text strong style={{ fontSize: '18px' }}>
                            {`${numeral(
                              displayedResource?.total ||
                                0 + displayedResource?.VAT ||
                                0
                            ).format('0,0.00')}€`}
                          </Typography.Text>
                        </Col>
                      </Row>
                    </Skeleton>
                  </Col>
                </Row>
              </Card>
            </ContentCustom>
          </Spin>
        </>
      )}
    </>
  );
};

ShowQuotation.propTypes = {
  resource: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
ShowQuotation.defaultProps = {
  resource: null
};

export default ShowQuotation;
