import React from 'react';
import { Layout } from 'antd';

const { Content } = Layout;

const ContentCustom = ({ children, background }) => {
  return (
    <Content
      style={{
        padding: '24px 24px 16px 24px',
        marginTop: 2,
        background: background || 'var(--componentBackground)'
      }}
    >
      {children}
    </Content>
  );
};

export default ContentCustom;
