import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';
import { useDrop } from 'react-dnd';
import CardList from './cardList';
import FiltersMoves from './filters';
import useInterventionContext from './InterventionContext';

const ListMoves = () => {
  const [filter, setFilter] = useState({});
  const {
    movesToPlanned,
    unPlannedMove,
    isLoading,
    setIsLoading,
    setDisplayToPlanned,
    toBeAffectToWeekX
  } = useInterventionContext();

  const [{ isOver }, drop] = useDrop({
    accept: 'card',
    drop: (item) => {
      unPlannedMove(item.id, 'toAffect');
    },
    collect: (monitor) => ({
      isOver: monitor.isOver()
    })
  });

  useEffect(() => {
    if (Object.keys(filter).length === 0) {
      setDisplayToPlanned(movesToPlanned);
    } else {
      Object.entries(filter).forEach(([key, value]) => {
        if (key === 'customer') {
          setDisplayToPlanned(
            movesToPlanned.filter((el) => el[key]._id === value)
          );
        } else {
          setDisplayToPlanned(
            movesToPlanned.filter((el) => el[key]._id === value)
          );
        }
      });
    }
  }, [filter]);
  return (
    <Row style={{ margin: '16px 0 0 16px' }}>
      <Col
        xxl={4}
        lg={5}
        sm={6}
        xs={8}
        style={{
          height: '120px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          paddingRight: '1%'
        }}
      >
        <FiltersMoves setFilter={setFilter} />
      </Col>
      <Col xxl={20} lg={19} sm={18} xs={16}>
        <Row
          ref={drop}
          justify={toBeAffectToWeekX.length && !isOver > 0 ? 'start' : 'center'}
          align="middle"
          style={{
            border:
              toBeAffectToWeekX.length > 0
                ? 'none'
                : '1px solid var(--primaryColor)',
            backgroundColor: isOver && 'var(--primaryColor)',
            height: `120px`
          }}
        >
          {toBeAffectToWeekX.length > 0 ? (
            toBeAffectToWeekX.map((move) => {
              return (
                <CardList
                  key={move._id}
                  el={move}
                  isLoading={isLoading}
                  setIsLoading={setIsLoading}
                  color="#ff4d4f"
                />
              );
            })
          ) : (
            <div style={{ color: isOver ? '#fff' : 'var(--primaryColor)' }}>
              AUCUNE INTERVENTION A PLANIFIER
            </div>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default ListMoves;
