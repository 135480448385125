import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import CreateUpdateInventory from './CreateUpdateInventory';
import ShowInventory from './ShowInventory';
import ListInventories from './ListInventories';

const InventoriesRouter = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route
        path={`${path}/create`}
        render={() => <CreateUpdateInventory purpose="create" />}
      />
      <Route path={`${path}/edit/:id`} component={ShowInventory} />
      <Route path={`${path}/show/:id`} component={ShowInventory} />
      <Route path={`${path}`} component={ListInventories} />
    </Switch>
  );
};

export default InventoriesRouter;
