import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const useListContent = ({
  label,
  code,
  buy_price,
  description,
  supplier,
  ref_supplier,
  sold_price
}) => {
  const { t } = useTranslation();

  return [
    {
      label: t('tools.form.code'),
      content: code,
      span: 1
    },
    {
      label: t('tools.form.label'),
      content: label,
      span: 2
    },
    {
      label: t('tools.form.buy_price'),
      content: `${buy_price || '-'} €`,
      span: 1
    },
    {
      label: t('tools.form.sold_price'),
      content: `${sold_price || '-'} €`,
      span: 1
    },
    {
      label: t('articles.form.mark_up'),
      content:
        sold_price &&
        buy_price &&
        `${Math.round((sold_price / buy_price) * 100 - 100)} %`,
      span: 1
    },
    {
      label: t('tools.form.supplier'),
      content: supplier && (
        <Link to={`/purchases/suppliers/show/${supplier._id}`}>
          {supplier.name}
        </Link>
      ),
      span: 1
    },
    {
      label: t('tools.form.ref_supplier'),
      content: ref_supplier,
      span: 2
    },
    {
      label: t('tools.form.description'),
      content: description,
      span: 3
    }
  ];
};

export default useListContent;
