import React, { useEffect, useState } from 'react';
import { Input, message } from 'antd';
import useAuthContext from '../../../../contexts/AuthContext';
import ManagerTable from './ManagerTable';

const initialColumns = [
  {
    title: "chef d'équipe",
    dataIndex: 'columns',
    key: 'columns'
  },
  {
    title: 'Label',
    dataIndex: 'label',
    key: 'label',
    render: (text) => (
      <Input InitialValue={`${text?.first_name} ${text?.last_name}`} />
    )
  }
];

const ManagerSettings = () => {
  const { dispatchAPI } = useAuthContext();
  const [managersCols, setManagersCols] = useState(initialColumns);
  const [trucks, setTrucks] = useState([]);

  const fetchManagers = async () => {
    try {
      const data = await dispatchAPI('GET', {
        url: `/users?type=MANAGER&populate=managed_trucks`
      });
      setManagersCols(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };
  const fetchTrucks = async () => {
    try {
      const data = await dispatchAPI('GET', {
        url: `/trucks`
      });
      setTrucks(data.data);
    } catch (e) {
      message.error(e.message);
    }
  };

  const editManagersCol = async (_id, row) => {
    try {
      await dispatchAPI('PATCH', {
        url: `/users/${_id}`,
        body: row
      });
    } catch (e) {
      message.error(e.message);
    }
    await fetchManagers();
  };
  useEffect(() => {
    fetchManagers();
    fetchTrucks();
  }, []);

  return (
    <>
      <ManagerTable
        managersCols={managersCols}
        trucks={trucks}
        editManagerCol={editManagersCol}
      />
    </>
  );
};

export default ManagerSettings;
