import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Divider, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { EditOutlined } from '@ant-design/icons';
import { outOfNavRoutes } from '../../utils/constants/routes';
import useAuthContext from '../../contexts/AuthContext';
import DescriptionList from '../../components/DescriptionList/DescriptionList';
import listContent from './listContent';
import PageHeaderCustom from '../../components/PageHeader/PageHeader';
import ContentCustom from '../../components/ContentCustom/ContentCustom';

const Profile = () => {
  const {
    dispatchAPI,
    user
  } = useAuthContext();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <PageHeaderCustom
        title={t('profile.title')}
        extra={
          <>
            <Link to={{ pathname: `${outOfNavRoutes.PROFILE}/edit/${user._id}` }}>
              <Button type="primary">
                {`${t('buttons.edit')} `}
                <EditOutlined />
              </Button>
            </Link>
          </>
        }
      />
      <ContentCustom>
        <Skeleton loading={isLoading} active>
          <DescriptionList data={listContent(user, t)} translate />
        </Skeleton>
        <Divider orientation="left">{t('profile.settings.title')}</Divider>
        <div>
          <Link to={`${outOfNavRoutes.PROFILE}/change-pwd`}>
            <Button type="link">{t('profile.settings.changePwd')}</Button>
          </Link>
        </div>
     {/*   <div>
          <Button type="link">{t('profile.settings.others')}</Button>
        </div>*/}
      </ContentCustom>
    </>
  );
};

export default Profile;
