import React from 'react';
import { Descriptions, Tag, Row, Col, Card, Checkbox } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import useAuthContext from '../../../../contexts/AuthContext';
import useMovementContext from '../PostIntervention/MovementsContext';
import { TruckIconFilled } from '../../../../utils/constants/customTruckIcons';

const ShowInvoicing = ({ data }) => {
  const { user } = useAuthContext();
  const { CSTB } = useMovementContext();

  return (
    <>
      <Row justify="start">
        <Col span={24}>
          <Card>
            <Row>
              <Col
                style={{ color: 'var(--addColor)', textAlign: 'start' }}
                span={24}
              >
                <>
                  <TruckIconFilled />
                  <span style={{ color: 'black', marginLeft: 20 }}>
                    {`${CSTB?.truck?.label} - Unité de projection : ${CSTB?.truck?.projection_unit}`}
                  </span>
                </>
              </Col>
            </Row>
            <Descriptions
              colon={false}
              column={4}
              extra={<Tag>Infos du technicien</Tag>}
              style={{ fontSize: 20, flex: 'none' }}
            >
              <Descriptions.Item label="Nom">
                <span style={{ fontWeight: 'bold' }}>{user?.last_name}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Prénom">
                <span style={{ fontWeight: 'bold' }}>{user?.first_name}</span>
              </Descriptions.Item>
              <Descriptions.Item label="Date de l'intervention">
                <span style={{ fontWeight: 'bold' }}>
                  {moment(CSTB?.date).format('DD-MM-YYYY')}
                </span>
              </Descriptions.Item>
              <Descriptions.Item span={2} label="N° de devis">
                <span style={{ fontWeight: 'bold' }}>
                  {CSTB?.quotation?.number}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Temps de transport">
                <span style={{ fontWeight: 'bold' }}>
                  {data?.time_truck ? `${data.time_truck} Heures` : null}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Temps de l'intervention">
                <span style={{ fontWeight: 'bold' }}>
                  {data?.time_intervention
                    ? `${data.time_intervention} Heures`
                    : null}
                </span>
              </Descriptions.Item>
              <Descriptions.Item span={2} label="Temps de pause">
                <span style={{ fontWeight: 'bold' }}>
                  {data?.break
                    ? `${moment(data.break).format('HH:mm')} Heures`
                    : null}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Panier">
                <Checkbox checked={data?.basket || false} disabled />
              </Descriptions.Item>
              <Descriptions.Item label="Panier du soir">
                <Checkbox checked={data?.basket_night || false} disabled />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={24}>
          <Row justify="space-around" style={{ width: '100%' }}>
            <Col span={4}>
              <Col span={24}>
                <span style={{ marginRight: 10 }}>Départ camion : </span>
              </Col>
              <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>
                  {moment(data.start_truck).format('HH:mm')}
                </span>
              </Col>
            </Col>
            <Col span={4}>
              <Col span={24}>
                <span style={{ marginRight: 10 }}>
                  Début d&apos;intervention :
                </span>
              </Col>
              <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>
                  {moment(data.start_movement).format('HH:mm')}
                </span>
              </Col>
            </Col>
            <Col span={4}>
              <Col span={24}>
                <span style={{ marginRight: 10 }}>
                  fin d&apos;intervention :
                </span>
              </Col>
              <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>
                  {moment(data.due_movement).format('HH:mm')}
                </span>
              </Col>
            </Col>
            <Col span={4}>
              <Col span={24}>
                <span style={{ marginRight: 10 }}>Arrivée camion : </span>
              </Col>
              <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>
                  {moment(data.due_truck).format('HH:mm')}
                </span>
              </Col>
            </Col>
            <Col span={4}>
              <Col span={24}>
                <span style={{ marginRight: 10 }}>Pause: </span>
              </Col>
              <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>{moment(data.break).format('HH:mm')}</span>
              </Col>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

ShowInvoicing.propTypes = {
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};

ShowInvoicing.defaultProps = {
  data: null
};

export default ShowInvoicing;
