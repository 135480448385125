import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { message, Table } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';

const TableStock = ({ style, stockType }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const { id } = useParams();

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data } =
        stockType === 'consumables'
          ? await dispatchAPI('GET', {
              url: `/articles/deposit/${id}?populate=unit`
            })
          : stockType === 'epis'
          ? await dispatchAPI('GET', {
              url: `/epis/bydeposit/${id}?populate=unit`
            })
          : await dispatchAPI('GET', {
              url: `/machines/deposit/${id}`
            });
      setResources(data.map(({ _id, ...d }) => ({ ...d, key: _id })));
    } catch (e) {
      message.error(e.message);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    (async () => {
      await fetchData();
    })();
  }, [fetchData]);

  const columns = [
    {
      title: t('articles.column.code'),
      key: 'code',
      dataIndex: 'code',
      sorter: true
    },
    {
      title: t('articles.column.wording'),
      key: 'label',
      dataIndex: 'label',
      sorter: true
    },
    {
      title: t('articles.column.quantity'),
      key: 'quantity',
      dataIndex: 'quantity',
      render: (e) => e || <span style={{ color: 'red' }}>0</span>
    },
    {
      title: t('articles.column.unit'),
      key: 'unit',
      dataIndex: 'unit',
      render: (e) => e?.label
    }
  ];

  return (
    <>
      <Table
        style={style}
        rowClassName="rowStyle"
        dataSource={resources}
        loading={isLoading}
        columns={columns}
        pagination={false}
        scroll={{ x: 700 }}
      />
      <style>
        {`
          .rowStyle {
            cursor: pointer;
          }
        `}
      </style>
    </>
  );
};
TableStock.propTypes = {
  style: PropTypes.string.isRequired,
  stockType: PropTypes.string.isRequired
};

export default TableStock;
