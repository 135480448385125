import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ListMovements from './ListMovements';
import ShowMovement from './ShowMovement';
import CreateUpdateMovements from './CreateUpdateMovements';
import { MovementContextProvider } from '../ManageMovements/PostIntervention/MovementsContext';

const MovementsRouter = () => {
  const { path } = useRouteMatch();

  return (
    <MovementContextProvider>
      <Switch>
        <Route
          path={`${path}/create`}
          render={() => <CreateUpdateMovements purpose="create" />}
        />
        <Route
          path={`${path}/edit/:id`}
          render={() => <CreateUpdateMovements purpose="edit" />}
        />
        <Route path={`${path}/show/:id/:key`} component={ShowMovement} />
        <Route path={`${path}/show/:id`} component={ShowMovement} />
        <Route path={path} component={ListMovements} />
      </Switch>
    </MovementContextProvider>
  );
};

export default MovementsRouter;
