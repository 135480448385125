import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Select,
  Table
} from 'antd';
import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import useErrorMessage from '../../utils/ErrorMessage';
import { Option } from 'antd/es/mentions';
import useAuthContext from '../../contexts/AuthContext';

const { TextArea } = Input;

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  isLoading,
  templates,
  ...restProps
}) => {
  const [template, setTemplate] = useState();
  let inputNode;

  switch (inputType) {
    case 'NUMBER':
    case 'DAY':
      inputNode = <InputNumber />;
      break;
    case 'TEXT':
      inputNode = <Input />;
      break;
    case 'TEXTAREA':
      inputNode = <TextArea />;
      break;
    case 'TEMPLATE':
      inputNode = (
        <Select
          placeholder="Sélectionner un modèle"
          loading={isLoading}
          style={{ width: 200, marginRight: 0 }}
          onChange={(value) => {
            setTemplate(value);
          }}
          value={template}
          allowClear
        >
          {templates.map((file) => (
            <Option key={file._id} value={file._id}>
              {file.metadata.originalName}
            </Option>
          ))}
        </Select>
      );
      break;
    default:
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[{ required: inputType !== 'TEMPLATE' }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const AccountingTable = ({ settings, editSetting }) => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { message } = useErrorMessage();
  const [editingKey, setEditingKey] = useState('');
  const [editedValue, setEditedValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);

  const isEditing = (record) => record._id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      label: '',
      ...record
    });
    setEditingKey(record._id);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();

      if (record.label === 'number_regex') {
        if (!row.value.includes('{number}')) {
          return message('number_regex_invalid');
        }
      }

      if (!row.value && record.unitType === 'TEMPLATE') row.value = null;

      setEditingKey('');
      editSetting(record._id, row);
    } catch (e) {
      message(e.response.status);
    }
  };

  const getTemplates = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/files' });
      setTemplates(data);
    } catch (e) {
      if (e.response) message(e.response.status);
    }
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    await getTemplates();
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns = [
    {
      title: t('settings.accounting.label'),
      dataIndex: 'label',
      width: '30%',
      editable: false,
      render: (v) => t(`settings.accounting.${v}`)
    },
    {
      title: t('settings.accounting.value'),
      key: 'value',
      width: '30%',
      editable: true,
      render: (setting) => {
        if (!setting.value)
          return (
            <span style={{ color: 'var(--secondaryColor)' }}>
              {t('settings.accounting.no-value')}
            </span>
          );
        if (setting.unitType === 'TEMPLATE') {
          const template = templates.find((t) => t._id === setting.value);
          return template && template.metadata.originalName;
        }
        return `${setting.value}`;
      }
    },
    {
      title: t('settings.accounting.action'),
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Popconfirm
              title={t('settings.accounting.confirm')}
              onConfirm={() => save(record)}
            >
              <Button style={{ borderRadius: 0, width: '50%' }}>
                {t('buttons.save')}
                <SaveOutlined />
              </Button>
            </Popconfirm>
            <Button onClick={cancel} style={{ borderRadius: 0, width: '50%' }}>
              {t('buttons.cancel')}
              <CloseOutlined />
            </Button>
          </>
        ) : (
          <Button
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
            type="primary"
            style={{ borderRadius: 0 }}
          >
            {t('buttons.edit')}
            <EditOutlined />
          </Button>
        );
      }
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: record.unitType,
        dataIndex: col.key,
        title: col.title,
        value: editedValue,
        setValue: setEditedValue,
        editing: isEditing(record),
        isLoading,
        templates
      })
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell
          }
        }}
        bordered
        dataSource={settings}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={false}
      />
    </Form>
  );
};

export default AccountingTable;
