import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { Button, Col, Input, Row, Form } from 'antd';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { noLabelLayout } from '../../../utils/constants/formLayout';

const AdvancedFields = ({ field }) => {
  const [visible, setVisible] = useState();
  const { t } = useTranslation();
  return (
    <>
      <Row style={{ marginTop: 30, marginBottom: 30 }}>
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        <Col>
          <Button type="link" onClick={() => setVisible(!visible)}>
            {t('customers.form.options')}
            {visible ? (
              <UpOutlined style={{ marginLeft: 10 }} />
            ) : (
              <DownOutlined style={{ marginLeft: 10 }} />
            )}
          </Button>
        </Col>
      </Row>
      {visible && (
        <Form.Item
          name={[field.name, 'specifications']}
          style={{ width: '100%' }}
        >
          <Input.Group
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}
          >
            <Form.Item
              name={[field.name, 'specifications', 'shower_location']}
              label={t('sites.form.shower_location')}
              labelAlign="left"
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={[field.name, 'specifications', 'upper_one_meter_location']}
              label={t('sites.form.upper_one_meter_location')}
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
              labelAlign="left"
            >
              <Input />
            </Form.Item>

            <Form.Item
              name={[field.name, 'specifications', 'heating_floor']}
              label={t('sites.form.heating_floor')}
              style={{
                display: 'flex',
                flexDirection: 'column'
              }}
              labelAlign="left"
            >
              <Input />
            </Form.Item>
          </Input.Group>
        </Form.Item>
      )}
    </>
  );
};

AdvancedFields.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired
};
export default AdvancedFields;
