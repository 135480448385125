export const userRoles = {
  GUEST: 'green',
  USER: 'blue',
  'SUPER-USER': 'geekblue',
  ADMIN: 'red',
  'SUPER-ADMIN': 'magenta'
};

export const userTypes = {
  SELLER: 'red',
  TECH: 'blue',
  MANAGER: 'magenta',
  ACCOUNTING: 'green'
};

export const invoiceTypes = {
  PAY: 'blue',
  DEPOSIT: 'green',
  CREDIT: 'magenta'
};
export const type_invoices = {
  GLOBAL: 'blue',
  INTERVENTION: 'green',
  DEPOSIT: 'black',
  CREDIT: 'magenta'
};

export const ticketStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  AUDITED: 'cyan',
  CLOSED: 'red',
  CANCELED: 'red'
};

export const quotationsStatus = {
  REQUEST: 'blue',
  TO_NUMBER: 'cyan',
  READY: 'purple',
  NEED_INFOS: 'gold',
  REFUSED: 'red',
  TO_VALIDATE: 'magenta',
  TO_SEND: 'orange',
  PENDING: 'grey',
  QUOTATION_PENDING: 'purple',
  VALIDATE: 'green'
};

export const dealsPriorities = {
  LOW: 'green',
  NORMAL: 'blue',
  HIGH: 'orange',
  URGENT: 'red'
};

export const ticketsPriorities = ['NORMAL', 'HIGH', 'URGENT'];

export const ticketsPrioritiesColor = {
  LOW: 'blue',
  NORMAL: 'green',
  HIGH: 'orange',
  URGENT: 'red'
};

export const ticketsTypes = ['PICKUP', 'THEFT', 'LOSS', 'DAMAGED'];

export const ticketsTypesColor = {
  PICKUP: 'green',
  THEFT: 'volcano',
  LOSS: 'cyan',
  DAMAGED: 'red'
};

export const tasksStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  CLOSED: 'red'
};

export const tasksPriorities = {
  URGENT: 'red',
  HIGH: 'yellow',
  NORMAL: 'cyan',
  LOW: 'grey'
};

export const projectsStatuses = {
  OPEN: 'blue',
  PENDING: 'green',
  'IN PROGRESS': 'orange',
  AUDITED: 'cyan',
  CLOSED: 'red',
  CANCELED: 'red'
};

export const companiesTypes = {
  CUSTOMERS: 'blue',
  SUPPLIERS: 'green'
};

export const userPermissions = {
  devTeam: 'yellow'
};

export const movementsStatus = {
  WAITING: 'default',
  PLANNED: 'orange',
  VALIDATED: 'green'
};

export const movementsTypes = {
  COMMERCIAL: 'blue',
  TECHNICIANS: 'orange'
};
