import {
  Modal,
  Form,
  Divider,
  Button,
  Select,
  DatePicker,
  Row,
  Col,
  message,
  Card
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined
} from '@ant-design/icons';
import Draggable from 'react-draggable';
import useAuthContext from '../../../../contexts/AuthContext';
import useErrorMessage from '../../../../utils/ErrorMessage';
import {
  formItemLayout,
  noLabelLayout
} from '../../../../utils/constants/formLayout';
import useInterventionContext from '../InterventionContext';
import RecapModal from './RecapModal';
import Fields from './Fields';

const { Option } = Select;

const SplitInterventionModal = ({ showModal, setShowModal, el }) => {
  const { t } = useTranslation();
  const { dispatchAPI } = useAuthContext();
  const { trucks, refresh, setRefresh } = useInterventionContext();
  const [showField, setShowField] = useState(false);
  const [currentArea, setCurrentArea] = useState(0);
  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0
  });
  const [disabled, setDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();
  const { message: errorMessage } = useErrorMessage();
  const draggleRef = React.createRef();

  const onStart = (event, uiData) => {
    const { clientWidth, clientHeight } = window?.document?.documentElement;
    const targetRect = draggleRef?.current?.getBoundingClientRect();
    setBounds({
      left: -targetRect?.left + uiData?.x,
      right: clientWidth - (targetRect?.right - uiData?.x),
      top: -targetRect?.top + uiData?.y,
      bottom: clientHeight - (targetRect?.bottom - uiData?.y)
    });
  };

  const duplicateIntervention = async (body) => {
    const initialMovement = { prestas: body.initialMovement };
    try {
      await dispatchAPI('POST', {
        url: `/movements`,
        body
      });
      await dispatchAPI('PATCH', {
        url: `/movements/${el.id}`,
        body: initialMovement
      });
    } catch (e) {
      if (e.response) errorMessage(e.response.status);
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().startOf('week');
  };

  const totalArea =
    typeof el !== 'string'
      ? el?.area?.reduce((total, cv) => {
          return total + parseFloat(cv);
        }, 0)
      : 0;

  const handleSubmit = async (values) => {
    setIsLoading(false);
    const body = {
      ...values,
      initialMovement: { ...values.initialMovement, id: el.id }
    };

    if (currentArea === totalArea) {
      await duplicateIntervention(body);
      setShowModal(false);
      setRefresh(!refresh);
      setIsLoading(true);
      form.resetFields();
    } else {
      message.error(
        'La surface totale saisie ne correspond pas avec la surface totale du chantier'
      );
    }
  };
  const onChangeFields = (_, values) => {
    const { initialMovement, newDate } = values;
    if (initialMovement || newDate) {
      const resultNewDate = newDate
        ? newDate
            ?.map(
              (prestas) =>
                prestas &&
                prestas.prestas
                  ?.map((area) => area && area.area)
                  ?.reduce((curr, prev) => curr + prev, 0)
            )
            ?.reduce((curr, prev) => curr + prev, 0)
        : 0;
      setCurrentArea(initialMovement?.area + resultNewDate);
    }
  };
  const checkIsNaN = (value) => {
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(value)) return 'Saisie incomplète';
    return value;
  };
  return (
    <Modal
      className="split-intervention-modal"
      title={
        <div
          style={{
            width: '100%',
            cursor: 'move'
          }}
          onMouseOver={() => {
            if (disabled) {
              setDisabled(false);
            }
          }}
          onMouseOut={() => {
            setDisabled(true);
          }}
          onFocus={() => {}}
          onBlur={() => {}}
        >
          {`Dupliquer l'intervention de ${totalArea} m²`}
        </div>
      }
      width="40%"
      visible={showModal}
      onCancel={() => setShowModal(false)}
      footer={false}
      cancelText={t('buttons.cancel')}
      modalRender={(modal) => (
        <Draggable
          disabled={disabled}
          bounds={bounds}
          onStart={(event, uiData) => onStart(event, uiData)}
        >
          <div ref={draggleRef}>{modal}</div>
        </Draggable>
      )}
    >
      {/* {totalArea > 100 && ( */}
      <Form
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...formItemLayout}
        form={form}
        onValuesChange={onChangeFields}
        onFinish={handleSubmit}
      >
        <RecapModal el={el} showField={showField} setShowField={setShowField} />
        <Divider orientation="left">
          {`Intervention 1 : ${moment(el?.date).format('DD-MM-YYYY')}`}
        </Divider>
        <Row justify="space-around" style={{ minWidth: '100%' }}>
          {/*  Form.List => ajoute de nouvelles mesures */}

          <Row justify="space-around" style={{ minWidth: '100%' }}>
            <Fields
              el={el}
              name={['initialMovement']}
              getFieldsValue={form.getFieldsValue}
            />
          </Row>
        </Row>

        {/*  Form.List => ajoute de nouveaux jours */}
        <Form.List name="newDate" {...formItemLayout}>
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, fieldKey }, index) => (
                <Row align="middle" justify="space-between" key={key}>
                  <Divider orientation="left">
                    {`${t(`movements.modal.day`)} ${index + 2}`}
                    <DeleteOutlined
                      onClick={() => remove(name)}
                      style={{
                        fontSize: '15px',
                        marginLeft: '10px'
                      }}
                    />
                  </Divider>
                  <Form.Item
                    fieldKey={[fieldKey, `date`]}
                    name={[name, 'date']}
                    label={t(`movements.modal.date`)}
                    validateTrigger={['onChange', 'onBlur']}
                    style={{ width: '100%' }}
                    className="intervention-date-select"
                  >
                    <DatePicker
                      disabledDate={disabledDate}
                      format="DD/MM/YYYY"
                    />
                  </Form.Item>

                  {/*  Form.List => ajoute de nouvelles mesures dans les nouveaux jours */}

                  <Form.List
                    name={[name, 'prestas']}
                    {...formItemLayout}
                    initialValue={[{}]}
                  >
                    {(fields2, { add: add2, remove: remove2 }) => (
                      <>
                        {fields2.map(
                          ({ key: key2, name: name2, fieldKey: fieldKey2 }) => (
                            <Row
                              justify="center"
                              style={{ minWidth: '100%' }}
                              key={key2}
                            >
                              <Card style={{ width: '90%', margin: 10 }}>
                                <Fields
                                  el={el}
                                  name={name2}
                                  getFieldsValue={form.getFieldsValue}
                                />

                                <MinusCircleOutlined
                                  style={{
                                    color: 'var(--secondaryColor)',
                                    fontSize: 16,
                                    width: '10%',
                                    position: 'relative',
                                    left: '92%',
                                    bottom: '35px'
                                  }}
                                  onClick={() => remove2(name2)}
                                />
                                {showField && (
                                  <Form.Item
                                    fieldKey={[fieldKey2, `truck`]}
                                    validateTrigger={['onChange', 'onBlur']}
                                    label={t(`movements.modal.truck`)}
                                    name={[name2, 'truck']}
                                    style={{ width: '100%' }}
                                  >
                                    <Select>
                                      {trucks.map(({ _id, label }) => (
                                        <Option key={_id} value={_id}>
                                          {label}
                                        </Option>
                                      ))}
                                    </Select>
                                  </Form.Item>
                                )}
                              </Card>
                            </Row>
                          )
                        )}
                        <Col span={24}>
                          <Form.Item {...noLabelLayout}>
                            <Button
                              disabled={currentArea >= totalArea}
                              type="dashed"
                              onClick={() => add2()}
                            >
                              {`${t(`movements.modal.addMeasure`)} `}
                              <PlusOutlined />
                            </Button>
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </Form.List>
                </Row>
              ))}
              <Divider />
              <Form.Item {...noLabelLayout} style={{ width: '100%' }}>
                <Button
                  disabled={currentArea >= totalArea}
                  type="dashed"
                  onClick={() => add()}
                >
                  {`${t(`movements.modal.addDay`)} `}
                  <PlusOutlined />
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        <Divider />
        <Row justify="space-around">
          <Col span={6}>
            <Button
              loading={isLoading}
              disabled={isLoading || totalArea !== currentArea}
              type="add"
              htmlType="submit"
            >
              {`${t('buttons.save')} `}
              <CheckOutlined />
            </Button>
          </Col>
          <Col span={6}>
            <Button type="link" danger onClick={() => setShowModal(false)}>
              {`${t('buttons.cancel')} `}
              <CloseOutlined />
            </Button>
          </Col>
          <Col span={6}>
            <span>
              {`Surface restante : 
              ${checkIsNaN(totalArea - currentArea)} m²`}
            </span>
          </Col>
        </Row>
      </Form>
      {/* )} */}
    </Modal>
  );
};

SplitInterventionModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  el: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired
};

export default SplitInterventionModal;
