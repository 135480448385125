import React from 'react';
import { Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CSTBPendingTable = ({ tableCSTBData, movements }) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: 'Number',
      dataIndex: 'number',
      key: 'number',
      render: (number) => {
        return (
          movements &&
          tableCSTBData && (
            <Link
              to={`/intervention/manage_interventions/show/${
                movements.find(
                  (movement) =>
                    movement.CSTB._id ===
                    tableCSTBData.find((cstb) => cstb.number === number)._id
                )?._id
              }`}
            >
              {number}
            </Link>
          )
        );
      }
    },

    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => t(`cstb.status.${text}`)
    }
  ];

  return (
    tableCSTBData && <Table columns={columns} dataSource={tableCSTBData} />
  );
};

export default CSTBPendingTable;
