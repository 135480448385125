import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import useAuthContext from '../../../contexts/AuthContext';

const useColumns = () => {
  const { dispatchAPI } = useAuthContext();
  const { t } = useTranslation();
  const [supports, setSupports] = useState([]);

  const getEnums = async () => {
    try {
      const { data } = await dispatchAPI('GET', { url: '/prestations/enums' });
      setSupports(data.supports);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  useEffect(() => {
    (async () => {
      await getEnums();
    })();
  }, []);

  const base = [
    {
      title: t('prestations.column.reference'),
      key: 'reference',
      width: 200,
      dataIndex: 'reference',
      sorter: true
    },
    {
      title: t('prestations.column.label'),
      key: 'label',
      dataIndex: 'label',
      width: 400,
      sorter: true,
      ellipsis: true
    },
    {
      title: t('prestations.column.support'),
      key: 'support',
      dataIndex: 'support',
      sorter: true,
      filters: supports.length && supports.map((e) => ({ text: e, value: e }))
    }
  ];

  const columnPrestation = [
    ...base,
    {
      title: t('prestations.column.isObsolete'),
      key: 'isObsolete',
      dataIndex: 'isObsolete',
      render: (obsolete) =>
        obsolete === true ? (
          <Tag>{t(`prestations.tags.${obsolete}`)}</Tag>
        ) : (
          <Tag>{t(`prestations.tags.${obsolete}`)}</Tag>
        ),
      sorter: true
    },
    {
      title: t('prestations.column.unit'),
      key: 'price',
      dataIndex: 'price',
      render: (price) =>
        price && price.proPrice && price.proPrice.unitaire === 0
          ? '-'
          : `${price.proPrice.unitaire.toFixed(2)} €`
    },
    {
      title: t('prestations.column.forf'),
      key: 'price',
      dataIndex: 'price',
      render: (price) =>
        price && price.proPrice && price.proPrice.forfait === 0
          ? '-'
          : `${price.proPrice.forfait.toFixed(2)} €`
    }
  ];

  const columnQuotationPrestation = [
    ...base,
    {
      title: t('prestations.column.thickness'),
      key: 'thickness',
      dataIndex: 'thickness',
      sorter: true,
      render: (thickness) => thickness && `${thickness} cm`
    },
    {
      title: t('prestations.column.thermal_resistance'),
      key: 'thermal_resistance',
      dataIndex: 'thermal_resistance',
      sorter: true,
      render: (thermal_resistance) =>
        thermal_resistance && `R = ${thermal_resistance}`
    }
  ];

  return { columnPrestation, columnQuotationPrestation };
};

export default useColumns;
