import React from 'react';
import { Tag } from 'antd';
import { useTranslation } from 'react-i18next';

const useColumns = () => {
  const { t } = useTranslation();

  return [
    {
      title: t('vat_account.code_number'),
      dataIndex: 'code_number'
    },
    {
      title: t('vat_account.description'),
      dataIndex: 'description'
    },
    {
      title: t('vat_account.lettrable'),
      dataIndex: 'lettrable',
      render: (text) => (text ? <Tag>Oui</Tag> : <Tag>Non</Tag>)
    },
    {
      title: t('vat_account.sales_product_account'),
      dataIndex: 'sales_product_account'
    },
    {
      title: t('vat_account.sales_product_account_subcontracting'),
      dataIndex: 'sales_product_account_subcontracting'
    },
    {
      title: t('vat_account.vat_account_deposit_invoice'),
      dataIndex: 'vat_account_deposit_invoice'
    }
  ];
};

export default useColumns;
